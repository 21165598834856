import { FeatureFlagConfig } from '../components/Feature';

export type EnvironmentTypes = 'develop' | 'staging' | 'production';

export interface LaunchDarklyEnvConfig {
  // launch darkly client IDs
  develop: string;
  staging: string;
  production: string;
}
export interface ConfigurationStateInterface {
  baseUrl: string; // base url of the main API
  tpCoreApiUrl: string;
  compSearchApiUrl: string; // Comp Search API
  docApiUrl: string; // base url of platform docuemnt API
  tpReportGeneratorApiUrl: string; // base url of TP Report Generator API
  authUrl: string; // base url of auth stack
  loginStackRelativeUrl: string; // realtive path for generic login page
  complianceCentralApiUrl: string; // base url of Compliance Central API
  planningApiUrl: string; // base url of planning lambda
  planningWebSocketUrl: string; // base url of planning web socket
  principleUrl: string; // base url of principle api
  adminUrl: string; // base url of admin stack
  proxyUrl: string; // base url of platform proxy
  tpUIReports: string; // base url of report transfer pricing ui app
  exacteraDomainUrl: string; // base url of exactera domain
  xbsEnvironment: EnvironmentTypes;
  xbsEnvironmentShort: string;
  buildTime: string;
  clientAppId: string;
  googleAnalyticsTrackId: Record<EnvironmentTypes, string>;
  tinyMCEApiKey: string;
  featureFlags: FeatureFlagConfig;
  launchDarklyEnv: LaunchDarklyEnvConfig;
}

declare global {
  interface Window {
    configuration: ConfigurationStateInterface;
  }
}

export const getAppConfig = (): ConfigurationStateInterface => window.configuration || {};
