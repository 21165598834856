import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ComplianceCentralCounter, ReportsInfo, MasterFileReportsInfo } from '../../models';
import { selectWorkingContainer } from '../../selectors';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface ComplianceCentralState {
  counters: ComplianceCentralCounter;
  reportsInfo: ReportsInfo[] | null;
  masterFileReportsInfo: MasterFileReportsInfo[] | null;
  error?: string;
}

const initialState: ComplianceCentralState = {
  counters: { countriesCount: 0, totalReportsCount: 0, finalReportsCount: 0, pendingReportsCount: 0 },
  reportsInfo: null,
  masterFileReportsInfo: null
};

export const fetchComplianceCentralCounter = createAsyncThunk<
  ComplianceCentralCounter,
  void,
  { state: RootState; rejectValue: Error }
>('complianceCentral/counter', async (_, { getState, rejectWithValue }) => {
  try {
    const container = selectWorkingContainer(getState());

    return (
      await httpService.request<{ data: ComplianceCentralCounter }>({
        method: 'get',
        apiUrlKey: 'complianceCentralApiUrl',
        relativePath: `/v1/containers/${container!.containerId}/counters`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchComplianceCentralReportsInfo = createAsyncThunk<
  ReportsInfo[],
  number,
  { state: RootState; rejectValue: Error }
>('complianceCentral/reportsInfo', async (containerId: number, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: ReportsInfo[] }>({
        method: 'get',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/containers/${containerId}/report/report-information`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetctComplianceCentralMasterFileReportsInfo = createAsyncThunk<
  MasterFileReportsInfo[],
  number,
  { state: RootState; rejectValue: Error }
>('complianceCentral/masterFileReportsInfo', async (containerId: number, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: MasterFileReportsInfo[] }>({
        method: 'get',
        apiUrlKey: 'complianceCentralApiUrl',
        relativePath: `/v1/containers/${containerId}/master-file-report-information`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const complianceCentralSlice = createSlice({
  name: 'complianceCentral',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchComplianceCentralCounter.fulfilled, (state, action: PayloadAction<ComplianceCentralCounter>) => {
        state.counters = action.payload;
      })
      .addCase(fetchComplianceCentralReportsInfo.fulfilled, (state, action: PayloadAction<ReportsInfo[]>) => {
        state.reportsInfo = action.payload;
      })
      .addCase(
        fetctComplianceCentralMasterFileReportsInfo.fulfilled,
        (state, action: PayloadAction<MasterFileReportsInfo[]>) => {
          state.masterFileReportsInfo = action.payload;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^complianceCentral\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^complianceCentral\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default complianceCentralSlice.reducer;
