import React, { ComponentProps } from 'react';
import { SvgIcon } from '@mui/material';

type Props = ComponentProps<typeof SvgIcon>;

const CopyContainerModalIcon = (props: Props) => {
  return (
    <SvgIcon
      width="24px"
      height="24px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="2023-Q2-Copy-Container" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TP-ConCopyTo-4" transform="translate(-521.000000, -146.000000)">
          <g id="workspaces_black_24dp" transform="translate(521.000000, 146.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <path
              d="M6,15 C7.1,15 8,15.9 8,17 C8,18.1 7.1,19 6,19 C4.9,19 4,18.1 4,17 C4,15.9 4.9,15 6,15 M6,13 C3.8,13 2,14.8 2,17 C2,19.2 3.8,21 6,21 C8.2,21 10,19.2 10,17 C10,14.8 8.2,13 6,13 Z M12,5 C13.1,5 14,5.9 14,7 C14,8.1 13.1,9 12,9 C10.9,9 10,8.1 10,7 C10,5.9 10.9,5 12,5 M12,3 C9.8,3 8,4.8 8,7 C8,9.2 9.8,11 12,11 C14.2,11 16,9.2 16,7 C16,4.8 14.2,3 12,3 Z M18,15 C19.1,15 20,15.9 20,17 C20,18.1 19.1,19 18,19 C16.9,19 16,18.1 16,17 C16,15.9 16.9,15 18,15 M18,13 C15.8,13 14,14.8 14,17 C14,19.2 15.8,21 18,21 C20.2,21 22,19.2 22,17 C22,14.8 20.2,13 18,13 Z"
              id="Shape"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CopyContainerModalIcon;
