import { useState } from 'react';
import { Box, Accordion, AccordionSummary, makeStyles } from '@material-ui/core';
import { ArrowRight, ArrowDropUp } from '@material-ui/icons';
import { Header } from './Header';
import { Item } from './Item';
import { NestedListItem, NestedListProps, NestedListSection } from '../NestedList.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto'
  },
  accordion: {
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: 0
    }
  },
  summary: {
    background: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 0
    }
  },
  summaryContent: {
    margin: theme.spacing(1, 0),
    order: 2,
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0)
    }
  },
  summaryButton: {
    padding: theme.spacing(0.5),
    margin: 0
  }
}));

export const NestedList = <T extends NestedListItem>({ data, selectedId, onSelect }: NestedListProps<T>) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<NestedListSection<T> | null>(null);

  return (
    <Box className={classes.root} component="aside">
      {data.map((section, i) => {
        const isExpanded = expanded === section || section.children.some(({ id }) => id === selectedId);
        return (
          <Accordion
            key={`${String(i)}`}
            square
            classes={{ root: classes.accordion }}
            expanded={isExpanded}
            onChange={(event, expanded) => {
              setExpanded(expanded ? section : null);
            }}
          >
            <AccordionSummary
              classes={{ root: classes.summary, content: classes.summaryContent, expandIcon: classes.summaryButton }}
              expandIcon={isExpanded ? <ArrowDropUp /> : <ArrowRight />}
            >
              <Header data={section} />
            </AccordionSummary>
            {section.children.map((item, j) => (
              <Item
                key={`${String(j)}`}
                disabled={item.disabled}
                selected={selectedId === item.id}
                data={item}
                onSelect={onSelect}
              />
            ))}
          </Accordion>
        );
      })}
    </Box>
  );
};
