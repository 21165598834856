import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { DeleteOutline, AddCircleOutline } from '@material-ui/icons';
import { ConfirmationModal } from '../../../components';
import tokens from '../../../styles/designTokens';
import { body1, body2, buttonText2, captionOverline, title2 } from '../../../styles/typography';
import { TrashCanIcon } from '../../../svgs';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '3.5rem',
    width: '32rem',
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: tokens.purpleLight2,
    boxSizing: 'border-box',
    borderRadius: '0.25rem',
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  selectedButton: {
    borderRadius: '0.813rem',
    backgroundColor: tokens.purpleLight2,
    height: '1.563rem',
    marginRight: '0.688rem',
    marginLeft: '2rem',
    marginTop: '1rem',
    width: '8rem'
  },
  selectedText: {
    ...captionOverline,
    color: tokens.white,
    textTransform: 'uppercase',
    letterSpacing: '0.6px'
  },
  statusDropDown: {
    minWidth: 'none',
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  buttons: {
    width: '6rem',
    height: '2.25rem',
    padding: '0.313rem',
    color: tokens.product100,
    border: '1px solid',
    borderColor: tokens.product100,
    marginRight: '0.5rem',
    marginTop: '0.6rem',
    '&:hover': {
      background: theme.palette.action.selected
    }
  },
  addToSearchBtn: {
    width: '9rem',
    height: '2.25rem',
    padding: '0.313rem',
    color: tokens.product100,
    border: '1px solid',
    borderColor: tokens.product100,
    marginRight: '0.5rem',
    marginTop: '0.6rem',
    '&:hover': {
      background: theme.palette.action.selected
    }
  },
  removeIcon: {
    height: '1.5rem',
    marginRight: '0.25rem',
    '& > g': {
      fill: tokens.product100
    }
  },
  deselectText: {
    marginTop: '1rem',
    marginRight: '1rem',
    height: '1.5rem',
    width: '3.625rem',
    color: tokens.product100,
    buttonText2,
    textAlign: 'center',
    fontWeight: 600
  },
  cardContent: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  checkCircle: {
    color: tokens.positive70
  },
  statusIcon: {
    ...body1,
    width: '1.375rem',
    height: '1.375rem',
    strokeWidth: '0rem'
  },
  dropDown: {
    ...body1,
    paddingLeft: '0.5rem'
  },
  cancelCircle: {
    backgroundColor: 'inherit'
  },
  header: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  contentWrapper: {
    backgroundColor: tokens.neutral70
  },
  headerIcon: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    marginRight: '0.313rem',
    padding: '0.5rem',
    borderRadius: '25%'
  },
  title2,
  descriptionContainer: {
    paddingLeft: '0.313rem',
    paddingRight: '0.625rem'
  },
  identifier: {
    ...body2,
    color: tokens.core2
  }
}));

interface TBAUncontrolledBulkActionsProp {
  selectedUncontrolledTransactionIds: number[];
  unselectAll: () => void;
  onBulkDeleteFromAllUncontrolled: (ids: number[]) => void;
  onAddToSearch: (ids: number[]) => void;
}

export const TbaUncontrolledTransactionsBulkActionsCard = ({
  selectedUncontrolledTransactionIds,
  onBulkDeleteFromAllUncontrolled,
  unselectAll,
  onAddToSearch
}: TBAUncontrolledBulkActionsProp) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = async (confirmation: boolean) => {
    setIsDeleting(false);
    if (confirmation) {
      onBulkDeleteFromAllUncontrolled(selectedUncontrolledTransactionIds);
    }

    setShowDeleteModal(false);
  };

  const handleAddToSearch = async () => {
    onAddToSearch(selectedUncontrolledTransactionIds);
  };

  return (
    <Box>
      <Card variant="outlined" className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Button disabled variant="outlined" className={classes.selectedButton}>
            <Typography
              className={classes.selectedText}
            >{`${selectedUncontrolledTransactionIds.length} selected`}</Typography>
          </Button>
          <Box>
            <Button variant="outlined" className={classes.addToSearchBtn} onClick={handleAddToSearch}>
              <AddCircleOutline className={classes.removeIcon} />
              {t('analysis:tba-uncontrolled-transaction-current-search-bulk-actions-add-to-search')}
            </Button>

            <Button
              variant="outlined"
              className={classes.buttons}
              onClick={() => {
                setShowDeleteModal(true);
              }}
            >
              <DeleteOutline className={classes.removeIcon} />
              {t('analysis:tba-uncontrolled-transaction-current-search-bulk-actions-delete')}
            </Button>
          </Box>
          <Button
            variant="text"
            className={classes.deselectText}
            onClick={() => {
              unselectAll();
            }}
          >
            {t('analysis:tba-uncontrolled-transaction-current-search-bulk-actions-deselect')}
          </Button>
        </CardContent>
      </Card>

      <ConfirmationModal
        open={showDeleteModal}
        title={t('analysis:tba-uncontrolled-transaction-delete-modal-title')}
        subtitle={`${t('analysis:tba-uncontrolled-transaction-bulk-delete-modal-subtitle')}`}
        text={t('analysis:tba-uncontrolled-transaction-bulk-delete-modal-text')}
        HeaderIcon={TrashCanIcon}
        handleClose={handleDelete}
        isLoading={isDeleting}
      />
    </Box>
  );
};
