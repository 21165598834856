import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { PbaTbaJurisdictionStatus } from '@xbs/xbs-enums';
import designTokens from '../../styles/designTokens';
import { captionOverline } from '../../styles/typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  jurisdictionContainer: {
    ...captionOverline,
    margin: 'auto',
    display: 'flex',
    textTransform: 'uppercase',
    padding: '0.313rem 0.375rem 0.313rem 0.625rem',
    borderRadius: '10% 0% 0% 10%'
  },
  statusContainer: {
    ...captionOverline,
    margin: 'auto',
    display: 'flex',
    textTransform: 'uppercase',
    padding: '0.313rem 0.625rem 0.313rem 0.375rem',
    borderRadius: '0% 10% 10% 0%'
  },
  completedJurisdiction: {
    backgroundColor: designTokens.product25,
    color: designTokens.product100
  },
  completedStatus: {
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    minWidth: '6.25rem'
  },
  inReviewJurisdiction: {
    backgroundColor: designTokens.supportColorA20,
    color: designTokens.supportColorA100
  },
  inReviewStatus: {
    backgroundColor: designTokens.supportColorA15,
    color: designTokens.supportColorA100,
    minWidth: '6.25rem'
  },
  inProgressJurisdiction: {
    backgroundColor: designTokens.progress20,
    color: theme.palette.warning.dark
  },
  inProgressStatus: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    minWidth: '6.25rem'
  }
}));

export interface JurisdictionStatusLabelProps {
  jurisdictionIso: string;
  jurisdictionStatusId: number;
}

export const JurisdictionStatusLabel = ({ jurisdictionIso, jurisdictionStatusId }: JurisdictionStatusLabelProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesByStatusId = {
    [PbaTbaJurisdictionStatus.ByName.InProgress.Id]: {
      jurisdiction: classes.inProgressJurisdiction,
      status: classes.inProgressStatus
    },
    [PbaTbaJurisdictionStatus.ByName.InReview.Id]: {
      jurisdiction: classes.inReviewJurisdiction,
      status: classes.inReviewStatus
    },
    [PbaTbaJurisdictionStatus.ByName.Complete.Id]: {
      jurisdiction: classes.completedJurisdiction,
      status: classes.completedStatus
    }
  };
  const statusText: string = PbaTbaJurisdictionStatus.ById[jurisdictionStatusId].Localization;
  return (
    <Box className={classes.root}>
      <Box
        className={`${classesByStatusId[jurisdictionStatusId].jurisdiction}
        ${classes.jurisdictionContainer}`}
      >
        {jurisdictionIso}
      </Box>
      <Box className={`${classesByStatusId[jurisdictionStatusId].status} ${classes.statusContainer}`}>
        {t(`common:label-${statusText}`)}
      </Box>
    </Box>
  );
};
