import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Typography, makeStyles } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { FunctionalAnalysisListItemProps } from './FunctionalAnalysisTable.proptype';
import { CustomIconMenu } from '../../components';
import LabelInput from '../../components/LabelInput';
import tokens from '../../styles/designTokens';
import { createDefaultNameCharacteristic } from '../../utils/createDefaultCharacteristicName';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(4.5),
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3)
  },
  itemName: {
    width: '15vw'
  },
  itemText: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    maxWidth: '15vw',
    padding: '0.5rem 0.75rem',

    '&:hover': {
      background: tokens.neutral90,
      borderRadius: '0.15rem'
    }
  },
  itemOptions: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between'
  }
}));

export const FunctionalAnalysisListItem = ({
  item,
  disabled = false,
  selectedAnalysis,
  setSelectedAnalysis,
  editFunctionalAnalysisCharacteristic,
  areaId,
  deleteFunctionalAnalysisCharacteristicRow,
  setCurrentSection,
  functionalAnalysisCharacteristics
}: FunctionalAnalysisListItemProps) => {
  const [newName, setNewName] = useState('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();

  function handleEditCharacteristicName() {
    if (newName.length < 3) {
      const newItem = {
        ...item,
        characteristicName: createDefaultNameCharacteristic(functionalAnalysisCharacteristics)
      };
      void editFunctionalAnalysisCharacteristic(newItem, areaId);

      setNewName('');
    } else if (newName.length <= 50) {
      const newItem = {
        ...item,
        characteristicName: newName
      };
      void editFunctionalAnalysisCharacteristic(newItem, areaId);
    }

    setIsActive(false);
  }

  return (
    <Box
      key={item.pbaFunctionalAnalysisDataId}
      className={classes.listItem}
      style={{
        backgroundColor: item.pbaFunctionalAnalysisDataId
          ? item.pbaFunctionalAnalysisDataId === selectedAnalysis?.pbaFunctionalAnalysisDataId
            ? tokens.product15
            : ''
          : item.tbaFunctionalAnalysisDataId === selectedAnalysis?.tbaFunctionalAnalysisDataId
          ? tokens.product15
          : ''
      }}
      onClick={() => {
        setSelectedAnalysis(item);
        setCurrentSection(areaId);
      }}
    >
      <Box className={classes.itemName}>
        {isActive && !disabled ? (
          <span>
            <LabelInput
              value={newName}
              isActive={isActive}
              disabled={disabled}
              maxLength={50}
              onChange={(newVal: string) => {
                setNewName(newVal);
              }}
              onBlur={() => {
                handleEditCharacteristicName();
              }}
              onSubmit={() => {
                handleEditCharacteristicName();
              }}
            />
          </span>
        ) : (
          <Typography
            className={classes.itemText}
            title={
              item.characteristicName.includes('functional_characteristic_type')
                ? t(`analysis:${String(item.characteristicName)}`)
                : item.characteristicName
            }
            onClick={() => {
              setNewName(t(`analysis:${String(item.characteristicName)}`));
              setIsActive(true);
            }}
          >
            {newName
              ? newName
              : item.characteristicName.includes('functional_characteristic_type')
              ? t(`analysis:${String(item.characteristicName)}`)
              : item.characteristicName}
          </Typography>
        )}
      </Box>
      <Box className={classes.itemOptions}>
        <Checkbox
          disabled={disabled}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          color="primary"
          checked={item.pbaFunctionalAnalysisDataId ? item.testedPartyContribution : Boolean(item.srcContribution)}
          onChange={() => {
            let newItem: any = {};
            if (item.pbaFunctionalAnalysisDataId) {
              newItem.testedPartyContribution = !item.testedPartyContribution;
              newItem.otherParticipantContribution = item.otherParticipantContribution;
              newItem.pbaFunctionalAnalysisDataId = item.pbaFunctionalAnalysisDataId;
            } else {
              newItem = {
                srcContribution: Number(!item.srcContribution),
                destContribution: item.destContribution,
                importance: 3,
                discussion: item.discussion,
                tbaFunctionalAnalysisDataId: item.tbaFunctionalAnalysisDataId
              };
            }

            void editFunctionalAnalysisCharacteristic(newItem, areaId);
          }}
        />
        <Checkbox
          disabled={disabled}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          color="primary"
          checked={
            item.pbaFunctionalAnalysisDataId
              ? Boolean(item.otherParticipantContribution)
              : Boolean(item.destContribution)
          }
          onChange={() => {
            let newItem: any = {};

            if (item.pbaFunctionalAnalysisDataId) {
              newItem.otherParticipantContribution = !item.otherParticipantContribution;
              newItem.testedPartyContribution = item.testedPartyContribution;
              newItem.pbaFunctionalAnalysisDataId = item.pbaFunctionalAnalysisDataId;
            } else {
              newItem = {
                srcContribution: item.srcContribution,
                destContribution: Number(!item.destContribution),
                importance: 3,
                discussion: item.discussion,
                tbaFunctionalAnalysisDataId: item.tbaFunctionalAnalysisDataId
              };
            }

            void editFunctionalAnalysisCharacteristic(newItem, areaId);
          }}
        />
        {!disabled && (
          <CustomIconMenu
            Icon={MoreHoriz}
            menuItems={[
              {
                text: t('analysis:functional_characteristic.delete_characteristic'),
                handleOnClick: () => {
                  deleteFunctionalAnalysisCharacteristicRow(
                    item.pbaFunctionalAnalysisDataId
                      ? item.pbaFunctionalAnalysisDataId
                      : item.tbaFunctionalAnalysisDataId,
                    areaId
                  );
                }
              }
            ]}
          />
        )}
      </Box>
    </Box>
  );
};
