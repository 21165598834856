import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import tokens from '../../../../styles/designTokens';
import { mediumFont } from '../../../../styles/fonts';
import { captionOverline } from '../../../../styles/typography';

const useStyles = makeStyles(() => ({
  chipContainer: {
    ...captionOverline,
    display: 'flex',
    alignItems: 'center',
    width: '13.625rem',
    height: '3.375rem',
    borderRadius: '0.25rem'
  },
  hasCount: {
    color: tokens.product100,
    backgroundColor: tokens.product10,
    border: `1px solid ${tokens.purpleLight2}`
  },
  noCount: {
    backgroundColor: tokens.neutral70
  },
  country: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%',
    width: '5rem',
    float: 'left'
  },
  countryHasCount: {
    backgroundColor: tokens.product15,
    borderRight: `1px solid ${tokens.purpleLight2}`,
    borderRadius: '0.25rem'
  },
  count: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginLeft: '1.25rem'
  },
  text: {
    textTransform: 'uppercase'
  },
  updating: {
    textTransform: 'uppercase',
    lineHeight: '1.375rem'
  },
  number: {
    ...mediumFont,
    fontSize: '1.125rem',
    lineHeight: '1.375rem'
  }
}));

export const ComparableCountChip = ({ country, count }: { country: string; count: number | null }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // eslint-disable-next-line unicorn/prefer-number-properties
  const hasCount = count !== null && Boolean(!isNaN(count));
  const updating = count === -1;

  const countText = () => {
    if (!hasCount) {
      return '-';
    }

    if (count === -1) {
      return t('title-updating');
    }

    return String(count);
  };

  return (
    <Box className={`${classes.chipContainer} ${hasCount ? classes.hasCount : classes.noCount}`}>
      <Box className={`${classes.country} ${hasCount ? classes.countryHasCount : ''}`}>{country}</Box>
      <Box className={classes.count}>
        <Box className={classes.text}>{t('analysis:title-section-search-results')}</Box>
        <Typography className={updating ? classes.updating : classes.number}>{countText()}</Typography>
      </Box>
    </Box>
  );
};
