import { Theme, makeStyles } from '@material-ui/core';
import tokens from '../../../../../styles/designTokens';
import { body1, body2, buttonText2 } from '../../../../../styles/typography';

export const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    background: tokens.product15,
    borderRadius: '50%',
    boxSizing: 'content-box',
    fill: tokens.product100,
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  topForm: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: tokens.white,
    borderRadius: '0.25rem',
    height: '10rem',
    paddingTop: '1.5rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    gap: '1rem',
    width: '100%'
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
    width: '100%'
  },
  formActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
    width: '100%',
    justifyContent: 'end'
  },
  largeInput: {
    width: '22rem',
    '& > *': {
      width: '100%'
    }
  },
  smallInput: {
    width: '10rem',
    '& > *': {
      width: '100%'
    }
  },
  xsmallInput: {
    width: '7rem',
    '& > *': {
      width: '100%'
    }
  },
  textField: {
    width: '100%',
    marginTop: '0rem',
    marginBottom: '0rem',
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white
    }
  },
  textFieldNonEmpty: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: tokens.purpleLight2,
      color: theme.palette.common.white,
      border: `0px solid ${tokens.purpleLight2}`,
      borderRadius: '4px'
    }
  },
  selectdNonEmpty: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white
    },
    '& > .MuiTextField-root .MuiAutocomplete-inputRoot': {
      color: theme.palette.common.white,
      placeholderColor: theme.palette.common.white,
      backgroundColor: tokens.purpleLight2,
      '& > .MuiAutocomplete-endAdornment .MuiIconButton-root': {
        color: theme.palette.common.white,
        placeholderColor: theme.palette.common.white
      }
    }
  },
  label: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    paddingBottom: theme.spacing(0.5)
  },
  selectStyles: {
    width: '100%',
    marginTop: '0rem',
    marginBottom: '0rem'
  },
  description: {
    ...body1,
    maxWidth: '20rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      ...body1,
      color: tokens.core2,
      backgroundColor: theme.palette.background.default,
      borderRadius: '4px',
      boxShadow: '0 0 4px 4px rgba(226,227,230,0.4)',
      padding: '1.1875rem 2.125rem',
      maxWidth: '40rem'
    }
  },
  poolSize: {
    marginLeft: 'auto'
  },
  bottomTable: {
    backgroundColor: tokens.white,
    borderRadius: '0.25rem',
    gap: '2rem',
    height: '20rem',
    overflowX: 'scroll'
  },
  removeAddContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1rem'
  },
  removeAddIcon: {
    color: tokens.neutral100,
    paddingTop: '0.375rem',
    paddingLeft: '0.375rem',
    borderRadius: '2rem',
    width: '2.25rem',
    height: '2.25rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  companyAddedIcon: {
    color: tokens.product100,
    background: tokens.product25
  },
  noItemsSplashContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '2.5rem',
    backgroundColor: tokens.neutral70,
    padding: '1rem',
    gap: '1rem'
  },
  noItemsSplashIcon: {
    color: tokens.product100
  },
  noItemsSplashTexts: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '25rem'
  },
  websiteColumnIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: tokens.neutral100,
    paddingTop: '0.2rem',
    paddingLeft: '0.3rem',
    width: '1rem',
    height: '2.25rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },

  buttonLinkWrapper: {
    ...buttonText2,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: tokens.white
    }
  },
  companyColumnHeader: {
    paddingLeft: '1rem'
  },
  addColumnHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1.3rem'
  },

  columnText: {
    ...body1
  },
  disabledText: {
    ...body1,
    color: tokens.core2,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid transparent`,

    paddingLeft: '1rem',
    height: '1.5rem',
    borderRadius: '1rem',
    background: 'transparent',
    width: 'fit-content',
    gap: '0.25rem'
  },
  blockIcon: {
    width: '0.75rem'
  },
  selectedText: {
    ...body2,
    color: tokens.product100
  },
  ellipsizeLongNames: {
    maxWidth: '25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  companyColumnText: {
    ...body2,
    border: `1px solid transparent`,
    paddingRight: '1rem',
    paddingLeft: '1rem',
    height: '1.5rem',
    borderRadius: '1rem',
    background: 'transparent',
    width: 'fit-content'
  },
  selectedCompany: {
    display: 'table-cell',
    verticalAlign: 'middle',
    maxWidth: '25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: tokens.product100,
    borderColor: tokens.product25,
    background: tokens.product15
  },
  unavailableText: {
    textTransform: 'uppercase',
    paddingRight: '0.25rem'
  },
  doneButton: {
    width: '8rem'
  },
  searchCompaniesButton: {
    width: '18rem'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    backgroundColor: tokens.neutral70
  }
}));
