import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { ConnectorProps, ParamsStudy } from './AnalysisHeader.proptype';
import { currentPBA, fetchPBAs } from '../../redux/profitBasedAnalyses';
import { resetTimer } from '../../redux/studies';
import { selectPBAsList, selectPBA, selectWorkingContainer } from '../../selectors';
import { getAuthInfo, decodeTokens } from '../../utils';

const Connector = ({ component: Component }: ConnectorProps) => {
  const { search } = useLocation();
  const history = useHistory();
  const match = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');

  const isReferredTransactions = (/^[#?]/.test(search) ? search.slice(1) : search).split('&').find((queryString) => {
    return queryString.includes('referredTransaction');
  });

  const dispatch = useDispatch();
  const params: ParamsStudy = match?.params as ParamsStudy;
  const studyId = params?.studyId;
  const pbaId = Number.parseInt(params?.pbaId, 10);
  const pbas = useSelector(selectPBAsList);
  const pba = useSelector(selectPBA(pbaId));
  const workingContainer = useSelector(selectWorkingContainer);
  const { authToken } = getAuthInfo();
  const userData = decodeTokens(authToken);

  useEffect(() => {
    if (pbas === null) {
      dispatch(fetchPBAs(studyId));
    }
  });

  useEffect(() => {
    if (pba) {
      dispatch(currentPBA(pba));
    }
  });

  const onHandleClose = () => {
    if (isReferredTransactions) {
      const transactionId = isReferredTransactions.split('=')[1];
      history.push(`/transactions/${String(transactionId)}/dashboard/`);
    } else {
      dispatch(resetTimer({ containerId: workingContainer?.containerId, userId: userData?.userId }));
      history.push(`/analysis/${String(studyId)}`);
    }
  };

  return <Component pba={pba} workingContainer={workingContainer} onHandleClose={onHandleClose} />;
};

export default Connector;
