import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { DropDownButton } from '../../../components';
import { EntitiesEventsProps } from '../Entities.proptype';

export const AddEntitiesButton = ({ onAddSingle, onImport }: Pick<EntitiesEventsProps, 'onAddSingle' | 'onImport'>) => {
  const { t } = useTranslation();
  return (
    <DropDownButton
      variant="contained"
      color="primary"
      items={[
        {
          children: t('entities:action-new'),
          onClick: () => {
            onAddSingle();
          }
        },
        {
          children: t('entities:action-import'),
          onClick: () => {
            onImport();
          }
        }
      ]}
    >
      <>
        <Add />
        {t('entities:action-add-choice')}
      </>
    </DropDownButton>
  );
};
