import {
  FunctionalAnalysisCharacteristic,
  TBAFunctionalAnalysisCharacteristic
} from '../components/FunctionalAnalysis/FunctionalAnalysisTable.proptype';

const regex = /^Characteristic.[1-9]\d*$/;

export function createDefaultNameCharacteristic(
  functionalAnalysisCharacteristicsArea: FunctionalAnalysisCharacteristic[] | TBAFunctionalAnalysisCharacteristic[]
) {
  let maxNum = 0;
  functionalAnalysisCharacteristicsArea.forEach(
    (functionalAnalysisCharacteristic: FunctionalAnalysisCharacteristic | TBAFunctionalAnalysisCharacteristic) => {
      if (regex.test(functionalAnalysisCharacteristic.characteristicName)) {
        const nameSplit = functionalAnalysisCharacteristic.characteristicName.split(' ');
        maxNum = Math.max(maxNum, Number(nameSplit[1]));
      }
    }
  );
  const charName = `Characteristic ${maxNum + 1}`;
  return charName;
}
