import { useTranslation } from 'react-i18next';
import { Table } from '../../Table';
import { PliAveragesTableProps } from '../ProfitLevelIndicator.proptype';

export const PliAveragesTable = ({ displayablePliAverages }: PliAveragesTableProps) => {
  const { t } = useTranslation();

  return (
    <Table
      data={displayablePliAverages}
      columns={[
        {
          key: 'name',
          header: 'PLI Name',
          width: 250
        },
        { key: 'Year3Simple', header: t('analysis:pli_average_type.3_year_simple'), width: 150 },
        { key: 'Year3Weighted', header: t('analysis:pli_average_type.3_year_weighted'), width: 150 },
        { key: 'Year3PeriodWeighted', header: t('analysis:pli_average_type.3_year_period_weighted'), width: 150 },
        { key: 'Year5Simple', header: t('analysis:pli_average_type.5_year_simple'), width: 150 },
        { key: 'Year5Weighted', header: t('analysis:pli_average_type.5_year_weighted'), width: 150 },
        { key: 'Year5PeriodWeighted', header: t('analysis:pli_average_type.5_year_period_weighted'), width: 150 }
      ]}
    />
  );
};
