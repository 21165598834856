import { ComponentType, Dispatch, SetStateAction } from 'react';
import { FunctionalAnalysisCharacteristic } from '../../components/FunctionalAnalysis/FunctionalAnalysisTable.proptype';

export interface PBAFunctionalAnalysisProps {
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[];
      }
    | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId?: number
  ) => Promise<void>;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  currentSection: number;
  setSelectedAnalysis: Dispatch<SetStateAction<FunctionalAnalysisCharacteristic | undefined>>;
  setCurrentSection: Dispatch<SetStateAction<number>>;
  deleteFunctionalAnalysisCharacteristicRow: (characteristicId: number, areaId: number) => void;
  createNewCharacteristic: (characteristicName: string, areaId: number) => void;
  selectedContent: JSX.Element[];
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  trackGATime: () => void;
}

export interface ConnectorProps {
  component: ComponentType<PBAFunctionalAnalysisProps>;
}

export enum FunctionalAnalysisObjectTypeEnum {
  TBA = 1,
  UncontrolledTransaction,
  PBA
}
