import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { CenteredProgress } from '../../components';
import { Entity, FinalLocalReport, InternalLocalFileReport, Transaction } from '../../models';
import tokens from '../../styles/designTokens';
import { DownloadIcon } from '../../svgs';
import { DashboardCard } from '../DashboardCard';

export interface EntityReportsCardProps {
  entity: Entity | null;
  transactions: Transaction[] | null;
  internalReports?: InternalLocalFileReport[];
  finalReport?: FinalLocalReport | null;
  onDownload: () => Promise<void>;
  onNavigate?: () => void;
}

const useStyles = makeStyles((theme) => ({
  topNote: {
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  content: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    gap: theme.spacing(2)
  },
  downloadButton: {
    height: 'auto',
    '& svg path': {
      fill: theme.palette.primary.contrastText
    }
  },
  navigateIcon: {
    color: tokens.neutral95,
    cursor: 'not-allowed'
  },
  highlighted: {
    color: theme.palette.text.primary
  }
}));

export const EntityReportsCard = ({
  entity,
  transactions,
  internalReports,
  finalReport,
  onDownload,
  onNavigate
}: EntityReportsCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [downloading, setDownloading] = useState(false);
  const [reportsIconDisabled, setReportsIconDisabled] = useState(false);

  useEffect(() => {
    if (transactions) {
      setReportsIconDisabled(transactions.length === 0);
    }
  }, [transactions]);

  const handleDownload = async () => {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  };

  return (
    <DashboardCard
      title={t('reports:label-count', { count: internalReports?.length })}
      customAction={
        <>
          {t('reports:label-go-to')}
          <Tooltip title={reportsIconDisabled ? t('reports:label-can-not-go') ?? '' : ''}>
            <IconButton
              className={`${reportsIconDisabled ? classes.navigateIcon : ''}`}
              onClick={() => {
                if (!reportsIconDisabled) onNavigate?.();
              }}
            >
              <Launch />
            </IconButton>
          </Tooltip>
        </>
      }
    >
      {entity && internalReports && finalReport !== undefined ? (
        finalReport ? (
          <>
            <Box className={classes.topNote}>{t('reports:label-latest-published')}</Box>
            <Box className={classes.content}>
              <Button
                className={classes.downloadButton}
                variant="contained"
                color="primary"
                disabled={downloading}
                onClick={handleDownload}
              >
                <DownloadIcon />
              </Button>
              <Box flexGrow={1}>
                <Typography className={classes.highlighted}>{finalReport.name}</Typography>
                <Typography>{entity.code}</Typography>
                <Typography>
                  {t('entities:label-count', {
                    count: finalReport?.internalLocalfileReportInstance?.primaryEntities?.length
                  })}{' '}
                  {t('reports:label-trading-partner-count', {
                    count: finalReport.internalLocalfileReportInstance?.tradingPartners?.length
                  })}
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Typography>{t('reports:message-none-for-entity')}</Typography>
        )
      ) : (
        <CenteredProgress />
      )}
    </DashboardCard>
  );
};
