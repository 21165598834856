/* eslint-disable camelcase */
import { useCallback, useState } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { selectTransactionSelected } from '../../../selectors';
import { getPlatformModule } from '../../../services/platform/platform';

type ValidIntervals = 'minutes' | 'seconds';

interface UseTrackingTimeResult {
  startTime: () => void;
  resetTime: () => Promise<void>;
  isTracking: boolean;
}

export enum TimeTrackingEvents {
  JurisdictionAnalysisTextArea = 'time_spent_analysis_textarea',
  MethodEvaluationSwitchTab = 'time_spent_method_evaluation_tab',
  CompSearchAnalysis = 'time_spent_comp_search_analysis',
  CompSearchTestedPartyDetails = 'time_spent_comp_search_tested_party_details',
  CompSearchFunctionalAnalysis = 'time_spent_comp_search_functional_analysis',
  CompSearchTestedPartyCharacterization = 'time_spent_comp_search_tested_party_characterization',
  CompSearchReRunModal = 'time_spent_comp_search_rerun_modal',
  AddSearchCompaniesModal = 'time_spent_add_companies_modal'
}

type AnalyticsPayloadEvent = {
  event_category: string;
  event_label: string;
  time: number;
  containerId: number;
  userId: string;
  transactionId: number;
  interval: ValidIntervals;
  appEvent: TimeTrackingEvents;
};

export default function useTrackingTime(
  appEvent: TimeTrackingEvents,
  defaultInterval?: ValidIntervals
): UseTrackingTimeResult {
  const [time, setTime] = useState<number | null>(null);
  const transaction = useSelector(selectTransactionSelected);
  const interval = defaultInterval ?? 'minutes';

  const startTime = useCallback(() => {
    setTime(Date.now());
  }, []);

  const resetTime = useCallback(async () => {
    if (!time) return;

    try {
      const timeDiff = Date.now() - time;
      const { IdentityInfo } = await getPlatformModule();
      const session = await IdentityInfo.getIdentitySession();
      const { containerUuid, userUuid } = session;

      const timeDiffInMinutes = timeDiff / 1000 / 60;
      const timeDiffInSeconds = timeDiff / 1000;

      const timeElapsed = interval === 'minutes' ? timeDiffInMinutes : timeDiffInSeconds;

      const payload: AnalyticsPayloadEvent = {
        event_category: 'Time Tracking',
        event_label: `Time spent on ${interval}`,
        containerId: containerUuid,
        userId: userUuid,
        transactionId: transaction?.transactionId ?? 0,
        time: timeElapsed,
        interval,
        appEvent
      };

      ReactGA.set({
        user_id: userUuid,
        container_id: containerUuid,
        transaction_id: transaction?.transactionId ?? 0
      });

      ReactGA.event(appEvent, payload);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error while sending time tracking event', error.message);
      } else {
        console.error('Error while sending time tracking event', error);
      }
    } finally {
      setTime(null);
    }
  }, [time, interval, transaction?.transactionId, appEvent]);

  return {
    startTime,
    resetTime,
    isTracking: time !== null
  };
}
