import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectWorkingContainer } from '../../selectors';
import { getAuthInfo, getAppConfig, decodeTokens } from '../../utils';

export const walkMesSriptByEnvironment: Record<string, string> = {
  develop: `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ea99c6174fa949ed89103c49ab1d2ee0/test/walkme_ea99c6174fa949ed89103c49ab1d2ee0_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`,
  production: `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ea99c6174fa949ed89103c49ab1d2ee0/walkme_ea99c6174fa949ed89103c49ab1d2ee0_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`
};

export const WalkMe = () => {
  const { xbsEnvironment } = getAppConfig();
  const workingContainer = useSelector(selectWorkingContainer);
  const { authToken } = getAuthInfo();

  useEffect(() => {
    // Avoiding to request script twice
    if (!document.querySelector('#walk-me-script')) {
      const node = document.createElement('script');
      node.type = 'text/javascript';
      node.id = 'walk-me-script';

      node.innerHTML = walkMesSriptByEnvironment[xbsEnvironment] || walkMesSriptByEnvironment.develop;
      document.querySelectorAll('head')[0].append(node);
    }
  }, [xbsEnvironment]);

  useEffect(() => {
    if (authToken && workingContainer) {
      const decodedToken = decodeTokens(authToken);

      if (decodedToken) {
        (window as any).userId = decodedToken.userId;
        (window as any).role = decodedToken.role;
        (window as any).tenantId = workingContainer.tenantId;
      }
    }
  }, [authToken, workingContainer]);

  return null;
};
