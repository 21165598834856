import { Box, makeStyles, useTheme } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { NestedListItem, NestedListProps } from '../NestedList.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 1, 1, 3),
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    '&.selected': {
      backgroundColor: theme.palette.secondary.main
    },
    '&.disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
      cursor: 'default'
    }
  }
}));

interface NestedListItemProps<T extends NestedListItem> {
  data: T;
  selected?: boolean;
  disabled?: boolean;
  onSelect: NestedListProps<T>['onSelect'];
}

export const Item = <T extends NestedListItem>({ data, selected, disabled, onSelect }: NestedListItemProps<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      role="listitem"
      aria-selected={selected}
      aria-disabled={disabled}
      className={`${classes.root}${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
      onClick={() => {
        if (!disabled) {
          onSelect(data);
        }
      }}
    >
      <FiberManualRecord
        style={{ color: data.checked ? theme.palette.primary.main : theme.palette.warning.main, fontSize: 12 }}
      />
      <Box component="span" flexGrow={1} marginLeft={0.5}>
        {data.name}
      </Box>
      {data.extra}
    </Box>
  );
};
