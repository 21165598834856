import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { TransactionDashboardProps } from './TransactionDashboard.proptype';
import { selectWorkingContainer } from '../../selectors';
import { findTransactionDestination, findTransactionSource } from '../../utils';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { ContentHeader } from '../ContentHeader';
import { StatementOfFactsCard } from '../StatementOfFactsCard';
import { TransactionAnalysisSummaryCard } from '../TransactionAnalysisSummaryCard';
import { TransactionEvaluationCard } from '../TransactionEvaluationCard';
import { TransactionMethodEvaluationCard } from '../TransactionMethodEvaluationCard';
import { TransactionSummaryCard } from '../TransactionSummaryCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },
  rowContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto'
  },
  row: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    '& > *': {
      flex: 1
    }
  },
  bottomRow: {
    backgroundColor: theme.palette.background.paper,
    flex: 1
  }
}));

export const TransactionDashboard = ({
  transaction,
  study,
  upeCurrency,
  entityById,
  evaluations,
  evaluationsCompletion,
  statementOfFactsCompletion,
  onNavigate
}: TransactionDashboardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);
  const entitiesWithCompletion = transaction
    ? transaction.legalEntityTransactions
        .map(({ isSource, entity, completed }) => ({
          order: isSource ? 0 : 1,
          code: entity?.code,
          completed
        }))
        .sort((a, b) => a.order - b.order)
    : undefined;
  const source = findTransactionSource(transaction, entityById);
  const destination = findTransactionDestination(transaction, entityById);

  return (
    <Box className={classes.root}>
      <ContentHeader
        primaryEntity={transaction?.identifier ?? ''}
        title={t('transactions:title-dashboard')}
        link="/transactions"
        icon={<ArrowBack />}
      />
      <Box className={classes.rowContainer}>
        <Box className={classes.row}>
          <StatementOfFactsCard
            title={t('transactions:title-sof')}
            message={t('message-dashboard-sof')}
            completionStatuses={statementOfFactsCompletion}
            onNavigate={() => {
              if (transaction) {
                /* eslint-disable camelcase */
                void logGoogleAnalyticsEvent({
                  event_category: `transactions_section_sof_card_click`,
                  event_label: `Transactions section statement of facts card click`,
                  container_id: workingContainer?.containerId
                });
                onNavigate(`/transactions/${transaction.transactionId}/details/statement-of-facts`);
              }
            }}
          />
          {(!transaction || transaction.transactionType.name === 'Services') && (
            <TransactionEvaluationCard
              entitiesWithCompletion={entitiesWithCompletion}
              onNavigate={() => {
                if (transaction) {
                  void logGoogleAnalyticsEvent({
                    event_category: `transactions_section_tr_evaluation_card_click`,
                    event_label: `Transactions section transaction evaluation card click`,
                    container_id: workingContainer?.containerId
                  });
                  onNavigate(`/transactions/${transaction.transactionId}/details/transaction-evaluation`);
                }
              }}
            />
          )}
          <TransactionMethodEvaluationCard
            evaluations={evaluations}
            evaluationsCompletion={evaluationsCompletion}
            onNavigate={() => {
              if (transaction) {
                void logGoogleAnalyticsEvent({
                  event_category: `transactions_section_method_evaluation_card_click`,
                  event_label: `Transactions section method evaluation card click`,
                  container_id: workingContainer?.containerId
                });
                onNavigate(`/transactions/${transaction.transactionId}/details/method-evaluation`);
              }
            }}
          />
        </Box>
        <Box className={`${classes.bottomRow} ${classes.row}`}>
          <TransactionSummaryCard
            transaction={transaction ?? undefined}
            currency={upeCurrency}
            source={source}
            destination={destination}
          />
          <TransactionAnalysisSummaryCard
            evaluations={evaluations}
            study={study}
            source={source}
            destination={destination}
            transactionId={transaction?.transactionId ?? 0}
            transactionTypeName={transaction?.transactionType.name ?? ''}
          />
        </Box>
      </Box>
    </Box>
  );
};
