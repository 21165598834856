import { TbaEvaluationMethods, TbaTransactionTypes } from '@xbs/xbs-enums';
import i18next from 'i18next';
import { MethodTypes } from '../app/NewAnalysisModal/CreateTBAModal.proptypes';
import { TransactionBasedAnalysis, TransactionType } from '../models';
import { TbaControlledParameter } from '../redux/transactionBasedAnalyses';

const evaluationMethods = TbaEvaluationMethods.EvaluationMethods;
const transactionTypes = TbaTransactionTypes.TransactionType;

export const findTBARangeValue = (tbaParams: any[] | undefined, tba: TransactionBasedAnalysis | undefined) => {
  if (tba && tbaParams && tbaParams.length > 0) {
    const tbaMethod = tba.tbaEvaluationMethod.name;
    const transactionType = tba.transactionType.name;
    let selectedTbaParameter = tbaParams[0];

    if (tbaMethod === 'CP' && transactionType === 'Services') {
      selectedTbaParameter = tbaParams.find(
        (param: TbaControlledParameter) => param.tbaParameter?.type === 'Gross Profit Markup'
      );
    }

    if (tbaMethod === 'CP' && transactionType === 'Tangible Goods') {
      selectedTbaParameter = tbaParams.find(
        (param: TbaControlledParameter) => param.tbaParameter?.type === 'Gross Profit Markup'
      );
    }

    if (tbaMethod === 'RSP' && transactionType === 'Tangible Goods') {
      selectedTbaParameter = tbaParams.find(
        (param: TbaControlledParameter) => param.tbaParameter?.type === 'Gross Profit Margin'
      );
    }

    return selectedTbaParameter?.parameterValue ?? Number.NaN;
  }
};

export const isRangeValueTypeUnitPrice = (tba: TransactionBasedAnalysis | null | undefined): boolean => {
  if (tba) {
    const tbaMethod = tba.tbaEvaluationMethod.name;
    const transactionType = tba.transactionType.name;
    return tbaMethod === 'CUT/CUP' && transactionType === 'Tangible Goods';
  }

  return false;
};

/**
 * Rounds a decimal value to the nearest provided roundingValue.
 * @param {number} rangeValue - The decimal value to be rounded.
 * @param {number} roundingValue - rounding value for decimal/percentage conversion.

 * @returns {number} - The rounded value (e.g., 0.80).
 */
export const roundValue = (value: number | string | undefined, roundingValue: number): number => {
  if (typeof roundingValue === 'undefined' || Number(roundingValue) === 0) {
    return Math.round(Number(value));
  }

  value = Number(value);
  roundingValue = Number(roundingValue);

  if (Number.isNaN(value) || !(typeof roundingValue === 'number' && roundingValue % 1 === 0)) {
    return Number.NaN;
  }

  const roundToIntegerWithScaledValue = Math.round(Number(`${String(value) + 'e' + String(roundingValue)}`));

  const convertToFloatRemoveScaledValue = Number(
    `${String(roundToIntegerWithScaledValue) + 'e' + String(-roundingValue)}`
  );

  return Number(convertToFloatRemoveScaledValue);
};

export const formatRangeValue = (rangeValue: number | string | undefined, isUnitPrice?: boolean): string => {
  if (rangeValue || typeof rangeValue === 'number') {
    if (isUnitPrice) return roundValue(Number(rangeValue), 2).toFixed(2);
    return `${roundValue(`${String(rangeValue) + 'e2'}`, 2).toFixed(2)}%`;
  }

  return '';
};

export const formatRangeValueRowHeader = (
  label: string,
  isUnitPrice?: boolean,
  percentageLocation: 'prefix' | 'suffix' = 'suffix'
): string => {
  const percentageSymbol = isUnitPrice ? '' : '(%)';
  return `${percentageLocation === 'prefix' ? percentageSymbol : ''} ${label} ${
    percentageLocation === 'suffix' ? percentageSymbol : ''
  }`.trim();
};

export const getRangeValueHeader = (
  transactionType: TransactionType | undefined,
  tbaEvaluationMethod: MethodTypes | undefined,
  isUnitPrice: boolean | undefined,
  percentageLocation: 'prefix' | 'suffix' = 'suffix'
): string => {
  let header = i18next.t('analysis:column-source-range-value');

  if (!transactionType || !tbaEvaluationMethod) {
    return formatRangeValueRowHeader(i18next.t('analysis:column-source-range-value'), isUnitPrice, percentageLocation);
  }

  const isUnitPriceHeader =
    tbaEvaluationMethod === evaluationMethods['CUT/CUP'] && transactionType.name === transactionTypes.TangibleGoods;

  if (tbaEvaluationMethod === evaluationMethods.CP) {
    header = i18next.t('analysis:column-source-gross-profit-markup');
  } else if (tbaEvaluationMethod === evaluationMethods['CUT/CUP']) {
    switch (transactionType.name) {
      case transactionTypes.IntangibleGoods:
        header = i18next.t('analysis:column-source-royalty');
        break;
      case transactionTypes.Loans:
        header = i18next.t('analysis:column-source-interest');
        break;
      case transactionTypes.Services:
        header = i18next.t('analysis:column-source-service');
        break;
      case transactionTypes.TangibleGoods:
        header = i18next.t('analysis:column-source-unit-price');
        break;
      default:
        break;
    }
  } else if (tbaEvaluationMethod === evaluationMethods.RSP && transactionType.name === transactionTypes.TangibleGoods) {
    header = i18next.t('analysis:column-source-gross-profit-margin');
  }

  return isUnitPriceHeader ? header : formatRangeValueRowHeader(header, isUnitPrice, percentageLocation);
};
