import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchEntities } from '../../redux/entities';
import { currentPBA, fetchPBAs } from '../../redux/profitBasedAnalyses';
import { fetchCurrentMajorClassifications, fetchMajorClassifications } from '../../redux/testedParty';
import {
  selectEntitiesLoadingState,
  selectPBA,
  selectPBALoadingState,
  selectPBAsList,
  testedPartyLoadingState
} from '../../selectors';

interface ConnectorProps {
  component: any;
  // ToDo: Change this to Component type
}

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const pbas = useSelector(selectPBAsList);
  const pba = useSelector(selectPBA(pbaId));
  const loadingPBAStates = useSelector(selectPBALoadingState);
  const loadingTestedPartyState = useSelector(testedPartyLoadingState);
  const loadingEntitiesState = useSelector(selectEntitiesLoadingState);

  useEffect(() => {
    if (!loadingPBAStates.fetchPBAs) {
      dispatch(fetchPBAs);
    } else if (!loadingTestedPartyState.fetchCurrentMajorClassifications) {
      dispatch(fetchCurrentMajorClassifications(pbaId));
    }
  }, [dispatch, pbas, pbaId, loadingPBAStates.fetchPBAs, loadingTestedPartyState.fetchCurrentMajorClassifications]);

  useEffect(() => {
    if (!loadingTestedPartyState.fetchMajorClassifications) {
      dispatch(fetchMajorClassifications());
    }

    dispatch(currentPBA);
  }, [dispatch, pba, loadingTestedPartyState.fetchMajorClassifications]);

  useEffect(() => {
    if (!loadingEntitiesState.fetchEntities) {
      dispatch(fetchEntities());
    }
  }, [dispatch, loadingEntitiesState.fetchEntities]);

  return <Component />;
};

export default Connector;
