import { makeStyles, Paper } from '@material-ui/core';
import { FinancialTableBody } from './FinancialTableBody';
import { FinancialTableFooter } from './FinancialTableFooter';
import { FinancialTableHeader } from './FinancialTableHeader';
import { CenteredProgress } from '../../../components';
import { FinancialTableProps } from '../FinancialTable.proptype';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    padding: '2em',
    overflow: 'auto'
  }
}));

export const FinancialTable = ({
  title,
  headerInfo,
  currencyOptions,
  financialConversions,
  columnYears,
  hasFooter,
  tableData,
  onCurrencyChange,
  onSaveData,
  completionByYear,
  onSetCompletion,
  handleUpdate,
  namespace
}: FinancialTableProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <FinancialTableHeader
        title={title}
        headerInfo={headerInfo}
        handleChangeCurrency={onCurrencyChange}
        currencyOptions={currencyOptions}
        financialConversions={financialConversions}
        namespace={namespace}
        onSaveData={onSaveData}
      />
      {tableData && tableData.length > 0 ? (
        <FinancialTableBody
          data={tableData}
          years={columnYears}
          update={handleUpdate}
          footer={
            hasFooter ? (
              <FinancialTableFooter
                columnYears={columnYears}
                completionByYear={completionByYear}
                onSetCompletion={onSetCompletion}
              />
            ) : null
          }
          onSaveData={onSaveData}
        />
      ) : (
        <CenteredProgress />
      )}
    </Paper>
  );
};
