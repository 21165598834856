import { useState } from 'react';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { IconSortDown } from '../../svgs';

export type SortOrder = 'asc' | 'desc';

export interface SearchAndSortProps {
  field: string;
  onSortClicked: (sortOrder: SortOrder) => void;
  onSearchChange: (filterBy: string) => void;
}

const useStyles = makeStyles((theme) => ({
  searchBox: {
    '& .MuiOutlinedInput-input': {
      textOverflow: 'ellipsis',
      paddingRight: theme.spacing(3.5)
    },
    '& .MuiInputAdornment-root': {
      position: 'relative'
    }
  },
  flip: {
    transform: 'rotate(180deg)'
  },
  floatingAdornment: {
    left: theme.spacing(-3.5),
    position: 'absolute'
  }
}));

export const SearchAndSort = ({ field, onSearchChange, onSortClicked }: SearchAndSortProps) => {
  const classes = useStyles();
  const [sortOrderAsc, setSortOrderAsc] = useState(true);

  return (
    <TextField
      key={`${field}-input-field`}
      fullWidth
      className={`MuiDataGrid-colCellTitle ${classes.searchBox}`}
      variant="outlined"
      placeholder={field}
      InputProps={{
        onChange: ({ target: { value } }) => {
          onSearchChange(value);
        },
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={() => {
              setSortOrderAsc((isAsc) => !isAsc);
              onSortClicked(sortOrderAsc ? 'desc' : 'asc');
            }}
          >
            <Search className={classes.floatingAdornment} />
            <IconSortDown className={`${sortOrderAsc ? '' : classes.flip}`} />
          </InputAdornment>
        )
      }}
    />
  );
};
