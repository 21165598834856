import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ConnectorProps } from './TransactionDashboard.proptype';
import { BackgroundInfoType } from '../../models';
import { fetchEntities } from '../../redux/entities';
import { fetchStatementOfFacts } from '../../redux/statementOfFacts';
import { fetchTransactionEvaluations } from '../../redux/transactionEvaluations';
import { fetchTransactionById, fetchTransactions } from '../../redux/transactions';
import {
  selectEntitiesList,
  selectEvaluationsCompletion,
  selectStatementOfFactsCompletion,
  selectTransaction,
  selectTransactionEvaluations,
  selectTransactionSelected,
  selectUPECurrency
} from '../../selectors';

const Connector = ({ transactionId, component: Component }: ConnectorProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const upeCurrency = useSelector(selectUPECurrency);
  const entities = useSelector(selectEntitiesList);
  const transaction = useSelector(selectTransaction(transactionId));
  const transactionSelected = useSelector(selectTransactionSelected);
  const evaluations = useSelector(selectTransactionEvaluations({ transactionId }));
  const statementOfFactsCompletion = useSelector(
    selectStatementOfFactsCompletion({ type: BackgroundInfoType.Transaction, id: transactionId })
  );
  const evaluationsCompletion = useSelector(selectEvaluationsCompletion({ transactionId, transaction, entities }));

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchEntities());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStatementOfFacts({ type: BackgroundInfoType.Transaction, id: transactionId }));
  }, [dispatch, transactionId]);

  useEffect(() => {
    dispatch(fetchTransactionEvaluations({ transactionId }));
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (!transactionSelected) {
      dispatch(fetchTransactionById(transactionId));
    }
  }, [dispatch, transactionSelected, transactionId]);

  const entityById = useMemo(() => new Map((entities ?? []).map((entity) => [entity.entityId, entity])), [entities]);

  return (
    <Component
      transaction={transaction}
      study={transactionSelected?.study}
      upeCurrency={upeCurrency}
      entityById={entityById}
      evaluations={evaluations}
      evaluationsCompletion={evaluationsCompletion}
      statementOfFactsCompletion={statementOfFactsCompletion}
      onNavigate={(path) => {
        history.push(path);
      }}
    />
  );
};

export default Connector;
