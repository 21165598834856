import { TFunction } from 'react-i18next';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { differs, groupByEntityId } from './utils';
import { RootState } from '..';
import { FinancialConversion } from '../../models';
import { selectConversionRates } from '../../selectors';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface ConversionRatesState {
  rates: FinancialConversion[] | null;
  error?: string;
}

const initialState: ConversionRatesState = { rates: null };

export const fetchConversionRates = createAsyncThunk<FinancialConversion[], void, { rejectValue: Error }>(
  'conversionRates/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: FinancialConversion[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'entity-conversions'
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const saveConversionRates = createAsyncThunk<
  FinancialConversion[],
  { newRates: FinancialConversion[]; t: TFunction },
  { state: RootState; rejectValue: Error }
>('conversionRates/save', async ({ newRates, t }, { getState, rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = error.response?.data.errors.global;
      return (Array.isArray(errors) ? errors : [errors]).map((message: string) => t(`errors:${message}`));
    });

    const existingRates = groupByEntityId(selectConversionRates(getState()) ?? []);
    const finalRates: FinancialConversion[] = [];

    const promises = [];
    for (const [entityId, conversions] of groupByEntityId(newRates)) {
      const existing = existingRates.get(entityId);

      if (!existing) {
        // brand new entity
        promises.push(
          httpService.request<{ data: FinancialConversion[] }>({
            method: 'post',
            apiUrlKey: 'baseUrl',
            relativePath: `entity-conversions/${entityId}`,
            data: { conversions, localCurrencyScale: 0 }
          })
        );
      } else if (differs(existing, conversions)) {
        // update for this entity
        promises.push(
          httpService.request<{ data: FinancialConversion[] }>({
            method: 'patch',
            apiUrlKey: 'baseUrl',
            relativePath: `entity-conversions/${entityId}`,
            data: { conversions }
          })
        );
      } else {
        // no changes
        finalRates.push(...existing);
      }

      // remove so we could identify deleted entities
      existingRates.delete(entityId);
    }

    // remaining rates are the ones of removed entities
    for (const [entityId] of existingRates) {
      promises.push(
        httpService.request<{ data: FinancialConversion[] }>({
          method: 'delete',
          apiUrlKey: 'baseUrl',
          relativePath: `entity-conversions/${entityId}`
        })
      );
    }

    await Promise.all(promises);
    return newRates;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const conversionRatesSlice = createSlice({
  name: 'conversionRates',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addMatcher(
        (action) => action.type.match(/^conversionRates\/.+\/pending$/),
        (state: ConversionRatesState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^conversionRates\/.+\/fulfilled$/),
        (state: ConversionRatesState, action: PayloadAction<FinancialConversion[]>) => {
          state.rates = action.payload;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^conversionRates\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default conversionRatesSlice.reducer;
