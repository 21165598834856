import tokens from '../designTokens';

export const title1 = {
  ...tokens.mediumFont,
  fontSize: '1rem',
  lineHeight: '1.5rem'
};
export const title2 = {
  ...tokens.mediumFont,
  fontSize: '1.125rem',
  lineHeight: '1.5rem'
};
export const title3 = {
  ...tokens.mediumFont,
  fontSize: '1.25rem',
  lineHeight: '1.625rem'
};
export const titlelarge = {
  ...tokens.regularFont,
  fontSize: '1.25rem',
  lineHeight: '1.625rem'
};
export const titleStandard = {
  ...tokens.regularFont,
  fontSize: '1.125rem',
  lineHeight: '1.375rem'
};
export const title2Standard = {
  ...tokens.regularFont,
  fontSize: '1.125rem',
  lineHeight: '1.5rem'
};
export const titleEmphasis = {
  ...tokens.mediumFont,
  fontSize: '1.0rem',
  lineHeight: '1.375rem'
};
export const titleSmallPrimary = {
  ...tokens.mediumFont,
  fontSize: '1.0rem',
  lineHeight: '1.5rem'
};
export const body1 = {
  ...tokens.regularFont,
  fontSize: '0.875rem',
  lineHeight: '1.125rem'
};
export const body2 = {
  ...tokens.mediumFont,
  fontSize: '0.875rem',
  lineHeight: '1.125rem'
};
export const body3 = {
  ...tokens.regularFont,
  fontSize: '1.0rem',
  lineHeight: '1.375rem'
};
export const body4 = {
  ...tokens.mediumFont,
  fontSize: '1.0rem',
  lineHeight: '1.375rem'
};
export const buttonText1 = {
  ...tokens.regularFont,
  fontSize: '0.875rem',
  lineHeight: '1.125rem'
};
export const buttonText2 = {
  ...tokens.mediumFont,
  fontSize: '0.875rem',
  lineHeight: '1.125rem'
};
export const captionOverline = {
  ...tokens.mediumFont,
  fontSize: '0.75rem',
  lineHeight: '0.875rem'
};
export const productContainer = {
  ...tokens.mediumFont,
  fontSize: '0.938rem',
  lineHeight: '1.063rem'
};
export const productNameFont = {
  ...tokens.titleFont,
  fontSize: '1rem',
  lineHeight: '1.188rem'
};
