import { Box, makeStyles } from '@material-ui/core';
import { StudyTabsTable, StudyDashboardSplashScreen } from '.';
import { CenteredProgress } from '../../../components';
import { StudyTabsTableProps } from '../StudyDashboard.proptype';

const useStyles = makeStyles(() => ({
  wrapper: {
    flexDirection: 'column'
  }
}));

interface StudyTabsContentProps extends StudyTabsTableProps {
  editElement: (dataId: number) => void;
  deleteElement: (dataId: number) => void;
  isLoading: boolean;
}

export const StudyTabsContent = ({
  isLoading,
  selectedKey,
  data,
  editElement,
  deleteElement,
  onAddSingle
}: StudyTabsContentProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {isLoading ? (
        <CenteredProgress />
      ) : data?.length ? (
        <StudyTabsTable
          data={data}
          selectedKey={selectedKey}
          onEdit={async (dataId) => {
            editElement(dataId);
          }}
          onDelete={async (dataId) => {
            deleteElement(dataId);
          }}
        />
      ) : (
        <StudyDashboardSplashScreen selectedKey={selectedKey} onAddSingle={onAddSingle} />
      )}
    </Box>
  );
};
