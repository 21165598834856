import { useCallback, useEffect, useState } from 'react';
import { useResizedDetection } from './useResizedDetection';

interface useOverflowShadowsProps {
  isExpanded: boolean;
  scrollerRef: React.RefObject<HTMLDivElement>;
}

export const useOverflowShadows = ({ isExpanded, scrollerRef }: useOverflowShadowsProps) => {
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);

  const checkShadows = useCallback(() => {
    const scroller = scrollerRef.current;
    if (!scroller) {
      return;
    }

    const { scrollLeft, scrollWidth, clientWidth } = scroller;
    const maxScrollLeft = scrollWidth - clientWidth;
    const MIN_SCROLL_DIFF = 1;

    setShowLeftShadow(scrollLeft > 0);
    setShowRightShadow(scrollLeft < maxScrollLeft - MIN_SCROLL_DIFF);
  }, [scrollerRef]);

  const handleScroll = useCallback(() => {
    checkShadows();
  }, [checkShadows]);

  const { wrapperRef: tableWrapperRef } = useResizedDetection(checkShadows);

  useEffect(() => {
    checkShadows();
  }, [isExpanded, checkShadows]);

  return {
    tableWrapperRef,
    showLeftShadow,
    showRightShadow,
    handleScroll
  };
};
