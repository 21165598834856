import { styled, Switch, SwitchProps } from '@material-ui/core';
import tokens from '../../styles/designTokens';
import { asRem } from '../../utils/styling';

export const CustomSwitch = styled((props: SwitchProps) => (
  <Switch disableRipple focusVisibleClassName=".Mui-focusVisible" {...props} />
))(({ theme }) => {
  // These magnitudes are in rem units
  const height = 0.9375;
  const width = 1.875;
  const thumbMargin = 0.0625;
  const thumbDiameter = height - thumbMargin * 2;

  return {
    width: asRem(width),
    height: asRem(height),
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: asRem(thumbMargin),
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: `translateX(${asRem(width / 2)})`,
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: tokens.product100,
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: asRem(thumbDiameter),
      height: asRem(thumbDiameter)
    },
    '& .MuiSwitch-track': {
      borderRadius: asRem(height / 2),
      backgroundColor: '#c5c5c9',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  };
});
