export enum EntityDetailsTab {
  StatementOfFacts = 'statement-of-facts',
  IncomeStatement = 'income-statement',
  BalanceSheet = 'balance-sheet'
}

export interface EntityDetailsProps {
  entityId: number;
  tab: EntityDetailsTab;
}
