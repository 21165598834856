import { ComponentType, ReactNode } from 'react';
import { Country, PbaJurisdictionStatus } from '../../models';
import { JurisdictionPli } from '../JurisdictionAnalysisInfo/JurisdictionAnalysisInfo.proptype';

export enum TuneCompSearchTab {
  InititalRangeTab = 'initial-range',
  BulkRejectionsTab = 'bulk-rejections',
  FineTuningTab = 'fine-tuning',
  FinalRangeTab = 'final-range',
  GapAnalysisTab = 'gap-analysis'
}

export interface TuneCompSearchProps {
  selectedKey: string;
  tabs: Array<{
    key: string;
    label: string;
    disabled: boolean;
    content: ReactNode;
  }>;
  jurisdictionInfo?: Country;
  jurisdictionStatus?: PbaJurisdictionStatus;
  selectedPli: JurisdictionPli;
  onSelectTab: (arg: string) => void;
  updateJurisdictionStatus: (status: number) => void;
}

export interface ConnectorProps {
  component: ComponentType<TuneCompSearchProps>;
}

export interface excelReturnBody {
  body: {
    url: string;
    status: number;
  };
}

export interface GapAnalysisTabNewUIProps {
  compSearchId: number;
  jurisdictionId: number;
  pliTypeId: number;
  pliAverageTypeId: number;
  pbaId: number;
}
