import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import { CompSearchAnalysisTemplate } from '../../../models';
import { fetchSavedAnalysisTemplates } from '../../../redux/profitBasedAnalyses';
import { selectSavedAnalysisTemplates, selectWorkingContainer } from '../../../selectors';
import { title1, title2 } from '../../../styles/typography';
import { TPModal } from '../../TPModal';
import { LoadDeleteAnalysisTemplateModalProps } from '../JurisdictionAnalysisInfo.proptype';

const useStyles = makeStyles((theme) => ({
  AnalysisTemplateModalForm: {
    width: '100%',
    '& .MuiFormControl-root': {
      width: '100% !important'
    }
  },
  AnalysisTemplateModalContainer: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: '1.0rem',
    alignItems: 'center',
    '& p': {
      width: '50%',
      whiteSpace: 'nowrap'
    }
  },
  AnalysisTemplateModalDoneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  AnalysisTemplateModalDisabledButton: {
    backgroundColor: theme.palette.action.disabledBackground
  },
  AnalysisTemplateModalSelect: {
    width: 'inherit'
  }
}));

export const LoadDeleteAnalysisTemplateModal = ({
  isOpen,
  title,
  onDone,
  onClose
}: LoadDeleteAnalysisTemplateModalProps) => {
  const analysisTemplates = useSelector(selectSavedAnalysisTemplates);
  const [selectedAnalysisTemplate, setSelectedAnalysisTemplate] = useState<CompSearchAnalysisTemplate | null>(null);

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const container = useSelector(selectWorkingContainer);

  useEffect(() => {
    if (!analysisTemplates && container?.tenantId) {
      dispatch(fetchSavedAnalysisTemplates(container?.tenantId));
    }
  }, [dispatch, analysisTemplates, container?.tenantId]);

  const noAnalysisTemplates = analysisTemplates?.length === 0;

  const handleSelectAnalysisTemplate = (compSearchLocalLibraryId: number) => {
    const analysisTemplate = analysisTemplates?.find(
      (analysisTemplate) => analysisTemplate.compSearchLocalLibraryId === compSearchLocalLibraryId
    );
    if (analysisTemplate) setSelectedAnalysisTemplate(analysisTemplate);
  };

  const handleClose = () => {
    setSelectedAnalysisTemplate(null);
    onClose();
  };

  return (
    <TPModal
      maxWidth="sm"
      isOpen={isOpen}
      title={<Typography style={title2}>{title}</Typography>}
      actions={[
        { name: t('action-cancel'), handler: handleClose },
        {
          name: t('action-done'),
          className: noAnalysisTemplates
            ? classes.AnalysisTemplateModalDisabledButton
            : classes.AnalysisTemplateModalDoneButton,
          disabled: noAnalysisTemplates,
          handler: () => {
            if (selectedAnalysisTemplate) {
              onDone(selectedAnalysisTemplate);
              setSelectedAnalysisTemplate(null);
            }
          }
        }
      ]}
      onClose={onClose}
    >
      <FormControl size="medium" variant="outlined" className={classes.AnalysisTemplateModalForm}>
        <Box className={classes.AnalysisTemplateModalContainer}>
          <Typography style={{ ...title1 }}>{t(`analysis:label-analysis-template-name`)}</Typography>
          <Select
            className={classes.AnalysisTemplateModalSelect}
            value={selectedAnalysisTemplate ? selectedAnalysisTemplate?.compSearchLocalLibraryId : 1}
            disabled={noAnalysisTemplates}
            onChange={(event) => {
              handleSelectAnalysisTemplate(Number(event.target.value));
            }}
          >
            {analysisTemplates && analysisTemplates.length > 0 ? (
              analysisTemplates.map(({ compSearchLocalLibraryId, compSearchName }) => (
                <MenuItem key={compSearchLocalLibraryId} value={compSearchLocalLibraryId}>
                  {compSearchName}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled selected value={1}>
                {t(`analysis:label-analysis-template-name-no-analysis`)}
              </MenuItem>
            )}
          </Select>
        </Box>
      </FormControl>
    </TPModal>
  );
};
