import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@material-ui/icons';
import { CustomIconMenu, ConfirmationModal } from '../../../components';
import { Study } from '../../../models';
import { TrashCanIcon } from '../../../svgs';
import { hasEditAccess } from '../../../utils';
import { AnalysisEventsProps } from '../Analysis.proptype';

interface MenuProps extends Omit<AnalysisEventsProps, 'onAddSingle'> {
  study: Study;
  onNavigate: (path: string) => void;
}

export const AnalysisMenu = ({ study, onNavigate, onEdit, onDelete }: MenuProps) => {
  const { studyId, name } = study;
  const { t } = useTranslation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const items = [
    {
      text: t('analysis:action-view-analysis-group'),
      handleOnClick: () => {
        onNavigate(`/analysis/${studyId!}`);
      }
    }
  ];

  if (hasEditAccess()) {
    items.push(
      {
        text: t('analysis:action-edit'),
        handleOnClick: () => {
          onEdit(studyId!);
        }
      },
      {
        text: t('analysis:action-delete-study'),
        handleOnClick: () => {
          setIsConfirmationOpen(true);
        }
      }
    );
  }

  const handleDeletion = (confirmed: boolean) => {
    setIsConfirmationOpen(false);
    if (confirmed && studyId) {
      onDelete(studyId);
    }
  };

  return (
    <>
      <CustomIconMenu Icon={MoreHoriz} menuItems={items} />
      {isConfirmationOpen && (
        <ConfirmationModal
          open
          title={t('analysis:action-delete-study')}
          subtitle={t('analysis:title-delete', { name })}
          text={t('analysis:message-delete')}
          HeaderIcon={TrashCanIcon}
          handleClose={handleDeletion}
        />
      )}
    </>
  );
};
