import { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { parse } from 'date-fns';
import { dateFromUserInputToOurFormat, getDateStringFromDateObject } from '../../utils/dates';

const useStyles = makeStyles(() => ({
  inputFieldPicker: {
    width: '100%',
    marginTop: 0
  }
}));

export const SimpleDatePicker = ({
  defaultDate,
  onChange
}: {
  defaultDate: string;
  onChange: (date: string | null) => void;
}) => {
  const classes = useStyles();
  const [currentlyChosenDate, setCurrentlyChosenDate] = useState<Date>(new Date(defaultDate.replace(/-/g, '/')));

  const handlePickerDateChange = (newDate: MaterialUiPickersDate) => {
    if (newDate) {
      const dateInOurFormat: string | null = getDateStringFromDateObject(newDate);
      setCurrentlyChosenDate(newDate);
      onChange(dateInOurFormat);
    } else {
      onChange(null);
    }
  };

  const handleKeyboardDateChange = (event: any) => {
    const parsedDate = parse(event.target.value, 'dd/MM/yyyy', new Date());
    setCurrentlyChosenDate(parsedDate);
    const dateInOurFormat: string = dateFromUserInputToOurFormat(event.target.value);
    onChange(dateInOurFormat);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        size="small"
        variant="inline"
        inputVariant="outlined"
        className={classes.inputFieldPicker}
        placeholder="DD/MM/YYYY"
        margin="normal"
        id="simple-date-picker"
        data-testid="simple-date-picker-input"
        format="dd/MM/yyyy"
        value={currentlyChosenDate}
        error={false}
        helperText=""
        onChange={handlePickerDateChange}
        onKeyUp={handleKeyboardDateChange}
      />
    </MuiPickersUtilsProvider>
  );
};
