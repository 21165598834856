import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { ConnectorProps, FunctionalAnalysisObjectTypeEnum } from './TbaFunctionalAnalysis.proptype';
import {
  AreaIds,
  TBAFunctionalAnalysisCharacteristic
} from '../../components/FunctionalAnalysis/FunctionalAnalysisTable.proptype';
import { SearchFunctionalAnalysisWizard } from '../../components/FunctionalAnalysis/SearchFunctionalAnalysisWizard';
import { StandardizedFunctionalAnalysisWizard } from '../../components/FunctionalAnalysis/StandardizedFunctionalAnalysisWizard';
import { Container, Study, TransactionBasedAnalysis } from '../../models';
import { resetFAContributionData, resetSelectedDiscussionText } from '../../redux/profitBasedAnalyses';
import { fetchStudies } from '../../redux/studies';
import {
  tbafetchFunctionalAnalysisCharacteristics,
  createTBAFunctionalAnalysisCharacteristic,
  updateTBAFunctionalAnalysisCharacteristic,
  reinitiaizeTBAtoFionaDefualts,
  deleteTBAFunctionalAnalysisCharacteristic,
  fetchTBAs,
  updateTBAFunctionalAnalysisNarrative
} from '../../redux/transactionBasedAnalyses';
import { selectStudiesList, selectTBAFunctionalAnalysisCharacteristics, selectWorkingContainer } from '../../selectors';
import { AppDispatch } from '../../store';
import { decodeTokens, getAuthInfo, hasEditAccess } from '../../utils';
import { logGoogleAnalyticsTimeSpent } from '../../utils/sendGoogleAnalyticaEvent';
import { tba } from '../AnalysisHeader/AnalysisHeader.testdata';
import { TimeTrackingEvents } from '../MethodEvaluation/hooks/useTrackingTime';

type functionalAnalysisCharacteristicsKeys = 'Functions' | 'Risks' | 'Assets';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const tbaId = Number(useParams<{ tbaId: string }>().tbaId);
  const [selectedAnalysis, setSelectedAnalysis] = useState<TBAFunctionalAnalysisCharacteristic>();
  const [currentSection, setCurrentSection] = useState<number>(1);
  const functionalAnalysisCharacteristics = useSelector(selectTBAFunctionalAnalysisCharacteristics);
  const [FASingleApplyStatus, setFASingleApplyStatus] = useState<boolean>(false);
  const [FABulkApplyStatus, setFABulkApplyStatus] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const studies: Study[] | null = useSelector(selectStudiesList);
  const currentContainer: Container | undefined = useSelector(selectWorkingContainer);
  const [sortedStudies, setSortedStudies] = useState<Study[] | null>(null);
  const [TBAs, setTBAs] = useState<TransactionBasedAnalysis[] | null>(null);
  const [sortedTBAs, setSortedTBAs] = useState<TransactionBasedAnalysis[] | null>(null);
  const [studyValue, setStudyValue] = useState<Study | null>(null);
  const [tbaValue, setTBAValue] = useState<TransactionBasedAnalysis | null>(null);
  const [showTBADropdown, setShowTBADropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = getAuthInfo();
  const userData = decodeTokens(authToken);

  useEffect(() => {
    if (studies === null) {
      void dispatch(fetchStudies());
    } else {
      const studiesCopy: Study[] = [...studies];
      studiesCopy.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }

        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }

        return 0;
      });
      setSortedStudies(studiesCopy);
    }
  }, [studies, dispatch]);

  useEffect(() => {
    if (TBAs) {
      const tbaCopy: TransactionBasedAnalysis[] = [...TBAs];
      tbaCopy.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }

        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }

        return 0;
      });
      setSortedTBAs(tbaCopy);
    }
  }, [TBAs]);

  const handleStudySelect = async (newValue: Study | null) => {
    setStudyValue(newValue);

    if (newValue?.studyId) {
      const TBAs: any = unwrapResult(await dispatch(fetchTBAs(newValue.studyId)));
      setTBAs(TBAs);
      setShowTBADropdown(true);
    } else {
      setShowTBADropdown(false);
    }

    setTBAValue(null);
  };

  const handleTBASelect = (newValue: TransactionBasedAnalysis | null) => {
    setTBAValue(newValue);
  };

  const apply = async () => {
    if (currentContainer && studyValue?.studyId && tbaId && tba?.study?.studyId) {
      setIsLoading(true);
      const applyParams: any = {
        tbaId,
        type: FunctionalAnalysisObjectTypeEnum.TBA,
        container: currentContainer,
        sourceId: tbaValue?.tbaId,
        uncontrolled: false
      };
      await dispatch(updateTBAFunctionalAnalysisNarrative({ params: applyParams }));

      const promises: Array<Promise<PayloadAction<unknown>>> = [];
      Object.values(AreaIds).forEach(async (id) => {
        if (Number.isInteger(id)) {
          promises.push(
            dispatch(
              tbafetchFunctionalAnalysisCharacteristics({
                functionalAnalysisId: tbaId,
                areaId: id as number,
                FATypeId: 1
              })
            )
          );
        }
      });

      await Promise.all(promises);
      setIsLoading(false);
      closeDrawer();
      unsetAnalysis();
    }
  };

  useEffect(() => {
    if (functionalAnalysisCharacteristics && selectedAnalysis) {
      Object.keys(functionalAnalysisCharacteristics).forEach((key) => {
        functionalAnalysisCharacteristics[key as functionalAnalysisCharacteristicsKeys].forEach(
          (functionalAnalysisCharacteristic: TBAFunctionalAnalysisCharacteristic) => {
            if (
              functionalAnalysisCharacteristic.tbaFunctionalAnalysisDataId ===
              selectedAnalysis.tbaFunctionalAnalysisDataId
            ) {
              setSelectedAnalysis(functionalAnalysisCharacteristic);
            }
          }
        );
      });
    }
  }, [functionalAnalysisCharacteristics, selectedAnalysis]);

  const editFunctionalAnalysisCharacteristic = async (
    characteristic: TBAFunctionalAnalysisCharacteristic,
    areaId?: number
  ) => {
    if (!hasEditAccess()) return;
    await dispatch(
      updateTBAFunctionalAnalysisCharacteristic({
        characteristicId: characteristic.tbaFunctionalAnalysisDataId ?? selectedAnalysis?.tbaFunctionalAnalysisDataId,
        characteristic: {
          container: currentContainer,
          ...characteristic
        }
      })
    );
    if (areaId) {
      await dispatch(
        tbafetchFunctionalAnalysisCharacteristics({
          functionalAnalysisId: tbaId,
          areaId,
          FATypeId: 1
        })
      );
    }
  };

  const createNewCharacteristic = async (characteristicName: string, areaId: number) => {
    if (!hasEditAccess()) return;
    const createdCharacteristic = await dispatch(
      createTBAFunctionalAnalysisCharacteristic({
        functionalAnalysisId: tbaId,
        areaId,
        characteristic: { characteristicName, container: currentContainer }
      })
    );
    if (createdCharacteristic?.payload) {
      setSelectedAnalysis({
        characteristicName,
        discussion: '',
        srcContribution: (createdCharacteristic.payload as TBAFunctionalAnalysisCharacteristic).srcContribution,
        destContribution: (createdCharacteristic.payload as TBAFunctionalAnalysisCharacteristic).destContribution,
        tbaFunctionalAnalysisDataId: (createdCharacteristic.payload as TBAFunctionalAnalysisCharacteristic)
          .tbaFunctionalAnalysisDataId,
        sortOrder: (createdCharacteristic.payload as TBAFunctionalAnalysisCharacteristic).sortOrder
      });
    }

    await dispatch(
      tbafetchFunctionalAnalysisCharacteristics({
        functionalAnalysisId: tbaId,
        areaId,
        FATypeId: 1
      })
    );
  };

  const deleteFunctionalAnalysisCharacteristicRow = async (characteristicId: number, areaId: number) => {
    if (!hasEditAccess()) return;
    await dispatch(deleteTBAFunctionalAnalysisCharacteristic(characteristicId));
    await dispatch(
      tbafetchFunctionalAnalysisCharacteristics({
        functionalAnalysisId: tbaId,
        areaId,
        FATypeId: 1
      })
    );
    setSelectedAnalysis((undefined as unknown) as TBAFunctionalAnalysisCharacteristic);
  };

  const reinitialize = async () => {
    if (tbaId) {
      void (await dispatch(reinitiaizeTBAtoFionaDefualts({ functionalAnalysisId: tbaId })));
    }
  };

  useEffect(() => {
    /* void dispatch(
      initializeTBAFunctionalAnalysis({
        functionalAnalysisId: tbaId,
        FATypeId: 1
      })
    ); */
    Object.values(AreaIds).forEach(async (id) => {
      if (Number.isInteger(id)) {
        await dispatch(
          tbafetchFunctionalAnalysisCharacteristics({
            functionalAnalysisId: tbaId,
            areaId: id as number,
            FATypeId: 1
          })
        );
      }
    });
  }, [dispatch, tbaId]);

  const unsetAnalysis = () => {
    setSelectedAnalysis((null as unknown) as TBAFunctionalAnalysisCharacteristic);
  };

  const handleBulkApply = async () => {
    if (functionalAnalysisCharacteristics) {
      await reinitialize();
      await fetchNewSections();
      void dispatch(resetFAContributionData());
      setFABulkApplyStatus(false);
    }

    setIsDrawerOpen(false);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const fetchNewSections = async () => {
    if (selectedAnalysis?.functionalCharacteristicType) {
      dispatch(
        resetSelectedDiscussionText({ FAType: currentSection, name: selectedAnalysis?.characteristicName ?? '' })
      );
    }

    Object.values(AreaIds).forEach((id) => {
      if (tbaId && Number.isInteger(id)) {
        void dispatch(
          tbafetchFunctionalAnalysisCharacteristics({
            functionalAnalysisId: tbaId,
            areaId: Number(id),
            FATypeId: 1
          })
        );
      }
    });
  };

  const trackGATime = () => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsTimeSpent({
      title: TimeTrackingEvents.CompSearchFunctionalAnalysis,
      page: '/custom/modal/CompSearchFunctionalAnalysis',
      user_id: userData?.userId ?? '',
      container_id: currentContainer?.containerId
    });
  };

  const selectedContent = [
    <StandardizedFunctionalAnalysisWizard
      key={0}
      FASingleApplyStatus={FASingleApplyStatus}
      FABulkApplyStatus={FABulkApplyStatus}
      selectedAnalysis={selectedAnalysis}
      onApply={async () => {
        // Todo: Refactor this function: DUO-1610
        // Todo: Include updated TBAFAcharacteristic once BE is updated: DUO-1611
        setFASingleApplyStatus(true);
        if (selectedAnalysis) {
          dispatch(
            resetSelectedDiscussionText({ FAType: currentSection, name: selectedAnalysis?.characteristicName ?? '' })
          );
          await editFunctionalAnalysisCharacteristic(
            {
              discussion: 'This is default discussion - need to update in the backend',
              srcContribution: selectedAnalysis.srcContribution,
              destContribution: selectedAnalysis.destContribution,
              importance: 3
            } as any,
            currentSection
          );
        }

        setIsDrawerOpen(false);
        setFASingleApplyStatus(false);
      }}
      onBulkApply={() => {
        setFABulkApplyStatus(true);
        unsetAnalysis();
        void handleBulkApply();
      }}
    />,
    <SearchFunctionalAnalysisWizard
      key={1}
      studyValue={studyValue}
      sortedStudies={sortedStudies}
      handleStudySelect={handleStudySelect}
      isLoading={isLoading}
      apply={apply}
      showPBADropdown={showTBADropdown}
      sortedPBAs={sortedTBAs}
      pbaValue={tbaValue}
      handlePBASelect={handleTBASelect}
      isPba={false}
    />
  ];

  return (
    <Component
      functionalAnalysisCharacteristics={functionalAnalysisCharacteristics}
      editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
      selectedAnalysis={selectedAnalysis}
      setSelectedAnalysis={setSelectedAnalysis}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
      createNewCharacteristic={createNewCharacteristic}
      deleteFunctionalAnalysisCharacteristicRow={deleteFunctionalAnalysisCharacteristicRow}
      selectedContent={selectedContent}
      setIsDrawerOpen={setIsDrawerOpen}
      isDrawerOpen={isDrawerOpen}
      trackGATime={trackGATime}
    />
  );
};

export default Connector;
