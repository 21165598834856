import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@material-ui/core';
import { ConfirmationPinnedComparableModalProps } from './ConfirmationPinnedComparableModal/ConfirmationPinnedComparableModal.proptype';
import { useStyles } from './ConfirmationPinnedComparableModal/ConfirmationPinnedComparableModal.styles';
import { WarningIcon } from '../../../../svgs';
import { TPModal, TPModalActions } from '../../../TPModal';

export const CannotAddCompanyModal = ({ isOpen, onClose, isoCodes }: ConfirmationPinnedComparableModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const title = (
    <span className={classes.modalTitleWrapper}>
      <WarningIcon className={classes.warningIcon} />
      <Typography className={classes.title}>{t(`analysis:title-pinned-comparables-confirmation`)}</Typography>
    </span>
  );
  const actionButtons: TPModalActions[] = [
    {
      name: t('action-cancel'),
      handler: onClose,
      className: classes.cancelButton,
      color: 'inherit',
      variant: 'text'
    },
    {
      name: t('action-confirm'),
      handler: onClose,
      color: 'primary',
      variant: 'contained'
    }
  ];

  const countryIsoCode = isoCodes?.length > 0 ? isoCodes[0].isoCode : '';

  return (
    <TPModal
      isOpen={isOpen}
      maxWidth="sm"
      title={title}
      actions={actionButtons}
      containerStyle={classes.modalContent}
      onClose={onClose}
    >
      <Box>
        <Typography className={classes.description}>
          {t('analysis:label-pinned-comparables-cannot-add-company-text', {
            isoCode: countryIsoCode
          })}
        </Typography>
      </Box>
    </TPModal>
  );
};
