import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import { TFunction } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StudyTabsContent, StudyDashboardWithTabsProps } from './components';
import { StudyDashboardTab, StudyDashboardProps, TbaParameterIdMap } from './StudyDashboard.proptype';
import {
  CreateTbaApiData,
  PrimaryFunction,
  ProfitBasedAnalysis,
  Transaction,
  TransactionBasedAnalysis
} from '../../models';
import { fetchEntities } from '../../redux/entities';
import {
  fetchPBAs,
  savePBA,
  deletePBA,
  createTestedParty,
  updatePbaTransactions
} from '../../redux/profitBasedAnalyses';
import { fetchStudies } from '../../redux/studies';
import { createValueAddedDistributor, fetchCurrentDistributor } from '../../redux/testedParty';
import {
  fetchTBAs,
  saveTBA,
  deleteTBA,
  createTBA,
  EditSearchStrategyParams,
  editTbaSearchStrategy,
  saveTbaFinancialInfoParams,
  saveTbaFinancialInfo,
  TbaControlledParameter
} from '../../redux/transactionBasedAnalyses';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectEntitiesList,
  selectPrimaryFunctions,
  selectPBAsList,
  selectStudiesList,
  selectStudy,
  selectTBAsList,
  selectTransactionsList,
  selectUPECurrency,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { getAppConfig, handleRoleRedirect } from '../../utils';
import { ProfitBasedAnalysisAddModal } from '../NewAnalysisModal';
import { SelectedTransaction } from '../NewAnalysisModal/CreatePBAModal.proptypes';
import { CreateTBAModal } from '../NewAnalysisModal/CreateTBAModal';
import {
  FinancialInfoFieldValues,
  FinancialInfoValues,
  MethodTypes,
  TBAInputs,
  TransactionTypes
} from '../NewAnalysisModal/CreateTBAModal.proptypes';
import { StudyTabsEditModal } from '../StudyTabsEditModal';
import { CharacterizationTypeEnum } from '../TestedPartyDistributor/TestedPartyDistributor.proptype';

interface ConnectorProps extends StudyDashboardProps {
  component: ComponentType<StudyDashboardWithTabsProps>;
}

const { ProfitBasedAnalysisTab, TransactionBasedAnalysisTab } = StudyDashboardTab;

const getPrimaryFunctionOptions = (primaryFunctions: PrimaryFunction[], t: TFunction) =>
  primaryFunctions.map((primaryFunction: PrimaryFunction) => ({
    title: t(`entities:primary-function-${primaryFunction.name}`),
    value: primaryFunction.primaryFunctionId
  }));

type TbaTransactionTypeMapType = {
  [key in TransactionTypes]: number;
};

// @todo: fetch this from Database. See DUO-1276 & DUO-1277
export const transactionTypeMap: TbaTransactionTypeMapType = {
  'Tangible Goods': 1,
  'Intangible Goods': 2,
  Services: 3,
  Loans: 4,
  '': 0
};

// @todo: fetch this from Database. See DUO-1276 & DUO-1277
export const tbaParameterIdMap: TbaParameterIdMap = {
  'Unit Price': 1,
  Royalty: 2,
  Interest: 3,
  Service: 4,
  'COGS (related) CP': 6,
  'Resale Price': 8,
  'COGS (unrelated)': 10
};

const Connector = ({ studyId, component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const { xbsEnvironmentShort } = getAppConfig();
  const [selectedKey, setSelectedKey] = useState(ProfitBasedAnalysisTab);
  const [isNewPBAModalOpen, setIsNewPBAModalOpen] = useState(false);
  const [isNewTBAModalOpen, setIsNewTBAModalOpen] = useState(false);
  const [isStudyTabsModalOpen, setIsStudyTabsModalOpen] = useState(false);
  const [selectedElement, setSelectedElement] = useState<ProfitBasedAnalysis | TransactionBasedAnalysis | null>(null);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const primaryFunctions = useSelector(selectPrimaryFunctions);
  const transactions = useSelector(selectTransactionsList);
  const entities = useSelector(selectEntitiesList);
  const upeCurrency = useSelector(selectUPECurrency);
  const studies = useSelector(selectStudiesList);
  const study = useSelector(selectStudy(studyId));
  const isPBA = selectedKey === ProfitBasedAnalysisTab;
  const pbas = useSelector(selectPBAsList);
  const tbas = useSelector(selectTBAsList);
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (transactions === null) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    setLoading(true);
    if (studies === null) {
      void dispatch(fetchStudies());
    }
  }, [studies, dispatch]);

  useEffect(() => {
    if (isLoading) {
      void dispatch(fetchPBAs(studyId));
      void dispatch(fetchTBAs(studyId));
    }
  }, [isLoading, studyId, dispatch]);

  useEffect(() => {
    if ((pbas && isPBA) || (tbas && !isPBA)) {
      setLoading(false);
    }
  }, [pbas, isPBA, tbas]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleClose = () => {
    setSelectedElement(null);
    setIsStudyTabsModalOpen(false);
    setIsNewPBAModalOpen(false);
    setIsNewTBAModalOpen(false);
  };

  const handleSubmitNewTBA = async (tbaInfo: TBAInputs) => {
    const {
      financialInfo,
      name,
      searchStrategy,
      selectedTransactionType,
      selectedMethod,
      selectedTransactions
    }: {
      financialInfo: FinancialInfoValues;
      name: string;
      searchStrategy: string;
      selectedTransactionType: TransactionTypes;
      selectedMethod: MethodTypes | null;
      selectedTransactions: SelectedTransaction[];
    } = tbaInfo;

    type TbaEvanuationMethodMapType = {
      [key in MethodTypes]: number;
    };

    // @todo: fetch this from Database. See DUO-1276 & DUO-1277
    const tbaEvaluationMethodMap: TbaEvanuationMethodMapType = {
      '': 0,
      'CUT/CUP': 1,
      RSP: 2,
      CP: 3
    };

    if (selectedMethod && selectedTransactions && study && container) {
      const data: CreateTbaApiData = {
        exclude: false,
        tbaEvaluationMethod: {
          tbaEvaluationMethodId: tbaEvaluationMethodMap[selectedMethod],
          name: selectedMethod
        },
        transactionType: {
          transactionTypeId: transactionTypeMap[selectedTransactionType],
          name: selectedTransactionType
        },
        study,
        primaryLegalEntity: study.primaryEntity,
        container,
        name
      };

      // create TBA
      const result: TransactionBasedAnalysis = unwrapResult(await dispatch(createTBA({ tba: data, t })));
      const { tbaId } = result;
      const createSearchStrategyParams: EditSearchStrategyParams = {
        tbaId,
        strategy: searchStrategy,
        container
      };

      // save Search Strategy
      unwrapResult(await dispatch(editTbaSearchStrategy(createSearchStrategyParams)));

      // @Todo: figure out how to save transactions if the types are CUT/CUP & 'Tangible Goods' (or other derived)
      const skipTbaControlledParameters = selectedMethod === 'CUT/CUP' && selectedTransactionType === 'Tangible Goods';

      const tbaControlledParameters: TbaControlledParameter[] = skipTbaControlledParameters
        ? []
        : Object.keys(financialInfo)
            .filter((propertyName: any) => Boolean(tbaParameterIdMap[propertyName as FinancialInfoFieldValues]))
            .map((propertyName: any) => {
              const regex = /[\s$%,]/gi;
              const parameterValue = Number(
                financialInfo[propertyName as FinancialInfoFieldValues]?.replace(regex, '')
              );
              const tbaParameterId: number = tbaParameterIdMap[propertyName as FinancialInfoFieldValues] ?? 0;
              return {
                parameterValue,
                tba: {
                  exclude: false,
                  tbaEvaluationMethod: {},
                  transactionType: {},
                  tbaId
                },
                tbaParameter: {
                  tbaParameterId,
                  type: propertyName,
                  description: propertyName,
                  calculated: false,
                  optional: false
                }
              };
            });

      const addTransactionIds: number[] = selectedTransactions.map((tr) => tr.transactionId);

      const saveFinancialInfoPayload: saveTbaFinancialInfoParams = {
        tbaId,
        addTransactionIds,
        container,
        removeTransactionIds: [],
        tbaControlledParameters
      };

      unwrapResult(await dispatch(saveTbaFinancialInfo(saveFinancialInfoPayload)));
      void dispatch(fetchTBAs(studyId));
    }
  };

  const handleSubmit = async (data: ProfitBasedAnalysis | TransactionBasedAnalysis) => {
    const { name } = data;
    if (isPBA) {
      const pba = { ...selectedElement, name, description: selectedElement?.description ?? '' };
      unwrapResult(await dispatch(savePBA({ pba, t })));
      void dispatch(fetchPBAs(studyId));
    } else {
      const tba = { ...selectedElement, name, description: selectedElement?.description ?? '' };
      unwrapResult(await dispatch(saveTBA({ tba, t })));
      void dispatch(fetchTBAs(studyId));
    }

    handleClose();
  };

  const handleSubmitNewPBA = async (data: any) => {
    const {
      name,
      otherPrimaryFunction,
      primaryFunction,
      testedPartyJustification,
      testedPartyName,
      segmentType,
      testedParty
    } = data;
    const pba = {
      name,
      description: '',
      study: { studyId: study?.studyId },
      primaryLegalEntity: { entityId: testedParty?.entityId },
      method: 1
    };
    const createdPbaInfo = unwrapResult(await dispatch(savePBA({ pba, t })));
    const { pbaId } = createdPbaInfo;
    const testedPartyData = {
      justification: testedPartyJustification,
      name: testedPartyName,
      otherPrimaryFunction,
      primaryFunction: { primaryFunctionId: primaryFunction },
      segmentType: segmentType.value
    };
    await dispatch(createTestedParty({ pbaId, testedParty: testedPartyData }));

    const isDistributor = PrimaryFunctions.ByType.Distributor.includes(primaryFunction);
    if (isDistributor) {
      const createValueAddedDistributorPayload = {
        characterizationType: CharacterizationTypeEnum.FunctionsPerformed,
        characterizationFunctionsPerformed: [
          {
            state: false,
            functionPerformed: {
              functionPerformedId: CharacterizationTypeEnum.FunctionsPerformed
            }
          }
        ]
      };
      await dispatch(createValueAddedDistributor({ pbaId, payload: createValueAddedDistributorPayload }));
      void dispatch(fetchCurrentDistributor(pbaId));
    }

    const promises: any = [];

    data.transactions.forEach((transaction: Transaction) => {
      const params = { transactionId: transaction.transactionId, pbaId };
      promises.push(dispatch(updatePbaTransactions(params)));
    });

    await Promise.all(promises);

    void dispatch(fetchPBAs(studyId));
    handleClose();
  };

  const handleEdit = async (dataId: number) => {
    if (isPBA) {
      const pba = pbas?.find((s) => s.pbaId === dataId) ?? null;
      setSelectedElement(pba);
      setIsStudyTabsModalOpen(true);
    } else {
      const tba = tbas?.find((s) => s.tbaId === dataId) ?? null;
      setSelectedElement(tba);
      setIsStudyTabsModalOpen(true);
    }
  };

  const handleDelete = async (dataId: number) => {
    if (isPBA) {
      unwrapResult(await dispatch(deletePBA(dataId)));
      void dispatch(fetchPBAs(studyId));
    } else {
      unwrapResult(await dispatch(deleteTBA(dataId)));
      void dispatch(fetchTBAs(studyId));
    }
  };

  const tabs = [
    {
      key: ProfitBasedAnalysisTab,
      label: ProfitBasedAnalysisTab,
      disabled: false,
      content: (
        <StudyTabsContent
          isLoading={isLoading}
          data={pbas}
          editElement={handleEdit}
          deleteElement={handleDelete}
          selectedKey={selectedKey}
          onAddSingle={() => {
            setIsNewPBAModalOpen(true);
          }}
        />
      ),
      createButtonText: t('analysis:action-new-profit-based-analysis')
    },
    {
      key: TransactionBasedAnalysisTab,
      label: TransactionBasedAnalysisTab,
      disabled: true,
      content: (
        <StudyTabsContent
          isLoading={isLoading}
          data={tbas}
          editElement={handleEdit}
          deleteElement={handleDelete}
          selectedKey={selectedKey}
          onAddSingle={() => {
            if (
              flags.createNewTbaButtonEnabled &&
              (flags.createNewTbaButtonEnabled === true ||
                flags.createNewTbaButtonEnabled.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
                flags.createNewTbaButtonEnabled.includes(`${String(xbsEnvironmentShort)}-*`))
            ) {
              setIsNewTBAModalOpen(true);
            } else {
              handleRoleRedirect(
                `${window.location.origin}/legacy/local-files/study/${String(studyId)}/redirect;activeTab=2`
              );
            }
          }}
        />
      ),
      createButtonText: t('analysis:action-new-transaction-based-analysis')
    }
  ];

  return (
    <>
      <Component
        title={t('analysis:title-dashboard')}
        study={study}
        pbas={pbas}
        tbas={tbas}
        selectedKey={selectedKey}
        tabs={tabs}
        onSelectTab={(tab: number) => {
          setSelectedKey(tabs[tab].key);
        }}
        onAddSingle={() => {
          setIsNewPBAModalOpen(true);
        }}
        onCreateTba={() => {
          if (
            flags.createNewTbaButtonEnabled &&
            (flags.createNewTbaButtonEnabled === true ||
              flags.createNewTbaButtonEnabled.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
              flags.createNewTbaButtonEnabled.includes(`${String(xbsEnvironmentShort)}-*`))
          ) {
            setIsNewTBAModalOpen(true);
          } else {
            handleRoleRedirect(
              `${window.location.origin}/legacy/local-files/study/${String(studyId)}/redirect;activeTab=2`
            );
          }
        }}
      />
      {isNewPBAModalOpen && (
        <ProfitBasedAnalysisAddModal
          entities={entities}
          upeCurrency={upeCurrency}
          study={study}
          transactions={transactions}
          primaryFunctionOptions={getPrimaryFunctionOptions(primaryFunctions, t)}
          onClose={handleClose}
          onSubmit={handleSubmitNewPBA}
        />
      )}
      {isNewTBAModalOpen && (
        <CreateTBAModal
          upeCurrency={upeCurrency}
          study={study}
          transactions={transactions}
          onClose={handleClose}
          onSubmit={handleSubmitNewTBA}
        />
      )}
      {isStudyTabsModalOpen && (
        <StudyTabsEditModal element={selectedElement} isPBA={isPBA} onClose={handleClose} onSubmit={handleSubmit} />
      )}
    </>
  );
};

export default Connector;
