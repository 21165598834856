import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { TbaAnalysisSideMenu } from './component';
import { TbaJurisdictionStatus } from '../../models';
import { fetchTBAs } from '../../redux/transactionBasedAnalyses';
import { selectCurrentTBA, selectTBA } from '../../selectors';
import { AppDispatch } from '../../store';

const Connector = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch('/analysis/:studyId/tba-dashboard/:tbaId');
  const history = useHistory();
  const studyId: any = Number.parseInt(match.params?.studyId, 10);
  const tbaId = Number.parseInt(match.params?.tbaId, 10);
  const selectedTba = useSelector(selectTBA(tbaId));
  const currentTBA = useSelector(selectCurrentTBA);
  const dispatch = useDispatch<AppDispatch>();
  const [isTbaFetching, setIsTbaFetching] = useState<boolean>(false);

  const jurisdictions: TbaJurisdictionStatus[] | undefined = selectedTba?.jurisdictionStatuses?.map(
    (jurisdiction) => jurisdiction
  );
  const transaction = currentTBA?.transactions && currentTBA?.transactions[0];
  const source = transaction?.legalEntityTransactions.find((legalTx) => legalTx.isSource)?.entity;
  const dest = transaction?.legalEntityTransactions.find((legalTx) => !legalTx.isSource)?.entity;

  const legalEntities = [currentTBA?.primaryLegalEntity, currentTBA?.secondaryLegalEntity];

  const sourceJurisdiction = legalEntities.find((v) => v?.entityId === source?.entityId)?.taxJurisdiction?.isoCode;
  const destinationJurisdiction = legalEntities.find((v) => v?.entityId === dest?.entityId)?.taxJurisdiction?.isoCode;

  useEffect(() => {
    setIsTbaFetching(true);
    void dispatch(fetchTBAs(Number(studyId))).finally(() => {
      setIsTbaFetching(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jurisdictions?.length === 0) {
      setIsTbaFetching(true);
      void dispatch(fetchTBAs(Number(studyId))).finally(() => {
        setIsTbaFetching(false);
      });
    }
  }, [dispatch, jurisdictions?.length, studyId]);

  return (
    <TbaAnalysisSideMenu
      sections={[
        {
          title: t('analysis:title-section-setup'),
          items: [
            {
              path: `/analysis/${String(studyId)}/tba-dashboard/${String(tbaId)}/tba-transaction-overview`,
              text: t('analysis:title-section-item-transaction-overview'),
              disabled: false,
              showProgressIcon: true,
              // TODO: make it dynamic
              isComplete: false
            },
            {
              path: `/analysis/${String(studyId)}/tba-dashboard/${String(tbaId)}/functional-analysis`,
              text: t('analysis:title-section-item-tested-functional-analysis'),
              disabled: false,
              showProgressIcon: false,
              isComplete: false
            }
          ]
        },
        {
          title: t('analysis:title-section-comparable-search'),
          items: [
            {
              path: `/analysis/${String(studyId)}/tba-dashboard/${String(tbaId)}/uncontrolled-transactions`,
              text: t('analysis:title-section-item-uncontrolled-transactions'),
              disabled: false,
              showProgressIcon: true,
              // TODO: make it dynamic
              isComplete: true
            }
          ]
        },
        {
          title: t('analysis:title-section-comparable-analysis'),
          items:
            isTbaFetching || !sourceJurisdiction
              ? []
              : [
                  {
                    // TODO: make the progress icon dynamic - see DUO-1331
                    path: `/analysis/${String(studyId)}/tba-dashboard/${String(tbaId)}/comparable-analysis`,
                    text: sourceJurisdiction ?? '',
                    disabled: false,
                    showProgressIcon: true,
                    isComplete: false,
                    isParallelItems: sourceJurisdiction !== destinationJurisdiction,
                    parallelItemPath: `/analysis/${String(studyId)}/tba-dashboard/${String(tbaId)}/test`,
                    parallelItemText: sourceJurisdiction === destinationJurisdiction ? '' : destinationJurisdiction
                  }
                ]
        }
      ]}
      onNavigate={(path) => {
        history.push(`${path}`);
      }}
    />
  );
};

export default Connector;
