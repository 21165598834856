import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  BackgroundInfoSubTopic,
  BackgroundInfoTopic,
  BackgroundInfoType,
  Country,
  NormalizedStateSlice,
  StatementOfFactsPerJurisdiction,
  CORE_KEY,
  CopyBackgroundInfoSource
} from '../../models';
import { selectCountries } from '../../selectors';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface StatementOfFactsState {
  entities: NormalizedStateSlice<StatementOfFactsPerJurisdiction>;
  transactions: NormalizedStateSlice<StatementOfFactsPerJurisdiction>;
  error?: string;
}

const initialState: StatementOfFactsState = {
  entities: { byId: null, allIds: [] },
  transactions: { byId: null, allIds: [] }
};

const sortAndFixCompletion = (topics: BackgroundInfoTopic[]) => {
  for (const topic of topics) {
    topic.subTopics = topic.subTopics
      // API returned subtopic is an integer, that we'll turn to a boolean
      // @ts-expect-error ts(2367)
      .map((subtopic) => ({ ...subtopic, completed: subtopic.completed === 1 }))
      .sort((a, b) => a.subtopicSortOrder - b.subtopicSortOrder);
  }

  topics.sort((a, b) => a.sortOrder - b.sortOrder);
};

export const fetchStatementOfFacts = createAsyncThunk<
  StatementOfFactsPerJurisdiction,
  { type: BackgroundInfoType; id: number },
  { rejectValue: Error }
>('statementOfFacts/fetch', async ({ type, id }, { rejectWithValue }) => {
  try {
    // This call is required. We may change the GET endpoint to perform this initialization steps
    await httpService.request<{ data: Array<{ jurisdiction: Country | null; topics: BackgroundInfoTopic[] }> }>({
      method: 'post',
      apiUrlKey: 'baseUrl',
      relativePath: `background-info/${id}/initialize/${type}`
    });
    const {
      data: { data: topicList }
    } = await httpService.request<{ data: Array<{ jurisdiction: Country | null; topics: BackgroundInfoTopic[] }> }>({
      method: 'get',
      apiUrlKey: 'baseUrl',
      relativePath: `background-info/${id}/topics/${type}`
    });

    const results: StatementOfFactsPerJurisdiction = {};
    const requests = [];
    for (const { jurisdiction, topics } of topicList) {
      results[jurisdiction ? jurisdiction.isoCode : CORE_KEY] = topics;

      for (const { name: topic, country } of topics) {
        requests.push(
          httpService.request<{ data: BackgroundInfoSubTopic[] }>({
            method: 'get',
            apiUrlKey: 'baseUrl',
            relativePath: `background-info/${id}/subtopics/${type}`,
            params: country ? { topic, jurisdiction: `${country.countryId}`, isoCode: country.isoCode } : { topic }
          })
        );
      }
    }

    const backgroundInfoRequests: any = [];
    for (const {
      data: { data: backgroundInfo }
    } of await Promise.all(requests)) {
      backgroundInfo.forEach((backgroundInfo) => {
        backgroundInfoRequests.push(
          httpService.request<{ data: BackgroundInfoSubTopic[] }>({
            method: 'get',
            apiUrlKey: 'baseUrl',
            relativePath: `background-info/${id}/subtopic`,
            params: { backgroundInfoId: backgroundInfo.backgroundInfoId }
          })
        );
      });
    }

    const subtopics: any = await Promise.all(backgroundInfoRequests);
    for (const {
      data: { data }
    } of subtopics) {
      const topicsPerJurisdiction = Object.keys(results);
      topicsPerJurisdiction.forEach((key) => {
        results[key].forEach((topic) => {
          if (topic.topicId === data.topicId) {
            if (!topic.subTopics) {
              topic.subTopics = [];
            }

            topic.subTopics.push(data);
          }
        });
      });
    }

    for (const topics of Object.values(results)) {
      sortAndFixCompletion(topics);
    }

    return results;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const saveStatementOfFacts = createAsyncThunk<
  BackgroundInfoSubTopic,
  {
    type: BackgroundInfoType;
    id: number;
    jurisdiction: string;
    subtopic: Partial<BackgroundInfoSubTopic>;
  },
  { state: RootState; rejectValue: Error }
>('statementOfFacts/save', async ({ type, id, jurisdiction, subtopic }, { getState, rejectWithValue }) => {
  try {
    const jurisdictionId = selectCountries(getState()).find((country) => country.isoCode === jurisdiction)?.countryId;
    return (
      await httpService.request<{ data: BackgroundInfoSubTopic }>({
        method: subtopic.backgroundInfoId ? 'patch' : 'post',
        apiUrlKey: 'baseUrl',
        relativePath: subtopic.backgroundInfoId
          ? `background-info/${subtopic.backgroundInfoId}`
          : `background-info/${id}/subtopics/${type}`,
        data: {
          jurisdictionId,
          ...subtopic
        }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const copyStatementOfFacts = createAsyncThunk<
  BackgroundInfoSubTopic[],
  {
    type: BackgroundInfoType;
    id: number;
    jurisdiction: string;
    source: CopyBackgroundInfoSource;
  },
  { state: RootState; rejectValue: Error }
>('statementOfFacts/copy', async ({ type, id, jurisdiction, source }, { getState, rejectWithValue }) => {
  try {
    const jurisdictionId = selectCountries(getState()).find((country) => country.isoCode === jurisdiction)?.countryId;
    return (
      await httpService.request<{ data: BackgroundInfoSubTopic[] }>({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath: `background-info/copy/${id}/type/${type}`,
        params: jurisdictionId ? { jurisdiction: `${jurisdictionId}` } : {},
        data: {
          sourceId: source.id,
          jurisdictionId,
          uncontrolled: source?.uncontrolled ?? false
        }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const resetStatementOfFacts = createAsyncThunk<
  BackgroundInfoSubTopic[],
  {
    type: BackgroundInfoType;
    id: number;
    jurisdiction: string;
  },
  { state: RootState; rejectValue: Error }
>('statementOfFacts/reset', async ({ type, id, jurisdiction }, { getState, rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: BackgroundInfoSubTopic[] }>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `background-info/${id}/reinitialize/${type}`,
        data: {
          jurisdictionId: selectCountries(getState()).find((country) => country.isoCode === jurisdiction)?.countryId
        }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const statementOfFactsSlice = createSlice({
  name: 'statementOfFacts',
  initialState,
  reducers: {
    updateSubtopcDiscussionState: (state, action) => {
      const { type, id, jurisdiction, subtopic } = action.payload;

      const backgroundInfo = state[type === BackgroundInfoType.Entity ? 'entities' : 'transactions'];
      const topics = backgroundInfo.byId?.[id]?.[jurisdiction];
      const topic = topics?.find((topic) => topic.topicId === subtopic.topicId);
      const topSubTopic = topic?.subTopics.find((sub) => sub.backgroundInfoId === subtopic.backgroundInfoId);
      if (topSubTopic) {
        topSubTopic.discussion = subtopic.discussion;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchStatementOfFacts.fulfilled, (state, action) => {
        const { type, id } = action.meta.arg;
        const slice = state[type === BackgroundInfoType.Entity ? 'entities' : 'transactions'];

        if (!slice.byId) {
          slice.byId = {};
        }

        slice.byId[id] = action.payload;
        if (!slice.allIds.includes(id)) {
          slice.allIds.push(id);
        }
      })
      .addCase(saveStatementOfFacts.fulfilled, (state, action) => {
        const {
          type,
          id,
          jurisdiction,
          subtopic: { backgroundInfoId }
        } = action.meta.arg;
        const { topicId, subtopicSortOrder } = action.payload;

        const slice = state[type === BackgroundInfoType.Entity ? 'entities' : 'transactions'];
        const topics = slice.byId?.[id]?.[jurisdiction];
        const { subTopics } = (topics ?? []).find((topic) => topic.topicId === topicId) ?? {};

        if (subTopics) {
          // warning: subtopicSortOrder are 1-based.
          subTopics.splice(subtopicSortOrder - 1, backgroundInfoId ? 1 : 0, action.payload);
          if (!backgroundInfoId) {
            // when inserting new topic, we must recompute subtopic indices
            let index = 1;
            for (const subtopic of subTopics) {
              subtopic.subtopicSortOrder = index++;
            }
          }
        }
      })
      .addMatcher(
        (action) => resetStatementOfFacts.fulfilled.match(action) || copyStatementOfFacts.fulfilled.match(action),
        (state, action) => {
          const { type, id, jurisdiction } = action.meta.arg;

          const slice = state[type === BackgroundInfoType.Entity ? 'entities' : 'transactions'];
          const topics = slice.byId?.[id]?.[jurisdiction];
          if (topics) {
            for (const topic of topics) {
              topic.subTopics = (action.payload as BackgroundInfoSubTopic[]).filter(
                ({ topicId }) => topicId === topic.topicId
              );
            }

            sortAndFixCompletion(topics);
          }
        }
      )
      .addMatcher(
        (action) => action.type.match(/^statementOfFacts\/.+\/pending$/),
        (state: StatementOfFactsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^statementOfFacts\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export const { updateSubtopcDiscussionState } = statementOfFactsSlice.actions;

export default statementOfFactsSlice.reducer;
