import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ProfitBasedAnalysis, TransactionBasedAnalysis } from '../../../models';
import { selectWorkingContainer } from '../../../selectors';
import { StudyTabsDirectLinkIcon } from '../../../svgs';
import { getAppConfig, handleRoleRedirect } from '../../../utils';

interface StudyTabsDirectLinkProps {
  element: ProfitBasedAnalysis | TransactionBasedAnalysis;
  isPBA: boolean;
}

const useStyles = makeStyles(() => ({
  directLinkIcon: {
    width: '1.25rem',
    height: '1.25rem',
    cursor: 'pointer'
  }
}));

export const StudyTabsDirectLink = ({ isPBA, element }: StudyTabsDirectLinkProps) => {
  const classes = useStyles();
  const elementId = isPBA ? (element as ProfitBasedAnalysis).pbaId : (element as TransactionBasedAnalysis).tbaId;
  const history = useHistory();
  const location = useLocation();
  const flags = useFlags();
  const match: any = useRouteMatch('/analysis/:studyId');
  const studyId: any = match.params.studyId ?? null;
  const { xbsEnvironmentShort } = getAppConfig();
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;

  const handleOnClick = () => {
    if (isPBA) {
      history.push(`${location.pathname}/pba-dashboard/${String(elementId)}`, { element });
    } else if (
      flags.tbaAnalysisDashboardNavigationFlag &&
      (flags.tbaAnalysisDashboardNavigationFlag.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
        flags.tbaAnalysisDashboardNavigationFlag.includes(`${String(xbsEnvironmentShort)}-*`))
    ) {
      history.push(`${location.pathname}/tba-dashboard/${String(elementId)}`, { element });
    } else {
      handleRoleRedirect(
        `${window.location.origin}/legacy/local-files/study/${String(studyId)}/tba/${String(elementId)}`
      );
    }
  };

  return <StudyTabsDirectLinkIcon className={classes.directLinkIcon} onClick={handleOnClick} />;
};
