import { ComponentType, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MajorClassification, TestedPartyCharacterizationProps } from './TestedPartyCharacterization.proptype';
import { fetchCurrentTestedParty } from '../../redux/profitBasedAnalyses';
import { selectCurrentTestedParty } from '../../selectors';
import { selectCurrentMajorClassification } from '../../selectors/testedParty';

const Connector = ({ component: Component }: { component: ComponentType<TestedPartyCharacterizationProps> }) => {
  const dispatch = useDispatch();
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);

  const [numOfSelectedMajor, setNumOfSelectedMajor] = useState<number>(0);

  useEffect(() => {
    dispatch(fetchCurrentTestedParty(pbaId));
    setNumOfSelectedMajor(currentMajorClassifications.length);
  }, [dispatch, pbaId, currentMajorClassifications]);

  return <Component currentTestedParty={currentTestedParty} numOfSelectedMajor={numOfSelectedMajor} />;
};

export default Connector;
