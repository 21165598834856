import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import { TransactionBasedAnalysis } from '../../../models';
import { fetchStudies } from '../../../redux/studies';
import { CopyTBAParameters, fetchTBAsAPICall } from '../../../redux/transactionBasedAnalyses';
import { selectStudiesList, selectWorkingContainer } from '../../../selectors';
import { AppDispatch } from '../../../store';
import tokens from '../../../styles/designTokens';
import { body3, title1, title2 } from '../../../styles/typography';
import { TPModal } from '../../TPModal';

interface UncontrolledTransactionUploadModalProps {
  isOpen: boolean;
  title: string;
  setIsOpenUploadModal: (isOpen: boolean) => void;
  onCopyTba: (data: CopyTBAParameters) => void;
  currentTba: TransactionBasedAnalysis | undefined;
}

interface ValidTBAs {
  name: string;
  value: number;
  studyId: number | undefined;
}

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

const useStyles = makeStyles((theme) => ({
  title: title2,
  contentBox: {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(1),
    minHeight: '10.563rem',
    minWidth: '43.875rem',
    boxShadow: '0 0 0.188rem 0 rgba(0,0,0,0.12)'
  },
  contentTBAFields: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
  },
  contentTBAValue: body3,
  contentTBAFieldName: {
    gridColumn: 'span 2 / span 2'
  },
  contentTitle: {
    color: tokens.core3,
    display: 'inline',
    ...title1
  },
  contentLabel: {
    color: tokens.core2,
    ...body3,
    letterSpacing: '0.009rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentWrapper: {
    backgroundColor: tokens.neutral70
  },
  contentTBASelect: {
    width: '100%'
  }
}));

export const UncontrolledTransactionsCopyTbaModal = ({
  isOpen,
  title,
  setIsOpenUploadModal,
  onCopyTba,
  currentTba
}: UncontrolledTransactionUploadModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [selectedStudyId, setSelectedStudyId] = useState<number | null>(null);
  const [validTBAs, setValidTBAs] = useState<ValidTBAs[]>([]);
  const workingContainer = useSelector(selectWorkingContainer);
  const analysisGroups = useSelector(selectStudiesList);

  const getTbasByAnalysisGroupId = useCallback(async () => {
    if (selectedStudyId) {
      return fetchTBAsAPICall(selectedStudyId);
    }
  }, [selectedStudyId]);

  useEffect(() => {
    if (currentTba) {
      void getTbasByAnalysisGroupId().then((tbas) => {
        const filteredTbas = (tbas ?? [])
          .filter((tba) => tba.tbaId !== currentTba?.tbaId)
          .filter(
            (tba) =>
              tba.tbaEvaluationMethod.tbaEvaluationMethodId === currentTba.tbaEvaluationMethod.tbaEvaluationMethodId &&
              tba.transactionType.transactionTypeId === currentTba.transactionType.transactionTypeId
          )
          .map((tba) => ({
            name: tba.name,
            value: tba.tbaId,
            studyId: tba.study.studyId
          }));
        setValidTBAs(filteredTbas);
      });
    }
  }, [currentTba, getTbasByAnalysisGroupId]);

  const formMethods = useForm({
    defaultValues: {
      sourceAnalysisGroup: '',
      sourceTBA: ''
    }
  });

  const { handleSubmit, control, errors, setValue } = formMethods;

  useEffect(() => {
    if (analysisGroups === null) {
      void dispatch(fetchStudies());
    }
  }, [dispatch, analysisGroups]);

  useEffect(() => {
    if (selectedStudyId) {
      setValue('sourceTBA', '');
    }
  }, [selectedStudyId, setValue]);

  return (
    <TPModal
      isOpen={isOpen}
      maxWidth="md"
      containerStyle={classes.contentWrapper}
      title={
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
      }
      actions={[
        {
          name: t('action-cancel'),
          variant: 'outlined',
          color: 'primary',
          handler: () => {
            setIsOpenUploadModal(false);
          }
        },
        {
          name: t('action-done'),
          color: 'primary',
          variant: 'contained',
          handler: handleSubmit((data) => {
            onCopyTba({
              archived: 0,
              deleted: '0',
              containerId: workingContainer?.containerId ?? 0,
              name: workingContainer?.name ?? '',
              taxYear: workingContainer?.taxYear ?? 0,
              isPlaceholder: 0,
              isService: 1,
              tenantId: workingContainer?.tenantId ?? 0,
              sourceTbaId: Number(data.sourceTBA) ?? 0,
              destinationTbaId: currentTba?.tbaId ?? 0
            });
            setIsOpenUploadModal(false);
          })
        }
      ]}
      onClose={() => {
        setIsOpenUploadModal(false);
      }}
    >
      <Box className={classes.contentBox}>
        <Box className={classes.contentTBAFields}>
          <Box className={classes.contentTBAFieldName}>
            <Typography className={classes.contentTitle}>
              {t('analysis:tba-copy-tba-modal-tba-name')}:{' '}
              <span className={classes.contentTBAValue}>{currentTba?.name}</span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.contentTitle}>
              {t('analysis:tba-copy-tba-modal-tba-method')}:{' '}
              <span className={classes.contentTBAValue}>{currentTba?.tbaEvaluationMethod.name}</span>
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.contentTitle}>
              {t('analysis:tba-copy-tba-modal-tba-transaction-type')}:{' '}
              <span className={classes.contentTBAValue}>{currentTba?.transactionType.name}</span>
            </Typography>
          </Box>
        </Box>

        <Typography className={classes.contentLabel}>{t('analysis:tba-copy-tba-modal-description')}</Typography>

        <Box className={classes.contentTBAFields}>
          <Box>
            <Typography className={classes.contentTitle}>
              {t('analysis:tba-copy-tba-modal-source-analysis-group')}
            </Typography>
          </Box>
          <Box>
            <FormControl className={classes.contentTBASelect} variant="outlined">
              <Controller
                name="sourceAnalysisGroup"
                control={control}
                render={({ value, onChange }) => (
                  <Select
                    displayEmpty
                    defaultValue=""
                    value={value}
                    onChange={(item) => {
                      onChange(item);
                      const itemId = item.target.value as number;
                      setSelectedStudyId(itemId);
                    }}
                  >
                    {(analysisGroups ?? []).map((item) => (
                      <MenuItem key={item.studyId} value={item.studyId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </FormControl>
            <ErrorMessage
              error={Boolean(errors.sourceAnalysisGroup)}
              message={t('analysis:tba-copy-tba-modal-source-analysis-group-required')}
            />
          </Box>
          <Box>
            <Typography className={classes.contentTitle}>{t('analysis:tba-copy-tba-modal-source-tba')}</Typography>
          </Box>
          <Box>
            <FormControl className={classes.contentTBASelect} variant="outlined">
              <Controller
                name="sourceTBA"
                control={control}
                render={({ value, onChange }) => (
                  <Select
                    displayEmpty
                    defaultValue=""
                    value={value}
                    onChange={(item) => {
                      onChange(item);
                    }}
                  >
                    {validTBAs.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </FormControl>
            <ErrorMessage
              error={Boolean(errors.sourceTBA)}
              message={t('analysis:tba-copy-tba-modal-source-tba-required')}
            />
          </Box>
        </Box>
      </Box>
    </TPModal>
  );
};
