import React, { ReactNode, useEffect, useRef } from 'react';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  useTheme,
  TableContainer,
  Paper
} from '@material-ui/core';
import { useOverflowShadows } from './hooks/useOverflowShadows';
import { TableProps, realisticObject } from './Table.proptype';
import tokens from '../../styles/designTokens';
import { getRandomId } from '../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-stickyHeader': {
      left: 'initial',
      zIndex: 1
    },
    '& .MuiTableCell-root': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      whiteSpace: 'nowrap',

      '&.MuiTableCell-head': {
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        whiteSpace: 'normal'
      },

      '&.MuiTableCell-body': {
        height: 45,
        padding: theme.spacing(0, 1),
        '&:first-child': {
          fontWeight: theme.typography.fontWeightMedium
        },

        '& .MuiIconButton-root': {
          color: theme.palette.secondary.dark
        }
      }
    },
    overflowX: 'auto'
  },
  wrappedStickyCell: {
    '&.MuiTableCell-root': {
      borderBottom: 'none',
      '&.MuiTableCell-head': {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  },
  leftSticky: {
    '&.MuiTableCell-root': {
      borderRight: '1px solid ' + tokens.neutral90,
      left: 0,
      position: 'sticky',
      '&.MuiTableCell-head': {
        paddingLeft: 0,
        paddingRight: 0,
        zIndex: 2
      },
      '&.MuiTableCell-body': {
        padding: 0
      }
    }
  },
  rightSticky: {
    '&.MuiTableCell-root': {
      borderLeft: '1px solid ' + tokens.neutral90,
      position: 'sticky',
      right: 0,
      '&.MuiTableCell-head': {
        paddingLeft: 0,
        paddingRight: 0,
        zIndex: 2
      },
      '&.MuiTableCell-body': {
        padding: 0
      }
    }
  },
  leftStickyShadow: {
    '&::after': {
      background: 'linear-gradient(270deg, rgba(0,0,0,0) 0%, #000000 100%)',
      borderLeft: '1px solid ' + tokens.neutral90,
      content: '""',
      height: '100%',
      opacity: '8%',
      position: 'absolute',
      right: '-8px',
      top: 0,
      width: '8px'
    }
  },
  rightStickyShadow: {
    '&::after': {
      background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, #000000 100%)',
      borderRight: '1px solid ' + tokens.neutral90,
      content: '""',
      height: '100%',
      left: '-8px',
      opacity: '8%',
      position: 'absolute',
      top: 0,
      width: '8px'
    }
  },
  tableWrapper: {
    height: '100%'
  },
  scroller: {
    backgroundColor: tokens.white
  }
}));

const DEFAULT_INITIAL_ITEM_COUNT = 20;
const DEFAULT_INCREASE_VIEWPORT_BY = 500;
const AVERAGE_CHAR_WIDTH = 0.78;
const MAX_BUSINESS_DESCRIPTION_WIDTH = 424;
const BUSINESS_DESCRIPTION_LIMIT = 30.3;
const COLUMN_WIDTH_BUFFER = 1.5;
const LEFT_TABLE_SIDE = 'left';
const YEAR_COLUMN_HEADER_PREFIX = 'oneYear';
const AVERAGE_COLUMN_HEADER = 'average';
const BUSINESS_DESCRIPTION_HEADER = 'businessDescription';

export const Table = (props: TableProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const movingCellsClassName = getRandomId();

  const { stickyHeader, stickyCols, className, columns, data, selectedIndices, isExpanded } = props;
  const dataRef = useRef(data);
  const scrollerRef = useRef<HTMLDivElement | null>(null);

  const { tableWrapperRef, showLeftShadow, showRightShadow, handleScroll } = useOverflowShadows({
    isExpanded,
    scrollerRef
  });

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  const columnWidths = React.useMemo(() => {
    const newColumnWidths: { [key: string]: number } = {};

    columns.forEach(({ key, width }) => {
      if (
        key.startsWith(YEAR_COLUMN_HEADER_PREFIX) ||
        key === AVERAGE_COLUMN_HEADER ||
        key === BUSINESS_DESCRIPTION_HEADER
      ) {
        let maxWidth = 0;
        const headerWidth = Number.parseFloat(String(width));
        maxWidth = Math.max(maxWidth, headerWidth);

        data.forEach((row) => {
          const cellText = key === BUSINESS_DESCRIPTION_HEADER ? row[key]?.props?.title ?? '' : String(row[key] ?? '');
          const textWidth = cellText.length * AVERAGE_CHAR_WIDTH;
          maxWidth = Math.max(maxWidth, textWidth);
        });

        newColumnWidths[key] = maxWidth;
      }
    });

    return newColumnWidths;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  const columnSpecificStyles: realisticObject = props.columnSpecificStyles ?? {};

  /**
   * Returns the cells aggregated with their location and stickiness attributes.
   * If row data is not passed, it assumes cells are header cells
   */
  const renderCells = (row?: any) => {
    const locations = {
      left: [] as ReactNode[],
      middle: [] as ReactNode[],
      right: [] as ReactNode[]
    };

    columns.forEach(({ key, header, align, width }, i) => {
      let cellStyles = { ...columnSpecificStyles[key], minWidth: width, width };

      if (!stickyCols?.[key]) {
        let calculatedWidth = columnWidths[key];
        if (key.startsWith(YEAR_COLUMN_HEADER_PREFIX) && calculatedWidth) {
          cellStyles = {
            ...cellStyles,
            minWidth: `${calculatedWidth}em`,
            width: `${calculatedWidth}em`
          };
        } else if (key === BUSINESS_DESCRIPTION_HEADER && calculatedWidth) {
          if (calculatedWidth > MAX_BUSINESS_DESCRIPTION_WIDTH) {
            calculatedWidth = BUSINESS_DESCRIPTION_LIMIT;
          }

          cellStyles = {
            ...cellStyles,
            minWidth: `${calculatedWidth}em`,
            width: `${calculatedWidth}em`
          };
        }

        locations.middle.push(
          <TableCell key={key} align={align} className={row ? undefined : movingCellsClassName} style={cellStyles}>
            {row ? row[key] : header}
          </TableCell>
        );
        return;
      }

      locations[stickyCols[key].side][stickyCols[key].position] = (
        <TableCell
          key={String(i)}
          style={{ ...cellStyles, minWidth: width, width }}
          component="div"
          align={align}
          className={classes.wrappedStickyCell + ` ${stickyCols[key].side}`}
        >
          {row ? row[key] : header}
        </TableCell>
      );
    });

    return [
      locations.left.length > 0 ? (
        <TableCell key="leftCell" className={`${classes.leftSticky} ${showLeftShadow ? classes.leftStickyShadow : ''}`}>
          {locations.left}
        </TableCell>
      ) : null,
      locations.middle,
      locations.right.length > 0 ? (
        <TableCell
          key="rightCell"
          className={`${classes.rightSticky} ${showRightShadow ? classes.rightStickyShadow : ''}`}
        >
          {locations.right}
        </TableCell>
      ) : null
    ];
  };

  const renderColgroup = () => {
    const cols: any[] = [];
    const leftWidth: any[] = [];
    const rightWidth: any[] = [];

    columns.forEach(({ key, width }, i) => {
      if (stickyCols?.[key]) {
        if (stickyCols?.[key].side === LEFT_TABLE_SIDE) {
          leftWidth.push(width);
        } else if (key === AVERAGE_COLUMN_HEADER) {
          const averageWidth = Number.parseFloat(String(columnWidths[key]));
          const averageWidthWithBuffer = averageWidth + COLUMN_WIDTH_BUFFER;
          rightWidth.push(`${averageWidthWithBuffer}em`);
        } else {
          rightWidth.push(width);
        }
      } else {
        cols.push(<col key={String(i)} style={width ? { minWidth: width, width } : {}} />);
      }
    });

    if (leftWidth.length > 0) {
      const calculated = `calc(${leftWidth.join('+')})`;
      cols.unshift(<col key="colgroupLeft" style={{ minWidth: calculated, width: calculated }} />);
    }

    if (rightWidth.length > 0) {
      const remWidths = rightWidth.map((value) => value.replace('em', 'rem'));
      const calculated = `calc(${remWidths.join(' + ')})`;
      cols.push(<col key="colgroupRight" style={{ minWidth: calculated, width: calculated }} />);
    }

    return <colgroup>{cols}</colgroup>;
  };

  const VirtuosoTableComponents: TableComponents = React.useMemo(
    () => ({
      Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer
          component={Paper}
          {...props}
          ref={(node: any) => {
            scrollerRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          className={classes.scroller}
          onScroll={handleScroll}
        />
      )),
      Table: ({ children }: { children?: React.ReactNode }) => (
        <div ref={tableWrapperRef}>
          <MUITable stickyHeader={stickyHeader ?? true} className={`${classes.root} ${className ?? ''}`}>
            {renderColgroup()}
            {children}
          </MUITable>
        </div>
      ),
      TableHead,
      TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),

      TableRow: ({ 'data-item-index': dataItemIndex, children, ...rowProps }: any) => {
        const row = dataRef.current[dataItemIndex];

        return (
          <TableRow
            {...rowProps}
            className={row?.className ?? ''}
            selected={selectedIndices?.includes(dataItemIndex)}
            onClick={(event: React.MouseEvent<Element, MouseEvent>) => {
              props.onRowClick?.(event, dataItemIndex, row);
            }}
          >
            {children}
          </TableRow>
        );
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [className, classes.root, selectedIndices, stickyHeader, tableWrapperRef, isExpanded, classes.scroller]
  );

  const fixedHeaderContent = () => <TableRow>{renderCells()}</TableRow>;

  const itemContent = (index: number, row: any) => {
    return renderCells(row);
  };

  return (
    <div className={classes.tableWrapper}>
      <TableVirtuoso
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={(index, row) => itemContent(index, row)}
        initialItemCount={DEFAULT_INITIAL_ITEM_COUNT}
        increaseViewportBy={DEFAULT_INCREASE_VIEWPORT_BY}
      />
    </div>
  );
};
