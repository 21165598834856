interface SectionsByRole {
  [key: string]: Set<string>;
}
export const sectionsByRole: SectionsByRole = {
  reportsonly: new Set(['reports', 'global-guide']),
  documentationreview: new Set(['entities', 'transactions', 'analysis', 'reports', 'global-guide', 'master-file']),
  transferpricing: new Set(['entities', 'transactions', 'analysis', 'reports', 'global-guide', 'master-file']),
  salestransferpricing: new Set(['entities', 'transactions', 'analysis', 'reports', 'global-guide', 'master-file']),
  psganalysttransferpricing: new Set([
    'entities',
    'transactions',
    'analysis',
    'reports',
    'global-guide',
    'master-file'
  ]),
  psgadmintransferpricing: new Set(['entities', 'transactions', 'analysis', 'reports', 'global-guide', 'master-file'])
};
