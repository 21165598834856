import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { AssociatedTransactionsMenu } from './AssociatedTransactionsMenu';
import { SortOrder } from '../../../components';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { LegalEntityTransaction, AssociatedTransactionsPBA } from '../../../models';
import { filterData, sortData } from '../../../services/filtering';
import {
  currencyValueFormatter,
  findTBARangeValue,
  formatRangeValue,
  getRangeValueHeader,
  isRangeValueTypeUnitPrice
} from '../../../utils';
import { parseAndFormatForDisplay } from '../../../utils/dates';
import { Table } from '../../Table';
import { AssociatedTransactionsTableProps } from '../PBADashboardTPDSummary.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1)
  },
  country: {
    padding: theme.spacing(0, 2)
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    gap: theme.spacing(0.5),
    margin: theme.spacing(0.5, 0)
  },
  row: {
    backgroundColor: theme.palette.background.paper
  }
}));

export const AssociatedTransactionsTable = ({
  data,
  onEdit,
  onDelete,
  upeCurrency,
  rangeValue,
  analysisType,
  tba
}: AssociatedTransactionsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [featureFlagIsActive] = useFeatureFlags();
  const [sortObject] = useState<{ sortBy?: any; sortOrder?: SortOrder }>({});
  const [filterObject] = useState<Record<string, string>>({});
  const isUnitPrice = isRangeValueTypeUnitPrice(tba);

  const transactionType = tba?.transactionType;
  const method = tba?.tbaEvaluationMethod.name;

  const showRangeValueFixFormatting = featureFlagIsActive('tbaRangeValuesFormatting');

  const displayableData = useMemo(() => {
    return ((data ?? []).map((transaction) => {
      const {
        legalEntityTransactions,
        identifier,
        transactionType,
        propertyTransferred,
        transferDate,
        value
      } = transaction;
      const sourceEntity: LegalEntityTransaction | null = ((legalEntityTransactions ?? []).find(
        (legal: any) => legal?.isSource === true
      ) as unknown) as LegalEntityTransaction | null;
      const destinationEntity: LegalEntityTransaction | null = ((legalEntityTransactions ?? []).find(
        (legal: any) => legal?.isSource === false
      ) as unknown) as LegalEntityTransaction | null;

      const transactionsTableDataObj = {
        sourceEntity: sourceEntity?.entity?.code ?? '',
        destinationEntity: destinationEntity?.entity?.code ?? '',
        transactionIdentifier: identifier,
        transactionType: transactionType?.name,
        property: propertyTransferred,
        transferDate: transferDate ? parseAndFormatForDisplay(transferDate) : '',
        transactionsAmountGBP: value
          ? new Intl.NumberFormat('en', currencyValueFormatter(2, 2, upeCurrency)).format(value)
          : '',
        rangeValue: showRangeValueFixFormatting
          ? formatRangeValue(findTBARangeValue(rangeValue!, tba), isUnitPrice)
          : findTBARangeValue(rangeValue!, tba),
        menu: (
          <AssociatedTransactionsMenu
            element={transaction}
            analysisType={analysisType}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )
      };

      return transactionsTableDataObj;
    }) as unknown) as Array<Partial<AssociatedTransactionsPBA>>;
  }, [data, upeCurrency, showRangeValueFixFormatting, rangeValue, tba, isUnitPrice, analysisType, onEdit, onDelete]);

  const associatedTransactionsColumnsHeaders = [
    {
      key: 'sourceEntity',
      width: 250,
      header: t('analysis:column-source-entity'),
      className: classes.row
    },
    { key: 'destinationEntity', header: t('analysis:column-destination-entity'), className: classes.row },
    { key: 'transactionIdentifier', header: t('analysis:column-transaction-id'), className: classes.row },
    { key: 'transactionType', header: t('analysis:column-transaction-type'), className: classes.row },
    { key: 'property', header: t('transactions:label-property-transferred'), className: classes.row },
    { key: 'transferDate', header: t('analysis:column-transaction-transfer-date-two'), className: classes.row },
    {
      key: 'transactionsAmountGBP',
      header: `${t('analysis:column-transactions-amount')} (${upeCurrency.isoCode ? upeCurrency.isoCode : 'USD'})`,
      className: classes.row
    },
    rangeValue
      ? {
          key: 'rangeValue',
          header: showRangeValueFixFormatting
            ? getRangeValueHeader(transactionType, method, isUnitPrice)
            : 'Range Value',
          className: classes.row
        }
      : { key: '', header: '', className: '' },
    { key: 'menu', header: '', width: 50, className: classes.row }
  ];

  return (
    <Table
      data={sortData(filterData(displayableData, filterObject), sortObject)}
      columns={associatedTransactionsColumnsHeaders}
    />
  );
};
