import { Link } from 'react-router-dom';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';

export interface ContentHeaderProps {
  primaryEntity: string;
  title: string;
  link: string;
  icon: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    padding: theme.spacing(0.5, 1)
  },
  title: {
    color: theme.palette.text.secondary
  },
  highlight: {
    color: theme.palette.text.primary,
    marginRight: '0.5em'
  }
}));

export const ContentHeader = ({ primaryEntity, title, link, icon }: ContentHeaderProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <IconButton component={Link} to={link}>
        {icon}
      </IconButton>
      <Typography variant="h2" className={classes.title}>
        <Box component="span" className={classes.highlight}>
          {title}
        </Box>
        {primaryEntity}
      </Typography>
    </Box>
  );
};
