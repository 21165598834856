import { ReactElement } from 'react';
import { Box, ListItem, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.spacing(0.5),
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1, 0, 0),
    '&:not(:last-child)::after, &:not(:first-child)::before': {
      content: '""',
      position: 'absolute',
      height: theme.spacing(0.5),
      borderLeft: `2px solid ${theme.palette.secondary.light}`,
      left: theme.spacing(1.4)
    },
    '&::after': {
      top: '100%'
    },
    '&::before': {
      bottom: '100%'
    },
    // Make the selected link the same color as hover
    '&.Mui-selected, .Mui-selected:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.action.selected,
      '& > :first-child': {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.action.active
      }
    },
    '&:hover > :first-child': {
      backgroundColor: theme.palette.action.hover
    }
  },
  icon: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    lineHeight: 0,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.light
  }
}));

interface SideMenuItemProps {
  icon: ReactElement;
  text: string;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const SideMenuItem = ({ icon, text, disabled, selected, onClick }: SideMenuItemProps) => {
  const classes = useStyles();
  return (
    <ListItem button className={classes.root} disabled={disabled} selected={selected} onClick={onClick}>
      <Box component="span" className={classes.icon}>
        {icon}
      </Box>
      <ListItemText primary={text} />
    </ListItem>
  );
};
