import { Tabs as MUITabs, Tab, makeStyles, useTheme } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'auto'
  },
  indicator: {
    backgroundColor: theme.palette.primary.dark,
    height: 3
  },
  label: {
    minHeight: 'auto',
    '&.MuiTab-root': {
      padding: theme.spacing(0.5)
    },
    '& .MuiTab-wrapper > :first-child': {
      margin: theme.spacing(0, 1, 0, 0)
    }
  },
  iconWrapper: {
    flexDirection: 'row'
  }
}));

interface TabItem {
  label: string;
  current?: boolean;
  checked?: boolean;
}

export interface TabsProps {
  tabs: TabItem[];
  onChange: (current: TabItem) => void;
}

export const Tabs = ({ tabs, onChange }: TabsProps) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <MUITabs
      classes={{ root: classes.root, indicator: classes.indicator }}
      value={tabs.findIndex(({ current }) => current)}
      onChange={(event, index) => {
        onChange(tabs[index]);
      }}
    >
      {tabs.map(({ label, checked }) => (
        <Tab
          key={label}
          classes={{ wrapper: classes.iconWrapper, labelIcon: classes.label }}
          label={label}
          icon={
            <FiberManualRecord
              style={{ color: checked ? theme.palette.primary.main : theme.palette.warning.main, fontSize: 12 }}
            />
          }
        />
      ))}
    </MUITabs>
  );
};
