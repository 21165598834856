import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { InitialRange } from './InitialRange';
import { SplitView } from '../../../../../components/SplitView';
import { RangeComparables, RangeResults } from '../../../../../models';
import { selectInitialRange, selectInitialRangeComparables } from '../../../../../selectors';
import { mediumFont } from '../../../../../styles/fonts';
import { captionOverline, body2 } from '../../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { SingleChipProps, SummaryChips } from '../../../../SummaryChips';
import { CompanyTable } from '../../CompanyTable';
import { InitialRangeProps } from '../InitialRange.proptype';

const useStyles = makeStyles(() => ({
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily,
    textAlign: 'left'
  },
  tableWrapper: {
    height: '100%',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body.right.MuiTableCell-alignRight': {
      paddingRight: '2.25rem'
    }
  },
  captionOverline,
  body2
}));

export const InitialTab = ({ pliFormat, pliId }: InitialRangeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  const initialComparables: RangeComparables | undefined = useSelector(selectInitialRangeComparables);
  const initialRangeResults: RangeResults | undefined = useSelector(selectInitialRange);

  const displayableComparables: Array<Record<string, HTMLElement | number>> = useMemo(() => {
    return (initialComparables?.data ?? []).map((comparable: any) => {
      const displayableComp: any = {};
      Object.keys(comparable).forEach((key) => {
        if (typeof comparable[key] === 'number' && key !== 'sourceId') {
          displayableComp[key] = formatPliDisplayValue(formatType, roundingValue, comparable[key]);
        } else if (typeof comparable[key] === 'string') {
          displayableComp[key] = (
            <span title={comparable[key]} className={classes.truncate}>
              {comparable[key]}
            </span>
          );
        }
      });
      return displayableComp;
    });
  }, [initialComparables?.data, formatType, roundingValue, classes.truncate]);

  const chipsData: SingleChipProps[] = [
    {
      isSelected: true,
      label: t('analysis:comparable-range-total'),
      amount: displayableComparables.length,
      key: 'total'
    }
  ];

  const initialComparablesColumns = [
    { key: 'pinned', label: '' },
    ...(initialComparables ? [...initialComparables.columns] : [])
  ];

  return (
    <SplitView
      left={
        <div className={classes.tableWrapper}>
          <CompanyTable
            comparables={initialComparables}
            companies={displayableComparables}
            columns={initialComparablesColumns}
            chips={<SummaryChips chips={chipsData} />}
            showPinnedCompanies={false}
          />
        </div>
      }
      right={
        <InitialRange
          comparables={initialComparables}
          pliFormat={pliFormat}
          pliId={pliId}
          initialRangeResults={initialRangeResults}
        />
      }
    />
  );
};
