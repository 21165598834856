import { useTranslation } from 'react-i18next';
import { Card, Typography, CardHeader, Divider, Box, makeStyles } from '@material-ui/core';
import { DashboardCardEntityTransactionsTradingPairsProps } from './DashboardCardEntityTransactionsTradingPairs.proptypes';
import { CenteredProgress, D3EntitiesTradingGraph } from '../../components';
import { DashboardCardEntityTradingPairsSelection } from '../DashboardCardEntityTradingPairsSelection';

const useStyles = makeStyles((theme) => ({
  emptyTitle: {
    ...theme.typography.caption,
    display: 'flex',
    padding: theme.spacing(0.5, 0, 2, 0),
    alignItems: 'center',
    textAlign: 'left',
    margin: theme.spacing(1)
  },
  emptyMessage: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 1.5),
    color: theme.palette.text.secondary,
    textAlign: 'left',
    margin: theme.spacing(1)
  },
  card: {
    background: theme.palette.background.default,
    width: '100%',
    display: 'flex'
  },
  halfSize: {
    width: '50%',
    padding: theme.spacing(1.5),
    '& > .MuiCardHeader-root': {
      padding: 0
    }
  },
  loadingState: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground
  }
}));

const DashboardCardEntityTransactionsTradingPairs = ({
  onGraphClick,
  pairsForList,
  nodes,
  currentTradingPartner,
  currentEntity,
  onSelectChange,
  upeCurrency
}: DashboardCardEntityTransactionsTradingPairsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isDataLoaded = nodes && currentEntity && Array.isArray(pairsForList);
  const isEntityWithTransactions = Object.keys(nodes ?? {}).length > 0 && pairsForList.length > 0;

  return (
    <Card className={classes.card}>
      {!isDataLoaded && (
        <div className={classes.loadingState}>
          <CenteredProgress />
        </div>
      )}
      {isDataLoaded && isEntityWithTransactions && currentTradingPartner && (
        <>
          <div className={classes.halfSize}>
            <DashboardCardEntityTradingPairsSelection
              currentTradingPartner={currentTradingPartner}
              items={pairsForList}
              onSelectChange={onSelectChange}
            />
          </div>
          <Divider orientation="vertical" />
          <div className={classes.halfSize}>
            <CardHeader
              title={t('entities:title-trading-pairs-flow')}
              subheader={t('transactions:label-value', { currency: upeCurrency.isoCode })}
            />
            <D3EntitiesTradingGraph
              key={currentEntity?.code}
              upeCurrency={upeCurrency}
              currentEntity={currentEntity}
              nodes={nodes}
              currentTradingPartner={currentTradingPartner}
              onClicked={onGraphClick}
            />
          </div>
        </>
      )}
      {isDataLoaded && !isEntityWithTransactions && !currentTradingPartner && (
        <Box className={classes.disabled}>
          <Typography className={classes.emptyTitle}>{t('entities:title-trading-pairs')}</Typography>
          <Typography className={classes.emptyMessage}>
            {t('entities:message-no-trading-pairs-transactions')}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default DashboardCardEntityTransactionsTradingPairs;
