import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { RootState } from '..';
import { FinancialInfoFieldValues } from '../../app/NewAnalysisModal/CreateTBAModal.proptypes';
import {
  BulkUpdateEvaluationsApiCallParams,
  BulkUpdateSimilaritiesApiCallParams,
  ComparabileAnalysisTableTransactions,
  ComparabilityOption
} from '../../app/TbaComparableAnalysis/TbaComparableAnalysis.proptype';
import {
  CreateUncontrolledTransaction,
  EditUncontrolledTransaction,
  TbaUncontrolledParametersPayload,
  TbaUncontrolledTransactionComparability,
  UncontrolledTransaction,
  UncontrolledTransactionParameters,
  UpdateTbaUncontrolledParameter
} from '../../app/TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';
import {
  AreaIds,
  FunctionalAnalysisCharacteristic,
  TBAFunctionalAnalysisCharacteristic,
  UpdateFunctionalAnalysisNarrativeParams
} from '../../components/FunctionalAnalysis/FunctionalAnalysisTable.proptype';
import { FetchLoadingState, FetchLoadingStateEnum } from '../../constants';
import {
  Container,
  CreateTbaApiData,
  TbaEvaluationMethod,
  TransactionBasedAnalysis,
  TransactionType
} from '../../models';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface TBAsState {
  comparabilityOptions?: ComparabilityOption[];
  currentTBA?: TransactionBasedAnalysis;
  currentTBASearchStrategy: string | null;
  currentTBAParameters: TbaParameter[];
  currentTBALocked?: string[];
  tbas: TransactionBasedAnalysis[] | null;
  tbaEvaluationMethods: TbaEvaluationMethod[];
  uncontrolledTransactions: UncontrolledTransaction[];
  error?: string;
  currentUncontrolledTransactionParameters?: UncontrolledTransactionParameters[];
  currentUncontrolledTransactionComparability?: TbaUncontrolledTransactionComparability[];
  statisticalMethods?: StatisticalMethod[];
  currentTbaRangeTypes?: TbaRangeType[];
  currentTbaRangeResults?: TbaRangeResult[];
  currentTbaTaxJurisdictionCodes?: TbaTaxJurisdictionCodes;
  functionalAnalysisCharacteristics:
    | {
        Functions: TBAFunctionalAnalysisCharacteristic[];
        Risks: TBAFunctionalAnalysisCharacteristic[];
        Assets: TBAFunctionalAnalysisCharacteristic[];
      }
    | undefined;
  loading: {
    fetchRoyaltyStatTransactions?: FetchLoadingState;
    fetchTbaRangeType?: FetchLoadingState;
  };
}

const initialState: TBAsState = {
  tbas: null,
  tbaEvaluationMethods: [],
  currentTBASearchStrategy: null,
  uncontrolledTransactions: [],
  currentTBAParameters: [],
  currentUncontrolledTransactionParameters: [],
  currentUncontrolledTransactionComparability: [],
  currentTbaRangeResults: undefined,
  functionalAnalysisCharacteristics: {
    Functions: [],
    Risks: [],
    Assets: []
  },
  loading: {}
};

export const fetchTBAsAPICall = async (studyId: number) =>
  (
    await httpService.request<{ result: { tbas: TransactionBasedAnalysis[] } }>({
      method: 'get',
      apiUrlKey: 'baseUrl',
      relativePath: `tbas/study/${studyId}`,
      params: { sort: 'name', order: 'asc' }
    })
  ).data.result.tbas;

export const fetchTBAs = createAsyncThunk<TransactionBasedAnalysis[], number, { rejectValue: Error }>(
  'tbas/fetch',
  async (studyId, { rejectWithValue }) => {
    try {
      return await fetchTBAsAPICall(studyId);
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const currentTBA = createAction('currentTBA', (tba: TransactionBasedAnalysis) => {
  return { payload: tba };
});

interface fetchTBAResult {
  tba: TransactionBasedAnalysis;
  tbaParameters: TbaParameter[];
  locked: string[];
}

export const fetchCurrentTBA = createAsyncThunk<fetchTBAResult, number, { rejectValue: Error }>(
  'tbas/fetch-current-tba',
  async (tbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: fetchTBAResult }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `tbas/${tbaId}`
        })
      ).data.result;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const createNewUncontrolledTransaction = createAsyncThunk<
  UncontrolledTransaction,
  { transaction: CreateUncontrolledTransaction; t: TFunction },
  { state: RootState; rejectValue: Error }
>('uncontrolled-transactions/create', async ({ transaction, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const { data } = await httpService.request<{ result: UncontrolledTransaction }>({
      method: 'post',
      apiUrlKey: 'baseUrl',
      relativePath: 'uncontrolled-transactions',
      data: transaction
    });

    return data.result;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const editUncontrolledTransaction = createAsyncThunk<
  UncontrolledTransaction,
  { transaction: EditUncontrolledTransaction; t: TFunction },
  { state: RootState; rejectValue: Error }
>(`uncontrolled-transactions}`, async ({ transaction, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const { data } = await httpService.request<{ result: UncontrolledTransaction }>({
      method: 'patch',
      apiUrlKey: 'baseUrl',
      relativePath: `uncontrolled-transactions/${transaction.transactionId}`,
      data: transaction
    });

    return data.result;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchUncontrolledTransactions = createAsyncThunk<
  { transactions: UncontrolledTransaction[]; count: number },
  void,
  { rejectValue: Error }
>('tbas/uncontrolled-transactions/fetch', async (_, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ result: { transactions: UncontrolledTransaction[]; count: number } }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `uncontrolled-transactions`
      })
    ).data.result;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deleteTBA = createAsyncThunk<number, number, { rejectValue: Error }>(
  'tbas/delete',
  async (tbaId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}`
      });
      return tbaId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchUncontrolledTransactionComparability = createAsyncThunk<
  TbaUncontrolledTransactionComparability[],
  number,
  { rejectValue: Error }
>('transactions-comparability/tba/by-id/fetch', async (tbaId, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ result: { transactionComparability: TbaUncontrolledTransactionComparability[] } }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions-comparability/tba/${tbaId}`
      })
    ).data.result.transactionComparability;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchComparabilityOptions = createAsyncThunk<ComparabilityOption[], void, { rejectValue: Error }>(
  'transactions-comparability/options',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { comparabilityOptions: ComparabilityOption[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'transactions-comparability/options'
        })
      ).data.result.comparabilityOptions;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchUncontrolledTransactionParameters = createAsyncThunk<
  UncontrolledTransactionParameters[],
  number,
  { rejectValue: Error }
>('tbas/by-id/parameters/uncontrolled/fetch', async (tbaId, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ result: { parameters: UncontrolledTransactionParameters[] } }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}/parameters/uncontrolled`
      })
    ).data.result.parameters;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deleteTBAUncontrolledTransaction = createAsyncThunk<
  { tbaId: number; transactionId: number },
  { tbaId: number; transactionId: number },
  { rejectValue: Error }
>(
  'tbas/by-id/transactions/uncontrolled/transaction-id/delete',
  async ({ tbaId, transactionId }, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}/transactions/uncontrolled/${transactionId}`
      });
      return { tbaId, transactionId };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deassociateTBAUncontrolledTransaction = createAsyncThunk<
  { tbaId: number; transactionId: number },
  { tbaId: number; transactionId: number },
  { rejectValue: Error }
>(
  'tbas/by-id/transactions/uncontrolled/transaction-id/deassociate',
  async ({ tbaId, transactionId }, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}/transactions/uncontrolled/${transactionId}/deassociate`
      });
      return { tbaId, transactionId };
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const deleteUncontrolledTransaction = createAsyncThunk<
  { transactionId: number },
  { transactionId: number },
  { rejectValue: Error }
>('uncontrolled-transactions/transaction-id', async ({ transactionId }, { rejectWithValue }) => {
  try {
    await httpService.request({
      method: 'delete',
      apiUrlKey: 'baseUrl',
      relativePath: `uncontrolled-transactions/${transactionId}`
    });
    return { transactionId };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface BulkDeleteTBAUncontrolledTransactionProps {
  tbaId: number;
  transactionIds: number[];
}

export const bulkDeleteTBAUncontrolledTransaction = createAsyncThunk<
  unknown,
  BulkDeleteTBAUncontrolledTransactionProps,
  { rejectValue: Error }
>('tbas/by-id/transactions/uncontrolled/bulk/delete', async ({ tbaId, transactionIds }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}/transactions/uncontrolled/bulk`,
        data: { transactionIds }
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface BulkDeleteFromAllUncontrolledProps {
  transactionIds: number[];
}

export const bulkDeleteFromAllUncontrolled = createAsyncThunk<
  unknown,
  BulkDeleteFromAllUncontrolledProps,
  { rejectValue: Error }
>(
  'uncontrolled/bulk/delete', // TODO: update thunk id, reference SC -> DUO-1460
  async ({ transactionIds }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request({
          method: 'delete',
          apiUrlKey: 'baseUrl',
          relativePath: `uncontrolled/bulk/delete`, // TODO: update bulk delete url, reference SC -> DUO-1460
          data: { transactionIds }
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface BulkAddToCurrentSearchProps {
  tbaId: number;
  transactionIds: number[];
}

export const bulkAddToCurrentSearch = createAsyncThunk<unknown, BulkAddToCurrentSearchProps, { rejectValue: Error }>(
  'tbas/by-id/transactions/uncontrolled/bulk/add', // TODO: update thunk id, reference SC -> DUO-1460
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ tbaId, transactionIds }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: ``, // TODO: update bulk add to search url, reference SC -> DUO-1460
          data: { transactionIds }
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const addTBAUncontrolledTransaction = createAsyncThunk<
  unknown,
  { tbaId: number; transactionId: number; tbaUncontrolledParameters: TbaUncontrolledParametersPayload[] },
  { rejectValue: Error }
>('tbas/by-id/transactions/uncontrolled/put', async (payload, { rejectWithValue }) => {
  const { tbaId } = payload;

  try {
    return (
      await httpService.request({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}/transactions/uncontrolled`,
        data: payload
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateUncontrolledTransactionFinancialInfoParameters = createAsyncThunk<
  unknown,
  { transactionId: number; tbaId: number; tbaUncontrolledParameters: UpdateTbaUncontrolledParameter[] },
  { rejectValue: Error }
>('tbas/:id/transactions/uncontrolled/:transactionId/parameters', async (payload, { rejectWithValue }) => {
  const { transactionId, tbaId, tbaUncontrolledParameters } = payload;

  try {
    return (
      await httpService.request({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath: `tbas/${tbaId}/transactions/uncontrolled/${transactionId}/parameters`,
        data: { tbaUncontrolledParameters }
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateUncontrolledTransactionComparabilityAcceptance = createAsyncThunk<
  number,
  { transactionComparabilityId: number; status: number },
  { rejectValue: Error }
>(
  'transactions-comparability/acceptance/by-id/patch',
  async (updateUncontrolledTransactionComparabilityParams, { rejectWithValue }) => {
    const { transactionComparabilityId } = updateUncontrolledTransactionComparabilityParams;
    try {
      return (
        await httpService.request<any>({
          method: 'patch',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions-comparability/acceptance/${transactionComparabilityId}`,
          data: updateUncontrolledTransactionComparabilityParams
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface BulkUpdateUncontrolledTransactionComparabilityAcceptanceProps {
  uncontrolledTransactionIds: number[];
  tbaId: number;
  status: number;
}

export const bulkUpdateUncontrolledTransactionComparabilityAcceptance = createAsyncThunk<
  number,
  BulkUpdateUncontrolledTransactionComparabilityAcceptanceProps,
  { rejectValue: Error }
>(
  'transactions-comparability/acceptance/by-id/patch',
  async (updateUncontrolledTransactionComparabilityParams, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<any>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `transactions-comparability/acceptance/bulk`,
          data: updateUncontrolledTransactionComparabilityParams
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const bulkUpdateSimilarities = createAsyncThunk<
  number,
  BulkUpdateSimilaritiesApiCallParams,
  { rejectValue: Error }
>('transactions-comparability/similarities/bulk', async (bulkUpdateSimiliartiesParams, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<any>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions-comparability/similarities/bulk`,
        data: bulkUpdateSimiliartiesParams
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});
export const bulkUpdateEvaluations = createAsyncThunk<
  number,
  BulkUpdateEvaluationsApiCallParams,
  { rejectValue: Error }
>('transactions-comparability/evaluation/bulk', async (bulkUpdateEvaluations, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<any>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions-comparability/evaluation/bulk`,
        data: bulkUpdateEvaluations
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateUncontrolledTransactionComparabilityEvaluation = createAsyncThunk<
  number,
  ComparabileAnalysisTableTransactions,
  { rejectValue: Error }
>('transactions-comparability/evaluate/by-id/patch', async (comparabilityPayload, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<any>({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `transactions-comparability/evaluate/${String(comparabilityPayload.transactionComparabilityId)}`,
        data: comparabilityPayload
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const createTBA = createAsyncThunk<
  TransactionBasedAnalysis,
  { tba: CreateTbaApiData; t: TFunction },
  { state: RootState; rejectValue: Error }
>('tbas/save', async ({ tba, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const { data } = await httpService.request<{ result: TransactionBasedAnalysis }>({
      method: 'post',
      apiUrlKey: 'baseUrl',
      relativePath: 'tbas',
      data: tba
    });

    return data.result;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface CopyTBAParameters {
  containerId: number;
  name: string;
  taxYear: number;
  isService: number;
  isPlaceholder: number;
  tenantId: number;
  archived: number;
  deleted: string;
  sourceTbaId: number;
  destinationTbaId: number;
}

export const copyTBA = createAsyncThunk<
  unknown,
  { data: CopyTBAParameters; t: TFunction },
  { state: RootState; rejectValue: Error }
>('tba/copy', async ({ data, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });

    const { sourceTbaId, destinationTbaId, ...rest } = data;

    const result = await httpService.request<{ result: unknown }>({
      method: 'put',
      apiUrlKey: 'baseUrl',
      relativePath: `tbas/copy/${sourceTbaId}/to/${destinationTbaId}`,
      data: rest
    });

    return result.data.result;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const saveTBA = createAsyncThunk<
  TransactionBasedAnalysis,
  { tba: Partial<TransactionBasedAnalysis>; t: TFunction },
  { state: RootState; rejectValue: Error }
>('tbas/save', async ({ tba, t }, { rejectWithValue }) => {
  try {
    httpService.setErrorMessageResolver((error: AxiosError) => {
      const errors = Object.keys(error.response?.data.errors).map((key) => error.response!.data.errors[key]);
      return errors.map((message: string) => t(`errors:${message}`));
    });
    const isEdit: boolean = typeof tba.tbaId === 'number';
    const relativePath = isEdit ? `tbas/${tba.tbaId!}` : 'tbas';
    const { data } = await httpService.request<{ data: TransactionBasedAnalysis }>({
      method: isEdit ? 'patch' : 'post',
      apiUrlKey: 'baseUrl',
      relativePath,
      data: tba
    });

    return data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchTbaEvaluationMethods = createAsyncThunk<TbaEvaluationMethod[], void, { rejectValue: Error }>(
  'tba/evaluation-methods/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { methods: TbaEvaluationMethod[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'tba-evaluation-methods'
        })
      ).data?.result?.methods;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchTbaSearchStrategy = createAsyncThunk<string, number, { rejectValue: Error }>(
  'tba/by-id/search-strategy/fetch',
  async (tbaId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ result: { searchStrategy: string } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `tbas/${tbaId}/search-strategy`
        })
      ).data?.result.searchStrategy;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface EditSearchStrategyParams {
  tbaId: number;
  strategy: string;
  container: Container;
}

export const editTbaSearchStrategy = createAsyncThunk<void, EditSearchStrategyParams, { rejectValue: Error }>(
  'tbas/editSearchStrategy',
  async ({ tbaId, strategy, container }, { rejectWithValue }) => {
    try {
      const relativePath = `tbas/${tbaId}/search-strategy`;
      return (
        await httpService.request<any>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath,
          data: { container, strategy }
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

interface uncontrolledTransactionFileDefinition {
  allowed: any;
  hash: string;
  headers: string[];
}

export const uploadUncontrolledTransactionsFile = createAsyncThunk<
  uncontrolledTransactionFileDefinition,
  UploadUncontrolledTransactionParams,
  { rejectValue: Error }
>('uncontrolled-transactions/tba/upload/file', async ({ file }, { rejectWithValue }) => {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  try {
    const relativePath = `uncontrolled-transactions/tba/upload`;
    const { data } = await httpService.request<any>({
      method: 'post',
      apiUrlKey: 'baseUrl',
      relativePath,
      data: formData,
      headers: {
        'content-type': 'multipart/form-data',
        accept: 'Application/json'
      }
    });
    return data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const importUncontrolledTransactionMappings = createAsyncThunk<
  any,
  ImportUncontrolledTransactionMappingsParams,
  { rejectValue: Error }
>('uncontrolled-transactions/tba/by-id/mappings', async ({ tbaId, mappings, hash, container }, { rejectWithValue }) => {
  try {
    const relativePath = `uncontrolled-transactions/tba/${tbaId}/mappings`;
    const data = {
      mappings,
      hash,
      container
    };
    return (
      await httpService.request<any>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath,
        data
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchRoyaltyStatTransactions = createAsyncThunk<
  void,
  ImportRoyaltyStatTransactionsParams,
  { rejectValue: Error }
>('pba/royaltyStatTransactions/post', async ({ tbaId, folderId, transactionType }, { rejectWithValue }) => {
  try {
    const relativePath = `uncontrolled-transactions/tba/${tbaId}/royalty-stat`;
    const data = {
      folderId,
      transactionType
    };
    return (
      await httpService.request<any>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath,
        data
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface UploadUncontrolledTransactionParams {
  file: File;
}

export interface ImportData {
  header: string;
  field?: string;
  error?: string;
}
export interface UploadedUncontrolledData {
  header: string;
  field?: string;
  error?: string;
}

export interface UploadMultipleTransactionsParams {
  hash: string;
  mappings: ImportData[];
  headers: string[];
}

export interface ImportUncontrolledTransactionMappingsParams {
  tbaId: number;
  mappings: ImportData[];
  hash: string;
  container: Container;
}

export interface ImportRoyaltyStatTransactionsParams {
  tbaId: number;
  folderId: number;
  transactionType: TransactionType;
}

export interface TbaControlledParameter {
  parameterValue: number;
  tba: {
    tbaId: number;
    exclude: boolean;
    tbaEvaluationMethod: { tbaEvaluationMethodId?: number };
    transactionType: { transactionTypeId?: number };
  };
  tbaParameter: {
    tbaParameterId: number;
    type: FinancialInfoFieldValues;
    description: FinancialInfoFieldValues;
    calculated: boolean;
    optional: boolean;
  };
}

export interface TbaParameter extends TbaControlledParameter {
  tbaControlledParameterId: number;
}

export interface saveTbaFinancialInfoParams {
  tbaId?: number;
  addTransactionIds: number[];
  container?: Container;
  removeTransactionIds: number[];
  tbaControlledParameters: TbaControlledParameter[];
}

export const saveTbaFinancialInfo = createAsyncThunk<number, saveTbaFinancialInfoParams, { rejectValue: Error }>(
  'tbas/saveTbaFinancialInfo',
  async (saveTbaParams, { rejectWithValue }) => {
    const { tbaId } = saveTbaParams;
    delete saveTbaParams.tbaId;
    try {
      const relativePath = `tbas/${tbaId!}/transactions`;
      return (
        await httpService.request<any>({
          method: 'put',
          apiUrlKey: 'baseUrl',
          relativePath,
          data: saveTbaParams
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface TbaRangeType {
  excluded: true;
  name: string;
  statisticalMethod: 1;
  tba: TransactionBasedAnalysis;
  tbaRangeTypeId: number;
  useForDestinationTaxJurisdiction: boolean;
  useForSourceTaxJurisdiction: boolean;
}

export interface TbaTaxJurisdictionCodes {
  source: string;
  destination: string;
  sourceFullName: string;
  destinationFullName: string;
}

export const fetchTbaRangeTypes = createAsyncThunk<
  { tbaRangeTypes: TbaRangeType[]; taxJurisdiction: TbaTaxJurisdictionCodes },
  number,
  { rejectValue: Error }
>('tbas/fetchTbaRangeTypes', async (tbaId, { rejectWithValue }) => {
  try {
    const relativePath = `range-types/tba/${tbaId}`;
    return (
      await httpService.request<{
        result: {
          count: number;
          tbaRangeTypes: TbaRangeType[];
          taxJurisdiction: TbaTaxJurisdictionCodes;
        };
      }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath
      })
    ).data?.result;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface CreateTbaRangeType {
  container: Container;
  name: string;
  statisticalMethod: number;
  tba: TransactionBasedAnalysis;
  useForSourceTaxJurisdiction?: boolean;
  useForDestinationTaxJurisdiction?: boolean;
}

export const createTbaRangeType = createAsyncThunk<number, CreateTbaRangeType, { rejectValue: Error }>(
  'tbas/createTbaRangeType',
  async (createTbaRangeTypePayload, { rejectWithValue }) => {
    try {
      const relativePath = `range-types`;
      return (
        await httpService.request<any>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath,
          data: createTbaRangeTypePayload
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface UpdateTbaRangeType {
  container: Container;
  name: string;
  excluded: boolean;
  statisticalMethod: number;
  useForSourceTaxJurisdiction?: boolean;
  useForDestinationTaxJurisdiction?: boolean;
}

export const updateTbaRangeType = createAsyncThunk<
  number,
  { rangeTypeId: number; data: UpdateTbaRangeType },
  { rejectValue: Error }
>('tbas/updateTbaRangeType', async (payload, { rejectWithValue }) => {
  try {
    const relativePath = `range-types/${payload.rangeTypeId}`;
    await httpService.request<any>({
      method: 'patch',
      apiUrlKey: 'baseUrl',
      relativePath,
      data: payload.data
    });
    return payload.rangeTypeId;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deleteTbaRangeType = createAsyncThunk<number, number, { rejectValue: Error }>(
  'tbas/deleteTbaRangeType',
  async (rangeTypeId, { rejectWithValue }) => {
    try {
      const relativePath = `range-types/${rangeTypeId}`;
      await httpService.request<any>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath
      });
      return rangeTypeId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface StatisticalMethod {
  id: number;
  name: string;
}

export const fetchStatisticalMethods = createAsyncThunk<StatisticalMethod[], void, { rejectValue: Error }>(
  'tbas/fetchStatisticalMethods',
  async (_, { rejectWithValue }) => {
    try {
      const relativePath = `range-types/statistical-methods`;
      return (
        await httpService.request<{ result: { statisticalMethods: StatisticalMethod[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath
        })
      ).data?.result.statisticalMethods;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface TbaRangeResult {
  discussion?: string;
  inRange: boolean;
  lowerQuartile?: number;
  upperQuartile?: number;
  minimum?: number;
  median?: number;
  maximum?: number;
  tba: TransactionBasedAnalysis;
  tbaRangeResultId: number;
  tbaRangeType: TbaRangeType;
  testedValue: number;
}

export const fetchTbaRangeResults = createAsyncThunk<TbaRangeResult[], number, { rejectValue: Error }>(
  'tbas/fetchTbaRangeResults',
  async (tbaId, { rejectWithValue }) => {
    try {
      const relativePath = `range-results/tba/${tbaId}`;
      return (
        await httpService.request<{ result: { counter: number; tbaRangeResults: TbaRangeResult[] } }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath
        })
      ).data?.result.tbaRangeResults;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface TbaRangeResultUpdate extends TbaRangeResult {
  container: Container;
  collapsed: boolean;
}

export const updateTbaRangeResult = createAsyncThunk<number, TbaRangeResultUpdate, { rejectValue: Error }>(
  'tbas/updateTbaRangeResult',
  async (updateTbaRangeResultPayload, { rejectWithValue }) => {
    const { tbaRangeResultId } = updateTbaRangeResultPayload;
    try {
      const relativePath = `range-results/${tbaRangeResultId}`;
      return (
        await httpService.request<any>({
          method: 'patch',
          apiUrlKey: 'baseUrl',
          relativePath,
          data: updateTbaRangeResultPayload
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const tbafetchFunctionalAnalysisCharacteristics = createAsyncThunk<
  TBAFunctionalAnalysisCharacteristic[],
  { functionalAnalysisId: number; areaId: number; FATypeId: number },
  { rejectValue: Error }
>(`functional-analysis/area/type/fetch`, async ({ functionalAnalysisId, areaId, FATypeId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: TBAFunctionalAnalysisCharacteristic[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/${functionalAnalysisId}/area/${areaId}/type/${FATypeId}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const initializeTBAFunctionalAnalysis = createAsyncThunk<
  FunctionalAnalysisCharacteristic[],
  { functionalAnalysisId: number; FATypeId: number },
  { rejectValue: Error }
>(`functional-analysis/area/type/fetch`, async ({ functionalAnalysisId, FATypeId }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: FunctionalAnalysisCharacteristic[] }>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/${functionalAnalysisId}/initialize/type/${FATypeId}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const tbaFunctionalAnalysisTypeId = 1;
export const updateTBAFunctionalAnalysisCharacteristic = createAsyncThunk<
  number,
  {
    characteristicId: number;
    characteristic: TBAFunctionalAnalysisCharacteristic;
  },
  { rejectValue: Error }
>(
  'functional-analysis/characteristic/type/patch',
  async ({ characteristicId, characteristic }, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/characteristic/${characteristicId}/type/${tbaFunctionalAnalysisTypeId}`,
        data: characteristic
      });
      return characteristicId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const createTBAFunctionalAnalysisCharacteristic = createAsyncThunk<
  TBAFunctionalAnalysisCharacteristic,
  {
    functionalAnalysisId: number;
    areaId: number;
    characteristic: { characteristicName: string; container: Container | undefined };
  },
  { rejectValue: Error }
>(
  'functional-analysis/area/type/post',
  async ({ functionalAnalysisId, areaId, characteristic }, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: TBAFunctionalAnalysisCharacteristic }>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `functional-analysis/${functionalAnalysisId}/characteristic/area/${areaId}/type/${tbaFunctionalAnalysisTypeId}`,
          data: characteristic
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const reinitiaizeTBAtoFionaDefualts = createAsyncThunk<
  void,
  {
    functionalAnalysisId: number;
  },
  { rejectValue: Error }
>('functional-analysis//type/reinitialize', async ({ functionalAnalysisId }, { rejectWithValue }) => {
  try {
    await httpService.request({
      method: 'post',
      apiUrlKey: 'baseUrl',
      relativePath: `functional-analysis/${functionalAnalysisId}/reinitialize/type/${tbaFunctionalAnalysisTypeId}?overwrite=default`
    });
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deleteTBAFunctionalAnalysisCharacteristic = createAsyncThunk<number, number, { rejectValue: Error }>(
  'functional-analysis/characteristic/type/delete',
  async (characteristicId, { rejectWithValue }) => {
    try {
      await httpService.request({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/characteristic/${characteristicId}/type/${tbaFunctionalAnalysisTypeId}`
      });
      return characteristicId;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const updateTBAFunctionalAnalysisNarrative = createAsyncThunk<
  any,
  { params: UpdateFunctionalAnalysisNarrativeParams },
  { rejectValue: Error }
>('functional-analysis/copy', async ({ params }, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: any }>({
        method: 'put',
        apiUrlKey: 'baseUrl',
        relativePath: `functional-analysis/copy/${String(params.tbaId)}/type/${params.type}`,
        data: { ...params }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const tbasSlice = createSlice({
  name: 'tbas',
  initialState,
  reducers: {
    clearTbaData: (state) => {
      state.tbas = null;
      state.currentTBA = undefined;
      state.currentTBASearchStrategy = '';
      state.uncontrolledTransactions = [];
      state.currentTBAParameters = [];
      state.currentTbaRangeTypes = [];
      state.currentTbaRangeResults = undefined;
    },
    updateTBAFADiscussionText: (state, action) => {
      let selectedFAState =
        action.payload.FAType === 1
          ? state.functionalAnalysisCharacteristics?.Functions
          : state.functionalAnalysisCharacteristics?.Risks;
      selectedFAState = action.payload.FAType === 3 ? state.functionalAnalysisCharacteristics?.Assets : selectedFAState;
      const currentFA = selectedFAState?.find(
        (type) => type.tbaFunctionalAnalysisDataId === action.payload.tbaFunctionalAnalysisDataId
      );
      if (currentFA) {
        currentFA.discussion = action.payload.discussion;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(deleteTBA.fulfilled, (state: TBAsState, action: PayloadAction<number>) => {
        const idx = (state.tbas ?? []).findIndex(({ tbaId }) => tbaId === action.payload);
        if (idx >= 0) {
          state.tbas!.splice(idx, 1);
        }
      })
      .addCase(
        deleteTBAUncontrolledTransaction.fulfilled,
        (state: TBAsState, action: PayloadAction<{ tbaId: number; transactionId: number }>) => {
          if (state.currentTBA?.uncontrolledTransactions) {
            state.currentTBA.uncontrolledTransactions = state.currentTBA.uncontrolledTransactions?.filter(
              (uncontrolledTransaction) => {
                return uncontrolledTransaction.transactionId !== action.payload.transactionId;
              }
            );
          }
        }
      )
      .addCase(
        fetchUncontrolledTransactionParameters.fulfilled,
        (state: TBAsState, action: PayloadAction<UncontrolledTransactionParameters[]>) => {
          if (state.currentTBA) {
            state.currentUncontrolledTransactionParameters = action.payload;
          }
        }
      )
      .addCase(
        fetchUncontrolledTransactionComparability.fulfilled,
        (state: TBAsState, action: PayloadAction<TbaUncontrolledTransactionComparability[]>) => {
          if (state.currentTBA) {
            state.currentUncontrolledTransactionComparability = action.payload;
          }
        }
      )
      .addCase(
        fetchComparabilityOptions.fulfilled,
        (state: TBAsState, action: PayloadAction<ComparabilityOption[]>) => {
          state.comparabilityOptions = action.payload;
        }
      )
      .addCase(fetchTBAs.fulfilled, (state: TBAsState, action: PayloadAction<TransactionBasedAnalysis[]>) => {
        state.tbas = action.payload;
      })
      .addCase(currentTBA, (state: TBAsState, action: PayloadAction<TransactionBasedAnalysis>) => {
        state.currentTBA = action.payload;
      })
      .addCase(fetchCurrentTBA.fulfilled, (state: TBAsState, action: PayloadAction<fetchTBAResult>) => {
        state.currentTBA = action.payload.tba;
        state.currentTBAParameters = action.payload.tbaParameters;
        state.currentTBALocked = action.payload.locked;
      })
      .addCase(
        fetchTbaEvaluationMethods.fulfilled,
        (state: TBAsState, action: PayloadAction<TbaEvaluationMethod[]>) => {
          state.tbaEvaluationMethods = action.payload;
        }
      )
      .addCase(fetchTbaSearchStrategy.fulfilled, (state: TBAsState, action: PayloadAction<string>) => {
        state.currentTBASearchStrategy = action.payload;
      })
      .addCase(
        fetchUncontrolledTransactions.fulfilled,
        (state: TBAsState, action: PayloadAction<{ transactions: UncontrolledTransaction[]; count: number }>) => {
          state.uncontrolledTransactions = action.payload.transactions;
        }
      )
      .addCase(
        fetchTbaRangeTypes.fulfilled,
        (
          state: TBAsState,
          action: PayloadAction<{
            tbaRangeTypes: TbaRangeType[];
            taxJurisdiction: TbaTaxJurisdictionCodes;
          }>
        ) => {
          state.currentTbaRangeTypes = action.payload.tbaRangeTypes;
          state.currentTbaTaxJurisdictionCodes = action.payload.taxJurisdiction;
          state.loading.fetchTbaRangeType = FetchLoadingStateEnum.fulfilled;
        }
      )
      .addCase(fetchTbaRangeTypes.pending, (state: TBAsState) => {
        state.currentTbaRangeTypes = [];
        state.currentTbaTaxJurisdictionCodes = undefined;
        state.loading.fetchTbaRangeType = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchStatisticalMethods.fulfilled, (state: TBAsState, action: PayloadAction<StatisticalMethod[]>) => {
        state.statisticalMethods = action.payload;
      })
      .addCase(fetchTbaRangeResults.fulfilled, (state: TBAsState, action: PayloadAction<TbaRangeResult[]>) => {
        state.currentTbaRangeResults = action.payload;
      })
      .addCase(tbafetchFunctionalAnalysisCharacteristics.fulfilled, (state: TBAsState, action) => {
        const { areaId } = action.meta.arg;
        if (areaId === AreaIds.AreaIdFunctions) {
          state.functionalAnalysisCharacteristics!.Functions = action.payload;
        }

        if (areaId === AreaIds.AreaIdRisks) {
          state.functionalAnalysisCharacteristics!.Risks = action.payload;
        }

        if (areaId === AreaIds.AreaIdAssets) {
          state.functionalAnalysisCharacteristics!.Assets = action.payload;
        }
      })
      .addCase(fetchRoyaltyStatTransactions.pending, (state: TBAsState) => {
        state.loading.fetchRoyaltyStatTransactions = FetchLoadingStateEnum.loading;
      })
      .addCase(fetchRoyaltyStatTransactions.fulfilled, (state: TBAsState) => {
        state.loading.fetchRoyaltyStatTransactions = FetchLoadingStateEnum.fulfilled;
      })
      .addCase(fetchRoyaltyStatTransactions.rejected, (state: TBAsState) => {
        state.loading.fetchRoyaltyStatTransactions = FetchLoadingStateEnum.error;
      })
      .addMatcher(
        (action) => action.type.match(/^tbas\/.+\/pending$/),
        (state: TBAsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^tbas\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export const { clearTbaData, updateTBAFADiscussionText } = tbasSlice.actions;

export default tbasSlice.reducer;
