import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@material-ui/icons';
import { AddSubtopicModal } from './AddSubtopicModal';
import { CopyModal } from './CopyModal';
import { ResetModal } from './ResetModal';
import { CustomIconMenu } from '../../../components/CustomIconMenu';
import { BackgroundInfoSubTopic, CopyBackgroundInfoSource } from '../../../models';
import { EllipsisMenuProps } from '../StatementOfFactsOverview.proptype';

export const EllipsisMenu = ({
  namespace,
  name,
  subtopic,
  jurisdiction,
  fetchCopySources,
  onAddSubtopic,
  onCopySubtopics,
  onResetSubtopics
}: EllipsisMenuProps) => {
  const { t } = useTranslation();
  const [isCopyOpen, setCopyOpen] = useState(false);
  const [copyOptions, setCopyOptions] = useState<CopyBackgroundInfoSource[]>([]);
  const [isAddOpen, setAddOpen] = useState(false);
  const [isResetOpen, setResetOpen] = useState(false);
  return (
    <>
      <CustomIconMenu
        Icon={MoreHoriz}
        menuItems={[
          {
            text: t(`${namespace}:action-copy`),
            handleOnClick: () => {
              setCopyOpen(true);
              void fetchCopySources(jurisdiction).then(setCopyOptions);
            }
          },
          {
            text: t('action-add-subtopic'),
            handleOnClick: () => {
              setAddOpen(true);
            }
          },
          {
            text: t('action-reset-subtopics'),
            handleOnClick: () => {
              setResetOpen(true);
            }
          }
        ]}
      />
      {isCopyOpen && (
        <CopyModal
          isOpen
          name={name}
          namespace={namespace}
          jurisdiction={jurisdiction}
          options={copyOptions}
          onSubmit={({ source }) => {
            onCopySubtopics({ jurisdiction, source });
            setCopyOpen(false);
          }}
          onClose={() => {
            setCopyOpen(false);
          }}
        />
      )}
      {isAddOpen && (
        <AddSubtopicModal
          isOpen
          name={name}
          namespace={namespace}
          jurisdiction={jurisdiction}
          topicName={subtopic?.topicName ?? ''}
          onSubmit={(details) => {
            if (subtopic) {
              const created = ({
                topicId: subtopic.topicId,
                topicName: subtopic.topicName,
                currentSubtopicIndex: subtopic.subtopicSortOrder - 1,
                ...details
              } as unknown) as BackgroundInfoSubTopic;
              onAddSubtopic({ jurisdiction, subtopic: created });
            }

            setAddOpen(false);
          }}
          onClose={() => {
            setAddOpen(false);
          }}
        />
      )}
      {isResetOpen && (
        <ResetModal
          isOpen
          name={name}
          jurisdiction={jurisdiction}
          onClose={(isConfirmed) => {
            if (subtopic && isConfirmed) {
              onResetSubtopics({ jurisdiction, subtopic });
            }

            setResetOpen(false);
          }}
        />
      )}
    </>
  );
};
