import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { RouteOptionsProps } from './RouteOptions.proptype';

const RouteOptions = ({ RootRoute, ChildRoutes }: RouteOptionsProps) => {
  const match = useRouteMatch();
  return (
    <Switch>
      {ChildRoutes?.map((item) => (
        <Route key={item.path} path={`${match.path}${item.path}`}>
          {item.component}
        </Route>
      ))}
      <Route path={match.path}>{RootRoute}</Route>
    </Switch>
  );
};

export default RouteOptions;
