import { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { NotificationsContext } from './Notifications';
import Router from './Router';
import { IdleTimeout } from '../hocs/idleTimeout';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import designTokens from '../styles/designTokens';
import { buildTheme, getAppConfig } from '../utils';

const DIYTheme = buildTheme(designTokens);

const App = () => {
  const [featureFlagIsActive] = useFeatureFlags();
  const { exacteraDomainUrl, xbsEnvironmentShort } = getAppConfig();
  const currentEnv = window.location.hostname;

  const isRebrand = featureFlagIsActive('rebrandingPhase2');
  const cacheControlFlagOn = featureFlagIsActive('cacheControlTpHeader');
  localStorage.setItem('cacheControlTpHeader', cacheControlFlagOn ? 'true' : 'false');

  const isPageInitReloaded = localStorage.getItem('pageInitReloaded');
  // TODO: when we remove flags.tpRebranding feature flag, make sure to change public/index.html to use the new favicon
  if (featureFlagIsActive('tpRebranding')) {
    const faviconLink = document.querySelector("link[rel*='icon']");
    document.title = 'Exactera Transfer Pricing';
    if (faviconLink) {
      (faviconLink as HTMLLinkElement).href = '/newexperience/ex-favicon.ico';
    }
  }

  localStorage.setItem('isRebrand', isRebrand);
  if (isRebrand && !isPageInitReloaded) {
    localStorage.setItem('pageInitReloaded', 'true');
    window.location.reload();
  }

  useEffect(() => {
    const redirectUrl =
      xbsEnvironmentShort === 'prod'
        ? `https://tp.${exacteraDomainUrl}`
        : `https://tp.${xbsEnvironmentShort}.${exacteraDomainUrl}`;

    if (currentEnv.includes('tp-ui')) {
      window.location.replace(redirectUrl);
    }
  }, [xbsEnvironmentShort, exacteraDomainUrl, currentEnv]);

  return (
    <ThemeProvider theme={DIYTheme}>
      <CssBaseline />
      <NotificationsContext>
        <IdleTimeout>
          <Router />
        </IdleTimeout>
      </NotificationsContext>
    </ThemeProvider>
  );
};

export default App;
