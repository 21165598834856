import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography, Switch, Theme, Card, CardContent, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import { SearchBox } from '../../../components';
import { hasEditAccess } from '../../../utils';
import { IndustrialCodeValue, TestedPartyDistributorProps } from '../TestedPartyDistributor.proptype';

export interface ProgressButtonProps {
  isValueAddedDistributor: boolean;
  onChange?: (complete: boolean) => void;
}

const useStyles = makeStyles<Theme, ProgressButtonProps>((theme) => ({
  sicCard: {
    width: '20rem',
    justifyContent: 'space-between',
    margin: '1rem'
  },
  industrySelectionCard: {
    width: '35rem',
    justifyContent: 'space-between',
    margin: '1rem'
  },
  contentHeaderBorder: {
    backgroundColor: theme.palette.background.default,
    marginTop: 0,
    marginBottom: 0,
    fontSize: '1rem'
  },
  SICText: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    padding: '6px',
    borderRadius: '2px'
  },
  sicContentCard: {
    height: '7rem'
  },
  labelRoot: {
    backgroundColor: ({ isValueAddedDistributor }) =>
      isValueAddedDistributor ? theme.palette.primary.light : theme.palette.secondary.light,
    borderRadius: 20,
    color: ({ isValueAddedDistributor }) =>
      isValueAddedDistributor ? theme.palette.primary.main : theme.palette.text.primary,
    margin: 0,
    padding: theme.spacing(0.5, 1.5, 0.5, 1),
    textTransform: 'uppercase'
  },
  label: {
    fontSize: '0.9em'
  },
  switchRoot: {
    display: 'flex',
    height: 12,
    marginRight: '1em',
    padding: 0,
    width: 28
  },
  switchBase: {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        opacity: 1
      }
    }
  },
  track: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: 1
  },
  thumb: {
    color: theme.palette.common.white,
    boxShadow: 'none',
    height: 8,
    width: 8
  }
}));

export const TestedPartyDistributor = ({
  toggleDistributor,
  displayedSicIndusties,
  currentSelectedSic,
  isValueAddedDistributor,
  onSelecteSICIndustry,
  trackGATime
}: TestedPartyDistributorProps) => {
  const {
    sicCard,
    sicContentCard,
    contentHeaderBorder,
    label,
    SICText,
    labelRoot,
    switchRoot,
    industrySelectionCard,
    ...switchClasses
  } = useStyles({
    isValueAddedDistributor
  });

  const { t } = useTranslation();

  useEffect(() => {
    trackGATime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box margin="0.5rem 3rem" display="flex">
        <Typography variant="subtitle1">{t('analysis:tested_party_characterization.is_value_added')}</Typography>
        <Box marginLeft="1rem">
          <FormControlLabel
            classes={{ root: labelRoot, label }}
            control={
              <Switch
                classes={{ root: switchRoot, ...switchClasses }}
                checked={isValueAddedDistributor}
                color={isValueAddedDistributor ? 'primary' : 'secondary'}
                disabled={!hasEditAccess()}
                onChange={() => {
                  toggleDistributor(isValueAddedDistributor);
                }}
              />
            }
            label={t(isValueAddedDistributor ? 'Yes' : 'No')}
          />
        </Box>
      </Box>
      <Box display="flex" marginLeft="1rem">
        <Box className={sicCard}>
          <Card className={sicContentCard}>
            <CardContent>
              <Typography>{t('analysis:tested_party_characterization.industry_code_type')}</Typography>
            </CardContent>
            <Box width="90%" margin="auto">
              <Typography variant="h2" className={clsx(contentHeaderBorder, SICText)}>
                <Box component="span">{t('analysis:tested_party_characterization.sic')}</Box>
              </Typography>
            </Box>
          </Card>
        </Box>
        <Box className={industrySelectionCard}>
          <Card className={sicContentCard}>
            <CardContent>
              <Typography>{t('analysis:tested_party_characterization.industry_selection')}</Typography>
            </CardContent>
            <Box width="90%" margin="auto">
              <SearchBox
                value={currentSelectedSic}
                getOptionLabel={(displayedSicIndustry) => {
                  return displayedSicIndustry
                    ? t(`${displayedSicIndustry.value} - ${t(`analysis:${displayedSicIndustry.name}`)}`)
                    : '';
                }}
                getOptionSelected={(option: IndustrialCodeValue, value: IndustrialCodeValue) =>
                  option.value === value.value
                }
                options={displayedSicIndusties}
                noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                placeholder={t('analysis:tested_party_characterization.search_industry_sic')}
                disabled={!hasEditAccess()}
                onChange={(event, selectedSICIndustry) => {
                  onSelecteSICIndustry(event, selectedSICIndustry!);
                }}
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
