import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FinancialAreas } from '@xbs/xbs-enums';
import { ConnectorProps } from './EntityDashboard.proptype';
import { BackgroundInfoType, FinancialCompletionStatus } from '../../models';
import { fetchEntities } from '../../redux/entities';
import { getEntityFinancialCompletionStatus } from '../../redux/entityFinancialV2/entityFinancial.actions';
import { fetchFinalReports, fetchInternalReports } from '../../redux/reports';
import { fetchStatementOfFacts } from '../../redux/statementOfFacts';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectEntity,
  selectStatementOfFactsCompletion,
  selectFinalReports,
  selectInternalReports,
  selectWorkingContainer,
  selectTransactionsByEntity,
  selectEntityFinancialBalanceSheetCompletionStatus,
  selectEntityFinancialIncomeStatementCompletionStatus
} from '../../selectors';
import { fetchLatestFinalReport } from '../../utils';
import { CompletedItem } from '../CompletedItemCard';
import DashboardCardEntityTransactions from '../DashboardCardEntityTransactions';
import DashboardCardEntityTransactionsTradingPairs from '../DashboardCardEntityTransactionsTradingPairs';
import { getReportName } from '../Reports/ReportsUtilities';

const financialAreaId = {
  balanceSheet: FinancialAreas.ByName.BalanceSheet.Id,
  incomeStatement: FinancialAreas.ByName.IncomeStatement.Id
};

const numberOfYears = {
  balanceSheet: 6,
  incomeStatement: 5
};

const generateCompletionByYear = (
  taxYear: number,
  numberOfYears: number,
  financialAreaId: number,
  financialCompletionStatus: FinancialCompletionStatus[]
) => {
  const completionByYear: Record<number, boolean> = {};
  const completion: CompletedItem[] = [];
  for (let index = 0; index < numberOfYears; index++) {
    const currentYear = taxYear - index;
    const yearStatus = financialCompletionStatus.find(
      (status) => status.taxYear === currentYear && status.financialAreaId === financialAreaId
    );
    completionByYear[currentYear] = Boolean(yearStatus?.isCompleted);
  }

  for (const year in completionByYear) {
    if (completionByYear) {
      completion.push({ label: year, completed: completionByYear[year] });
    }
  }

  completion.sort((a: any, b: any) => b.label - a.label);
  return completion;
};

const Connector = ({ entityId, component: Component }: ConnectorProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const container = useSelector(selectWorkingContainer)!;
  const entity = useSelector(selectEntity(entityId));
  const transactions = useSelector(selectTransactionsByEntity(entity?.entityId));
  const finalReports = useSelector(selectFinalReports(entityId));
  const balanceSheetfinancialCompletionStatus = useSelector(selectEntityFinancialBalanceSheetCompletionStatus);
  const incomeStatementfinancialCompletionStatus = useSelector(selectEntityFinancialIncomeStatementCompletionStatus);
  const internalReports = useSelector(selectInternalReports(entityId));
  const statementOfFactsCompletion = useSelector(
    selectStatementOfFactsCompletion({ type: BackgroundInfoType.Entity, id: entityId })
  );
  // undefined means not loaded, null means no final
  const finalReport = finalReports ? finalReports[0] ?? null : undefined;

  const balanceSheetCompletionByYear = generateCompletionByYear(
    container.taxYear,
    numberOfYears.balanceSheet,
    financialAreaId.balanceSheet,
    balanceSheetfinancialCompletionStatus
  );

  const incomeStatementCompletionByYear = generateCompletionByYear(
    container.taxYear,
    numberOfYears.incomeStatement,
    financialAreaId.incomeStatement,
    incomeStatementfinancialCompletionStatus
  );

  useEffect(() => {
    dispatch(fetchEntities());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTransactions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStatementOfFacts({ type: BackgroundInfoType.Entity, id: entityId }));
  }, [dispatch, entityId]);

  useEffect(() => {
    dispatch(getEntityFinancialCompletionStatus(entityId, financialAreaId.balanceSheet));
    dispatch(getEntityFinancialCompletionStatus(entityId, financialAreaId.incomeStatement));
  }, [dispatch, entityId]);

  useEffect(() => {
    dispatch(fetchInternalReports(entityId));
    dispatch(fetchFinalReports(entityId));
  }, [dispatch, entityId]);

  return (
    <Component
      entity={entity}
      transactions={transactions}
      legacy={[<DashboardCardEntityTransactions key="1" />, <DashboardCardEntityTransactionsTradingPairs key="2" />]}
      incomeStatementCompletion={incomeStatementCompletionByYear}
      balanceSheetCompletion={balanceSheetCompletionByYear}
      statementOfFactsCompletion={statementOfFactsCompletion}
      internalReports={internalReports}
      finalReport={finalReport}
      onDownload={async () => {
        if (finalReport) {
          const reportName = finalReport.internalLocalfileReportInstance
            ? getReportName({
                reportName: finalReport.name,
                reportTemplateId: finalReport.internalLocalfileReportInstance.templateId,
                version: finalReport.internalLocalfileReportInstance.version
              })
            : finalReport.name;
          return fetchLatestFinalReport(finalReport.binaryPath, reportName, container.containerId);
        }
      }}
      onNavigate={(path) => {
        history.push(path);
      }}
    />
  );
};

export default Connector;
