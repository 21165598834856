export * from './TestedPartyDistributor.proptype';

import { TestedPartyDistributor as InitialSearchTestedPartyDistributorComponent } from './Components/InitialSearchTestedPartyDistributor';
import { TestedPartyDistributor as Component } from './Components/TestedPartyDistributor';
import Connector from './connector';

export const TestedPartyDistributor = () => <Connector component={Component} />;
export const InitialSearchTestedPartyDistributor = () => (
  <Connector component={InitialSearchTestedPartyDistributorComponent} />
);
