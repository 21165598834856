import { FinancialAreas } from '@xbs/xbs-enums';
import { Dispatch } from 'redux';
import {
  SET_ENTITYFINANCIAL_DATA,
  SET_ENTITYFINANCIAL_REQUEST_STATUS,
  GetEntityFinancialDataType,
  UpdateEntityFinancialDataType,
  SET_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_ENTITY_ADDITIONAL_FINANCIAL_INFO,
  RESET_ENTITY_FINANCIAL_STATUS
} from './entityFinancial.types';
import { FinancialData, FinancialCompletionStatus, Container } from '../../models';
import httpService from '../../services/http';

const getEntityFinancialDataSuccess = (data: FinancialData[], financialAreaId: number) => ({
  type: SET_ENTITYFINANCIAL_DATA,
  payload: { data, financialAreaId }
});

const getEntityAdditionalFinancialInfoSuccess = (data: string, financialAreaId: number) => ({
  type: SET_ENTITY_ADDITIONAL_FINANCIAL_INFO,
  payload: { data, financialAreaId }
});

const setEntityFinancialRequestStatus = (
  status: string,
  financialAreaId = FinancialAreas.ByName.IncomeStatement.Id,
  error: unknown = null
) => ({
  type: SET_ENTITYFINANCIAL_REQUEST_STATUS,
  payload: {
    status,
    financialAreaId,
    error
  }
});

export const getEntityFinancialCompletionStatusSuccess = (
  data: FinancialCompletionStatus[],
  financialAreaId: number
) => ({
  type: SET_ENTITY_FINANCIAL_COMPLETION_STATUS,
  payload: { data, financialAreaId }
});

export const setEntityFinancialCompletionRequestStatus = (
  status: string,
  financialAreaId: number,
  error: unknown = null
) => ({
  type: SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  payload: {
    status,
    financialAreaId,
    error
  }
});

export const updateEntityFinancialCompletionStatusSuccess = (
  data: FinancialCompletionStatus,
  financialAreaId: number
) => ({
  type: SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS,
  payload: { data, financialAreaId }
});

export const setUpdateEntityFinancialCompletionRequestStatus = (
  status: string,
  financialAreaId: number,
  error: unknown = null
) => ({
  type: SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  payload: {
    status,
    financialAreaId,
    error
  }
});

export const resetEntityFinancialStatus = () => ({
  type: RESET_ENTITY_FINANCIAL_STATUS
});

export const getEntityFinancialData = ({
  entityId,
  financialAreaId,
  financialDataObjectTypeId
}: GetEntityFinancialDataType) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending', financialAreaId));

      const {
        data: { data }
      } = await httpService.request<{ data: FinancialData[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `financial-data/${entityId}/area/${financialAreaId}/type/${financialDataObjectTypeId}`
      });

      dispatch(getEntityFinancialDataSuccess(data, financialAreaId));
      dispatch(setEntityFinancialRequestStatus('success', financialAreaId));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', financialAreaId, error));
    }
  };
};

export const getEntityAdditionalFinancialInfo = (entityId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending', FinancialAreas.ByName.IncomeStatement.Id));

      const {
        data: { data }
      } = await httpService.request<{ data: string }>({
        method: 'get',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/legal-entity/${entityId}/financials-info`
      });

      dispatch(getEntityAdditionalFinancialInfoSuccess(data, FinancialAreas.ByName.IncomeStatement.Id));
      dispatch(setEntityFinancialRequestStatus('success', FinancialAreas.ByName.IncomeStatement.Id));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', FinancialAreas.ByName.IncomeStatement.Id, error));
    }
  };
};

export const updateEntityFinancialsInfo = ({
  container,
  financialsInfo,
  entityId
}: {
  container: Container;
  financialsInfo: string;
  entityId: number;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending', FinancialAreas.ByName.IncomeStatement.Id));

      await httpService.request<{ data: any }>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/legal-entity/${entityId}/financials-info`,
        data: { container, financialsInfo }
      });

      dispatch(setEntityFinancialRequestStatus('success', FinancialAreas.ByName.IncomeStatement.Id));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', FinancialAreas.ByName.IncomeStatement.Id, error));
    }
  };
};

export const updateEntityFinancialData = ({
  entityId,
  financialAreaId,
  financialDataObjectTypeId,
  financialData,
  valueEnteredIn
}: UpdateEntityFinancialDataType) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialRequestStatus('pending', financialAreaId));

      const dataByTaxYear: Record<number, FinancialData[]> = {};

      // Separating data by tax year because financialData post method API requires for data to have same tax year.
      for (const item of financialData) {
        if (typeof item.value !== 'number' && item.value !== null) {
          continue;
        }

        if (!dataByTaxYear[item.taxYear]) {
          dataByTaxYear[item.taxYear] = [];
        }

        dataByTaxYear[item.taxYear].push(item);
      }

      const promises = Object.values(dataByTaxYear).map(async (item) => {
        return httpService.request<{ data: FinancialData[] }>({
          method: 'post',
          apiUrlKey: 'baseUrl',
          relativePath: `financial-data/${entityId}/area/${financialAreaId}/type/${financialDataObjectTypeId}`,
          data: {
            valueEnteredIn,
            financialData: item
          }
        });
      });

      await Promise.all(promises);
      dispatch(setEntityFinancialRequestStatus('success', financialAreaId));
    } catch (error: unknown) {
      dispatch(setEntityFinancialRequestStatus('failure', financialAreaId, error));
    }
  };
};

export const getEntityFinancialCompletionStatus = (entityId: number, financialAreaId: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setEntityFinancialCompletionRequestStatus('pending', financialAreaId));
      const {
        data: { data }
      } = await httpService.request<{ data: FinancialCompletionStatus[] }>({
        method: 'get',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/entity-financials/completion-status/${entityId}`
      });
      dispatch(getEntityFinancialCompletionStatusSuccess(data, financialAreaId));
      dispatch(setEntityFinancialCompletionRequestStatus('success', financialAreaId));
    } catch (error: unknown) {
      dispatch(setEntityFinancialCompletionRequestStatus('failure', financialAreaId, error));
    }
  };
};

export const updateEntityFinancialCompletionStatus = (financialStatus: FinancialCompletionStatus) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setUpdateEntityFinancialCompletionRequestStatus('pending', financialStatus.financialAreaId));

      await httpService.request<{ data: FinancialCompletionStatus }>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/entity-financials/completion-status`,
        data: {
          financialCompletionStatus: financialStatus
        }
      });

      dispatch(updateEntityFinancialCompletionStatusSuccess(financialStatus, financialStatus.financialAreaId));
      dispatch(setUpdateEntityFinancialCompletionRequestStatus('success', financialStatus.financialAreaId));
    } catch (error: unknown) {
      dispatch(setUpdateEntityFinancialCompletionRequestStatus('failure', financialStatus.financialAreaId, error));
    }
  };
};
