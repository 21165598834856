import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { PrimaryFunctions, CurrencyScale } from '@xbs/xbs-enums';
import { TFunction } from 'i18next';
import { EntitiesProps } from './Entities.proptype';
import { Container, Country, Currency, Entity, ParentEntity, PrimaryFunction } from '../../models';
import { deleteEntity, fetchEntities, saveEntity } from '../../redux/entities';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectCountries,
  selectEntitiesList,
  selectTransactionsList,
  selectTransactionsByEntities,
  selectUPE,
  selectPrimaryFunctions,
  selectWorkingContainer,
  selectCurrencies
} from '../../selectors';
import { AppDispatch } from '../../store';
import { formatPercentage } from '../../utils';
import { CurrencyConversionModal } from '../CurrencyConversionModal';
import { EntitiesImportModal } from '../EntitiesImportModal';
import { EntityAddEditModal, EntityInputs, UPEInputs } from '../EntityAddEditModal';

const mapFormToEntity = ({
  entityFormData,
  entity,
  workingContainer,
  isUpe
}: {
  entityFormData: EntityInputs | UPEInputs;
  entity: Entity | null;
  workingContainer: Container;
  isUpe: boolean;
}): Partial<Entity> => ({
  entityId: entity?.entityId,
  primaryFunction: {
    primaryFunctionId: PrimaryFunctions.ById[entityFormData.primaryFunction]?.Id,
    name: PrimaryFunctions.ById[entityFormData.primaryFunction]?.Name
  },
  otherPrimaryFunction: entityFormData.otherPrimaryFunction,
  domicile: entityFormData.domicile?.data,
  taxJurisdiction: entityFormData.domicile?.data.defaultJurisdiction ?? entityFormData.domicile?.data,
  container: workingContainer,
  code: entityFormData.entityCode,
  name: entityFormData.entityFullName,
  fiscalYearEnd: entityFormData.fiscalYearEnd,
  description: entityFormData.description,
  ...(isUpe
    ? {
        upeCurrency: (entityFormData as UPEInputs).currency.data,
        upeName: (entityFormData as UPEInputs).upeName,
        upeCurrencyScale: entity?.upeCurrencyScale ?? CurrencyScale.ByName.FullValue.Id
      }
    : {
        parentEntities: [
          {
            parent: (entityFormData as EntityInputs).parentEntity1?.data,
            ownership: (entityFormData as EntityInputs).ownershipPercent1
              ? formatPercentage(((entityFormData as EntityInputs).ownershipPercent1 as number) / 100, 6)
              : null
          },
          (entityFormData as EntityInputs).parentEntity2 &&
            (entityFormData as EntityInputs).ownershipPercent2 && {
              parent: (entityFormData as EntityInputs).parentEntity2!.data,
              ownership: formatPercentage(((entityFormData as EntityInputs).ownershipPercent2 as number) / 100, 6)
            },
          (entityFormData as EntityInputs).parentEntity3 &&
            (entityFormData as EntityInputs).ownershipPercent3 && {
              parent: (entityFormData as EntityInputs)!.parentEntity3!.data,
              ownership: formatPercentage(((entityFormData as EntityInputs).ownershipPercent3 as number) / 100, 6)
            }
        ].filter(Boolean) as ParentEntity[]
      })
});

const getEntityOptions = (entityToEdit: Entity | undefined, entities: Entity[]) => {
  const filteredEntities = entityToEdit
    ? entities.filter((entity) => entity.entityId !== entityToEdit.entityId)
    : entities;

  return (filteredEntities ?? [])
    .map((entity) => ({ value: entity.code, data: entity }))
    .sort((ent1, ent2) => (ent1.value < ent2.value ? -1 : ent1.value > ent2.value ? 1 : 0));
};

const getCurrencyOptions = (currencies: Currency[], t: TFunction) =>
  currencies.map((currency: Currency) => {
    return { value: t('label-name-and-code', currency), data: currency };
  });

const getDomicileOptions = (countries: Country[], t: TFunction) =>
  countries.map((country: Country) => ({ value: t('label-name-and-code', country), data: country }));

const getPrimaryFunctionOptions = (primaryFunctions: PrimaryFunction[], t: TFunction) =>
  primaryFunctions.map((primaryFunction: PrimaryFunction) => ({
    title: t(`entities:primary-function-${primaryFunction.name}`),
    value: primaryFunction.primaryFunctionId
  }));

const Connector = ({ component: Component }: { component: ComponentType<EntitiesProps> }) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const transactions = useSelector(selectTransactionsList);
  const transactionsByEntityId = useSelector(selectTransactionsByEntities);
  const entities = useSelector(selectEntitiesList);
  const countries = useSelector(selectCountries);
  const currencies = useSelector(selectCurrencies);
  const upeId = useSelector(selectUPE)?.entityId;
  const primaryFunctions = useSelector(selectPrimaryFunctions);
  const workingContainer = useSelector(selectWorkingContainer);
  const hasTransactions = transactionsByEntityId.get(upeId)?.length > 0;
  const hasNonUpeEntities = (entities ?? []).filter((entity) => entity.entityId !== upeId).length > 0;

  const [isCurrencyConversionOpen, setIsCurrencyConversionOpen] = useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isCreatingUPE, setIsCreatingUPE] = useState(false);
  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);
  const isUpe = isCreatingUPE || selectedEntity?.entityId === upeId;

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (transactions === null) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  const handleClose = () => {
    if (isCreatingUPE) {
      setIsCreatingUPE(false);
    }

    setSelectedEntity(null);
    setIsEntityModalOpen(false);
  };

  const handleSubmit = async (entityFormData: EntityInputs) => {
    const entity = mapFormToEntity({
      entityFormData,
      entity: selectedEntity,
      workingContainer: workingContainer!,
      isUpe
    });
    unwrapResult(await dispatch(saveEntity(entity)));
    handleClose();
  };

  return (
    <>
      <Component
        entities={entities}
        upeId={upeId}
        countries={countries}
        transactionsByEntityId={transactionsByEntityId}
        containerId={workingContainer?.containerId}
        onAddUPE={() => {
          setIsCreatingUPE(true);
          setIsEntityModalOpen(true);
        }}
        onAddSingle={() => {
          setIsEntityModalOpen(true);
        }}
        onImport={() => {
          setIsImportOpen(true);
        }}
        onNavigate={(path) => {
          history.push(path);
        }}
        onEdit={(entityId) => {
          const entity = entities?.find((t) => t.entityId === entityId) ?? null;
          setSelectedEntity(entity);
          setIsEntityModalOpen(true);
        }}
        onDelete={(entityId) => {
          void dispatch(deleteEntity(entityId));
        }}
        onOpenCurrencyConversion={() => {
          setIsCurrencyConversionOpen(true);
        }}
      />
      {isCurrencyConversionOpen && (
        <CurrencyConversionModal
          isOpen
          onClose={() => {
            setIsCurrencyConversionOpen(false);
          }}
        />
      )}
      {isEntityModalOpen && (
        <EntityAddEditModal
          hasTransactions={hasTransactions}
          isUpe={isUpe}
          entity={selectedEntity}
          currencyOptions={getCurrencyOptions(currencies, t)}
          domicileOptions={getDomicileOptions(countries, t)}
          primaryFunctionOptions={getPrimaryFunctionOptions(primaryFunctions, t)}
          entityOptions={getEntityOptions(selectedEntity!, entities!)}
          container={workingContainer}
          hasNonUpeEntities={hasNonUpeEntities}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      )}
      {isImportOpen && (
        <EntitiesImportModal
          onClose={() => {
            setIsImportOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Connector;
