import { makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ButtonItem } from '../../models';

export interface ToggleButtonProps {
  buttonItems: ButtonItem[];
  handleAlignment: (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => void;
  alignment: string | null;
}

const useStyles = makeStyles((theme) => ({
  buttonWidth: {
    width: theme.spacing(15)
  }
}));

export const ToggleButtons = ({ buttonItems, handleAlignment, alignment }: ToggleButtonProps) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup exclusive value={alignment} aria-label="text alignment" onChange={handleAlignment}>
      {buttonItems.map((buttonItem: ButtonItem) => (
        <ToggleButton key={buttonItem.value} className={classes.buttonWidth} value={buttonItem.value} aria-label="left">
          {buttonItem.title}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
