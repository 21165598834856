import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  MenuItem,
  TextField,
  Menu,
  Divider,
  Button,
  InputAdornment,
  IconButton,
  Backdrop,
  Fade,
  Box
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Cancel, MoreHoriz } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CustomIconMenu } from '../../../components';
import tokens from '../../../styles/designTokens';
import { body1, buttonText2, productContainer } from '../../../styles/typography';
import { SearchIcon } from '../../../svgs/index';
import { hasRfRbAccess } from '../../../utils/hasAccess';
import { CopyContainerModal, CopyContainerModalProps } from '../../CopyContainerModal';
import { ContainerModalViewKeys } from '../../CopyContainerModal/CopyContainerModal.proptypes';
import { RollingContainersCurrentsIds, SearchableContainerSelectorProps } from '../Header.proptype';

export interface StyleProps {
  width: string;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  closedDropDownButton: {
    ...productContainer,
    color: tokens.product100,
    '&:hover': {
      backgroundColor: tokens.product15
    }
  },
  openDropDownButton: {
    ...productContainer,
    backgroundColor: tokens.product25,
    color: tokens.product100
  },
  arrowIcon: {
    width: 'auto',
    paddingLeft: '0.625rem'
  },
  searchBar: {
    '&.MuiListItem-button': {
      backgroundColor: 'white'
    },
    paddingLeft: '0rem',
    paddingRight: '0.813rem'
  },
  searchIcon: {
    '& path': {
      fill: tokens.product100
    }
  },
  searchInput: {
    paddingLeft: '0.813rem',
    '& .MuiOutlinedInput-inputAdornedStart': {
      ...body1
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'tokens.purpleLight2'
    }
  },
  menuDivider: {
    margin: '0.625rem auto'
  },
  dashboardSelectMenu: {
    '& .MuiPopover-paper': {
      width: ({ width }) => width,
      alignItems: 'left',
      backgroundColor: 'white'
    }
  },
  selectedItem: {
    ...buttonText2,
    color: tokens.product100,
    backgroundColor: tokens.product15,
    flexGrow: 5,
    height: '2.25rem',
    borderRadius: '0.25rem',
    textOverflow: 'ellipsis',
    display: 'block',
    marginRight: '0.813rem',
    marginLeft: '0.688rem',
    marginBottom: '0.188rem',
    paddingTop: '0.469rem'
  },
  unselectedItem: {
    ...body1,
    flexGrow: 5,
    '&:hover': {
      backgroundColor: tokens.neutral70
    },
    height: '2.25rem',
    borderRadius: '0.25rem',
    textOverflow: 'ellipsis',
    display: 'block',
    marginRight: '0.813rem',
    marginLeft: '0.688rem',
    marginBottom: '0.25rem',
    paddingTop: '0.5rem'
  },
  allItems: {
    maxHeight: '16.875rem',
    overflow: 'overlay',
    marginRight: '0.5rem',
    '&::-webkit-scrollbar': {
      width: '0.375rem',
      paddingRight: '1.125rem'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: tokens.neutral90,
      borderRadius: '1.25rem'
    },
    '&::-webkit-scrollbar-thumb:active': {
      background: tokens.blackAlpha20,
      borderRadius: '1.25rem'
    }
  },
  clearButton: {
    color: tokens.product100,
    fontSize: 'large'
  },
  menuFooter: {
    height: '3.063rem'
  },
  backdrop: {
    zIndex: 1300,
    backgroundColor: tokens.blackAlpha20,
    marginTop: '5.969rem'
  },
  rfrbButton: {
    '&:hover': {
      backgroundColor: tokens.neutral70
    }
  }
}));

export const SearchableContainerSelector = ({
  containers,
  currentId,
  isContainerRollable,
  switchToContainer,
  changeContainerConfirmationOpen,
  setCurrentContainerId
}: SearchableContainerSelectorProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selection, setSelection] = useState('');
  const [isActive, setActive] = useState(false);
  const [openCopyContainerModal, setOpenCopyContainerModal] = useState(false);
  const [modalInitalView, setModalInitialView] = useState<ContainerModalViewKeys>(ContainerModalViewKeys.Home);
  const { t } = useTranslation();
  const flags = useFlags();
  const history = useHistory();

  useEffect(() => {
    const selectedContainer = containers!.find((container) => container.containerId === Number(currentId))!;
    setSelection(`${selectedContainer.name} ${selectedContainer.taxYear}`);
  }, [containers, currentId]);

  const options = (containers ?? []).map(({ containerId, name, taxYear }) => {
    return { value: `${name} ${taxYear}`, key: containerId, taxYear };
  });

  const getLongestContainerLength = () => {
    let max = 0;
    for (const option of options) {
      if (option.value.length > max) {
        max = option.value.length;
      }
    }

    return max;
  };

  const maxLength = getLongestContainerLength();
  const props = {
    width: maxLength > 25 ? '21.938rem' : `${(maxLength / 1.777 + 10).toString()}rem`
  };

  const classes = useStyles(props);

  const copyContainerModalProps: CopyContainerModalProps = {
    onClose: () => {
      setOpenCopyContainerModal(false);
    },
    modalInitialView: modalInitalView
  };

  const displayOptions = options
    .map((item) => {
      if (item.value.toLowerCase().includes(searchText.toLowerCase())) {
        return item;
      }

      return null;
    })
    .filter((item) => item !== null);

  const displayOptionMenuItems = useCallback(
    (itemsIds: RollingContainersCurrentsIds) => {
      const options: any[] = [];
      const { key: containerId, taxYear } = itemsIds;
      if (hasRfRbAccess() && flags.rollContainerNewUiBasicVersion && !flags.incrementalRollingNewModal) {
        options.push({
          text: t('common:navigate-to-rfrb'),
          handleOnClick: () => {
            history.push('/copy-containers');
          }
        });
      }

      if (flags.incrementalRollingNewModal) {
        options.push(
          {
            text: t('common:copy-container'),
            handleOnClick: () => {
              setCurrentContainerId(containerId, taxYear);
              setOpenCopyContainerModal(true);
              setModalInitialView(ContainerModalViewKeys.Home);
            }
          },
          {
            text: t('common:view-history'),
            handleOnClick: () => {
              setCurrentContainerId(containerId, taxYear);
              setOpenCopyContainerModal(true);
              setModalInitialView(ContainerModalViewKeys.Historical);
            }
          }
        );
      }

      return options;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, history, options, flags.incrementalRollingNewModal, flags.rollContainerNewUiBasicVersion]
  );

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    toggleActive();
    setSearchText('');
    setOpenMenu(false);
  };

  const handleContainerSelection = (value: string | null, id: number) => {
    toggleActive();
    if (value !== selection) {
      const selectedContainer = containers!.find((container) => container.containerId === Number(id))!;
      switchToContainer(selectedContainer);
      changeContainerConfirmationOpen(true);
    }

    setSearchText('');
    setOpenMenu(false);
    handleCloseBackdrop();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClearInput = () => {
    setSearchText('');
  };

  const toggleActive = () => {
    setActive(!isActive);
  };

  const handleCloseBackdrop = () => {
    setBackdrop(false);
  };

  const handleToggleBackdrop = () => {
    setBackdrop(!backdrop);
  };

  return (
    <div>
      {openCopyContainerModal && <CopyContainerModal {...copyContainerModalProps} />}
      <Button
        type="button"
        className={isActive ? classes.openDropDownButton : classes.closedDropDownButton}
        onClick={() => {
          handleOpenMenu();
          toggleActive();
          handleToggleBackdrop();
        }}
      >
        {selection}
        {isActive ? (
          <KeyboardArrowUpIcon className={classes.arrowIcon} />
        ) : (
          <KeyboardArrowDownIcon className={classes.arrowIcon} />
        )}
      </Button>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <Menu
          keepMounted
          open={openMenu}
          className={classes.dashboardSelectMenu}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 99, left: 240 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 216
          }}
          TransitionComponent={Fade}
          onClose={() => {
            handleCloseMenu();
            handleCloseBackdrop();
          }}
        >
          <MenuItem disableTouchRipple className={classes.searchBar}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              InputProps={
                searchText
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" disabled={!searchText} onClick={handleClearInput}>
                            <Cancel className={classes.clearButton} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  : {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                      )
                    }
              }
              className={classes.searchInput}
              value={searchText}
              onChange={handleSearchChange}
            />
          </MenuItem>
          <Divider className={classes.menuDivider} />
          <div className={classes.allItems}>
            {displayOptions.map((item, index) => {
              return (
                <Box key={item ? item.key : index} display="flex" alignItems="center">
                  <MenuItem
                    title={item ? item.value : ''}
                    className={(item ? item.key : '') === currentId ? classes.selectedItem : classes.unselectedItem}
                    onClick={(event) => {
                      const target = event.target as HTMLButtonElement;
                      handleContainerSelection(target.textContent, item ? item.key : 0);
                    }}
                  >
                    {item ? item.value : ''}
                  </MenuItem>
                  {item?.key === currentId && displayOptionMenuItems.length > 0 && isContainerRollable && (
                    <Box className={classes.rfrbButton}>
                      <CustomIconMenu Icon={MoreHoriz} menuItems={displayOptionMenuItems(item!)} />
                    </Box>
                  )}
                </Box>
              );
            })}
          </div>
        </Menu>
      </Backdrop>
    </div>
  );
};
