import { FinancialAreas } from '@xbs/xbs-enums';
import { Reducer } from 'redux';
import { entityFinancialInitialState } from './entityFinancial.initialState';
import {
  EntityFinancialStateInterface,
  EntityFinancialActions,
  SET_ENTITYFINANCIAL_REQUEST_STATUS,
  SET_ENTITYFINANCIAL_DATA,
  SET_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS,
  SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS,
  SET_ENTITY_ADDITIONAL_FINANCIAL_INFO,
  RESET_ENTITY_FINANCIAL_STATUS
} from './entityFinancial.types';

// eslint-disable-next-line complexity
const entityFinancialReducer: Reducer<EntityFinancialStateInterface, EntityFinancialActions> = (
  entityFinancialState = entityFinancialInitialState,
  action = {} as EntityFinancialActions
): EntityFinancialStateInterface => {
  const { financialAreaId } = action.payload || {};
  const isIncomeStatement = financialAreaId === FinancialAreas.ByName.IncomeStatement.Id;
  const currentState = isIncomeStatement ? entityFinancialState.incomeStatement : entityFinancialState.balanceSheet;

  switch (action.type) {
    case SET_ENTITY_ADDITIONAL_FINANCIAL_INFO:
      return {
        ...entityFinancialState,
        [isIncomeStatement ? 'incomeStatement' : 'balanceSheet']: {
          ...currentState,
          additionalFinancialInfo: action.payload.data
        }
      };
    case SET_ENTITYFINANCIAL_DATA:
      return {
        ...entityFinancialState,
        [isIncomeStatement ? 'incomeStatement' : 'balanceSheet']: {
          ...currentState,
          data: action.payload.data
        }
      };
    case SET_ENTITYFINANCIAL_REQUEST_STATUS:
      return {
        ...entityFinancialState,
        [isIncomeStatement ? 'incomeStatement' : 'balanceSheet']: {
          ...currentState,
          status: action.payload.status,
          error: action.payload.error ?? null
        }
      };
    case SET_ENTITY_FINANCIAL_COMPLETION_STATUS:
      return {
        ...entityFinancialState,
        [isIncomeStatement ? 'incomeStatement' : 'balanceSheet']: {
          ...currentState,
          completionStatus: action.payload.data
        }
      };
    case SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS:
      return {
        ...entityFinancialState,
        [isIncomeStatement ? 'incomeStatement' : 'balanceSheet']: {
          ...currentState,
          status: action.payload.status,
          error: action.payload.error ?? null
        }
      };
    case SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS: {
      const newCompletionStatus = currentState.completionStatus.map((status) => {
        return status.financialAreaId === action.payload.data.financialAreaId &&
          status.taxYear === action.payload.data.taxYear
          ? action.payload.data
          : status;
      });

      const changedExisting = newCompletionStatus.findIndex(
        (status) =>
          status.financialAreaId === action.payload.data.financialAreaId &&
          status.taxYear === action.payload.data.taxYear
      );

      return {
        ...entityFinancialState,
        [isIncomeStatement ? 'incomeStatement' : 'balanceSheet']: {
          ...currentState,
          completionStatus: [...newCompletionStatus, ...(changedExisting < 0 ? [action.payload.data] : [])]
        }
      };
    }

    case SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS:
      return {
        ...entityFinancialState,
        [isIncomeStatement ? 'incomeStatement' : 'balanceSheet']: {
          ...currentState,
          status: action.payload.status,
          error: action.payload.error ?? null
        }
      };

    case RESET_ENTITY_FINANCIAL_STATUS:
      return {
        ...entityFinancialState,
        incomeStatement: {
          ...entityFinancialState.incomeStatement,
          status: 'none'
        },
        balanceSheet: {
          ...entityFinancialState.balanceSheet,
          status: 'none'
        }
      };

    default:
      return entityFinancialState;
  }
};

export default entityFinancialReducer;
