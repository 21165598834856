import { useEffect, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EntityDetailsProps, EntityDetailsTab } from './EntityDetails.proptype';
import { fetchEntities } from '../../redux/entities';
import { selectEntity, selectEntitiesList } from '../../selectors';
import { DetailedWithTabsProps } from '../DetailedWithTabs';
import EntityDetailsBalanceSheet from '../EntityDetailsFinancials/EntityDetailsBalanceSheet';
import EntityDetailsIncomeStatement from '../EntityDetailsFinancials/EntityDetailsIncomeStatement';
import { EntityStatementOfFacts } from '../EntityStatementOfFacts';

interface ConnectorProps extends EntityDetailsProps {
  component: ComponentType<DetailedWithTabsProps>;
}

const Connector = ({ entityId, tab, component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const entities = useSelector(selectEntitiesList);
  const entity = useSelector(selectEntity(entityId));

  useEffect(() => {
    if (entities === null) {
      dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  return (
    <Component
      title={t('entities:title-details')}
      data={entity ?? undefined}
      selectedKey={tab}
      tabs={[
        {
          key: EntityDetailsTab.StatementOfFacts,
          label: t('entities:title-sof'),
          disabled: false,
          content: <EntityStatementOfFacts />
        },
        {
          key: EntityDetailsTab.IncomeStatement,
          label: t('entities:title-income-statement'),
          disabled: false,
          content: <EntityDetailsIncomeStatement />
        },
        {
          key: EntityDetailsTab.BalanceSheet,
          label: t('entities:title-balance-sheet'),
          disabled: false,
          content: <EntityDetailsBalanceSheet />
        }
      ]}
      onSelectTab={(tab: string) => {
        history.push(`/entities/${entityId}/details/${tab}`);
      }}
    />
  );
};

export default Connector;
