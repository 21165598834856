import { Table } from '../../Table';
import { PliValuesTableProps } from '../ProfitLevelIndicator.proptype';

export const PliValuesTable = ({ displayablePliValues }: PliValuesTableProps) => {
  const columns =
    displayablePliValues.length > 0
      ? Object.keys(displayablePliValues[0])
          .reverse()
          .map((key) => {
            return key === 'name'
              ? {
                  key: 'name',
                  header: 'PLI Name',
                  width: 250
                }
              : { key, header: key, width: 150 };
          })
      : [];
  return <Table data={displayablePliValues} columns={columns} />;
};
