import { createSelector } from 'reselect';
import { RootState } from '../redux';

export const selectInternalReports = (entityId: number) =>
  createSelector(
    ({
      reports: {
        internal: { byId }
      }
    }: RootState) => byId,
    (byId) => (byId ?? {})[entityId]
  );

export const selectFinalReports = (entityId: number) =>
  createSelector(
    ({
      reports: {
        final: { byId }
      }
    }: RootState) => byId,
    (byId) => (byId ?? {})[entityId]
  );
