import { ReactNode } from 'react';
import { Box, Card, CardClassKey, CardContent, CardHeader, IconButton, makeStyles } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

export interface DashboardCardProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  classes?: Partial<Record<CardClassKey, string>>;
  children?: ReactNode;
  customAction?: ReactNode;
  onNavigate?: null | (() => void);
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    '& .MuiCardHeader-action': {
      marginTop: 0
    }
  }
}));

export const DashboardCard = ({ title, subtitle, children, classes, customAction, onNavigate }: DashboardCardProps) => {
  const { root } = useStyles();
  return (
    <Card classes={{ root, ...classes }}>
      <CardHeader
        title={title}
        subheader={subtitle}
        action={
          (onNavigate !== undefined || customAction) && (
            <Box ml={2}>
              {customAction ?? (
                <IconButton
                  disabled={onNavigate === null}
                  onClick={() => {
                    onNavigate?.();
                  }}
                >
                  <ArrowForwardIos />
                </IconButton>
              )}
            </Box>
          )
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
