import { useRef } from 'react';

function useDebounce(fn: any, timeMs = 500) {
  const debounceTimeout = useRef(0) as any;

  return [
    (...fnParams: any) => {
      const functionCall = () => {
        fn(...fnParams);
      };

      clearTimeout(debounceTimeout.current);

      debounceTimeout.current = setTimeout(functionCall, timeMs);
    },
    () => {
      clearTimeout(debounceTimeout.current);
    }
  ];
}

export default useDebounce;
