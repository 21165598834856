import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, IconButton, Typography, makeStyles } from '@material-ui/core';
import {
  UploadMultipleTransactionsParams,
  uploadUncontrolledTransactionsFile
} from '../../../redux/transactionBasedAnalyses';
import tokens from '../../../styles/designTokens';
import { body3, buttonText2, title1, title2 } from '../../../styles/typography';
import { DownloadIconColor, UploadIcon } from '../../../svgs';
import { fetchUploadTemplate } from '../../../utils';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
import { container } from '../../Entities/Entities.testdata';
import { TPModal } from '../../TPModal';

export interface UncontrolledTransactionUploadModalProps {
  isOpen: boolean;
  title: string;
  description: string;
  setisOpenUploadModal: (isOpen: boolean) => void;
  onUploadMultipleTransactions: (data: UploadMultipleTransactionsParams) => void;
  source: string;
}

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    height: '2.25rem',
    width: '2.25rem',
    backgroundColor: tokens.product15,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '& svg': {
      fill: tokens.product100
    },
    '&.MuiIconButton-root': {
      padding: '0.5rem'
    }
  },
  title: {
    ...title2
  },
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  contentBox: {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(2),
    minHeight: '10.563rem',
    minWidth: '43.875rem',
    boxShadow: '0 0 0.188rem 0 rgba(0,0,0,0.12)'
  },
  contentTitle: {
    color: tokens.core3,
    ...title1
  },
  contentLabel: {
    color: tokens.core2,
    ...body3,
    letterSpacing: '0.009rem'
  },
  contentWrapper: {
    backgroundColor: tokens.neutral70
  },
  downloadIcon: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    color: tokens.product100
  },
  downloadLabel: {
    color: theme.palette.primary.dark,
    ...buttonText2,
    paddingLeft: theme.spacing(1)
  },
  uploadContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  uploadButton: {
    '&.MuiButton-outlinedPrimary': {
      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.dark}`
    }
  },
  uploadLabel: {
    ...buttonText2,
    color: tokens.core2,
    paddingBottom: theme.spacing(0.5)
  }
}));
const onTemplateDownload = async () => {
  await fetchUploadTemplate(
    'transfer-pricing-uncontrolled-transactions-import-template-v1.xlsx',
    'Transfer Pricing Uncontrolled Transactions Import Template.xlsx'
  );
};

export const UncontrolledTransactionUploadModal = ({
  isOpen,
  title,
  description,
  source,
  setisOpenUploadModal,
  onUploadMultipleTransactions
}: UncontrolledTransactionUploadModalProps) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null | undefined>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <TPModal
      isOpen={isOpen}
      maxWidth="md"
      containerStyle={classes.contentWrapper}
      title={
        <span className={classes.modalTitleWrapper}>
          <IconButton className={classes.headerIcon}>
            <UploadIcon />
          </IconButton>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
        </span>
      }
      actions={[
        {
          name: t('action-cancel'),
          variant: 'outlined',
          color: 'primary',
          handler: () => {
            /* eslint-disable camelcase */
            void logGoogleAnalyticsEvent({
              event_category: 'tba_button_click',
              event_label: `${source} On Cancel Upload Multiple Transaction Modal click`,
              container_id: container?.containerId
            });
            setisOpenUploadModal(false);
            setFile(null);
          }
        },
        {
          name: t('action-import'),
          color: 'primary',
          variant: 'contained',
          handler: async () => {
            /* eslint-disable camelcase */
            void logGoogleAnalyticsEvent({
              event_category: 'tba_button_click',
              event_label: `${source} On Import Upload Multiple Transaction Modal click`,
              container_id: container?.containerId
            });
            if (file) {
              // eslint-disable-next-line @typescript-eslint/await-thenable
              const result = await dispatch(uploadUncontrolledTransactionsFile({ file }));
              const parsedResult = JSON.parse(JSON.stringify(result));
              setisOpenUploadModal(false);
              onUploadMultipleTransactions({
                hash: parsedResult.payload?.hash,
                mappings: parsedResult.payload?.allowed?.map(
                  (d: { defaultHeader: string; field: string; error: string }) => {
                    return {
                      header: d.defaultHeader,
                      field: d.field,
                      error: d.error
                    };
                  }
                ),
                headers: parsedResult.payload?.headers
              });
              setFile(null);
            }
          }
        }
      ]}
      onClose={() => {
        /* eslint-disable camelcase */
        void logGoogleAnalyticsEvent({
          event_category: 'tba_button_click',
          event_label: `${source} On Close Upload Multiple Transaction Modal click`,
          container_id: container?.containerId
        });
        setisOpenUploadModal(false);
        setFile(null);
      }}
    >
      <Box className={classes.contentBox}>
        <Typography className={classes.contentTitle}>{t(`analysis:auto-template`)}</Typography>
        <Typography className={classes.contentLabel}>
          {t(`analysis:tba-uncontrolled-transaction-auto-template-description`)}
        </Typography>
        <Typography className={classes.contentLabel}>{t(`analysis:auto-template-download`)}</Typography>
        <Button className={classes.downloadIcon} onClick={onTemplateDownload}>
          <DownloadIconColor />
          <Typography className={classes.downloadLabel}>{t(`analysis:download-template`)}</Typography>
        </Button>
      </Box>
      <Box className={classes.contentBox}>
        <Typography className={classes.contentTitle}>{t(`analysis:upload-and-import`)}</Typography>
        <Typography className={classes.contentLabel}>{description}</Typography>
        <Box className={classes.uploadContainer}>
          <Typography className={classes.uploadLabel}>{file?.name ?? t(`analysis:choose-file-upload`)}</Typography>
          <Button color="primary" variant="outlined" component="label" className={classes.uploadButton}>
            {t(`analysis:import-template-browse`)}
            <input
              hidden
              accept=".xls,.xlsx,.xlsm"
              type="file"
              onChange={(event) => {
                setFile(event.target.files?.item(0));
              }}
            />
          </Button>
        </Box>
      </Box>
    </TPModal>
  );
};
