import Connector from './connector';
import { ImportModal } from '../ImportModal';

export interface TransactionsImportModalProps {
  onClose: () => void;
}

export const TransactionsImportModal = (props: TransactionsImportModalProps) => (
  <Connector component={ImportModal} {...props} />
);
