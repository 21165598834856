import { CircularProgress, Box, makeStyles } from '@material-ui/core';

export interface CenteredProgressProps {
  size?: number | string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
    height: '100%'
  }
}));

export const CenteredProgress = ({ size }: CenteredProgressProps) => (
  <Box className={useStyles().root}>
    <CircularProgress color="primary" size={size} />
  </Box>
);
