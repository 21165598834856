import { useEffect, ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { TransactionsProps } from './Transactions.proptype';
import { Container, LegalEntityTransaction, Transaction } from '../../models';
import { fetchConversionRates } from '../../redux/conversionRates';
import { fetchEntities } from '../../redux/entities';
import {
  deleteTransaction,
  fetchPartyRoles,
  fetchTransactions,
  fetchTransactionTypes,
  saveTransaction
} from '../../redux/transactions';
import {
  selectTransactionTypes,
  selectEntitiesList,
  selectPartyRoles,
  selectTransactionsList,
  selectCountries,
  selectUPECurrency,
  selectCurrencies,
  selectConversionRates,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { TransactionAddEditModal, TransactionAddEditModalInputs } from '../TransactionAddEditModal';
import { TransactionsImportModal } from '../TransactionsImportModal';

const mapFormToTransaction = ({
  formData,
  transaction,
  container
}: {
  formData: TransactionAddEditModalInputs;
  transaction: Transaction | null;
  container: Container;
}): Partial<Transaction> => {
  return {
    identifier: formData.identifier!,
    transactionType: formData.transactionType!,
    description: formData.description!,
    value: formData.value ? formData.value : undefined,
    units: formData.units ? formData.units : undefined,
    propertyTransferred: formData.propertyTransferred!,
    partyRole: formData.partyRole!,
    ...(transaction ? { transactionId: transaction.transactionId } : {}),
    container,
    // Legal Transactions are always 2, having:
    // - source
    // - destination
    // We don't need to pass completed attribute since the transaction Save API does not support it

    legalEntityTransactions: [
      ({ isSource: true, details: '', entity: formData.sourceEntity! } as unknown) as LegalEntityTransaction,
      ({ isSource: false, details: '', entity: formData.destinationEntity! } as unknown) as LegalEntityTransaction
    ],
    valueEnteredIn: formData.inputCurrency!.value,
    transferDate: formData.transferDate
  };
};

const Connector = ({ component: Component }: { component: ComponentType<TransactionsProps> }) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const transactions = useSelector(selectTransactionsList);
  const entities = useSelector(selectEntitiesList);
  const partyRoles = useSelector(selectPartyRoles);
  const transactionTypes = useSelector(selectTransactionTypes);
  const countries = useSelector(selectCountries);
  const currencies = useSelector(selectCurrencies);
  const conversionRates = useSelector(selectConversionRates);
  const container = useSelector(selectWorkingContainer)!;
  const upeCurrency = useSelector(selectUPECurrency);
  const [isAddEditTransactionOpen, setAddEditTransactionOpen] = useState(false);
  const [isImportOpen, setImportOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | null>(null);
  const [addEditModalKey, setAddEditModalKey] = useState(Date.now());

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (transactions === null) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (partyRoles === null) {
      void dispatch(fetchPartyRoles());
    }
  }, [dispatch, partyRoles]);

  useEffect(() => {
    if (transactionTypes === null) {
      void dispatch(fetchTransactionTypes());
    }
  }, [dispatch, transactionTypes]);

  useEffect(() => {
    if (conversionRates === null) {
      void dispatch(fetchConversionRates());
    }
  }, [dispatch, conversionRates]);

  const handleClose = () => {
    setSelectedTransaction(null);
    setAddEditTransactionOpen(false);
  };

  const handleSubmit = async (formData: TransactionAddEditModalInputs) => {
    const transaction = mapFormToTransaction({ formData, container, transaction: selectedTransaction });

    unwrapResult(await dispatch(saveTransaction({ transaction, t })));
    void dispatch(fetchTransactions());
  };

  const handleRestart = () => {
    setAddEditModalKey(Date.now());
  };

  return (
    <>
      <Component
        entities={entities}
        transactions={transactions}
        upeCurrency={upeCurrency!}
        onAddSingle={() => {
          setAddEditTransactionOpen(true);
        }}
        onEdit={(transactionId) => {
          const transaction = transactions?.find((t) => t.transactionId === transactionId) ?? null;
          setSelectedTransaction(transaction);
          setAddEditTransactionOpen(true);
        }}
        onNavigate={(path) => {
          history.push(path);
        }}
        onDelete={(transactionId) => {
          void dispatch(deleteTransaction(transactionId));
        }}
        onImport={() => {
          setImportOpen(true);
        }}
      />
      {isAddEditTransactionOpen && upeCurrency && (
        <TransactionAddEditModal
          key={addEditModalKey}
          transaction={selectedTransaction}
          entities={entities!}
          partyRoles={partyRoles!}
          conversionRates={conversionRates!}
          container={container}
          countries={countries}
          transactionTypes={transactionTypes!}
          upeCurrency={upeCurrency}
          currencies={currencies}
          handleRestart={handleRestart}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
      {isImportOpen && (
        <TransactionsImportModal
          onClose={() => {
            setImportOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Connector;
