import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { PbaTbaJurisdictionStatus } from '@xbs/xbs-enums';
import { PbaJurisdictionStatus } from '../../models';
import designTokens from '../../styles/designTokens';
import { captionOverline } from '../../styles/typography';
import { hasEditAccess } from '../../utils';
import { DropDownButton } from '../DropDownButton';

const useStyles = makeStyles((theme) => ({
  jurisdictionDropdown: {
    ...captionOverline,
    margin: 'auto'
  },
  jurisdictionDropdownColorComplete: {
    backgroundColor: designTokens.product15,
    color: designTokens.product100
  },
  jurisdictionDropdownColorInReview: {
    backgroundColor: designTokens.supportColorA15,
    color: designTokens.supportColorA100
  },
  jurisdictionDropdownColorInProgress: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark
  }
}));

export interface JurisdictionStatusDropDownProps {
  jurisdictionStatus: PbaJurisdictionStatus;
  updateJurisdictionStatus: (status: number) => void;
}

export const JurisdictionStatusDropDown = ({
  updateJurisdictionStatus,
  jurisdictionStatus
}: JurisdictionStatusDropDownProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const statusColorMap = {
    [PbaTbaJurisdictionStatus.ByName.Complete.Id]: classes.jurisdictionDropdownColorComplete,
    [PbaTbaJurisdictionStatus.ByName.InReview.Id]: classes.jurisdictionDropdownColorInReview,
    [PbaTbaJurisdictionStatus.ByName.InProgress.Id]: classes.jurisdictionDropdownColorInProgress
  };

  const jurisdictionDropdownColorClass = statusColorMap[jurisdictionStatus.status];

  const statusText: string = PbaTbaJurisdictionStatus.ById[jurisdictionStatus.status]
    ? PbaTbaJurisdictionStatus.ById[jurisdictionStatus.status].Localization
    : PbaTbaJurisdictionStatus.ByName.InProgress.Localization;

  return (
    <DropDownButton
      disabled={!hasEditAccess()}
      className={`${classes.jurisdictionDropdown} ${jurisdictionDropdownColorClass}`}
      variant="text"
      endIcon={<ExpandMore />}
      items={[
        {
          children: t('common:label-in-progress').toUpperCase(),
          onClick: () => {
            updateJurisdictionStatus(PbaTbaJurisdictionStatus.ByName.InProgress.Id);
          }
        },
        {
          children: t('common:label-in-review').toUpperCase(),
          onClick: () => {
            updateJurisdictionStatus(PbaTbaJurisdictionStatus.ByName.InReview.Id);
          }
        },
        {
          children: t('common:label-complete').toUpperCase(),
          onClick: () => {
            updateJurisdictionStatus(PbaTbaJurisdictionStatus.ByName.Complete.Id);
          }
        }
      ]}
    >
      <>
        {jurisdictionStatus.jurisdictionIsoCode}: {t(`common:label-${statusText}`).toUpperCase()}
      </>
    </DropDownButton>
  );
};
