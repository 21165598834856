import { useEffect, useState, ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton, makeStyles } from '@material-ui/core';
import { PostAdd, FullscreenExit } from '@material-ui/icons';
import { Editor, TPModal, TPModalProps } from '../../components';
import editAccess from '../../components/HigherOrderComponents/EditAccess';
import { BackgroundInfoSubTopic } from '../../models';
import useDebounce from '../../utils/debounce';
import { hasEditAccess } from '../../utils/hasAccess';
import { NoteSlider } from '../NoteSlider';
import { ProgressButton } from '../ProgressButton';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'stretch',
    display: 'flex',
    height: '100%'
  },
  rootModal: {
    alignItems: 'stretch',
    display: 'flex',
    height: `75vh`,
    // negate padding from TPModal's DialogContent
    margin: theme.spacing(-1.3, -2)
  },
  contentWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2.5, 2, 2),
    overflow: 'auto'
  },
  line: {
    alignItems: 'center',
    gap: theme.spacing(1),
    display: 'flex',
    '& > :first-child': {
      flex: 1
    }
  },
  subtopicTopicName: {
    marginTop: theme.spacing(1.5)
  },
  notes: {
    display: 'none'
  }
}));

const Wrapper = ({ children, ...props }: TPModalProps & { children: ReactElement }) =>
  props.isOpen ? <TPModal {...props}>{children}</TPModal> : children;

export interface StatementOfFactsDetailsProps {
  title: string;
  subtopic: BackgroundInfoSubTopic;
  namespace: string;
  onSubmit: (subtopic: BackgroundInfoSubTopic) => void;
}

export const StatementOfFactsDetails = ({ title, subtopic, namespace, onSubmit }: StatementOfFactsDetailsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [subtopicDiscussion, setSubtopicDiscussion] = useState(subtopic.discussion);
  const [subtopicNotes, setSubtopicNotes] = useState(subtopic?.notes ?? '');
  const [openNotes, setOpenNotes] = useState(false);
  const [isCompleted, setIsCompleted] = useState(subtopic.completed);
  const [asModal, setAsModal] = useState(false);
  const shouldAutoSave = useRef(false);

  const handleAutoSaveSubmit = () => {
    onSubmit({
      backgroundInfoId: subtopic.backgroundInfoId,
      discussion: subtopicDiscussion,
      topicId: subtopic.topicId
    } as BackgroundInfoSubTopic);
    setOpenNotes(false);
  };

  const handleSubmit = () => {
    onSubmit({
      ...subtopic,
      discussion: subtopicDiscussion,
      notes: subtopicNotes ?? '',
      completed: isCompleted
    });
    setOpenNotes(false);
  };

  const [handleEditorAutoSave] = useDebounce(() => {
    handleAutoSaveSubmit();
  }, 3000);

  useEffect(() => {
    if (shouldAutoSave.current) {
      handleEditorAutoSave();
    }

    shouldAutoSave.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtopicDiscussion]);

  const handleCompletedChange = (checked: boolean) => {
    onSubmit({
      ...subtopic,
      discussion: subtopicDiscussion,
      notes: subtopicNotes ?? '',
      completed: checked
    });
    setOpenNotes(false);
    setAsModal(false);
    setIsCompleted(checked);
  };

  useEffect(() => {
    setSubtopicDiscussion(subtopic.discussion ?? '');
    setSubtopicNotes(subtopic?.notes ?? '');
    setIsCompleted(subtopic.completed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtopic.backgroundInfoId]);

  const handleCloseModal = () => {
    setAsModal(false);
    setSubtopicNotes(subtopic?.notes ?? '');
  };

  return subtopic ? (
    <Wrapper isOpen={asModal} title={title} closeIcon={FullscreenExit} onClose={handleCloseModal}>
      <Box className={asModal ? classes.rootModal : classes.root}>
        <Box className={classes.contentWrapper}>
          <Box className={classes.line}>
            <Typography>{t(`topic-${subtopic.topicName}`)}</Typography>
          </Box>
          <Typography variant="h3" className={classes.subtopicTopicName}>
            {subtopic.subtopicHeader}
          </Typography>
          <Box className={classes.line}>
            <Typography variant="body2">{subtopic.subtopicDiscussionPoints}</Typography>
            {editAccess(<ProgressButton complete={isCompleted} onChange={handleCompletedChange} />)}
            <IconButton
              className={classes.notes}
              aria-label={t('action-add-note')}
              onClick={() => {
                setOpenNotes(true);
                setSubtopicNotes(subtopic?.notes ?? '');
              }}
            >
              <PostAdd />
            </IconButton>
          </Box>
          <Box mt={3} flexGrow={1}>
            <Editor
              data-testid="statementOfFactsEditor"
              theme="TransferPricing"
              id={`rte-newui-${namespace}-${subtopic.topicId}-statement-of-facts-input`}
              init={{
                height: '100%',
                maximize: !asModal
              }}
              value={subtopicDiscussion}
              disabled={!hasEditAccess() || isCompleted}
              onSetupActions={{
                toggleButtons: [
                  {
                    name: 'maximize',
                    icon: 'maximize-icon',
                    tooltip: 'Maximize Form',
                    onAction: () => {
                      setAsModal(true);
                    }
                  }
                ]
              }}
              onEditorChange={(content) => {
                if (content !== subtopicDiscussion) {
                  shouldAutoSave.current = true;
                  setSubtopicDiscussion(content);
                }
              }}
              onBlur={handleSubmit}
            />
          </Box>
        </Box>
        {openNotes && (
          <NoteSlider
            notes={subtopicNotes}
            onClose={() => {
              setOpenNotes(false);
            }}
            onChange={(inputValue: string) => {
              setSubtopicNotes(inputValue);
            }}
          />
        )}
      </Box>
    </Wrapper>
  ) : null;
};
