import { useTranslation } from 'react-i18next';
import NavigationTabs from '../NavigationTabs/component';
import { PBADashboardBalanceSheet } from '../PBADashboardFinancials/PBADashboardBalanceSheet';
import { PBADashboardIncomeStatement } from '../PBADashboardFinancials/PBADashboardIncomeStatement';
import { PBADashboardTPDSummary } from '../PBADashboardTPDSummary';
import { TestedPartyDetailsTabOptions } from '../ProfitBasedAnalysisDashboard/ProfitBasedAnalysisDashboard.proptype';
import { ProfitLevelIndicatorComponent } from '../ProfitLevelIndicator';

export const TestedPartyDetails = () => {
  const { t } = useTranslation();

  const { Summary, IncomeStatement, BalanceSheet, ProfitLevelIndicators } = TestedPartyDetailsTabOptions;

  const tabs = [
    {
      key: Summary,
      label: Summary,
      disabled: false,
      content: <PBADashboardTPDSummary />,
      createButtonText: t('analysis:summary')
    },
    {
      key: IncomeStatement,
      label: IncomeStatement,
      disabled: false,
      content: <PBADashboardIncomeStatement />,
      createButtonText: t('analysis:income-statement')
    },
    {
      key: BalanceSheet,
      label: BalanceSheet,
      disabled: true,
      content: <PBADashboardBalanceSheet />,
      createButtonText: t('analysis:balance-sheet')
    },
    {
      key: ProfitLevelIndicators,
      label: ProfitLevelIndicators,
      disabled: true,
      content: <ProfitLevelIndicatorComponent />,
      createButtonText: t('analysis:profit-level-indicator')
    }
  ];

  return <NavigationTabs tabs={tabs} />;
};
