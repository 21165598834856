import { Grid, makeStyles } from '@material-ui/core';
import { TransferPricingProp } from './types';

const useStyles = makeStyles((theme) => ({
  theme: {
    height: '100%',
    '& .tox .tox-tbtn svg': {
      fill: theme.palette.grey['500']
    },
    '& .tox .tox-tbtn:hover svg': {
      fill: theme.palette.common.white
    },
    '& .tox .tox-tbtn': {
      transition: 'background-color 0.2s ease'
    },
    '& .tox .tox-tbtn:hover, & .tox .tox-tbtn:active, & .tox .tox-tbtn:focus': {
      backgroundColor: theme.palette.grey['500'],
      cursor: 'pointer'
    },
    '& .tox-tinymce': {
      borderRadius: theme.shape.borderRadius
    },
    // Trying to keep parity between Material-UI and TinyMCE
    // So the borderColor is coming from Material-UI theme and it can be updated based on that
    '&.Mui-error .tox-tinymce': {
      borderColor: theme.palette.error.main
    },
    '& .tox .tox-toolbar__primary': {
      backgroundColor: theme.palette.grey['100'],
      backgroundImage: 'none',
      padding: theme.spacing(1, 2)
    },
    '& .tox .tox-edit-area__iframe': {
      borderTop: `1px solid ${theme.palette.grey['400']}`
    },
    '& .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)': {
      border: 'transparent'
    },
    '& .tox-tinymce--disabled .tox-edit-area__iframe': {
      backgroundColor: theme.palette.action.disabledBackground
    },
    '& .tox-toolbar-overlord .tox-toolbar__primary .tox-toolbar__group:last-child': {
      marginLeft: 'auto'
    }
  }
}));

export const TransferPricing = ({ error, ...props }: TransferPricingProp) => {
  const classes = useStyles();
  return <Grid className={`${error ? 'Mui-error' : ''} ${classes.theme}`} {...props} />;
};
