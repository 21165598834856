import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { FunctionalAnalysisSplashImageHolder } from './FunctionalAnalysisSplashImageHolder';
import { FunctionalAnalysisDiscussionEditorProps, functionalAnalysisHeaders } from './FunctionalAnalysisTable.proptype';
import { Editor } from '../../components/Editor';
import { updateFADiscussionText } from '../../redux/profitBasedAnalyses';
import { updateTBAFADiscussionText } from '../../redux/transactionBasedAnalyses';
import { title1, body1 } from '../../styles/typography';
import { hasEditAccess } from '../../utils';

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    width: '50%'
  },
  editorHeader: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  editorDesc: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

export const FunctionalAnalysisDiscussionEditor = ({
  selectedAnalysis,
  currentSection,
  editFunctionalAnalysisCharacteristic
}: FunctionalAnalysisDiscussionEditorProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editorText, setEditorText] = useState<string | undefined>(selectedAnalysis?.discussion);
  const [debounceRef, setDebounceRef] = useState<NodeJS.Timeout>((0 as unknown) as NodeJS.Timeout);

  const dispatch = useDispatch();
  useEffect(() => {
    setEditorText(selectedAnalysis?.discussion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnalysis?.pbaFunctionalAnalysisDataId, selectedAnalysis?.tbaFunctionalAnalysisDataId]);

  return (
    <Box className={classes.editorContainer}>
      {selectedAnalysis ? (
        <>
          <Typography style={{ ...body1 }} className={classes.editorHeader}>
            {functionalAnalysisHeaders[currentSection - 1]}
          </Typography>
          <Typography style={{ ...title1 }}>
            {selectedAnalysis
              ? selectedAnalysis.characteristicName.includes('functional_characteristic_type')
                ? t(`analysis:${String(selectedAnalysis.characteristicName)}`)
                : selectedAnalysis.characteristicName
              : ''}
          </Typography>
          <Typography style={{ ...body1 }} className={classes.editorDesc}>
            {t(`analysis:functional_characteristic.discussion_title`)}
          </Typography>
          <Editor
            theme="TransferPricing"
            id="functionalAnalysis"
            init={{ height: '50vh' }}
            value={editorText}
            disabled={!hasEditAccess()}
            onEditorChange={(content) => {
              if (content !== selectedAnalysis?.discussion) {
                setEditorText(content);
                clearTimeout(debounceRef);
                setDebounceRef(
                  setTimeout(() => {
                    if (selectedAnalysis.pbaFunctionalAnalysisDataId) {
                      dispatch(
                        updateFADiscussionText({
                          FAType: currentSection,
                          discussion: content,
                          pbaFunctionalAnalysisDataId: selectedAnalysis.pbaFunctionalAnalysisDataId
                        })
                      );
                    } else {
                      dispatch(
                        updateTBAFADiscussionText({
                          FAType: currentSection,
                          discussion: content,
                          tbaFunctionalAnalysisDataId: selectedAnalysis.tbaFunctionalAnalysisDataId
                        })
                      );
                    }

                    const editPayload: any = {
                      discussion: content,
                      discussionOnly: true
                    };
                    if (selectedAnalysis?.pbaFunctionalAnalysisDataId) {
                      editPayload.pbaFunctionalAnalysisDataId = selectedAnalysis.pbaFunctionalAnalysisDataId;
                    } else {
                      editPayload.tbaFunctionalAnalysisDataId = selectedAnalysis.tbaFunctionalAnalysisDataId;
                    }

                    void editFunctionalAnalysisCharacteristic(editPayload, currentSection);
                  }, 3000)
                );
              }
            }}
            onBlur={() => {
              clearTimeout(debounceRef);
              if (selectedAnalysis.pbaFunctionalAnalysisDataId) {
                dispatch(
                  updateFADiscussionText({
                    FAType: currentSection,
                    discussion: editorText,
                    pbaFunctionalAnalysisDataId: selectedAnalysis.pbaFunctionalAnalysisDataId
                  })
                );
              } else {
                dispatch(
                  updateTBAFADiscussionText({
                    FAType: currentSection,
                    discussion: editorText,
                    tbaFunctionalAnalysisDataId: selectedAnalysis.tbaFunctionalAnalysisDataId
                  })
                );
              }

              if (selectedAnalysis) {
                const editPayload: any = {
                  discussion: editorText,
                  discussionOnly: true
                };
                if (selectedAnalysis?.pbaFunctionalAnalysisDataId) {
                  editPayload.pbaFunctionalAnalysisDataId = selectedAnalysis.pbaFunctionalAnalysisDataId;
                } else {
                  editPayload.tbaFunctionalAnalysisDataId = selectedAnalysis.tbaFunctionalAnalysisDataId;
                }

                void editFunctionalAnalysisCharacteristic(editPayload, currentSection);
              }
            }}
          />
        </>
      ) : (
        <FunctionalAnalysisSplashImageHolder />
      )}
    </Box>
  );
};
