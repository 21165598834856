import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Block as BlockIcon } from '@material-ui/icons';
import { BulkRejectionsProps } from './BulkRejections.proptype';
import { AppliedBulkRejections } from './components/AppliedBulkRejections';
import { SplitView } from '../../../../components/SplitView';
import { selectWorkingContainer } from '../../../../selectors';
import designTokens from '../../../../styles/designTokens';
import { mediumFont } from '../../../../styles/fonts';
import { body2, captionOverline } from '../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../utils/formatPliDisplayValue';
import { logGoogleAnalyticsEvent } from '../../../../utils/sendGoogleAnalyticaEvent';
import { SingleChipProps, SummaryChips } from '../../../SummaryChips';
import { StatusTypes } from '../../../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';
import { CompanyTable } from '../CompanyTable';

const useStyles = makeStyles(() => ({
  boxSection: {
    height: '100%'
  },
  chipsWrapper: {
    display: 'flex'
  },
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily,
    textAlign: 'left'
  },
  iconWrapper: {
    marginRight: '0.25rem',
    '& svg': {
      width: '0.75rem',
      height: '0.75rem'
    }
  },
  blockedCompany: {
    '& .MuiTableCell-root': {
      color: designTokens.core2,
      backgroundColor: designTokens.neutral70
    },
    '& .MuiTableCell-body:first-child': {
      color: designTokens.core2,
      backgroundColor: designTokens.neutral70
    }
  },
  filterCompany: {
    '& .MuiTableCell-root': {
      color: designTokens.core1,
      backgroundColor: designTokens.purpleLight3
    },
    '& .MuiTableCell-body:first-child': {
      color: designTokens.core1,
      backgroundColor: designTokens.purpleLight3,
      '& svg': {
        color: designTokens.purpleLight2
      }
    }
  },
  tableWrapper: {
    height: '100%',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body.right.MuiTableCell-alignRight': {
      paddingRight: '2.25rem'
    }
  },
  captionOverline,
  body2
}));

export const BulkRejectionsTab = ({
  comparables,
  appliedBulkRejections,
  nonAppliedBulkRejections,
  companies,
  columns,
  onApplyBulkRejection,
  applyBulkRejectionsIsLoading,
  pliFormat,
  pliId
}: BulkRejectionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const container = useSelector(selectWorkingContainer);

  const [filteringBulkRejection, setFilteringBulkRejection] = useState<string>();
  const bulkRejectedComparables = (companies ?? []).filter((comparable: any) => comparable.isBulkRejected === 1);
  const [selectedStatus, setSelectedStatus] = useState<StatusTypes>('total');
  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  const displaybleComparables: Array<Record<string, HTMLElement | number>> = useMemo(() => {
    const resultCompanies = filteringBulkRejection
      ? (companies ?? []).filter((comparable: any) => comparable.rejectionType === filteringBulkRejection)
      : companies ?? [];

    return resultCompanies.map((comparable: any) => {
      const displayableComp: any = {};
      Object.keys(comparable).forEach((key) => {
        if (typeof comparable[key] === 'number' && key !== 'sourceId') {
          displayableComp[key] = formatPliDisplayValue(formatType, roundingValue, comparable[key]);
        } else if (typeof comparable[key] === 'string') {
          if (comparable.isBulkRejected === 1) {
            displayableComp[key] = (
              <span className={classes.truncate} title={comparable[key]} data-value={comparable[key]}>
                <span className={classes.iconWrapper}>
                  <BlockIcon />
                </span>
                <span>{comparable[key]}</span>
              </span>
            );
            displayableComp.className = filteringBulkRejection ? classes.filterCompany : classes.blockedCompany;
          } else {
            displayableComp[key] = (
              <span className={classes.truncate} title={comparable[key]}>
                {comparable[key]}
              </span>
            );
          }
        }
      });
      return displayableComp;
    });
  }, [classes, companies, filteringBulkRejection, formatType, roundingValue]);

  const handleSelectView = (key: StatusTypes) => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      event_category: 'pba_button_click',
      event_label: `Bulk rejection filter clicked for ${key}`,
      container_id: container?.containerId
    });
    setSelectedStatus(key);
  };

  const chipsData: SingleChipProps[] = useMemo(
    () =>
      filteringBulkRejection
        ? [
            {
              isSelected: false,
              label: t(`analysis:${t(`analysis:${filteringBulkRejection}`)}`),
              key: 'total'
            }
          ]
        : [
            {
              isSelected: selectedStatus === 'total',
              label: t('analysis:comparable-range-total'),
              amount: displaybleComparables.length,
              key: 'total',
              disabled: displaybleComparables.length === 0
            },
            {
              isSelected: selectedStatus === 'rejected',
              label: t('analysis:comparable-range-rejected'),
              amount: bulkRejectedComparables.length,
              key: 'rejected',
              disabled: bulkRejectedComparables.length === 0
            },
            {
              isSelected: selectedStatus === 'remaining',
              label: t('analysis:comparable-range-remaining'),
              amount: displaybleComparables.length - bulkRejectedComparables.length,
              key: 'remaining',
              disabled: displaybleComparables.length - bulkRejectedComparables.length === 0
            }
          ],
    [filteringBulkRejection, displaybleComparables, bulkRejectedComparables, selectedStatus, t]
  );

  const filteredByStatus = displaybleComparables.filter((comp) => {
    if (selectedStatus === 'total') {
      return true;
    }

    if (selectedStatus === 'rejected' && Number.parseInt(String(comp.isBulkRejected), 10) === 100) {
      return true;
    }

    if (selectedStatus === 'remaining') {
      if (Number.parseInt(String(comp.isBulkRejected), 10) === 100) return false;
      return true;
    }

    return false;
  });

  return (
    <SplitView
      left={
        <div className={classes.tableWrapper}>
          <CompanyTable
            comparables={comparables}
            companies={filteredByStatus}
            columns={columns}
            filteringBulkRejection={filteringBulkRejection}
            chips={<SummaryChips clickHandler={handleSelectView} chips={chipsData} />}
          />
        </div>
      }
      right={
        <AppliedBulkRejections
          comparables={comparables}
          appliedBulkRejections={appliedBulkRejections}
          nonAppliedBulkRejections={nonAppliedBulkRejections}
          applyBulkRejectionsIsLoading={applyBulkRejectionsIsLoading}
          onApplyFilterBulkRejection={(rejectedType) => {
            setFilteringBulkRejection(t(`analysis:${t(`analysis:${rejectedType}`)}`));
          }}
          onApplyBulkRejection={onApplyBulkRejection}
        />
      }
      className={classes.boxSection}
    />
  );
};
