import { useEffect, ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TransactionDetailsProps, TransactionDetailsTab } from './TransactionDetails.proptype';
import {
  MethodEvaluation as MethodEvaluationModel,
  ProfitBasedEvaluation,
  Transaction,
  TransactionBasedEvaluation,
  TransactionEvaluations,
  TransactionServiceEvaluation
} from '../../models';
import { fetchEntities } from '../../redux/entities';
import {
  fetchTransactionEvaluations,
  saveMethodEvaluation,
  saveMethodEvaluationEditorText
} from '../../redux/transactionEvaluations';
import {
  fetchServiceEvaluation,
  fetchTransactions,
  saveServiceEvaluation,
  saveServiceEvaluationEditorText
} from '../../redux/transactions';
import {
  selectEntitiesList,
  selectEvaluationsCompletion,
  selectServiceEvaluation,
  selectTransaction,
  selectTransactionEvaluations,
  selectTransactionsList
} from '../../selectors';
import { findTransactionDestination, findTransactionSource, hasEditAccess } from '../../utils';
import { DetailedWithTabsProps } from '../DetailedWithTabs';
import { MethodEvaluation as MethodEvaluationComponent } from '../MethodEvaluation';
import { TransactionEvaluation as TransactionEvaluationComponent } from '../TransactionEvaluation';
import { TransactionStatementOfFacts } from '../TransactionStatementOfFacts';

interface ConnectorProps extends TransactionDetailsProps {
  component: ComponentType<DetailedWithTabsProps>;
}

const { StatementOfFacts, MethodEvaluation, TransactionEvaluation } = TransactionDetailsTab;

const allowsTransactionEvaluation = (transaction: Transaction | null) =>
  transaction ? transaction.transactionType.name === 'Services' : true;

const allowsMethodEvaluation = (evaluations?: TransactionEvaluations) =>
  evaluations ? (evaluations.pbas.length ?? 0) > 0 || (evaluations.tbas.length ?? 0) > 0 : true;

const Connector = ({ transactionId, tab, component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const entities = useSelector(selectEntitiesList);
  const transactions = useSelector(selectTransactionsList);
  const transaction = useSelector(selectTransaction(transactionId));
  const evaluations = useSelector(selectTransactionEvaluations({ transactionId }));
  const serviceEvaluation = useSelector(selectServiceEvaluation(transactionId));
  const evaluationsCompletion = useSelector(selectEvaluationsCompletion({ transactionId, transaction, entities }));

  useEffect(() => {
    if (entities === null) {
      dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (transactions === null) {
      dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (!evaluations) {
      dispatch(fetchTransactionEvaluations({ transactionId }));
    }
  }, [dispatch, evaluations, transactionId]);

  useEffect(() => {
    if (
      transaction &&
      ((tab === TransactionEvaluation && !allowsTransactionEvaluation(transaction)) ||
        (tab === MethodEvaluation && !allowsMethodEvaluation(evaluations)))
    ) {
      history.push(`/transactions/${transaction.transactionId}/details/${StatementOfFacts}`);
    }
  }, [history, tab, transaction, evaluations]);

  useEffect(() => {
    if (
      tab === TransactionEvaluation &&
      transaction &&
      allowsTransactionEvaluation(transaction) &&
      !serviceEvaluation
    ) {
      dispatch(fetchServiceEvaluation(transaction.transactionId));
    }
  }, [dispatch, transaction, tab, serviceEvaluation]);

  const { source, destination } = useMemo(() => {
    const entityById = new Map((entities ?? []).map((entity) => [entity.entityId, entity]));
    return {
      source: findTransactionSource(transaction, entityById),
      destination: findTransactionDestination(transaction, entityById)
    };
  }, [transaction, entities]);

  const handleSaveMethodEvaluation = async (
    method: MethodEvaluationModel,
    analysis: TransactionBasedEvaluation | ProfitBasedEvaluation
  ) => {
    if (!hasEditAccess()) return;
    dispatch(saveMethodEvaluation({ transactionId, method, analysis }));
  };

  const handleSaveServiceEvaluation = async (evaluation: TransactionServiceEvaluation) => {
    if (!hasEditAccess()) return;
    dispatch(saveServiceEvaluation({ transactionId, evaluation }));
  };

  const handleSaveServiceEvaluationEditorText = async (evaluation: TransactionServiceEvaluation) => {
    if (!hasEditAccess()) return;
    dispatch(saveServiceEvaluationEditorText({ transactionId, evaluation }));
  };

  const handleSaveMethodEvaluationEditorText = async (
    method: MethodEvaluationModel,
    analysis: TransactionBasedEvaluation | ProfitBasedEvaluation
  ) => {
    if (!hasEditAccess()) return;
    dispatch(saveMethodEvaluationEditorText({ transactionId, method, analysis }));
  };

  const selected =
    !transaction ||
    (tab === TransactionEvaluation && allowsTransactionEvaluation(transaction)) ||
    (tab === MethodEvaluation && allowsMethodEvaluation(evaluations))
      ? tab
      : StatementOfFacts;

  const tabs = [
    {
      key: StatementOfFacts,
      label: t('transactions:title-sof'),
      disabled: false,
      content: <TransactionStatementOfFacts />
    },
    {
      key: MethodEvaluation,
      label: t('transactions:title-method'),
      disabled: !allowsMethodEvaluation(evaluations),
      content: (
        <MethodEvaluationComponent
          transactionType={transaction?.transactionType.transactionTypeId ?? 0}
          sourceJurisdiction={source?.domicile}
          destinationJurisdiction={destination?.domicile}
          evaluations={evaluations}
          evaluationsCompletion={evaluationsCompletion}
          onSave={handleSaveMethodEvaluation}
          onAutoSave={handleSaveMethodEvaluationEditorText}
        />
      )
    }
  ];

  if (allowsTransactionEvaluation(transaction)) {
    tabs.splice(1, 0, {
      key: TransactionEvaluation,
      label: t('transactions:title-evaluation'),
      disabled: false,
      content: (
        <TransactionEvaluationComponent
          sourceEntity={source}
          destinationEntity={destination}
          data={serviceEvaluation}
          onSave={handleSaveServiceEvaluation}
          onAutoSave={handleSaveServiceEvaluationEditorText}
        />
      )
    });
  }

  return (
    <Component
      title={t('transactions:title-details')}
      data={transaction ?? undefined}
      selectedKey={selected}
      tabs={tabs}
      onSelectTab={(tab: string) => {
        history.push(`/transactions/${transactionId}/details/${tab}`);
      }}
    />
  );
};

export default Connector;
