import { ComponentType } from 'react';
import { Container } from '../../models';

export interface FunctionalAnalysisTableProps {
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[] | TBAFunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[] | TBAFunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[] | TBAFunctionalAnalysisCharacteristic[];
      }
    | undefined;
  editFunctionalAnalysisCharacteristic: (characteristic: any, areaId: number) => Promise<void>;
  deleteFunctionalAnalysisCharacteristicRow: (characteristicId: number, areaId: number) => void;
  createNewCharacteristic: (characteristicName: string, areaId: number) => void;
  selectedAnalysis: any;
  setSelectedAnalysis: (item: any) => void;
  setCurrentSection: (item: number) => void;
  currentSection: number;
}

export interface FunctionalAnalysisProps {
  functionalAnalysisCharacteristics:
    | {
        Functions: FunctionalAnalysisCharacteristic[];
        Risks: FunctionalAnalysisCharacteristic[];
        Assets: FunctionalAnalysisCharacteristic[];
      }
    | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId?: number
  ) => Promise<void>;
  setSelectedAnalysis: (item: FunctionalAnalysisCharacteristic) => void;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  unsetAnalysis: () => void;
  setCurrentSection: (item: number) => void;
  currentSection: number;
  reinitialize: () => Promise<void>;
}

export interface ConnectorProps extends FunctionalAnalysisProps {
  component: ComponentType<FunctionalAnalysisTableProps>;
}

export interface FunctionalCharacteristicType {
  functionalCharacteristicTypeId: number;
  sortOrder: number;
  name: string;
  discussion: string;
}

export interface FunctionalAnalysisCharacteristic {
  characteristicName: string;
  discussion: string;
  otherParticipantContribution: boolean;
  pbaFunctionalAnalysisDataId: number;
  tbaFunctionalAnalysisDataId?: number;
  sortOrder: number;
  testedPartyContribution: boolean;
  discussionOnly?: boolean;
  functionalCharacteristicType?: FunctionalCharacteristicType | null;
}

export interface TBAFunctionalAnalysisCharacteristic {
  characteristicName: string;
  discussion: string;
  srcContribution: boolean;
  tbaFunctionalAnalysisDataId: number;
  sortOrder: number;
  destContribution: boolean;
  functionalCharacteristicType?: FunctionalCharacteristicType | null;
  container?: Container;
}

export interface UpdateFunctionalAnalysisNarrativeParams {
  studyId: number;
  tbaId?: number;
  type: number;
  container: Container;
  sourceId: number;
  uncontrolled: boolean;
}

export interface FunctionalAnalysisListItemProps {
  item: any;
  disabled?: boolean;
  setSelectedAnalysis: (item: any) => void;
  setCurrentSection: (areaId: number) => void;
  selectedAnalysis: FunctionalAnalysisCharacteristic | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId: number
  ) => Promise<void>;
  areaId: number;
  deleteFunctionalAnalysisCharacteristicRow: (characteristicId: number, areaId: number) => void;
  functionalAnalysisCharacteristics: FunctionalAnalysisCharacteristic[] | TBAFunctionalAnalysisCharacteristic[];
}

export interface FunctionalAnalysisDiscussionEditorProps {
  selectedAnalysis: any;
  currentSection: number;
  editFunctionalAnalysisCharacteristic: (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId: number
  ) => Promise<void>;
}

export const functionalAnalysisHeaders = ['Functions', 'Risks', 'Assets'];

export enum AreaIds {
  AreaIdFunctions = 1,
  AreaIdRisks,
  AreaIdAssets
}

export const InitialExpanedSections =
  String(AreaIds.AreaIdFunctions - 1) + String(AreaIds.AreaIdRisks - 1) + String(AreaIds.AreaIdAssets - 1);
