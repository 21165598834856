import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  StatisticalMethodIds,
  UncontrolledTransactionComparabilities
} from '../app/TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';
import { Country } from '../models';
import { fetchEntities } from '../redux/entities';
import {
  createTbaRangeType,
  fetchTbaRangeResults,
  CreateTbaRangeType,
  fetchTbaRangeTypes,
  deleteTbaRangeType,
  fetchUncontrolledTransactionComparability,
  fetchCurrentTBA
} from '../redux/transactionBasedAnalyses';
import { selectWorkingContainer } from '../selectors';
import { AppDispatch } from '../store';
import { findTransactionDestination, findTransactionSource } from '../utils';

const getStatisticalMethodByJurisdiction = (isoCode?: string): number => {
  if (isoCode === 'USA') return StatisticalMethodIds.InterquartileRangeIRS;
  if (isoCode === 'CAN') return StatisticalMethodIds.FullRange;
  return StatisticalMethodIds.InterquartileRange;
};

export const useRangeTypes = (tbaId: number) => {
  const dispatch = useDispatch<AppDispatch>();
  const workingContainer = useSelector(selectWorkingContainer);

  const deleteRangeTypes = async () => {
    const { tba } = await dispatch(fetchCurrentTBA(tbaId)).unwrap();
    const { tbaRangeTypes } = await dispatch(fetchTbaRangeTypes(tba.tbaId)).unwrap();
    if (tbaRangeTypes) {
      await Promise.all(tbaRangeTypes.map((rangeType) => dispatch(deleteTbaRangeType(rangeType.tbaRangeTypeId)))).then(
        () => {
          void dispatch(fetchTbaRangeTypes(tba.tbaId));
          void dispatch(fetchTbaRangeResults(tba.tbaId));
        }
      );
    }
  };

  const handleRangeTypes = async () => {
    const { tba } = await dispatch(fetchCurrentTBA(tbaId)).unwrap();
    const uncontrolledTransactionComparability = unwrapResult(
      await dispatch(fetchUncontrolledTransactionComparability(tba.tbaId))
    );
    const { tbaRangeTypes } = await dispatch(fetchTbaRangeTypes(tba.tbaId)).unwrap();

    const acceptedTransactions = uncontrolledTransactionComparability
      ? uncontrolledTransactionComparability?.filter(
          (current) => current.status === UncontrolledTransactionComparabilities.Accepted
        )
      : [];

    if (acceptedTransactions?.length > 0) {
      if (tbaRangeTypes?.length === 0) {
        await createRangeTypes();
      }
    } else if (Array.isArray(tbaRangeTypes) && tbaRangeTypes?.length > 0) {
      await deleteRangeTypes();
    }
  };

  const createRangeTypes = async () => {
    const { tba } = await dispatch(fetchCurrentTBA(tbaId)).unwrap();
    const entities = await dispatch(fetchEntities()).unwrap();
    const entityById = new Map((entities ?? []).map((entity) => [entity.entityId, entity]));
    const transaction = tba?.transactions && tba?.transactions[0];
    const sourceEntity = findTransactionSource(transaction, entityById);
    const destinationEntity = findTransactionDestination(transaction, entityById);
    const sourceTaxJurisdiction = sourceEntity?.taxJurisdiction;
    const destinationTaxJurisdiction = destinationEntity?.taxJurisdiction;

    const tbaTaxJurisdictions: Array<{ jurisdiction?: Country; isSource: boolean }> = [
      { jurisdiction: sourceTaxJurisdiction, isSource: true }
    ];

    // Only if the country for the Tax Jurisdiction of the Source is different as the destination
    // adding the destination Entity to avoid duplicates.
    if (sourceTaxJurisdiction?.countryId !== destinationTaxJurisdiction?.countryId) {
      tbaTaxJurisdictions.push({ jurisdiction: destinationTaxJurisdiction, isSource: false });
    }

    await Promise.all(
      tbaTaxJurisdictions.map(({ jurisdiction, isSource }) => {
        const tbaRangeTypePayload: CreateTbaRangeType = {
          container: workingContainer!,
          name: `${isSource ? 'Source' : 'Destination'} ${jurisdiction?.isoCode ?? ''}`,
          statisticalMethod: getStatisticalMethodByJurisdiction(jurisdiction?.isoCode),
          useForSourceTaxJurisdiction: isSource,
          useForDestinationTaxJurisdiction: !isSource,
          tba
        };
        return dispatch(createTbaRangeType(tbaRangeTypePayload));
      })
    ).then(() => {
      void dispatch(fetchTbaRangeTypes(tba.tbaId));
      void dispatch(fetchTbaRangeResults(tba.tbaId));
    });
  };

  return [handleRangeTypes, createRangeTypes];
};
