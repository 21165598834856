import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { AssociatedTransactions } from './AssociatedTransactions';
import { TestedPartyDetailsForm } from './TestedPartyDetailsForm';
import { Transaction } from '../../../models';
import {
  filterTransactionsByTestedPartyAndPrimaryEntity,
  getTradingPairFromTransaction
} from '../../../utils/analysis';
import { ProfitBasedAnalysisAddModalTable } from '../../NewAnalysisModal';
import { SelectedTransaction } from '../../NewAnalysisModal/CreatePBAModal.proptypes';
import { TPModal } from '../../TPModal';
import { PBADashboardSummaryProps } from '../PBADashboardTPDSummary.proptype';

const useStyles = makeStyles((theme) => ({
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  },
  titleClass: {
    display: 'inline-flex',
    float: 'left'
  },
  dropdownClass: {
    float: 'right'
  },
  placeHolder: {
    color: theme.palette.text.secondary
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const PBADashboardTPDSummary = ({
  pba,
  currentTestedParty,
  studyPrimaryEntity,
  onSubmit,
  onTransactionsUpdateSubmit,
  primaryFunctionOptions,
  transactionsPBA,
  testedPartyTransactions,
  entities,
  onDeleteAssociateTransaction,
  upeCurrency,
  trackGATime
}: PBADashboardSummaryProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [addTransactionModalOpen, setAddTransactionModalOpen] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState<SelectedTransaction[]>([]);
  const [showError, setShowError] = useState(false);
  const [associatedTransactions, setAssociatedTransactions] = useState<Transaction[]>(testedPartyTransactions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (testedPartyTransactions && studyPrimaryEntity && currentTestedParty) {
      if (transactionsPBA?.length) {
        // remove transactions that are already part of the study, though TBA shows them.
        // todo: DUO-2490: Add Associated Transactions Modal for PBA should match TBA Behavior
        transactionsPBA.forEach((pbatransaction) => {
          const index = testedPartyTransactions.findIndex(
            (transaction: Transaction) => pbatransaction.transactionId === transaction.transactionId
          );
          if (index >= 0) {
            testedPartyTransactions.splice(index, 1);
          }
        });
      }

      if (
        currentTestedParty.pba?.primaryLegalEntity?.entityId === studyPrimaryEntity?.entityId &&
        transactionsPBA?.length
      ) {
        const tradingPair = getTradingPairFromTransaction(transactionsPBA[0], studyPrimaryEntity.entityId);
        if (tradingPair.testedParty && tradingPair.primaryEntity) {
          const transactionsFilteredByTradingPair = filterTransactionsByTestedPartyAndPrimaryEntity({
            primaryEntity: tradingPair.primaryEntity,
            testedParty: tradingPair.testedParty,
            transactions: testedPartyTransactions
          });
          setAssociatedTransactions(transactionsFilteredByTradingPair);
        }
      } else {
        const transactionsFilteredByTradingPair = filterTransactionsByTestedPartyAndPrimaryEntity({
          testedParty: currentTestedParty.pba.primaryLegalEntity,
          primaryEntity: studyPrimaryEntity,
          transactions: testedPartyTransactions
        });
        setAssociatedTransactions(transactionsFilteredByTradingPair);
      }
    }
  }, [testedPartyTransactions, currentTestedParty, studyPrimaryEntity, transactionsPBA]);

  const onAddTransactions = () => {
    setAddTransactionModalOpen(true);
  };

  const onClose = () => {
    closeAndReset();
  };

  const handleClose = () => {
    closeAndReset();
  };

  const closeAndReset = () => {
    setSelectedTransactions([]);
    setShowError(false);
    setAddTransactionModalOpen(false);
  };

  const onSelectTransactionChange = (selectedTransactionIds: SelectedTransaction[]) => {
    if (selectedTransactionIds.length > 0 && studyPrimaryEntity) {
      const selectedTransactions = selectedTransactionIds.map((tr: SelectedTransaction) =>
        testedPartyTransactions.find((tPT) => tPT.transactionId === tr.transactionId)
      );

      if (selectedTransactions?.[0]) {
        const tradingPair = getTradingPairFromTransaction(selectedTransactions[0], studyPrimaryEntity.entityId);

        if (tradingPair.testedParty && tradingPair.primaryEntity) {
          const transactionsFilteredByTradingPair = filterTransactionsByTestedPartyAndPrimaryEntity({
            primaryEntity: tradingPair.primaryEntity,
            testedParty: tradingPair.testedParty,
            transactions: testedPartyTransactions
          });
          setAssociatedTransactions(transactionsFilteredByTradingPair);
        }
      }
    } else if (currentTestedParty && studyPrimaryEntity) {
      const filteredTransactions = filterTransactionsByTestedPartyAndPrimaryEntity({
        testedParty: currentTestedParty.pba.primaryLegalEntity,
        primaryEntity: studyPrimaryEntity,
        transactions: testedPartyTransactions
      });

      setAssociatedTransactions(filteredTransactions);
    } else {
      setAssociatedTransactions(testedPartyTransactions);
    }

    setSelectedTransactions(selectedTransactionIds);
    setShowError(false);
  };

  return (
    <>
      <TestedPartyDetailsForm
        currentTestedParty={currentTestedParty}
        pba={pba}
        primaryFunctionOptions={primaryFunctionOptions}
        trackGATime={trackGATime}
        onSubmit={onSubmit}
      />
      <AssociatedTransactions
        pba={pba}
        transactionsPBA={transactionsPBA}
        entities={entities}
        upeCurrency={upeCurrency}
        onAddTransactions={onAddTransactions}
        onDelete={onDeleteAssociateTransaction}
      />
      {addTransactionModalOpen && (
        <TPModal
          isOpen
          maxWidth="md"
          title={
            <span className={classes.titleClass}>
              <AddIcon className={classes.headerIcon} />
              <Typography variant="h6">{t('analysis:title-add-associated-transactions')}</Typography>
            </span>
          }
          actions={[
            {
              name: t('action-cancel'),
              handler: () => {
                onClose();
              }
            },
            {
              name: isLoading ? '' : t('action-done'),
              className: classes.doneButton,
              handler: async () => {
                if (selectedTransactions.length > 0) {
                  setShowError(false);
                  setIsLoading(true);
                  await onTransactionsUpdateSubmit!(selectedTransactions, pba.pbaId);
                  setSelectedTransactions([]);
                  setIsLoading(false);
                  handleClose();
                } else {
                  setShowError(true);
                }
              }
            }
          ]}
          onClose={handleClose}
        >
          <Grid>
            <ProfitBasedAnalysisAddModalTable
              entities={entities}
              selectedTransactions={selectedTransactions}
              transactions={associatedTransactions}
              upeCurrency={upeCurrency}
              onChange={onSelectTransactionChange}
            />
            <ErrorMessage error={showError} message={t('analysis:invalid-add-associated-transactions')} />
          </Grid>
        </TPModal>
      )}
    </>
  );
};
