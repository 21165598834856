import { findCountryCurrency } from './countries';
import { Country, Currency, Entity } from '../models';

export const enrichWithCountriesAndCurrencies = (
  entities: Entity[] | undefined,
  countries: Country[] | undefined,
  currencies: Currency[] | undefined
) =>
  (entities ?? []).map((entity) => {
    const country = findEntityCountry(entity, countries);
    return { ...entity, country, currency: findCountryCurrency(country, currencies) };
  });

// TODO refactor in selectCountry
export const findEntityCountry = (entity: Entity | undefined | null, countries: Country[] | undefined) =>
  (countries ?? []).find(({ countryId }) => countryId === entity?.domicile?.countryId);
