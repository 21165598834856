import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BulkRejectionsTab as Component } from './BulkRejectionsTab';
import { RangeComparables, BulkRejection, PbaJurisdictionPliFormat } from '../../../../models';
import {
  fetchInitialRangeComparablesWithRejections,
  fetchAppliedBulkRejections,
  fetchNonAppliedBulkRejections,
  applyUnappliedBulkRejection,
  fetchJurisdictionPliFormats
} from '../../../../redux/profitBasedAnalyses';
import {
  selectInitialRangeComparablesWithRejections,
  selectAppiedBulkRejections,
  selectNonAppiedBulkRejections,
  selectWorkingContainer,
  selectJurisdictionPliFormat
} from '../../../../selectors';
import { AppDispatch } from '../../../../store';

const Connector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgId = Number(useParams<{ pliAvgId: string }>().pliAvgId);
  const initialComparables: RangeComparables | undefined = useSelector(selectInitialRangeComparablesWithRejections);
  const { t } = useTranslation();
  const appliedBulkRejections: BulkRejection[] | null = useSelector(selectAppiedBulkRejections) ?? null;
  const nonAppliedBulkRejections: BulkRejection[] | null = useSelector(selectNonAppiedBulkRejections) ?? null;
  const workingContainer = useSelector(selectWorkingContainer);
  const [applyBulkRejectionsIsLoading, setApplyBulkRejectionsIsLoading] = useState(false);
  const flags = useFlags();

  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));
  const pliFormat = jurisdictionPliFormat
    ? jurisdictionPliFormat.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pliId)
    : null;

  const tableData = useMemo(() => {
    if (!initialComparables?.data) return [];

    return initialComparables.data.map((comparable) => ({
      ...comparable,
      rejectionType: comparable.rejectionType ? t(`analysis:${comparable.rejectionType}`) : ''
    }));
  }, [initialComparables?.data, t]);

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliId && pliAvgId && !initialComparables) {
      void dispatch(
        fetchInitialRangeComparablesWithRejections({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId,
          useS3Flag: flags.getRangeComparables
        })
      );
    }
  }, [dispatch, initialComparables, compSearchId, jurisdictionId, pliId, pliAvgId, flags]);

  useEffect(() => {
    if (!appliedBulkRejections) {
      void dispatch(
        fetchAppliedBulkRejections({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
    }
  }, [dispatch, appliedBulkRejections, compSearchId, jurisdictionId, pliId, pliAvgId]);

  useEffect(() => {
    if (!nonAppliedBulkRejections) {
      void dispatch(
        fetchNonAppliedBulkRejections({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId
        })
      );
    }
  }, [dispatch, nonAppliedBulkRejections, compSearchId, jurisdictionId, pliId, pliAvgId]);

  const onApplyBulkRejection = async (isRejected: boolean, rejectionTypeId: number) => {
    setApplyBulkRejectionsIsLoading(true);
    await dispatch(
      applyUnappliedBulkRejection({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId,
        rejectionTypeId,
        isApplying: isRejected,
        containerId: workingContainer?.containerId!
      })
    );

    await dispatch(
      fetchNonAppliedBulkRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId
      })
    );

    await dispatch(
      fetchAppliedBulkRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId
      })
    );

    await dispatch(
      fetchInitialRangeComparablesWithRejections({
        compSearchId,
        jurisdictionId,
        pliId,
        pliAvgId,
        useS3Flag: flags.getRangeComparables
      })
    );
    setApplyBulkRejectionsIsLoading(false);
  };

  const initialComparablesColumns = [
    { key: 'pinned', label: '' },
    ...(initialComparables ? [...initialComparables.columns] : [])
  ];

  return (
    <Component
      comparables={initialComparables}
      companies={tableData}
      columns={initialComparablesColumns}
      appliedBulkRejections={appliedBulkRejections ?? []}
      nonAppliedBulkRejections={nonAppliedBulkRejections ?? []}
      applyBulkRejectionsIsLoading={applyBulkRejectionsIsLoading}
      pliFormat={pliFormat}
      pliId={pliId}
      onApplyBulkRejection={onApplyBulkRejection}
    />
  );
};

export default Connector;
