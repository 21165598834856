import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CopyContainerInfo,
  GetHistoricalCopyContainerInfoPayload,
  GetIsRollableResponse,
  GetRollingStatus,
  RollContainerResponse,
  RollingContainersState,
  GetRollForecastResponse
} from './rollingContainers.proptype';
import { RollingContainerStatus } from '../../app/CopyContainerModal/CopyContainerModal.proptypes';
import httpService from '../../services/http';

export const getRollingStatus = createAsyncThunk<
  GetRollingStatus,
  { parentContainerId: number; childContainerId: number },
  { rejectValue: Error | Error['message'] }
>('rollingContainers/getRollingStatus/fetch', async ({ parentContainerId, childContainerId }, thunkApi) => {
  try {
    const { data } = await httpService.request<GetRollingStatus>({
      method: 'get',
      apiUrlKey: 'principleUrl',
      relativePath: `rolling/srcContainer/${parentContainerId}/dstContainer/${childContainerId}/status`
    });
    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return thunkApi.rejectWithValue(error as Error);
  }
});

export const getHistoricalCopyContainerInfo = createAsyncThunk<
  CopyContainerInfo[],
  GetHistoricalCopyContainerInfoPayload,
  { rejectValue: Error | Error['message'] }
>('rollingContainers/getHistoricalCopyContainerInfo', async ({ containerId, taxYear }, thunkApi) => {
  try {
    const { data } = await httpService.request<{ data: CopyContainerInfo[]; requestId: string }>({
      method: 'get',
      apiUrlKey: 'principleUrl',
      relativePath: `rolling/container/${containerId}/year/${taxYear}/historical`
    });

    return data.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return thunkApi.rejectWithValue(error as Error);
  }
});

export const getIsRollable = createAsyncThunk<GetIsRollableResponse, number, { rejectValue: Error | Error['message'] }>(
  'rollingContainers/isRollableStatus/get',
  async (containerId, thunkApi) => {
    try {
      const { data } = await httpService.request<GetIsRollableResponse>({
        method: 'get',
        apiUrlKey: 'principleUrl',
        relativePath: `container/rollable/${containerId}`
      });
      return data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return thunkApi.rejectWithValue(error.message);
      }

      return thunkApi.rejectWithValue(error as Error);
    }
  }
);

export const getRollingForecast = createAsyncThunk<
  GetRollForecastResponse,
  {
    direction: string;
    containerId: number;
    taxYear: number;
  },
  { rejectValue: Error | Error['message'] }
>('rollingContainers/rollingForecast/get', async ({ ...rollForecastInfo }, thunkApi) => {
  try {
    const { data } = await httpService.request<GetRollForecastResponse>({
      method: 'get',
      data: rollForecastInfo,
      apiUrlKey: 'principleUrl',
      relativePath: `rolling/container/${rollForecastInfo.containerId}/year/${rollForecastInfo.taxYear}/forecast`
    });
    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return thunkApi.rejectWithValue(error as Error);
  }
});

export const rollContainer = createAsyncThunk<
  RollContainerResponse,
  {
    containerId: number;
    newTaxYear: number;
  },
  { rejectValue: Error | Error['message'] }
>('rollingContainers/rollStatus/post', async ({ ...rollContainerInfo }, thunkApi) => {
  try {
    const { data } = await httpService.request<RollContainerResponse>({
      method: 'post',
      data: rollContainerInfo,
      apiUrlKey: 'principleUrl',
      relativePath: `container/roll/${rollContainerInfo.containerId}`
    });
    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return thunkApi.rejectWithValue(error.message);
    }

    return thunkApi.rejectWithValue(error as Error);
  }
});

export const initialState: RollingContainersState = {
  rollingContainerIds: { fromContainerId: 0, toContainerId: 0 },
  rollingStatus: 0,
  canRollBack: false,
  canRollForward: false,
  cantRollBackReason: null,
  cantRollForwardReason: null,
  rollForwardForecast: {
    isIncrementalRoll: false,
    containerName: '',
    lastRolledBy: '',
    lastRolledDate: ''
  },
  rollBackForecast: {
    isIncrementalRoll: false,
    containerName: '',
    lastRolledBy: '',
    lastRolledDate: ''
  },
  historicalCopyContainerInfo: {
    historicalCopyInfo: [],
    currentContainerId: Number.NaN,
    currentTaxYear: Number.NaN
  }
};

const rollingContainersSlice = createSlice({
  name: 'rollingContainers',
  initialState,
  reducers: {
    resetRollingStatus: (state) => {
      state.rollingStatus = 0;
    },
    setCurrentContainerIdAndTaxYear(state, action: PayloadAction<{ containerId: number; taxYear: number }>) {
      state.historicalCopyContainerInfo.currentContainerId = action.payload.containerId;
      state.historicalCopyContainerInfo.currentTaxYear = action.payload.taxYear;
    },
    resetCanRollButton: (state, action: PayloadAction<{ direction: string }>) => {
      if (action.payload.direction === 'FORWARD') {
        state.canRollForward = false;
      } else {
        state.canRollBack = false;
      }
    },
    resetRollingState: (state) => {
      state.rollingContainerIds = { fromContainerId: 0, toContainerId: 0 };
      state.rollingStatus = 0;
      state.canRollBack = false;
      state.canRollForward = false;
      state.cantRollBackReason = null;
      state.cantRollForwardReason = null;
      state.rollForwardForecast = {
        isIncrementalRoll: false,
        containerName: '',
        lastRolledBy: '',
        lastRolledDate: ''
      };
      state.rollBackForecast = {
        isIncrementalRoll: false,
        containerName: '',
        lastRolledBy: '',
        lastRolledDate: ''
      };
      state.historicalCopyContainerInfo = {
        historicalCopyInfo: [],
        currentContainerId: Number.NaN,
        currentTaxYear: Number.NaN
      };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getRollingStatus.fulfilled, (state: RollingContainersState, { payload }) => {
        state.rollingStatus = payload.data.statusId;
      })
      .addCase(getIsRollable.fulfilled, (state: RollingContainersState, { payload }) => {
        state.canRollBack = payload.data.canRollBack;
        state.canRollForward = payload.data.canRollForward;
        state.cantRollBackReason = payload.data.cantRollBackReason;
        state.cantRollForwardReason = payload.data.cantRollForwardReason;
      })
      .addCase(rollContainer.fulfilled, (state: RollingContainersState, action) => {
        state.rollingContainerIds.toContainerId = action.payload.data.destContainerId;
        state.rollingContainerIds.fromContainerId = action.meta.arg.containerId;
        state.rollingStatus = RollingContainerStatus.PENDING;
      })
      .addCase(getHistoricalCopyContainerInfo.fulfilled, (state: RollingContainersState, { payload }) => {
        state.historicalCopyContainerInfo.historicalCopyInfo = payload;
      })
      .addCase(getRollingForecast.fulfilled, (state: RollingContainersState, action) => {
        if (action.meta.arg.direction === 'FORWARD') {
          state.rollForwardForecast.isIncrementalRoll = action.payload.data.isIncrementalRoll;
          state.rollForwardForecast.containerName = action.payload.data.containerName;
          state.rollForwardForecast.lastRolledDate = action.payload.data.lastRollingUserAndDate;
          state.rollForwardForecast.lastRolledBy = action.payload.data.lastRolledBy;
        } else if (action.meta.arg.direction === 'BACK') {
          state.rollBackForecast.isIncrementalRoll = action.payload.data.isIncrementalRoll;
          state.rollBackForecast.containerName = action.payload.data.containerName;
          state.rollBackForecast.lastRolledDate = action.payload.data.lastRollingUserAndDate;
          state.rollBackForecast.lastRolledBy = action.payload.data.lastRolledBy;
        }
      });
  }
});

export const {
  resetRollingStatus,
  setCurrentContainerIdAndTaxYear,
  resetCanRollButton,
  resetRollingState
} = rollingContainersSlice.actions;

export const { reducer, actions } = rollingContainersSlice;
