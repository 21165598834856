import { Box, makeStyles } from '@material-ui/core';
import { TransactionOverviewForm } from './TransactionOverviewForm';
import { TbaTransactionOverviewProps } from '../TbaTransactionOverview.proptype';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: '1.375em',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export const TbaTransactionOverview = ({
  tba,
  tbaPropertiesLocked,
  tbaEvaluationMethodTypes,
  tbaSearchStrategy,
  tbaTransactions,
  initialTransactionTypes,
  onSubmitTBAData,
  onSubmitTBASearchStrategy,
  onDeleteAssociatedTransactionFromTBA,
  onSubmitFinancialInfo,
  study,
  transactions,
  upeCurrency,
  tbaParameters,
  onSubmitAssociatedTransactions: onSubmit
}: TbaTransactionOverviewProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.paperContainer}>
      <TransactionOverviewForm
        tba={tba}
        tbaPropertiesLocked={tbaPropertiesLocked}
        tbaEvaluationMethodTypes={tbaEvaluationMethodTypes}
        tbaSearchStrategy={tbaSearchStrategy}
        tbaTransactions={tbaTransactions}
        initialTransactionTypes={initialTransactionTypes}
        study={study}
        transactions={transactions}
        upeCurrency={upeCurrency}
        tbaParameters={tbaParameters}
        onDeleteAssociatedTransactionFromTBA={onDeleteAssociatedTransactionFromTBA}
        onSubmitTBASearchStrategy={onSubmitTBASearchStrategy}
        onSubmitTBAData={onSubmitTBAData}
        onSubmitAssociatedTransactions={onSubmit}
        onSubmitFinancialInfo={onSubmitFinancialInfo}
      />
    </Box>
  );
};
