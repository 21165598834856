import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ComparabilityTab } from './ComparabilityTab';
import {
  BulkUpdateEvaluationsApiCallParams,
  BulkUpdateSimilaritiesApiCallParams,
  ComparabileAnalysisTableTransactions,
  TbaComparableAnalysisProps,
  statusIdMap
} from './TbaComparableAnalysis.proptype';
import { CenteredProgress } from '../../components';
import { useRangeTypes } from '../../hooks/useRangeTypes';
import {
  fetchComparabilityOptions,
  fetchTbaRangeResults,
  fetchTbaRangeTypes,
  fetchTBAs,
  fetchUncontrolledTransactionComparability,
  fetchUncontrolledTransactions,
  updateUncontrolledTransactionComparabilityAcceptance,
  bulkUpdateUncontrolledTransactionComparabilityAcceptance,
  updateUncontrolledTransactionComparabilityEvaluation,
  bulkUpdateSimilarities,
  bulkUpdateEvaluations
} from '../../redux/transactionBasedAnalyses';
import { selectCurrentUncontrolledTransactionComparability, selectTBA } from '../../selectors';
import { AppDispatch } from '../../store';
import { TbaFinalRange } from '../TbaFinalRange';

interface ConnectorProps {
  component: ComponentType<TbaComparableAnalysisProps>;
}

const Connector = ({ component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch('/analysis/:studyId/tba-dashboard/:tbaId');
  const studyId: any = Number.parseInt(match.params?.studyId, 10);
  const tbaId = Number.parseInt(match.params?.tbaId, 10);
  const selectedTba = useSelector(selectTBA(tbaId));
  const [handleRangeTypes] = useRangeTypes(tbaId);
  const currentUncontrolledTransactionComparabilities =
    useSelector(selectCurrentUncontrolledTransactionComparability) ?? [];

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (selectedTba) {
      void dispatch(fetchUncontrolledTransactions());
      void dispatch(fetchComparabilityOptions());
      void dispatch(fetchUncontrolledTransactionComparability(selectedTba.tbaId));
    } else {
      void dispatch(fetchTBAs(Number(studyId)));
    }
  }, [dispatch, selectedTba, studyId]);

  const updateComparabilityApiCall = (transactionComparabilityId: number, status: number, tbaId: number) => {
    void dispatch(
      updateUncontrolledTransactionComparabilityAcceptance({
        transactionComparabilityId,
        status
      })
    )
      .then(handleRangeTypes)
      .then(() => {
        void dispatch(fetchUncontrolledTransactionComparability(tbaId));
        void dispatch(fetchUncontrolledTransactions());
        void dispatch(fetchComparabilityOptions());
        void dispatch(fetchTbaRangeTypes(tbaId));
        void dispatch(fetchTbaRangeResults(tbaId));
      });
  };

  const bulkUpdateComparabilityApiCall = async (
    uncontrolledTransactionIds: number[],
    status: number,
    tbaId: number
  ) => {
    await dispatch(
      bulkUpdateUncontrolledTransactionComparabilityAcceptance({
        uncontrolledTransactionIds,
        tbaId,
        status
      })
    );
    await handleRangeTypes();
    void dispatch(fetchUncontrolledTransactionComparability(tbaId));
    void dispatch(fetchUncontrolledTransactions());
    void dispatch(fetchComparabilityOptions());
  };

  const bulkUpdateSimilaritiesApiCall = async ({
    tbaId,
    uncontrolledTransactionIds,
    economicAnalysisSimilarity,
    functionalAnalysisSimilarity,
    contractualTermsSimilarity
  }: BulkUpdateSimilaritiesApiCallParams) => {
    await dispatch(
      bulkUpdateSimilarities({
        tbaId,
        uncontrolledTransactionIds,
        economicAnalysisSimilarity,
        functionalAnalysisSimilarity,
        contractualTermsSimilarity
      })
    );
    await handleRangeTypes();
    void dispatch(fetchUncontrolledTransactionComparability(tbaId));
    void dispatch(fetchUncontrolledTransactions());
    void dispatch(fetchComparabilityOptions());
  };

  const bulkUpdateEvaluationsApiCall = async ({
    tbaId,
    uncontrolledTransactionIds,
    comparabilityEvaluation
  }: BulkUpdateEvaluationsApiCallParams) => {
    await dispatch(
      bulkUpdateEvaluations({
        tbaId,
        comparabilityEvaluation,
        uncontrolledTransactionIds
      })
    );
    await handleRangeTypes();
    void dispatch(fetchUncontrolledTransactionComparability(tbaId));
    void dispatch(fetchUncontrolledTransactions());
    void dispatch(fetchComparabilityOptions());
  };

  const updateUncontrolledTransactionComparibility = (payload: ComparabileAnalysisTableTransactions) => {
    void dispatch(updateUncontrolledTransactionComparabilityEvaluation(payload)).then(() => {
      void dispatch(fetchComparabilityOptions());
      void dispatch(fetchUncontrolledTransactions());
      void dispatch(fetchUncontrolledTransactionComparability(tbaId));
    });
  };

  const tabs = [
    {
      key: 'comparability',
      label: t('analysis:comparability'),
      disabled: false,
      content: (
        <ComparabilityTab
          selectedTba={selectedTba}
          updateComparabilityApiCall={updateComparabilityApiCall}
          bulkUpdateComparabilityApiCall={bulkUpdateComparabilityApiCall}
          updateUncontrolledTransactionComparibility={updateUncontrolledTransactionComparibility}
          bulkUpdateSimilaritiesApiCall={bulkUpdateSimilaritiesApiCall}
          bulkUpdateEvaluationsApiCall={bulkUpdateEvaluationsApiCall}
        />
      )
    }
  ];

  if (currentUncontrolledTransactionComparabilities.some((tx) => tx.status === statusIdMap.accepted)) {
    tabs.push({
      key: 'final-range',
      label: t('analysis:final-range'),
      disabled: false,
      content: <TbaFinalRange selectedTba={selectedTba} />
    });
  }

  return selectedTba ? <Component selectedTba={selectedTba} tabs={tabs} /> : <CenteredProgress />;
};

export default Connector;
