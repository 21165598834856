import { SelectedTransaction } from './CreatePBAModal.proptypes';
import { Study, Transaction, Currency } from '../../models';
import { TbaParameter } from '../../redux/transactionBasedAnalyses';
import { UncontrolledTransactionParameters } from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

export type FinancialInfoFieldValues =
  | 'COGS (related) CP'
  | 'COGS (unrelated)'
  | 'Unit Price'
  | 'Units'
  | 'Transaction Amount'
  | 'Royalty'
  | 'Interest'
  | 'Service'
  | 'Resale Price'
  | 'Gross Profit Margin'
  | 'Gross Profit Markup';

export type FinancialInfoFieldValueMap = {
  [key in MethodTypes]?: {
    [key in TransactionTypes]?: FinancialInfoFieldValues[];
  };
};

export type FinancialInfoValues = {
  [key in FinancialInfoFieldValues]?: string;
};

export interface FinancialInfoFormProps {
  selectedMethod: MethodTypes | null;
  selectedTransactionType: TransactionTypes | null;
  selectedTransactions: SelectedTransaction[];
  transactions: Transaction[] | null;
  setFinancialFormHasErrors: (setFinancialFormHasErrors: boolean) => void;
  setFinancialInfo: (setFinancialInfo: FinancialInfoValues) => void;
  showErrors: boolean;
  tbaParameters?: TbaParameter[];
  originalFinancialInfo?: UncontrolledTransactionParameters[];
}

export interface FinancialInfoErrorsType {
  'COGS (related) CP'?: boolean;
  'COGS (unrelated)'?: boolean;
  'Unit Price'?: boolean;
  Units?: boolean;
  'Transaction Amount'?: boolean;
  Royalty?: boolean;
  Interest?: boolean;
  Service?: boolean;
  'Resale Price'?: boolean;
  'Gross Profit Margin'?: boolean;
  'Gross Profit Markup'?: boolean;
}

export interface CreateTBAModalProps {
  onClose: () => void;
  onSubmit: (data: TBAInputs) => void;
  study: Study | null;
  transactions: Transaction[] | null;
  upeCurrency: Currency | undefined;
}
export type TransactionTypes = 'Services' | 'Tangible Goods' | 'Intangible Goods' | 'Loans' | '';

export type PartyRoles =
  | 'Lender/Borrower'
  | 'Lessor/Lessee'
  | 'Licensor/Licensee'
  | 'Payer/Payee'
  | 'Renderer/Recipient'
  | 'Renter/Rentee'
  | 'Seller/Buyer';

export type MethodTypes = 'CP' | 'CUT/CUP' | 'RSP' | '';

export type TransactionsSortBy = 'source' | 'destination' | 'type';

export interface TBAInputs {
  name: string;
  selectedMethod: MethodTypes | null;
  selectedTransactionType: TransactionTypes;
  selectedTransactions: SelectedTransaction[];
  searchStrategy: string;
  financialInfo: FinancialInfoValues;
}

export interface selectMenuType {
  name: string;
  value: string;
  disabled?: boolean;
}

export const financialInfoFieldTypesMap: FinancialInfoFieldValueMap = {
  CP: {
    Services: ['COGS (related) CP'],
    'Tangible Goods': ['COGS (related) CP']
  },
  'CUT/CUP': {
    'Intangible Goods': ['Royalty'],
    Loans: ['Interest'],
    Services: ['Service'],
    'Tangible Goods': ['Unit Price', 'Units', 'Transaction Amount']
  },
  RSP: {
    'Tangible Goods': ['Resale Price', 'COGS (unrelated)']
  }
};
