import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { TransactionsSplashImage } from '../../../svgs';
import { InformationBox } from '../../InformationBox';
import { TransactionsEventsProps } from '../Transactions.proptype';

export const TransactionsSplashScreen = ({ onAddSingle, onImport }: TransactionsEventsProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" height={1} justifyContent="center" alignItems="center" bgcolor="secondary.light">
      <InformationBox
        image={<TransactionsSplashImage />}
        title={t('transactions:title-info-box')}
        subTitle={t('link-transactions')}
        text={t('transactions:message-info-box')}
        footerButtons={[
          { caption: t('transactions:action-new'), onClick: onAddSingle },
          { caption: t('transactions:action-import'), onClick: onImport }
        ]}
      />
    </Box>
  );
};
