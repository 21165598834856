import { Route, Switch, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import Connector from './connector';
import { JurisdictionAnalysisInfo } from '../JurisdictionAnalysisInfo';
import { InitialSearch } from '../NewAnalysisSideMenu/InitialSearch';
import { PBAFunctionalAnalysis } from '../PBAFunctionalAnalysis';
import RouteOptions from '../Router/RouteOptions';
import { SearchChecklist } from '../SearchChecklist';
import { TestedPartyCharacterization } from '../TestedPartyCharacterization';
import { TestedPartyDetails as TestedPartyDetailsComponent } from '../TestedPartyDetails';
import { TuneCompSearch } from '../TuneCompSearch';

const ChildRoutes = () => {
  const { path } = useRouteMatch();
  const matchJurisdictionPath: any = useRouteMatch(
    '/analysis/:studyId/pba-dashboard/:pbaId/jurisdiction/:jurisdictionId'
  );
  const jurisdictionId: number = matchJurisdictionPath?.params?.jurisdictionId ?? null;
  const jurisdictionPath: string = matchJurisdictionPath?.path ?? null;

  return (
    <Switch>
      <Route key="tested-party" path={`${path}/tested-party-details`}>
        <Connector component={TestedPartyDetailsComponent} />
      </Route>
      <Route key="tested-party-characterization" path={`${path}/tested-party-characterization`}>
        <Connector component={TestedPartyCharacterization} />
      </Route>
      <Route key="search-checklist" path={`${path}/search-checklist`}>
        <Connector component={SearchChecklist} />
      </Route>
      <Route key="initial-search" path={`${path}/initial-search`}>
        <Connector component={InitialSearch} />
      </Route>
      <Route key="tune-comp-search" path={`${jurisdictionPath}/comp-search/:compSearchId/pli/:pliId/pli-avg/:pliAvgId`}>
        <Connector component={TuneCompSearch} />
      </Route>
      <Route key="functional-analysis" path={`${path}/functional-analysis`}>
        <Connector component={PBAFunctionalAnalysis} />
      </Route>
      <Route
        key={`jurisdiction-analysis-info-${jurisdictionId}`}
        path={`${jurisdictionPath}/jurisdiction-analysis-info`}
      >
        <Connector component={JurisdictionAnalysisInfo} />
      </Route>
      <Redirect to={`${path}/tested-party-details`} />
    </Switch>
  );
};

export const ProfitBasedAnalysisDashboard = () => {
  return (
    <RouteOptions
      RootRoute={<Connector component={TestedPartyDetailsComponent} />}
      ChildRoutes={[{ path: '/:pbaId', component: <ChildRoutes /> }]}
    />
  );
};
