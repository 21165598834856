import { FinancialTypes } from '@xbs/xbs-enums';
import { TableBodyDataProps } from '../app/FinancialTable/FinancialTable.proptype';

export const calculateIncomeStatementFieldValues = (tableData: TableBodyDataProps[], year: string) => {
  const grossProfitCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.GrossProfit.Id);
  const totalNetSalesCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.TotalNetSales.Id);
  const costCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.CostOfGoodsSold.Id);
  const operatingExpensesCell: any = tableData.find((data) => data.id === FinancialTypes.ByName.OperatingExpenses.Id);
  const operatingProfit: any = tableData.find((data) => data.id === FinancialTypes.ByName.OperatingProfit.Id);

  const totalNetSalesValue = Number.parseFloat(totalNetSalesCell[year].value);
  const costsOfGoodsSoldValue = Number.parseFloat(costCell[year].value);

  if (totalNetSalesValue !== undefined && costsOfGoodsSoldValue !== undefined) {
    grossProfitCell[year].value = totalNetSalesValue - costsOfGoodsSoldValue;
  }

  if (!Number.isFinite(totalNetSalesValue) && !Number.isFinite(costsOfGoodsSoldValue)) {
    grossProfitCell[year].value = '';
  }

  if (!Number.isFinite(totalNetSalesValue) && Number.isFinite(costsOfGoodsSoldValue)) {
    grossProfitCell[year].value = '';
  }

  if (Number.isFinite(totalNetSalesValue) && !Number.isFinite(costsOfGoodsSoldValue)) {
    grossProfitCell[year].value = totalNetSalesValue;
  }

  const grossProfit = Number.parseFloat(grossProfitCell[year].value);
  const operatingExpenses = Number.parseFloat(operatingExpensesCell[year].value);

  if (!Number.isFinite(grossProfit) && !Number.isFinite(operatingExpenses)) {
    operatingProfit[year].value = '';
  }

  if (!Number.isFinite(grossProfit) && Number.isFinite(operatingExpenses)) {
    operatingProfit[year].value = '';
  }

  if (Number.isFinite(grossProfit) && !Number.isFinite(operatingExpenses)) {
    operatingProfit[year].value = grossProfit;
  }

  if (Number.isFinite(grossProfit) && Number.isFinite(operatingExpenses)) {
    operatingProfit[year].value = grossProfit - operatingExpenses;
  }

  return tableData;
};
