import { ComponentType, Dispatch, SetStateAction } from 'react';
import { TBAFunctionalAnalysisCharacteristic } from '../../components/FunctionalAnalysis/FunctionalAnalysisTable.proptype';

export interface TBAFunctionalAnalysisProps {
  functionalAnalysisCharacteristics:
    | {
        Functions: TBAFunctionalAnalysisCharacteristic[];
        Risks: TBAFunctionalAnalysisCharacteristic[];
        Assets: TBAFunctionalAnalysisCharacteristic[];
      }
    | undefined;
  editFunctionalAnalysisCharacteristic: (
    characteristic: TBAFunctionalAnalysisCharacteristic,
    areaId?: number
  ) => Promise<void>;
  selectedAnalysis: TBAFunctionalAnalysisCharacteristic | undefined;
  currentSection: number;
  setSelectedAnalysis: Dispatch<SetStateAction<TBAFunctionalAnalysisCharacteristic | undefined>>;
  setCurrentSection: Dispatch<SetStateAction<number>>;
  deleteFunctionalAnalysisCharacteristicRow: (characteristicId: number, areaId: number) => void;
  createNewCharacteristic: (characteristicName: string, areaId: number) => void;
  selectedContent: JSX.Element[];
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  trackGATime: () => void;
}

export interface ConnectorProps {
  component: ComponentType<TBAFunctionalAnalysisProps>;
}

export enum FunctionalAnalysisObjectTypeEnum {
  TBA = 1,
  UncontrolledTransaction,
  PBA
}
