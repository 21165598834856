import { useTranslation } from 'react-i18next';
import { StudyTabsTable } from '.';
import { StudyDashboardEmptyTabsImage } from '../../../svgs';
import { hasEditAccess } from '../../../utils';
import { InformationBoxHorizontal } from '../../InformationBoxHorizontalLayout';
import { StudyTabsTableProps } from '../StudyDashboard.proptype';

export const StudyDashboardSplashScreen = ({ onAddSingle, selectedKey }: StudyTabsTableProps) => {
  const { t } = useTranslation();

  const rightButton = hasEditAccess()
    ? [
        {
          caption: t(`analysis:action-new-${selectedKey}`),
          onClick: onAddSingle
        }
      ]
    : [];

  const displayText = hasEditAccess() ? t(`analysis:info-box-text-${selectedKey}`) : t('analysis:info-box-text-empty');

  return (
    <>
      <StudyTabsTable selectedKey={selectedKey} />
      <InformationBoxHorizontal
        image={<StudyDashboardEmptyTabsImage />}
        text={displayText}
        rightButtons={rightButton}
      />
    </>
  );
};
