import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Chip, Grid, Typography, makeStyles } from '@material-ui/core';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import { GapAnalysisComparison, NewGapAnalysisResponse, NewGapAnalysisResponseData } from '../../../../models';
import { selectNewGapAnalysis } from '../../../../selectors';
import httpService from '../../../../services/http';
import designTokens from '../../../../styles/designTokens';
import { mediumFont } from '../../../../styles/fonts';
import { body1, body2, captionOverline, titlelarge } from '../../../../styles/typography';
import { ArrowDirection, DownloadIcon } from '../../../../svgs';
import ButtonWithLoadingSpinner from '../../../ButtonWithLoadingSpinner';
import { Table } from '../../../Table';
import { StickyColsDefinition } from '../../../Table/Table.proptype';
import { GapAnalysisTabNewUIProps, excelReturnBody } from '../../TuneCompSearch.proptype';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflow: 'auto'
  },
  headerColumnCompSearch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0.75rem',
    '& h1': {
      ...body2,
      color: designTokens.core1,
      marginBottom: theme.spacing(0.4)
    },
    '& h2': {
      ...body1,
      color: designTokens.core2,
      marginTop: theme.spacing(0.1)
    }
  },
  boxLoading: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 999,
    inset: 0
  },
  primaryChip: {
    border: '0.063rem solid',
    borderColor: designTokens.purpleLight2,
    padding: '0.406rem',
    height: 'auto',
    backgroundColor: designTokens.product15,
    fontFamily: mediumFont.fontFamily,
    color: designTokens.product100,
    '& span': {
      display: 'flex'
    },
    '& span span:first-child': {
      marginRight: '2.25rem',
      display: 'flex',
      alignItems: 'center',
      ...captionOverline
    },
    '& span span:last-child': {
      ...titlelarge
    }
  },
  secondaryChip: {
    border: '0.063rem solid',
    borderColor: designTokens.neutral70,
    padding: '0.406rem',
    height: 'auto',
    backgroundColor: designTokens.neutral70,
    fontFamily: mediumFont.fontFamily,
    color: designTokens.core1,
    '& span': {
      display: 'flex'
    },
    '& span span:first-child': {
      marginRight: '2.25rem',
      display: 'flex',
      alignItems: 'center',
      ...captionOverline
    },
    '& span span:last-child': {
      ...titlelarge
    }
  },
  comparisonHeader: {
    ...captionOverline,
    color: designTokens.core2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  comparisonText: {
    marginBottom: theme.spacing(0.4),
    textTransform: 'uppercase'
  },
  optionCompany: {
    ...body2,
    color: designTokens.core1,
    padding: '0 0.75rem'
  },
  optionRejected: {
    ...body2,
    color: designTokens.core2,
    backgroundColor: designTokens.neutral60,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.75rem'
  },
  comparisonBox: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      border: '0.063rem solid',
      borderRadius: 4,
      fontFamily: mediumFont.fontFamily,
      width: 125,
      textAlign: 'center',
      padding: 5,
      '&.New': {
        color: designTokens.purpleLight2,
        borderColor: designTokens.purpleLight2,
        backgroundColor: designTokens.white
      },
      '&.Rejected': {
        color: designTokens.darkMustard,
        borderColor: designTokens.mustard,
        backgroundColor: designTokens.mustard
      },
      '&.Same': {
        color: designTokens.product100,
        borderColor: designTokens.product15,
        backgroundColor: designTokens.product15
      }
    }
  },
  downloadButton: {
    backgroundColor: 'transparent',
    marginRight: '1rem'
  },
  downloadButtonContainer: {
    maxWidth: '5rem',
    minWidth: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  downloadIcon: {
    width: '0.9rem',
    marginRight: theme.spacing(0.5)
  },
  loading: {
    color: designTokens.neutral100
  }
}));

export const GapAnalysis = ({
  compSearchId,
  jurisdictionId,
  pliTypeId,
  pliAverageTypeId,
  pbaId
}: GapAnalysisTabNewUIProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(false);
  const [featureFlagIsActive] = useFeatureFlags();

  const stickyCols: StickyColsDefinition = {};

  const gapAnalysisComp: NewGapAnalysisResponse | undefined = useSelector(selectNewGapAnalysis);

  const exportExcel = useCallback(() => {
    setIsDownloadButtonDisabled(true);

    httpService
      .request<{ data: excelReturnBody }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v2/compsearch/${String(compSearchId)}/jurisdiction/${String(jurisdictionId)}/pli/${String(
          pliTypeId
        )}/pli-avg/${String(pliAverageTypeId)}/pba/${String(pbaId)}/generate-excel-export`
      })
      .then((result) => {
        const { data } = result.data;
        const downloadS3Url = data.body.url;
        window.open(downloadS3Url);
      })
      .finally(() => {
        setIsDownloadButtonDisabled(false);
      });
  }, [compSearchId, jurisdictionId, pliTypeId, pliAverageTypeId, pbaId]);

  if (!gapAnalysisComp) {
    return (
      <Box className={classes.boxLoading}>
        <ButtonWithLoadingSpinner loading />
      </Box>
    );
  }

  const renderHeaderCompSearch = (type: string) => {
    const isGapAnalysisExcelDownloadEnabled = featureFlagIsActive('gapAnalysisExcelDownload');
    const yearKey = type === 'prior' ? gapAnalysisComp.previousTaxYear : gapAnalysisComp.currentTaxYear;
    const yearRender = type === 'prior' ? <h1>{yearKey} (Prior Year)</h1> : <h1>{yearKey} (Current Year)</h1>;
    const chipClass = type === 'prior' ? classes.secondaryChip : classes.primaryChip;

    return (
      <Box className={classes.headerColumnCompSearch}>
        <Box>
          {yearRender}
          <h2>Comparable Companies</h2>
        </Box>
        {isGapAnalysisExcelDownloadEnabled && type !== 'prior' && (
          <ButtonWithLoadingSpinner
            className={classes.downloadButton}
            variant="contained"
            color="secondary"
            disabled={isDownloadButtonDisabled}
            loading={isDownloadButtonDisabled}
            loadingClassName={classes.loading}
            loadingSize="1.25rem"
            container={<Box className={classes.downloadButtonContainer} />}
            onClick={exportExcel}
          >
            <DownloadIcon className={classes.downloadIcon} />
            <Typography>{t('reports:action-download')}</Typography>
          </ButtonWithLoadingSpinner>
        )}
        <Box>
          <Chip
            color="primary"
            size="medium"
            label={
              <>
                <span>Final Accepted</span>
                <span>{gapAnalysisComp.totalAcceptedCompsPerYear[yearKey]}</span>
              </>
            }
            className={chipClass}
          />
        </Box>
      </Box>
    );
  };

  const renderComparisonHeader = () => {
    return (
      <Box className={classes.comparisonHeader}>
        <span className={classes.comparisonText}>{t('analysis:comparison')}</span>
        <ArrowDirection />
      </Box>
    );
  };

  const renderDataCompany = (data: NewGapAnalysisResponseData) => {
    const response: any = {};

    response.comparison = (
      <Box className={classes.comparisonBox}>
        <span className={data.comparison}>{data.comparison}</span>
      </Box>
    );

    if (data.comparison === GapAnalysisComparison.New) {
      response.previousYearCompPool = (
        <Box className={classes.optionRejected}>
          {data.rejectionName
            ? data.rejectionName.includes('rejection_type')
              ? t(`analysis:${String(data.rejectionName)}`)
              : String(data.rejectionName)
            : t('analysis:company_not_available_in_search_results')}
        </Box>
      );

      response.currentYearCompPool = <Box className={classes.optionCompany}>{data.taxPayerName}</Box>;
    }

    if (data.comparison === GapAnalysisComparison.Same) {
      response.previousYearCompPool = <Box className={classes.optionCompany}>{data.taxPayerName}</Box>;
      response.currentYearCompPool = <Box className={classes.optionCompany}>{data.taxPayerName}</Box>;
    }

    if (data.comparison === GapAnalysisComparison.Deleted) {
      response.previousYearCompPool = <Box className={classes.optionCompany}>{data.taxPayerName}</Box>;
      response.currentYearCompPool = (
        <Box className={classes.optionRejected}>{t('analysis:company_not_available_in_search_results')}</Box>
      );
    }

    if (data.comparison === GapAnalysisComparison.Rejected) {
      response.previousYearCompPool = <Box className={classes.optionCompany}>{data.taxPayerName}</Box>;
      response.currentYearCompPool = (
        <Box className={classes.optionRejected}>
          {data.rejectionName
            ? data.rejectionName.includes('rejection_type')
              ? t(`analysis:${String(data.rejectionName)}`)
              : String(data.rejectionName)
            : t('analysis:company_not_available_in_search_results')}
        </Box>
      );
    }

    return response;
  };

  const renderCompPool = gapAnalysisComp.comps.map((data: NewGapAnalysisResponseData) => {
    const response = renderDataCompany(data);

    return {
      currentYearCompPool: response.currentYearCompPool,
      previousYearCompPool: response.previousYearCompPool,
      comparison: response.comparison
    };
  });

  return (
    <Grid item xs={12} className={classes.tableContainer}>
      <Table
        data={renderCompPool}
        stickyCols={stickyCols}
        columnSpecificStyles={{
          previousYearCompPool: {
            padding: 0
          },
          comparison: {
            borderLeft: `1px solid ${designTokens.neutral90}`,
            borderRight: `1px solid ${designTokens.neutral90}`
          },
          currentYearCompPool: {
            padding: 0
          }
        }}
        columns={[
          { key: 'previousYearCompPool', header: renderHeaderCompSearch('prior'), width: 400 },
          { key: 'comparison', header: renderComparisonHeader(), width: 50 },
          { key: 'currentYearCompPool', header: renderHeaderCompSearch('current'), width: 400 }
        ]}
      />
    </Grid>
  );
};
