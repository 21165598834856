import { createSelector } from 'reselect';
import { RootState } from '../redux';

export const selectGlobalGuides = createSelector(
  ({ globalGuides }: RootState) => globalGuides,
  ({ allIds, byId }) => (byId ? allIds.map((id) => byId[id]) : null)
);

export const selectGlobalGuideReport = (state: RootState) => state.globalGuides?.report?.reports[0];

export const selectGlobalGuideReportStatus = (state: RootState) =>
  state.globalGuides?.report?.reports[0]?.reportStatus ?? null;

export const selectGlobalGuideHasReportStatus = (state: RootState) => state.globalGuides?.report?.hasReports ?? null;
