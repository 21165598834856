import { ReactNode, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { ConfirmationModal } from '../../components';
import { IDLE_SESSION_TIMEOUT, IDLE_SESSION_PROMPT_TIMEOUT } from '../../constants';
import { logUserLogout, redirectToLogin } from '../../utils';

export const IdleTimeout = (props: { children: ReactNode }) => {
  const timeout = IDLE_SESSION_TIMEOUT;
  const promptTimeout = IDLE_SESSION_PROMPT_TIMEOUT;
  let interval: NodeJS.Timeout;

  const crossTab = true;
  const [remainingTime, setRemainingTime] = useState<number>(promptTimeout / 1000);
  const [countDownSeconds, setCountDownSeconds] = useState<number>(60);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const onIdle = () => {
    clearInterval(interval);
    setShowWarningModal(false);
    void logUserLogout('idle_timeout');
    void redirectToLogin();
  };

  const onPrompt = () => {
    clearInterval(interval);
    let updateTime = promptTimeout / 1000;
    let seconds = countDownSeconds;
    setShowWarningModal(true);
    interval = setInterval(() => {
      if (seconds === 0) {
        seconds = 60;
      }

      updateTime--;
      if (updateTime >= 0) {
        setRemainingTime(updateTime);
        setCountDownSeconds(seconds--);
      }
    }, 1000);
  };

  const onResetTimeout = () => {
    idleTimer.reset();
    clearInterval(interval);
    setShowWarningModal(false);
    setRemainingTime(promptTimeout / 1000);
  };

  const onSignOut = () => {
    clearInterval(interval);
    setShowWarningModal(false);
    void logUserLogout('intended_logout');
    void redirectToLogin();
  };

  const onMessage = (message: string) => {
    if (message === 'reset') {
      onResetTimeout();
    } else {
      onSignOut();
    }
  };

  const idleTimer = useIdleTimer({
    timeout,
    promptTimeout,
    crossTab,
    onPrompt,
    onIdle,
    onMessage
  });

  return (
    <>
      {showWarningModal && (
        <ConfirmationModal
          open
          confirmButtonText="action-signout"
          cancelButtonText="action-resume"
          title="Inactive Session"
          subtitle="You have been inactive for at least 50 minutes and will be logged out soon for security reasons."
          text={`You will be logged out in and ${Math.ceil(remainingTime / 60 - 1)}
            minutes and ${countDownSeconds} seconds.`}
          HeaderIcon={null}
          handleClose={(confirmation: boolean) => {
            if (confirmation) {
              idleTimer.message('signout');
              onSignOut();
            } else {
              idleTimer.message('reset');
              onResetTimeout();
            }
          }}
        />
      )}
      {props.children}
    </>
  );
};
