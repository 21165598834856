import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { TbaUncontrolledTransactionSplashScreen } from './TbaUncontrolledTransactionsSplashScreen';
import { TbaUncontrolledTransSearchTable } from './TbaUncontrolledTransSearchTable';
import { TbaUncontrolledTransShowAll } from './TbaUncontrolledTransShowAll';
import { CenteredProgress } from '../../../components';
import { title2 } from '../../../styles/typography';
import { isRangeValueTypeUnitPrice } from '../../../utils';
import NavigationTabs from '../../NavigationTabs/component';
import { TbaUncontrolledTransactionsProps } from '../TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    height: '100%',
    backgroundColor: 'inherit',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  paperContainer: {
    padding: '1.375em',
    backgroundColor: theme.palette.common.white
  },
  title2
}));

export const TbaUncontrolledTransactions = ({
  tba,
  tbaParameters,
  tbaRangeTypes,
  onCreateSingleTransaction,
  onCopyTba,
  onUploadMultipleTransactions,
  setIsOpenImportFromRoyaltyStatModal,
  allUncontrolledTransactions,
  onEditSingleTransaction,
  currentSearchUncontrolledTransactions,
  savingNewTransaction,
  isUcTransactionStatusUpdating,
  setIsUcTransactionStatusUpdating
}: TbaUncontrolledTransactionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [stayOnSplashScreen, setStayOnSplashScreen] = useState(true);
  const isUnitPrice = isRangeValueTypeUnitPrice(tba);

  const handleContinueSplashScreen = () => {
    setStayOnSplashScreen(false);
  };

  if (!tba?.uncontrolledTransactions) {
    return <CenteredProgress />;
  }

  if ((!tba?.uncontrolledTransactions || tba?.uncontrolledTransactions?.length === 0) && stayOnSplashScreen) {
    return <TbaUncontrolledTransactionSplashScreen onContinue={handleContinueSplashScreen} />;
  }

  const tabs = [
    {
      key: 'current-search',
      label: t('analysis:current-search'),
      disabled: false,
      content: (
        <TbaUncontrolledTransSearchTable
          tba={tba}
          tbaRangeTypes={tbaRangeTypes}
          tabLabel={t('analysis:current-search')}
          isUnitPrice={isUnitPrice}
          currentSearchUncontrolledTransactions={currentSearchUncontrolledTransactions}
          savingNewTransaction={savingNewTransaction}
          isUcTransactionStatusUpdating={isUcTransactionStatusUpdating}
          setIsUcTransactionStatusUpdating={setIsUcTransactionStatusUpdating}
          setIsOpenImportFromRoyaltyStatModal={setIsOpenImportFromRoyaltyStatModal}
          onCreateSingleTransaction={() => {
            onCreateSingleTransaction('current-search');
          }}
          onUploadMultipleTransactions={onUploadMultipleTransactions}
          onEditSingleTransaction={onEditSingleTransaction}
          onCopyTba={onCopyTba}
        />
      ),
      createButtonText: '+ Create Transaction'
    },
    {
      key: 'all-uncontrolled',
      label: t('analysis:all-uncontrolled'),
      disabled: false,
      content: (
        <TbaUncontrolledTransShowAll
          tba={tba}
          tbaParameters={tbaParameters}
          tabLabel={t('analysis:all-uncontrolled')}
          isUnitPrice={isUnitPrice}
          allUncontrolledTransactions={allUncontrolledTransactions}
          savingNewTransaction={savingNewTransaction}
          isUcTransactionStatusUpdating={isUcTransactionStatusUpdating}
          setIsUcTransactionStatusUpdating={setIsUcTransactionStatusUpdating}
          setIsOpenImportFromRoyaltyStatModal={setIsOpenImportFromRoyaltyStatModal}
          onCreateSingleTransaction={() => {
            onCreateSingleTransaction('all-uncontrolled');
          }}
          onUploadMultipleTransactions={onUploadMultipleTransactions}
          onEditSingleTransaction={onEditSingleTransaction}
          onCopyTba={onCopyTba}
        />
      ),
      createButtonText: t('analysis:all-uncontrolled')
    }
  ];

  return (
    <Box className={classes.boxContainer}>
      <NavigationTabs tabs={tabs} />
    </Box>
  );
};
