import { TFunction } from 'react-i18next';
import { AxiosError } from 'axios';
import { ImportDefinition, ImportMapping } from '../models';
import httpService from '../services/http';

export type ImportKind = 'transactions' | 'entities';

interface UploadResponse {
  hash: string;
  headers: string[];
  allowed: Array<{ field: string; label: string; defaultHeader: string }>;
}

export const uploadFile = async (kind: ImportKind, file: File): Promise<ImportDefinition> => {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);

  const {
    data: {
      data: { hash, allowed: allowedMappings, headers }
    }
  } = await httpService.request<{ data: UploadResponse }>({
    method: 'post',
    apiUrlKey: 'baseUrl',
    relativePath: `${kind}/upload`,
    data: formData
  });

  return {
    hash,
    allowedMappings,
    mappings: headers.map((header) => ({
      header,
      field: allowedMappings.find((mapping) => mapping.defaultHeader === header)?.field ?? ''
    }))
  };
};

export const triggerImport = async (kind: ImportKind, hash: string, mappings: ImportMapping[], t: TFunction) => {
  httpService.setErrorMessageResolver((error: AxiosError) => {
    // Checking for the error when user uplods with with wrong configuration
    // This should be removed as soon as the API returns the correct error message
    if (error.response?.status === 422) {
      return t('errors:import-mappings-http-status-422');
    }

    if (error.response?.status === 500) {
      return t('errors:default-message');
    }

    const errors = JSON.parse(error.response?.data.errors.global ?? []);
    return errors.length > 0
      ? errors.map(
          (m: { message: string; name: string; subject: Record<string, string> }) =>
            (m.subject[m.name] ? `${m.subject[m.name]} - ` : '') + t(`errors:${m.message}`)
        )
      : t('errors:default-message');
  });

  await httpService.request({
    method: 'post',
    apiUrlKey: 'baseUrl',
    relativePath: `${kind}/mappings`,
    data: {
      hash,
      mappings: mappings.map(({ header, field }) => ({ header, field }))
    }
  });
};
