import { RootState } from '../redux';

export const selectAllMajorClassifications = (state: RootState) => state.testedParty.majorClassifications;

export const selectCurrentMajorClassification = (state: RootState) => state.testedParty.currentMajorClassification;

export const selectCurrentValueAddedDistributor = (state: RootState) => state.testedParty.currentDistributor;

export const selectSicTypes = (state: RootState) => state.testedParty.sicTypes;

export const testedPartyLoadingState = (state: RootState) => state.testedParty.loading;
