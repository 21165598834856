import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Grid,
  Box,
  Typography,
  Divider,
  makeStyles,
  Theme,
  TextField,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import { ProfitBasedAnalysisAddModalTable } from '.';
import { ProfitBasedAnalysisAddModalProps, PBAInputs, SelectedTransaction } from './CreatePBAModal.proptypes';
import { Editor, SearchBox } from '../../components';
import { Entity, SegmentTypeEnum, Transaction } from '../../models';
import { selectWorkingContainer } from '../../selectors';
import tokens from '../../styles/designTokens';
import { body2 } from '../../styles/typography';
import { sortAlphabetically } from '../../utils';
import { filterTransactionsByTestedPartyAndPrimaryEntity, getTradingPairFromTransaction } from '../../utils/analysis';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { TPModal } from '../TPModal';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  },
  inputGridElement: {
    marginBottom: theme.spacing(2)
  },
  otherPrimaryFunction: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0)
  },
  columnFields: {
    margin: theme.spacing(3, 0, 2)
  },
  editorLabel: {
    color: tokens.product100,
    margin: theme.spacing(1, 0, 0.5, 0),
    ...body2
  },
  label: {
    color: tokens.product100,
    marginBottom: '6px',
    ...body2
  },
  tableLabel: {
    color: tokens.product100,
    padding: theme.spacing(1, 0, 0),
    ...body2
  },
  textField: {
    width: '100%',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  inputField: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },
  inputTitle: {
    color: theme.palette.primary.dark,
    display: 'inline',
    marginLeft: theme.spacing(1),
    bottom: theme.spacing(0.5),
    position: 'relative'
  },
  dropdownTitle: {
    marginBottom: theme.spacing(0.5)
  },
  placeHolder: {
    color: theme.palette.text.secondary
  },
  formControl: {
    width: '100%'
  },
  inputTitlePicker: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(0.5)
  },
  subtitle: {
    color: tokens.product100,
    padding: theme.spacing(0, 0, 0.5),
    ...body2
  },
  table: {
    margin: theme.spacing(1, 0, 2)
  },
  tableError: {
    borderRadius: theme.spacing(0.5),
    border: 'solid 1px  red',
    padding: theme.spacing(0.5)
  }
}));

const segmentType: any[] = [];
for (const type in SegmentTypeEnum) {
  if (SegmentTypeEnum) {
    const isNumber = Number.parseInt(type, 10);
    if (!isNumber) {
      segmentType.push({ title: type, value: SegmentTypeEnum[type] });
    }
  }
}

segmentType.sort((seg1, seg2) => {
  return seg1.title > seg2.title ? 1 : -1;
});

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

const FormDivider = () => (
  <Grid item xs={12}>
    <Divider variant="fullWidth" />
  </Grid>
);

const selectableEntities = (transactions: Transaction[], entity: Entity) => {
  const transactionsLegalEntities = transactions
    ?.map((transaction) => {
      const relatedLegalEntityTransactions = transaction.legalEntityTransactions?.find(
        (legalEntityTransaction) => legalEntityTransaction.entity.code === entity.code
      );
      return relatedLegalEntityTransactions ? transaction.legalEntityTransactions : [];
    })
    .flat();

  const allRelatedEntitiesList = transactionsLegalEntities
    ?.filter((transaction) => transaction.entity.code !== entity.code)
    .map((transaction) => transaction.entity);

  const uniqueEntities: any = {};

  for (const entity of allRelatedEntitiesList) {
    if (!uniqueEntities[entity.code]) {
      uniqueEntities[entity.code] = entity;
    }
  }

  const entitiesList: Entity[] = [entity];
  for (const entity in uniqueEntities) {
    if (uniqueEntities) {
      entitiesList.push(uniqueEntities[entity]);
    }
  }

  return entitiesList;
};

const selectedTransactionValidation = (selectedTransactions: SelectedTransaction[]) => {
  const hasTransactions = selectedTransactions.length > 0;
  return hasTransactions;
};

const testedPartyPrimaryFunctionsDropDown = new Set([
  PrimaryFunctions.ByName.Distributor.Name,
  PrimaryFunctions.ByName.ServiceProvider.Name,
  PrimaryFunctions.ByName.Manufacturer.Name,
  PrimaryFunctions.ByName.DistributorOther.Name,
  PrimaryFunctions.ByName.NonValueAddedDistributor.Name,
  PrimaryFunctions.ByName.Retail.Name,
  PrimaryFunctions.ByName.ServiceProviderOther.Name,
  PrimaryFunctions.ByName.ValueAddedDistributor.Name
]);

export const ProfitBasedAnalysisAddModal = ({
  onClose,
  onSubmit,
  primaryFunctionOptions,
  entities,
  study,
  transactions,
  upeCurrency
}: ProfitBasedAnalysisAddModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);
  const primaryFunctionDropDown = primaryFunctionOptions.filter((primaryFunctionOption) =>
    testedPartyPrimaryFunctionsDropDown.has(primaryFunctionOption.title)
  );
  const menuItems = sortAlphabetically(
    primaryFunctionDropDown ? [...primaryFunctionDropDown] : [],
    ({ title }) => title
  );
  const [selectedTestedParty, setSelectedTestedParty] = useState<Entity | null>(null);
  const [selectedTransactions, setSelectedTransactions] = useState<SelectedTransaction[]>([]);
  const [notSelectedTransactionsError, setNotSelectedTransactionsError] = useState(false);
  const [relatedTestedPartyTransactions, setRelatedTestedPartyTransactions] = useState<Transaction[] | null>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<Transaction[] | null>([]);
  const primaryEntityRelatedEntities = selectableEntities(transactions!, study?.primaryEntity!);
  const [hasOtherPrimaryFunction, setHasOtherPrimaryFunction] = useState(false);

  const otherPrimaryFunctions = new Set([
    PrimaryFunctions.ByName.DistributorOther.Id,
    PrimaryFunctions.ByName.ServiceProviderOther.Id
  ]);

  useEffect(() => {
    if (selectedTestedParty && study && transactions) {
      const filteredTestedPartyTransactions = filterTransactionsByTestedPartyAndPrimaryEntity({
        testedParty: selectedTestedParty,
        primaryEntity: study.primaryEntity,
        transactions
      });

      setRelatedTestedPartyTransactions(filteredTestedPartyTransactions);
      setFilteredTransactions(filteredTestedPartyTransactions);
      setSelectedTransactions([]);
    }
  }, [selectedTestedParty, transactions, study]);

  const defaultValues = {
    name: '',
    method: 'TNMM/CPM',
    testedParty: null,
    testedPartyName: '',
    segmentType: null,
    primaryFunction: '',
    testedPartyJustification: '',
    tradingPartner: null,
    transactions: []
  };

  const onSelectPrimaryFunction = (event: any) => {
    const selectedPrimaryFunctionId = event.target.value;
    setHasOtherPrimaryFunction(otherPrimaryFunctions.has(selectedPrimaryFunctionId));
  };

  const formMethods = useForm<PBAInputs>({
    defaultValues
  });

  const { handleSubmit, control, errors } = formMethods;

  const filterListBySelectedTransactions = (selectedTransactions: SelectedTransaction[]) => {
    if (study && transactions && relatedTestedPartyTransactions) {
      const selectedTransactionIds = new Set(selectedTransactions.map((tr) => tr.transactionId));
      const firstSelectedTransaction = relatedTestedPartyTransactions.find((tr) =>
        selectedTransactionIds.has(tr.transactionId)
      );

      if (firstSelectedTransaction) {
        const tradingPair = getTradingPairFromTransaction(firstSelectedTransaction, study.primaryEntity.entityId);

        if (tradingPair.testedParty && tradingPair.primaryEntity) {
          const transactionsFilteredByTradingPair = filterTransactionsByTestedPartyAndPrimaryEntity({
            primaryEntity: tradingPair.primaryEntity,
            testedParty: tradingPair.testedParty,
            transactions
          });
          setFilteredTransactions(transactionsFilteredByTradingPair);
        }
      } else if (selectedTestedParty) {
        const filteredTestedPartyTransactions = filterTransactionsByTestedPartyAndPrimaryEntity({
          testedParty: selectedTestedParty,
          primaryEntity: study.primaryEntity,
          transactions
        });
        setFilteredTransactions(filteredTestedPartyTransactions);
      }
    }
  };

  return (
    <TPModal
      isOpen
      maxWidth="lg"
      title={
        <span className={classes.modalTitleWrapper}>
          <AddIcon className={classes.headerIcon} />
          <Typography variant="h6">{`${t('analysis:action-new-pba')} for ${study!.primaryEntity.code}`}</Typography>
        </span>
      }
      actions={[
        {
          name: t('action-cancel'),
          handler: () => {
            onClose();
          }
        },
        {
          name: t('action-done'),
          className: classes.doneButton,
          handler: handleSubmit((data) => {
            data.transactions = selectedTransactions;
            if (selectedTransactions.length > 0) {
              try {
                onSubmit(data);
                /* eslint-disable camelcase */
                void logGoogleAnalyticsEvent({
                  event_category: 'create_pba_done_button',
                  event_label: 'Create PBA modal done button',
                  container_id: workingContainer?.containerId
                });
                onClose();
              } catch (error: unknown) {
                console.log(error);
              }
            } else {
              setNotSelectedTransactionsError(true);
            }
          })
        }
      ]}
      onClose={onClose}
    >
      <Grid container justify="center">
        <form
          onSubmit={() => {
            console.log('isSubmited');
          }}
        >
          <Grid container item xs={12} justify="center">
            <Grid container item xs={11} spacing={1} className={classes.columnFields} justify="space-around">
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box>
                  <Typography className={classes.label}>{t('analysis:column-pba-name')}</Typography>
                  <Controller
                    name="name"
                    render={({ value, onChange }) => (
                      <TextField
                        id="name"
                        name="name"
                        placeholder={t('analysis:placerholder-pba-name')}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        value={value}
                        inputProps={{ 'data-testid': 'pbaName' }}
                        error={Boolean(errors.name)}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    defaultValue={defaultValues.name}
                    rules={{ required: true }}
                    error={Boolean(errors.name)}
                  />
                </Box>
                <ErrorMessage
                  error={Boolean(errors.name)}
                  message={t('analysis:invalid-entry-required-element-name-pba')}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Typography className={classes.label}>{t('analysis:column-method')}</Typography>
                <Controller
                  name="method"
                  render={() => (
                    <TextField
                      disabled
                      id="method"
                      name="method"
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={t('analysis:pba-tnmm')}
                    />
                  )}
                  control={control}
                  rules={{ required: false }}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box>
                  <Typography className={classes.label}>{t('analysis:column-select-tested-party')}</Typography>
                  <Controller
                    name="testedParty"
                    render={({ value, onChange }) => (
                      <SearchBox
                        error={Boolean(errors.testedParty)}
                        placeholder={t('analysis:title-add-tested-party')}
                        value={value}
                        options={primaryEntityRelatedEntities}
                        getOptionSelected={(option, value) => option.entityId === value.entityId}
                        getOptionLabel={(parent) => parent.code}
                        onChange={(_, item) => {
                          onChange(item);
                          setSelectedTestedParty(item);
                        }}
                      />
                    )}
                    control={control}
                    defaultValue={defaultValues.testedParty}
                    rules={{
                      required: true,
                      validate: () => {
                        const hasTransactions = selectedTransactionValidation(selectedTransactions);
                        setNotSelectedTransactionsError(!hasTransactions);
                        return true;
                      }
                    }}
                    error={Boolean(errors.testedParty)}
                  />
                </Box>
                <ErrorMessage
                  error={Boolean(errors.testedParty)}
                  message={t('analysis:invalid-entry-required-select-tested-party')}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box>
                  <Typography variant="h3" className={classes.inputTitlePicker}>
                    {t('analysis:column-tested-party-name')}
                  </Typography>
                  <Controller
                    name="testedPartyName"
                    render={({ value, onChange }) => (
                      <TextField
                        id="testedPartyName"
                        name="testedPartyName"
                        placeholder={t('analysis:column-tested-party-name')}
                        className={classes.textField}
                        error={Boolean(errors.testedPartyName)}
                        margin="dense"
                        variant="outlined"
                        value={value}
                        inputProps={{ 'data-testid': 'testedPartyName' }}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    defaultValue={defaultValues.testedPartyName}
                    rules={{ required: true }}
                    error={Boolean(errors.testedPartyName)}
                  />
                </Box>
                <ErrorMessage
                  error={Boolean(errors.testedPartyName)}
                  message={t('analysis:invalid-entry-required-tested-party-name')}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box>
                  <Typography className={classes.label}>{t('analysis:column-segment-type')}</Typography>
                  <Controller
                    name="segmentType"
                    render={({ value, onChange }) => (
                      <SearchBox
                        error={Boolean(errors.segmentType)}
                        placeholder={t('analysis:title-add-segment-type')}
                        value={value}
                        options={segmentType}
                        getOptionSelected={(option, value) => option.value === value.value}
                        getOptionLabel={(parent) => parent.title}
                        onChange={(_, item) => {
                          onChange(item);
                        }}
                      />
                    )}
                    control={control}
                    defaultValue={defaultValues.segmentType}
                    rules={{ required: true }}
                    error={Boolean(errors.segmentType)}
                  />
                </Box>
                <ErrorMessage
                  error={Boolean(errors.segmentType)}
                  message={t('analysis:invalid-entry-required-segment-type')}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box width="100%">
                  <Typography variant="h3" className={classes.dropdownTitle}>
                    {t('analysis:column-primary-function')}
                  </Typography>
                  <FormControl size="small" variant="outlined" className={classes.formControl}>
                    <Controller
                      render={({ value, onChange }) => (
                        <Select
                          displayEmpty
                          id="field-newui-create-pba-primary-function-input"
                          value={value}
                          onChange={($event) => {
                            onChange($event);
                            onSelectPrimaryFunction($event);
                          }}
                        >
                          <MenuItem disabled value="">
                            <div className={classes.placeHolder}>{t('analysis:placeholder-primary-function')}</div>
                          </MenuItem>
                          {menuItems.map(({ value, title }) => (
                            <MenuItem key={value} value={value}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name="primaryFunction"
                      control={control}
                      defaultValue={defaultValues.primaryFunction}
                      rules={{ required: true }}
                      error={Boolean(errors.primaryFunction)}
                    />
                  </FormControl>
                </Box>
                <ErrorMessage
                  error={Boolean(errors.primaryFunction)}
                  message={t('analysis:invalid-entry-primary-function')}
                />
                {hasOtherPrimaryFunction ? (
                  <>
                    <Controller
                      name="otherPrimaryFunction"
                      render={({ value, onChange }) => (
                        <TextField
                          name="otherPrimaryFunction"
                          id="otherPrimaryFunction"
                          className={classes.otherPrimaryFunction}
                          margin="dense"
                          variant="outlined"
                          value={value ? value : ''}
                          inputProps={{ maxLength: 75 }}
                          onChange={onChange}
                        />
                      )}
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                    />
                    <ErrorMessage
                      error={Boolean(errors.otherPrimaryFunction)}
                      message={t('analysis:invalid-other-primary-function')}
                    />
                  </>
                ) : null}
              </Grid>
              <FormDivider />
              <Grid item xs={12} className={classes.inputGridElement}>
                <Box width="100%">
                  <Typography className={classes.editorLabel}>{t('analysis:label-justification')}</Typography>
                  <Controller
                    name="testedPartyJustification"
                    render={({ onChange, value }) => (
                      <Editor
                        theme="TransferPricing"
                        id="testedPartyJustification"
                        init={{ height: '250' }}
                        value={value || defaultValues.testedPartyJustification}
                        error={Boolean(errors.testedPartyJustification)}
                        onEditorChange={(content) => {
                          onChange(content);
                        }}
                      />
                    )}
                    control={control}
                    rules={{ required: false }}
                    defaultValue={defaultValues.testedPartyJustification}
                    error={Boolean(errors.testedPartyJustification)}
                  />
                </Box>
              </Grid>
              <FormDivider />
              <Grid container item xs={12} justify="flex-start">
                <Typography className={classes.tableLabel}>
                  {t('analysis:title-select-applicable-transactions')}
                </Typography>
              </Grid>
              {selectedTestedParty ? (
                <Grid item xs={12}>
                  <Grid container item xs={12} justify="flex-start">
                    <Typography className={classes.subtitle}>{selectedTestedParty.code}</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.table}>
                    <Box className={notSelectedTransactionsError ? classes.tableError : ''}>
                      <ProfitBasedAnalysisAddModalTable
                        entities={entities}
                        upeCurrency={upeCurrency}
                        selectedTransactions={selectedTransactions}
                        transactions={filteredTransactions}
                        onChange={(transactions: SelectedTransaction[]) => {
                          setSelectedTransactions(transactions);
                          setNotSelectedTransactionsError(transactions.length === 0);
                          filterListBySelectedTransactions(transactions);
                        }}
                      />
                    </Box>
                    <ErrorMessage
                      error={notSelectedTransactionsError}
                      message={t('analysis:invalid-entry-required-transactions')}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container item xs={12} justify="flex-start">
                  <Typography className={classes.subtitle}>{t('analysis:subtitle-select-tested-party')}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </TPModal>
  );
};
