import { ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Dashboard } from '@material-ui/icons';
import { ArrowNavigationTabsBar } from '@xbs/xbs-common-ui';
import { Entity, Transaction } from '../../models';
import { ContentHeader } from '../ContentHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    '& > :nth-child(2)': {
      '& .Mui-selected': {
        color: theme.palette.primary.dark
      },
      '& .MuiTabs-scroller': {
        borderBottom: `1px solid ${theme.palette.divider}`
      },
      '& .MuiTab-root': {
        padding: theme.spacing(0.5, 1, 0.5, 2)
      }
    }
  },
  paperContainer: {
    padding: '1.375em',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export interface DetailedWithTabsProps {
  title: string;
  data?: Entity | Transaction;
  selectedKey: string;
  tabs: Array<{
    key: string;
    label: string;
    disabled: boolean;
    content: ReactNode;
  }>;
  onSelectTab: (key: string) => void;
}

export const DetailedWithTabs = ({ title, data, selectedKey, tabs, onSelectTab }: DetailedWithTabsProps) => {
  const classes = useStyles();
  const selected = tabs.find(({ key }) => key === selectedKey);
  const isEntity = 'entityId' in (data ?? {});
  const isTransaction = 'identifier' in (data ?? {});
  const code = isEntity ? (data as Entity).code : isTransaction ? (data as Transaction).identifier : '';
  const id = isEntity ? (data as Entity).entityId : isTransaction ? (data as Transaction).transactionId : '';

  return (
    <Box className={classes.root}>
      <ContentHeader
        title={title}
        primaryEntity={code}
        link={`/${isEntity ? 'entities' : 'transactions'}/${id}/dashboard`}
        icon={<Dashboard />}
      />
      <ArrowNavigationTabsBar
        tabs={tabs.map((tab) => ({ ...tab, onTabClick: onSelectTab }))}
        selectedTab={selectedKey}
      />
      <Box className={classes.paperContainer}>{selected?.content}</Box>
    </Box>
  );
};
