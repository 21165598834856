import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import { TFunction } from 'i18next';
import { PBADashboardSummaryProps, PBASummaryInputs, PBASummarySubmitData } from './PBADashboardTPDSummary.proptype';
import { CenteredProgress } from '../../components';
import { AssociatedTransactionsPBA, Entity, PrimaryFunction, Transaction } from '../../models';
import {
  deletePBAAssociatedTransaction,
  editTestedParty,
  fetchCurrentTestedParty,
  updatePbaTransactions
} from '../../redux/profitBasedAnalyses';
import { fetchStudies } from '../../redux/studies';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectCurrentPBA,
  selectCurrentTestedParty,
  selectEntitiesList,
  selectPrimaryFunctions,
  selectStudiesList,
  selectTransactionByPbaId,
  selectTransactionsByEntity,
  selectUPECurrency,
  selectWorkingContainer
} from '../../selectors';
import { decodeTokens, getAuthInfo } from '../../utils';
import { logGoogleAnalyticsTimeSpent } from '../../utils/sendGoogleAnalyticaEvent';
import { TimeTrackingEvents } from '../MethodEvaluation/hooks/useTrackingTime';

interface ConnectorProps {
  component: ComponentType<PBADashboardSummaryProps>;
}

const getPrimaryFunctionOptions = (
  primaryFunctions: PrimaryFunction[],
  testedPartyPrimaryFunctionsDropDown: string[],
  t: TFunction
) =>
  primaryFunctions
    .filter((primaryFunction) => testedPartyPrimaryFunctionsDropDown.includes(primaryFunction.name))
    .map((primaryFunction: PrimaryFunction) => ({
      title: t(`entities:primary-function-${primaryFunction.name}`),
      value: primaryFunction.primaryFunctionId
    }));

const Connector = ({ component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const match: any = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');
  const pba = useSelector(selectCurrentPBA);
  const primaryFunctions = useSelector(selectPrimaryFunctions);
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const upeCurrency = useSelector(selectUPECurrency);
  const transactionsPBA: AssociatedTransactionsPBA[] | null = useSelector(selectTransactionByPbaId(pba?.pbaId));
  const entities: Entity[] | null = useSelector(selectEntitiesList);
  const testedPartyTransactions = useSelector(selectTransactionsByEntity(pba?.primaryLegalEntity?.entityId));
  const workingContainer = useSelector(selectWorkingContainer);
  const studies = useSelector(selectStudiesList);
  const { authToken } = getAuthInfo();
  const userData = decodeTokens(authToken);

  const { studyId } = match.params;
  const currentStudy = studies?.find((study) => study.studyId === Number(studyId));
  const studyPrimaryEntity = currentStudy?.primaryEntity;

  useEffect(() => {
    if (studies === null) {
      void dispatch(fetchStudies());
    }
  }, [dispatch, studies]);

  const testedPartyPrimaryFunctionsDropDown: string[] = [
    PrimaryFunctions.ByName.Distributor.Name,
    PrimaryFunctions.ByName.ServiceProvider.Name,
    PrimaryFunctions.ByName.Manufacturer.Name,
    PrimaryFunctions.ByName.DistributorOther.Name,
    PrimaryFunctions.ByName.NonValueAddedDistributor.Name,
    PrimaryFunctions.ByName.Retail.Name,
    PrimaryFunctions.ByName.ServiceProviderOther.Name,
    PrimaryFunctions.ByName.ValueAddedDistributor.Name
  ];

  useEffect(() => {
    dispatch(fetchTransactions());
    if (!pba) {
      dispatch(selectCurrentPBA);
    }

    if (pba) {
      dispatch(fetchCurrentTestedParty(pba.pbaId));
    }
  }, [dispatch, pba]);

  const mapSumittedData = (data: PBASummaryInputs) => {
    const { name, segmentType, justification, primaryFunctionId, otherPrimaryFunction } = data;
    const newData = {
      testedPartyId: pba?.testedParty?.testedPartyId,
      name,
      segmentType,
      justification,
      primaryFunction: { primaryFunctionId },
      otherPrimaryFunction
    };
    return newData;
  };

  const onSubmit = (testedParty: PBASummaryInputs) => {
    if (pba && testedParty && Object.keys(testedParty).length > 0) {
      const pbaId = pba?.pbaId;
      const fixedTestedParty: PBASummarySubmitData = mapSumittedData(testedParty);
      dispatch<any>(editTestedParty({ pbaId, testedParty: fixedTestedParty })).then(() => {
        dispatch(fetchCurrentTestedParty(pba.pbaId));
      });
    }
  };

  const onUpdatePbaTransactions = async (transactions: Transaction[], pbaId: number) => {
    const promises: any = [];
    transactions.forEach((transaction) => {
      const params = { transactionId: transaction.transactionId, pbaId };
      promises.push(dispatch(updatePbaTransactions(params)));
    });
    await Promise.all(promises);
    dispatch(fetchTransactions());
  };

  const onDeleteAssociateTransaction = (transactionId: number) => {
    if (pba) {
      const pbaId = pba?.pbaId;
      dispatch<any>(deletePBAAssociatedTransaction({ pbaId, transactionId })).then(() => {
        dispatch(fetchTransactions());
      });
    }
  };

  const trackGATime = () => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsTimeSpent({
      title: TimeTrackingEvents.CompSearchTestedPartyCharacterization,
      page: '/custom/modal/CompSearchTestedPartyCharacterization',
      user_id: userData?.userId ?? '',
      container_id: workingContainer?.containerId
    });
  };

  return pba ? (
    <Component
      pba={pba}
      currentTestedParty={currentTestedParty}
      transactionsPBA={transactionsPBA}
      entities={entities}
      studyPrimaryEntity={studyPrimaryEntity}
      testedPartyTransactions={testedPartyTransactions}
      primaryFunctionOptions={getPrimaryFunctionOptions(primaryFunctions, testedPartyPrimaryFunctionsDropDown, t)}
      upeCurrency={upeCurrency!}
      trackGATime={trackGATime}
      onSubmit={onSubmit}
      onTransactionsUpdateSubmit={onUpdatePbaTransactions}
      onDeleteAssociateTransaction={onDeleteAssociateTransaction}
    />
  ) : (
    <CenteredProgress />
  );
};

export default Connector;
