import { Container } from '.';
import { Entity } from './entities.interface';
import { PbaLocation } from '../app/NewAnalysisSideMenu/AnalysisSideMenu.proptype';
import { IndustrialCodeValue } from '../app/TestedPartyDistributor';

export enum SegmentTypeEnum {
  'Entire Entity' = 1,
  'Product Line',
  'Product Line Group',
  'Division',
  'Sub-Division',
  'Group',
  'Sub-Group',
  'Other'
}
export interface PbaCompsSearchOptions {
  dataDate?: string | null;
  chosenCountry?: PbaLocation | null;
  compPoolCount?: number | null;
  compPoolCountIndex?: number | null;
  majorClassifications?: any;
  maxSicCode?: IndustrialCodeValue | null;
  minSicCode?: IndustrialCodeValue | null;
}
export interface PbaCompSearchOptionsByJurisdiction {
  [key: number]: PbaCompsSearchOptions;
}

export interface ManualComparablesCompany {
  id: number;
  searchScore?: number;
  name: string;
  tagged: boolean;
  sourceType?: number;
  sourceId: number;
  jurisdictionIds?: number[];
  domicileName: string;
  domicileId: number;
  description: string;
  sicCode: number;
  website?: string;
  dataProvider?: string;
  available?: boolean;
  companyAdded?: boolean;
}

export interface AddManualComparableCompanyPayload {
  id: number;
  sourceId: number;
  sourceType: number;
  containerId: number;
  pbaId: number;
  jurisdictionIds: number[];
  name: string;
  domicileId: number;
  domicileName: string;
  sicCode: number;
  tagged: boolean;
  description: string;
}
export interface ProfitBasedAnalysis {
  pbaId: number;
  name: string;
  exclude: boolean;
  isSourceComplete: boolean;
  isDestinationComplete: boolean;
  description: string | null;
  method: number;
  testedParty?: TestedParty;
  primaryLegalEntity: Entity;
  jurisdictionStatuses?: PbaJurisdictionStatus[];
  compSearchJurisdictionsOptions?: {
    [key: number]: PbaCompsSearchOptions;
  };
  manualComparablesCompanies?: ManualComparablesCompany[];
}
export interface newProfitBasedAnalysis {
  name: string;
  description: string;
  study: { studyId?: number };
  primaryLegalEntity: { entityId?: number };
  method: number;
}
export interface TabsHeaderProfitBasedAnalysis {
  tabId: number;
  name: string;
  disabled: boolean;
}

export interface TestedParty {
  testedPartyId: number;
  name: string;
  segmentType: SegmentTypeEnum;
  justification: string;
  primaryFunctionId?: number;
  otherPrimaryFunction?: string;
}

export interface PbaJurisdiction {
  compSearchId: number;
  industryCodeType: string;
  industryCodeValue: number;
  jurisdictionId: number;
  locationCode: string;
  pbaId: number;
  primaryFunctionId: number;
  searchCreated: string;
  pbaJurisdictionInfo: any;
  jurisdictionPli: any;
  jurisdictionPliFormat: any;
}

export interface PbaJurisdictionPayload {
  data: any;
  jurisdictionId: number;
}

export interface PbaJurisdictionStatus {
  jurisdictionId: number;
  jurisdictionIsoCode: string;
  status: number;
}

export interface JurisdictionDescription {
  codeRanges: string;
  compSearchId: number;
  domicileName: string;
  /* eslint-disable camelcase */
  domicile_id: number;
  fionaExclusions: number;
  industryCodeType: string;
  initialCount: number;
  jurisdictionId: number;
  jurisdictionName: string;
  location: string;
  locationTypeId: number;
  primaryFunctionId: number;
  publicPrivate: string;
  runOnDate: string;
  taxYear: string;
  majorClassifications: any[];
  maxIndustrialCodeRange: number;
  minIndustrialCodeRange: number;
  majorClassification1: string;
  majorClassification2: string;
  majorClassification3: string;
  numCompsInPool?: number;
}

export interface JurisdictionOverride extends JurisdictionDescription {
  isCompPoolAllowed: boolean;
  locationId: number;
  locationTypeId: number;
  locations: any;
  majorClassifications: any;
  maxIndustrialCodeRange: number;
  minIndustrialCodeRange: number;
  numCompsInPool: number;
  primaryFunctionId: number;
  runOnDate: string;
}

export interface PbaJurisdictionPliFormat {
  compSearchId: number;
  displayFormat: number;
  jurisdictionId: number;
  pliTypeId: number;
  roundingValue: number;
}

export interface BulkRejection {
  inRangeStatus: string;
  numRejected: number;
  numRemaining: number;
  rejectionReason: string;
  rejectionType: string;
  rejectionTypeId: number;
  startingCount: number;
}

export interface PbaJurisdictionPliFormatPayload {
  data: PbaJurisdictionPliFormat[];
}

export interface RangeColumns {
  key: string;
  label: string | number;
}

export interface RangeComparables {
  data: RangeCompany[];
  columns: RangeColumns[];
}

export interface RangeParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
  useS3Flag: boolean;
}

export interface BulkRejectionsParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
}

export interface ApplyUnappliedBulkRejectionParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
  rejectionTypeId: number;
  isApplying: boolean;
  containerId: number;
}

export interface ApplyUnappliedBulkRejectionResponse {
  rejectionTypeId: number;
  isApplying: boolean;
}

export interface GapAnalysisCompData {
  taxPayerName: string;
  sourceId: number;
  isRejected: string;
  rejectionName: string | null;
}
export interface GapAnalysisResponse {
  previousYearCompPool: GapAnalysisCompData[];
  currentYearCompPool: GapAnalysisCompData[];
}

export enum GapAnalysisComparison {
  Same = 'Same',
  New = 'New',
  Rejected = 'Rejected',
  Deleted = 'Deleted'
}

export interface NewGapAnalysisResponseData {
  taxPayerName: string;
  sourceId: number;
  isRejected: string;
  rejectionName: string;
  yearsRejected: number[];
  comparison: GapAnalysisComparison;
}

export interface NewGapAnalysisResponse {
  comps: NewGapAnalysisResponseData[];
  previousTaxYear: number;
  currentTaxYear: number;
  totalAcceptedCompsPerYear: {
    [key: string]: number;
  };
}

export interface GapAnalysisParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
  pbaId: number;
}

export interface ApiCompPoolOverrides {
  locationId: number;
  locationTypeId: number;
  industrialCodeTypeId?: number;
  minIndustrialCodeRange?: number;
  maxIndustrialCodeRange?: number;
  majorClassificationIds?: number[];
  runOnDate: string;
}

export interface CompSearchExecutionContext {
  jurisdictions: {
    [key: string]: {
      executionMode: number;
      jurisdictionId: number;
      compPoolOverrides?: {
        locationId: number;
        locationTypeId: number;
        industrialCodeTypeId?: number;
        minIndustrialCodeRange?: number;
        maxIndustrialCodeRange?: number;
        majorClassificationIds?: number[];
        runOnDate: string;
      };
    };
  };
}
export interface CompSearchPayload {
  containerId: number | undefined;
  container: Container | undefined;
  pbaId: number;
  jurisdictionId?: number;
  executionContext?: CompSearchExecutionContext;
}

export interface CompSearchRunBothPayload {
  pbaId: number;
  compSearchPayload: CompSearchPayload;
}

export interface CompSearchAnalysisTemplate {
  compSearchId: number;
  compSearchLocalLibraryId?: number;
  compSearchName: string;
  tenantId: number;
  jurisdictionId: number;
  containerId: number;
}

export interface CompSearchPsgAnalysisTemplate {
  compSearchId: number;
  compSearchName: string;
  jurisdictionId: number;
  containerId: number;
}

export interface CompSearchLoadPsgAnalysisTemplatePayload {
  compSearchId: number;
  jurisdictionId: number;
  containerId: number;
  loadCompSearchId: number;
  loadJurisdictionId: number;
  loadContainerId: number;
}

export interface LoadPsgAnalysisTemplateApiCallParams {
  loadCompSearchId: number;
  loadJurisdictionId: number;
  loadContainerId: number;
}

export interface ProjectCompPoolWithoutCompSearchPayload {
  container: Container;
  locationId: number;
  locationTypeId: number;
  primaryFunctionId: number;
  testedPartyId: number;
  minIndustrialCodeRange?: number;
  maxIndustrialCodeRange?: number;
  majorClassificationIds: number[];
  runOnDateAsDate: string;
}

export interface CompSearchStatus {
  compSearchState: number;
  doesTestedPartyExist: number;
  doesTestedPartyHavePli: number;
  hasIndustrialCodeSicValue: number;
  hasTransactionsAttachedToPba: number;
  isTestedPartyCharacterized: number;
  pbaId: number;
}

export interface Classification {
  majorClassificationTypeId: number;
  majorClassificationName: string;
  minorClassifications: Array<{
    minorClassificationTypeId: number;
    minorClassificationName: string;
  }>;
}

export interface RangeCompany {
  [key: string]: number | string | boolean | null | undefined;
  sourceId: number;
  companyName: string;
  average: number | null;
  isShifted: boolean;
  rejectionType?: string | null;
}

export interface RangeResults {
  data: Array<Record<string, string | number | undefined | null>>;
  columns: RangeColumns[];
  requestInfo: {
    compSearchId: number;
    pliTypeId: number;
  };
}

export interface RangeDiscussion {
  rangeDiscussion: string;
  jurisdiction: string;
}

export interface RangeDiscussionParams {
  compSearchId: number;
  jurisdictionId: number;
  pliId: number;
  pliAvgId: number;
  container: any;
  containerId: number;
  rangeDiscussion: string;
}

export interface FineTuningFinancialInfo {
  advertisingExpenses: number;
  cash: number;
  currencyCode: string;
  cashEquivalents: number;
  costOfGoodsSold: number;
  grossProfit: number;
  interestExpense: number;
  netInventory: number;
  netPayables: number;
  netReceivables: number;
  netSales: number;
  operatingAssets: number;
  operatingExpenses: number;
  operatingIncome: number;
  propertyPlantEquipment: number;
  rndExpense: number;
  taxYear: number;
  totalAssets: number;
  operatingProfit: number;
  netPpe: number;
}

export interface FineTuningPLIInfoData {
  pliAverageTypeId: string;
  pliTypeId: string;
  pliValue: number;
  shiftedTaxYear: number;
  sourceId: number;
  taxYear: number;
}

export interface FineTuningPLIInfo {
  average: FineTuningPLIInfoData[];
  yearly: FineTuningPLIInfoData[];
}

export interface SavedPsgAnalysisTemplates {
  compSearchGlobalLibraryId: number;
  compSearchId: number;
  compSearchName: string;
  containerId: number;
  hasValueAdded: number;
  jurisdictionId: number;
  majorClassificationIds: number[];
  primaryFunctionId: number;
  runOnDate: string;
  taxYear: number;
  testedPartyIndustrialCode: number;
}

export interface CSInitialSearchJurisdictionData {
  countryId: number;
  isOverride: boolean;
}

export interface PinnedCompsResponse {
  data: [
    {
      containerId: number;
      jurisdictionId: number;
      sourceId: number;
      sourceType: number;
      createdAt: string;
    }
  ];
}
