import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LanguageIsoInfo,
  LocalReportsState,
  PartialEntity,
  PreviousReportVersionData,
  PrimaryTradingPartnersMap,
  ReportPayload,
  ReportOrgChartPayload,
  ReportOrgChartGetPayload,
  SetupData,
  SummariesData,
  TransactionItem
} from './localReports.proptype';
import { RootState } from '..';
import {
  ReportInstanceDownloadProp,
  SignedUrl,
  UploadedReportSignedUrlInfo
} from '../../app/LocalReports/LocalReports.proptype';
import { UploadReportPayloadProps } from '../../app/LocalReportsActions/UploadReportModal/UploadReportModal.proptype';
import {
  FinalLocalReport,
  InternalLocalFileReport,
  InternalLocalFileReportInstance,
  LocalReportData,
  TemplateReport
} from '../../models';
import { selectTransactionsList } from '../../selectors';
import httpService from '../../services/http';

export const createPrimaryTradingPartnersMap = createAsyncThunk<
  PrimaryTradingPartnersMap,
  number,
  { state: RootState; rejectValue: Error }
>('localReports/createPrimaryTradingPartnersMap', async (jurisdictionId, { getState, rejectWithValue }) => {
  try {
    const primaryEntityList = (
      await httpService.request<{ data: PartialEntity[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `entities/?domicile=${jurisdictionId}&sort=code&order=asc`
      })
    ).data.data;

    const state = getState();
    const transactions = selectTransactionsList(state);
    const primaryEntitiesIds = new Set(primaryEntityList.map((ent) => ent.entityId));
    const primaryTradingPartnersMap: PrimaryTradingPartnersMap = {};

    for (const transaction of transactions ?? []) {
      const primaryEntityIndex = transaction.legalEntityTransactions.findIndex((party) =>
        primaryEntitiesIds.has(party.entity.entityId)
      );

      if (primaryEntityIndex === -1) {
        continue;
      }

      const primaryEntity = transaction.legalEntityTransactions[primaryEntityIndex].entity;
      const tradingPartner = transaction.legalEntityTransactions[primaryEntityIndex ^ 1].entity;

      if (!primaryTradingPartnersMap[primaryEntity.entityId]) {
        primaryTradingPartnersMap[primaryEntity.entityId] = {
          selected: false,
          entityId: primaryEntity.entityId,
          code: primaryEntity.code,
          tradingPartners: {}
        };
      }

      if (!primaryTradingPartnersMap[primaryEntity.entityId].tradingPartners[tradingPartner.entityId]) {
        primaryTradingPartnersMap[primaryEntity.entityId].tradingPartners[tradingPartner.entityId] = {
          selected: false,
          entityId: tradingPartner.entityId,
          code: tradingPartner.code,
          transactions: []
        };
      }

      primaryTradingPartnersMap[primaryEntity.entityId].tradingPartners[tradingPartner.entityId].transactions.push({
        ...transaction,
        selected: false
      });
    }

    return primaryTradingPartnersMap;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchLanguages = createAsyncThunk<
  LanguageIsoInfo[],
  { jurisdictionId: number; taxYear: number },
  { rejectValue: Error }
>('localReports/fetchLanguages', async (payload, { rejectWithValue }) => {
  try {
    /* eslint-disable camelcase */
    return (
      await httpService.request<{ data: Array<{ language_iso: string }> }>({
        method: 'get',
        apiUrlKey: 'compSearchApiUrl',
        relativePath: `/v1/compsearch/jurisdiction/${payload.jurisdictionId}/tax-year/${payload.taxYear}/languages`
      })
    ).data.data.map((lang) => {
      return {
        isoCode: lang.language_iso
      };
    });
    /* eslint-enable camelcase */
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const downloadReportInstance = createAsyncThunk<
  { data: SignedUrl },
  ReportInstanceDownloadProp,
  { rejectValue: Error }
>('localReports/instance/download', async (payload, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/download`;
    const { data }: any = await httpService.request<SignedUrl>({
      method: 'post',
      apiUrlKey: 'docApiUrl',
      relativePath,
      data: payload
    });
    // TODO Move this to connector calling this function
    window.open(data.data.url, '_self');
    return data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const getSignedUrlForUploadedReport = createAsyncThunk<
  UploadedReportSignedUrlInfo,
  UploadReportPayloadProps,
  { rejectValue: Error }
>('localReports/instance/upload/post', async (payload, { rejectWithValue }) => {
  try {
    const relativePath = `/v1/internal-localfile-report-instance/upload`;
    const { data }: any = await httpService.request<SignedUrl>({
      method: 'post',
      apiUrlKey: 'docApiUrl',
      relativePath,
      data: payload
    });

    return data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const uploadReportToSignedUrl = createAsyncThunk<
  any,
  { awsSignedUrlPath: string; reportFile: File },
  { rejectValue: Error }
>('localReports/instance/upload/put', async ({ awsSignedUrlPath, reportFile }, { rejectWithValue }) => {
  try {
    const response = await httpService.requestWithoutAuthHeader<any>(awsSignedUrlPath, 'put', reportFile, {
      'Content-Type': reportFile.type
    });
    return response.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchAllInternalLocalFileReportVersions = createAsyncThunk<
  InternalLocalFileReportInstance[],
  number,
  { rejectValue: Error }
>('reports/versions/fetch', async (reportId, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(reportId)}/instance`
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const updateReportActiveVersion = createAsyncThunk<
  InternalLocalFileReportInstance[],
  any,
  { rejectValue: Error }
>('reports/versions/patch', async (params, { rejectWithValue }) => {
  const { reportId, internalLocalfileReportInstanceId } = params;

  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'patch',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(reportId)}/instance/${String(
          internalLocalfileReportInstanceId
        )}/active-status`,
        data: {}
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deleteReport = createAsyncThunk<
  InternalLocalFileReportInstance[],
  { reportId: number },
  { rejectValue: Error }
>('reports/versions/delete', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(params.reportId)}`
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const deleteReportVersion = createAsyncThunk<
  InternalLocalFileReportInstance[],
  { reportId: number; internalLocalfileReportInstanceId: number },
  { rejectValue: Error }
>('reports/versions/delete/version', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance[]>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(params.reportId)}/instance/${String(
          params.internalLocalfileReportInstanceId
        )}`
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchInternalLocalFileReportInstance = createAsyncThunk<
  InternalLocalFileReportInstance,
  { reportId: number; instanceId: number },
  { rejectValue: Error }
>('reports/instance/fetch', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<InternalLocalFileReportInstance>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${String(params.reportId)}/instance/${String(params.instanceId)}`
      })
    ).data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const fetchTemplates = createAsyncThunk<TemplateReport[], number, { rejectValue: Error }>(
  'localReports/fetchTemplates',
  async (entityId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: { templates: TemplateReport[] } }>({
          method: 'get',
          apiUrlKey: 'tpReportGeneratorApiUrl',
          relativePath: `/v1/entity/${entityId}/templates`
        })
      ).data.data.templates.map((temp) => {
        /**
         * There are two possible template name formats: The one that has 'Analysis-Benchmark-Report' as a suffix
         * and the one that hasn't.The latter one should use the UI name 'Local File Report'.
         */
        return temp.name.includes('Analysis-Benchmark-Report') ? temp : { ...temp, useName: 'Local File Report' };
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const generateNewReport = createAsyncThunk<
  { reportId: string; reportInstanceId: string },
  ReportPayload,
  { state: RootState; rejectValue: Error }
>('localReports/generateNewReport', async (reportPayload, { rejectWithValue }) => {
  try {
    const reportResult = await httpService.request<{ data: { reportId: string; reportInstanceId: string } }>({
      method: 'post',
      apiUrlKey: 'tpReportGeneratorApiUrl',
      relativePath: '/v1/generate-reports',
      data: reportPayload
    });
    const { reportId } = reportResult.data.data;
    if (reportPayload.orgChart) {
      const { orgChart: userInputData, containerId } = reportPayload;

      await generateNewReportOrgChart({
        userInputData,
        containerId,
        reportId
      });
    }

    return reportResult.data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const generateNewReportOrgChart = async (reportOrgChartPayload: ReportOrgChartPayload) => {
  try {
    const { userInputData, containerId, reportId } = reportOrgChartPayload;
    const inputDataTypeId = 1; // org chart
    const url = `container/${String(containerId)}/report/${String(reportId)}/user-input-data`;

    return await httpService.request<{ data: { result: Record<string, unknown> } }>({
      method: 'post',
      apiUrlKey: 'tpReportGeneratorApiUrl',
      relativePath: `/v1/${url}`,
      data: { userInputData, inputDataTypeId }
    });
  } catch (error: unknown) {
    throw error as Error;
  }
};

export const fetchWorkingLocalFileReports = createAsyncThunk<InternalLocalFileReport[], number, { rejectValue: Error }>(
  'localReports/workingLocalFile/fetch',
  async (jurisdictionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<InternalLocalFileReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `internal-local-file-reports/domicile/${jurisdictionId}/working-reports`
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalLocalFileReports = createAsyncThunk<FinalLocalReport[], number, { rejectValue: Error }>(
  'localReports/finalLocalFile/fetch',
  async (jurisdictionId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<FinalLocalReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `internal-local-file-reports/domicile/${jurisdictionId}/final-reports`
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchReportInstancesForJurisdiction = createAsyncThunk<
  LocalReportData[],
  { jurisdictionId: number; isActive?: boolean; reportId?: string; containerId?: number },
  { rejectValue: Error }
>('localReports/reportInstancesForJurisdiction/fetch', async (params, { rejectWithValue }) => {
  const { jurisdictionId, isActive, containerId } = params;
  try {
    const reportsResult = await httpService.request<LocalReportData[]>({
      method: 'get',
      apiUrlKey: 'baseUrl',
      relativePath: `internal-local-file-reports/domicile-instances/${jurisdictionId}${
        isActive === undefined ? '' : `?isActive=${String(isActive)}`
      }`
    });

    const reportsData: LocalReportData[] = reportsResult.data;

    // now fetch the orgChart for each one.
    if (containerId) {
      const orgChartPromises = reportsData.map(async (report) => {
        const reportId = String(report.internalLocalfileReportId);
        return getReportOrgChart({
          containerId,
          reportId
        });
      });
      const orgChartResults = await Promise.all(orgChartPromises);
      const orgChartData = orgChartResults.map((orgChartResult) => orgChartResult.data.data);
      const finalData = reportsData.map((orgChartResult, index) => {
        orgChartResult.orgChart = orgChartData[index].userInputData;
        return orgChartResult;
      });
      return finalData;
    }

    return reportsData;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const getReportOrgChart = async (reportOrgChartGetPayload: ReportOrgChartGetPayload) => {
  try {
    const { containerId, reportId } = reportOrgChartGetPayload;
    const url = `container/${String(containerId)}/report/${String(reportId)}/user-input-data/1`;
    return await httpService.request<{
      data: {
        userInputData: string | undefined;
        result: Record<string, unknown>;
      };
    }>({
      method: 'get',
      apiUrlKey: 'tpReportGeneratorApiUrl',
      relativePath: `/v1/${url}`
    });
  } catch (error: unknown) {
    throw error as Error;
  }
};

export interface ReportStatusPayload {
  reportId: number;
  reportInstanceId: number;
  containerId: number | undefined;
  reportStatus: string;
}
export const saveReportStatus = createAsyncThunk<
  { reportId: string; reportInstanceId: string; reportStatus: string },
  ReportStatusPayload,
  { state: RootState; rejectValue: Error }
>('localReports/saveReportStatus', async (reportPayload, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: { reportId: string; reportInstanceId: string; reportStatus: string } }>({
        method: 'post',
        apiUrlKey: 'tpReportGeneratorApiUrl',
        relativePath: `/v1/container/${String(reportPayload.containerId)}/report/${String(
          reportPayload.reportId
        )}/instance/${String(reportPayload.reportInstanceId)}/report-status`,
        data: { reportStatus: reportPayload.reportStatus }
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export interface ReportPublishPayload {
  reportId: number;
  reportInstanceId: number;
  containerId: number | undefined;
}
export const publishReport = createAsyncThunk<void, ReportPublishPayload, { rejectValue: Error }>(
  'reports/publish',
  async ({ reportId, reportInstanceId, containerId }, { rejectWithValue }) => {
    try {
      await httpService.request<void>({
        method: 'post',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${reportId}/instance/${reportInstanceId}/publish`,
        data: { containerId }
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface ReportUnpublishPayload {
  reportId: number;
  reportInstanceId: number;
}
export const unpublishReport = createAsyncThunk<void, ReportUnpublishPayload, { rejectValue: Error }>(
  'reports/unpublish',
  async ({ reportId, reportInstanceId }, { rejectWithValue }) => {
    try {
      await httpService.request<void>({
        method: 'delete',
        apiUrlKey: 'baseUrl',
        relativePath: `internal-local-file-reports/${reportId}/instance/${reportInstanceId}/unpublish`
      });
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const initialState: LocalReportsState = {
  finalLocalFiles: null,
  workingLocalFiles: null,
  localFileReports: [],
  reportVersions: [],
  languageList: [],
  templateList: [],
  setup: {
    reportTitle: '',
    language: 'en',
    currency: '',
    reportTemplate: '',
    limitWording: false,
    addPageNumber: false
  },
  primaryTradingPartnersMap: null,
  summaries: {
    executive: '',
    conclusion: '',
    orgChart: ''
  },
  activeReport: null
};

const localReportsSlice = createSlice({
  name: 'localReports',
  initialState,
  reducers: {
    setSelectedPrimaryEntities(state, action: PayloadAction<Array<PartialEntity['entityId']>>) {
      const entitiIds = new Set(action.payload);

      Object.keys(state.primaryTradingPartnersMap ?? {}).forEach((entityId) => {
        state.primaryTradingPartnersMap![Number(entityId)].selected = entitiIds.has(Number(entityId));
      });
    },
    setSelectedTradingPartners(state, action: PayloadAction<Array<PartialEntity['entityId']>>) {
      const entitiIds = new Set(action.payload);

      Object.values(state.primaryTradingPartnersMap ?? {}).forEach((primaryEntity) => {
        Object.keys(primaryEntity.tradingPartners).forEach((tradPartnerId) => {
          state.primaryTradingPartnersMap![primaryEntity.entityId].tradingPartners[
            Number(tradPartnerId)
          ].selected = entitiIds.has(Number(tradPartnerId));
        });
      });
    },
    setSelectedTransactions(state, action: PayloadAction<Array<TransactionItem['transactionId']>>) {
      const transactionIds = new Set(action.payload);

      Object.values(state.primaryTradingPartnersMap ?? {}).forEach((primaryEntity) => {
        Object.keys(primaryEntity.tradingPartners).forEach((tradPartnerId) => {
          state.primaryTradingPartnersMap![primaryEntity.entityId].tradingPartners[
            Number(tradPartnerId)
          ].transactions.forEach((trans) => {
            trans.selected = transactionIds.has(Number(trans.transactionId));
          });
        });
      });
    },
    setSetup(state, action: PayloadAction<SetupData>) {
      state.setup = action.payload;
    },
    setSummaries(state, action: PayloadAction<SummariesData>) {
      state.summaries = action.payload;
    },
    resetData(state) {
      state.languageList = initialState.languageList;
      state.templateList = initialState.templateList;
      state.setup = initialState.setup;
      state.primaryTradingPartnersMap = initialState.primaryTradingPartnersMap;
      state.summaries = initialState.summaries;
      state.error = initialState.error;
    },
    resetReportVersionsData(state) {
      state.reportVersions = initialState.reportVersions;
    },
    setPreviousVersionData(state, action: PayloadAction<PreviousReportVersionData>) {
      state.setup = action.payload.setupData;
      state.summaries = action.payload.summaries;
    },
    setLocalFileReport(state, action: PayloadAction<LocalReportData[] | null>) {
      state.localFileReports = action.payload;
    },
    replaceLocalFileReportInstanceData(
      state,
      action: PayloadAction<{ previousInstanceId: number; instanceData: LocalReportData }>
    ) {
      if (state.localFileReports === null) {
        return;
      }

      const instanceToUpdateIndex = state.localFileReports?.findIndex(
        (instance) => instance.internalLocalfileReportInstanceId === action.payload.previousInstanceId
      );

      if (instanceToUpdateIndex === -1) {
        return;
      }

      state.localFileReports[instanceToUpdateIndex] = action.payload.instanceData;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLanguages.fulfilled, (state, { payload: languagesList }) => {
        state.languageList = languagesList;
      })
      .addCase(fetchTemplates.fulfilled, (state, { payload: templateList }) => {
        state.templateList = templateList;
      })
      .addCase(createPrimaryTradingPartnersMap.fulfilled, (state, { payload: primaryTradingPartnersMap }) => {
        state.primaryTradingPartnersMap = primaryTradingPartnersMap;
      })
      .addCase(fetchAllInternalLocalFileReportVersions.fulfilled, (state: LocalReportsState, { payload }) => {
        const reportVersions: any = payload.map((versionData) => {
          return {
            version: versionData.version,
            internalLocalfileReportInstanceId: versionData.internalLocalfileReportInstanceId,
            // Todo: Remove once report status migration from old UI is done
            reportStatus: versionData.reportStatus ?? 'draft ',
            isUploadedReport: versionData.isUploadedReport,
            createdAt: versionData.createdAt,
            isActive: versionData.isActive,
            finalVersionFlag: versionData.finalVersionFlag,
            languageIso: versionData.languageIso,
            reportCurrency: versionData.reportCurrency,
            binaryPath: versionData.binaryPath
          };
        });
        state.reportVersions = reportVersions;

        const activeReport = payload?.find((report) => report.isActive);
        state.activeReport = activeReport;
      })
      .addCase(updateReportActiveVersion.fulfilled, (state: LocalReportsState, { meta: { arg: params } }) => {
        const { internalLocalfileReportInstanceId } = params;
        if (state.reportVersions === null) {
          return state;
        }

        // do all this with payload when it is available
        const reportVersions = [...state.reportVersions];

        const activeVersion = reportVersions.find((reportVersion) => reportVersion.isActive);
        if (activeVersion) {
          activeVersion.isActive = 0;
        }

        const newActiveVersion = reportVersions.find(
          (reportVersion: any) => reportVersion.internalLocalfileReportInstanceId === internalLocalfileReportInstanceId
        );
        if (newActiveVersion) {
          newActiveVersion.isActive = 1;
        }

        state.reportVersions = reportVersions;
      })
      .addCase(deleteReportVersion.fulfilled, (state: LocalReportsState, { meta: { arg: params } }) => {
        if (state.reportVersions === null) {
          return state;
        }

        const reportVersions = [...state.reportVersions];
        const deletedVersionIndex = reportVersions.findIndex(
          (reportVersion) =>
            reportVersion.internalLocalfileReportInstanceId === params.internalLocalfileReportInstanceId
        );
        if (deletedVersionIndex >= 0) {
          reportVersions.splice(deletedVersionIndex, 1);
        }

        // todo: assign new active version - to be done on backend?

        state.reportVersions = reportVersions;
      })
      .addCase(
        fetchInternalLocalFileReportInstance.fulfilled,
        (state: LocalReportsState, action: PayloadAction<InternalLocalFileReportInstance>) => {
          state.activeReport.tradingPartners = action.payload.tradingPartners;
          state.activeReport.reportTransactions = action.payload.reportTransactions;
        }
      )
      .addCase(
        fetchWorkingLocalFileReports.fulfilled,
        (state: LocalReportsState, { payload: internalLocalFileReport }) => {
          state.workingLocalFiles = internalLocalFileReport;
        }
      )
      .addCase(fetchFinalLocalFileReports.fulfilled, (state: LocalReportsState, { payload: finalLocalReport }) => {
        state.finalLocalFiles = finalLocalReport;
      })
      .addCase(
        fetchReportInstancesForJurisdiction.fulfilled,
        (state: LocalReportsState, { payload: localFileReports }) => {
          state.localFileReports = localFileReports;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^localReports\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^localReports\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export const { reducer, actions } = localReportsSlice;
