import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core';
import { NavigationTabsProps } from './NavigationTabs.prototype';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    padding: '1.375em',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    backgroundColor: 'inherit',
    '& .Mui-selected': {
      color: theme.palette.primary.dark
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

// @todo: extend to display optional icons to left of tab.label. See DUO-2242
// eslint-disable-next-line @typescript-eslint/no-empty-function
const NavigationTabs = ({ tabs, onSelectTab = () => {} }: NavigationTabsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const tabKey = pathname.split(`${url}/`).pop();
    const currentTab = tabs.findIndex(({ key }) => key === tabKey);
    setTabIndex(currentTab);
    onSelectTab(currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, url]);

  if (tabIndex === -1) {
    history.push(`${url}/${tabs[0].key}`);
    return null;
  }

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={tabIndex}
        onChange={(event, value) => {
          history.push(`${url}/${tabs[value].key}`);
        }}
      >
        {tabs.map((tab) => (
          <Tab key={tab.key} label={t(`analysis:${tab.label}`)} />
        ))}
      </Tabs>
      <Switch>
        {tabs.map((tab) => {
          return (
            <Route key={`tab-route-${tab.key}`} path={`${path}/${tab.key}`}>
              <Box className={classes.paperContainer}>{tab.content}</Box>
            </Route>
          );
        })}
        <Route path={`${path}/`}>
          <Box className={classes.paperContainer}>{tabs[tabIndex].content}</Box>
        </Route>
      </Switch>
    </>
  );
};

export default NavigationTabs;
