/* eslint-disable @typescript-eslint/ban-types */
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { TbaFinalRangeTabProps } from './TbaFinalRange.proptype';
import { TbaFinalRangeTabHeader } from './TbaFinalRangeTabHeader';
import { Table } from '../../app/Table';
import { CenteredProgress, Editor } from '../../components';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import {
  fetchTbaRangeResults,
  updateTbaRangeResult,
  StatisticalMethod,
  TbaRangeResult,
  TbaRangeResultUpdate,
  UpdateTbaRangeType,
  TbaRangeType,
  CreateTbaRangeType
} from '../../redux/transactionBasedAnalyses';
import { selectWorkingContainer } from '../../selectors';
import tokens from '../../styles/designTokens';
import { body1, body4 } from '../../styles/typography';
import { formatRangeValue, hasEditAccess } from '../../utils';
import useDebounce from '../../utils/debounce';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { StatisticalMethodIds } from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  rangeResultLabel: {
    fontWeight: 600
  },
  rangeResultValue: {
    textAlign: 'right'
  },
  labelEditor: {
    ...body4,
    color: tokens.core1,
    marginBottom: theme.spacing(0.5)
  },
  labelEditorDescription: {
    ...body1,
    color: tokens.core2,
    marginBottom: theme.spacing(1)
  }
}));

export type TbaRangeResultTableValue = keyof Omit<
  TbaRangeResult,
  'discussion' | 'inRange' | 'tba' | 'tbaRangeResultId' | 'tbaRangeType'
>;

export interface FinalRangeTabTableData {
  rangeOption: JSX.Element;
  values: JSX.Element;
}

const TbaRangeResultValueOrderMap: Record<TbaRangeResultTableValue, number> = {
  minimum: 1,
  lowerQuartile: 2,
  median: 3,
  upperQuartile: 4,
  maximum: 5,
  testedValue: 6
};

export const TbaFinalRangeTabContent = ({
  selectedTba,
  statisticalMethods,
  tbaRangeResults,
  tbaTaxJurisdictionCodes,
  isLoading,
  isUnitPrice,
  handleCreateRangeTypes,
  handleUpdateRangeTypes,
  handleDeleteRangeTypes
}: TbaFinalRangeTabProps) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { t } = useTranslation();
  const container = useSelector(selectWorkingContainer);
  const [selectedJurisdictionCode, setSelectedJurisdictionCode] = useState('');
  const [isSourceSelected, setIsSourceSelected] = useState<boolean>(true);
  const [selectedRangeType, setSelectedRangeType] = useState<TbaRangeType | null>(null);
  const [selectedRangeOption, setSelectedRangeOption] = useState<StatisticalMethod | null>(null);
  const [currentRangeResult, setCurrentRangeResult] = useState<Omit<
    TbaRangeResult,
    'inRange' | 'tba' | 'tbaRangeType'
  > | null>();
  const [sourceDiscussion, setSourceDiscussion] = useState<string>();
  const [destinationDiscussion, setDestinationDiscussion] = useState<string>();
  const workingContainer = useSelector(selectWorkingContainer);
  const [statisticalMethodOptions, setStatisticalMethodOptions] = useState<StatisticalMethod[]>([]);
  const [featureFlagIsActive] = useFeatureFlags();

  const showRangeValueFixFormatting = featureFlagIsActive('tbaRangeValuesFormatting');

  useEffect(() => {
    if (statisticalMethods && statisticalMethods.length > 0) {
      setStatisticalMethodOptions([
        { id: StatisticalMethodIds.NoSelected, name: 'No_Range_Selected' },
        ...statisticalMethods
      ]);
    }
  }, [statisticalMethods]);

  let data: FinalRangeTabTableData[] = [];

  useEffect(() => {
    if (tbaTaxJurisdictionCodes?.source && selectedJurisdictionCode === '') {
      setSelectedJurisdictionCode(tbaTaxJurisdictionCodes.source);
      setIsSourceSelected(true);
    }
  }, [tbaTaxJurisdictionCodes, selectedJurisdictionCode]);

  useEffect(() => {
    if (Array.isArray(tbaRangeResults) && tbaRangeResults.length > 0) {
      const selectedRangeResult = tbaRangeResults.find((tbaRangeResult) =>
        isSourceSelected
          ? tbaRangeResult.tbaRangeType.useForSourceTaxJurisdiction
          : tbaRangeResult.tbaRangeType.useForDestinationTaxJurisdiction
      );

      if (!selectedRangeResult) {
        setCurrentRangeResult(null);
        setSelectedRangeOption(
          statisticalMethodOptions.find(
            (statisticalMethod) => statisticalMethod.id === StatisticalMethodIds.NoSelected
          ) ?? null
        );
        return;
      }

      if (selectedRangeResult && statisticalMethodOptions.length > 0) {
        const { inRange: _inRange, tba: _tba, tbaRangeType, ...rangeResult } = selectedRangeResult;

        if (typeof sourceDiscussion === 'undefined' && typeof destinationDiscussion === 'undefined') {
          const sourceRangeResult = tbaRangeResults.find((result) => result.tbaRangeType.useForSourceTaxJurisdiction);
          const destinationRangeResult = tbaRangeResults.find(
            (result) => result.tbaRangeType.useForDestinationTaxJurisdiction
          );

          setSourceDiscussion(sourceRangeResult?.discussion ?? '');
          setDestinationDiscussion(destinationRangeResult?.discussion ?? '');
          return;
        }

        setCurrentRangeResult(rangeResult);
        setSelectedRangeType(tbaRangeType);

        setSelectedRangeOption(
          statisticalMethodOptions.find(
            (statisticalMethod) => statisticalMethod.id === tbaRangeType.statisticalMethod
          ) ?? null
        );
      } else {
        setCurrentRangeResult(null);
        setSelectedRangeOption(null);
        setSelectedRangeType(null);
      }
    } else {
      setSelectedRangeOption(
        statisticalMethodOptions.find(
          (statisticalMethod) => statisticalMethod.id === StatisticalMethodIds.NoSelected
        ) ?? null
      );
    }
  }, [tbaRangeResults, statisticalMethodOptions, isSourceSelected, sourceDiscussion, destinationDiscussion]);

  const getJurisdictionName = () =>
    isSourceSelected ? tbaTaxJurisdictionCodes?.sourceFullName : tbaTaxJurisdictionCodes?.destinationFullName ?? '';

  const handleSourceSelected = () => {
    if (tbaTaxJurisdictionCodes?.source) {
      setSelectedJurisdictionCode(tbaTaxJurisdictionCodes?.source);
      setIsSourceSelected(true);
      const sourceRangeType = tbaRangeResults?.find(
        (rangeResult) => rangeResult.tbaRangeType.useForSourceTaxJurisdiction
      );
      if (sourceRangeType) {
        setSelectedRangeType(sourceRangeType.tbaRangeType);
      }
    }
  };

  const handleDestinationSelected = () => {
    if (tbaTaxJurisdictionCodes?.destination) {
      setSelectedJurisdictionCode(tbaTaxJurisdictionCodes?.destination ?? '');
      setIsSourceSelected(false);
      const destinationRangeType = tbaRangeResults?.find(
        (rangeResult) => rangeResult.tbaRangeType.useForDestinationTaxJurisdiction
      );
      if (destinationRangeType) {
        setSelectedRangeType(destinationRangeType.tbaRangeType);
      }
    }
  };

  const handleUpdateRangeOption = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const newRangeOptionId = event.target.value as number;
    if (newRangeOptionId === StatisticalMethodIds.NoSelected && selectedRangeType) {
      handleDeleteRangeTypes(selectedRangeType.tbaRangeTypeId);
      setCurrentRangeResult(null);
      return;
    }

    const newRangeOption = statisticalMethods?.find((statisticalMethod) => statisticalMethod.id === newRangeOptionId);
    const rangeType = tbaRangeResults?.find(
      (rangeResult) => rangeResult.tbaRangeResultId === currentRangeResult?.tbaRangeResultId
    )?.tbaRangeType;
    if (rangeType) {
      const updatePayload: UpdateTbaRangeType = {
        container: container!,
        name: rangeType?.name,
        excluded: rangeType?.excluded,
        useForSourceTaxJurisdiction: rangeType.useForSourceTaxJurisdiction,
        useForDestinationTaxJurisdiction: rangeType?.useForDestinationTaxJurisdiction,
        statisticalMethod: newRangeOption!.id
      };
      handleUpdateRangeTypes(rangeType.tbaRangeTypeId, updatePayload);
    } else if (container && newRangeOption && selectedTba) {
      const createPayload: CreateTbaRangeType = {
        container,
        name: `${isSourceSelected ? 'Source' : 'Destination'} ${selectedJurisdictionCode}`,
        statisticalMethod: newRangeOption.id,
        tba: selectedTba,
        useForDestinationTaxJurisdiction: !isSourceSelected,
        useForSourceTaxJurisdiction: isSourceSelected
      };
      handleCreateRangeTypes(createPayload);
    }

    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      event_category: 'tba_button_click',
      event_label: `${selectedJurisdictionCode ?? ''} ${newRangeOption?.name ?? ''} Update click`,
      container_id: workingContainer?.containerId
    });
    setSelectedRangeOption(newRangeOption ?? null);
  };

  const onSubmitRangeResultDiscussion = async () => {
    if (!hasEditAccess) return;
    if (currentRangeResult && container) {
      const tbaRangeResult = tbaRangeResults?.find(
        (tbaRangeResult) => tbaRangeResult.tbaRangeResultId === currentRangeResult.tbaRangeResultId
      );
      if (tbaRangeResult) {
        const payload: TbaRangeResultUpdate = {
          ...tbaRangeResult,
          container,
          collapsed: true,
          discussion: isSourceSelected ? sourceDiscussion : destinationDiscussion
        };
        unwrapResult(await dispatch<any>(updateTbaRangeResult(payload)));
        unwrapResult(await dispatch<any>(fetchTbaRangeResults(tbaRangeResult.tba.tbaId)));
      }
    }
  };

  const [handleEditorAuto] = useDebounce(async () => {
    void onSubmitRangeResultDiscussion();
  }, 3000);

  useEffect(() => {
    handleEditorAuto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceDiscussion, destinationDiscussion]);

  const displayColumns = [
    {
      key: 'rangeOption',
      header: statisticalMethodOptions && selectedRangeOption && (
        <Select variant="outlined" value={selectedRangeOption?.id} onChange={handleUpdateRangeOption}>
          {statisticalMethodOptions?.map((statisticalMethod) => (
            <MenuItem key={statisticalMethod.id} value={statisticalMethod.id}>
              {selectedJurisdictionCode}: {t(`analysis:range_method_type.${statisticalMethod.name}`)}
            </MenuItem>
          ))}
        </Select>
      )
    },
    {
      key: 'values',
      header: (
        <Box style={{ textAlign: 'right' }}>
          {showRangeValueFixFormatting ? !isUnitPrice && <Typography>(%)</Typography> : <Typography>(%)</Typography>}
          <Typography>{t('analysis:final-range')}</Typography>
        </Box>
      ),
      width: 120
    }
  ];

  data = currentRangeResult
    ? Object.keys(currentRangeResult)
        .filter(
          (key) =>
            !['discussion', 'tbaRangeResultId'].includes(key) &&
            (currentRangeResult[key as keyof typeof currentRangeResult] ||
              typeof currentRangeResult[key as keyof typeof currentRangeResult] === 'number')
        )
        .sort((a, b) =>
          TbaRangeResultValueOrderMap[a as TbaRangeResultTableValue] >
          TbaRangeResultValueOrderMap[b as TbaRangeResultTableValue]
            ? 1
            : -1
        )
        .map((row) => ({
          rangeOption: (
            <Box
              key={row}
              className={classes.rangeResultLabel}
              style={row === 'testedValue' ? { color: tokens.product100 } : {}}
            >
              {t(`analysis:final-range-results-column.${row}`)}
            </Box>
          ),
          values: (
            <Box
              key={row}
              className={classes.rangeResultValue}
              style={row === 'testedValue' ? { color: tokens.product100, fontWeight: 600 } : {}}
            >
              {showRangeValueFixFormatting
                ? formatRangeValue(currentRangeResult[row as keyof typeof currentRangeResult], isUnitPrice)
                : `${(Number(currentRangeResult[row as keyof typeof currentRangeResult]) * 100).toFixed(2)}%`}
            </Box>
          )
        }))
    : [];

  return (
    <Box className={classes.container}>
      {isLoading ? (
        <CenteredProgress />
      ) : (
        <>
          <TbaFinalRangeTabHeader
            tbaTaxJurisdictionCodes={tbaTaxJurisdictionCodes}
            currentValue={selectedJurisdictionCode}
            handleSourceSelected={handleSourceSelected}
            handleDestinationSelected={handleDestinationSelected}
          />
          <Table columns={displayColumns} data={data} />
          {currentRangeResult && (
            <Box style={{ marginTop: '2rem' }}>
              <Typography className={classes.labelEditor}>
                {t('analysis:final-range-results-discussion-title', {
                  jurisdictionName: getJurisdictionName()
                })}
              </Typography>
              <Typography className={classes.labelEditorDescription}>
                {/* hiding subtitle text per DUO-1525 TODO: add back in when ready
                {t('analysis:final-range-results-discussion-desc')}
                */}
              </Typography>
              <Editor
                theme="TransferPricing"
                id="discussion"
                init={{ height: '250' }}
                value={isSourceSelected ? sourceDiscussion : destinationDiscussion}
                disabled={!hasEditAccess()}
                onEditorChange={(content) => {
                  /* eslint-disable camelcase */
                  void logGoogleAnalyticsEvent({
                    event_category: 'tba_button_click',
                    event_label: `${selectedJurisdictionCode} Range Results Discussion Update click`,
                    container_id: workingContainer?.containerId
                  });
                  if (isSourceSelected) {
                    setSourceDiscussion(content);
                  } else {
                    setDestinationDiscussion(content);
                  }
                }}
                onBlur={onSubmitRangeResultDiscussion}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
