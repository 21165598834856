import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FinancialTypes, FinancialAreas } from '@xbs/xbs-enums';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { FinancialData } from '../../../models';
import { fetchConversionRates } from '../../../redux/conversionRates';
import { fetchTestedPartyFinancials, updateTestedPartyFinancial } from '../../../redux/profitBasedAnalyses';
import {
  selectConversionRatesByEntity,
  selectEntityCurrency,
  selectUPECurrency,
  selectWorkingContainer
} from '../../../selectors';
import { selectCurrentPBA, selectTestedPartyBalanceSheet } from '../../../selectors/profitBasedAnalyses';
import { PbaDashboardFinancialProps } from '../../FinancialTable/FinancialTable.proptype';

interface ConnectorProps {
  readonly component: ComponentType<PbaDashboardFinancialProps>;
}

const Connector = ({ component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const [featureFlagIsActive] = useFeatureFlags();
  const dispatch = useDispatch();
  const pba = useSelector(selectCurrentPBA);
  const testedPartyBalanceSheet = useSelector(selectTestedPartyBalanceSheet);
  const balanceSheetData: FinancialData[] | null = testedPartyBalanceSheet;
  const workingContainer = useSelector(selectWorkingContainer);
  const entityId = pba?.primaryLegalEntity?.entityId;
  const financialConversions = useSelector(selectConversionRatesByEntity(entityId));

  const upeCurrency = useSelector(selectUPECurrency);
  const entityCurrency = useSelector(selectEntityCurrency(entityId));
  // valueEnteredIn: 1 always mean upeCurrency, so this option must come first.
  const currencyOptions = [upeCurrency!];
  if (entityCurrency && entityCurrency.currencyId !== upeCurrency?.currencyId) {
    currencyOptions.push(entityCurrency);
  }

  let testedPartyInfo: Record<string, any> = {};
  if (pba?.testedParty) {
    testedPartyInfo = {
      testedPartyName: pba.testedParty.name,
      fiscalYearEnd: String(pba?.primaryLegalEntity?.fiscalYearEnd ?? ''),
      testedPartyId: pba.testedParty.testedPartyId
    };
  }

  const headerInfo = [
    { label: t('analysis:column-tested-party-name'), value: testedPartyInfo.testedPartyName },
    { label: t('analysis:fiscal-year-end'), value: testedPartyInfo.fiscalYearEnd }
  ];

  const currentTaxYear = workingContainer?.taxYear ?? 0;
  const columnYears = [
    currentTaxYear,
    currentTaxYear - 1,
    currentTaxYear - 2,
    currentTaxYear - 3,
    currentTaxYear - 4,
    currentTaxYear - 5
  ];

  const testedPartyId = pba?.testedParty?.testedPartyId;
  const financialTableTitle = t(`analysis:balance-sheet`);
  useEffect(() => {
    if (!pba) {
      dispatch(selectCurrentPBA);
    }

    if (testedPartyId && pba) {
      dispatch(
        fetchTestedPartyFinancials({
          testedPartyId,
          financialTypeId: FinancialAreas.ByName.BalanceSheet.Id
        })
      );
    }
  }, [dispatch, pba, testedPartyId]);

  useEffect(() => {
    if (!financialConversions) {
      dispatch(fetchConversionRates());
    }
  }, [dispatch, testedPartyId, financialConversions]);

  const hasFooter = false;
  const onSaveData = async (data: any) => {
    const perYear: any = {};
    const changedYears = new Set<number>();

    data.forEach((row: any) => {
      columnYears.forEach((year) => {
        perYear[year] = perYear[year] ?? [];
        const value = row[year]?.value ?? null;

        if (row[year]) {
          const payload: any = {
            taxYear: year,
            value,
            financialType: {
              financialTypeId: FinancialTypes.ById[row.id].Id,
              name: FinancialTypes.ById[row.id].Name
            },
            pbaId: pba?.pbaId
          };

          if (featureFlagIsActive('testedPartyPliCalculation')) {
            const hasPreviousData = row[year].data?.testedPartyFinancialDataId;
            if (hasPreviousData) {
              payload.testedPartyFinancialDataId = row[year].data.testedPartyFinancialDataId;
            }

            if (hasPreviousData || payload.value !== null) {
              perYear[year].push(payload);
              if (row[year].changed) {
                changedYears.add(year);
              }
            }
          } else {
            if (row[year].data) {
              payload.testedPartyFinancialDataId = row[year].data.testedPartyFinancialDataId;
            }

            if (row[year].value || row[year].value === '' || row[year].value === 0) {
              perYear[year].push(payload);
              if (row[year].changed) {
                changedYears.add(year);
              }
            }
          }
        }
      });
    });

    const promises: any = [];
    [...changedYears].forEach((year) => {
      if (testedPartyId && perYear[year].length > 0 && workingContainer) {
        promises.push(
          dispatch(
            updateTestedPartyFinancial({
              testedPartyId,
              financialTypeId: FinancialAreas.ByName.BalanceSheet.Id,
              payload: {
                financialData: perYear[year],
                valueEnteredIn: 1,
                container: workingContainer,
                pbaId: pba?.pbaId
              }
            })
          )
        );
      }
    });

    await Promise.all(promises);
    if (testedPartyId && pba) {
      dispatch(
        fetchTestedPartyFinancials({
          testedPartyId,
          financialTypeId: FinancialAreas.ByName.BalanceSheet.Id
        })
      );
    }
  };

  return (
    <Component
      title={financialTableTitle}
      headerInfo={headerInfo}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      hasFooter={hasFooter}
      tableData={balanceSheetData}
      financialFieldIds={FinancialTypes.ByType.BalanceSheet}
      columnYears={columnYears}
      saveData={onSaveData}
    />
  );
};

export default Connector;
