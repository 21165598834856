import { MouseEvent, MouseEventHandler, useState } from 'react';
import { MenuItem, Menu, IconButton, Divider, makeStyles } from '@material-ui/core';

interface MenuItem {
  text: string;
  handleOnClick: React.MouseEventHandler;
  dividerBefore?: boolean;
  dividerAfter?: boolean;
}

export interface CustomIconMenuProps {
  Icon: React.ComponentType;
  menuItems: MenuItem[];
}

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiListItem-root': {
      padding: theme.spacing(1, 2.5),
      fontSize: '0.9rem'
    }
  }
}));

export const CustomIconMenu = ({ menuItems, Icon }: CustomIconMenuProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getOnClick = (handleOnClick: MouseEventHandler) => (event: MouseEvent) => {
    handleOnClick(event);
    handleCloseMenu();
  };

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <Icon />
      </IconButton>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleCloseMenu}
      >
        {menuItems.map((menuItem) => {
          const arr = [
            <MenuItem key={menuItem.text} onClick={getOnClick(menuItem.handleOnClick)}>
              {menuItem.text}
            </MenuItem>
          ];
          if (menuItem.dividerBefore) {
            arr.splice(0, 0, <Divider key={menuItem.text + 'divider'} />);
          }

          if (menuItem.dividerAfter) {
            arr.push(<Divider key={menuItem.text + 'divider2'} />);
          }

          return arr;
        })}
      </Menu>
    </>
  );
};
