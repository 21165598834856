import { createSelector } from 'reselect';
import { selectEntitiesList } from './entities';
import { Entity, AssociatedTransactionsPBA } from '../models';
import { RootState } from '../redux';

export const selectTransactionsList = ({ transactions: { transactions } }: RootState) => transactions;

export const selectTransaction = (transactionId: number | null | undefined) =>
  createSelector(
    selectTransactionsList,
    (transactions) => (transactions ?? []).find((transaction) => transaction.transactionId === transactionId) ?? null
  );

export type EntityWithMetrics = {
  entity: Entity;
  metrics: Record<string, number>;
};

export type TransactionsTradingPairsMap = Record<string, EntityWithMetrics>;

export const selectTransactionTradingPairsByEntityCode = (entity: number) => (
  state: RootState
): TransactionsTradingPairsMap => {
  const transactions = selectTransactionsList(state);
  const requiredTransactionTypes = ['Tangible Goods', 'Intangible Goods', 'Services', 'Loans'];
  const entityMap: Record<string, EntityWithMetrics> = {};
  for (const transaction of transactions ?? []) {
    const currentEntity = transaction.legalEntityTransactions.find((party) => party.entity?.entityId === entity);
    if (currentEntity) {
      const counterEntity = transaction.legalEntityTransactions.find((party) => party.entity?.entityId !== entity);
      if (counterEntity) {
        const counterEntityId: string = counterEntity.entity.code;

        if (entityMap[counterEntityId]) {
          const counterEntityNode = entityMap[counterEntityId];
          counterEntityNode.metrics[transaction.transactionType.name] =
            Number(counterEntityNode.metrics[transaction.transactionType.name]) || 0 + (Number(transaction.value) || 0);
        } else {
          const counterEntityNode = {
            entity: {
              ...counterEntity.entity,
              code: counterEntityId
            },
            metrics: {
              [transaction.transactionType.name]: Number(transaction.value) || 0
            }
          };
          entityMap[counterEntityId] = counterEntityNode;
        }
      }
    }
  }

  for (const processedEntity of Object.values(entityMap)) {
    for (const type of requiredTransactionTypes) {
      processedEntity.metrics[type] = processedEntity.metrics[type] || 0;
    }
  }

  return entityMap;
};

export const selectTransactionsByEntities = createSelector(
  selectTransactionsList,
  selectEntitiesList,
  (transactions, entities) => {
    const groups = new Map();
    if (entities) {
      for (const transaction of transactions ?? []) {
        for (const { entity } of transaction.legalEntityTransactions) {
          if (entity) {
            let entityTransactions = groups.get(entity.entityId);
            // eslint-disable-next-line max-depth
            if (!entityTransactions) {
              entityTransactions = [];
              groups.set(entity.entityId, entityTransactions);
            }

            entityTransactions.push(transaction);
          }
        }
      }
    }

    return groups;
  }
);

export const selectTransactionsByEntity = (entityId?: number) =>
  createSelector(selectTransactionsByEntities, (transactionsByEntities) => transactionsByEntities.get(entityId) ?? []);

export const selectTransactionsTotalByEntity = (entityId?: number) =>
  createSelector(selectTransactionsByEntity(entityId), (entityTransactions) => {
    let total = 0;
    for (const { value } of entityTransactions) {
      total += value;
    }

    return total;
  });

export const selectTransactionTypes = ({ transactions: { transactionTypes } }: RootState) => transactionTypes;

export const selectPartyRoles = ({ transactions: { partyRoles } }: RootState) => partyRoles;

export const selectServiceEvaluation = (transactionId?: number) =>
  createSelector(
    (state: RootState) => state.transactions.serviceEvaluationByTransactionId,
    (byId) => (transactionId ? byId[transactionId] : undefined)
  );

export const selectTransactionByPbaId = (pbaId: number | undefined) => (
  state: RootState
): AssociatedTransactionsPBA[] | null => {
  const transactions = selectTransactionsList(state);
  if (transactions === null) return null;
  const transactionPBA: AssociatedTransactionsPBA[] = [] as AssociatedTransactionsPBA[];
  for (const transaction of transactions ?? []) {
    const currentPba: any = ((transaction.pbas ?? []).find((pba) => pba?.pbaId === pbaId) as unknown) as any | null;
    if (currentPba) {
      const associatedTransactionsPBA: AssociatedTransactionsPBA = {
        transactionId: transaction.transactionId,
        legalEntityTransactions: transaction.legalEntityTransactions,
        identifier: transaction.identifier,
        transactionType: transaction.transactionType,
        propertyTransferred: transaction.propertyTransferred,
        transferDate: transaction.transferDate,
        value: transaction.value
      };
      transactionPBA.push(associatedTransactionsPBA);
    }
  }

  return transactionPBA;
};

export const selectTransactionSelected = ({ transactions: { transactionSelected } }: RootState) => transactionSelected;
