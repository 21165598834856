import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, IconButton, makeStyles, Typography } from '@material-ui/core';
import { EntitiesMenu } from './EntitiesMenu';
import { CompletionLabel, SearchAndSort, SearchBox, SortOrder } from '../../../components';
import { filterData, sortData } from '../../../services/filtering';
import { body2 } from '../../../styles/typography';
import { ArrowMove } from '../../../svgs';
import { parseAndFormatForDisplay } from '../../../utils/dates';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
import { Table } from '../../Table';
import { EntitiesSortBy, EntitiesProps } from '../Entities.proptype';

interface EntitiesTableProps extends EntitiesProps {
  onEdit: (entityId: number) => void;
}

const useStyles = makeStyles((theme) => ({
  upeRow: {
    '& .MuiTableCell-body, & .MuiTableCell-root.MuiTableCell-body': {
      backgroundColor: theme.palette.background.default,
      borderBottomColor: theme.palette.primary.main,
      left: 0,
      position: 'sticky',
      top: 61, // height of our table header
      zIndex: 1
    },
    '& .MuiTableCell-root.MuiTableCell-body:first-child': {
      zIndex: 2
    }
  }
}));

export const EntitiesTable = ({
  entities,
  upeId,
  transactionsByEntityId,
  countries,
  containerId,
  onEdit,
  onNavigate,
  onDelete
}: EntitiesTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sortObject, setSort] = useState<{ sortBy?: EntitiesSortBy; sortOrder?: SortOrder }>({});
  const [filterObject, setFilter] = useState<Record<string, string>>({});

  const countryById = useMemo(() => new Map((countries ?? []).map((country) => [country.countryId, country])), [
    countries
  ]);

  const domiciles = useMemo(() => {
    let domicileCharsArr = (entities ?? []).map((entity) => entity?.domicile?.isoCode);
    domicileCharsArr = [...new Set(domicileCharsArr)];
    return domicileCharsArr.sort();
  }, [entities]);

  function orderUPE(data: Array<Record<string, any>>, upeId?: number): Array<Record<string, any>> {
    if (upeId) {
      let upeIndex = 0;
      const upeEntity = data.find((entity: Record<string, any>, index: number) => {
        if (entity.entityId === upeId) {
          upeIndex = index;
          return entity;
        }

        return null;
      });
      if (upeIndex > 0 && upeEntity) {
        data.splice(upeIndex, 1);
      }

      if (upeEntity && data[0].entityId !== upeId) {
        data.unshift(upeEntity);
      }
    }

    return data;
  }

  const displaybleEntities = useMemo(() => {
    return (entities ?? []).map((entity) => {
      const {
        entityId,
        code,
        name,
        domicile,
        taxJurisdiction,
        primaryFunction,
        otherPrimaryFunction,
        fiscalYearEnd,
        completionStatus
      } = entity;

      const isUPE = upeId === entityId;

      return {
        className: isUPE ? classes.upeRow : '',
        entityId,
        code: isUPE ? (
          <span title={code}>
            {code}
            <Box component="span" marginLeft={1}>
              <Chip color="primary" size="small" label={t('entities:label-upe')} />
            </Box>
          </span>
        ) : (
          code
        ),
        name,
        domicile: countryById.get(domicile?.countryId)?.isoCode ?? '',
        taxJurisdiction: countryById.get(taxJurisdiction?.countryId)?.isoCode ?? '',
        primaryFunction: otherPrimaryFunction ?? primaryFunction?.name ?? '',
        fiscalYearEnd: parseAndFormatForDisplay(fiscalYearEnd),
        transactionCount: transactionsByEntityId.get(entityId)?.length ?? 0,
        completed: <CompletionLabel hideMark completed={completionStatus} />,
        toDashboard: (
          <IconButton
            onClick={() => {
              /* eslint-disable camelcase */
              void logGoogleAnalyticsEvent({
                event_category: 'entity_clicks',
                event_label: `Entity Dashboard arrow icon click`,
                container_id: containerId
              });
              onNavigate(`/entities/${entityId}/dashboard`);
            }}
          >
            <ArrowMove pointerEvents="none" />
          </IconButton>
        ),
        menu: <EntitiesMenu {...{ entity, isUPE, onEdit, onDelete, onNavigate, containerId }} />
      };
    });
  }, [t, classes, entities, upeId, transactionsByEntityId, countryById, onNavigate, onEdit, onDelete, containerId]);

  return (
    <Table
      data={orderUPE(sortData(filterData(displaybleEntities, filterObject), sortObject), upeId ?? 0)}
      columns={[
        {
          key: 'code',
          header: (
            <SearchAndSort
              field={t('entities:column-code')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'code', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, code: value });
              }}
            />
          ),
          width: 200
        },
        {
          key: 'name',
          header: (
            <SearchAndSort
              field={t('entities:column-name')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'name', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, name: value });
              }}
            />
          ),
          width: 200
        },
        {
          key: 'domicile',
          header: (
            <SearchBox
              placeholder={t('entities:column-domicile')}
              options={domiciles}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => option === value}
              renderOption={(option) => <Typography>{option}</Typography>}
              onChange={(_, data) => {
                setFilter({ ...filterObject, domicile: data ? data : '' });
              }}
            />
          ),
          width: 150
        },
        { key: 'taxJurisdiction', header: t('entities:column-tax-jurisdiction'), width: 150 },
        {
          key: 'primaryFunction',
          header: (
            <SearchAndSort
              field={t('entities:column-primary-function')}
              onSortClicked={(sortOrder) => {
                setSort({ sortBy: 'primaryFunction', sortOrder });
              }}
              onSearchChange={(value) => {
                setFilter({ ...filterObject, primaryFunction: value });
              }}
            />
          ),
          width: 260
        },
        { key: 'fiscalYearEnd', header: t('entities:column-fiscal-year-end'), width: 150 },
        {
          key: 'transactionCount',
          header: t('entities:column-transactions'),
          align: 'right',
          width: 150
        },
        { key: 'completed', header: t('entities:column-status'), width: 150 },
        { key: 'toDashboard', header: '', width: 30 },
        { key: 'menu', header: '', width: 30 }
      ]}
      stickyCols={{
        code: {
          side: 'left',
          position: 0
        },
        completed: {
          side: 'right',
          position: 0
        },
        toDashboard: {
          side: 'right',
          position: 1
        },
        menu: {
          side: 'right',
          position: 2
        }
      }}
      columnSpecificStyles={{
        code: {
          ...body2
        },
        toDashboard: {
          paddingLeft: 0,
          paddingRight: 0
        },
        menu: {
          paddingLeft: 0
        }
      }}
    />
  );
};
