import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { CenteredProgress } from '../../../components';
import tokens from '../../../styles/designTokens';
import { body3, buttonText2, title1 } from '../../../styles/typography';
import { DownloadIconColor } from '../../../svgs';
import { fetchUploadTemplate } from '../../../utils';
import { ImportModalProps } from '../ImportModal.proptype';

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none'
  },
  contentContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  contentBox: {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(2),
    minHeight: '10.563rem',
    minWidth: '43.875rem',
    boxShadow: '0 0 0.188rem 0 rgba(0,0,0,0.12)'
  },
  contentTitle: {
    color: tokens.core3,
    ...title1
  },
  contentLabel: {
    color: tokens.core2,
    ...body3,
    letterSpacing: '0.009rem'
  },
  subContentLabel: {
    ...buttonText2,
    color: tokens.core2,
    paddingBottom: theme.spacing(0.5)
  },
  downloadLabel: {
    color: theme.palette.primary.main,
    ...buttonText2,
    paddingLeft: theme.spacing(1)
  }
}));

interface UploadProps extends Pick<ImportModalProps, 'namespace' | 'loading'> {
  file?: File | null;
  onSelectFile: (file?: File | null) => void;
}

export const Upload = ({ namespace, file, loading, onSelectFile }: UploadProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onTemplateDownload = async () => {
    if (namespace === 'entities') {
      await fetchUploadTemplate(
        'transfer-pricing-entities-import-template-v1.xlsx',
        'Transfer Pricing Entities Import Template.xlsx'
      );
    } else if (namespace === 'transactions') {
      await fetchUploadTemplate(
        'transfer-pricing-transactions-import-template-v1.xlsx',
        'Transfer Pricing Transactions Import Template.xlsx'
      );
    }
  };

  return (
    <Box>
      {loading ? (
        <CenteredProgress />
      ) : (
        <>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>{t(`analysis:auto-template`)}</Typography>
            <Typography className={classes.contentLabel}>
              {`Use the template to import ${namespace} into the current container.`}
            </Typography>
            <Box className={classes.contentContainer}>
              <Typography className={classes.subContentLabel}>{t(`analysis:auto-template-download`)}</Typography>
              <Button color="primary" variant="outlined" component="label" onClick={onTemplateDownload}>
                <DownloadIconColor />
                <Typography className={classes.downloadLabel}>{t(`analysis:download-template`)}</Typography>
              </Button>
            </Box>
          </Box>
          <Box className={classes.contentBox}>
            <Typography className={classes.contentTitle}>{t(`analysis:upload-and-import`)}</Typography>
            <Typography className={classes.contentLabel}>
              {t(`analysis:upload-entitity-or-transaction-template`, { namespace })}
            </Typography>
            <Box className={classes.contentContainer}>
              <Typography className={classes.subContentLabel}>
                {file?.name ?? t('analysis:choose-file-upload')}
              </Typography>
              <Button color="primary" variant="outlined" component="label" disabled={loading}>
                {t(`analysis:import-template-browse`)}
                <input
                  accept=".xls,.xlsx,.xlsm"
                  className={classes.input}
                  data-testid="upload-file"
                  type="file"
                  onChange={(event) => {
                    onSelectFile(event.target.files?.item(0));
                  }}
                />
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
