import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { body3, title3 } from '../../../styles/typography';
import { UncontrolledTransactionAllUncontrolled } from '../../../svgs';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(7),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  },
  text: {
    color: theme.palette.text.secondary
  },
  image: {
    width: theme.spacing(12),
    marginRight: theme.spacing(4),
    color: theme.palette.primary.dark
  }
}));

export const TBAAllUncontrolledNoTransSplashScreen = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.image}>
        <UncontrolledTransactionAllUncontrolled />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="space-evenly">
        <Typography style={{ ...title3 }}>
          {t('analysis:tba-uncontrolled-transactions-splash-screen-all-uncontrolled-trans-title')}
        </Typography>
        <Typography style={{ ...body3 }}>
          {t('analysis:tba-uncontrolled-transactions-splash-screen-all-uncontrolled-trans-subtext')}
        </Typography>
      </Box>
    </Box>
  );
};
