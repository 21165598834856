import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SvgIconTypeMap, Box } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ConnectorProps } from './Header.proptype';
import { ConfirmationModal } from '../../components';
import { PLATFORM_CONTAINER_CHANGED } from '../../constants';
import { getContainers, getWorkingContainer } from '../../redux/baseData';
import {
  resetRollingState,
  getRollingStatus,
  resetRollingStatus,
  setCurrentContainerIdAndTaxYear
} from '../../redux/rollingContainers';
import {
  selectContainerIsRollable,
  selectContainers,
  selectRollingContainerIds,
  selectRollingStatus,
  selectWorkingContainer
} from '../../selectors';
import { getPlatformModule } from '../../services/platform/platform';
import { AppDispatch } from '../../store';
import { SwapIcon } from '../../svgs/';
import { redirectToLogin, handleRoleRedirect } from '../../utils';
import { RollingContainerStatus } from '../CopyContainerModal/CopyContainerModal.proptypes';
import { useNotifications } from '../Notifications';

const Connector = ({ component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { showNotification } = useNotifications();

  const containers = useSelector(selectContainers);
  const workingContainer = useSelector(selectWorkingContainer);
  const rollingStatus = useSelector(selectRollingStatus);
  const rollingContainerIds = useSelector(selectRollingContainerIds);
  const isContainerRollable = useSelector(selectContainerIsRollable);

  const [currentId, setCurrentId] = useState(workingContainer?.containerId);
  const [containerChangedInADifferenTab, setContainerChangedInADifferenTab] = useState(false);
  const [containerSelectedName, setContainerSelectedName] = useState('');
  const [isListentingForPlatformEvents, setIsListentingForPlatformEvents] = useState(false);
  const [isChangeContainerConfirmationOpen, setIsChangeContainerConfirmationOpen] = useState(false);
  const [switchToContainer, setSwitchToContainer] = useState(workingContainer!);
  const [isChangingContainer, setIsChangingContainer] = useState(false);

  const checkRollingStatus = useRef(0) as any;

  const flags = useFlags();

  const shouldShowLegacyUiButton = !flags.forceRedirectToNewUi;

  useEffect(() => {
    if (rollingStatus === RollingContainerStatus.PENDING) {
      checkRollingStatus.current = setInterval(() => {
        if (rollingContainerIds.fromContainerId && rollingContainerIds.toContainerId) {
          void dispatch(
            getRollingStatus({
              parentContainerId: rollingContainerIds.fromContainerId,
              childContainerId: rollingContainerIds.toContainerId
            })
          );
        } else {
          showNotification({ type: 'error', message: t('common:rolling-container-missing-dest-container-id') });
          dispatch(resetRollingStatus());
        }
      }, 5000);
    } else {
      clearInterval(checkRollingStatus.current);
    }

    if (rollingStatus === RollingContainerStatus.SUCCESS) {
      showNotification({ type: 'success', message: t('common:rolling-container-succeed') });

      if (flags.incrementalRollingNewModal) {
        void dispatch(getContainers());
      }

      dispatch(resetRollingStatus());
    }

    if (rollingStatus === RollingContainerStatus.FAILURE) {
      showNotification({ type: 'error', message: t('common:rolling-container-failed') });
      dispatch(resetRollingStatus());
    }

    return () => {
      clearInterval(checkRollingStatus.current);
    };
  }, [
    dispatch,
    currentId,
    rollingStatus,
    showNotification,
    flags.incrementalRollingNewModal,
    rollingContainerIds.toContainerId,
    rollingContainerIds.fromContainerId,
    t
  ]);

  const { Events, Containers } = getPlatformModule();
  if (!isListentingForPlatformEvents) {
    Events.on(PLATFORM_CONTAINER_CHANGED, async (newContainerUUID: string) => {
      const container = containers.find((container) => container.containerUuid === newContainerUUID)!;
      setCurrentId(container.containerId);
      history.push('/');
      setContainerSelectedName(`${container.name} ${container.taxYear}`);
      setContainerChangedInADifferenTab(true);
      await dispatch(getWorkingContainer());
    });
    setIsListentingForPlatformEvents(true);
  }

  const closeContainerChangedNotification = () => {
    setContainerChangedInADifferenTab(false);
  };

  const closeContainerSwitchModal = async (confirmed: boolean) => {
    if (!confirmed) {
      setIsChangeContainerConfirmationOpen(false);
      return;
    }

    setIsChangingContainer(true);

    if (confirmed) {
      void handleContainerChange(switchToContainer.containerId).then(() => {
        setIsChangingContainer(false);
        setIsChangeContainerConfirmationOpen(false);
        dispatch(resetRollingState());
      });
    }
  };

  const handleContainerChange = async (selectedId: number) => {
    const previous = currentId;
    setCurrentId(selectedId);
    history.push('/');
    try {
      const container = containers.find((container) => container.containerId === selectedId)!;
      await Containers.switchContainers(container);
      // Todo - Remove once old UI is deprecated.
      localStorage.setItem('workingContainer', JSON.stringify(container));
      await dispatch(getWorkingContainer());
    } catch {
      setCurrentId(previous);
    }
  };

  const setCurrentContainerId = (containerId: number, taxYear: number) => {
    dispatch(setCurrentContainerIdAndTaxYear({ containerId, taxYear }));
  };

  return (
    <div>
      <Component
        title={flags.tpRebranding === true ? t('exactera-title-app') : t('xbs-title-app')}
        legacyUiText={t('common:action-navigate-to-legacy-ui')}
        shouldShowLegacyUiButton={shouldShowLegacyUiButton}
        containers={containers}
        isContainerRollable={isContainerRollable}
        currentId={currentId}
        goToLegacyUI={() => {
          handleRoleRedirect(`${window.location.origin}/legacy/`);
        }}
        switchToContainer={setSwitchToContainer}
        changeContainerConfirmationOpen={setIsChangeContainerConfirmationOpen}
        setCurrentContainerId={setCurrentContainerId}
        onSignOut={redirectToLogin}
        onLogoClick={() => {
          history.push('/');
        }}
      />
      <ConfirmationModal
        open={containerChangedInADifferenTab}
        title={t('common:alert_switch_container')}
        subtitle=""
        text={`${t('common:alert_switch_container_content')} ${containerSelectedName}`}
        handleClose={closeContainerChangedNotification}
      />
      <ConfirmationModal
        open={isChangeContainerConfirmationOpen}
        title={t('common:switch_container')}
        HeaderIcon={SwapIcon as OverridableComponent<SvgIconTypeMap<Record<string, unknown>>>}
        subtitle=""
        text={
          ((
            <>
              {t('common:switch_to_container_prompt')}&nbsp;
              <Box fontWeight="bold" display="inline">
                {switchToContainer.name} {switchToContainer.taxYear}
              </Box>
              ?
            </>
          ) as unknown) as string
        }
        handleClose={closeContainerSwitchModal}
        isLoading={isChangingContainer}
      />
    </div>
  );
};

export default Connector;
