import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectorProps, CopyContainerModalProps } from './CopyContainerModal.proptypes';
import { getIsRollable, getHistoricalCopyContainerInfo, getRollingForecast } from '../../redux/rollingContainers';
import {
  selectRollingContainerStatus,
  selectWorkingContainer,
  selectHistoricalCopyContainerInfo,
  selectRollingForecast
} from '../../selectors';

const Connector = ({ component: Component, onClose, modalInitialView }: ConnectorProps) => {
  const dispatch = useDispatch();
  const workingContainer = useSelector(selectWorkingContainer);
  const containerId = workingContainer ? workingContainer.containerId : undefined;
  const containerRollStatus = useSelector(selectRollingContainerStatus);
  const rollForwardYear = workingContainer ? workingContainer.taxYear + 1 : 2020;
  const rollBackYear = workingContainer ? workingContainer.taxYear - 1 : 2019;
  const rollingContainerForecast = useSelector(selectRollingForecast);
  const historicalCopyContainerInfo = useSelector(selectHistoricalCopyContainerInfo);

  useEffect(() => {
    if (containerId) {
      dispatch(getIsRollable(containerId));
    }
  }, [containerId, dispatch]);

  useEffect(() => {
    if (containerId && rollForwardYear) {
      dispatch(getRollingForecast({ direction: 'FORWARD', containerId, taxYear: rollForwardYear }));
    }
  }, [containerId, dispatch, rollForwardYear, workingContainer]);

  useEffect(() => {
    if (containerId && rollBackYear) {
      dispatch(getRollingForecast({ direction: 'BACK', containerId, taxYear: rollBackYear }));
    }
  }, [containerId, dispatch, rollBackYear, workingContainer]);

  useEffect(() => {
    dispatch(
      getHistoricalCopyContainerInfo({
        containerId: historicalCopyContainerInfo.currentContainerId,
        taxYear: historicalCopyContainerInfo.currentTaxYear
      })
    );
  }, [dispatch, historicalCopyContainerInfo.currentContainerId, historicalCopyContainerInfo.currentTaxYear]);

  const componentProps: CopyContainerModalProps = {
    historicalCopyContainerInfo,
    canRollForward: containerRollStatus.canRollForward,
    canRollBack: containerRollStatus.canRollBack,
    cantRollBackReason: containerRollStatus.cantRollBackReason,
    cantRollForwardReason: containerRollStatus.cantRollForwardReason,
    container: workingContainer,
    rollingContainerForecast,
    onClose,
    modalInitialView
  };

  return <Component {...componentProps} />;
};

export default Connector;
