import { Box, Paper, makeStyles } from '@material-ui/core';
import { TBAFunctionalAnalysisProps } from './TbaFunctionalAnalysis.proptype';
import { FunctionalAnalysisHeader } from '../../components/FunctionalAnalysis/FunctionalAnalysisHeader';
import { FunctionalAnalysisTable } from '../../components/FunctionalAnalysis/FunctionalAnalysisTable';
import tokens from '../../styles/designTokens';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    background: tokens.neutral80
  },
  rowContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    margin: theme.spacing(2)
  },
  paper: {
    height: '100%',
    backgroundColor: theme.palette.background.default
  }
}));

export const TBAFunctionalAnalysis = ({
  functionalAnalysisCharacteristics,
  editFunctionalAnalysisCharacteristic,
  selectedAnalysis,
  setSelectedAnalysis,
  currentSection,
  setCurrentSection,
  createNewCharacteristic,
  deleteFunctionalAnalysisCharacteristicRow,
  selectedContent,
  setIsDrawerOpen,
  isDrawerOpen,
  trackGATime
}: TBAFunctionalAnalysisProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.rowContainer}>
        <Paper className={classes.paper}>
          <FunctionalAnalysisHeader
            selectedContent={selectedContent}
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
            trackGATime={trackGATime}
          />
          <FunctionalAnalysisTable
            functionalAnalysisCharacteristics={functionalAnalysisCharacteristics}
            editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
            selectedAnalysis={selectedAnalysis}
            setSelectedAnalysis={setSelectedAnalysis}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            createNewCharacteristic={createNewCharacteristic}
            deleteFunctionalAnalysisCharacteristicRow={deleteFunctionalAnalysisCharacteristicRow}
          />
        </Paper>
      </Box>
    </Box>
  );
};
