import { MethodEvaluation, ProfitBasedEvaluation, TransactionBasedEvaluation, TransactionTypeEnum } from '../models';

// Conventional names returned by API, independently of any jurisdiction
const gsm = 'Gross Services Margin Method';
const cusp = 'Comparable Uncontrolled Services Price Method';
const cp = 'Cost Plus Method';
const cutCup = 'CUT/CUP';
const other = 'Other';
const rp = 'Resale Price Method';
const sc = 'Services Cost Method';
const tnmmCpm = 'TNMM/CPM';

const nameMapping: Record<string, Record<string, string | Record<string, string>>> = {
  USA: {
    general: {
      [tnmmCpm]: 'CPM',
      [cusp]: 'CUSP',
      [gsm]: 'GSMM',
      [cp]: 'Cost of Services Plus Method'
    },
    [TransactionTypeEnum.IntangibleGoods]: {
      [cutCup]: 'CUT'
    }
  },
  PRI: {
    general: {
      [tnmmCpm]: 'CPM',
      [cusp]: 'CUSP',
      [gsm]: 'GSMM',
      [cp]: 'Cost of Services Plus Method'
    },
    [TransactionTypeEnum.IntangibleGoods]: {
      [cutCup]: 'CUT'
    }
  },
  TWN: {
    general: {
      [tnmmCpm]: 'CPM'
    }
  },
  general: {
    [cutCup]: 'CUP',
    [tnmmCpm]: 'TNMM'
  }
};

export type NotApplicableMethods = Record<string, { [key: number]: string[] }>;

export const notApplicableMethods: NotApplicableMethods = {
  USA: {
    [TransactionTypeEnum.TangibleGoods]: [cusp, gsm, sc, other],
    [TransactionTypeEnum.IntangibleGoods]: [cusp, rp, gsm, cp, sc, other],
    // cutCup & rp authorized as per https://crossbordersolutions.atlassian.net/browse/TPIQ-2626
    [TransactionTypeEnum.Services]: [/* cutCup, rp, */ other],
    [TransactionTypeEnum.Loans]: [cusp, rp, gsm, cp, sc, other]
  },
  general: {
    [TransactionTypeEnum.TangibleGoods]: [cusp, gsm, sc],
    [TransactionTypeEnum.IntangibleGoods]: [cusp, gsm, /* cp, */ sc],
    [TransactionTypeEnum.Services]: [cusp, gsm, sc],
    [TransactionTypeEnum.Loans]: [cusp, gsm, /* cp, */ sc]
  }
};

export const getMethodEvaluationName = (isoCode: string, transactionType: number, methodName: string) =>
  (nameMapping[isoCode]?.[transactionType] as Record<string, string>)?.[methodName] ??
  (nameMapping[isoCode]?.general as Record<string, string>)?.[methodName] ??
  nameMapping.general?.[methodName] ??
  methodName;

export const isMethodApplicable = (
  methodName: string,
  transactionType: number,
  isoCode: string,
  notApplicableMethods: NotApplicableMethods
) => !(notApplicableMethods[isoCode] ?? notApplicableMethods.general)[transactionType]?.includes(methodName) ?? true;

export const isAnalysisUsed = (
  analysis: TransactionBasedEvaluation | ProfitBasedEvaluation | null,
  method: MethodEvaluation
) =>
  analysis
    ? 'pba' in analysis
      ? analysis.pba.method === method.evaluationMethodId
      : (analysis.tba.tbaEvaluationMethod.tbaEvaluationMethodId === 1 && method.methodName === cutCup) ||
        (analysis.tba.tbaEvaluationMethod.tbaEvaluationMethodId === 2 && method.methodName === rp) ||
        (analysis.tba.tbaEvaluationMethod.tbaEvaluationMethodId === 3 && method.methodName === cp)
    : false;

export const getAnalysisMethod = (
  analysis: TransactionBasedEvaluation | ProfitBasedEvaluation | null,
  methods: MethodEvaluation[]
) => (analysis ? methods.find((method) => isAnalysisUsed(analysis, method)) : undefined);
