import { useEffect, useRef } from 'react';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import { drawChart } from './d3';
import { Node } from './Node';
// do not use index to break circular dependency
import { CenteredProgress } from '../../CenteredProgress';
import { TreeChartProps, TreeNode } from '../TreeChart.proptype';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    overflow: 'hidden',
    padding: ({ nodeHeight, nodeWidth }: { nodeHeight: number; nodeWidth: number }) =>
      `${nodeHeight / 2}px ${nodeWidth / 2}px`
  },
  container: {
    display: 'inline-block',
    cursor: 'move',
    position: 'relative',
    transformOrigin: 'center'
  }
});

export const TreeChart = <T extends TreeNode>(props: TreeChartProps<T>) => {
  const { data, NodeComponent = Node, nodeHeight = 125, nodeWidth = 150 } = props;
  const svgRef = useRef<SVGSVGElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ nodeHeight, nodeWidth });
  const theme = useTheme();

  useEffect(() => {
    if (svgRef.current && containerRef.current && data) {
      drawChart({
        svg: svgRef.current,
        nodeContainer: containerRef.current,
        nodeHeight: nodeHeight * 1.25,
        nodeWidth: nodeWidth * 1.25,
        data,
        linkColor: theme.palette.primary.light
      });
    }
  }, [data, nodeHeight, nodeWidth, theme]);

  return (
    <Box className={classes.wrapper}>
      {data ? (
        <div ref={containerRef} className={classes.container}>
          <svg ref={svgRef} className={classes.container} />
          {data.map((data) => (
            <NodeComponent {...props} key={data.id} data={data} />
          ))}
        </div>
      ) : (
        <CenteredProgress />
      )}
    </Box>
  );
};
