/* eslint-disable camelcase */
import ReactGA from 'react-ga4';
import { getPlatformModule } from '../services/platform/platform';

export interface GoogleAnalyticsPayload {
  event_category: string;
  event_label: string;
  container_id: number | undefined;
  userUuid?: string;
}

export interface GoogleAnalyticsTimeSpentPayload {
  container_id: number | undefined;
  user_id: string;
  title: string;
  page: string;
  userUuid?: string;
}

export const logGoogleAnalyticsEvent = async (eventInfo: GoogleAnalyticsPayload) => {
  const { IdentityInfo } = await getPlatformModule();
  const session = await IdentityInfo.getIdentitySession();
  const { userUuid } = session;

  const payload = {
    userUuid,
    ...eventInfo
  };

  ReactGA.event(eventInfo.event_category, payload);
};

export const logGoogleAnalyticsTimeSpent = async (eventInfo: GoogleAnalyticsTimeSpentPayload) => {
  const { IdentityInfo } = await getPlatformModule();
  const session = await IdentityInfo.getIdentitySession();
  const { userUuid } = session;

  const payload = {
    userUuid,
    hitType: 'pageview',
    ...eventInfo
  };

  ReactGA.send(payload);
};
