/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Paper, Grid, makeStyles, TextField, Typography, Button } from '@material-ui/core';
import { ContentLayout, Editor } from '../../../components';
import editAccess from '../../../components/HigherOrderComponents/EditAccess';
import { saveTbaFinancialInfoParams } from '../../../redux/transactionBasedAnalyses';
import { selectWorkingContainer } from '../../../selectors';
import tokens from '../../../styles/designTokens';
import { body1, body2, body4, title3 } from '../../../styles/typography';
import { hasEditAccess } from '../../../utils';
import useDebounce from '../../../utils/debounce';
import {
  FinancialInfoErrorsType,
  financialInfoFieldTypesMap,
  FinancialInfoFieldValues,
  FinancialInfoValues
} from '../../NewAnalysisModal/CreateTBAModal.proptypes';
import { AssociatedTransactionsTable } from '../../PBADashboardTPDSummary/components/AssociatedTransactionsTable';
import { TbaAssociateTransactionModal } from '../../TbaAssociateTransactionModal';
import {
  TbaTransactionOverviewFormDataInputs,
  TbaTransactionOverviewFormSearchStrategyInputs,
  TbaTransactionOverviewProps
} from '../TbaTransactionOverview.proptype';

export const financialInfoDefaultValues: FinancialInfoValues = {
  'COGS (related) CP': '',
  'COGS (unrelated)': '',
  'Unit Price': '',
  Units: '',
  'Transaction Amount': '',
  Royalty: '',
  Interest: '',
  Service: '',
  'Resale Price': ''
};

const financialInfoHasError = (value?: string): boolean => {
  if (!value) {
    return true;
  }

  const regex = /[\s$%,]/gi;
  const trimmedString: string = value.replace(regex, '');
  if (trimmedString.length === 0) return true;
  const trimmedValue = Number(value.replace(regex, ''));
  if (Number.isNaN(trimmedValue)) return true;
  return false;
};

const useStyles = makeStyles((theme) => ({
  formGroupContainer: {
    padding: theme.spacing(3)
  },
  transactionOverviewFormContainer: {
    backgroundColor: theme.palette.common.white
  },
  titleContainer: {
    ...title3,
    color: tokens.core3,
    paddingLeft: 0,
    marginBottom: theme.spacing(2.5)
  },
  label: {
    ...body2,
    color: tokens.core2
  },
  labelEditor: {
    ...body4,
    color: tokens.core1,
    marginBottom: theme.spacing(0.5)
  },
  labelEditorDescription: {
    ...body1,
    color: tokens.core2,
    marginBottom: theme.spacing(1)
  },
  input: {
    ...body1,
    color: tokens.core1
  },
  disabledInput: {
    backgroundColor: theme.palette.grey[100]
  },
  inputsSize: {
    width: '22rem',
    '& > *': {
      width: '100%'
    }
  },
  inputsHalfSize: {
    width: '11rem',
    '& > *': {
      width: '100%'
    }
  },
  inlineGroup: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2)
  },
  separated: {
    marginLeft: theme.spacing(2)
  },
  transactionsContainer: {
    marginTop: '1.375rem',
    backgroundColor: theme.palette.common.white
  },
  tableContainer: {
    padding: theme.spacing(2)
  },
  titleModalClass: {
    display: 'inline-flex',
    float: 'left'
  },
  titleClass: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.light,
    margin: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '10px',
    padding: theme.spacing(1)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    width: '100%',
    whiteSpace: 'nowrap'
  },
  placeHolder: {
    color: theme.palette.text.secondary
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  dropdownClass: {
    float: 'right'
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const TransactionOverviewForm = ({
  tba,
  tbaEvaluationMethodTypes,
  tbaSearchStrategy,
  tbaTransactions,
  onSubmitTBAData,
  onSubmitTBASearchStrategy,
  onDeleteAssociatedTransactionFromTBA,
  onSubmitFinancialInfo,
  study,
  transactions,
  upeCurrency,
  tbaParameters,
  onSubmitAssociatedTransactions: onSubmit
}: TbaTransactionOverviewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);
  const { isoCode } = upeCurrency;

  const [financialInfoErrors, setFinancialInfoErrors] = useState<FinancialInfoErrorsType>({});
  const [financialInfoValues, setFinancialInfoValues] = useState<FinancialInfoValues>({});
  const [financialInfoFieldTypes, setFinancialInfoFieldTypes] = useState<FinancialInfoFieldValues[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const isFinancialInfoDisabled =
    tba?.tbaEvaluationMethod?.name === 'CUT/CUP' && tba?.transactionType.name === 'Tangible Goods';

  const defaultValuesTBAData: TbaTransactionOverviewFormDataInputs = {
    name: tba?.name,
    primaryEntity: tba?.primaryLegalEntity.name,
    tbaMethod: tba?.tbaEvaluationMethod.name,
    transactionType: tba?.transactionType.name
  };

  const defaultValuesSearchStrategy: TbaTransactionOverviewFormSearchStrategyInputs = {
    searchStrategy: tbaSearchStrategy ?? ''
  };

  const formMethodsTBAData = useForm<TbaTransactionOverviewFormDataInputs>({
    defaultValues: defaultValuesTBAData
  });

  const formMethodsSearchStrategy = useForm<TbaTransactionOverviewFormSearchStrategyInputs>({
    defaultValues: defaultValuesSearchStrategy
  });

  const { handleSubmit: handleSubmitTBAData, control: controlTBAData, errors: errorsTBAData } = formMethodsTBAData;
  const {
    handleSubmit: handleSubmitSearchStrategy,
    control: controlSearchStrategy,
    errors: errorsSearchStrategy
  } = formMethodsSearchStrategy;

  const [handleAutoSaveTbaData] = useDebounce((formData: TbaTransactionOverviewFormDataInputs) => {
    if (Object.keys(errorsTBAData).length === 0) {
      void onSubmitTBAData(formData);
    }
  }, 3000);

  const [handleEditorAutoSaveSearchStrategy] = useDebounce(async () => {
    if (Object.keys(errorsSearchStrategy).length === 0) {
      void handleSubmitSearchStrategy(onSubmitTBASearchStrategy)();
    }
  }, 100);

  const [handleEditorAutoSaveFinancialInfo] = useDebounce(async (financialInfoValues: FinancialInfoValues) => {
    onSubmitFinancialInfo(financialInfoValues);
  }, 3000);

  const tbaMethodOptions = tbaEvaluationMethodTypes.map((evaluationMethod) => ({
    title: evaluationMethod.name,
    value: evaluationMethod.tbaEvaluationMethodId
  }));

  tbaMethodOptions.sort((option1, option2) => (option1.title > option2.title ? 1 : -1));

  useEffect(() => {
    setFinancialInfoValues({});
    tbaParameters?.forEach((tbaParameter) => {
      financialInfoDefaultValues[tbaParameter.tbaParameter.type] = String(tbaParameter.parameterValue);
    });
    const tbaMethod = tba?.tbaEvaluationMethod.name;
    const tbaTransactionType = tba?.transactionType.name;
    if (isFinancialInfoDisabled) {
      const fields: FinancialInfoFieldValues[] = financialInfoFieldTypesMap[tbaMethod]![tbaTransactionType] ?? [];
      setFinancialInfoFieldTypes(fields);
      const calculatedInfo = {
        Units: 0,
        'Transaction Amount': 0
      };
      tbaTransactions?.forEach((tbaTransaction) => {
        calculatedInfo.Units += tbaTransaction?.units ? tbaTransaction.units : 0;
        calculatedInfo['Transaction Amount'] += tbaTransaction?.value ? tbaTransaction.value : 0;
      });
      financialInfoDefaultValues['Transaction Amount'] = String(calculatedInfo['Transaction Amount']);
      financialInfoDefaultValues.Units = String(calculatedInfo.Units);
      const newFinancialInfoValues: FinancialInfoValues = {};
      fields.forEach((fieldName) => {
        newFinancialInfoValues[fieldName] = financialInfoDefaultValues[fieldName];
      });
      setFinancialInfoValues(newFinancialInfoValues);
    } else if (tbaMethod && tbaTransactionType && financialInfoFieldTypesMap[tbaMethod]![tbaTransactionType]) {
      const fields: FinancialInfoFieldValues[] = financialInfoFieldTypesMap[tbaMethod]![tbaTransactionType] ?? [];
      setFinancialInfoFieldTypes(fields);
      const newFinancialInfoValues: FinancialInfoValues = {};
      fields.forEach((fieldName) => {
        newFinancialInfoValues[fieldName] = financialInfoDefaultValues[fieldName];
      });
      setFinancialInfoValues(newFinancialInfoValues);
    } else {
      setFinancialInfoFieldTypes([]);
    }
  }, [tba, tbaParameters, isFinancialInfoDisabled, tbaTransactions]);

  const onFinancialInfoChange = (
    item: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    eventType: 'change' | 'blur'
  ) => {
    const { name, value } = item.target;
    const newFinancialInfoValues: FinancialInfoValues = { ...financialInfoValues };
    newFinancialInfoValues[name as FinancialInfoFieldValues] = value;
    setFinancialInfoValues(newFinancialInfoValues);

    const tbaMethod = tba?.tbaEvaluationMethod.name;
    const tbaTransactionType = tba?.transactionType.name;
    if (tbaMethod && tbaTransactionType && financialInfoFieldTypesMap[tbaMethod]![tbaTransactionType]) {
      const fields = financialInfoFieldTypesMap[tbaMethod]![tbaTransactionType] ?? [];
      const newFinancialInfoErrors = { ...financialInfoErrors };
      fields.forEach((field) => {
        newFinancialInfoErrors[field] = financialInfoHasError(newFinancialInfoValues[field]);
      });
      setFinancialInfoErrors(newFinancialInfoErrors);
      if (
        Object.values(newFinancialInfoErrors).filter((financialInfoError) => financialInfoError === true).length === 0
      ) {
        if (eventType === 'change') handleEditorAutoSaveFinancialInfo(newFinancialInfoValues);
        else onSubmitFinancialInfo(newFinancialInfoValues);
      }
    }
  };

  const financialInfoFormMethods = useForm({
    defaultValues: { name: '' }
  });

  const { control: financialInfoControl } = financialInfoFormMethods;

  const financialInfoComponents = () => (
    <>
      {financialInfoFieldTypes.length > 0 && (
        <Box className={classes.inlineGroup}>
          {financialInfoFieldTypes.slice(0, 2).map((fieldName: FinancialInfoFieldValues, index: number) => (
            <Box
              key={fieldName}
              className={`${index % 2 === 0 ? '' : classes.separated} ${
                financialInfoFieldTypes.slice(0, 2).length === 2 ? classes.inputsSize : classes.inputsHalfSize
              }`}
            >
              <Typography className={classes.label}>
                {t(`analysis:label-tba-financial-info-${fieldName}`, { isoCode })}
              </Typography>
              <Controller
                name={fieldName}
                render={({ onChange }) => (
                  <TextField
                    disabled={isFinancialInfoDisabled || !hasEditAccess()}
                    className={`${classes.input} ${isFinancialInfoDisabled ? classes.disabledInput : ''}`}
                    id={fieldName}
                    name={fieldName}
                    margin="dense"
                    variant="outlined"
                    value={financialInfoValues[fieldName]}
                    placeholder={t(`analysis:label-tba-financial-info-${fieldName}`, { isoCode })}
                    onChange={(item) => {
                      onFinancialInfoChange(item, 'change');
                      onChange(item);
                    }}
                    onBlur={(item) => onFinancialInfoChange(item, 'blur')}
                  />
                )}
                control={financialInfoControl}
                defaultValue={financialInfoDefaultValues[fieldName]}
                rules={{ required: true }}
                error={Boolean(financialInfoErrors[fieldName])}
              />
              <ErrorMessage
                error={Boolean(financialInfoErrors[fieldName])}
                message={t('analysis:invalid-entry-required-element-financial-info-tba')}
              />
            </Box>
          ))}
        </Box>
      )}
      {financialInfoFieldTypes.length > 2 && (
        <Box className={classes.inlineGroup}>
          {financialInfoFieldTypes.slice(2).map((fieldName: FinancialInfoFieldValues, index: number) => (
            <Box key={fieldName} className={`${index % 2 === 0 ? '' : classes.separated} ${classes.inputsHalfSize}`}>
              <Typography className={classes.label}>
                {t(`analysis:label-tba-financial-info-${fieldName}`, { isoCode })}
              </Typography>
              <Controller
                name={fieldName}
                render={({ onChange }) => (
                  <TextField
                    disabled={isFinancialInfoDisabled}
                    className={`${classes.input} ${isFinancialInfoDisabled ? classes.disabledInput : ''}`}
                    id={fieldName}
                    name={fieldName}
                    margin="dense"
                    variant="outlined"
                    value={financialInfoValues[fieldName]}
                    placeholder={t(`analysis:label-tba-financial-info-${fieldName}`, { isoCode })}
                    onChange={(item) => {
                      onFinancialInfoChange(item, 'change');
                      onChange(item);
                    }}
                    onBlur={(item) => onFinancialInfoChange(item, 'blur')}
                  />
                )}
                control={financialInfoControl}
                defaultValue={financialInfoDefaultValues[fieldName]}
                rules={{ required: true }}
                error={Boolean(financialInfoErrors[fieldName])}
              />
              <ErrorMessage
                error={Boolean(financialInfoErrors[fieldName])}
                message={t('analysis:invalid-entry-required-element-financial-info-tba')}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <>
      <Paper className={classes.transactionOverviewFormContainer}>
        <form onSubmit={handleSubmitTBAData(onSubmitTBAData)}>
          <Grid container spacing={3} className={classes.formGroupContainer}>
            <Grid item xs={6}>
              <Box className={classes.titleContainer}>
                <span>
                  <Box>
                    <Typography className={classes.titleContainer}>
                      {t('analysis:title-transaction-overview')}
                    </Typography>
                  </Box>
                </span>
              </Box>
              {tba ? (
                <Grid item>
                  <Box className={classes.inlineGroup}>
                    <Box className={classes.inputsSize}>
                      <Typography className={classes.label}>{t('analysis:column-tba-name')}</Typography>
                      <Controller
                        name="name"
                        render={({ onChange, value }) => (
                          <TextField
                            id="name"
                            name="name"
                            className={classes.input}
                            margin="dense"
                            variant="outlined"
                            value={value}
                            placeholder={t('analysis:column-tba-name')}
                            disabled={!hasEditAccess()}
                            onChange={(event) => {
                              onChange(event.target.value);
                              handleAutoSaveTbaData({ ...defaultValuesTBAData, name: event.target.value });
                            }}
                            onBlur={async () => handleSubmitTBAData(onSubmitTBAData)()}
                          />
                        )}
                        control={controlTBAData}
                        defaultValue={defaultValuesTBAData.name}
                        rules={{ required: true, minLength: 3, min: 3 }}
                        error={Boolean(errorsTBAData.name)}
                      />
                      <ErrorMessage
                        error={Boolean(errorsTBAData.name)}
                        message={t('analysis:invalid-entry-required-tba-name')}
                      />
                    </Box>
                    <Box className={`${classes.separated} ${classes.inputsSize}`}>
                      <Typography className={classes.label}>{t('analysis:column-primary-entity')}</Typography>
                      <Controller
                        name="primaryEntity"
                        render={({ onChange, value }) => (
                          <TextField
                            disabled
                            style={{ ...body1, color: tokens.core1 }}
                            className={`${classes.input} ${classes.disabledInput}`}
                            id="primaryEntity"
                            name="primaryEntity"
                            margin="dense"
                            variant="outlined"
                            value={value}
                            placeholder={t('analysis:column-primary-entity')}
                            onChange={onChange}
                            onBlur={async () => handleSubmitTBAData(onSubmitTBAData)()}
                          />
                        )}
                        control={controlTBAData}
                        defaultValue={defaultValuesTBAData.primaryEntity}
                        rules={{ required: true }}
                        error={Boolean(errorsTBAData.primaryEntity)}
                      />
                      <ErrorMessage
                        error={Boolean(errorsTBAData.primaryEntity)}
                        message={t('analysis:invalid-entry-required-tba-primary-entity')}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.inlineGroup}>
                    <Box className={classes.inputsSize}>
                      <Typography className={classes.label}>{t('analysis:column-tba-method')}</Typography>
                      <Controller
                        name="tbaMethod"
                        render={({ onChange, value }) => (
                          <TextField
                            disabled
                            className={`${classes.input} ${classes.disabledInput}`}
                            id="tbaMethod"
                            name="tbaMethod"
                            margin="dense"
                            variant="outlined"
                            value={value}
                            placeholder={t('analysis:column-tba-method')}
                            onChange={onChange}
                            onBlur={async () => handleSubmitTBAData(onSubmitTBAData)()}
                          />
                        )}
                        control={controlTBAData}
                        defaultValue={defaultValuesTBAData.tbaMethod}
                        rules={{ required: true }}
                        error={Boolean(errorsTBAData.tbaMethod)}
                      />
                      <ErrorMessage
                        error={Boolean(errorsTBAData.tbaMethod)}
                        message={t('analysis:invalid-entry-required-tba-method')}
                      />
                    </Box>
                    <Box className={`${classes.separated} ${classes.inputsSize}`}>
                      <Typography className={classes.label}>{t('analysis:column-tba-transaction-type')}</Typography>
                      <Controller
                        name="transactionType"
                        render={({ onChange, value }) => (
                          <TextField
                            disabled
                            className={`${classes.input} ${classes.disabledInput}`}
                            id="transactionType"
                            name="transactionType"
                            margin="dense"
                            variant="outlined"
                            value={value}
                            placeholder={t('analysis:column-tba-transaction-type')}
                            onChange={onChange}
                            onBlur={async () => handleSubmitTBAData(onSubmitTBAData)()}
                          />
                        )}
                        control={controlTBAData}
                        defaultValue={defaultValuesTBAData.transactionType}
                        rules={{ required: true }}
                        error={Boolean(errorsTBAData.transactionType)}
                      />
                      <ErrorMessage
                        error={Boolean(errorsTBAData.transactionType)}
                        message={t('analysis:invalid-entry-required-tba-transaction-type')}
                      />
                    </Box>
                  </Box>
                  {tbaTransactions?.length! > 0 ? financialInfoComponents() : null}
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.labelEditor}>{t('analysis:column-tba-search-strategy')}</Typography>
                <Typography className={classes.labelEditorDescription}>
                  {t('analysis:column-tba-search-strategy-description')}
                </Typography>
                <Controller
                  name="searchStrategy"
                  render={({ onChange, value }) => (
                    <Editor
                      theme="TransferPricing"
                      id="searchStrategy"
                      init={{ height: '250' }}
                      value={value || tbaSearchStrategy}
                      error={Boolean(errorsSearchStrategy.searchStrategy)}
                      disabled={!hasEditAccess()}
                      onEditorChange={(content) => {
                        onChange(content);
                        handleEditorAutoSaveSearchStrategy();
                      }}
                      onBlur={async () => handleSubmitSearchStrategy(onSubmitTBASearchStrategy)()}
                    />
                  )}
                  control={controlSearchStrategy}
                  rules={{ required: false }}
                  defaultValue={defaultValuesSearchStrategy.searchStrategy}
                  error={Boolean(errorsSearchStrategy.searchStrategy)}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper className={classes.transactionsContainer}>
        <Grid container spacing={3} className={classes.tableContainer}>
          <Grid item xs={12}>
            <ContentLayout
              withoutPadding
              title={
                <>
                  {t('analysis:title-associated-transactions')}
                  <span className={classes.titleClass}>{tbaTransactions?.length}</span>
                </>
              }
              actions={
                <Box className={classes.buttonContainer}>
                  {editAccess(
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setShowModal(true);
                      }}
                    >
                      {t('transactions:action-add-choice')}
                    </Button>
                  )}
                </Box>
              }
              main={
                <AssociatedTransactionsTable
                  data={tbaTransactions}
                  upeCurrency={upeCurrency}
                  analysisType="TBA"
                  tba={tba}
                  rangeValue={tbaParameters}
                  onDelete={(transactionId) => {
                    const payload: saveTbaFinancialInfoParams = {
                      tbaId: tba.tbaId,
                      addTransactionIds: [],
                      container: workingContainer,
                      removeTransactionIds: [transactionId],
                      tbaControlledParameters:
                        !isFinancialInfoDisabled && tbaParameters && tbaParameters.length > 0 ? tbaParameters : []
                    };
                    onDeleteAssociatedTransactionFromTBA(payload);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </Paper>
      {showModal && (
        <TbaAssociateTransactionModal
          study={study}
          transactions={transactions}
          upeCurrency={upeCurrency}
          selectedTba={tba}
          tbaParameters={tbaParameters}
          onClose={() => {
            setShowModal(false);
          }}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
