import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.warning.light,
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.warning.dark,
    display: 'flex',
    gap: theme.spacing(0.5),
    justifyContent: 'center',
    textTransform: 'uppercase',

    '&[aria-checked="true"]': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.dark
    }
  },
  short: {
    padding: theme.spacing(0, 1)
  },
  tall: {
    padding: theme.spacing(0.5, 1)
  }
}));

export interface CompletionLabelProps {
  completed: boolean;
  label?: ReactNode;
  hideMark?: boolean;
  size?: 'short' | 'tall';
}

export const CompletionLabel = ({ completed, label, hideMark, ...props }: CompletionLabelProps) => {
  const { t } = useTranslation();
  const size = props.size ?? 'tall';
  return (
    <Box className={`${useStyles().root} ${useStyles()[size]}`} role="checkbox" aria-checked={completed}>
      {completed && !hideMark && <CheckCircleOutline fontSize="small" />}
      {label ?? t(completed ? 'label-complete' : 'label-in-progress')}
    </Box>
  );
};
