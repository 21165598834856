import React from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { TradingPairTransactionTypeMetrics } from '.';
import DashboardCardEntityTradingPairsSelectionLinearProgress from './DashboardCardEntityTradingPairsSelectionLinearProgress';
import { currencyValueFormatter } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  wrapperCategory: {
    margin: theme.spacing(1.4, 0)
  },
  wrapperProgress: {
    display: 'flex'
  },
  menuCaption: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    fontSize: '0.9rem'
  },
  categoryText: {
    ...theme.typography.caption,
    fontSize: '0.9rem',
    fontWeight: 600
  }
}));

const TradingPairTransactionType = ({ type, value, percent, upeCurrency }: TradingPairTransactionTypeMetrics) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const formatTradingValueToDisplayString = (value: number, t: TFunction): string => {
    if (value === 0) {
      return t('label-not-applicable');
    }

    if (value < 1000) {
      return new Intl.NumberFormat('en', currencyValueFormatter(2, 2, upeCurrency)).format(value);
    }

    if (value < 1000000) {
      return `${String(
        new Intl.NumberFormat('en', currencyValueFormatter(0, 0, upeCurrency)).format(Math.floor(value / 1000))
      )}K`;
    }

    if (value < 1000000000) {
      return `${String(
        new Intl.NumberFormat('en', currencyValueFormatter(0, 0, upeCurrency)).format(Math.floor(value / 1000000))
      )}M`;
    }

    return `${String(
      new Intl.NumberFormat('en', currencyValueFormatter(0, 0, upeCurrency)).format(Math.floor(value / 1000000000))
    )}B`;
  };

  return (
    <div className={classes.wrapperCategory}>
      <Typography className={classes.menuCaption}>{t(`transactions:type-${type}`)}</Typography>
      <div className={classes.wrapperProgress}>
        <Typography className={classes.categoryText}>{formatTradingValueToDisplayString(value, t)}</Typography>
        <DashboardCardEntityTradingPairsSelectionLinearProgress variant="determinate" value={percent} />
      </div>
    </div>
  );
};

export default TradingPairTransactionType;
