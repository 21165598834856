import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles, Paper } from '@material-ui/core';
import { TransactionDescription } from './TransactionDescription';
import { CenteredProgress } from '../../../components';
import { NoteSlider } from '../../NoteSlider';
import { TransactionEvaluationProps } from '../TransactionEvaluation.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '65vh',
    background: theme.palette.common.white
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(1, 1.5)
  },
  container: {
    color: theme.palette.text.secondary,
    display: 'flex',
    gap: theme.spacing(4),
    padding: theme.spacing(0, 1.5)
  },
  title: {
    flexGrow: 1
  }
}));

export const TransactionEvaluation = ({
  sourceEntity,
  destinationEntity,
  data,
  onSave,
  onAutoSave
}: TransactionEvaluationProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openNote, setOpenNote] = useState(false);
  const [steId, setSteId] = useState(0);
  const [editDestinationEval, setEditDestinationEval] = useState('');
  const [editSourceEval, setEditSourceEval] = useState('');
  const [editNotes, setEditNotes] = useState('');
  const [sourceComplete, setSourceComplete] = useState(false);
  const [destinationComplete, setDestinationComplete] = useState(false);

  useEffect(() => {
    if (data) {
      setSteId(data.serviceTransactionEvaluationId);
      setSourceComplete(data.isSourceComplete);
      setEditSourceEval(data.sourceEvaluation ?? '');
      setDestinationComplete(data.isDestinationComplete);
      setEditDestinationEval(data.destinationEvaluation ?? '');
      setEditNotes(data.notes ?? '');
    }
  }, [data]);

  if (!sourceEntity || !destinationEntity) {
    return <CenteredProgress />;
  }

  const {
    code: sourceEntityCode,
    domicile: { isoCode: sourceEntityIsoCode }
  } = sourceEntity;
  const {
    code: destinationEntityCode,
    domicile: { isoCode: destinationEntityIsoCode }
  } = destinationEntity;

  const handleOpenNote = () => {
    setOpenNote(true);
  };

  const transactionEvaluationPayload = {
    serviceTransactionEvaluationId: steId,
    sourceEvaluation: editSourceEval,
    isSourceComplete: sourceComplete,
    destinationEvaluation: editDestinationEval,
    isDestinationComplete: destinationComplete,
    notes: editNotes
  };

  return (
    <Paper className={classes.root}>
      <Box>
        <Box className={classes.header}>
          <Typography className={classes.title}>{t('transactions:title-evaluation')}</Typography>
        </Box>
        <Box className={classes.container}>
          <TransactionDescription
            title={t('label-name-and-code', { name: sourceEntityCode, isoCode: sourceEntityIsoCode })}
            content={editSourceEval}
            completed={sourceComplete}
            onOpenNote={handleOpenNote}
            onCompletionChange={() => {
              onSave({
                serviceTransactionEvaluationId: steId,
                sourceEvaluation: editSourceEval,
                isSourceComplete: !sourceComplete,
                destinationEvaluation: editDestinationEval,
                isDestinationComplete: destinationComplete,
                notes: editNotes
              });
            }}
            onSaveChange={() => {
              onSave(transactionEvaluationPayload);
            }}
            onAutoSave={() => {
              onAutoSave(transactionEvaluationPayload);
            }}
            onContentChange={(content) => {
              setEditSourceEval(content ?? '');
            }}
          />
          <TransactionDescription
            title={t('label-name-and-code', { name: destinationEntityCode, isoCode: destinationEntityIsoCode })}
            content={editDestinationEval}
            completed={destinationComplete}
            onOpenNote={handleOpenNote}
            onCompletionChange={() => {
              onSave({
                serviceTransactionEvaluationId: steId,
                sourceEvaluation: editSourceEval,
                isSourceComplete: sourceComplete,
                destinationEvaluation: editDestinationEval,
                isDestinationComplete: !destinationComplete,
                notes: editNotes
              });
            }}
            onSaveChange={() => {
              onSave(transactionEvaluationPayload);
            }}
            onAutoSave={() => {
              onAutoSave(transactionEvaluationPayload);
            }}
            onContentChange={(content) => {
              setEditDestinationEval(content ?? '');
            }}
          />
        </Box>
      </Box>
      {openNote && (
        <NoteSlider
          notes={editNotes ?? ''}
          onClose={() => {
            setOpenNote(false);
          }}
          onChange={(inputValue: string) => {
            setEditNotes(inputValue);
          }}
        />
      )}
    </Paper>
  );
};
