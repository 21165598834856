import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch, Theme, makeStyles } from '@material-ui/core';
import tokens from '../../styles/designTokens';
import { captionOverline } from '../../styles/typography';

export interface ProgressButtonProps {
  complete: boolean;
  onChange?: (complete: boolean) => void;
}

const useStyles = makeStyles<Theme, ProgressButtonProps>((theme) => ({
  labelRoot: {
    backgroundColor: ({ complete }) => (complete ? tokens.product15 : theme.palette.warning.light),
    borderRadius: 20,
    color: ({ complete }) => (complete ? tokens.product100 : theme.palette.warning.dark),
    margin: 0,
    padding: theme.spacing(0, 0.8, 0, 0.8),
    textTransform: 'uppercase',
    width: 140,
    height: 30,
    '&:hover': {
      backgroundColor: ({ complete }) => (complete ? tokens.product25 : theme.palette.warning.contrastText)
    }
  },
  label: {
    ...captionOverline,
    padding: theme.spacing(0.1, 0, 0, 0),
    width: '100%',
    textAlign: 'center',
    userSelect: 'none'
  },
  switchRoot: {
    display: 'flex',
    height: 12,
    padding: 0,
    width: 25
  },
  switchBase: {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(13px)',
      '& + .MuiSwitch-track': {
        opacity: 1
      }
    }
  },
  track: {
    backgroundColor: ({ complete }) => (complete ? tokens.product100 : theme.palette.warning.main),
    opacity: 1
  },
  thumb: {
    color: theme.palette.common.white,
    boxShadow: 'none',
    height: 8,
    width: 8
  }
}));

export const ProgressButton = ({ complete, onChange }: ProgressButtonProps) => {
  const { label, labelRoot, switchRoot, ...switchClasses } = useStyles({ complete });
  const { t } = useTranslation();
  return (
    <FormControlLabel
      classes={{ root: labelRoot, label }}
      control={
        <Switch
          classes={{ root: switchRoot, ...switchClasses }}
          checked={complete}
          color={complete ? 'primary' : 'secondary'}
          onChange={(_, checked) => {
            if (onChange) onChange(checked);
          }}
        />
      }
      label={t(complete ? 'label-complete' : 'label-in-progress')}
    />
  );
};
