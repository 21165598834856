import { ButtonItem } from '../../models';

export interface ProfitLevelIndicatorProps {
  testedPartyName: string;
  handleAlignment: (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => void;
  alignment: string | null;
  displayablePliAverages: DisplayablePliAverages[];
  displayablePliValues: DisplayablePliValues[];
  buttonItems: ButtonItem[];
}

export enum PliValuesTypes {
  GrossMargin = 1,
  BerryRatio,
  OperatingMargin,
  RtnonOperAssets,
  CostPlus,
  NetCostPlus
}

export enum PliAveragesTypes {
  Year3Simple = 1,
  Year3Weighted,
  Year3PeriodWeighted,
  Year5Simple,
  Year5Weighted,
  Year5PeriodWeighted
}

export interface PliValues {
  containerId: number;
  testedPartyId: number;
  pliTypeId: number;
  pliAverageTypeId: number;
  taxYear: number;
  shiftedTaxYear: number;
  pliValue: number;
  value: number;
  pliValueType: PliValueType;
  roundedValue?: string | null;
}

export interface PliAverages {
  containerId: number;
  testedPartyId: number;
  pliTypeId: number;
  pliAverageTypeId: number;
  taxYear: number;
  shiftedTaxYear: number;
  pliValue: number;
  value: number;
  pliValueType: PliValueType;
  pliAverageType: PliAverageType;
  roundedValue?: string | null;
}

export interface PliValueType {
  pliValueTypeId: number;
  name: string;
  definition: string;
}

export interface PliAverageType {
  pliAverageTypeId: number;
  name: string;
}

export interface ToggleButton {
  title: string;
  value: string;
}

export interface DisplayablePliAverages {
  Year3PeriodWeighted: string;
  Year3Simple: string;
  Year3Weighted: string;
  Year5PeriodWeighted: string;
  Year5Simple: string;
  Year5Weighted: string;
  name: string;
}

export interface PliAveragesTableProps {
  displayablePliAverages: DisplayablePliAverages[];
}

export interface DisplayablePliValues {
  [key: number]: string | null;
  name: string;
}

export interface PliValuesTableProps {
  displayablePliValues: DisplayablePliValues[];
}
