import { useTranslation } from 'react-i18next';
import { Refresh } from '@material-ui/icons';
import { ConfirmationModal } from '../../../components';

interface ResetModalProps {
  isOpen: boolean;
  name: string;
  jurisdiction: string;
  onClose: (isConfirmed: boolean) => void;
}

export const ResetModal = ({ isOpen, name, jurisdiction, onClose }: ResetModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      open={isOpen}
      HeaderIcon={Refresh}
      title={t('title-sof-reset')}
      subtitle={t('label-sof-reset', { name, jurisdiction })}
      text={t('message-sof-reset-confirm')}
      handleClose={onClose}
    />
  );
};
