import { useEffect, useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import { AutocompleteChangeDetails, AutocompleteProps } from '@material-ui/lab';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import tokens from '../../styles/designTokens';
import { buttonText1, buttonText2 } from '../../styles/typography';

const useStyles = makeStyles(() => ({
  outerStyle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${tokens.neutral100}`,
    borderRadius: '0.25rem',
    width: '100%'
  },
  innerStyle: {
    color: tokens.white,
    border: 'none',
    background: tokens.purpleLight2,
    borderRadius: '0.25rem',
    flexGrow: 2,
    margin: '1px',
    height: '2.25rem',
    overflow: 'hidden'
  },
  emptyStyle: {
    color: tokens.white,
    border: 'none',
    borderRadius: '0.25rem',
    flexGrow: 2,
    margin: '1px',
    height: '2.25rem',
    overflow: 'hidden'
  },
  inputContainerStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  minorItemIconButton: {
    padding: 0,
    margin: 0,
    width: '2.25rem',
    '& .MuiListItemText-root': {
      margin: 0
    },
    '& .MuiSvgIcon-root': {
      height: '1.125rem',
      width: '1.125rem'
    }
  },
  closeIcon: {
    color: tokens.white
  },
  searchIcon: {},
  searchButton: {
    width: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    paddingRight: '0.5rem'
  },
  listBox: {
    width: '100%',
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    top: '2.25rem',
    borderRadius: '0.25rem',
    boxShadow: '0 0 6px 0 rgba(0,0,0,0.29)',
    listStyle: 'none',
    backgroundColor: tokens.white,
    overflow: 'auto',
    maxHeight: '18rem',
    '& li': {
      ...buttonText1,
      display: 'flex',
      alignItems: 'center',
      height: '2.25rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem'
    },
    [`& li.${autocompleteClasses.focused}`]: {
      backgroundColor: tokens.product15,
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: tokens.product15
    }
  },
  inputStyles: {
    ...buttonText2,
    border: 'none',
    margin: 0,
    background: 'inherit',
    width: '80%',
    height: '2.25rem',
    paddingLeft: '0.75rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 2,
    '&:focus-visible': {
      outlineStyle: 'none'
    }
  },
  whiteText: {
    color: tokens.white
  },
  blackText: {
    color: tokens.core1,
    ...tokens.regularFont
  }
}));

export interface FancyDropdownProps<T>
  extends Omit<AutocompleteProps<T, false, false, false>, 'renderInput' | 'popupIcon'> {
  error?: boolean;
}

const FancyDropdown = <T,>({
  getOptionLabel,
  options,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  placeholder = '',
  value,
  disabled
}: FancyDropdownProps<T>) => {
  const classes = useStyles();
  const [openProp, setOpenProp] = useState(false);
  const [controlledValue, setControlledValue] = useState(value);
  const onOpen = () => {
    setOpenProp(true);
  };

  useEffect(() => {
    if (value !== controlledValue) {
      setControlledValue(value);
      setOpenProp(false);
    }
  }, [value, controlledValue]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: 'FancyDropdown-autocomplete',
    options,
    isOptionEqualToValue: (option, value) => (option || value) as any,
    getOptionLabel: (option: T) => (getOptionLabel ? getOptionLabel(option) : 'f'),
    onChange: (event: any, value: any, reason: any, details?: AutocompleteChangeDetails<any> | undefined) => {
      onChange(event, value, reason, details);
      setControlledValue(value);
      setOpenProp(false);
    },
    open: openProp,
    onOpen,
    openOnFocus: true,
    selectOnFocus: true,
    clearOnBlur: true,
    value
  });

  const placeholderToDisplay = () => {
    if (openProp || controlledValue) {
      return;
    }

    return placeholder;
  };

  const innerClasses = openProp || controlledValue ? classes.innerStyle : classes.emptyStyle;
  const inputStylesClasses = controlledValue
    ? `${classes.inputStyles} ${classes.whiteText}`
    : `${classes.inputStyles} ${classes.blackText}`;
  const onBlur = () => {
    setOpenProp(false);
  };

  return (
    <div className={classes.outerStyle}>
      <div className={innerClasses}>
        <div {...getRootProps()}>
          <div className={classes.inputContainerStyle}>
            <input
              className={inputStylesClasses}
              placeholder={placeholderToDisplay()}
              {...getInputProps()}
              disabled={disabled}
              onBlur={onBlur}
            />
            {!disabled && (
              <IconButton
                className={classes.minorItemIconButton}
                onClick={(event) => {
                  onChange(event, null, 'clear');
                  setControlledValue(null);
                  setOpenProp(false);
                }}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            )}
          </div>
        </div>
      </div>
      {!disabled && (
        <div
          className={classes.searchButton}
          onClick={() => {
            setOpenProp(!openProp);
          }}
        >
          <SearchIcon className={classes.searchIcon} />
        </div>
      )}
      {groupedOptions.length > 0 ? (
        <ul className={classes.listBox} {...getListboxProps()}>
          {(groupedOptions as typeof options).map((option: any, index) => {
            const name = getOptionLabel ? getOptionLabel(option) : '';
            return (
              <li key={option.name} {...getOptionProps({ option, index })}>
                {name}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export { FancyDropdown };
