import { createStyles, withStyles, Theme, LinearProgress } from '@material-ui/core';

const TPLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
      flexGrow: 1,
      marginTop: 7,
      marginLeft: theme.spacing(1)
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.text.hint
    }
  })
)(LinearProgress);

export default TPLinearProgress;
