import { Country } from '.';

export enum BackgroundInfoType {
  Entity = 1,
  Transaction,
  MNE,
  UncontrolledTransaction
}

export const CORE_KEY = 'CORE';

export type StatementOfFactsPerJurisdiction = Record<string, BackgroundInfoTopic[]>;

export interface CopyBackgroundInfoSource {
  id: number;
  identifier: string;
  uncontrolled: boolean;
}

export interface BackgroundInfoTopic {
  name: string;
  sortOrder: number;
  objectType: number;
  taxYear: number;
  country: Country | null;
  topicId: number;
  subTopics: BackgroundInfoSubTopic[];
}

export interface BackgroundInfoSubTopic {
  topicId: number;
  topicName: string;
  topicSortOrder: number;
  topicObjectType: BackgroundInfoType;
  topicTaxYear: number;
  backgroundInfoId: number;
  subtopicHeader: string;
  subtopicDiscussionPoints: string;
  subtopicSortOrder: number;
  discussion?: string;
  notes?: string;
  active: number;
  completed: boolean;
}
