import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { TPModal } from '../../../components';
import { CORE_KEY } from '../../../models';
import { TranslationNamespace } from '../StatementOfFactsOverview.proptype';

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '100%',
    fill: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    verticalAlign: 'bottom'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 6)
  },
  infoContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    '& > *': {
      flexGrow: 1
    }
  }
}));

interface AddSubtopicModalProps {
  isOpen: boolean;
  name: string;
  namespace: TranslationNamespace;
  jurisdiction: string;
  topicName: string;
  onClose: () => void;
  onSubmit: (subtopic: { subtopicHeader: string; subtopicDiscussionPoints?: string }) => void;
}

export const AddSubtopicModal = ({
  isOpen,
  name,
  namespace,
  jurisdiction,
  topicName,
  onClose,
  onSubmit
}: AddSubtopicModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { handleSubmit: submitWrapper, control, errors } = useForm();

  return (
    <TPModal
      isOpen={isOpen}
      title={
        <>
          <Add fontSize="large" className={classes.icon} />
          {t('title-add-sof-subtopic')}
        </>
      }
      actions={[
        { name: t('action-cancel'), handler: onClose },
        { name: t('action-load'), variant: 'contained', color: 'primary', handler: submitWrapper(onSubmit) }
      ]}
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Box className={classes.infoContainer}>
          <Box>
            <Typography variant="caption" component="div">
              {t(`${namespace}:label-sof-name`)}
            </Typography>
            <TextField disabled fullWidth margin="dense" variant="outlined" value={name} />
          </Box>
          <Box>
            <Typography variant="caption" component="div">
              {t('title-jurisdiction')}
            </Typography>
            <TextField
              disabled
              fullWidth
              margin="dense"
              variant="outlined"
              value={jurisdiction === CORE_KEY ? t('label-core') : jurisdiction}
            />
          </Box>
          <Box>
            <Typography variant="caption" component="div">
              {t('title-topic')}
            </Typography>
            <TextField disabled fullWidth margin="dense" variant="outlined" value={topicName} />
          </Box>
        </Box>
        <Box width={0.5}>
          <Typography variant="caption" component="div">
            {t('label-add-sof-subtopic-name')}
          </Typography>
          <Controller
            name="subtopicHeader"
            as={<TextField fullWidth margin="dense" variant="outlined" />}
            control={control}
            defaultValue=""
            rules={{ required: true }}
            error={Boolean(errors.subtopicHeader)}
          />
          {errors.subtopicHeader && (
            <Typography color="error" variant="caption" component="div">
              {t('invalid-entry-required-sof-subtopic')}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant="caption" component="div">
            {t('label-add-sof-subtopic-description')}
          </Typography>
          <Controller
            name="subtopicDiscussionPoints"
            as={<TextField fullWidth margin="dense" variant="outlined" />}
            control={control}
            defaultValue=""
          />
        </Box>
      </Box>
    </TPModal>
  );
};
