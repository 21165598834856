import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, Box, Typography, makeStyles, Theme, TextField, Select, MenuItem, FormControl } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { SelectedTransaction } from './CreatePBAModal.proptypes';
import {
  CreateTBAModalProps,
  FinancialInfoValues,
  MethodTypes,
  selectMenuType,
  TBAInputs,
  TransactionTypes
} from './CreateTBAModal.proptypes';
import { FinancialInfoForm } from './FinancialInfoForm';
import { Editor } from '../../components';
import { TransactionItem } from '../../redux/localReports/localReports.proptype';
import { selectWorkingContainer } from '../../selectors';
import tokens from '../../styles/designTokens';
import { body2, title2 } from '../../styles/typography';
import {
  filterTransactionsByTestedPartyAndPrimaryEntity,
  filterTransactionsByTransactionType,
  getTradingPairFromTransaction
} from '../../utils/analysis';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { TransactionsTab } from '../LocalReportCreationTabs/TransactionsTab/component';
import { TPModal } from '../TPModal';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  modalTitle: {
    ...title2
  },
  modalTitleStudyName: {
    ...title2,
    ...tokens.regularFont
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  },
  inputGridElement: {
    marginBottom: theme.spacing(2)
  },
  errorBorder: {
    borderRadius: '0.25rem',
    border: '1px solid red'
  },
  gridContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.25rem',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: '1.375rem 2.25rem',
    margin: '1.5rem 0',
    justifyContent: 'flex-start'
  },
  editorLabel: {
    ...body2,
    color: tokens.core1,
    margin: theme.spacing(1, 0, 0.5, 0)
  },
  FormControl: {
    width: '100%'
  },
  label: {
    ...body2,
    color: tokens.core2,
    marginBottom: '0.375rem'
  },
  textField: {
    width: '100%',
    '&.MuiTextField-root': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  greyBackground: {
    backgroundColor: tokens.neutral70
  },
  noMargins: {
    margin: 0
  },
  transactionsList: {
    width: '100%'
  },
  placeHolder: {
    color: tokens.neutral100
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const CreateTBAModal = ({ onClose, onSubmit, study, transactions, upeCurrency }: CreateTBAModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);

  const [analysisName, setAnalysisName] = useState<string>('');
  const [selectedMethod, setSelectedMethod] = useState<MethodTypes | null>(null);
  const [searchStrategy, setSearchStrategy] = useState<string>('');
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [financialInfo, setFinancialInfo] = useState<FinancialInfoValues>({});
  const [financialFormHasErrors, setFinancialFormHasErrors] = useState<boolean>(false);
  const [tbaTransactionTypes, setTbaTransactionTypes] = useState<selectMenuType[]>([]);
  const [selectedTransactionType, setSelectedTransactionType] = useState<TransactionTypes>('');
  const [selectedTransactions, setSelectedTransactions] = useState<SelectedTransaction[]>([]);

  useEffect(() => {
    if (selectedMethod === 'CP') {
      setTbaTransactionTypes([
        { name: t('analysis:add-transaction-type'), value: '', disabled: true },
        { name: 'Services', value: 'Services' },
        { name: 'Tangible Goods', value: 'Tangible Goods' }
      ]);
    } else if (selectedMethod === 'CUT/CUP') {
      setTbaTransactionTypes([
        { name: t('analysis:add-transaction-type'), value: '', disabled: true },
        { name: 'Intangible Goods', value: 'Intangible Goods' },
        { name: 'Loans', value: 'Loans' },
        { name: 'Services', value: 'Services' },
        { name: 'Tangible Goods', value: 'Tangible Goods' }
      ]);
    } else if (selectedMethod === 'RSP') {
      setTbaTransactionTypes([
        { name: t('analysis:add-transaction-type'), value: '', disabled: true },
        { name: 'Tangible Goods', value: 'Tangible Goods' }
      ]);
    } else {
      setTbaTransactionTypes([{ name: t('analysis:title-transaction-type'), value: '' }]);
    }

    setSelectedTransactions([]);
  }, [selectedMethod, t]);

  const transactionsFilteredByTransactionTypeAndTradingPair: TransactionItem[] = useMemo(() => {
    if (!selectedTransactionType || !transactions) {
      return [];
    }

    const transactionsFilteredByTransactionType = filterTransactionsByTransactionType({
      transactionType: selectedTransactionType,
      transactions
    });

    if (study && selectedTransactions.length > 0 && transactionsFilteredByTransactionType) {
      const firstSelectedTransaction = transactionsFilteredByTransactionType?.find(
        (tr) => tr.transactionId === selectedTransactions[0].transactionId
      );
      if (firstSelectedTransaction) {
        const tradingPair = getTradingPairFromTransaction(firstSelectedTransaction, study.primaryEntity.entityId);
        if (tradingPair.primaryEntity && tradingPair.testedParty) {
          return filterTransactionsByTestedPartyAndPrimaryEntity({
            primaryEntity: tradingPair.primaryEntity,
            testedParty: tradingPair.testedParty,
            transactions: transactionsFilteredByTransactionType
          }).map((tr) => {
            const isSelected = selectedTransactions.find((selectedTr) => tr.transactionId === Number(selectedTr.id));
            const newTr = { selected: Boolean(isSelected), ...tr };
            return newTr;
          });
        }
      }
    }

    if (study?.primaryEntity && transactionsFilteredByTransactionType) {
      return filterTransactionsByTestedPartyAndPrimaryEntity({
        primaryEntity: study.primaryEntity,
        testedParty: study.primaryEntity,
        transactions: transactionsFilteredByTransactionType
      }).map((tr) => ({ ...tr, selected: false }));
    }

    return transactionsFilteredByTransactionType
      ? transactionsFilteredByTransactionType.map((tr) => ({ ...tr, selected: false }))
      : [];
  }, [transactions, selectedTransactions, study, selectedTransactionType]);

  const defaultValues = {
    name: '',
    analysisType: '',
    analysisMethods: [
      { name: t('analysis:add-method'), value: '', disabled: true },
      { name: 'CP', value: 'CP' },
      { name: 'CUT/CUP', value: 'CUT/CUP' },
      { name: 'RSP', value: 'RSP' }
    ],
    selectedMethod: '',
    testedParty: null,
    testedPartyName: '',
    primaryFunction: '',
    searchStrategy: '',
    testedPartyJustification: '',
    tradingPartner: null,
    selectedTransactions: [],
    selectedTransactionType: ''
  };

  const formMethods = useForm({
    defaultValues
  });

  const { handleSubmit, control, errors } = formMethods;

  const onSelectTransactionChange = (selectedIds: number[]) => {
    const newlySelectedTransactions = transactions?.filter((transA) => selectedIds.includes(transA.transactionId));

    if (newlySelectedTransactions?.length) {
      const newlySelectedTransactionsToSet = newlySelectedTransactions.map((transA, index) => {
        return { index, id: String(transA.transactionId), transactionId: transA.transactionId };
      });
      setSelectedTransactions(newlySelectedTransactionsToSet);
    } else {
      setSelectedTransactions([]);
    }
  };

  return (
    <TPModal
      isOpen
      maxWidth="lg"
      title={
        <span className={classes.modalTitleWrapper}>
          <AddIcon className={classes.headerIcon} />
          <Typography className={classes.modalTitle}>
            {t('analysis:action-new-tba-long')}&emsp;
            <span className={classes.modalTitleStudyName}>{study!.primaryEntity.code}</span>
          </Typography>
        </span>
      }
      containerStyle={classes.greyBackground}
      actions={[
        {
          name: t('action-cancel'),
          handler: () => {
            onClose();
          }
        },
        {
          name: t('action-done'),
          className: classes.doneButton,
          handler: handleSubmit(() => {
            setShowErrors(true);
            const dataToSave: TBAInputs = {
              name: analysisName,
              financialInfo,
              selectedMethod: selectedMethod ?? '',
              selectedTransactionType,
              selectedTransactions,
              searchStrategy
            };

            try {
              if (selectedTransactions.length > 0 && !financialFormHasErrors) {
                onSubmit(dataToSave);
                /* eslint-disable camelcase */
                void logGoogleAnalyticsEvent({
                  event_category: 'create_tba_done_button',
                  event_label: 'Create TBA modal done button',
                  container_id: workingContainer?.containerId
                });
                onClose();
              }
            } catch (error: unknown) {
              console.log(error);
            }
          })
        }
      ]}
      onClose={onClose}
    >
      <Grid container justify="center" className={classes.noMargins}>
        <Grid container item xs={12} justify="center">
          <Grid container item xs={12} spacing={1} justify="space-around" className={classes.gridContainer}>
            <Grid item xs={4} className={classes.inputGridElement}>
              <Box>
                <Typography className={classes.label}>{t('analysis:column-tba-name')}</Typography>
                <Controller
                  name="name"
                  render={({ value, onChange }) => (
                    <TextField
                      id="name"
                      name="name"
                      placeholder={t('analysis:placerholder-tba-name')}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={value}
                      inputProps={{ 'data-testid': 'tbaName' }}
                      error={Boolean(errors.name)}
                      onChange={(item) => {
                        setAnalysisName(typeof item?.target?.value === 'string' ? item.target.value : '');
                        onChange(item);
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.name}
                  rules={{ required: true }}
                  error={Boolean(errors.name)}
                />
              </Box>
              <ErrorMessage
                error={Boolean(errors.name)}
                message={t('analysis:invalid-entry-required-element-name-tba')}
              />
            </Grid>
            <Grid item xs={4} className={classes.inputGridElement}>
              <Box className={classes.label}>{t('analysis:column-method')}</Box>
              <FormControl size="small" variant="outlined" className={classes.FormControl}>
                <Controller
                  name="selectedMethod"
                  control={control}
                  render={({ value, onChange }) => (
                    <Select
                      displayEmpty
                      data-testid="tbaSelectedMethod"
                      inputProps={{ 'data-testid': 'tbaSelectedMethod' }}
                      value={value}
                      renderValue={(selected: any) =>
                        selected === '' ? (
                          <span className={classes.placeHolder}>{t('analysis:add-method')}</span>
                        ) : (
                          selected
                        )
                      }
                      onChange={(item) => {
                        onChange(item);
                        setSelectedMethod(item.target.value as MethodTypes);
                        setSelectedTransactionType('');
                        setSelectedTransactions([]);
                      }}
                    >
                      {defaultValues.analysisMethods.map((item) => (
                        <MenuItem key={item.name} value={item.value} disabled={item.disabled}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
              <ErrorMessage
                error={Boolean(errors.selectedMethod)}
                message={t('analysis:invalid-entry-required-element-method-tba')}
              />
            </Grid>
            <Grid item xs={4} className={classes.inputGridElement}>
              <Box className={classes.label}>{t('analysis:column-transaction-type')}</Box>
              <FormControl size="small" variant="outlined" className={classes.FormControl}>
                <Controller
                  name="selectedTransactionType"
                  control={control}
                  render={({ onChange }) => (
                    <Select
                      displayEmpty
                      data-testid="tbaSelectedTransactionType"
                      value={selectedTransactionType}
                      renderValue={(selected: any) =>
                        selected === '' ? (
                          <span className={classes.placeHolder}>{t('analysis:add-transaction-type')}</span>
                        ) : (
                          selected
                        )
                      }
                      disabled={!selectedMethod}
                      onChange={(item) => {
                        onChange(item);
                        setSelectedTransactionType(item.target.value as TransactionTypes);
                        setSelectedTransactions([]);
                      }}
                    >
                      {tbaTransactionTypes.map((item) => (
                        <MenuItem key={item.name} value={item.value} disabled={item.disabled}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  rules={{ required: true }}
                />
              </FormControl>
              <ErrorMessage
                error={Boolean(errors.selectedTransactionType)}
                message={t('analysis:invalid-entry-required-element-transactionType-tba')}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputGridElement}>
              <Box width="100%">
                <Typography className={classes.editorLabel}>{t('analysis:title-search-strategy')}</Typography>
                <Typography className={classes.label}>{t('analysis:search-stragegy-description')}</Typography>
                <Controller
                  name="searchStrategy"
                  render={({ onChange, value }) => (
                    <Editor
                      theme="TransferPricing"
                      id="searchStrategy"
                      init={{ height: '250' }}
                      value={value}
                      error={Boolean(errors.searchStrategy)}
                      onEditorChange={(content) => {
                        onChange(content);
                        setSearchStrategy(content ?? '');
                      }}
                    />
                  )}
                  control={control}
                  rules={{ required: false }}
                  defaultValue={defaultValues.searchStrategy}
                  error={Boolean(errors.searchStrategy)}
                />
              </Box>
              <ErrorMessage
                error={Boolean(errors.searchStrategy)}
                message={t('analysis:invalid-entry-required-justification')}
              />
            </Grid>
          </Grid>
          {selectedTransactionType && transactionsFilteredByTransactionTypeAndTradingPair.length === 0 && (
            <Grid container item xs={12} spacing={1} justify="space-around" className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography className={classes.editorLabel}>{`${t(
                  'analysis:invalid-entry-no-matching-transactions-1'
                )} ${study?.primaryEntity.name ?? ''} ${t(
                  'analysis:invalid-entry-no-matching-transactions-2'
                )}`}</Typography>
              </Grid>
            </Grid>
          )}
          {upeCurrency && transactionsFilteredByTransactionTypeAndTradingPair.length > 0 && (
            <div data-testid="transactions-list" className={classes.transactionsList}>
              <Grid
                item
                xs={12}
                className={
                  selectedTransactions.length === 0 && showErrors
                    ? `${classes.inputGridElement} ${classes.errorBorder}`
                    : `${classes.inputGridElement}`
                }
              >
                <TransactionsTab
                  transactions={transactionsFilteredByTransactionTypeAndTradingPair}
                  upeCurrency={upeCurrency}
                  selectedTransactions={[]}
                  disableSelectAll={selectedTransactions.length === 0}
                  onSelectionChange={onSelectTransactionChange}
                />
              </Grid>
              <ErrorMessage
                error={selectedTransactions.length === 0 && showErrors}
                message={t('analysis:invalid-entry-required-transactions')}
              />
            </div>
          )}
          <FinancialInfoForm
            selectedMethod={selectedMethod}
            selectedTransactionType={selectedTransactionType}
            selectedTransactions={selectedTransactions}
            transactions={transactions}
            setFinancialFormHasErrors={setFinancialFormHasErrors}
            setFinancialInfo={setFinancialInfo}
            showErrors={showErrors}
          />
        </Grid>
      </Grid>
    </TPModal>
  );
};
