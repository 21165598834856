import React, { ReactElement } from 'react';
import { isValid } from 'date-fns';
import { SortOrder } from '../../components';

const filterObjectToLowerCase = (filterObject: Record<string, string>) => {
  const newFilterObject: Record<string, string> = {};
  Object.entries(filterObject).forEach(([key, value]) => {
    newFilterObject[key] = value.toLowerCase();
  });
  return newFilterObject;
};

const itemIncludesAllFilters = <T extends Record<string, any>>(
  item: T,
  filterObject: Record<string, string>
): boolean => {
  return Object.entries(filterObject).every(([filterBy, filterString]) => {
    if (React.isValidElement(item[filterBy])) {
      return (
        !filterString ||
        findStringInReactElement(item[filterBy] as ReactElement)
          ?.toString()
          .toLowerCase()
          .includes(filterString)
      );
    }

    return !filterString || item[filterBy].toString().toLowerCase().includes(filterString);
  });
};

const filterData = <T extends Record<string, any>>(data: T[], filterObject: Record<string, string>): T[] => {
  if (Object.keys(filterObject).length === 0) {
    return data;
  }

  const lowerCaseFilterObject = filterObjectToLowerCase(filterObject);

  return data.filter((item) => {
    return itemIncludesAllFilters(item, lowerCaseFilterObject);
  });
};

const getValue = (item: any) => {
  if (React.isValidElement(item)) {
    item = findStringInReactElement(item as ReactElement);
  }

  if (typeof item === 'string') {
    if (isValid(new Date(item))) {
      return new Date(item).getTime();
    }

    return item.toLowerCase();
  }

  return item;
};

const getNumericValue = (item: string) => {
  if (item && typeof item === 'string') {
    // strip anything that is not a number, decimal, or minus-sign
    return Number.parseFloat(item.replace(/[^\d.-]/g, ''));
  }

  return item;
};

const findStringInReactElement = (node: ReactElement): string | undefined => {
  if (Object.keys(node.props).includes('data-value')) {
    return node.props['data-value'];
  }

  if (!React.isValidElement(node.props.children)) {
    return node.props.children;
  }

  return findStringInReactElement(node.props.children);
};

const sortData = <T extends Record<string, any>>(
  data: T[],
  { sortBy, sortOrder }: { sortBy?: string; sortOrder?: SortOrder }
): T[] => {
  const sortDirection = sortOrder === 'asc' ? 1 : -1;

  const columnsToSortNumerically = ['average'];
  const sortNumerically = sortBy && columnsToSortNumerically.includes(sortBy);

  return sortBy
    ? [...data].sort((firstItem: T, secondItem: T) => {
        const firstValue = sortNumerically ? getNumericValue(firstItem[sortBy]) : getValue(firstItem[sortBy]);
        const secondValue = sortNumerically ? getNumericValue(secondItem[sortBy]) : getValue(secondItem[sortBy]);
        const result = firstValue < secondValue ? -1 : firstValue > secondValue ? 1 : 0;
        return result * sortDirection;
      })
    : [...data];
};

export { filterData, sortData };
