import { ProfitBasedAnalysis } from '../../models';

export enum TestedPartyDetailsTabOptions {
  Summary = 'summary',
  IncomeStatement = 'income-statement',
  BalanceSheet = 'balance-sheet',
  ProfitLevelIndicators = 'profit-level-indicators'
}

export interface ProfitBasedAnalysisDashboardProps {
  pba: ProfitBasedAnalysis;
}
