import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FinancialTypes } from '@xbs/xbs-enums';
import { FinancialTableConnectorProps, TableBodyDataProps } from './FinancialTable.proptype';
import { FinancialConversion, FinancialData } from '../../models';
import { resetEntityFinancialStatus as resetNewEntityFinancialStatus } from '../../redux/entityFinancialV2';
import { clearFinancialLoadingStates } from '../../redux/profitBasedAnalyses';
import {
  selectEntityFinancialBalanceSheetStatus,
  selectEntityFinancialIncomeStatementStatus,
  selectPBALoadingState
} from '../../selectors';
import useDebounce from '../../utils/debounce';
import { hasEditAccess } from '../../utils/hasAccess';

const Connector = ({
  component: Component,
  tableData,
  financialConversions,
  financialReadOnlyFieldIds,
  financialFieldIds,
  columnYears,
  completionByYear,
  hasFooter,
  title,
  headerInfo,
  currencyOptions,
  namespace,
  isFieldValueValid,
  isColumnValid,
  calculateFieldValues,
  saveData,
  onSetCompletion
}: FinancialTableConnectorProps) => {
  const [tableBodyData, setTableBodyData] = useState<TableBodyDataProps[]>([]);
  const [isNotDefaultUPECurrency, setIsNotDefaultUPECurrency] = useState<boolean>(false);
  const dispatch = useDispatch();

  const pbaLoadingState = useSelector(selectPBALoadingState);
  const entityFinancialIncomeStatementStatus = useSelector(selectEntityFinancialIncomeStatementStatus);
  const entityFinancialBalanceSheetStatus = useSelector(selectEntityFinancialBalanceSheetStatus);
  const [debouncedSetTableBodyData] = useDebounce(setTableBodyData, 1500);
  const updateFinancialLoadingState = pbaLoadingState.updateTestedPartyFinancial;
  const fetchTestedPartyFinancialsLoadingState = pbaLoadingState.fetchTestedPartyFinancials;

  const getCustomTableData = (tableData: FinancialData[], financialValues: number[], years: number[]) => {
    const financialData: TableBodyDataProps[] = [];
    financialValues.forEach((financial) => {
      const financialDataObj: TableBodyDataProps = {};
      const financialType = FinancialTypes.ById[financial].Name;
      financialDataObj.name = financialType;
      financialDataObj.id = FinancialTypes.ById[financial].Id;
      years.forEach((year) => {
        const financialDataByType = tableData.find(
          (data) => data.taxYear === year && data.financialType.name === financialType
        );

        financialDataObj[year] = {
          value: financialDataByType?.value ?? null,
          data: financialDataByType ?? null
        };
      });
      financialData.push(financialDataObj);
    });
    return financialData;
  };

  const modifyData = (
    tableData: FinancialData[],
    columnYears: number[],
    financialFieldIds: number[],
    financialReadOnlyFieldIds: number[]
  ) => {
    let tableBodyData: TableBodyDataProps[] = [];
    if (columnYears.length > 0) {
      tableBodyData = getCustomTableData(tableData, financialFieldIds, columnYears);
      tableBodyData.forEach((data) => {
        columnYears.forEach((year) => {
          if (financialReadOnlyFieldIds.includes(data.id)) {
            data[year].isAllowed = false;
          }

          if (completionByYear?.[year]) {
            data[year].isAllowed = false;
          }
        });
      });
    }

    return tableBodyData;
  };

  useEffect(() => {
    if (
      tableData &&
      !isNotDefaultUPECurrency &&
      (entityFinancialIncomeStatementStatus === 'success' || entityFinancialBalanceSheetStatus === 'success') &&
      !fetchTestedPartyFinancialsLoadingState
    ) {
      const modifiedData = modifyData(tableData, columnYears, financialFieldIds, financialReadOnlyFieldIds);
      debouncedSetTableBodyData(modifiedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnYears,
    completionByYear,
    financialFieldIds,
    financialReadOnlyFieldIds,
    isNotDefaultUPECurrency,
    tableData,
    entityFinancialIncomeStatementStatus,
    entityFinancialBalanceSheetStatus
  ]);

  useEffect(() => {
    if (
      tableData &&
      !isNotDefaultUPECurrency &&
      fetchTestedPartyFinancialsLoadingState !== 'loading' &&
      updateFinancialLoadingState !== 'loading' &&
      entityFinancialIncomeStatementStatus === 'none' &&
      entityFinancialBalanceSheetStatus === 'none'
    ) {
      const modifiedData = modifyData(tableData, columnYears, financialFieldIds, financialReadOnlyFieldIds);
      debouncedSetTableBodyData(modifiedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTestedPartyFinancialsLoadingState, updateFinancialLoadingState, tableData]);

  useEffect(() => {
    return () => {
      dispatch(resetNewEntityFinancialStatus());
      dispatch(clearFinancialLoadingStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateRequiredCells = () => {
    let validationFailed = false;
    const tempTableData = [...tableBodyData];

    columnYears.forEach((year: number) => {
      if (!isColumnValid(tempTableData, String(year))) {
        tempTableData.forEach((data) => {
          if (!isFieldValueValid(data, String(year))) {
            data[year].showRedBorder = true;
            validationFailed = true;
          }
        });
      }
    });

    if (validationFailed) {
      setTableBodyData(tempTableData);
    }

    return !validationFailed;
  };

  const handleChangeCurrency = (index: number) => {
    const isNotUpe = Boolean(index);
    setIsNotDefaultUPECurrency(isNotUpe);
    const tempTableData = [...tableBodyData];
    if (financialConversions) {
      tempTableData.forEach((cell) => {
        columnYears.forEach((year) => {
          const conversionValueForYear = financialConversions.find((conversionYear) => conversionYear.taxYear === year);
          if (conversionValueForYear) {
            let convertedValue = conversionValueForYear.conversionValue;
            if (index === 0) {
              convertedValue = 1 / conversionValueForYear.conversionValue;
            }

            if (cell[year].value) {
              cell[year].value = Number(cell[year].value / convertedValue);
            }
          } else {
            cell[year].notAvailable = isNotUpe;
            cell[year].showRedBorder = false;
          }
        });
      });
    }

    return tempTableData;
  };

  const onCurrencyChange = (index: number) => {
    const updateTableData = handleChangeCurrency(index);
    setTableBodyData(updateTableData);
  };

  const handleUpdate = (index: any, year: any, changedValue: number | string, changed: boolean): void => {
    const tempTableData = [...tableBodyData];

    tempTableData[index][year].value = changedValue;
    tempTableData[index][year].changed = changed;

    tempTableData[index][year].showRedBorder = false;

    if (isColumnValid(tempTableData, String(year))) {
      tempTableData.forEach((data) => {
        data[year].showRedBorder = false;
      });
    }

    const updatedTableData = calculateFieldValues(tempTableData, year);

    setTableBodyData(updatedTableData);
  };

  const onSaveData = () => {
    const canSave = validateRequiredCells();
    let conversionValueForYear: FinancialConversion | null;
    if (canSave) {
      if (isNotDefaultUPECurrency) {
        const tableBodyDataToSave: TableBodyDataProps[] = [];
        tableBodyData.forEach((row) => {
          const obj: any = {};
          columnYears.forEach((year) => {
            if (financialConversions) {
              conversionValueForYear =
                financialConversions.find((conversionYear) => conversionYear.taxYear === year) ?? null;
            }

            if (conversionValueForYear) {
              obj[year] = {
                value: row[year].value
                  ? Number(row[year].value / (1 / (conversionValueForYear?.conversionValue ?? 0)))
                  : row[year].value,
                data: row[year].data,
                changed: row[year].changed
              };
              obj.name = row.name;
              obj.id = row.id;
            }
          });
          tableBodyDataToSave.push(obj);
        });
        saveData(tableBodyDataToSave);
      } else {
        saveData(tableBodyData);
      }
    }

    return canSave;
  };

  const handleSetCompletion = (taxYear: number, isCompleted: boolean) => {
    if (onSaveData()) {
      onSetCompletion(taxYear, isCompleted);
    }
  };

  return (
    <Component
      hasFooter={hasEditAccess() && hasFooter}
      tableData={tableBodyData}
      columnYears={columnYears}
      saveData={saveData}
      title={title}
      headerInfo={headerInfo}
      namespace={namespace}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      handleUpdate={handleUpdate}
      completionByYear={completionByYear}
      onSaveData={onSaveData}
      onCurrencyChange={onCurrencyChange}
      onSetCompletion={handleSetCompletion}
    />
  );
};

Connector.defaultProps = {
  isFieldValueValid: () => true,
  isColumnValid: () => true,
  financialReadOnlyFieldIds: [],
  headerInfo: [],
  calculateFieldValues: (tempTableData: TableBodyDataProps[]) => tempTableData
};

export default Connector;
