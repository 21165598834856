import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CardHeader, Typography, makeStyles, Theme, Select, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { DashboardCardEntityTradingPairsSelectionProps, TradingPairTransactionSummary } from '.';
import Transaction from './DashboardCardEntityTradingPairsAmount';
import { selectUPECurrency } from '../../selectors';

const useStyles = makeStyles((theme: Theme) => ({
  wrapperMenu: {
    margin: theme.spacing(1, 0)
  },
  menuCaption: {
    ...theme.typography.caption,
    color: theme.palette.text.secondary,
    fontSize: '0.9rem'
  },
  select: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderStyle: 'none'
      },
      '&.Mui-focused fieldset': {
        borderStyle: 'none'
      }
    },
    '& .MuiSelect-root': {
      minWidth: theme.spacing(16)
    }
  },
  transactionSelect: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderStyle: 'none'
      }
    },
    '& .MuiSelect-root': {
      minWidth: '12vw'
    }
  }
}));

function convertToMenuItems(items: TradingPairTransactionSummary[]) {
  return items.map((item) => ({
    text: item.tradingPair,
    value: item.entityCode
  }));
}

const TPDashboardTradingPairTransactions = ({
  items,
  currentTradingPartner,
  onSelectChange
}: DashboardCardEntityTradingPairsSelectionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const upeCurrency = useSelector(selectUPECurrency);

  const selectedPair = items.find((item) => item.entityCode === currentTradingPartner.code);

  const selectClasses = clsx({
    [classes.transactionSelect]: true
  });

  return (
    <>
      <CardHeader title={t('entities:title-trading-pairs')} />
      <div className={classes.wrapperMenu}>
        <Typography className={classes.menuCaption}>{t('entities:placeholder-trading-pair')}</Typography>
        <Select
          disableUnderline
          className={selectClasses}
          variant="outlined"
          value={String(selectedPair?.entityCode)}
          onChange={(e: any) => {
            onSelectChange(e);
          }}
        >
          {convertToMenuItems(items).map((item) => (
            <MenuItem key={item.text} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
        {selectedPair
          ? selectedPair.types.map((transactionObj) => (
              <Transaction key={transactionObj.type} {...transactionObj} upeCurrency={upeCurrency!} />
            ))
          : null}
      </div>
    </>
  );
};

export default TPDashboardTradingPairTransactions;
