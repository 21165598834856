import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { GeometricalShapes } from '../../../svgs';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(7),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  },
  text: {
    color: theme.palette.text.secondary
  },
  image: {
    width: theme.spacing(12),
    marginRight: theme.spacing(4)
  }
}));

export const NoRates = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.image}>
        <GeometricalShapes />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="h2">{t('entities:title-no-currency-conversion')}</Typography>
        <Typography className={classes.text} variant="h2">
          {t('entities:message-no-currency-conversion')}
        </Typography>
      </Box>
    </Box>
  );
};
