import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Chip, makeStyles } from '@material-ui/core';
import { PbaTbaJurisdictionStatus } from '@xbs/xbs-enums';
import { JurisdictionStatusDropDown } from '../../components';
import { TransactionBasedAnalysis } from '../../models';
import { TbaRangeResult } from '../../redux/transactionBasedAnalyses';
import { selectCurrentTBA } from '../../selectors';
import designTokens from '../../styles/designTokens';
import { captionOverline, title2 } from '../../styles/typography';
import { InRangeIcon, OutOfRangeIcon } from '../../svgs';

export interface TuneCompSearchHeaderProps {
  selectedTba: TransactionBasedAnalysis | null;
  tbaRangeResults: TbaRangeResult[] | undefined;
}

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    height: '2.75rem'
  },
  pliRange: {
    margin: 'auto',
    marginRight: '0.5em'
  },
  inRange: {
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    '& g': {
      fill: designTokens.product100
    }
  },
  outOfRange: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    '& g': {
      fill: theme.palette.warning.dark
    }
  },
  leftBlock: {
    display: 'flex'
  },
  rightBlock: {
    display: 'flex',
    marginRight: '0.5em'
  },
  jurisdictionName: {
    ...title2,
    margin: 'auto',
    marginRight: '0.5em',
    marginLeft: '1.125rem',
    color: designTokens.core3
  },
  pliName: {
    margin: 'auto',
    textTransform: 'uppercase'
  },
  captionOverline
}));

export const TbaComparableAnalysisHeader = ({ selectedTba, tbaRangeResults }: TuneCompSearchHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentTBA = useSelector(selectCurrentTBA);

  const transaction = currentTBA?.transactions && currentTBA?.transactions[0];
  const source = transaction?.legalEntityTransactions.find((legalTx) => legalTx.isSource)?.entity;
  const dest = transaction?.legalEntityTransactions.find((legalTx) => !legalTx.isSource)?.entity;

  const legalEntities = [currentTBA?.primaryLegalEntity, currentTBA?.secondaryLegalEntity];

  const sourceJurisdiction = legalEntities.find((v) => v?.entityId === source?.entityId)?.taxJurisdiction?.isoCode;
  const destinationJurisdiction = legalEntities.find((v) => v?.entityId === dest?.entityId)?.taxJurisdiction?.isoCode;

  const sourceCountry = selectedTba?.jurisdictionStatuses && selectedTba?.jurisdictionStatuses[1];
  const destinationCountry = selectedTba?.jurisdictionStatuses && selectedTba?.jurisdictionStatuses[0];

  if (!selectedTba || !sourceJurisdiction || !destinationJurisdiction) {
    return <h1>no countries</h1>;
  }

  const sourceJurisdictionStatus = sourceCountry && PbaTbaJurisdictionStatus.ById[sourceCountry.status];
  const destinationJurisdictionStatus =
    destinationCountry &&
    sourceJurisdiction !== destinationJurisdiction &&
    PbaTbaJurisdictionStatus.ById[destinationCountry?.status];

  const updateSourceJurisdictionStatus = async (status: number) => {
    if (sourceJurisdictionStatus && sourceJurisdictionStatus.Id !== status) {
      console.log('update source jurisdiction status to', PbaTbaJurisdictionStatus.ById[status].Name);
      // make the api call here and re-fetch TBA
    }
  };

  const updateDestinationJurisdictionStatus = async (status: number) => {
    if (destinationJurisdictionStatus && destinationJurisdictionStatus.Id !== status) {
      console.log('update destination jurisdiction status to', PbaTbaJurisdictionStatus.ById[status].Name);
      // make the api call here and re-fetch TBA
    }
  };

  const sourceIsInRange = tbaRangeResults?.find((rs) => rs.tbaRangeType.useForSourceTaxJurisdiction)?.inRange;
  const destinationIsInRange = tbaRangeResults?.find((rs) => rs.tbaRangeType.useForDestinationTaxJurisdiction)?.inRange;

  return (
    <Box className={classes.root}>
      <span className={classes.leftBlock}>
        <Box component="span" className={classes.jurisdictionName}>
          {sourceJurisdiction === destinationJurisdiction
            ? `${sourceJurisdiction}`
            : `${sourceJurisdiction} + ${destinationJurisdiction}`}{' '}
          {t('analysis:title')}
        </Box>
        <Box component="span" className={classes.pliName}>
          <Chip
            className={classes.captionOverline}
            color="primary"
            size="small"
            label={t(`analysis:${String(selectedTba.tbaEvaluationMethod.name)}`)}
          />
        </Box>
      </span>
      <span className={classes.rightBlock}>
        {sourceCountry && (
          <>
            <Box component="span" className={classes.pliRange}>
              <Chip
                className={`${sourceIsInRange ? classes.inRange : classes.outOfRange} ${classes.captionOverline}`}
                size="medium"
                label={
                  sourceIsInRange ? t(`analysis:in_range_status.in_range`) : t(`analysis:in_range_status.not_in_range`)
                }
                icon={sourceIsInRange ? <InRangeIcon /> : <OutOfRangeIcon />}
              />
            </Box>
            <JurisdictionStatusDropDown
              jurisdictionStatus={sourceCountry}
              updateJurisdictionStatus={updateSourceJurisdictionStatus}
            />
          </>
        )}
        {destinationCountry && (
          <>
            &emsp;
            <Box component="span" className={classes.pliRange}>
              <Chip
                className={`${destinationIsInRange ? classes.inRange : classes.outOfRange} ${classes.captionOverline}`}
                size="medium"
                label={
                  destinationIsInRange
                    ? t(`analysis:in_range_status.in_range`)
                    : t(`analysis:in_range_status.not_in_range`)
                }
                icon={destinationIsInRange ? <InRangeIcon /> : <OutOfRangeIcon />}
              />
            </Box>
            <JurisdictionStatusDropDown
              jurisdictionStatus={destinationCountry}
              updateJurisdictionStatus={updateDestinationJurisdictionStatus}
            />
          </>
        )}
      </span>
    </Box>
  );
};
