import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  makeStyles,
  Typography,
  Chip,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  IconButton
} from '@material-ui/core';
import { VisibilityOutlined as EyeIcon, FormatListNumberedRounded } from '@material-ui/icons';
import { CenteredProgress, SearchAndSort, SortOrder } from '../../../../../components';
import { BulkRejection } from '../../../../../models';
import { selectWorkingContainer } from '../../../../../selectors';
import { filterData, sortData } from '../../../../../services/filtering';
import designTokens from '../../../../../styles/designTokens';
import { body1, body2, captionOverline } from '../../../../../styles/typography';
import { InRangeIcon, OutOfRangeIcon } from '../../../../../svgs';
import { hasEditAccess } from '../../../../../utils';
import { logGoogleAnalyticsEvent } from '../../../../../utils/sendGoogleAnalyticaEvent';
import { Table } from '../../../../Table';
import { AppliedBulkRejectionsProps } from '../BulkRejections.proptype';

const useStyles = makeStyles(() => ({
  labelRoot: {},
  switchRoot: {
    display: 'flex',
    height: 12,
    marginRight: '1em',
    padding: 0,
    width: 28
  },
  switchBase: {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        backgroundColor: 'green',
        opacity: 1
      }
    }
  },
  track: {
    backgroundColor: 'yellow',
    opacity: 1
  },
  thumb: {
    color: 'blue',
    boxShadow: 'none',
    height: 8,
    width: 8
  },
  wrapperComponent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  headingTitle: {
    fontFamily: designTokens.regularFont.fontFamily,
    margin: 'auto 0',
    size: '1em',
    lineHeight: '1.375em',
    color: designTokens.core1
  },
  headingRow: {
    display: 'flex',
    margin: '0 1em'
  },
  primaryChip: {
    fontSize: '0.75em',
    margin: 'auto',
    marginLeft: '0.5em',
    fontFamily: designTokens.mediumFont.fontFamily,
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    height: '1.75rem',
    width: '2.063rem'
  },
  scrollTable: {
    width: '100%',
    '& .MuiTableCell-root': {
      '&.MuiTableCell-body': {
        '& .MuiIconButton-root': {
          '&.Mui-checked': {
            color: designTokens.product100
          }
        }
      }
    }
  },
  rejectionTypesSearch: {
    width: '80%'
  },
  columnHeader: {
    fontFamily: designTokens.regularFont.fontFamily,
    size: '0.875em',
    lineHeight: '1.125em',
    verticalAlign: 'bottom'
  },
  columnActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiFormControlLabel-root': {
      margin: '0',
      width: '100%'
    }
  },
  rowWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chipWrapper: {
    ...body1,
    padding: '0 0.5rem',
    borderRadius: '15px'
  },
  appliedBulkRejection: {
    '& .MuiTableCell-root': {
      ...body1,
      color: designTokens.core1,
      backgroundColor: designTokens.neutral60
    },
    '& .MuiTableCell-body:first-child': {
      ...body2,
      color: designTokens.core1,
      backgroundColor: designTokens.neutral60
    }
  },
  iconEye: {
    color: designTokens.neutral100,
    width: '1.15rem'
  },
  iconEyeActive: {
    color: designTokens.purpleLight2,
    width: '1.15rem'
  },
  loadingWrapper: {
    width: '100%'
  },
  disabledRow: {
    opacity: '0.3'
  },
  buttonSortBulkRejections: {
    width: '2.25rem',
    height: '2.25rem',
    borderRadius: '4px',
    '&.active': {
      backgroundColor: designTokens.product15
    },
    '&.inactive': {
      backgroundColor: designTokens.neutral70
    }
  },
  rejectedWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  rejectedIndexWrapper: {
    ...captionOverline,
    backgroundColor: designTokens.product100,
    color: designTokens.white,
    width: '1.375rem',
    height: '1.375rem',
    marginRight: '0.4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableWrapper: {
    overflow: 'auto'
  }
}));

interface RejectionRow {
  rejectionTypes: ReactElement;
  range: ReactElement;
  rejected: ReactElement;
  remaining: ReactElement;
  isActive: boolean;
  active: ReactElement;
  className?: string;
}

export const AppliedBulkRejections = ({
  comparables,
  appliedBulkRejections,
  nonAppliedBulkRejections,
  onApplyBulkRejection,
  onApplyFilterBulkRejection,
  applyBulkRejectionsIsLoading
}: AppliedBulkRejectionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'rejected',
    sortOrder: 'desc'
  });

  const [indexLoading, setIndexLoading] = useState(-1);
  const [sortRejectedList, setSortRejectedList] = useState(false);
  const [filterBulkRejected, setFilterBulkRejected] = useState('');

  const container = useSelector(selectWorkingContainer);

  const applyFilterByBulkRejected = useCallback(
    (rejectedType: string) => {
      const value = filterBulkRejected === '' ? rejectedType : filterBulkRejected === rejectedType ? '' : rejectedType;
      setFilterBulkRejected(value);
      onApplyFilterBulkRejection(value);
    },
    [filterBulkRejected, setFilterBulkRejected, onApplyFilterBulkRejection]
  );

  const getRowData = useCallback(
    (isActive: boolean, bulkRejection: BulkRejection, index?: number): RejectionRow => {
      const isInRange = bulkRejection.inRangeStatus === 'in_range_status.in_range';
      return {
        rejectionTypes: (
          <Box className={classes.rejectedWrapper} data-value={`${t(`analysis:${bulkRejection.rejectionType}`)}`}>
            {isActive && <Box className={classes.rejectedIndexWrapper}>{index}</Box>}
            {`${t(`analysis:${bulkRejection.rejectionType}`)}`}
          </Box>
        ),
        range: <Box className={classes.rowWrapper}>{isInRange ? <InRangeIcon /> : <OutOfRangeIcon />}</Box>,
        rejected: (
          <Box className={classes.rowWrapper} data-value={bulkRejection.numRejected}>
            <Chip className={classes.chipWrapper} size="small" label={Number(bulkRejection.numRejected)} />
          </Box>
        ),
        remaining: <Box className={classes.rowWrapper}>{bulkRejection.numRemaining}</Box>,
        isActive,
        className:
          applyBulkRejectionsIsLoading && bulkRejection.rejectionTypeId !== indexLoading
            ? classes.disabledRow
            : isActive
            ? classes.appliedBulkRejection
            : '',
        active: (
          <Box className={classes.columnActions}>
            {applyBulkRejectionsIsLoading && bulkRejection.rejectionTypeId === indexLoading ? (
              <Box className={classes.loadingWrapper}>
                <LinearProgress />
              </Box>
            ) : (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isActive}
                      disabled={applyBulkRejectionsIsLoading || !hasEditAccess()}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      onChange={(e) => {
                        const checkedValue = e.target.checked ? 'applied' : 'unapplied';
                        /* eslint-disable camelcase */
                        void logGoogleAnalyticsEvent({
                          event_category: 'pba_button_click',
                          event_label: `Active checkbox click ${checkedValue} for rejection ${String(
                            bulkRejection.rejectionType
                          )}`,
                          container_id: container?.containerId
                        });
                        setIndexLoading(bulkRejection.rejectionTypeId);
                        onApplyBulkRejection(e.target.checked, bulkRejection.rejectionTypeId);
                      }}
                    />
                  }
                  label=""
                />
                {isActive && !applyBulkRejectionsIsLoading && (
                  <IconButton
                    onClick={() => {
                      applyFilterByBulkRejected(bulkRejection.rejectionType);
                    }}
                  >
                    <EyeIcon
                      className={
                        bulkRejection.rejectionType === filterBulkRejected ? classes.iconEyeActive : classes.iconEye
                      }
                    />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        )
      };
    },
    [
      t,
      classes,
      applyBulkRejectionsIsLoading,
      indexLoading,
      filterBulkRejected,
      onApplyBulkRejection,
      applyFilterByBulkRejected,
      container?.containerId
    ]
  );

  const onActiveSortRejectedList = () => {
    setSortRejectedList(!sortRejectedList);
  };

  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const displaybleBulkRejections: RejectionRow[] = useMemo(() => {
    const formattedAppliedRejections: RejectionRow[] = appliedBulkRejections.map((bulkRejection, index) =>
      getRowData(true, bulkRejection, index + 1)
    );
    const formattedNonAppliedRejections: RejectionRow[] = nonAppliedBulkRejections.map((bulkRejection) =>
      getRowData(false, bulkRejection)
    );

    if (sortRejectedList) {
      const sortAndFilteredAppliedRejections = filterData(formattedAppliedRejections, filterObject);
      const sortAndFilteredNonAppliedRejections = sortData(
        filterData(formattedNonAppliedRejections, filterObject),
        sortObject
      );

      return [...sortAndFilteredAppliedRejections, ...sortAndFilteredNonAppliedRejections];
    }

    const allValues = [...formattedAppliedRejections, ...formattedNonAppliedRejections];
    return sortData(filterData(allValues, filterObject), sortObject);
  }, [appliedBulkRejections, nonAppliedBulkRejections, filterObject, sortObject, getRowData, sortRejectedList]);

  return (
    <Box className={classes.wrapperComponent}>
      <Box className={classes.headingRow}>
        <Typography className={classes.headingTitle}>{t('analysis:applied-bulk-rejections')}</Typography>
        <Chip size="small" label={appliedBulkRejections.length} className={classes.primaryChip} />
        <IconButton
          disabled={applyBulkRejectionsIsLoading}
          className={classes.buttonSortBulkRejections + ' ' + (sortRejectedList ? 'active' : 'inactive')}
          onClick={onActiveSortRejectedList}
        >
          <FormatListNumberedRounded />
        </IconButton>
      </Box>
      {!comparables || (appliedBulkRejections.length === 0 && nonAppliedBulkRejections.length === 0) ? (
        <CenteredProgress />
      ) : (
        <div className={classes.tableWrapper}>
          <Table
            className={classes.scrollTable}
            columns={[
              {
                key: 'rejectionTypes',
                header: (
                  <Box className={classes.rejectionTypesSearch}>
                    <SearchAndSort
                      field={t('analysis:search-rejection-types')}
                      onSortClicked={(sortOrder) => {
                        setSort({ sortBy: 'rejectionTypes', sortOrder });
                      }}
                      onSearchChange={(value) => {
                        setFilter({ rejectionTypes: value });
                      }}
                    />
                  </Box>
                )
              },
              {
                key: 'range',
                header: <span className={classes.columnHeader}> {t('common:title-range')}</span>,
                width: '2rem'
              },
              {
                key: 'rejected',
                header: <span className={classes.columnHeader}> {t('common:title-rejected')}</span>,
                width: '2rem'
              },
              {
                key: 'remaining',
                header: <span className={classes.columnHeader}> {t('common:title-remaining')}</span>,
                width: '2rem'
              },
              {
                key: 'active',
                header: <span className={classes.columnHeader}> {t('common:title-active')}</span>,
                width: '6rem'
              }
            ]}
            data={displaybleBulkRejections}
            stickyCols={{
              active: {
                side: 'right',
                position: 0
              }
            }}
          />
        </div>
      )}
    </Box>
  );
};
