import { GeoJSONFeature } from '../../components';

const ignoredIsoCountryCodes = new Set(['ATA']);

export async function fetchCountries(selectedIsoCodes: string[]) {
  const response = await fetch(
    'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_110m_admin_0_countries.geojson'
  );
  const data = await response.json();
  const features = data.features
    // eslint-disable-next-line camelcase
    .map((feature: GeoJSONFeature<{ iso_a3: string; admin: string }>) => {
      const { iso_a3: iso, admin: name } = feature.properties;

      return ignoredIsoCountryCodes.has(iso)
        ? null
        : { ...feature, properties: { name, iso, selected: selectedIsoCodes?.includes(iso) } };
    })
    .filter(Boolean);

  return { ...data, features };
}
