import { Box, makeStyles } from '@material-ui/core';
import { NodeProps, TreeNode } from '../TreeChart.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    position: 'absolute',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    width: ({ nodeWidth }: NodeProps<any>) => nodeWidth,

    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      cursor: 'pointer'
    }
  }
}));

export const Node = <T extends TreeNode>(props: NodeProps<T>) => {
  const { data, onNodeClick, onNodeEnter, onNodeLeave } = props;
  const classes = useStyles(props);
  return (
    <Box
      role="figure"
      data-id={data.id}
      className={classes.root}
      onClick={(event) => {
        onNodeClick?.(event, data);
      }}
      onMouseEnter={(event) => {
        onNodeEnter?.(event, data);
      }}
      onMouseLeave={(event) => {
        onNodeLeave?.(event, data);
      }}
    >
      {data.content}
    </Box>
  );
};
