import * as fonts from './fonts';

const isRebrand = localStorage.getItem('isRebrand') === 'true';
const p100Color = isRebrand ? '#232A5C' : '#3C2A8E';

const tokens = {
  // https://crossbordersolutions.invisionapp.com/share/QW1024KA5PTX#/447962393_Colour

  /**
   * REBRANDING COLORS
   * Blue: '#232a5c'
   * Green: '#06ab71'
   *
   * Our approach will be to _replace_ existing colors below with the new colors.
   * This *should* replace the existing colors app-wide with the new colors in one move.
   * For example, `product100` will be updated from the existing purple color #3c2a8e to the new dark blue #232a5c
   *
   * Our approach is NOT to create new named colors for the new branding.
   */

  core1: '#464d59',
  core2: '#677283',
  core3: '#363636',
  error15: '#ffe8ec',
  error100: '#b00020',
  muiError: '#f44336',
  neutral100: '#8e949f',
  neutral95: '#c5c5c9',
  neutral90: '#e2e3e6',
  neutral80: '#efeff2',
  neutral70: '#f5f5f7',
  neutral60: '#faf9fa',
  product10: '#f9f8fe',
  product100: p100Color,
  progress20: '#faeab7',
  product90: '#4a36a7',
  product25: '#dcdbfe',
  product15: '#efefff',
  supportColorA20: '#c9e7f1',
  supportColorA15: '#e5f4f9',
  supportColorA100: '#0376a0',
  themeAp100: p100Color,
  black: '#000000',
  blackAlpha20: '#00000033',
  white: '#ffffff',
  purple: '#bd6dff',
  purpleLight1: '#8d74ff',
  purpleLight2: '#6e56db',
  purpleLight3: '#fbfbff',
  pink: '#c940e0',
  mustard: '#FEF7E0',
  darkMustard: '#82703A',
  positive70: '#2ac788',
  spacing: 12,
  ...fonts,
  baseFontSize: 14
};

export default tokens;
