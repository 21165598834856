import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { ComparableAnalysisHeaderProps } from './TbaComparableAnalysis.proptype';
import tokens from '../../styles/designTokens';
import { title2 } from '../../styles/typography';
import ComparabilityBulkActionCard from '../ComparabilityBulkActionCard/component';
import { SummaryChips } from '../SummaryChips';

const useStyles = makeStyles(() => ({
  headerArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title2,
  buttonDivider: {
    margin: '0 2rem',
    border: `1px solid ${tokens.neutral90}`,
    height: '2.625rem'
  }
}));

export const ComparabilityTabHeader = ({
  chips,
  filterByStatus,
  selectedUncontrolledTransactionIds,
  unselectAll,
  bulkUpdateComparabilityStatus,
  bulkUpdateSimilarities,
  bulkUpdateEvaluations,
  isUcTransactionStatusUpdating,
  setIsUcTransactionStatusUpdating
}: ComparableAnalysisHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isComparableSelected = selectedUncontrolledTransactionIds && selectedUncontrolledTransactionIds.length > 0;

  return (
    <Box className={classes.headerArea}>
      <Typography variant="h2" className={classes.title2}>
        {t('analysis:comparable-analysis')}
      </Typography>
      {isComparableSelected ? (
        <ComparabilityBulkActionCard
          isUcTransactionStatusUpdating={isUcTransactionStatusUpdating}
          setIsUcTransactionStatusUpdating={setIsUcTransactionStatusUpdating}
          selectedUncontrolledTransactionIds={selectedUncontrolledTransactionIds}
          unselectAll={unselectAll}
          bulkUpdateComparabilityStatus={bulkUpdateComparabilityStatus}
          bulkUpdateSimilarities={bulkUpdateSimilarities}
          bulkUpdateEvaluations={bulkUpdateEvaluations}
        />
      ) : (
        <SummaryChips chips={chips} clickHandler={filterByStatus} />
      )}
    </Box>
  );
};
