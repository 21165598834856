import { ReactInstance } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { TPModal } from '../../app/TPModal';
import { GlobalGuide } from '../../models';

export interface GlobalGuideCardProps {
  displayed?: GlobalGuide;
  container: ReactInstance;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2.5),

    '& caption': {
      display: 'block',
      textAlign: 'left'
    }
  }
}));

const extractData = (data?: GlobalGuide) => {
  const fields = [];
  if (data) {
    // Warn: allowed ids should be from 9 to 15, but we don't have data yet.
    for (const id of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]) {
      const details = data.wtpData.find(({ wtpReviewType: { wtpReviewTypeId } }) => id === wtpReviewTypeId);
      if (details?.value) {
        fields.push({
          reviewType: details.wtpReviewType.name
            .toLowerCase()
            // displayed review type have this suffix: https://admin.dev.xbscompliance.ai/wtp/types
            .replace(' - WTPR - Country Specific Report Page', 'country-report')
            .replace(/ /g, '-'),
          value: details.value
        });
      }
    }
  }

  return fields;
};

export const GlobalGuideCard = ({ displayed, container, onClose }: GlobalGuideCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TPModal
      title={
        <>
          <Typography variant="h3">{t('globalguide:title-card')}</Typography>
          <Box>{displayed?.name}</Box>
        </>
      }
      isOpen={Boolean(displayed)}
      container={container}
      maxWidth="sm"
      onClose={onClose}
    >
      <Box className={classes.root}>
        {extractData(displayed).map(({ reviewType, value }) => (
          <Box key={reviewType}>
            <Typography variant="h3" component="div">
              {t(`globalguide:wtp-review-type-${reviewType}`)}
            </Typography>
            <Typography>{value}</Typography>
          </Box>
        ))}
      </Box>
    </TPModal>
  );
};
