import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  makeStyles,
  TableCellProps as MaterialUiTableCellProps,
  Typography
} from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { ComparabileAnalysisTableTransactions, ComparableAnalysisTableProps } from './TbaComparableAnalysis.proptype';
import { DropDownButton, SearchAndSort } from '../../components';
import { SortControl } from '../../components/SortControl';
import { SortOrder } from '../../components/TableCellSort';
import { FetchLoadingStateEnum } from '../../constants';
import { selectComparabilityOptions, selectTBALoadingState, selectWorkingContainer } from '../../selectors';
import { filterData, sortData } from '../../services/filtering';
import tokens from '../../styles/designTokens';
import { body1, body2 } from '../../styles/typography';
import { TextGeneric, SelectedUndetermined } from '../../svgs';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import ComparabilityDescriptionModal from '../ComparabilityDescriptionModal/component';
import { Table } from '../Table';
import { StickyColsDefinition } from '../Table/Table.proptype';
import {
  CurrentSearchUncontrolledTransaction,
  UncontrolledTransactionComparabilities
} from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  scrollTable: {
    width: '100%',
    '& .MuiTableCell-root': {
      '&.MuiTableCell-body': {
        '& .MuiIconButton-root': {
          color: tokens.product100,
          '&:hover': {
            backgroundColor: tokens.product25
          }
        }
      }
    }
  },
  indeterminateCheckbox: {
    color: tokens.product100
  },
  tableWrapper: {
    overflow: 'auto',
    height: '100%'
  },
  statusDropDown: {
    minWidth: 'none',
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  checkCircle: {
    color: tokens.positive70
  },
  statusIcon: {
    ...body1,
    width: '1.375rem',
    height: '1.375rem',
    strokeWidth: '0rem'
  },
  cancelCircle: {
    backgroundColor: 'inherit'
  },
  dropDown: {
    ...body1,
    paddingLeft: '0.5rem'
  },
  rowSelected: {
    '&:hover': {
      '& .MuiTableCell-root': {
        backgroundColor: tokens.product15
      }
    },
    '& .MuiTableCell-root': {
      backgroundColor: tokens.neutral70
    },
    '& td.MuiTableCell-body:first-child': {
      color: tokens.product100,
      borderLeft: `0.188rem solid ${tokens.purpleLight2}`,
      ...body2,
      width: '10rem'
    },
    '& td.MuiTableCell-body:nth-child(2)': {
      ...body2
    }
  },
  rowNotSelected: {
    '&:hover': {
      '& .MuiTableCell-root': {
        backgroundColor: tokens.neutral60
      }
    },
    '& td.MuiTableCell-body:first-child': {
      ...body2,
      width: '10rem'
    },
    '& td.MuiTableCell-body:nth-child(2)': {
      ...body2
    }
  }
}));

export const ComparabilityTabTable = ({
  uncontrolledTransactions,
  updateComparability,
  onApplyDescription,
  selectedUncontrolledTransactionIds,
  selectUncontrolledTransaction,
  selectAllUncontrolledTransactions,
  isUcTransactionStatusUpdating,
  setIsUcTransactionStatusUpdating
}: ComparableAnalysisTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const comparabilityOptions = useSelector(selectComparabilityOptions) ?? [];
  const workingContainer = useSelector(selectWorkingContainer);
  const loadingState = useSelector(selectTBALoadingState);
  const tbaUpdated = loadingState.fetchTbaRangeType === FetchLoadingStateEnum.fulfilled;

  useEffect(() => {
    if (tbaUpdated) {
      setIsUcTransactionStatusUpdating(false);
    }
  }, [setIsUcTransactionStatusUpdating, tbaUpdated]);

  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'name',
    sortOrder: 'asc'
  });

  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const stickyCols: StickyColsDefinition = {};
  let tableDisplayData: any = [];

  const [openDescriptionModal, setOpenDescriptionModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const selectAllTransactions = () => {
    let transactionsIds: number[] = [];

    if (selectedUncontrolledTransactionIds.length === 0) {
      transactionsIds = uncontrolledTransactions
        .filter((uncontrolledTransaction: ComparabileAnalysisTableTransactions) => {
          return typeof uncontrolledTransaction?.transactionId === 'number';
        })
        .map((uncontrolledTransaction: ComparabileAnalysisTableTransactions) => {
          return uncontrolledTransaction.transactionId!;
        });
    }

    selectAllUncontrolledTransactions(transactionsIds);
  };

  if (uncontrolledTransactions) {
    const mapToTableData = uncontrolledTransactions.map(
      (uncontrolledTransaction: ComparabileAnalysisTableTransactions) =>
        uncontrolledTransaction
          ? {
              identifier: uncontrolledTransaction.identifier,
              transactionComparabilityId: uncontrolledTransaction.transactionComparabilityId,
              entityName: uncontrolledTransaction.entityName,
              comparabilityEvaluation: uncontrolledTransaction.comparabilityEvaluation,
              economicAnalysisSimilarity: uncontrolledTransaction.economicAnalysisSimilarity,
              functionalAnalysisSimilarity: uncontrolledTransaction.functionalAnalysisSimilarity,
              contractualTermsSimilarity: uncontrolledTransaction.contractualTermsSimilarity,
              acceptedStatus: (
                <DropDownButton
                  disabled={isUcTransactionStatusUpdating}
                  variant="text"
                  className={classes.statusDropDown}
                  items={[
                    {
                      children: (
                        <>
                          <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
                          <Typography className={`${classes.dropDown}`}>
                            {t(`analysis:comparable-range-accepted`)}
                          </Typography>
                        </>
                      ),
                      onClick: () => {
                        setIsUcTransactionStatusUpdating(true);
                        /* eslint-disable camelcase */
                        void logGoogleAnalyticsEvent({
                          event_category: 'tba_button_click',
                          event_label: `Comparability Accepted Status Update`,
                          container_id: workingContainer?.containerId
                        });
                        void updateComparability(
                          uncontrolledTransaction.transactionComparabilityId ?? 0,
                          UncontrolledTransactionComparabilities.Accepted
                        );
                      }
                    },
                    {
                      children: (
                        <>
                          <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                          <Typography className={classes.dropDown}>
                            {t(`analysis:comparable-range-rejected`)}
                          </Typography>
                        </>
                      ),
                      onClick: () => {
                        /* eslint-disable camelcase */
                        void logGoogleAnalyticsEvent({
                          event_category: 'tba_button_click',
                          event_label: `Comparability Rejected Status Update`,
                          container_id: workingContainer?.containerId
                        });
                        void updateComparability(
                          uncontrolledTransaction.transactionComparabilityId ?? 0,
                          UncontrolledTransactionComparabilities.Rejected
                        );
                      }
                    },
                    {
                      children: (
                        <>
                          <SelectedUndetermined className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                          <Typography className={classes.dropDown}>
                            {t(`analysis:comparable-range-undetermined`)}
                          </Typography>
                        </>
                      ),
                      onClick: () => {
                        /* eslint-disable camelcase */
                        void logGoogleAnalyticsEvent({
                          event_category: 'tba_button_click',
                          event_label: `Comparability Undetermined Status Update`,
                          container_id: workingContainer?.containerId
                        });
                        void updateComparability(
                          uncontrolledTransaction.transactionComparabilityId ?? 0,
                          UncontrolledTransactionComparabilities.Undetermined
                        );
                      }
                    }
                  ]}
                  onItemClick={() => {
                    /* eslint-disable camelcase */
                    void logGoogleAnalyticsEvent({
                      event_category: 'tba_button_click',
                      event_label: `Comparability Accepted Status Cell click`,
                      container_id: workingContainer?.containerId
                    });
                  }}
                >
                  {uncontrolledTransaction.status === UncontrolledTransactionComparabilities.Accepted && (
                    <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
                  )}
                  {uncontrolledTransaction.status === UncontrolledTransactionComparabilities.Rejected && (
                    <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                  )}
                  {uncontrolledTransaction.status === UncontrolledTransactionComparabilities.Undetermined && (
                    <SelectedUndetermined className={`${classes.cancelCircle} ${classes.statusIcon}`} />
                  )}
                </DropDownButton>
              ),
              className: selectedUncontrolledTransactionIds.includes(uncontrolledTransaction.transactionId ?? -1)
                ? classes.rowSelected
                : classes.rowNotSelected,
              backgroundInformation: (
                <DropDownButton
                  variant="text"
                  className={classes.statusDropDown}
                  items={comparabilityOptions.map((cOption: { name: string; id: number }) => ({
                    children: (
                      <Typography className={`${classes.dropDown}`}>
                        {t(
                          `analysis:tba-uncontrolled-transaction-comparability-option-${String(
                            cOption.name
                          ).toLowerCase()}`
                        )}
                      </Typography>
                    ),
                    onClick: () => {
                      void logGoogleAnalyticsEvent({
                        event_category: 'tba_button_click',
                        event_label: `Comparability ${cOption.name} Background Information Update`,
                        container_id: workingContainer?.containerId
                      });
                      uncontrolledTransaction.backgroundInformation = cOption.name;
                      uncontrolledTransaction.economicAnalysisSimilarity = cOption.id;
                      onApplyDescription(uncontrolledTransaction);
                    }
                  }))}
                  onItemClick={() => {
                    void logGoogleAnalyticsEvent({
                      event_category: 'tba_button_click',
                      event_label: `Comparability Background Information cell click`,
                      container_id: workingContainer?.containerId
                    });
                  }}
                >
                  {uncontrolledTransaction.backgroundInformation
                    ? t(
                        `analysis:tba-uncontrolled-transaction-comparability-option-${String(
                          uncontrolledTransaction.backgroundInformation
                        ).toLowerCase()}`
                      )
                    : t(`analysis:tba-uncontrolled-transaction-comparability-option-similar`)}
                </DropDownButton>
              ),
              functionalAnalysis: (
                <DropDownButton
                  variant="text"
                  className={classes.statusDropDown}
                  items={comparabilityOptions.map((cOption: { name: string; id: number }) => ({
                    children: (
                      <Typography className={`${classes.dropDown}`}>
                        {t(
                          `analysis:tba-uncontrolled-transaction-comparability-option-${String(
                            cOption.name
                          ).toLowerCase()}`
                        )}
                      </Typography>
                    ),
                    onClick: () => {
                      // todo: update via api (see DUO-1430)
                      void logGoogleAnalyticsEvent({
                        event_category: 'tba_button_click',
                        event_label: `Comparability ${cOption.name} Functional Analysis Update`,
                        container_id: workingContainer?.containerId
                      });
                      uncontrolledTransaction.functionalAnalysis = cOption.name;
                      uncontrolledTransaction.functionalAnalysisSimilarity = cOption.id;
                      onApplyDescription(uncontrolledTransaction);
                    }
                  }))}
                  onItemClick={() => {
                    void logGoogleAnalyticsEvent({
                      event_category: 'tba_button_click',
                      event_label: `Comparability Functional Analysis cell click`,
                      container_id: workingContainer?.containerId
                    });
                  }}
                >
                  {uncontrolledTransaction.functionalAnalysis
                    ? t(
                        `analysis:tba-uncontrolled-transaction-comparability-option-${String(
                          uncontrolledTransaction.functionalAnalysis
                        ).toLowerCase()}`
                      )
                    : t(`analysis:tba-uncontrolled-transaction-comparability-option-similar`)}
                </DropDownButton>
              ),
              contractualTerms: (
                <DropDownButton
                  variant="text"
                  className={classes.statusDropDown}
                  items={comparabilityOptions.map((cOption: { name: string; id: number }) => ({
                    children: (
                      <Typography className={`${classes.dropDown}`}>
                        {t(
                          `analysis:tba-uncontrolled-transaction-comparability-option-${String(
                            cOption.name
                          ).toLowerCase()}`
                        )}
                      </Typography>
                    ),
                    onClick: () => {
                      // todo: update via api (see DUO-1430)
                      void logGoogleAnalyticsEvent({
                        event_category: 'tba_button_click',
                        event_label: `Comparability ${cOption.name} Contractual Terms Update`,
                        container_id: workingContainer?.containerId
                      });
                      uncontrolledTransaction.contractualTerms = cOption.name;
                      uncontrolledTransaction.contractualTermsSimilarity = cOption.id;
                      onApplyDescription(uncontrolledTransaction);
                    }
                  }))}
                  onItemClick={() => {
                    // todo: update via api (see DUO-1430)
                    void logGoogleAnalyticsEvent({
                      event_category: 'tba_button_click',
                      event_label: `Comparability Contractual Terms cell click`,
                      container_id: workingContainer?.containerId
                    });
                  }}
                >
                  {uncontrolledTransaction.contractualTerms
                    ? t(
                        `analysis:tba-uncontrolled-transaction-comparability-option-${String(
                          uncontrolledTransaction.contractualTerms
                        ).toLowerCase()}`
                      )
                    : t(`analysis:tba-uncontrolled-transaction-comparability-option-similar`)}
                </DropDownButton>
              ),
              description: (
                <Button
                  onClick={() => {
                    // todo: update via api (see DUO-1430)
                    void logGoogleAnalyticsEvent({
                      event_category: 'tba_button_click',
                      event_label: `Comparability Description click`,
                      container_id: workingContainer?.containerId
                    });
                    setSelectedRow(uncontrolledTransaction);
                    setOpenDescriptionModal(true);
                  }}
                >
                  <TextGeneric />
                </Button>
              ),
              select: (
                <Checkbox
                  checked={selectedUncontrolledTransactionIds.includes(Number(uncontrolledTransaction?.transactionId))}
                  value={uncontrolledTransaction.transactionId}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (uncontrolledTransaction?.transactionId) {
                      selectUncontrolledTransaction(
                        selectedUncontrolledTransactionIds.indexOf(Number(event.target.value)),
                        Number(event.target.value)
                      );
                    }
                  }}
                />
              )
            }
          : {}
    );

    tableDisplayData = (sortData(
      filterData(mapToTableData, filterObject),
      sortObject
    ) as unknown) as CurrentSearchUncontrolledTransaction[];
  }

  const displayColumns = [
    {
      key: 'identifier',
      header: (
        <SortControl
          field={t('analysis:column-id')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'identifier', sortOrder });
          }}
        />
      ),
      width: 100
    },
    {
      key: 'entityName',
      header: (
        <SearchAndSort
          field={t('analysis:column-entities')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'entityName', sortOrder });
          }}
          onSearchChange={(value) => {
            setFilter({ ...filterObject, entityName: value });
          }}
        />
      ),
      width: 100
    },
    {
      key: 'acceptedStatus',
      header: (
        <SortControl
          field={t('analysis:column-source-range-accepted-status')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'acceptedStatus', sortOrder });
          }}
        />
      ),
      align: 'right' as MaterialUiTableCellProps['align'],
      width: 100
    },
    { key: 'backgroundInformation', header: t('analysis:background-information'), width: 100 },
    { key: 'functionalAnalysis', header: t('analysis:functional-analysis'), width: 100 },
    { key: 'contractualTerms', header: t('analysis:contractual-terms'), width: 100 },
    {
      key: 'description',
      header: t('analysis:description'),
      align: 'center' as MaterialUiTableCellProps['align'],
      width: 100
    },
    {
      key: 'select',
      header: (
        <Box marginLeft="0.25rem">
          <Checkbox
            color="primary"
            checked={selectedUncontrolledTransactionIds.length === uncontrolledTransactions.length}
            className={classes.indeterminateCheckbox}
            indeterminate={
              selectedUncontrolledTransactionIds.length !== uncontrolledTransactions.length &&
              selectedUncontrolledTransactionIds.length > 0
            }
            onChange={selectAllTransactions}
          />
        </Box>
      ),
      width: 10
    }
  ];

  const onCloseDescriptionModal = () => {
    setOpenDescriptionModal(false);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.tableWrapper}>
        <Table
          className={classes.scrollTable}
          data={tableDisplayData}
          columns={displayColumns}
          stickyCols={stickyCols}
        />
      </Box>
      {openDescriptionModal && (
        <ComparabilityDescriptionModal
          transactions={tableDisplayData}
          selectedTransaction={selectedRow}
          onClose={onCloseDescriptionModal}
          onApply={onApplyDescription}
        />
      )}
    </Box>
  );
};
