import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Paper, FormControl, Grid, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Editor } from '../../../components';
import { SegmentTypeEnum } from '../../../models';
import { selectWorkingContainer } from '../../../selectors';
import { sortAlphabetically, hasEditAccess } from '../../../utils';
import useDebounce from '../../../utils/debounce';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
import { PBASummaryInputs, TestedPartyDetailsFormProps } from '../PBADashboardTPDSummary.proptype';

const useStyles = makeStyles((theme) => ({
  formGroupContainer: {
    padding: theme.spacing(2)
  },
  testedPartyFormContainer: {
    backgroundColor: theme.palette.common.white
  },
  titleContainer: {
    '& > span': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        '& > h2': {
          fontWeight: 400
        },
        '& > p': {
          color: theme.palette.grey[600],
          paddingLeft: theme.spacing(1)
        }
      }
    }
  },
  inlineGroup: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2)
  },
  label: {
    color: theme.palette.grey[600],
    fontWeight: 600,
    paddingBottom: theme.spacing(0.5)
  },
  labelEditor: {
    paddingBottom: theme.spacing(1)
  },
  selectStyles: {
    marginTop: theme.spacing(0.5)
  },
  separated: {
    marginLeft: theme.spacing(2)
  },
  inputsSize: {
    width: '22rem',
    '& > *': {
      width: '100%'
    }
  },
  newField: {
    paddingTop: theme.spacing(2.2)
  }
}));

const segmentTypeOptions: any[] = [];
for (const type in SegmentTypeEnum) {
  if (SegmentTypeEnum) {
    const isNumber = Number.parseInt(type, 10);
    if (!isNumber) {
      segmentTypeOptions.push({ title: type, value: SegmentTypeEnum[type] });
    }
  }
}

segmentTypeOptions.sort((seg1, seg2) => {
  return seg1.title > seg2.title ? 1 : -1;
});

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const TestedPartyDetailsForm = ({
  currentTestedParty,
  pba,
  primaryFunctionOptions,
  onSubmit,
  trackGATime
}: TestedPartyDetailsFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const menuItems = sortAlphabetically(primaryFunctionOptions ? [...primaryFunctionOptions] : [], ({ title }) => title);
  const [primaryFunctionValue, setPrimaryFunctionValue] = useState(false);
  const container = useSelector(selectWorkingContainer);

  const defaultValues = {
    name: currentTestedParty?.name,
    segmentType: currentTestedParty?.segmentType,
    justification: currentTestedParty?.justification,
    primaryFunctionId: currentTestedParty?.primaryFunction.primaryFunctionId,
    otherPrimaryFunction: currentTestedParty?.otherPrimaryFunction
  };

  const formMethods = useForm<PBASummaryInputs>({
    defaultValues
  });

  const { handleSubmit, control, errors, reset } = formMethods;

  useEffect(() => {
    if (currentTestedParty) {
      const { name, segmentType, justification, primaryFunction, otherPrimaryFunction } = currentTestedParty;
      reset({
        name,
        segmentType,
        justification,
        primaryFunctionId: primaryFunction.primaryFunctionId,
        otherPrimaryFunction
      });
    }
  }, [currentTestedParty, reset]);

  const [handleDropdownAutoSave] = useDebounce(async () => {
    void handleSubmit(onSubmit)();
  });

  const [handleEditorAutoSave] = useDebounce(async () => {
    if (Object.keys(errors).length === 0) {
      void handleSubmit(onSubmit)();
    }
  }, 3000);

  useEffect(() => {
    if (currentTestedParty) {
      setPrimaryFunctionValue(Boolean(currentTestedParty.otherPrimaryFunction));
    }
  }, [currentTestedParty]);

  useEffect(() => {
    trackGATime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper className={classes.testedPartyFormContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} className={classes.formGroupContainer}>
          <Grid item xs={12}>
            <Box className={classes.titleContainer}>
              <span>
                <Box>
                  <Typography variant="h2">{t('analysis:title-tested-party')}</Typography>
                  <Typography>{pba?.testedParty?.name}</Typography>
                </Box>
              </span>
            </Box>
          </Grid>
          {currentTestedParty ? (
            <Grid item xs={6}>
              <Box className={classes.inlineGroup}>
                <Box className={classes.inputsSize}>
                  <Typography className={classes.label}>{t('analysis:column-tested-party-name')}</Typography>
                  <Controller
                    name="name"
                    render={({ onChange, value }) => (
                      <TextField
                        id="name"
                        name="name"
                        margin="dense"
                        variant="outlined"
                        value={value}
                        placeholder={t('analysis:column-tested-party-name')}
                        disabled={!hasEditAccess()}
                        onChange={onChange}
                        onBlur={async () => handleSubmit(onSubmit)()}
                      />
                    )}
                    control={control}
                    defaultValue={defaultValues.name}
                    rules={{ required: true }}
                    error={Boolean(errors.name)}
                  />
                  <ErrorMessage
                    error={Boolean(errors.name)}
                    message={t('analysis:invalid-entry-required-tested-party-name')}
                  />
                </Box>
                <Box className={`${classes.separated} ${classes.inputsSize}`}>
                  <Typography className={classes.label}>{t('analysis:column-segment-type')}</Typography>
                  <FormControl className={classes.selectStyles} size="small" variant="outlined">
                    <Controller
                      as={
                        <Select displayEmpty disabled={!hasEditAccess()}>
                          <MenuItem disabled value="">
                            <div>{t('analysis:title-add-segment-type')}</div>
                          </MenuItem>
                          {segmentTypeOptions.map(({ value, title }) => (
                            <MenuItem key={value} value={value} onClick={handleDropdownAutoSave}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="segmentType"
                      control={control}
                      defaultValue={defaultValues.segmentType}
                      rules={{ required: false }}
                      error={Boolean(errors.segmentType)}
                    />
                  </FormControl>
                  <ErrorMessage
                    error={Boolean(errors.segmentType)}
                    message={t('analysis:invalid-entry-required-segment-type')}
                  />
                </Box>
              </Box>
              <Box className={classes.inlineGroup}>
                <Box className={classes.inputsSize}>
                  <Typography className={classes.label}>{t('analysis:column-primary-function')}</Typography>
                  <FormControl className={classes.selectStyles} size="small" variant="outlined">
                    <Controller
                      as={
                        <Select
                          displayEmpty
                          disabled={!hasEditAccess()}
                          id="field-newui-overview-pba-primary-function-input"
                        >
                          <MenuItem disabled value="">
                            <div>{t('analysis:placeholder-primary-function')}</div>
                          </MenuItem>
                          {menuItems.map(({ value, title }) => (
                            <MenuItem
                              key={value}
                              value={value}
                              onClick={() => {
                                /* eslint-disable camelcase */
                                void logGoogleAnalyticsEvent({
                                  event_category: 'pba_button_click',
                                  event_label: `Primary Function Dropdown click`,
                                  container_id: container?.containerId
                                });
                                const showNewField = title.toLocaleLowerCase().includes('other');
                                setPrimaryFunctionValue(showNewField);
                                handleDropdownAutoSave();
                              }}
                            >
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      name="primaryFunctionId"
                      control={control}
                      defaultValue={defaultValues.primaryFunctionId}
                      rules={{ required: false }}
                      error={Boolean(errors.primaryFunctionId)}
                    />
                  </FormControl>
                  <ErrorMessage
                    error={Boolean(errors.primaryFunctionId)}
                    message={t('analysis:invalid-entry-primary-function')}
                  />
                </Box>
                {primaryFunctionValue && (
                  <Box className={`${classes.separated} ${classes.inputsSize} ${classes.newField}`}>
                    <Controller
                      name="otherPrimaryFunction"
                      render={({ onChange, value }) => (
                        <TextField
                          id="otherPrimaryFunction"
                          name="otherPrimaryFunction"
                          margin="dense"
                          variant="outlined"
                          value={value}
                          inputProps={{ maxLength: 75 }}
                          disabled={!hasEditAccess()}
                          onChange={onChange}
                          onBlur={async () => handleSubmit(onSubmit)()}
                        />
                      )}
                      control={control}
                      defaultValue={defaultValues.otherPrimaryFunction}
                      rules={{ required: true }}
                      error={Boolean(errors.otherPrimaryFunction)}
                    />
                    <ErrorMessage
                      error={Boolean(errors.otherPrimaryFunction)}
                      message={t('analysis:invalid-entry-required-tested-party-otherPrimaryFunction')}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          ) : null}
          {currentTestedParty ? (
            <Grid item xs={6}>
              <Box>
                <Typography className={`${classes.label} ${classes.labelEditor}`}>
                  {t('analysis:column-tested-party-justification')}
                </Typography>
                <Controller
                  name="justification"
                  render={({ onChange, value }) => (
                    <Editor
                      theme="TransferPricing"
                      id="justification"
                      init={{ height: '250' }}
                      value={value}
                      error={Boolean(errors.justification)}
                      disabled={!hasEditAccess()}
                      onEditorChange={(content) => {
                        onChange(content);
                        handleEditorAutoSave();
                      }}
                      onBlur={async () => handleSubmit(onSubmit)()}
                    />
                  )}
                  control={control}
                  rules={{ required: false }}
                  defaultValue={defaultValues.justification}
                  error={Boolean(errors.justification)}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </form>
    </Paper>
  );
};
