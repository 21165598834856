import { Entity } from './entities.interface';

export enum FinancialDataValueEnteredInEnum {
  MNE = 1,
  Entity
}

export enum FinancialDataObjectTypeEnum {
  ENTITY = 1,
  MNE,
  TESTED_PARTY
}

export interface FinancialType {
  financialTypeId: number;
  sortOrder: number;
  name: string;
}

export interface FinancialData {
  taxYear: number;
  value: number | string;
  valueEnteredIn: number;
  entityFinancialDataId?: number;
  testedPartyFinancialDataId?: number;
  financialType: FinancialType;
}

export interface FinancialInfo {
  financialAreaId: number;
  financialDataObjectTypeId: number;
  valueEnteredIn?: number;
}

export interface FinancialTypesEnums {
  Id: number;
  Name: string;
  displayName?: string;
}

export interface FinancialCompletionStatus {
  entityId: number;
  taxYear: number;
  financialAreaId: number;
  isCompleted: boolean;
}
export interface FinancialConversion {
  taxYear: number;
  conversionValue: number;
  localCurrencyScale: number;
  legalEntity: Entity;
}
