import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PayloadAction } from '@reduxjs/toolkit';
import { ConnectorProps, FunctionalAnalysisObjectTypeEnum } from './PBAFunctionalAnalysis.proptype';
import {
  AreaIds,
  FunctionalAnalysisCharacteristic
} from '../../components/FunctionalAnalysis/FunctionalAnalysisTable.proptype';
import { SearchFunctionalAnalysisWizard } from '../../components/FunctionalAnalysis/SearchFunctionalAnalysisWizard';
import { StandardizedFunctionalAnalysisWizard } from '../../components/FunctionalAnalysis/StandardizedFunctionalAnalysisWizard';
import { Container, ProfitBasedAnalysis, Study } from '../../models';
import {
  fetchCurrentTestedParty,
  fetchFunctionalAnalysisCharacteristics,
  updateFunctionalAnalysisCharacteristic,
  reinitiaizeToFionaDefualts,
  deleteFunctionalAnalysisCharacteristic,
  createFunctionalAnalysisCharacteristic,
  resetFAContributionData,
  resetSelectedDiscussionText,
  fetchPBAsApiCall,
  updateFunctionalAnalysisNarrative
} from '../../redux/profitBasedAnalyses';
import { fetchStudies } from '../../redux/studies';
import {
  selectCurrentTestedParty,
  selectFunctionalAnalysisCharacteristics,
  selectStudiesList,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { decodeTokens, getAuthInfo, hasEditAccess } from '../../utils';
import { logGoogleAnalyticsTimeSpent } from '../../utils/sendGoogleAnalyticaEvent';
import { TimeTrackingEvents } from '../MethodEvaluation/hooks/useTrackingTime';

type functionalAnalysisCharacteristicsKeys = 'Functions' | 'Risks' | 'Assets';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const [selectedAnalysis, setSelectedAnalysis] = useState<FunctionalAnalysisCharacteristic>();
  const [currentSection, setCurrentSection] = useState<number>(1);
  const functionalAnalysisCharacteristics = useSelector(selectFunctionalAnalysisCharacteristics);
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const [FASingleApplyStatus, setFASingleApplyStatus] = useState<boolean>(false);
  const [FABulkApplyStatus, setFABulkApplyStatus] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const studies: Study[] | null = useSelector(selectStudiesList);
  const currentContainer: Container | undefined = useSelector(selectWorkingContainer);
  const [sortedStudies, setSortedStudies] = useState<Study[] | null>(null);
  const [PBAs, setPBAs] = useState<ProfitBasedAnalysis[] | null>(null);
  const [sortedPBAs, setSortedPBAs] = useState<ProfitBasedAnalysis[] | null>(null);
  const [studyValue, setStudyValue] = useState<Study | null>(null);
  const [pbaValue, setPBAValue] = useState<ProfitBasedAnalysis | null>(null);
  const [showPBADropdown, setShowPBADropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken } = getAuthInfo();
  const userData = decodeTokens(authToken);

  useEffect(() => {
    if (studies === null) {
      void dispatch(fetchStudies());
    } else {
      const studiesCopy: Study[] = [...studies];
      studiesCopy.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });
      setSortedStudies(studiesCopy);
    }
  }, [studies, dispatch]);

  useEffect(() => {
    if (PBAs) {
      const pbaCopy: ProfitBasedAnalysis[] = [...PBAs];
      pbaCopy.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }

        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });
      setSortedPBAs(pbaCopy);
    }
  }, [PBAs]);

  const handleStudySelect = async (newValue: Study | null) => {
    setStudyValue(newValue);
    if (newValue?.studyId) {
      const PBAs = await fetchPBAsApiCall(newValue.studyId);
      setPBAs(PBAs);
      setShowPBADropdown(true);
    } else {
      setShowPBADropdown(false);
    }

    setPBAValue(null);
  };

  const handlePBASelect = (newValue: any) => {
    setPBAValue(newValue);
  };

  const apply = async () => {
    if (
      currentContainer &&
      studyValue?.studyId &&
      pbaValue?.testedParty?.testedPartyId &&
      currentTestedParty?.testedPartyId
    ) {
      setIsLoading(true);
      const applyParams = {
        studyId: currentTestedParty.testedPartyId,
        type: FunctionalAnalysisObjectTypeEnum.PBA,
        container: currentContainer,
        sourceId: pbaValue.testedParty.testedPartyId,
        uncontrolled: false
      };
      await dispatch(updateFunctionalAnalysisNarrative({ params: applyParams }));

      const promises: Array<Promise<PayloadAction<unknown>>> = [];
      Object.values(AreaIds).forEach(async (id) => {
        if (Number.isInteger(id)) {
          promises.push(
            dispatch(
              fetchFunctionalAnalysisCharacteristics({
                functionalAnalysisId: currentTestedParty.testedPartyId,
                areaId: id as number
              })
            )
          );
        }
      });

      await Promise.all(promises);
      setIsLoading(false);
      closeDrawer();
      unsetAnalysis();
    }
  };

  useEffect(() => {
    if (functionalAnalysisCharacteristics && selectedAnalysis) {
      Object.keys(functionalAnalysisCharacteristics).forEach((key) => {
        functionalAnalysisCharacteristics[key as functionalAnalysisCharacteristicsKeys].forEach(
          (functionalAnalysisCharacteristic: FunctionalAnalysisCharacteristic) => {
            if (
              functionalAnalysisCharacteristic.pbaFunctionalAnalysisDataId ===
              selectedAnalysis.pbaFunctionalAnalysisDataId
            ) {
              setSelectedAnalysis(functionalAnalysisCharacteristic);
            }
          }
        );
      });
    }
  }, [functionalAnalysisCharacteristics, selectedAnalysis]);

  const editFunctionalAnalysisCharacteristic = async (
    characteristic: Partial<FunctionalAnalysisCharacteristic>,
    areaId?: number
  ) => {
    if (!hasEditAccess()) return;
    await dispatch(
      updateFunctionalAnalysisCharacteristic({
        characteristicId: characteristic.pbaFunctionalAnalysisDataId!,
        characteristic: {
          container: currentContainer,
          ...characteristic
        }
      } as { characteristicId: number; characteristic: Partial<FunctionalAnalysisCharacteristic> })
    );
    if (areaId) {
      await dispatch(
        fetchFunctionalAnalysisCharacteristics({
          functionalAnalysisId: currentTestedParty!.testedPartyId,
          areaId
        })
      );
    }
  };

  const createNewCharacteristic = async (characteristicName: string, areaId: number) => {
    if (!hasEditAccess()) return;
    const createdCharacteristic = await dispatch(
      createFunctionalAnalysisCharacteristic({
        functionalAnalysisId: currentTestedParty!.testedPartyId,
        areaId,
        characteristic: { characteristicName, container: currentContainer }
      })
    );
    if (createdCharacteristic?.payload) {
      setSelectedAnalysis({
        characteristicName,
        discussion: '',
        otherParticipantContribution: false,
        pbaFunctionalAnalysisDataId: (createdCharacteristic.payload as FunctionalAnalysisCharacteristic)
          .pbaFunctionalAnalysisDataId,
        sortOrder: (createdCharacteristic.payload as FunctionalAnalysisCharacteristic).sortOrder,
        testedPartyContribution: false
      });
    }

    await dispatch(
      fetchFunctionalAnalysisCharacteristics({
        functionalAnalysisId: currentTestedParty!.testedPartyId,
        areaId
      })
    );
  };

  const deleteFunctionalAnalysisCharacteristicRow = async (characteristicId: number, areaId: number) => {
    if (!hasEditAccess()) return;
    await dispatch(deleteFunctionalAnalysisCharacteristic(characteristicId));
    await dispatch(
      fetchFunctionalAnalysisCharacteristics({
        functionalAnalysisId: currentTestedParty!.testedPartyId,
        areaId
      })
    );
    setSelectedAnalysis((undefined as unknown) as FunctionalAnalysisCharacteristic);
  };

  const reinitialize = async () => {
    if (currentTestedParty?.testedPartyId) {
      await dispatch(reinitiaizeToFionaDefualts({ functionalAnalysisId: currentTestedParty.testedPartyId }));
    }
  };

  useEffect(() => {
    if (currentTestedParty) {
      Object.values(AreaIds).forEach(async (id) => {
        if (Number.isInteger(id)) {
          await dispatch(
            fetchFunctionalAnalysisCharacteristics({
              functionalAnalysisId: currentTestedParty.testedPartyId,
              areaId: id as number
            })
          );
        }
      });
    } else {
      void dispatch(fetchCurrentTestedParty(pbaId));
    }
  }, [currentTestedParty, dispatch, pbaId]);

  const unsetAnalysis = () => {
    setSelectedAnalysis((null as unknown) as FunctionalAnalysisCharacteristic);
  };

  const handleBulkApply = async () => {
    if (functionalAnalysisCharacteristics) {
      await reinitialize();
      await fetchNewSections();
      void dispatch(resetFAContributionData());
      setFABulkApplyStatus(false);
    }

    setIsDrawerOpen(false);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const fetchNewSections = async () => {
    if (selectedAnalysis?.functionalCharacteristicType) {
      dispatch(
        resetSelectedDiscussionText({ FAType: currentSection, name: selectedAnalysis?.characteristicName ?? '' })
      );
    }

    Object.values(AreaIds).forEach((id) => {
      if (currentTestedParty && Number.isInteger(id)) {
        void dispatch(
          fetchFunctionalAnalysisCharacteristics({
            functionalAnalysisId: currentTestedParty.testedPartyId,
            areaId: Number(id)
          })
        );
      }
    });
  };

  const trackGATime = () => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsTimeSpent({
      title: TimeTrackingEvents.CompSearchFunctionalAnalysis,
      page: '/custom/modal/CompSearchFunctionalAnalysis',
      user_id: userData?.userId ?? '',
      container_id: currentContainer?.containerId
    });
  };

  const selectedContent = [
    <StandardizedFunctionalAnalysisWizard
      key={0}
      FASingleApplyStatus={FASingleApplyStatus}
      FABulkApplyStatus={FABulkApplyStatus}
      selectedAnalysis={selectedAnalysis}
      onApply={async () => {
        setFASingleApplyStatus(true);
        if (selectedAnalysis?.functionalCharacteristicType) {
          dispatch(
            resetSelectedDiscussionText({ FAType: currentSection, name: selectedAnalysis?.characteristicName ?? '' })
          );
          await editFunctionalAnalysisCharacteristic(
            {
              discussion: selectedAnalysis.functionalCharacteristicType.discussion,
              discussionOnly: true,
              pbaFunctionalAnalysisDataId: selectedAnalysis.pbaFunctionalAnalysisDataId
            },
            currentSection
          );
        }

        setIsDrawerOpen(false);
        setFASingleApplyStatus(false);
      }}
      onBulkApply={() => {
        setFABulkApplyStatus(true);
        unsetAnalysis();
        void handleBulkApply();
      }}
    />,
    <SearchFunctionalAnalysisWizard
      key={1}
      studyValue={studyValue}
      sortedStudies={sortedStudies}
      handleStudySelect={handleStudySelect}
      isLoading={isLoading}
      apply={apply}
      showPBADropdown={showPBADropdown}
      sortedPBAs={sortedPBAs}
      pbaValue={pbaValue}
      handlePBASelect={handlePBASelect}
    />
  ];

  return (
    <Component
      functionalAnalysisCharacteristics={functionalAnalysisCharacteristics}
      editFunctionalAnalysisCharacteristic={editFunctionalAnalysisCharacteristic}
      selectedAnalysis={selectedAnalysis}
      setSelectedAnalysis={setSelectedAnalysis}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
      createNewCharacteristic={createNewCharacteristic}
      deleteFunctionalAnalysisCharacteristicRow={deleteFunctionalAnalysisCharacteristicRow}
      selectedContent={selectedContent}
      setIsDrawerOpen={setIsDrawerOpen}
      isDrawerOpen={isDrawerOpen}
      trackGATime={trackGATime}
    />
  );
};

export default Connector;
