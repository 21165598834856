import { useTranslation } from 'react-i18next';
import { Box, Paper, Button, makeStyles, Grid } from '@material-ui/core';
import { AssociatedTransactionsTable } from './AssociatedTransactionsTable';
import { CenteredProgress, ContentLayout } from '../../../components';
import editAccess from '../../../components/HigherOrderComponents/EditAccess';
import { AssociatedTransactionsProps } from '../PBADashboardTPDSummary.proptype';

const useStyles = makeStyles((theme) => ({
  transactionsContainer: {
    marginTop: '1.375rem',
    backgroundColor: theme.palette.common.white
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    width: '100%',
    whiteSpace: 'nowrap'
  },
  tableContainer: {
    padding: theme.spacing(2)
  },
  titleClass: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.light,
    margin: theme.spacing(1),
    borderRadius: '4px',
    textAlign: 'center',
    fontSize: '10px',
    padding: theme.spacing(1)
  }
}));

const onEdit = () => {
  console.log('🚀 ~ file: AssociatedTransactions.tsx ~ line 45 ~ onEdit ~ onEdit');
};

export const AssociatedTransactions = ({
  pba,
  transactionsPBA,
  entities = null,
  onAddTransactions,
  onDelete,
  upeCurrency
}: AssociatedTransactionsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.transactionsContainer}>
      {pba && transactionsPBA !== null && entities !== null ? (
        <Grid container spacing={3} className={classes.tableContainer}>
          <Grid item xs={12}>
            <ContentLayout
              withoutPadding
              title={
                <>
                  {t('analysis:title-associated-transactions')}
                  <span className={classes.titleClass}>{transactionsPBA?.length}</span>
                </>
              }
              actions={
                <Box className={classes.buttonContainer}>
                  {editAccess(
                    <Button color="primary" variant="contained" onClick={onAddTransactions}>
                      {t('transactions:action-add-choice')}
                    </Button>
                  )}
                </Box>
              }
              main={
                <AssociatedTransactionsTable
                  data={transactionsPBA}
                  entities={entities}
                  upeCurrency={upeCurrency}
                  analysisType="PBA"
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              }
            />
          </Grid>
        </Grid>
      ) : (
        <CenteredProgress />
      )}
    </Paper>
  );
};
