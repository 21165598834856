import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, LinearProgress, makeStyles } from '@material-ui/core';
import { FancyDropdown } from '../../components';
import { ProfitBasedAnalysis, Study, TransactionBasedAnalysis } from '../../models';
import tokens from '../../styles/designTokens';
import { body1, body2 } from '../../styles/typography';

const useStyles = makeStyles((theme) => ({
  title: {
    ...body2,
    marginTop: '0.6rem',
    marginBottom: '0.3rem'
  },
  majorCard: {
    minWidth: '16rem',
    maxWidth: '26.375rem',
    flex: '1 1 34rem',
    margin: '1rem',
    boxShadow: 'none',
    background: theme.palette.secondary.light
  },
  applyButtonLabel: {
    ...body1,
    color: '#6F5ACE', // this color is new! Ask Dylan / Matt if they're sure.
    marginTop: '0.8rem',
    marginBottom: '0.8rem'
  },
  primaryButton: {
    width: '100%',
    color: tokens.product100,
    ...tokens.mediumFont,
    backgroundColor: tokens.product25
  },
  loadingButton: {
    width: '100%',
    borderRadius: '4px',
    color: tokens.product100,
    backgroundColor: tokens.product25,
    height: '2.25rem',
    padding: '1rem',
    '& .MuiLinearProgress-indeterminate': {
      backgroundColor: tokens.white
    }
  }
}));

interface FunctionalAnalysisSearchProps {
  studyValue: Study | null;
  sortedStudies: Study[] | null;
  handleStudySelect: (newValue: Study | null) => Promise<void>;
  isLoading: boolean;
  apply: () => Promise<void>;
  showPBADropdown: boolean;
  sortedPBAs: ProfitBasedAnalysis[] | TransactionBasedAnalysis[] | null;
  pbaValue: ProfitBasedAnalysis | TransactionBasedAnalysis | null;
  handlePBASelect: (newValue: any) => void;
  isPba?: boolean;
}

export const SearchFunctionalAnalysisWizard = ({
  studyValue,
  sortedStudies,
  handleStudySelect,
  isLoading,
  apply,
  showPBADropdown,
  sortedPBAs,
  pbaValue,
  handlePBASelect,
  isPba = true
}: FunctionalAnalysisSearchProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.majorCard}>
      <Box className={classes.title}>{t('analysis:search_functional_analysis.select_analysis_title')}</Box>
      <FancyDropdown
        getOptionLabel={(study: { name: string }) => study.name}
        value={studyValue}
        noOptionsText={t('analysis:major-classification-label-no-match')}
        options={sortedStudies ? sortedStudies : []}
        placeholder={t('analysis:search_functional_analysis.select_analysis_placeholder')}
        onChange={(_: ChangeEvent<Record<string, unknown>>, value: Study | null) => {
          void handleStudySelect(value);
        }}
      />

      {showPBADropdown && sortedPBAs && (
        <>
          <Box className={classes.title}>
            {isPba
              ? t('analysis:search_functional_analysis.profit_based_analysis_title')
              : t('analysis:search_functional_analysis.transaction_based_analysis_title')}
          </Box>
          <FancyDropdown
            getOptionLabel={(pba: { name: string }) => {
              return pba.name;
            }}
            value={pbaValue}
            noOptionsText={t('analysis:major-classification-label-no-match')}
            options={sortedPBAs}
            placeholder={
              isPba
                ? t('analysis:search_functional_analysis.profit_based_analysis_placeholder')
                : t('analysis:search_functional_analysis.transaction_based_analysis_placeholder')
            }
            onChange={(
              _: ChangeEvent<Record<string, unknown>>,
              value: ProfitBasedAnalysis | TransactionBasedAnalysis | null
            ) => {
              handlePBASelect(value);
            }}
          />
          {pbaValue && (
            <>
              <Box className={classes.applyButtonLabel}>
                {t('analysis:search_functional_analysis.execute_replace_text')}
              </Box>
              {isLoading ? (
                <Box className={classes.loadingButton}>
                  <LinearProgress />
                </Box>
              ) : (
                <Button
                  className={classes.primaryButton}
                  disabled={isLoading}
                  onClick={() => {
                    void apply();
                  }}
                >
                  {t('analysis:action-apply')}
                </Button>
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};
