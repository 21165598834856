import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, makeStyles, Typography, Select, MenuItem } from '@material-ui/core';
import { TPModal } from '../../../components';
import { SavedPsgAnalysisTemplates } from '../../../models/profitBasedAnalyses.interface';
import { fetchSavedPsgAnalysisTemplates } from '../../../redux/profitBasedAnalyses';
import { selectSavedPsgAnalysisTemplates } from '../../../selectors/profitBasedAnalyses';
import { selectAllMajorClassifications } from '../../../selectors/testedParty';
import { AppDispatch } from '../../../store';
import { title2 } from '../../../styles/typography';
import { MajorClassification } from '../../TestedPartyServiceProvider/TestedPartyServiceProvider.proptype';
import { LoadDeletePSGAnalysisTemplateModalProps } from '../JurisdictionAnalysisInfo.proptype';

const useStyles = makeStyles((theme) => ({
  AnalysisTemplateModalForm: {},
  AnalysisTemplateModalDoneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  select: {
    maxWidth: '50%'
  }
}));

const testedPartyCharacterizationOptions = [
  {
    value: 'distributor',
    name: 'Distributor'
  },
  {
    value: 'serviceProvider',
    name: 'Service Provider'
  }
];

const classificationOptions = [
  {
    name: 'Non Value Added Distributor',
    value: 'nonValueAddedDistributor'
  },
  {
    name: 'Value Added Distributor',
    value: 'valueAddedDistributor'
  }
];

interface SelectOptions {
  value: string;
  name: string;
}

export const LoadDeletePSGAnalysisTemplateModal = ({
  title,
  isOpen,
  onClose,
  onDone
}: LoadDeletePSGAnalysisTemplateModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();

  const allMajorClassifications = useSelector(selectAllMajorClassifications);
  const allSavedPsgAnalysisTemplates = useSelector(selectSavedPsgAnalysisTemplates);
  const [selectedTestedParty, setSelectedTestedParty] = useState<SelectOptions | null>(null);
  const [
    selectedClassificationForDistributor,
    setSelectedClassificationForDistributor
  ] = useState<SelectOptions | null>(null);
  const [selectedClassificationsForServiceProvider, setSelectedClassificationsForServiceProvider] = useState<
    MajorClassification[] | null
  >(null);
  const [selectedAnalysisTemplate, setSelectedAnalysisTemplate] = useState<SavedPsgAnalysisTemplates | null>();

  useEffect(() => {
    void dispatch(fetchSavedPsgAnalysisTemplates());
  }, [dispatch]);

  const filteredPsgAnalysisTemplates = useMemo(() => {
    return allSavedPsgAnalysisTemplates?.filter((temp) => {
      if (selectedTestedParty?.value === 'serviceProvider') {
        return selectedClassificationsForServiceProvider?.every((classification) => {
          return temp.majorClassificationIds.includes(classification.majorServiceClassificationId);
        });
      }

      if (selectedClassificationForDistributor?.value === 'valueAddedDistributor' && temp.hasValueAdded === 1) {
        return true;
      }

      if (selectedClassificationForDistributor?.value === 'nonValueAddedDistributor' && temp.hasValueAdded === 0) {
        return true;
      }

      return false;
    });
  }, [
    allSavedPsgAnalysisTemplates,
    selectedClassificationForDistributor,
    selectedClassificationsForServiceProvider,
    selectedTestedParty
  ]);

  const handleCloseModal = () => {
    setSelectedTestedParty(null);
    setSelectedClassificationForDistributor(null);
    setSelectedClassificationsForServiceProvider(null);
    setSelectedAnalysisTemplate(null);
  };

  return (
    <TPModal
      maxWidth="sm"
      isOpen={isOpen}
      title={<Typography style={title2}>{title}</Typography>}
      actions={[
        {
          name: t('action-cancel'),
          handler: () => {
            setError('');
            onClose();
            handleCloseModal();
          }
        },
        {
          name: t('action-done'),
          className: classes.AnalysisTemplateModalDoneButton,
          handler: () => {
            if (selectedAnalysisTemplate) onDone(selectedAnalysisTemplate);
            setError('');
            handleCloseModal();
          }
        }
      ]}
      onClose={() => {
        onClose();
        handleCloseModal();
      }}
    >
      <Box display="flex" flexDirection="column" gridGap="2rem">
        <FormControl size="medium" variant="outlined" className={classes.AnalysisTemplateModalForm}>
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <Typography>{t(`analysis:title-tested-party-characterization`)}</Typography>
            <Select
              fullWidth
              className={classes.select}
              value={selectedTestedParty?.value ?? ''}
              onChange={({ target: { value } }) => {
                const selectedOption = testedPartyCharacterizationOptions.find((party) => party.value === value);
                if (selectedOption) setSelectedTestedParty(selectedOption);
              }}
            >
              {testedPartyCharacterizationOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </FormControl>

        <FormControl size="medium" variant="outlined" className={classes.AnalysisTemplateModalForm}>
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Classification</Typography>
            {selectedTestedParty?.value === 'serviceProvider' ? (
              <Select
                fullWidth
                multiple
                className={classes.select}
                value={
                  selectedClassificationsForServiceProvider?.length
                    ? selectedClassificationsForServiceProvider.map((classification) => classification.name)
                    : []
                }
                onChange={({ target: { value } }) => {
                  if (Array.isArray(value)) {
                    const selectedList: MajorClassification[] = [];
                    value.forEach((item) => {
                      const selectedItem = allMajorClassifications.find(
                        (classification) => classification.name === item
                      );
                      if (selectedItem) selectedList.push(selectedItem);
                      setSelectedClassificationsForServiceProvider(selectedList);
                    });
                  }
                }}
              >
                {allMajorClassifications.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    {t(`analysis:${item.name}`)}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                fullWidth
                className={classes.select}
                value={selectedClassificationForDistributor?.value ?? ''}
                onChange={({ target: { value } }) => {
                  const selectedOption = classificationOptions.find((classification) => classification.value === value);
                  if (selectedOption) setSelectedClassificationForDistributor(selectedOption);
                }}
              >
                {classificationOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
        </FormControl>

        <FormControl size="medium" variant="outlined" className={classes.AnalysisTemplateModalForm}>
          <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Analysis Template Name</Typography>
            <Select
              fullWidth
              className={classes.select}
              value={selectedAnalysisTemplate?.compSearchName ?? ''}
              onChange={({ target: { value } }) => {
                const selectedOption = allSavedPsgAnalysisTemplates?.find((temp) => temp.compSearchName === value);
                if (selectedOption) setSelectedAnalysisTemplate(selectedOption);
              }}
            >
              {filteredPsgAnalysisTemplates?.map((item) => (
                <MenuItem key={item.compSearchName} value={item.compSearchName}>
                  {item.compSearchName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </FormControl>
      </Box>
      {error ? (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      ) : null}
    </TPModal>
  );
};
