import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { FinancialAreas, FinancialTypes } from '@xbs/xbs-enums';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { fetchConversionRates } from '../../../redux/conversionRates';
import {
  fetchPBAs,
  fetchTestedPartyFinancials,
  fetchTestedPartyFinancialsInfo,
  updateTestedPartyFinancial
} from '../../../redux/profitBasedAnalyses';
import {
  selectCurrentPBA,
  selectUPECurrency,
  selectConversionRatesByEntity,
  selectEntityCurrency,
  selectWorkingContainer,
  selectTestedPartyIncomeStatement,
  selectTestedPartyAdditionalFinancialInfo
} from '../../../selectors';
import { calculateIncomeStatementFieldValues } from '../../../utils';
import { PbaDashboardFinancialProps, TableBodyDataProps } from '../../FinancialTable/FinancialTable.proptype';

interface ConnectorProps {
  readonly component: ComponentType<PbaDashboardFinancialProps>;
}
const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [featureFlagIsActive] = useFeatureFlags();
  const match: any = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');
  const studyId: number = Number(match.params.studyId) ?? null;
  const pba = useSelector(selectCurrentPBA);
  const testedPartyIncomeStatement = useSelector(selectTestedPartyIncomeStatement);
  const testedPartyFinancialData = testedPartyIncomeStatement;
  const hasFooter = false;
  const financialTableTitle = t(`analysis:income-statement`);
  const requiredFields = new Set([
    FinancialTypes.ByName.CostOfGoodsSold.Id,
    FinancialTypes.ByName.OperatingExpenses.Id,
    FinancialTypes.ByName.TotalNetSales.Id
  ]);

  useEffect(() => {
    dispatch(fetchConversionRates());
    if (pba) {
      if (pba.testedParty?.testedPartyId) {
        dispatch(
          fetchTestedPartyFinancials({
            testedPartyId: pba?.testedParty?.testedPartyId,
            financialTypeId: FinancialAreas.ByName.IncomeStatement.Id
          })
        );
        dispatch(fetchTestedPartyFinancialsInfo(pba?.testedParty?.testedPartyId));
      }
    } else {
      dispatch(fetchPBAs(studyId));
    }
  }, [dispatch, pba, studyId]);

  const entityId = pba?.primaryLegalEntity?.entityId;
  const financialConversions = useSelector(selectConversionRatesByEntity(entityId));
  const upeCurrency = useSelector(selectUPECurrency);
  const entityCurrency = useSelector(selectEntityCurrency(entityId));
  const workingContainer = useSelector(selectWorkingContainer);

  // valueEnteredIn: 1 always mean upeCurrency, so this option must come first.
  const currencyOptions = [upeCurrency!];
  if (entityCurrency && entityCurrency.currencyId !== upeCurrency?.currencyId) {
    currencyOptions.push(entityCurrency);
  }

  const additionalFinancialInfo = useSelector(selectTestedPartyAdditionalFinancialInfo);
  let testedPartyInfo: Record<string, any> = {};
  if (pba?.testedParty) {
    testedPartyInfo = {
      testedPartyName: pba.testedParty.name,
      fiscalYearEnd: String(pba?.primaryLegalEntity?.fiscalYearEnd ?? ''),
      testedPartyId: pba.testedParty.testedPartyId
    };
  }

  const headerInfo = [
    { label: t('analysis:column-tested-party-name'), value: testedPartyInfo.testedPartyName },
    { label: t('analysis:fiscal-year-end'), value: testedPartyInfo.fiscalYearEnd }
  ];

  const currentTaxYear = workingContainer?.taxYear ?? 0;
  const columnYears = [currentTaxYear, currentTaxYear - 1, currentTaxYear - 2, currentTaxYear - 3, currentTaxYear - 4];

  const onSaveData = async (data: any) => {
    const perYear: any = {};
    const changedYears = new Set<number>();

    data.forEach((row: any) => {
      columnYears.forEach((year) => {
        perYear[year] = perYear[year] ?? [];
        const value = row[year]?.value ?? null;

        if (row[year]) {
          const payload: any = {
            taxYear: year,
            value,
            financialType: {
              financialTypeId: FinancialTypes.ById[row.id].Id,
              name: FinancialTypes.ById[row.id].Name
            }
          };

          if (featureFlagIsActive('testedPartyPliCalculation')) {
            const hasPreviousData = row[year].data?.testedPartyFinancialDataId;
            if (hasPreviousData) {
              payload.testedPartyFinancialDataId = row[year].data.testedPartyFinancialDataId;
            }

            if (hasPreviousData || payload.value !== null) {
              perYear[year].push(payload);
              if (row[year].changed) {
                changedYears.add(year);
              }
            }
          } else {
            if (row[year].data) {
              payload.testedPartyFinancialDataId = row[year].data.testedPartyFinancialDataId;
            }

            if (row[year].value || row[year].value === '' || row[year].value === 0) {
              perYear[year].push(payload);
              if (row[year].changed) {
                changedYears.add(year);
              }
            }
          }
        }
      });
    });

    const promises: any = [];
    const { testedPartyId } = testedPartyInfo;

    [...changedYears].forEach((year) => {
      if (testedPartyId && perYear[year].length > 0 && workingContainer) {
        promises.push(
          dispatch(
            updateTestedPartyFinancial({
              testedPartyId,
              financialTypeId: FinancialAreas.ByName.IncomeStatement.Id,
              payload: {
                financialData: perYear[year],
                valueEnteredIn: 1,
                container: workingContainer,
                pbaId: pba?.pbaId
              }
            })
          )
        );
      }
    });

    await Promise.all(promises);
    if (pba?.testedParty?.testedPartyId) {
      dispatch(fetchTestedPartyFinancialsInfo(pba?.testedParty?.testedPartyId));
    }
  };

  const isFieldValueValid = (data: TableBodyDataProps, year: string) => {
    let isValid = true;

    if (requiredFields.has(data.id) && !data[year].notAvailable) {
      isValid = false;
    }

    return isValid;
  };

  const isColumnValid = (columnData: TableBodyDataProps[], year: string) => {
    let atLeastOneRequiredFieldIsEmpty = false;
    let allFieldsEmpty = true;

    columnData.forEach((data) => {
      if (requiredFields.has(data.id)) {
        if (data[year].notAvailable) {
          allFieldsEmpty = false;
        } else {
          atLeastOneRequiredFieldIsEmpty = true;
        }
      }
    });
    const columnIsValid = allFieldsEmpty ? true : !atLeastOneRequiredFieldIsEmpty;
    return columnIsValid;
  };

  return (
    <Component
      title={financialTableTitle}
      headerInfo={headerInfo}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      hasFooter={hasFooter}
      tableData={testedPartyFinancialData}
      financialFieldIds={FinancialTypes.ByType.IncomeStatement}
      financialReadOnlyFieldIds={[FinancialTypes.ByName.GrossProfit.Id, FinancialTypes.ByName.OperatingProfit.Id]}
      columnYears={columnYears}
      isFieldValueValid={isFieldValueValid}
      isColumnValid={isColumnValid}
      calculateFieldValues={calculateIncomeStatementFieldValues}
      saveData={onSaveData}
      testedPartyId={pba?.testedParty?.testedPartyId}
      additionalFinancialInfo={additionalFinancialInfo}
      workingContainer={workingContainer}
    />
  );
};

export default Connector;
