import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';
import { title3 } from '../../../styles/typography';
import { Table } from '../../Table';
import { HistoryViewComponentProps, HistoryData } from '../CopyContainerModal.proptypes';

const useStyles = makeStyles((theme) => ({
  historyContainer: {
    height: '100%',
    width: '100%'
  },
  paperContainer: {
    alignItems: 'stretch',
    display: 'flex',
    flex: '1 1 0',
    width: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    overflow: 'inherit',
    width: '100%',
    padding: '1rem',
    overflowY: 'auto'
  }
}));

const HistoryViewComponent = ({ historyData }: HistoryViewComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const displayableHistoryData = useMemo(
    () =>
      (historyData ?? []).map((record: HistoryData) => {
        return {
          action: record.actionName,
          modified: record.date ? new Date(record.date).toString().slice(0, 25) : 'No historical data available',
          user: record.userName
        };
      }),
    [historyData]
  );

  return (
    <Box className={classes.paperContainer}>
      <Paper className={classes.paper}>
        <Box className={classes.historyContainer}>
          <Box marginBottom="1rem">
            <Typography style={{ ...title3 }}>{t('common:copy-container-container-history')}</Typography>
          </Box>
          <Table
            data={displayableHistoryData}
            columns={[
              { key: 'action', header: t('common:copy-container-action') },
              { key: 'modified', header: t('common:copy-container-modified') },
              { key: 'user', header: t('common:copy-container-user') }
            ]}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default HistoryViewComponent;
