import { ProductNames } from '@xbs/xbs-enums';
import * as xbsPlatformModule from '@xbs/xbs-platform-module';
import { getAppConfig } from './../../utils/configuration';

let platformModule;
export const getPlatformModule = () => {
  if (!platformModule) {
    const appConfig = getAppConfig();
    platformModule = xbsPlatformModule.getInstance(ProductNames.ByName.Tpiq.Uuid, appConfig.xbsEnvironmentShort);
  }

  return platformModule;
};
