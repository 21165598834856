import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { tpiqRoutes } from './constants';
import { Layout } from '../../components';
import GoogleAnalyticsProvider from '../../hocs/GoogleAnalyticsProvider';
import { getAuthInfo, decodeTokens, handleRoleRedirect, hasRfRbAccess } from '../../utils';
import { Header } from '../Header';
import { RollingContainers } from '../RollingContainers';
import { SideMenu } from '../SideMenu';

const Router = () => {
  const flags = useFlags();
  const { authToken } = getAuthInfo();
  const decodedToken = decodeTokens(authToken);
  const userRole = decodedToken?.role;
  const roleFilteredRoutes = tpiqRoutes.filter((route) => {
    const shouldIncludeRoute = userRole ? route.roles.includes(userRole) : true;
    return shouldIncludeRoute;
  });
  const routesToDisplay = roleFilteredRoutes.length === 0 ? tpiqRoutes : roleFilteredRoutes;

  return (
    <BrowserRouter basename="/newexperience">
      <GoogleAnalyticsProvider>
        <Layout
          header={<Header />}
          aside={<SideMenu />}
          main={
            <Switch>
              {routesToDisplay.map(({ path, component }) => (
                <Route key={path} path={path} component={component} />
              ))}
              <Route
                path="/admin"
                component={() => {
                  handleRoleRedirect(`${window.location.origin}/legacy/admin`);
                  return null;
                }}
              />
              {hasRfRbAccess() && flags.rollContainerNewUiBasicVersion ? (
                <Route path="/copy-containers" component={RollingContainers} />
              ) : null}
              <Redirect to={routesToDisplay[0].path} />
            </Switch>
          }
        />
      </GoogleAnalyticsProvider>
    </BrowserRouter>
  );
};

export default Router;
