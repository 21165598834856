import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Box } from '@mui/material';
import { RollingContainerProps } from './RollingContainer.proptype';
import { getIsRollable, rollContainer as _rollContainer, resetCanRollButton } from '../../redux/rollingContainers';
import { RollDirection } from '../../redux/rollingContainers/rollingContainers.proptype';
import { useNotifications } from '../Notifications';

export const RollingContainers = ({ containerId, containerRollStatus, taxYear }: RollingContainerProps) => {
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  useEffect(() => {
    if (containerId) {
      dispatch(getIsRollable(containerId));
    }
  }, [containerId, dispatch]);

  const rollContainer = ({
    containerId,
    direction,
    taxYear
  }: {
    containerId: number;
    taxYear: number;
    direction: RollDirection;
  }) => {
    const newTaxYear = direction === 'FORWARD' ? taxYear + 1 : taxYear - 1;
    try {
      showNotification({ type: 'success', message: t('common:rolling-container-in-progress') });
      dispatch(_rollContainer({ containerId, newTaxYear }));
    } catch (error: unknown) {
      const errorMessage = t('common:rolling-in-progress');
      if (error instanceof Error) {
        console.error(errorMessage, error.message);
      } else {
        console.error(errorMessage, error);
      }
    }
  };

  const buttonsInfo: Array<{ direction: RollDirection; disabled: boolean }> = [
    {
      direction: 'BACK',
      disabled: !containerRollStatus.canRollBack
    },
    {
      direction: 'FORWARD',
      disabled: !containerRollStatus.canRollForward
    }
  ];

  return (
    <Box sx={{ m: 2, gap: 2, display: 'flex' }}>
      {buttonsInfo.map((item) => (
        <Button
          key={item.direction}
          role="button"
          variant="contained"
          color="primary"
          disabled={item.disabled}
          onClick={(e) => {
            e.preventDefault();
            dispatch(resetCanRollButton({ direction: item.direction }));
            rollContainer({
              containerId,
              taxYear,
              direction: item.direction
            });
          }}
        >
          Roll {item.direction.toLowerCase()}
        </Button>
      ))}
    </Box>
  );
};
