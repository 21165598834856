import { useTranslation } from 'react-i18next';
import { Typography, makeStyles } from '@material-ui/core';
import { DotProgression } from '@xbs/xbs-common-ui';
import { CenteredProgress } from '../../components';
import { DashboardCard } from '../DashboardCard';

const useStyles = makeStyles((theme) => ({
  completionText: {
    margin: theme.spacing(2, 0),
    color: theme.palette.text.secondary
  }
}));

export interface StatementOfFactsCardProps {
  title: string;
  message: string;
  completionStatuses?: boolean[];
  onNavigate: () => void;
}

export const StatementOfFactsCard = ({ title, message, completionStatuses, onNavigate }: StatementOfFactsCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <DashboardCard title={title} subtitle={message} onNavigate={onNavigate}>
      {completionStatuses ? (
        <>
          <Typography variant="h2" className={classes.completionText}>
            {t('label-completion-state', {
              completed: completionStatuses.filter(Boolean).length,
              total: completionStatuses.length
            })}
          </Typography>
          <DotProgression dots={completionStatuses} /> {/** todo: should this be hidden? */}
        </>
      ) : (
        <CenteredProgress />
      )}
    </DashboardCard>
  );
};
