import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Editor } from '../../components';
import { selectWorkingContainer } from '../../selectors';
import tokens from '../../styles/designTokens';
import { body2, body4, title2 } from '../../styles/typography';
import { ArrowMoveBackward, ArrowMoveForward, TextGenericColor } from '../../svgs';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { TbaUncontrolledTransactionComparability } from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';
import { TPModal } from '../TPModal';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    backgroundColor: tokens.neutral70
  },
  header: {
    display: 'inline-flex'
  },
  headerIcon: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    marginRight: '0.313rem',
    padding: '0.5rem',
    borderRadius: '25%'
  },
  moveButton: {
    float: 'right',
    backgroundColor: tokens.white,
    minWidth: '2.25rem',
    borderRadius: '0.25rem',
    margin: '0 0 0.75rem 0'
  },
  descriptionContainer: {
    paddingLeft: '0.313rem',
    paddingRight: '10px'
  },
  title2,
  body4,
  identifier: {
    ...body2,
    color: tokens.core2
  }
}));

export interface DescriptionModalProps {
  transactions: TbaUncontrolledTransactionComparability[];
  selectedTransaction: TbaUncontrolledTransactionComparability;
  onClose: () => void;
  onApply: (unControlledtransactionComparibilty: TbaUncontrolledTransactionComparability) => void;
}
const ComparabilityDescriptionModal = (modalProps: DescriptionModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const workingContainer = useSelector(selectWorkingContainer);

  const [selected, setSelected] = useState<any>(modalProps.selectedTransaction);

  const initIndex = modalProps.transactions.findIndex((transaction) => {
    return transaction.transactionComparabilityId === selected.transactionComparabilityId;
  });

  const [selectedIndex, setSelectedIndex] = useState<number>(initIndex);

  return (
    <TPModal
      isOpen
      maxWidth="sm"
      containerStyle={classes.contentWrapper}
      title={
        <Box className={classes.header}>
          <TextGenericColor className={classes.headerIcon} />
          <Typography className={classes.title2}>
            {t('analysis:tba-uncontrolled-transaction-comparability-description')}
          </Typography>
        </Box>
      }
      actions={[
        {
          name: t('action-cancel'),
          variant: 'outlined',
          color: 'primary',
          handler: () => {
            /* eslint-disable camelcase */
            void logGoogleAnalyticsEvent({
              event_category: 'tba_button_click',
              event_label: `Comparability Description Cancel click`,
              container_id: workingContainer?.containerId
            });
            modalProps.onClose();
          }
        },
        {
          name: t('action-apply-changes'),
          color: 'primary',
          variant: 'contained',
          handler: () => {
            /* eslint-disable camelcase */
            void logGoogleAnalyticsEvent({
              event_category: 'tba_button_click',
              event_label: `Comparability Description Update`,
              container_id: workingContainer?.containerId
            });
            modalProps.onApply(selected);
            modalProps.onClose();
          }
        }
      ]}
      onClose={() => {
        /* eslint-disable camelcase */
        void logGoogleAnalyticsEvent({
          event_category: 'tba_button_click',
          event_label: `Comparability Description Close click`,
          container_id: workingContainer?.containerId
        });
        modalProps.onClose();
      }}
    >
      <Box className={classes.descriptionContainer}>
        <Typography className={classes.identifier}>
          {selected?.identifier}
          <Button
            className={classes.moveButton}
            disabled={selectedIndex === modalProps.transactions.length - 1 || modalProps.transactions.length === 1}
            onClick={() => {
              const index = selectedIndex + 1;
              setSelectedIndex(index);
              setSelected(modalProps.transactions[index]);
              modalProps.onApply(selected);
            }}
          >
            <ArrowMoveForward pointerEvents="none" />
          </Button>
          <Button
            className={classes.moveButton}
            style={{
              marginRight: '0.313rem'
            }}
            disabled={selectedIndex === 0 || modalProps.transactions.length === 1}
            onClick={() => {
              const index = selectedIndex - 1;
              setSelectedIndex(index);
              setSelected(modalProps.transactions[index]);
              modalProps.onApply(selected);
            }}
          >
            <ArrowMoveBackward pointerEvents="none" />
          </Button>
        </Typography>
        <Box>
          <Typography className={classes.body4}>{selected?.entityName}</Typography>
        </Box>
        <Box>
          <Editor
            theme="TransferPricing"
            id="uncontrolledcomparibilitydescriptio "
            init={{ height: '250' }}
            value={selected?.comparabilityEvaluation ?? ''}
            onEditorChange={(content) => {
              const updatedText = selected;
              updatedText.comparabilityEvaluation = content;
              setSelected(updatedText);
            }}
          />
        </Box>
      </Box>
    </TPModal>
  );
};

export default ComparabilityDescriptionModal;
