import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectWorkingContainer } from '../selectors';
import { getAppConfig } from '../utils';

export const useFeatureFlags = () => {
  const { xbsEnvironmentShort } = getAppConfig();
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;
  const flags = useFlags();

  const featureFlagIsActive = (featureFlagName: string) => {
    const isActive =
      flags[featureFlagName] &&
      (flags[featureFlagName] === true ||
        flags[featureFlagName].includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
        flags[featureFlagName].includes(`${String(xbsEnvironmentShort)}-*`));

    return isActive;
  };

  return [featureFlagIsActive];
};
