import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import { AnalysisProps } from './Analysis.proptype';
import { Study, CustomStudy } from '../../models';
import { fetchEntities } from '../../redux/entities';
import { fetchStudies, saveStudy, deleteStudy } from '../../redux/studies';
import { selectEntitiesList, selectStudiesList } from '../../selectors';
import { AppDispatch } from '../../store';
import { StudyAddEditModal } from '../StudyAddEditModal';

const Connector = ({ component: Component }: { component: ComponentType<AnalysisProps> }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const studies = useSelector(selectStudiesList);
  const entities = useSelector(selectEntitiesList);
  const [isStudyModalOpen, setIsStudyModalOpen] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState<Study | null>(null);

  const handleClose = () => {
    setSelectedStudy(null);
    setIsStudyModalOpen(false);
  };

  const handleSubmit = async (data: Study) => {
    const { name, description, primaryEntity } = data;
    const study: CustomStudy = {
      studyId: selectedStudy?.studyId!,
      name,
      description,
      primaryEntity: { entityId: primaryEntity.entityId }
    };
    unwrapResult(await dispatch(saveStudy({ study, t })));
    void dispatch(fetchStudies());
    handleClose();
  };

  useEffect(() => {
    if (studies === null) {
      void dispatch(fetchStudies());
    }
  }, [dispatch, studies]);

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  return (
    <>
      <Component
        studies={studies}
        onAddSingle={() => {
          setIsStudyModalOpen(true);
        }}
        onDelete={async (studyId) => {
          unwrapResult(await dispatch(deleteStudy(studyId)));
          void dispatch(fetchStudies());
        }}
        onEdit={(studyId) => {
          const study = studies?.find((s) => s.studyId === studyId) ?? null;
          setSelectedStudy(study);
          setIsStudyModalOpen(true);
        }}
        onNavigate={(path) => {
          history.push(path);
        }}
      />
      {isStudyModalOpen && (
        <StudyAddEditModal entities={entities!} study={selectedStudy} onClose={handleClose} onSubmit={handleSubmit} />
      )}
    </>
  );
};

export default Connector;
