import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { EntityDetailsIncomeStatementProps } from './EntityDetailsIncomeStatement.proptype';
import { Editor } from '../../../components/Editor';
import { updateEntityFinancialsInfo } from '../../../redux/entityFinancialV2';
import { body3, title3 } from '../../../styles/typography';
import { hasEditAccess } from '../../../utils';
import useDebounce from '../../../utils/debounce';
import { FinancialTableV2 } from '../../FinancialTableV2';

const useStyles = makeStyles(() => ({
  editorContainer: {
    width: '55vw',
    margin: '5vh auto'
  }
}));

export const EntityDetailsIncomeStatement = ({
  hasFooter,
  title,
  tableData,
  financialReadOnlyFieldIds,
  financialFieldIds,
  columnYears,
  completionByYear,
  currencyOptions,
  financialConversions,
  onSetCompletion,
  isFieldValueValid,
  isColumnValid,
  calculateFieldValues,
  saveData: onSaveData,
  entityId,
  additionalFinancialInfo,
  workingContainer
}: EntityDetailsIncomeStatementProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [editorText, setEditorText] = useState<string>('');

  useEffect(() => {
    setEditorText(additionalFinancialInfo!);
  }, [additionalFinancialInfo]);

  const [saveAdditionalFinancialInfo] = useDebounce((content: string) => {
    if (!hasEditAccess()) return;
    dispatch(
      updateEntityFinancialsInfo({
        container: workingContainer!,
        financialsInfo: content,
        entityId: entityId!
      })
    );
  }, 3000);

  return (
    <Box>
      <FinancialTableV2
        title={title}
        currencyOptions={currencyOptions}
        financialConversions={financialConversions}
        hasFooter={hasFooter}
        tableData={tableData}
        financialReadOnlyFieldIds={financialReadOnlyFieldIds}
        financialFieldIds={financialFieldIds}
        columnYears={columnYears}
        isFieldValueValid={isFieldValueValid}
        isColumnValid={isColumnValid}
        calculateFieldValues={calculateFieldValues}
        completionByYear={completionByYear}
        saveData={onSaveData}
        namespace="entity-income-statement"
        onSetCompletion={onSetCompletion}
      />
      <Box className={classes.editorContainer}>
        <Typography style={{ ...title3 }}>{t('entities:additional-financial-info-title')}</Typography>
        <Typography style={{ ...body3 }}>{t('entities:additional-financial-info-description')}</Typography>
        <Editor
          theme="TransferPricing"
          id="pbaDashboardIncomeStatement"
          init={{ height: '18.75rem' }}
          value={editorText}
          disabled={!hasEditAccess()}
          onEditorChange={(content) => {
            setEditorText(content!);
            saveAdditionalFinancialInfo(content);
          }}
          onBlur={() => {
            dispatch(
              updateEntityFinancialsInfo({
                container: workingContainer!,
                financialsInfo: editorText,
                entityId: entityId!
              })
            );
          }}
        />
      </Box>
    </Box>
  );
};
