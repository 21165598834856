import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Chip, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { getRollingForecast } from '../../../redux/rollingContainers';
import { selectRollingForecast, selectWorkingContainer } from '../../../selectors';
import tokens from '../../../styles/designTokens';
import { body1, body4, captionOverline } from '../../../styles/typography';
import { ArrowMove } from '../../../svgs';
import { ContainerModalViewKeys } from '../CopyContainerModal.proptypes';

const useStyles = makeStyles(() => ({
  cardContainer: {
    width: '75%',
    height: '100%'
  },
  copyContainerTitle: {
    ...body4,
    color: tokens.core1,
    marginBottom: '1.65rem'
  },
  divider: {
    border: '1px solid',
    borderColor: tokens.neutral90,
    marginBottom: '1.65rem'
  },
  navCard: {
    width: '100%',
    height: '7.5rem',
    padding: '1.875rem 2.25rem',
    backgroundColor: tokens.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.25rem',
    marginBottom: '1rem'
  },
  contentRight: {
    width: '75%',
    height: '100%'
  },
  contentLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleNavContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  titleNavBtn: {
    ...body4
  },
  copyContainerOptions: {
    '&.update': {
      backgroundColor: tokens.mustard,
      color: tokens.darkMustard,
      marginLeft: '1rem',
      ...captionOverline
    },
    '&.new': {
      backgroundColor: tokens.purpleLight2,
      color: tokens.white,
      marginLeft: '1rem',
      ...captionOverline
    }
  },
  navCardText: {
    ...body1,
    color: tokens.core2,
    marginTop: '0.5rem'
  }
}));

const CopyContainerModalHome = ({
  setView,
  setBackTo,
  canRollBack,
  canRollForward,
  cantRollBackReason,
  cantRollForwardReason
}: {
  setView: Dispatch<SetStateAction<ContainerModalViewKeys>>;
  setBackTo: Dispatch<SetStateAction<ContainerModalViewKeys | null>>;
  canRollForward: boolean;
  canRollBack: boolean;
  cantRollBackReason: string | null;
  cantRollForwardReason: string | null;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workingContainer = useSelector(selectWorkingContainer);
  const containerId = workingContainer?.containerId;
  const rollForwardYear = workingContainer ? workingContainer.taxYear + 1 : null;
  const rollBackYear = workingContainer ? workingContainer.taxYear - 1 : null;
  const selectRollingContainerForecast = useSelector(selectRollingForecast);

  useEffect(() => {
    if (containerId && rollForwardYear) {
      dispatch(getRollingForecast({ direction: 'FORWARD', containerId, taxYear: rollForwardYear }));
    }
  }, [containerId, dispatch, rollForwardYear, workingContainer]);

  useEffect(() => {
    if (containerId && rollBackYear) {
      dispatch(getRollingForecast({ direction: 'BACK', containerId, taxYear: rollBackYear }));
    }
  }, [containerId, dispatch, rollBackYear, workingContainer]);

  const copyContainerOptions = (direction: string) => {
    let statusClass = '';
    let label = '';
    let description = '';
    let yearLabel = '';

    if (workingContainer && direction === 'forward') {
      yearLabel = 'Next Year';
      if (
        selectRollingContainerForecast.rollForwardForecast.isIncrementalRoll ||
        (!selectRollingContainerForecast.rollForwardForecast.isIncrementalRoll && !canRollForward)
      ) {
        statusClass = 'update';
        label = 'UPDATE';
        description =
          selectRollingContainerForecast.rollForwardForecast.lastRolledDate === '' ||
          selectRollingContainerForecast.rollForwardForecast.lastRolledBy === ''
            ? 'No historical data available'
            : `Container last copied on ${new Date(selectRollingContainerForecast.rollForwardForecast.lastRolledDate)
                .toString()
                .slice(4, 16)} by ${selectRollingContainerForecast.rollForwardForecast.lastRolledBy}`;
      } else {
        statusClass = 'new';
        label = 'CREATE NEW CONTAINER';
        description = `No container currently exists.`;
      }
    } else if (workingContainer && direction === 'back') {
      yearLabel = 'Previous Year';
      if (
        selectRollingContainerForecast.rollBackForecast.isIncrementalRoll ||
        (!selectRollingContainerForecast.rollBackForecast.isIncrementalRoll && !canRollBack)
      ) {
        statusClass = 'update';
        label = 'UPDATE';
        description =
          selectRollingContainerForecast.rollBackForecast.lastRolledDate === '' ||
          selectRollingContainerForecast.rollBackForecast.lastRolledBy === ''
            ? 'No historical data available'
            : `Container last copied on ${new Date(selectRollingContainerForecast.rollBackForecast.lastRolledDate)
                .toString()
                .slice(4, 16)} by ${selectRollingContainerForecast.rollBackForecast.lastRolledBy}`;
      } else {
        statusClass = 'new';
        label = 'CREATE NEW CONTAINER';
        description = `No container currently exists.`;
      }
    }

    return (
      <>
        <Box className={classes.titleNavContainer}>
          <Typography className={classes.titleNavBtn}>{yearLabel}</Typography>
          <Chip className={classes.copyContainerOptions + ' ' + statusClass} size="small" label={label} />
        </Box>
        <Typography className={classes.navCardText}>{description}</Typography>
      </>
    );
  };

  return (
    <Box className={classes.cardContainer}>
      <Typography className={classes.copyContainerTitle}>{t('common:copy-container-to')}…</Typography>

      <Box className={classes.divider} />

      <Box className={classes.navCard}>
        <Box className={classes.contentRight}>{copyContainerOptions('forward')}</Box>
        <Tooltip arrow title={cantRollForwardReason ?? ''}>
          <span>
            <Button
              disabled={!canRollForward}
              data-testid="go-to-copy-forward-confirmation-view"
              className={classes.contentLeft}
              onClick={() => {
                setView(ContainerModalViewKeys.CopyForwardConfirmation);
                setBackTo(ContainerModalViewKeys.Home);
              }}
            >
              <ArrowMove pointerEvents="none" />
            </Button>
          </span>
        </Tooltip>
      </Box>

      <Box className={classes.navCard}>
        <Box className={classes.contentRight}>{copyContainerOptions('back')}</Box>
        <Tooltip arrow title={cantRollBackReason ?? ''}>
          <span>
            <Button
              disabled={!canRollBack}
              data-testid="go-to-copy-back-confirmation-view"
              className={classes.contentLeft}
              onClick={() => {
                setView(ContainerModalViewKeys.CopyBackConfirmation);
                setBackTo(ContainerModalViewKeys.Home);
              }}
            >
              <ArrowMove pointerEvents="none" />
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default CopyContainerModalHome;
