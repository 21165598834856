import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { CompSearchState, PrimaryFunctions } from '@xbs/xbs-enums';
import { AnalysisSideMenuProps, csRunningStatus } from './AnalysisSideMenu.proptype';
import { CharacterizationTypeEnum, Distributor } from '../../app/TestedPartyDistributor';
import { csRunningStates } from '../../components/CompSearchAnimation/CompSearchAnimation.proptype';
import { Country } from '../../models';
import {
  fetchCompSearchStatus,
  fetchJurisdictionDescription,
  fetchPbaJurisdictions,
  fetchPBAPli,
  doneCSRunning,
  removeCurrentPbaJurisdictions,
  clearPbaData
} from '../../redux/profitBasedAnalyses';
import { clearTestedPartyData, fetchCurrentDistributor, fetchSICTypeOptions } from '../../redux/testedParty';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectCurrentTestedParty,
  selectTransactionByPbaId,
  selectPBAPli,
  selectPbaJurisdictionCountry,
  selectCurrentPBA,
  selectcsStatus,
  selectPbaJurisdictions,
  selectJurisdictionDescription,
  selectCurrentMajorClassification,
  selectCurrentValueAddedDistributor
} from '../../selectors';
import { AppDispatch } from '../../store';
import { PliValues } from '../ProfitLevelIndicator/ProfitLevelIndicator.proptype';
import { MajorClassification } from '../TestedPartyCharacterization';

const Connector = ({ component: Component }: { component: ComponentType<AnalysisSideMenuProps> }) => {
  const [currentCSStatus, setCurrentCSStatus] = useState<csRunningStatus>('Not Running');
  const pbaJurisdictionDetails = useSelector(selectPbaJurisdictions);
  const jurisdictionDescription1 = useSelector(
    selectJurisdictionDescription(pbaJurisdictionDetails?.[0] ? pbaJurisdictionDetails[0].jurisdictionId : null)
  );
  const jurisdictionDescription2 = useSelector(
    selectJurisdictionDescription(pbaJurisdictionDetails?.[1] ? pbaJurisdictionDetails[1].jurisdictionId : null)
  );
  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);
  const currentValueAddedDistributor: Distributor = useSelector(selectCurrentValueAddedDistributor);
  const pbaJurisdictions: Country[] = useSelector(selectPbaJurisdictionCountry);
  const pba = useSelector(selectCurrentPBA);
  const match: any = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { studyId } = match.params;
  const { pbaId } = match.params;
  const jurisdictionId: number = jurisdictionDescription1
    ? jurisdictionDescription1?.jurisdictionId
    : jurisdictionDescription2?.jurisdictionId;
  const csRunningStatus = useSelector(selectcsStatus);

  const navigateToAnalysis = () => {
    if (studyId && pbaId) {
      const path = `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/jurisdiction/${String(
        jurisdictionId
      )}/jurisdiction-analysis-info`;
      history.push(path);
    }
  };

  const testedParty = useSelector(selectCurrentTestedParty);
  const transactionsPBA = useSelector(selectTransactionByPbaId(Number(pbaId)));
  const pbaPli = useSelector(selectPBAPli);

  const isDetailsComplete = Boolean(
    testedParty?.name &&
      testedParty?.justification &&
      testedParty?.segmentType &&
      (testedParty?.primaryFunction.name || testedParty?.otherPrimaryFunction) &&
      transactionsPBA &&
      transactionsPBA.length > 0 &&
      pbaPli?.pliValues.some((val: PliValues) => val.pliValue)
  );

  function assessCharacterization() {
    if (PrimaryFunctions.ByType.ServiceProvider.includes(testedParty?.primaryFunction?.primaryFunctionId)) {
      return (
        currentMajorClassifications.length > 0 &&
        currentMajorClassifications.every((major) => major.minorServiceClassifications.length)
      );
    }

    return Boolean(currentValueAddedDistributor.codeType && currentValueAddedDistributor.industrialCodeValue);
  }

  const isCharacterizationComplete = testedParty ? assessCharacterization() : false;
  const searchList = pbaJurisdictions.map((jurisdiction) => {
    const jurisdictionStatus = pba?.jurisdictionStatuses?.find(
      (status) => status.jurisdictionId === jurisdiction?.countryId
    );
    return {
      path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/jurisdiction/${
        jurisdiction?.countryId
      }/jurisdiction-analysis-info`,
      pathMatch: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/jurisdiction/${jurisdiction?.countryId}`,
      text: jurisdiction?.name,
      disabled: false,
      showProgressIcon: true,
      isComplete: true,
      status: jurisdictionStatus?.status
    };
  });
  useEffect(() => {
    void dispatch(fetchTransactions());
    void dispatch(fetchPBAPli(pbaId));
    void dispatch(fetchPbaJurisdictions(pbaId));
    void dispatch(fetchCurrentDistributor(pbaId));
    void dispatch(fetchSICTypeOptions(CharacterizationTypeEnum.TypeOfOption));

    return () => {
      void dispatch(clearPbaData());
      void dispatch(clearTestedPartyData());
    };
  }, [dispatch, pbaId]);

  useEffect(() => {
    if (pbaJurisdictionDetails && pbaJurisdictionDetails.length > 0) {
      for (const pbaJurisdictionDetail of pbaJurisdictionDetails) {
        void dispatch(
          fetchJurisdictionDescription({
            compSearchId: pbaJurisdictionDetail.compSearchId,
            jurisdictionId: pbaJurisdictionDetail.jurisdictionId
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(pbaJurisdictionDetails)]);

  useEffect(() => {
    let checkingStatus: NodeJS.Timeout;

    if (csRunningStatus === CompSearchState.ByName.Running.Id) {
      setCurrentCSStatus(csRunningStates.running);
      checkingStatus = setInterval(() => {
        void dispatch(fetchCompSearchStatus(pbaId));
      }, 2000);
    }

    return () => {
      clearInterval(checkingStatus);
    };
  }, [dispatch, csRunningStatus, pbaId]);

  useEffect(() => {
    if (csRunningStatus === CompSearchState.ByName.Successful.Id) {
      if (pbaJurisdictionDetails && pbaJurisdictionDetails.length > 0) {
        for (const pbaJurisdictionDetail of pbaJurisdictionDetails) {
          if (!pbaJurisdictionDetail.pbaJurisdictionInfo) {
            void dispatch(
              fetchJurisdictionDescription({
                compSearchId: pbaJurisdictionDetail.compSearchId,
                jurisdictionId: pbaJurisdictionDetail.jurisdictionId
              })
            );
          }
        }
      } else {
        void dispatch(fetchPbaJurisdictions(pbaId));
      }

      if (jurisdictionDescription1 || jurisdictionDescription2) {
        setCurrentCSStatus(csRunningStates.completed);
        void dispatch(doneCSRunning());
        void dispatch(fetchPbaJurisdictions(pbaId));
      }
    }

    const failedCsStates = [
      CompSearchState.ByName.Failed.Id,
      CompSearchState.ByName.OutOfSync.Id,
      CompSearchState.ByName.TimedOut.Id
    ];

    if (failedCsStates.includes(csRunningStatus)) {
      setCurrentCSStatus(csRunningStates.failed);
      void dispatch(doneCSRunning());
      void dispatch(removeCurrentPbaJurisdictions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    csRunningStatus,
    dispatch,
    pbaId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(pbaJurisdictionDetails),
    jurisdictionDescription1,
    jurisdictionDescription2
  ]);

  return (
    <Component
      currentCSStatus={currentCSStatus}
      selectedPath={pathname}
      setCurrentCSStatus={setCurrentCSStatus}
      pbaJurisdictionInfo={jurisdictionDescription1 ?? jurisdictionDescription2}
      navigateToAnalysis={navigateToAnalysis}
      sections={[
        {
          title: t('analysis:title-section-tested-party-setup'),
          items: [
            {
              path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/tested-party-details`,
              text: t('analysis:title-section-item-tested-party-details'),
              disabled: false,
              showProgressIcon: true,
              isComplete: isDetailsComplete
            },
            {
              path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/tested-party-characterization`,
              text: t('analysis:title-section-item-tested-party-characterization'),
              disabled: false,
              showProgressIcon: true,
              isComplete: isCharacterizationComplete
            },
            {
              path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/functional-analysis`,
              text: t('analysis:title-section-item-tested-functional-analysis'),
              disabled: false,
              showProgressIcon: false,
              isComplete: false
            }
          ]
        },
        {
          title: t('analysis:title-section-comparable-search'),
          items: [
            {
              path: `/analysis/${String(studyId)}/pba-dashboard/${String(pbaId)}/initial-search`,
              text: t('analysis:title-section-comparable-initial-search'),
              disabled: transactionsPBA?.length === 0,
              isButton: true,
              showProgressIcon: false,
              isComplete: false
            }
          ]
        },
        {
          title: t('analysis:title-section-search-results'),
          items: pbaJurisdictions.length > 0 ? searchList : []
        }
      ]}
      onNavigate={(path) => {
        history.push(`${path}`);
      }}
    />
  );
};

export default Connector;
