import { useTranslation } from 'react-i18next';
import { DialogContentText, Grid, Typography, Box, FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import { ImportMapping } from '../../../models';
import { ImportModalProps } from '../ImportModal.proptype';

const useStyles = makeStyles((theme) => ({
  formWidth: {
    width: '100%',
    padding: theme.spacing(0.5, 0),
    height: '100%'
  },
  menuPaper: {
    maxHeight: 300
  },
  modalWrapper: {
    padding: theme.spacing(4, 7.75)
  },
  titleWeight: {
    fontWeight: theme.typography.body1.fontWeight
  },
  formRow: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    minHeight: theme.spacing(4.5),
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

interface ImportProps extends Pick<ImportModalProps, 'namespace' | 'mappings' | 'allowedMappings' | 'disableMappings'> {
  onChangeMapping: (mappings: ImportMapping[]) => void;
}

export const Import = ({ namespace, mappings, allowedMappings, disableMappings, onChangeMapping }: ImportProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.modalWrapper}>
      <DialogContentText>{t(`${namespace}:message-upload-modal-successful`)}</DialogContentText>
      <DialogContentText>{t(`${namespace}:message-upload-modal-successful-description`)}</DialogContentText>
      <Grid container className={`${classes.formRow} ${classes.titleWeight}`}>
        <Grid item xs={6}>
          <Typography>{t(`${namespace}:column-source-name`)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{t(`${namespace}:column-destination`)}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Box width="100%">
          {mappings?.map((mapping, i) => (
            <Grid key={mapping.header} container className={classes.formRow}>
              <Grid item xs={6}>
                {mapping.header}
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" variant="outlined" className={classes.formWidth}>
                  <Select
                    disabled={disableMappings}
                    value={mapping.field}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                    onChange={({ target: { value } }) => {
                      onChangeMapping([
                        ...mappings.slice(0, i),
                        { ...mapping, field: value as string },
                        ...mappings.slice(i + 1)
                      ]);
                    }}
                  >
                    <MenuItem value={mapping.field}>{mapping.field}</MenuItem>
                    {(allowedMappings ?? [])
                      .filter(({ field }) => !mappings.some((used) => used.field === field))
                      .map(({ field }) => (
                        <MenuItem key={field} value={field}>
                          {field}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Grid>
    </Box>
  );
};
