import { useMemo, useState } from 'react';
import { Box, Checkbox, Chip, makeStyles } from '@material-ui/core';
import { CheckCircle, CheckCircleOutline, RadioButtonUnchecked as CircleOutlined } from '@material-ui/icons';
import { Table } from '../../app/Table';
import { TableColumnDef } from '../../app/Table/Table.proptype';
import { filterData } from '../../services/filtering';
import tokens from '../../styles/designTokens';
import { SearchBar } from '../SearchBar';

export interface SearchSelectListProps {
  title: string;
  searchBy: string;
  searchByPlaceholder: string;
  columns: TableColumnDef[];
  data: SearchSelectListItem[];
  selectedRows: Set<any>;
  disableSelectAll?: boolean;
  onSelectAll: (shouldSelect: boolean) => void;
  onSelectOne: (shouldSelect: boolean, value: any) => void;
}

// Only rowId is required
export interface SearchSelectListItem {
  [key: string]: any;
  rowId: string | number;
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      overflow: 'hidden',
      '& .MuiSvgIcon-root': {
        fontSize: '1.4rem',
        stroke: theme.palette.background.default
      },
      '& .MuiTableCell-head:first-child': {
        paddingLeft: 0
      },
      '& .MuiTable-stickyHeader': {
        borderCollapse: 'collapse'
      },
      '& tbody': {
        borderTop: '1.1px solid ' + tokens.neutral100
      },
      '&.MuiTableRow-root': {
        backgroundColor: tokens.product15
      },
      '& .MuiTableCell-root': {
        fontSize: '0.875rem'
      },
      '& .selectedIcon': {
        color: tokens.product100,
        strokeWidth: '0px'
      },
      '& .unselectedIcon': {
        color: tokens.core2,
        strokeWidth: '0.8px' // Make the icon path thinner
      },
      '& .selectedRow': {
        '& .MuiTableCell-root': {
          backgroundColor: tokens.product15,
          '&:first-child': {
            color: tokens.product100
          }
        }
      }
    },
    headerContainer: {
      alignItems: 'center',
      display: 'flex'
    },
    listContainer: {
      marginTop: '0.5rem',
      height: '100%',
      overflow: 'auto'
    },
    title: {
      fontSize: '1rem'
    },
    counter: {
      backgroundColor: tokens.product15,
      marginLeft: '1rem',
      width: '2.31rem',
      height: '1.87rem',
      color: tokens.product100
    },
    searchBarContainer: {
      width: '80%'
    },
    invisible: {
      visibility: 'hidden'
    }
  };
});

export const SearchSelectList = ({
  title,
  searchBy,
  searchByPlaceholder,
  columns,
  data,
  selectedRows,
  disableSelectAll,
  onSelectAll,
  onSelectOne
}: SearchSelectListProps) => {
  const [filterObject, setFilter] = useState<Record<string, string>>({ [searchBy]: '' });
  const classes = useStyles();

  const onSearchChange = (searchByText: string) => {
    setFilter({ ...filterObject, [searchBy]: searchByText });
  };

  const allSelected = selectedRows.size === data.length;

  const displayableData = useMemo(
    () =>
      data.map((item, index) => {
        const selected = selectedRows.has(item.rowId);
        return {
          ...item,
          className: selected ? 'selectedRow' : '',
          selectionColumn: (
            <Checkbox
              checked={selected}
              data-testid={`search-select-list-${index}`}
              icon={<CircleOutlined className="unselectedIcon" data-testid="unselectedIcon" />}
              checkedIcon={<CheckCircle className="selectedIcon" data-testid="selectedIcon" />}
              onClick={() => {
                onSelectOne(!selected, item.rowId);
              }}
            />
          )
        };
      }),
    [data, selectedRows, onSelectOne]
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContainer}>
        <Box className={classes.title}>{title}</Box>
        <Chip className={classes.counter} label={selectedRows.size} />
      </Box>

      <Box className={classes.listContainer}>
        <Table
          data={filterData(displayableData, filterObject)}
          columns={[
            {
              key: data.length > 0 ? Object.keys(data[0])[0] : 'searchColumn',
              header: (
                <Box className={classes.searchBarContainer}>
                  <SearchBar placeholder={searchByPlaceholder} onSearchChange={onSearchChange} />
                </Box>
              )
            },
            ...columns,
            {
              align: 'center',
              key: 'selectionColumn',
              header: (
                <Checkbox
                  className={disableSelectAll ? classes.invisible : ''}
                  data-testid="selectAllCheckbox"
                  checked={allSelected && data.length > 0}
                  icon={<CheckCircleOutline className="unselectedIcon" />}
                  checkedIcon={<CheckCircle className="selectedIcon" />}
                  disabled={disableSelectAll}
                  onClick={() => {
                    onSelectAll(!allSelected);
                  }}
                />
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
};
