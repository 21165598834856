import { ComponentType, Dispatch, SetStateAction } from 'react';
import { Container } from '../../models/containers.interface';
import { HistoricalCopyContainerInfo, RollForecast } from '../../redux/rollingContainers/rollingContainers.proptype';

export enum ContainerModalViewKeys {
  Home = 'home',
  CopyForwardConfirmation = 'copy-forward-confirmation',
  CopyBackConfirmation = 'copy-back-confirmation',
  Historical = 'historical'
}

export type CopyContainerModalProps = {
  historicalCopyContainerInfo: HistoricalCopyContainerInfo;
  rollingContainerForecast: {
    rollForwardForecast: RollForecast;
    rollBackForecast: RollForecast;
  };
  container: Container | undefined;
  onClose: () => void;
  canRollBack: boolean;
  canRollForward: boolean;
  cantRollForwardReason: string | null;
  cantRollBackReason: string | null;
  modalInitialView: ContainerModalViewKeys;
};

export type CopyContainerModalContainerProps = {
  container: Container | undefined;
  onClose: () => void;
  view: { key: ContainerModalViewKeys; component: JSX.Element };
  backTo: ContainerModalViewKeys | null;
  setView: Dispatch<SetStateAction<ContainerModalViewKeys>>;
  setBackTo: Dispatch<SetStateAction<ContainerModalViewKeys | null>>;
};

export interface ConnectorProps {
  component: ComponentType<CopyContainerModalProps>;
  onClose: () => void;
  modalInitialView: ContainerModalViewKeys;
}

export interface HistoryData {
  actionName: string;
  date: string;
  userName: string;
}

export interface HistoryViewComponentProps {
  historyData: HistoryData[];
}

export enum RollingContainerStatus {
  'PENDING' = 1,
  'SUCCESS' = 2,
  'FAILURE' = 3
}
