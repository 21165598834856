import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FinancialTypes, FinancialAreas } from '@xbs/xbs-enums';
import { EntityDetailsIncomeStatementProps } from './EntityDetailsIncomeStatement.proptype';
import { FinancialData, FinancialCompletionStatus } from '../../../models';
import { FinancialDataValueEnteredInEnum, FinancialDataObjectTypeEnum } from '../../../models/financial.interface';
import { fetchConversionRates } from '../../../redux/conversionRates';
import {
  getEntityFinancialData,
  updateEntityFinancialData,
  getEntityFinancialCompletionStatus,
  updateEntityFinancialCompletionStatus,
  getEntityAdditionalFinancialInfo
} from '../../../redux/entityFinancialV2/entityFinancial.actions';
import {
  selectConversionRatesByEntity,
  selectEntityCurrency,
  selectUPECurrency,
  selectWorkingContainer,
  selectEntityFinancialIncomeStatementCompletionStatus,
  selectEntityFinancialIncomeStatementAdditionalFinancialInfo,
  selectEntityFinancialIncomeStatement
} from '../../../selectors';
import { AppDispatch } from '../../../store';
import { calculateIncomeStatementFieldValues, hasEditAccess } from '../../../utils';
import { TableBodyDataProps } from '../../FinancialTable/FinancialTable.proptype';

const financialInfo = {
  financialAreaId: FinancialAreas.ByName.IncomeStatement.Id,
  financialDataObjectTypeId: FinancialDataObjectTypeEnum.ENTITY,
  valueEnteredIn: FinancialDataValueEnteredInEnum.MNE
};

interface ConnectorProps {
  readonly component: ComponentType<EntityDetailsIncomeStatementProps>;
}

const generateCompletionByYear = (
  taxYear: number,
  numberOfYears: number,
  financialAreaId: number,
  financialCompletionStatus: FinancialCompletionStatus[]
) => {
  const completionByYear: Record<number, boolean> = {};
  for (let index = 0; index < numberOfYears; index++) {
    const currentYear = taxYear - index;
    const yearStatus = financialCompletionStatus.find(
      (status) => status.taxYear === currentYear && status.financialAreaId === financialAreaId
    );
    completionByYear[currentYear] = Boolean(yearStatus?.isCompleted);
  }

  return completionByYear;
};

const Connector = ({ component: Component }: ConnectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const workingContainer = useSelector(selectWorkingContainer);
  const { entityId: entityIdAsString }: { entityId: string } = useParams();
  const entityId = Number(entityIdAsString);

  const incomeStatementData: FinancialData[] | null = useSelector(selectEntityFinancialIncomeStatement);
  const financialConversions = useSelector(selectConversionRatesByEntity(entityId));
  const additionalFinancialInfo = useSelector(selectEntityFinancialIncomeStatementAdditionalFinancialInfo);

  const upeCurrency = useSelector(selectUPECurrency);
  const entityCurrency = useSelector(selectEntityCurrency(entityId));
  const currencyOptions = [upeCurrency!];
  if (entityCurrency && entityCurrency.currencyId !== upeCurrency?.currencyId) {
    currencyOptions.push(entityCurrency);
  }

  const currentTaxYear = workingContainer?.taxYear ?? 0;
  const columnYears = [currentTaxYear, currentTaxYear - 1, currentTaxYear - 2, currentTaxYear - 3, currentTaxYear - 4];

  const financialCompletionStatus = useSelector(selectEntityFinancialIncomeStatementCompletionStatus);

  const completionByYear = generateCompletionByYear(
    currentTaxYear,
    columnYears.length,
    financialInfo.financialAreaId,
    financialCompletionStatus
  );
  const financialTableTitle = t(`analysis:income-statement`);
  const requiredFields = new Set([
    FinancialTypes.ByName.CostOfGoodsSold.Id,
    FinancialTypes.ByName.OperatingExpenses.Id,
    FinancialTypes.ByName.TotalNetSales.Id
  ]);

  useEffect(() => {
    if (!financialConversions) {
      void dispatch(fetchConversionRates());
    }
  }, [dispatch, financialConversions]);

  useEffect(() => {
    void dispatch(getEntityAdditionalFinancialInfo(entityId));
    void dispatch(getEntityFinancialData({ ...financialInfo, entityId }));
    void dispatch(getEntityFinancialCompletionStatus(entityId, financialInfo.financialAreaId));
  }, [dispatch, entityId]);

  const hasFooter = true;

  const onSaveData = async (data: any) => {
    if (!hasEditAccess()) return;
    const formatedData: FinancialData[] = [];
    data.forEach((row: any) => {
      columnYears.forEach((year) => {
        if (row[year]) {
          const payload: any = {
            taxYear: year,
            value: typeof row[year].value === 'string' && !row[year].value ? null : row[year].value,
            financialType: {
              financialTypeId: FinancialTypes.ById[row.id].Id,
              name: FinancialTypes.ById[row.id].Name
            }
          };

          if (row[year].data?.entityFinancialDataId) {
            payload.entityFinancialDataId = row[year].data.entityFinancialDataId;
            formatedData.push(payload);
          } else if (payload.value !== null) {
            formatedData.push(payload);
          }
        }
      });
    });

    await dispatch(
      updateEntityFinancialData({
        ...financialInfo,
        valueEnteredIn: 1,
        entityId,
        financialData: formatedData
      })
    );
    void dispatch(getEntityFinancialData({ ...financialInfo, entityId }));
  };

  const handleOnSetCompletion = (taxYear: number, isCompleted: boolean) => {
    void dispatch(
      updateEntityFinancialCompletionStatus({
        entityId,
        taxYear,
        financialAreaId: financialInfo.financialAreaId,
        isCompleted
      })
    );
  };

  const isFieldValueValid = (data: TableBodyDataProps, year: string) => {
    let isValid = true;

    if (requiredFields.has(data.id) && !data[year].notAvailable) {
      isValid = false;
    }

    return isValid;
  };

  const isColumnValid = (columnData: TableBodyDataProps[], year: string) => {
    let atLeastOneRequiredFieldIsEmpty = false;
    let allFieldsEmpty = true;

    columnData.forEach((data) => {
      if (requiredFields.has(data.id)) {
        if (data[year].notAvailable) {
          allFieldsEmpty = false;
        } else {
          atLeastOneRequiredFieldIsEmpty = true;
        }
      }
    });
    const columnIsValid = allFieldsEmpty ? true : !atLeastOneRequiredFieldIsEmpty;
    return columnIsValid;
  };

  return (
    <Component
      title={financialTableTitle}
      currencyOptions={currencyOptions}
      financialConversions={financialConversions}
      hasFooter={hasFooter}
      tableData={incomeStatementData}
      financialFieldIds={FinancialTypes.ByType.IncomeStatement}
      financialReadOnlyFieldIds={[FinancialTypes.ByName.GrossProfit.Id, FinancialTypes.ByName.OperatingProfit.Id]}
      isFieldValueValid={isFieldValueValid}
      isColumnValid={isColumnValid}
      calculateFieldValues={calculateIncomeStatementFieldValues}
      columnYears={columnYears}
      completionByYear={completionByYear}
      saveData={onSaveData}
      additionalFinancialInfo={additionalFinancialInfo}
      entityId={entityId}
      workingContainer={workingContainer}
      onSetCompletion={handleOnSetCompletion}
    />
  );
};

export default Connector;
