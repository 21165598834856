import { Theme, makeStyles } from '@material-ui/core';
import tokens from '../../../../../styles/designTokens';
import { body1, title3 } from '../../../../../styles/typography';

export const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  warningIcon: {
    background: tokens.product100,
    borderRadius: '50%',
    boxSizing: 'content-box',
    borderColor: tokens.white,
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    width: '1.5rem',
    height: '1.5rem',
    '& path': {
      fill: '#fff'
    }
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '3rem',
    marginTop: '2rem'
  },
  selectStyles: {
    width: 'auto',
    marginLeft: '5rem'
  },
  title: {
    ...title3,
    color: tokens.core1
  },
  description: {
    ...body1,
    color: tokens.core1,
    padding: '0 1rem'
  },
  selectJurisdictionsContainer: {
    color: tokens.core1,
    ...body1
  },
  cancelButton: {
    border: '0px',
    color: tokens.core1
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    backgroundColor: tokens.white
  }
}));
