import { RootState } from '../redux';

export const selectEntityFinancialIncomeStatement = (state: RootState) => state.entityFinancialV2.incomeStatement.data;
export const selectEntityFinancialBalanceSheet = (state: RootState) => state.entityFinancialV2.balanceSheet.data;

export const selectEntityFinancialIncomeStatementCompletionStatus = (state: RootState) =>
  state.entityFinancialV2.incomeStatement.completionStatus;

export const selectEntityFinancialBalanceSheetCompletionStatus = (state: RootState) =>
  state.entityFinancialV2.balanceSheet.completionStatus;

export const selectEntityFinancialIncomeStatementAdditionalFinancialInfo = (state: RootState) =>
  state.entityFinancialV2.incomeStatement.additionalFinancialInfo;
export const selectEntityFinancialBalanceSheetAdditionalFinancialInfo = (state: RootState) =>
  state.entityFinancialV2.balanceSheet.additionalFinancialInfo;

export const selectEntityFinancialIncomeStatementStatus = (state: RootState) =>
  state.entityFinancialV2.incomeStatement.status;
export const selectEntityFinancialBalanceSheetStatus = (state: RootState) =>
  state.entityFinancialV2.balanceSheet.status;
