import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { TbaFinalRangeHeaderProps } from './TbaFinalRange.proptype';
import tokens from '../../styles/designTokens';
import { title2 } from '../../styles/typography';
import { SummaryChips } from '../SummaryChips';

const useStyles = makeStyles(() => ({
  headerArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title2,
  buttonDivider: {
    margin: '0 2rem',
    border: `1px solid ${tokens.neutral90}`,
    height: '2.625rem'
  }
}));

export const TbaComparableTransactionsHeader = ({ chips }: TbaFinalRangeHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.headerArea}>
      <Typography variant="h2" className={classes.title2}>
        {t('analysis:comparable-transactions')}
      </Typography>
      <SummaryChips chips={chips} />
    </Box>
  );
};
