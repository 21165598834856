import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { EntityDashboardProps } from './EntityDashboard.proptype';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { CompletedItemCard } from '../CompletedItemCard';
import { ContentHeader } from '../ContentHeader';
import { EntityReportsCard } from '../EntityReportsCard';
import { StatementOfFactsCard } from '../StatementOfFactsCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },
  rowContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto'
  },
  row: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    '& > *': {
      flex: 1
    }
  },
  bottomRow: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    '& > :last-child': {
      flex: 2
    }
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    gap: theme.spacing(2),
    '& > *': {
      flex: 1
    }
  }
}));

export const EntityDashboard = ({
  entity,
  transactions,
  statementOfFactsCompletion,
  balanceSheetCompletion,
  incomeStatementCompletion,
  internalReports,
  finalReport,
  onDownload,
  legacy,
  onNavigate
}: EntityDashboardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ContentHeader
        primaryEntity={entity?.code ?? ''}
        title={t('entities:title-dashboard')}
        link="/entities"
        icon={<ArrowBackIcon />}
      />
      <Box className={classes.rowContainer}>
        <Box className={classes.row}>
          <StatementOfFactsCard
            title={t('entities:title-sof')}
            message={t('message-dashboard-sof')}
            completionStatuses={statementOfFactsCompletion}
            onNavigate={() => {
              if (entity) {
                /* eslint-disable camelcase */
                void logGoogleAnalyticsEvent({
                  event_category: 'entity_clicks',
                  event_label: `Entity Statement of Facts Entity dashboard click`,
                  container_id: entity?.container?.containerId
                });
                onNavigate(`/entities/${entity.entityId}/details/statement-of-facts`);
              }
            }}
          />
          <CompletedItemCard
            title={t('entities:title-income-statement')}
            message={t('entities:message-dashboard-income-statement')}
            items={incomeStatementCompletion}
            onNavigate={() => {
              if (entity) {
                /* eslint-disable camelcase */
                void logGoogleAnalyticsEvent({
                  event_category: 'entity_clicks',
                  event_label: `Entity Income Statement Entity dashboard click`,
                  container_id: entity?.container?.containerId
                });
                onNavigate(`/entities/${entity.entityId}/details/income-statement`);
              }
            }}
          />
          <CompletedItemCard
            title={t('entities:title-balance-sheet')}
            message={t('entities:message-dashboard-income-statement')}
            items={balanceSheetCompletion}
            onNavigate={() => {
              if (entity) {
                /* eslint-disable camelcase */
                void logGoogleAnalyticsEvent({
                  event_category: 'entity_clicks',
                  event_label: `Entity Balance Sheet Entity dashboard click`,
                  container_id: entity?.container?.containerId
                });
                onNavigate(`/entities/${entity.entityId}/details/balance-sheet`);
              }
            }}
          />
        </Box>
        <Box className={`${classes.bottomRow} ${classes.row}`}>
          <Box className={classes.column}>
            {legacy[0]}
            <EntityReportsCard
              entity={entity}
              transactions={transactions}
              finalReport={finalReport}
              internalReports={internalReports}
              onDownload={onDownload}
              onNavigate={() => {
                if (entity) {
                  onNavigate(`/reports/${entity.domicile.countryId}/`);
                }
              }}
            />
          </Box>
          {legacy[1]}
        </Box>
      </Box>
    </Box>
  );
};
