export enum TransactionDetailsTab {
  StatementOfFacts = 'statement-of-facts',
  MethodEvaluation = 'method-evaluation',
  TransactionEvaluation = 'transaction-evaluation'
}

export interface TransactionDetailsProps {
  transactionId: number;
  tab: TransactionDetailsTab;
}
