import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  overlay: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    overflowX: 'hidden'
  },
  visible: {
    background: 'rgba(0, 0, 0, 0.2)'
  }
}));

interface OverlayProps {
  visible?: boolean;
  onClick: () => void;
}

export const Overlay = ({ visible, onClick }: OverlayProps) => {
  const classes = useStyles();

  return <Box className={`${classes.overlay} ${visible ? classes.visible : ''}`} onClick={onClick} />;
};
