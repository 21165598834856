import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { TbaAnalysisHeader } from './component';
import { TbaStudy } from './TbaAnalysisHeader.proptype';
import { selectTBA, selectWorkingContainer } from '../../selectors';

const Connector = () => {
  const match = useRouteMatch('/analysis/:studyId/tba-dashboard/:tbaId');
  const history = useHistory();
  const { search } = useLocation();
  const params: TbaStudy = match?.params as TbaStudy;
  const studyId = params?.studyId;
  const tbaId = Number.parseInt(params?.tbaId, 10);
  const workingContainer = useSelector(selectWorkingContainer);
  const selectedTba = useSelector(selectTBA(tbaId));

  // next function it's created to extract query keys and values from url path, path is validated with regex pattern
  const isReferredTransactions = (/^[#?]/.test(search) ? search.slice(1) : search).split('&').find((queryString) => {
    return queryString.includes('referredTransaction');
  });

  const onHandleClose = () => {
    if (isReferredTransactions) {
      const transactionId = isReferredTransactions.split('=')[1];
      history.push(`/transactions/${String(transactionId)}/dashboard/`);
    } else {
      history.push(`/analysis/${String(studyId)}#1`);
    }
  };

  return (
    <TbaAnalysisHeader selectedTba={selectedTba} workingContainer={workingContainer} onHandleClose={onHandleClose} />
  );
};

export default Connector;
