import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, makeStyles } from '@material-ui/core';
import { CenteredProgress, CompletionLabel } from '../../components';
import { TransactionEvaluations } from '../../models';
import { DashboardCard } from '../DashboardCard';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center'
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flex: 1,
    padding: theme.spacing(1, 1.5),
    '& .MuiTypography-root': {
      flex: 1
    }
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground
  }
}));

export interface TransactionMethodEvaluationCardProps {
  evaluations?: TransactionEvaluations;
  evaluationsCompletion: boolean | null;
  onNavigate: () => void;
}

export const TransactionMethodEvaluationCard = ({
  evaluations,
  evaluationsCompletion,
  onNavigate
}: TransactionMethodEvaluationCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isEnabled = evaluations ? evaluations.pbas.length > 0 || evaluations.tbas.length > 0 : false;
  return (
    <DashboardCard
      classes={!evaluations || isEnabled ? undefined : { root: classes.disabled }}
      title={t('transactions:title-method')}
      subtitle={isEnabled ? t('transactions:message-method') : undefined}
      onNavigate={isEnabled ? onNavigate : null}
    >
      <Box className={classes.container}>
        {evaluations ? (
          <Paper elevation={0} className={classes.paper}>
            <Typography variant="body1" component="h2">
              {isEnabled ? t('transactions:label-all-evaluations') : t('transactions:message-no-method-evaluation')}
            </Typography>
            {isEnabled &&
              (evaluationsCompletion === null ? (
                <CenteredProgress />
              ) : (
                <CompletionLabel completed={evaluationsCompletion} />
              ))}
          </Paper>
        ) : (
          <CenteredProgress />
        )}
      </Box>
    </DashboardCard>
  );
};
