import { Box, Popover, Typography, makeStyles } from '@material-ui/core';
import { MapPopoverProps, MapColors } from '../WorldMap.proptype';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  popoverContent: {
    padding: theme.spacing(1),
    color: (colors: MapColors) => colors.text,
    backgroundColor: (colors: MapColors) => colors.tooltipBackground,
    border: (colors: MapColors) => `1px solid ${colors.tooltipBorder}`
  }
}));

export const MapPopover = ({ element, name, colors }: MapPopoverProps) => {
  const classes = useStyles(colors);

  return (
    <Popover
      open={Boolean(element)}
      anchorEl={element}
      className={classes.popover}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Box className={classes.popoverContent}>
        <Typography>{name}</Typography>
      </Box>
    </Popover>
  );
};
