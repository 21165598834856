import { ReactElement } from 'react';
import { Box, Button, ButtonProps as MUIButtonProps, Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface ButtonProps extends MUIButtonProps {
  caption: string;
}

export interface InformationBoxProps {
  center?: boolean | null;
  image?: ReactElement | null;
  title: string;
  subTitle?: string | null;
  text: string;
  paperWidth?: string | number;
  footerButtons: ButtonProps[];
  iconButton?: ReactElement | null;
}

const useStyles = makeStyles<Theme, { paperWidth: string | number | undefined }>((theme) => ({
  paper: ({ paperWidth }) => ({
    backgroundColor: theme.palette.background.default,
    width: paperWidth
  }),
  imageContainer: {
    '& > *': {
      width: '100%',
      height: '100%'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(3, 5, 5)
  },
  title: {},
  subTitle: {
    color: theme.palette.text.secondary
  },
  footer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  paperCenter: {
    alignItems: 'center'
  },
  icon: {
    marginLeft: theme.spacing(2)
  }
}));

export const InformationBox = ({
  center = false,
  image = null,
  title,
  subTitle = null,
  text,
  footerButtons,
  paperWidth = '36%',
  iconButton = null
}: InformationBoxProps) => {
  const classes = useStyles({ paperWidth });

  const selectClasses = clsx({
    [classes.paperCenter]: center,
    [classes.paper]: true
  });

  return (
    <Box className={selectClasses}>
      {image && <Box className={classes.imageContainer}>{image}</Box>}
      <Box className={classes.textContainer}>
        {subTitle && (
          <Typography className={classes.subTitle} variant="subtitle2">
            {subTitle}
          </Typography>
        )}
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
        <Typography className={classes.subTitle} variant="subtitle2">
          {text}
        </Typography>
        <Box className={classes.footer}>
          {footerButtons.map((footerButton) => (
            <Button key={footerButton.caption} variant="contained" color="primary" onClick={footerButton.onClick}>
              {footerButton.caption} {iconButton && <span className={classes.icon}>{iconButton}</span>}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
