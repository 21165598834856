import Connector from './connector';
import { ImportModal } from '../ImportModal';

export interface EntitiesImportModalProps {
  onClose: () => void;
}

export const EntitiesImportModal = (props: EntitiesImportModalProps) => (
  <Connector component={ImportModal} {...props} />
);
