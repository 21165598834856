import { Box, makeStyles } from '@material-ui/core';

export interface CircleProps {
  color: string;
  borderColor?: string;
}

export const Circle = ({ color, borderColor }: CircleProps) => {
  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: color,
      borderRadius: '50%',
      border: '0.0625rem solid ' + String(borderColor ?? color),
      height: '0.875rem',
      width: '0.875rem',
      justifyContent: 'center'
    }
  }));

  const styles = useStyles();

  return <Box className={styles.root} />;
};
