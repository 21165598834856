import { ChangeEvent, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import {
  FinancialInfoErrorsType,
  financialInfoFieldTypesMap,
  FinancialInfoFieldValues,
  FinancialInfoFormProps,
  FinancialInfoValues
} from './CreateTBAModal.proptypes';
import tokens from '../../styles/designTokens';
import { body2 } from '../../styles/typography';

export const financialInfoDefaultValues: FinancialInfoValues = {
  'COGS (related) CP': '',
  'COGS (unrelated)': '',
  'Unit Price': '',
  Units: '',
  'Transaction Amount': '',
  Royalty: '',
  Interest: '',
  Service: '',
  'Resale Price': ''
};

const financialInfoHasError = (value?: string): boolean => {
  if (!value) {
    return true;
  }

  const regex = /[\s$%,]/gi;
  const trimmedString: string = value.replace(regex, '');
  if (trimmedString.length === 0) return true;
  const trimmedValue = Number(value.replace(regex, ''));
  if (Number.isNaN(trimmedValue)) return true;
  return false;
};

const useStyles = makeStyles((theme: Theme) => ({
  gridContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: '1.375rem 2.25rem',
    margin: '1.5rem 0',
    justifyContent: 'flex-start'
  },
  editorLabel: {
    ...body2,
    color: tokens.core1,
    margin: theme.spacing(1, 0, 0.5, 0)
  },
  label: {
    ...body2,
    color: tokens.core2,
    marginBottom: '6px'
  },
  textField: {
    width: '100%',
    '&.MuiTextField-root': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  inputGridElement: {
    marginBottom: theme.spacing(2)
  }
}));

export const FinancialInfoForm = ({
  originalFinancialInfo,
  selectedMethod,
  selectedTransactionType,
  selectedTransactions,
  setFinancialFormHasErrors,
  setFinancialInfo,
  transactions,
  showErrors,
  tbaParameters
}: FinancialInfoFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [financialInfoErrors, setFinancialInfoErrors] = useState<FinancialInfoErrorsType>({});
  const [financialInfoValues, setFinancialInfoValues] = useState<FinancialInfoValues>({});
  const [financialInfoFieldTypes, setFinancialInfoFieldTypes] = useState<FinancialInfoFieldValues[]>([]);

  useEffect(() => {
    setFinancialInfoValues({});
    tbaParameters?.forEach((tbaParameter) => {
      financialInfoDefaultValues[tbaParameter.tbaParameter.type] = String(tbaParameter.parameterValue);
    });
    if (
      selectedMethod &&
      selectedTransactionType &&
      financialInfoFieldTypesMap[selectedMethod]![selectedTransactionType]
    ) {
      const fields: FinancialInfoFieldValues[] =
        financialInfoFieldTypesMap[selectedMethod]![selectedTransactionType] ?? [];
      setFinancialInfoFieldTypes(fields);
      const newFinancialInfoValues: FinancialInfoValues = {};
      fields.forEach((fieldName) => {
        newFinancialInfoValues[fieldName] = financialInfoDefaultValues[fieldName];
      });
      setFinancialInfoValues(newFinancialInfoValues);
    } else {
      setFinancialInfoFieldTypes([]);
    }
  }, [selectedMethod, selectedTransactionType, tbaParameters]);

  useEffect(() => {
    if (selectedMethod === 'CUT/CUP' && selectedTransactionType === 'Tangible Goods') {
      const newFinancialInfoValues: FinancialInfoValues = { ...financialInfoValues };
      if (selectedTransactions.length > 0) {
        const theTransactions = selectedTransactions.map((tr) => {
          return transactions?.find((transaction) => String(transaction.transactionId) === tr.id);
        });
        const finalInfo = {
          Units: 0,
          'Transaction Amount': 0
        };
        theTransactions.forEach((tr) => {
          finalInfo.Units += tr?.units ? tr.units : 0;
          finalInfo['Transaction Amount'] += tr?.value ? tr.value : 0;
        });
        newFinancialInfoValues['Transaction Amount'] = String(finalInfo['Transaction Amount']);
        newFinancialInfoValues.Units = theTransactions.some((tr) => typeof tr?.units !== 'number')
          ? ''
          : String(finalInfo.Units);
        newFinancialInfoValues['Unit Price'] =
          finalInfo['Transaction Amount'] > 0 && finalInfo.Units > 0
            ? String(finalInfo['Transaction Amount'] / finalInfo.Units)
            : '0';
      } else {
        newFinancialInfoValues['Unit Price'] = '';
        newFinancialInfoValues['Transaction Amount'] = '';
        newFinancialInfoValues.Units = '';
      }

      setFinancialInfoValues(newFinancialInfoValues);
      setFinancialInfo(newFinancialInfoValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(transactions), JSON.stringify(selectedTransactions)]);

  useEffect(() => {
    if (selectedMethod === 'CUT/CUP' && selectedTransactionType === 'Tangible Goods') {
      return;
    }

    if (
      selectedMethod &&
      selectedTransactionType &&
      financialInfoFieldTypesMap[selectedMethod]![selectedTransactionType]
    ) {
      const fields = financialInfoFieldTypesMap[selectedMethod]![selectedTransactionType] ?? [];
      const newFinancialInfoErrors = { ...financialInfoErrors };
      let hasErrors = false;
      fields.forEach((field) => {
        if (financialInfoHasError(financialInfoValues[field])) {
          hasErrors = true;
        }

        if (!(selectedMethod === 'CUT/CUP' && selectedTransactionType === 'Tangible Goods')) {
          newFinancialInfoErrors[field] = financialInfoHasError(financialInfoValues[field]);
        }
      });
      setFinancialInfoErrors(newFinancialInfoErrors);
      setFinancialFormHasErrors(hasErrors);
      setFinancialInfo({ ...financialInfoValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialInfoValues, selectedMethod, selectedTransactionType]);

  useEffect(() => {
    if (selectedMethod === 'CUT/CUP' && selectedTransactionType === 'Tangible Goods') {
      return;
    }

    const originalValues: FinancialInfoValues = {};
    if (originalFinancialInfo?.length) {
      financialInfoFieldTypes.forEach((propertyName) => {
        const fInfo = originalFinancialInfo.find(
          (finInfo) => finInfo.tbaParameter.description === propertyName || finInfo.tbaParameter.type === propertyName
        );
        if (fInfo) {
          originalValues[propertyName] = String(fInfo.parameterValue);
        }
      });
      setFinancialInfoValues(originalValues);
    }
  }, [originalFinancialInfo, financialInfoFieldTypes, selectedMethod, selectedTransactionType]);

  const onFinancialInfoChange = (item: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = item.target;
    const newFinancialInfoValues: FinancialInfoValues = { ...financialInfoValues };
    newFinancialInfoValues[name as FinancialInfoFieldValues] = value;
    setFinancialInfoValues(newFinancialInfoValues);
    setFinancialInfo(newFinancialInfoValues);
  };

  const defaultValues = {
    name: '',
    analysisType: '',
    analysisMethods: [
      { name: 'Add Method', value: '' },
      { name: 'CP', value: 'CP' },
      { name: 'CUT/CUP', value: 'CUT/CUP' },
      { name: 'RSP', value: 'RSP' }
    ],
    selectedMethod: '',
    testedParty: null,
    testedPartyName: '',
    primaryFunction: '',
    searchStrategy: '',
    testedPartyJustification: '',
    tradingPartner: null,
    selectedTransactions: [],
    selectedTransactionType: ''
  };

  const formMethods = useForm({
    defaultValues
  });
  const { control } = formMethods;
  const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
    error ? (
      <Typography color="error" variant="caption">
        {message}
      </Typography>
    ) : null;

  const financialInfoComponents = () =>
    financialInfoFieldTypes.map((fieldName: FinancialInfoFieldValues) => (
      <Grid key={fieldName} item xs={4} className={classes.inputGridElement}>
        <Box>
          <Typography className={classes.label}>{t(`analysis:label-tba-financial-info-${fieldName}`)}</Typography>
          <Controller
            name={fieldName}
            render={({ onChange }) => (
              <TextField
                disabled={selectedMethod === 'CUT/CUP' && selectedTransactionType === 'Tangible Goods'}
                id={fieldName}
                name={fieldName}
                placeholder={t(`analysis:label-tba-financial-info-${fieldName}`)}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={financialInfoValues[fieldName]}
                inputProps={{ 'data-testid': `tba${fieldName}` }}
                error={showErrors && Boolean(financialInfoErrors[fieldName])}
                onChange={(item) => {
                  onFinancialInfoChange(item);
                  onChange(item);
                }}
              />
            )}
            control={control}
            defaultValue={financialInfoDefaultValues[fieldName]}
            rules={{ required: true }}
            error={showErrors && Boolean(financialInfoErrors[fieldName])}
          />
        </Box>
        <ErrorMessage
          error={showErrors && Boolean(financialInfoErrors[fieldName])}
          message={t('analysis:invalid-entry-required-element-value-tba')}
        />
      </Grid>
    ));

  if (financialInfoFieldTypes.length > 0) {
    return (
      <Grid container item xs={12} spacing={1} justify="space-around" className={classes.gridContainer}>
        <Grid item xs={12}>
          <Typography className={classes.editorLabel}>{t('analysis:title-additional-transaction-fi')}</Typography>
          <Typography className={classes.label}>{t('analysis:title-additional-transaction-fi-description')}</Typography>
        </Grid>
        {financialInfoComponents()}
      </Grid>
    );
  }

  return null;
};
