import { useEffect, useRef } from 'react';
import useDebounce from '../../../utils/debounce';

export function useResizedDetection(callback: () => void) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [resizeCallback] = useDebounce(() => {
    callback();
  }, 100);

  useEffect(() => {
    const element = wrapperRef.current;

    if (element) {
      callback();

      const resizeObserver = new ResizeObserver(() => {
        resizeCallback();
      });

      resizeObserver.observe(element);
      window.addEventListener('resize', resizeCallback);

      return () => {
        window.removeEventListener('resize', resizeCallback);
        resizeObserver.unobserve(element);
      };
    }
  }, [resizeCallback, callback]);

  return { wrapperRef };
}
