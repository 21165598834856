import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import designTokens from '../../../../../styles/designTokens';
import { body4, captionOverline } from '../../../../../styles/typography';
import { ComparableInformationProps } from '../FineTuning.proptype';

const useStyles = makeStyles(() => ({
  wrapperPage: {
    height: '70vh',
    overflowY: 'scroll',
    padding: '0.5rem'
  },
  companyWrapper: {
    backgroundColor: designTokens.neutral70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12);',
    borderRadius: '4px',
    padding: '1rem',
    marginBottom: '1rem'
  },
  companyName: {
    ...body4
  },
  percentageWrapper: {
    ...captionOverline,
    color: designTokens.white,
    textTransform: 'uppercase',
    backgroundColor: designTokens.purpleLight2,
    padding: '0.5rem',
    borderRadius: '4px',
    display: 'inline-block'
  }
}));

export const OwnershipTab = ({ company }: ComparableInformationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapperPage}>
      {company?.isMajorityOwned ? (
        <Box className={classes.companyWrapper}>
          <Typography className={classes.companyName}>{t('analysis:ownership-majority')}</Typography>
        </Box>
      ) : null}

      {company?.isMinorityOwned ? (
        <Box className={classes.companyWrapper}>
          <Typography className={classes.companyName}>{t('analysis:ownership-minority')}</Typography>
        </Box>
      ) : null}

      {!company?.isMajorityOwned && !company?.isMinorityOwned ? (
        <Box className={classes.companyWrapper}>
          <Typography className={classes.companyName}>{t('analysis:ownership-information-not-available')}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};
