import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, parse, isValid } from 'date-fns';
export const hoursToAdd = 23;
export const minutesToAdd = 59;
export const minutesPerHour = 60;
export const milisecondsPerMinute = 60000;

export const getDateStringFromDateObject = (date: MaterialUiPickersDate) =>
  date && isValid(date) ? format(date, 'yyyy-MM-dd') : '';

export const parseAndFormatForDisplay = (dateString: string) => {
  try {
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return format(parsedDate, 'dd MMM yyyy');
  } catch {
    return '';
  }
};

const getLocalTimezoneOffset = () => {
  return new Date().getTimezoneOffset();
};

export const dateInUTC = (timestamp: number) => {
  const localTimeOffsetInMiliseconds = getLocalTimezoneOffset() * milisecondsPerMinute;
  const UTCDate = timestamp + localTimeOffsetInMiliseconds;
  return UTCDate;
};

export const dateToTimestamp = (year: number, month: number, day: number) => {
  const timestamp = new Date(year, month, day).getTime();
  const timeToAddInMiliseconds = (hoursToAdd * minutesPerHour + minutesToAdd) * milisecondsPerMinute;
  const dateToTimestamp = timestamp + timeToAddInMiliseconds;
  return dateToTimestamp;
};

export const dateInLocalTimeZone = (transferDate: number) => {
  const localTimeOffsetInMiliseconds = getLocalTimezoneOffset() * milisecondsPerMinute;
  const dateInLocalTimeZone = transferDate - localTimeOffsetInMiliseconds;
  return dateInLocalTimeZone;
};

export const timestampToDate = (timestamp: number | string) => {
  const date = new Date(timestamp);
  return date;
};

export const normalizeMonth = (date: Date) => {
  let month = date.getMonth();
  month++;
  return month;
};

const reformatDate = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

export const dateFromUserInputToOurFormat = (dateString: string) => {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
};

export const dateStringInOurFormatIsValidDate = (dateString: string) => {
  return isValid(parse(dateString, 'yyyy-MM-dd', new Date()));
};

export const validateDateString = ({
  date,
  maxDate,
  minDate,
  invalidErrorMessage,
  greaterThanErrorMessage,
  lessThanErrorMessage
}: {
  date: string;
  invalidErrorMessage: string;
  greaterThanErrorMessage: string;
  lessThanErrorMessage: string;
  maxDate?: string;
  minDate?: string;
}): { valid: boolean; message: string } => {
  const validateMinMaxDate = (dateString: string): { valid: boolean; message: string } => {
    if (minDate && dateString < minDate) {
      return {
        valid: false,
        message: lessThanErrorMessage + ' ' + reformatDate(minDate)
      };
    }

    if (maxDate && dateString > maxDate) {
      return {
        valid: false,
        message: greaterThanErrorMessage + ' ' + reformatDate(maxDate)
      };
    }

    return {
      valid: true,
      message: ''
    };
  };

  if (!isValid(parse(date, 'yyyy-MM-dd', new Date()))) {
    return {
      valid: false,
      message: invalidErrorMessage
    };
  }

  return validateMinMaxDate(date);
};
