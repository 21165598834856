import { ReactElement } from 'react';
import { Box, Button, ButtonProps as MUIButtonProps, Typography, makeStyles, Theme } from '@material-ui/core';

interface ButtonProps extends MUIButtonProps {
  caption: string;
}

export interface InformationBoxHorizontalProps {
  image: ReactElement;
  text: string;
  paperWidth?: string | number;
  rightButtons: ButtonProps[];
}

const useStyles = makeStyles<Theme, { paperWidth: string | number | undefined }>((theme) => ({
  paper: ({ paperWidth }) => ({
    backgroundColor: theme.palette.background.default,
    width: paperWidth
  }),
  imageContainer: {
    '& > *': {
      marginLeft: '20%',
      width: '70%',
      height: '70%'
    }
  },
  text: {
    margin: theme.spacing(0, 15, 0, 0)
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(1, 0, 1, 5),
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius
  },
  rightButtons: {
    margin: theme.spacing(0, 2, 0, 0),
    minWidth: '20%'
  },
  icon: {
    margin: theme.spacing(0, 2, 0, 0)
  }
}));

export const InformationBoxHorizontal = ({
  image,
  text,
  rightButtons,
  paperWidth = '100%'
}: InformationBoxHorizontalProps) => {
  const classes = useStyles({ paperWidth });

  return (
    <Box className={classes.paper}>
      <Box className={classes.textContainer}>
        <Box className={classes.imageContainer}>{image}</Box>
        <Typography className={classes.text} variant="subtitle2">
          {text}
        </Typography>
        <Box className={classes.rightButtons}>
          {rightButtons.map((rightButton) => (
            <Button key={rightButton.caption} variant="contained" color="primary" onClick={rightButton.onClick}>
              {rightButton.caption}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
