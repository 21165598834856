import { useMemo, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { GeoMap } from './GeoMap';
import { MapPopover } from './MapPopover';
import { GeoProperties, WorldMapProps } from '../WorldMap.proptype';

const emptyHovered: {
  element: HTMLElement | null;
  properties: GeoProperties | null;
} = {
  element: null,
  properties: null
};

export const WorldMap = ({ data, onClick }: WorldMapProps<GeoProperties>) => {
  const [hovered, setHovered] = useState(emptyHovered);
  const theme = useTheme();
  const colors = useMemo(
    () => ({
      text: theme.palette.text.primary,
      background: theme.palette.grey[300],
      selectedBackground: theme.palette.primary.dark,
      tooltipBackground: theme.palette.background.default,
      border: theme.palette.common.white,
      selectedBorder: theme.palette.common.white,
      tooltipBorder: theme.palette.primary.light
    }),
    [theme]
  );

  const handleEnter = (element: HTMLElement, properties: GeoProperties) => {
    setHovered({ element, properties });
  };

  const handleLeave = () => {
    setHovered(emptyHovered);
  };

  const handleClick = (element: HTMLElement, property: Required<GeoProperties>) => {
    onClick(property.iso);
  };

  return (
    <>
      <GeoMap selected colors={colors} data={data} onEnter={handleEnter} onLeave={handleLeave} onClick={handleClick} />
      <MapPopover colors={colors} element={hovered.element} name={hovered?.properties?.name} />
    </>
  );
};
