import { useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, makeStyles, Theme, TextField, FormControl } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  FinancialInfoValues,
  MethodTypes,
  TransactionTypes
} from '../../../app/NewAnalysisModal/CreateTBAModal.proptypes';
import { Editor, SearchBox } from '../../../components';
import { SimpleDatePicker } from '../../../components/SimpleDatePicker';
import { Entity, PartyRole } from '../../../models';
import tokens from '../../../styles/designTokens';
import { title1, title2, body2, buttonText2, body1 } from '../../../styles/typography';
import { validateDateString } from '../../../utils/dates';
import { checkNumberValue } from '../../../utils/numbers';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
import { FinancialInfoForm } from '../../NewAnalysisModal/FinancialInfoForm';
import { TPModal } from '../../TPModal';
import { CreateNewUncontrolledTransactionModalProps } from '../TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  },
  gridContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.25rem',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: '1.375rem 2.25rem',
    margin: '1.5rem 0',
    justifyContent: 'flex-start'
  },
  editorLabel: {
    ...body2,
    color: tokens.core1,
    margin: theme.spacing(1, 0, 0.5, 0)
  },
  FormControl: {
    width: '100%'
  },
  label: {
    ...body2,
    color: tokens.core2,
    margin: theme.spacing(1, 0, 0.5, 0)
  },
  textField: {
    width: '100%',
    '&.MuiTextField-root': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  greyBackground: {
    backgroundColor: tokens.neutral70
  },
  noMargins: {
    margin: 0
  },
  transactionsList: {
    width: '100%'
  },
  placeHolder: {
    color: tokens.neutral100
  },
  title1,
  title2,
  body2,
  sectionTitle: {
    ...title1,
    color: tokens.core3
  },
  buttonGroup: {
    width: '100%'
  },
  buttonGroupToggleButton: {
    ...buttonText2,
    width: '50%',
    borderColor: tokens.core2,
    '&.Mui-selected': {
      borderColor: tokens.purpleLight2,
      '&.MuiToggleButtonGroup-groupedHorizontal:not(:last-child)': {
        borderRightColor: tokens.purpleLight2,
        marginRight: '1px'
      },
      '.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)': {
        marginLeft: 0
      }
    }
  },
  dropdownStyle: {
    width: '100%',
    border: `1px solid ${tokens.neutral90}`,
    '&.MuiButton-root': {
      minWidth: 0,
      color: tokens.core2,
      justifyContent: 'flex-start',
      '&:hover': {
        backgroundColor: tokens.white
      }
    }
  },
  errorStyle: {
    borderColor: 'red'
  },
  dropDownItem: {
    ...body1,
    color: tokens.core2
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

const requiredTextValidation = (input: string) => input.length >= 3;

export const CreateNewUncontrolledTransactionModal = ({
  tba,
  entities = [],
  container,
  source,
  partyRoles,
  onClose,
  submitNewUncontrolledTransaction,
  savingNewTransaction,
  upeCurrency
}: CreateNewUncontrolledTransactionModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sourceControlType, setSourceControlType] = useState('uncontrolled');
  const [destinationControlType, setDestinationControlType] = useState('uncontrolled');
  const [sourceEntity, setSourceEntity] = useState<Entity | null>(null);
  const [destinationEntity, setDestinationEntity] = useState<Entity | null>(null);
  const [sourceEntityName, setSourceEntityName] = useState<string | null>('');
  const [destinationEntityName, setDestinationEntityName] = useState<string | null>('');
  const [transactionId, setTransactionId] = useState('');
  const [propertyTransferred, setPropertyTransferred] = useState('');
  const [units, setUnits] = useState<number | null>(null);
  const [unitsErrorMessage, setUnitsErrorMessage] = useState<string>(t('errors:error_message.units_required'));
  const [amount, setAmount] = useState<number | null>(null);
  const [amountErrorMessage, setAmountErrorMessage] = useState<string>(t('errors:error_message.amount_required'));
  const [partyRole, setPartyRole] = useState<PartyRole | null>(null);
  const [description, setDescription] = useState('');
  const [financialFormHasErrors, setFinancialFormHasErrors] = useState<boolean>(false);
  const [financialInfo, setFinancialInfo] = useState<FinancialInfoValues>({});
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [datePickerErrorMessage, setDatePickerErrorMessage] = useState<string>(
    t('errors:error_message.transfer_date_required')
  );
  const [transferDate, setTransferDate] = useState<string | null>(null);

  const transactionType: TransactionTypes = tba?.transactionType.name;
  const selectedMethod: MethodTypes = tba?.tbaEvaluationMethod.name;

  const currentYear = new Date().getFullYear();
  const taxYear = container?.taxYear ?? currentYear;
  const minDate = `1900-01-01`;
  const maxDate = `${taxYear + 1}-12-31`;

  const mustBeRealDate = (date: string) => {
    const { valid, message } = validateDateString({
      date,
      maxDate,
      minDate,
      invalidErrorMessage: t('errors:error_message.invalid_date'),
      lessThanErrorMessage: t('errors:error_message.date_can_not_be_less_than'),
      greaterThanErrorMessage: t('errors:error_message.date_can_not_be_larger_than')
    });

    setDatePickerErrorMessage(date ? message : t('errors:error_message.transfer_date_required'));

    return valid;
  };

  const defaultValues = {
    sourceEntityName,
    destinationEntityName,
    transactionId,
    propertyTransferred,
    transferDate,
    units,
    amount,
    partyRole: null,
    description
  };

  const formMethods = useForm({
    defaultValues
  });

  const unitsAreRequired =
    source === 'current-search' &&
    ((tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 3 && tba?.transactionType.transactionTypeId === 3) ||
      (tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 3 && tba?.transactionType.transactionTypeId === 1) ||
      (tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 1 && tba?.transactionType.transactionTypeId === 1));

  const amountIsRequired =
    source === 'current-search' &&
    ((tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 3 && tba?.transactionType.transactionTypeId === 3) ||
      (tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 3 && tba?.transactionType.transactionTypeId === 1) ||
      (tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 1 && tba?.transactionType.transactionTypeId === 1) ||
      (tba?.tbaEvaluationMethod.tbaEvaluationMethodId === 2 && tba?.transactionType.transactionTypeId === 1));

  const validateUnits = (str: string) => {
    switch (checkNumberValue(str)) {
      case 'empty':
        setUnitsErrorMessage(t('errors:error_message.units_required'));
        return !unitsAreRequired;
      case 'zero':
      case 'not a number':
      case 'negative number':
        setUnitsErrorMessage(t('errors:error_message.must_be_positive_number'));
        return false;
      case 'positive number':
      default:
        return true;
    }
  };

  const validateAmount = (str: string) => {
    switch (checkNumberValue(str)) {
      case 'empty':
        setAmountErrorMessage(t('errors:error_message.amount_required'));
        return !amountIsRequired;
      case 'zero':
      case 'not a number':
      case 'negative number':
        setAmountErrorMessage(t('errors:error_message.must_be_positive_number'));
        return false;
      case 'positive number':
      default:
        return true;
    }
  };

  const { handleSubmit, control, errors, setValue } = formMethods;
  const submitButtonDisabled = savingNewTransaction;

  const setEntityControlType = (which: 'source' | 'destination', controlType: 'controlled' | 'uncontrolled') => {
    if (which === 'source' && sourceControlType !== controlType) {
      setSourceControlType(controlType);
      setSourceEntity(null);
      setSourceEntityName(null);
      setValue('sourceEntityName', null);
      if (controlType === 'controlled' && destinationControlType === 'controlled') {
        setDestinationControlType('uncontrolled');
        setDestinationEntityName(null);
        setValue('destinationEntityName', null);
      }
    } else if (which === 'destination' && destinationControlType !== controlType) {
      setDestinationControlType(controlType);
      setDestinationEntity(null);
      setDestinationEntityName(null);
      setValue('destinationEntityName', null);
      if (controlType === 'controlled' && sourceControlType === 'controlled') {
        setSourceControlType('uncontrolled');
        setSourceEntityName(null);
        setValue('sourceEntityName', null);
      }
    }
  };

  const sourceEntityInput = useMemo(
    () =>
      sourceControlType === 'uncontrolled' ? (
        <Box>
          <Typography className={classes.label}>{t('analysis:column-source-entity')}</Typography>
          <Controller
            name="sourceEntityName"
            render={({ value, onChange }) => (
              <TextField
                id="sourceEntityName"
                name="sourceEntityName"
                placeholder={t('entities:placeholder-source-name')}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={value}
                inputProps={{ 'data-testid': 'sourceEntityName' }}
                error={Boolean(showErrors && !sourceEntityName)}
                onChange={(item) => {
                  setSourceEntityName(typeof item?.target?.value === 'string' ? item.target.value : '');
                  onChange(item);
                }}
              />
            )}
            control={control}
            defaultValue={{}}
            rules={{ required: true }}
            error={Boolean(showErrors && !sourceEntityName)}
          />
          <ErrorMessage
            error={Boolean(showErrors && !sourceEntityName)}
            message={t('transactions:invalid-entry-required-source-name')}
          />
        </Box>
      ) : (
        <Box>
          <Typography className={classes.label}>{t('analysis:column-source-entity')}</Typography>
          <FormControl size="small" variant="outlined" className={classes.FormControl}>
            <Controller
              name="sourceEntityName"
              render={({ onChange, value }) => (
                <SearchBox
                  placeholder={t('entities:placeholder-source-name')}
                  error={Boolean(showErrors && !sourceEntityName)}
                  value={value}
                  options={entities.filter((source: any) => destinationEntity?.entityId !== source.entityId)}
                  getOptionSelected={(option, value) => option.code === value.code}
                  getOptionLabel={(source) => source.code ?? ''}
                  onChange={(_, item) => {
                    setSourceEntity(item);
                    setSourceEntityName(item.name);
                    onChange(item);
                  }}
                />
              )}
              control={control}
              rules={{ required: true }}
              defaultValue={{}}
              error={Boolean(showErrors && !sourceEntityName)}
            />
          </FormControl>
          <ErrorMessage
            error={Boolean(showErrors && !sourceEntityName)}
            message={t('transactions:invalid-entry-required-source')}
          />
        </Box>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sourceEntityName, sourceControlType, showErrors]
  );

  const destinationEntityInput = useMemo(
    () =>
      destinationControlType === 'uncontrolled' ? (
        <Box>
          <Typography className={classes.label}>{t('entities:label-copy-destination')}</Typography>
          <Controller
            name="destinationEntityName"
            render={({ value, onChange }) => (
              <TextField
                id="destinationEntityName"
                name="destinationEntityName"
                placeholder={t('entities:placeholder-destination-name')}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                value={value}
                inputProps={{ 'data-testid': 'destinationEntityName' }}
                error={Boolean(showErrors && !destinationEntityName)}
                onChange={(item) => {
                  setDestinationEntityName(typeof item?.target?.value === 'string' ? item.target.value : '');
                  onChange(item);
                }}
              />
            )}
            control={control}
            defaultValue={{}}
            rules={{ required: true }}
            error={Boolean(showErrors && !destinationEntityName)}
          />
          <ErrorMessage
            error={Boolean(showErrors && !destinationEntityName)}
            message={t('transactions:invalid-entry-required-destination-name')}
          />
        </Box>
      ) : (
        <Box>
          <Typography className={classes.label}>{t('entities:label-copy-destination')}</Typography>
          <Controller
            name="destinationEntityName"
            render={({ onChange, value }) => (
              <SearchBox
                placeholder={t('entities:placeholder-destination-name')}
                error={Boolean(showErrors && !destinationEntityName)}
                value={value}
                options={entities.filter((destination: Entity) => destinationEntity?.entityId !== destination.entityId)}
                getOptionSelected={(option, value) => option.code === value.code}
                getOptionLabel={(destination) => destination.code ?? ''}
                onChange={(_, item) => {
                  setDestinationEntity(item);
                  setDestinationEntityName(item.name);
                  onChange(item);
                }}
              />
            )}
            control={control}
            rules={{ required: true }}
            defaultValue={{}}
            error={Boolean(showErrors && !destinationEntityName)}
          />
          <ErrorMessage
            error={Boolean(showErrors && !destinationEntityName)}
            message={t('transactions:invalid-entry-required-destination')}
          />
        </Box>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [destinationEntityName, destinationControlType, showErrors]
  );

  return (
    <TPModal
      isOpen
      maxWidth="md"
      title={
        <span className={classes.modalTitleWrapper}>
          <AddIcon className={classes.headerIcon} />
          <Typography className={classes.title2}>{t('analysis:create-uncontrolled-transaction')}</Typography>
        </span>
      }
      containerStyle={classes.greyBackground}
      actions={[
        {
          name: t('action-cancel'),
          handler: () => {
            /* eslint-disable camelcase */
            void logGoogleAnalyticsEvent({
              event_category: 'tba_button_click',
              event_label: `${source} Cancel Create Single Transaction Modal click`,
              container_id: container?.containerId
            });
            onClose();
          }
        },
        {
          name: t('action-done'),
          className: classes.doneButton,
          disabled: submitButtonDisabled,
          handler: async () => {
            setShowErrors(true);
            return handleSubmit(() => {
              /* eslint-disable camelcase */
              void logGoogleAnalyticsEvent({
                event_category: 'tba_button_click',
                event_label: `${source} Save Create Single Transaction Modal click`,
                container_id: container?.containerId
              });
              const data = {
                sourceEntity,
                sourceEntityName,
                destinationEntity,
                destinationEntityName,
                financialInfo,
                transactionId,
                propertyTransferred,
                transferDate,
                transactionType,
                selectedMethod,
                units,
                amount,
                partyRole,
                description
              };
              if (!financialFormHasErrors) {
                submitNewUncontrolledTransaction(data);
              }
            })();
          }
        }
      ]}
      onClose={() => {
        /* eslint-disable camelcase */
        void logGoogleAnalyticsEvent({
          event_category: 'tba_button_click',
          event_label: `${source} On Close Create Single Transaction Modal click`,
          container_id: container?.containerId
        });
        onClose();
      }}
    >
      <Grid container justify="center" className={classes.noMargins}>
        {/*
                                                                           Entities Section
        */}
        <Grid container item xs={12} justify="center">
          <Grid container item xs={12} spacing={1} justify="space-around" className={classes.gridContainer}>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.sectionTitle}>{t('transactions:column-source')}</Typography>
                <Typography className={classes.label}>{t('entities:label-describe-source-entity')}</Typography>
                <ToggleButtonGroup exclusive className={classes.buttonGroup} size="small" value={sourceControlType}>
                  <ToggleButton
                    className={classes.buttonGroupToggleButton}
                    value="uncontrolled"
                    onClick={() => {
                      setEntityControlType('source', 'uncontrolled');
                    }}
                  >
                    <Typography>{t('entities:label-uncontrolled')}</Typography>
                  </ToggleButton>
                  <ToggleButton
                    className={classes.buttonGroupToggleButton}
                    value="controlled"
                    onClick={() => {
                      setEntityControlType('source', 'controlled');
                    }}
                  >
                    <Typography>{t('entities:label-controlled')}</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {sourceEntityInput}
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.sectionTitle}>{t('entities:label-copy-destination')}</Typography>
                <Typography className={classes.label}>{t('entities:label-describe-destination-entity')}</Typography>
                <ToggleButtonGroup
                  exclusive
                  className={classes.buttonGroup}
                  size="small"
                  value={destinationControlType}
                >
                  <ToggleButton
                    className={classes.buttonGroupToggleButton}
                    value="uncontrolled"
                    onClick={() => {
                      setEntityControlType('destination', 'uncontrolled');
                    }}
                  >
                    <Typography>{t('entities:label-uncontrolled')}</Typography>
                  </ToggleButton>
                  <ToggleButton
                    className={classes.buttonGroupToggleButton}
                    value="controlled"
                    onClick={() => {
                      setEntityControlType('destination', 'controlled');
                    }}
                  >
                    <Typography>{t('entities:label-controlled')}</Typography>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {destinationEntityInput}
            </Grid>
          </Grid>
          {/*
                                                                            Transaction Details section
          */}
          <Grid container item xs={12} spacing={1} justify="space-around" className={classes.gridContainer}>
            <Grid item xs={12}>
              <Box>
                <Typography className={classes.sectionTitle}>{t('transactions:title-details')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.label}>{t('transactions:column-id')}</Typography>
                <FormControl size="small" variant="outlined" className={classes.FormControl}>
                  <Controller
                    name="transactionId"
                    render={({ value, onChange }) => (
                      <TextField
                        id="transactionId"
                        name="transactionId"
                        placeholder={t('entities:placeholder-transaction-id')}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        value={value}
                        inputProps={{ 'data-testid': 'transactionId' }}
                        error={Boolean(errors.transactionId)}
                        onChange={(item) => {
                          setTransactionId(typeof item?.target?.value === 'string' ? item.target.value : '');
                          onChange(item);
                        }}
                      />
                    )}
                    control={control}
                    defaultValue={defaultValues.transactionId}
                    rules={{ required: true, validate: requiredTextValidation }}
                    error={Boolean(errors.transactionId)}
                  />
                </FormControl>
              </Box>
              <ErrorMessage
                error={Boolean(errors.transactionId)}
                message={t('errors:error_message.transaction_id_required')}
              />
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.label}>{t('analysis:column-transaction-type')}</Typography>
                <TextField
                  disabled
                  id="transactionType"
                  name="transactionType"
                  placeholder={t('entities:placeholder-transaction-id')}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={transactionType}
                  inputProps={{ 'data-testid': 'transactionType' }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.label}>{t('transactions:label-property-transferred')}</Typography>
                <Controller
                  name="propertyTransferred"
                  render={({ value, onChange }) => (
                    <TextField
                      id="propertyTransferred"
                      name="propertyTransferred"
                      placeholder={t('entities:placeholder-property-transferred')}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={value}
                      inputProps={{ 'data-testid': 'propertyTransferred' }}
                      error={Boolean(errors.propertyTransferred)}
                      onChange={(item) => {
                        setPropertyTransferred(typeof item?.target?.value === 'string' ? item.target.value : '');
                        onChange(item);
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.propertyTransferred}
                  rules={{ required: true }}
                  error={Boolean(errors.propertyTransferred)}
                />
              </Box>
              <ErrorMessage
                error={Boolean(errors.propertyTransferred)}
                message={t('errors:error_message.property_transferred_required')}
              />
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.label}>{t('transactions:column-transfer')}</Typography>
                <Controller
                  name="transferDate"
                  render={({ onChange, value }) => (
                    <SimpleDatePicker
                      defaultDate={value ?? defaultValues.transferDate ?? ''}
                      onChange={(newDate) => {
                        onChange(newDate);
                        setTransferDate(newDate);
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.transferDate}
                  rules={{ required: true, validate: mustBeRealDate }}
                  error={Boolean(errors.transferDate)}
                />
              </Box>
              <ErrorMessage error={Boolean(errors.transferDate)} message={datePickerErrorMessage} />
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.label}>{t('transactions:label-units')}</Typography>
                <Controller
                  name="units"
                  render={({ value, onChange }) => (
                    <TextField
                      id="units"
                      name="units"
                      placeholder={t('entities:placeholder-units')}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={value}
                      inputProps={{ 'data-testid': 'units' }}
                      error={Boolean(errors.units)}
                      onChange={(item) => {
                        setUnits(
                          checkNumberValue(item.target.value) === 'positive number' ? Number(item.target.value) : null
                        );
                        onChange(item);
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.units}
                  rules={{ validate: validateUnits }}
                  error={Boolean(errors.units)}
                />
              </Box>
              <ErrorMessage error={Boolean(errors.units)} message={unitsErrorMessage} />
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.label}>{t('transactions:label-input-currency')}</Typography>
                <TextField
                  disabled
                  id="inputCurrency"
                  name="inputCurrency"
                  placeholder={t('entities:placeholder-transaction-id')}
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  value={upeCurrency.name}
                  inputProps={{ 'data-testid': 'inputCurrency' }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.label}>
                  {t('transactions:label-value', { currency: upeCurrency.isoCode })}
                </Typography>
                <Controller
                  name="amount"
                  render={({ value, onChange }) => (
                    <TextField
                      id="amount"
                      name="amount"
                      placeholder={t('entities:placeholder-amount')}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={value}
                      inputProps={{ 'data-testid': 'amount' }}
                      error={Boolean(errors.amount)}
                      onChange={(item) => {
                        setAmount(
                          checkNumberValue(item.target.value) === 'positive number' ? Number(item.target.value) : null
                        );
                        onChange(item);
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.amount}
                  rules={{ validate: validateAmount }}
                  error={Boolean(errors.amount)}
                />
              </Box>
              <ErrorMessage error={Boolean(errors.amount)} message={amountErrorMessage} />
            </Grid>
            {partyRoles && (
              <Grid item xs={6}>
                <Box>
                  <Typography className={classes.label}>{t('transactions:label-party-roles')}</Typography>
                  <Controller
                    name="partyRole"
                    render={({ onChange, value }) => (
                      <SearchBox
                        error={Boolean(errors.partyRole)}
                        value={value}
                        options={partyRoles}
                        getOptionSelected={(option, value) => option.name === value.name}
                        getOptionLabel={(source) => source.name ?? ''}
                        onChange={(_, item) => {
                          setPartyRole(item);
                          onChange(item);
                        }}
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={defaultValues.partyRole}
                    error={Boolean(errors.partyRole)}
                  />
                </Box>
                <ErrorMessage
                  error={Boolean(errors.partyRole)}
                  message={t('errors:error_message.party_role_required')}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box>
                <Typography className={classes.label}>{t('analysis:description')}</Typography>
                <Controller
                  name="description"
                  render={({ value, onChange }) => (
                    <Editor
                      theme="TransferPricing"
                      value={value}
                      onEditorChange={(content) => {
                        onChange(content);
                        setDescription(content ?? '');
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.description}
                  rules={{ required: true }}
                  error={Boolean(errors.description)}
                />
              </Box>
              <ErrorMessage
                error={Boolean(errors.description)}
                message={t('errors:error_message.description_required')}
              />
            </Grid>
          </Grid>

          {/*                                         Financial Information Section                         */}

          {partyRoles && container && (
            <FinancialInfoForm
              selectedMethod={selectedMethod}
              selectedTransactionType={transactionType}
              selectedTransactions={[
                {
                  index: 1,
                  id: '1',
                  transactionId: 1
                }
              ]}
              transactions={[
                {
                  transactionId: 1,
                  units,
                  value: Number(amount),
                  tbas: [{ tbaId: tba.tbaId, sourceCompleted: false, destinationCompleted: false }],
                  transactionType: tba?.transactionType,
                  propertyTransferred,
                  partyRole: partyRoles[0],
                  description,
                  transferDate: '2021-01-01',
                  valueEnteredIn: 1,
                  container,
                  identifier: transactionId,
                  legalEntityTransactions: [],
                  pbas: [{ pbaId: 2, sourceCompleted: false, destinationCompleted: false }]
                }
              ]}
              setFinancialFormHasErrors={setFinancialFormHasErrors}
              setFinancialInfo={setFinancialInfo}
              showErrors={showErrors}
            />
          )}
        </Grid>
      </Grid>
    </TPModal>
  );
};
