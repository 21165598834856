import { useEffect, useState } from 'react';
import { CenteredProgress } from '../../components';
import { getAppConfig } from '../../utils';

export const Configuration = ({ children }: { children: JSX.Element }) => {
  const config = getAppConfig();
  const [isConfigurationLoaded, setConfigurationLoaded] = useState(Boolean(config.baseUrl));

  useEffect(() => {
    if (!isConfigurationLoaded) {
      (async () => {
        try {
          const response = await fetch(`${process.env.PUBLIC_URL}/configuration.json`);

          const data = await response.json();
          (window as any).configuration = data;
          setConfigurationLoaded(true);
        } catch (error: unknown) {
          console.log(error);
        }
      })();
    }
  }, [isConfigurationLoaded]);

  return isConfigurationLoaded ? children : <CenteredProgress />;
};
