import { Box, Chip, makeStyles, Theme } from '@material-ui/core';
import tokens from '../../styles/designTokens';
import { body2, captionOverline } from '../../styles/typography';
import { StatusTypes } from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles<Theme>(() => ({
  body2,
  captionOverline,
  selectedChip: {
    ...captionOverline,
    border: '0.063rem solid',
    height: '2.6rem',
    width: '6rem',
    marginLeft: '0.5rem',
    backgroundColor: tokens.product15,
    color: tokens.product100,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  unselectedChip: {
    ...captionOverline,
    marginLeft: '0.5rem',
    width: '6rem',
    height: '2.6rem',
    backgroundColor: tokens.neutral70,
    color: tokens.core1,
    justfyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  pointerCursor: {
    cursor: 'pointer'
  },
  regularCursor: {
    pointerEvents: 'none'
  },
  chipsWrapper: {
    display: 'flex'
  }
}));

export interface SingleChipProps {
  isSelected: boolean;
  label: string;
  key: StatusTypes;
  amount?: number;
  disabled?: boolean;
}

export interface SummaryChipsProps {
  chips: SingleChipProps[];
  clickHandler?: (key: StatusTypes) => void;
}

export const SummaryChips = ({ chips, clickHandler }: SummaryChipsProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.chipsWrapper}>
      {chips.map((aChip: SingleChipProps) => {
        const chipTypeClasses = aChip.isSelected ? classes.selectedChip : classes.unselectedChip;
        const chipCursorClasses = !aChip.disabled && clickHandler ? classes.pointerCursor : classes.regularCursor;
        return (
          <Chip
            key={aChip.key}
            clickable
            data-testid={`summary-chip-${aChip.key}`}
            disabled={aChip.disabled}
            className={`${chipTypeClasses} ${chipCursorClasses}`}
            size="medium"
            component="div"
            label={
              <>
                <Box className={classes.captionOverline}>{aChip.label.toUpperCase()}</Box>
                {aChip.amount && <Box className={classes.body2}>{aChip.amount}</Box>}
              </>
            }
            onClick={() => {
              if (clickHandler) clickHandler(aChip.key);
            }}
          />
        );
      })}
    </Box>
  );
};
