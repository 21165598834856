import { Box, makeStyles } from '@material-ui/core';
import tokens from '../../styles/designTokens';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  jurisdiction: {
    color: tokens.core2,
    border: `1px solid ${tokens.neutral100}`,
    borderRadius: '4px 0 0 4px',
    fontWeight: 600,
    letterSpacing: '0.21px',
    lineHeight: '18px',
    padding: '0.5rem 1.5rem',
    cursor: 'pointer'
  },
  destination: {
    borderRadius: '0 4px 4px 0'
  },
  jurisdictionSelected: {
    color: tokens.product100,
    borderColor: tokens.product100,
    backgroundColor: tokens.product15
  }
}));

export interface TaxJurisdictionSwitchProps {
  source: string;
  destination: string | undefined;
  isSourceSelected?: boolean;
  handleSourceSelected: () => void;
  handleDestinationSelected: () => void;
}

export const TaxJurisdictionSwitch = ({
  source,
  destination,
  isSourceSelected = true,
  handleSourceSelected,
  handleDestinationSelected
}: TaxJurisdictionSwitchProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box
        className={` ${classes.jurisdiction} ${isSourceSelected ? classes.jurisdictionSelected : ''}`}
        onClick={handleSourceSelected}
      >
        {source}
      </Box>
      {destination && (
        <Box
          className={` ${classes.jurisdiction} ${classes.destination} ${
            isSourceSelected ? '' : classes.jurisdictionSelected
          }`}
          onClick={handleDestinationSelected}
        >
          {destination}
        </Box>
      )}
    </Box>
  );
};
