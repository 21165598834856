import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { TbaInformationBox } from './TbaInformationBox';
import tokens from '../../../styles/designTokens';
import { TbaAnalysisSplashImage } from '../../../svgs';
import { TbaUncontrolledTransactionsSplashScreenProps } from '../TbaUncontrolledTransactions.proptype';

export const TbaUncontrolledTransactionSplashScreen = ({
  onContinue
}: TbaUncontrolledTransactionsSplashScreenProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" height={1} justifyContent="center" alignItems="center" bgcolor="secondary.light">
      <TbaInformationBox
        image={<TbaAnalysisSplashImage color={tokens.product100} />}
        title={t('analysis:tba-uncontrolled-transactions-splash-screen-title')}
        text={t('analysis:tba-uncontrolled-transactions-splash-screen-text')}
        footerButtons={[
          {
            caption: t('analysis:tba-uncontrolled-transactions-splash-screen-button-text'),
            onClick: onContinue
          }
        ]}
      />
    </Box>
  );
};
