import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchCountries } from './fetch-countries';
import { ConnectorProps } from './GlobalGuide.proptypes';
import { GlobalGuide } from '../../models';
import {
  fetchGlobalGuideReport,
  downloadGlobalGuideReportVersion,
  fetchGlobalGuides,
  generateGlobalGuideReportVersion
} from '../../redux/globalGuides';
import {
  selectGlobalGuideHasReportStatus,
  selectGlobalGuideReport,
  selectGlobalGuideReportStatus,
  selectGlobalGuides,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { countryIso } = useParams<{ countryIso: string }>();
  const history = useHistory();
  const [data, setData] = useState();
  const [selected, setSelected] = useState<GlobalGuide | undefined>();
  const globalGuides = useSelector(selectGlobalGuides);
  const globalGuideReport = useSelector(selectGlobalGuideReport) ?? null;
  const workingContainer = useSelector(selectWorkingContainer);
  const globalGuideReportStatus = useSelector(selectGlobalGuideReportStatus);
  const globalGuideHasReports = useSelector(selectGlobalGuideHasReportStatus);

  useEffect(() => {
    if (globalGuides) {
      if (selected?.isoCode !== countryIso) {
        setSelected(globalGuides.find(({ isoCode }) => countryIso === isoCode));
      }
    } else {
      void dispatch(fetchGlobalGuides());
    }
  }, [dispatch, countryIso, selected, globalGuides]);

  useEffect(() => {
    if (!globalGuideReport) {
      void dispatch(fetchGlobalGuideReport());
      setInterval(() => {
        void dispatch(fetchGlobalGuideReport());
      }, 5000);
    }
  }, [dispatch, globalGuideReport]);

  useEffect(() => {
    if (globalGuideHasReports === false) {
      void dispatch(generateGlobalGuideReportVersion({}));
      void dispatch(fetchGlobalGuideReport());
    }
  }, [dispatch, globalGuideHasReports]);

  useEffect(() => {
    if (Array.isArray(globalGuides) && globalGuides.length > 0) {
      const isoCodes = (globalGuides ?? []).map(({ isoCode }) => isoCode);
      void fetchCountries(isoCodes).then(setData);
    }
  }, [globalGuides]);

  const getGlobalGuideReportDetails = async (reportId: string, versionId: string) => {
    const response = unwrapResult(await dispatch(downloadGlobalGuideReportVersion({ reportId, versionId })));
    window.open(response.signedUrl, '_self');
  };

  const generateGlobalGuideReport = async (reportId: string) => {
    const generatePayload = {
      payload: {
        reportId,
        container: workingContainer
      },
      reportId
    };
    unwrapResult(await dispatch(generateGlobalGuideReportVersion(generatePayload)));
    void dispatch(fetchGlobalGuideReport());
  };

  return (
    <Component
      globalGuides={globalGuides}
      geoData={data}
      selected={selected}
      report={globalGuideReport}
      status={globalGuideReportStatus}
      getGlobalGuideReportDetails={getGlobalGuideReportDetails}
      generateGlobalGuideReport={generateGlobalGuideReport}
      onSelect={(globalGuide) => {
        setSelected(globalGuide);
        history.push(globalGuide ? `/global-guide/${globalGuide.isoCode}` : '/global-guide');
      }}
    />
  );
};

export default Connector;
