import { getAppConfig } from './configuration';
import httpService from '../services/http';

export const fetchUploadTemplate = async (filePath: string, name: string) => {
  const { xbsEnvironment } = getAppConfig();
  const {
    data: { data }
  } = await httpService.request<{ data: { url: string } }>({
    method: 'post',
    apiUrlKey: 'docApiUrl',
    relativePath: '/v1/download',
    data: { containerId: 0, filePath: `container0/${filePath}`, name, bucketName: `tpiq-api-stack-${xbsEnvironment}` }
  });
  window.open(data.url, '_self');
};
