import { ReactElement } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface ContentLayoutProps {
  withoutPadding?: boolean | null;
  title: ReactElement[] | ReactElement;
  actions: ReactElement[] | ReactElement;
  main: ReactElement;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  actionBar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    '& > h2': {
      flexGrow: 1,
      paddingRight: theme.spacing(1)
    }
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  main: {
    flexGrow: 1,
    overflow: 'auto'
  },
  actionBarWithoutPadding: {
    padding: `${theme.spacing(0)} !important`
  }
}));

export const ContentLayout = ({ title, actions, main, withoutPadding = false }: ContentLayoutProps) => {
  const classes = useStyles();
  const selectClasses = clsx({
    [classes.actionBar]: true,
    [classes.actionBarWithoutPadding]: withoutPadding
  });
  return (
    <Box className={classes.container}>
      <Box className={selectClasses}>
        <Typography variant="h2">{title}</Typography>
        <Box className={classes.actions}>{actions}</Box>
      </Box>
      <Box className={classes.main}>{main}</Box>
    </Box>
  );
};
