import { Currency } from '../models';

export const currencyValueFormatter = (minFractionDigits: number, maxFractionDigits: number, upeCurrency: Currency) => {
  let isoCode = '';
  if (upeCurrency) {
    isoCode = upeCurrency.isoCode;
  }

  return isoCode
    ? {
        style: 'currency',
        currency: isoCode,
        maximumFractionDigits: maxFractionDigits,
        minimumFractionDigits: minFractionDigits
      }
    : {
        maximumFractionDigits: maxFractionDigits,
        minimumFractionDigits: minFractionDigits
      };
};
