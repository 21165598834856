import { createSelector } from 'reselect';
import { RootState } from '../redux';

export const selectStudiesList = (state: RootState) => state.studies.studies;

export const selectStudyName = (studyId: number) => createSelector(selectStudy(studyId), (study) => study?.name ?? '');

export const selectStudy = (studyId: number | null | undefined) =>
  createSelector(selectStudiesList, (studies) => (studies ?? []).find((study) => study.studyId === studyId) ?? null);

export const selectStudiesTimer = (state: RootState) => state.studies.studiesTimer;
