import { createSelector } from 'reselect';
import { RootState } from '../redux';

export const selectTBAsList = (state: RootState) => state.tbas.tbas;

export const selectTBAName = (tbaId: number) => createSelector(selectTBA(tbaId), (tba) => tba?.name ?? '');

export const selectTBA = (tbaId: number | null | undefined) =>
  createSelector(
    selectTBAsList,
    (tbas) =>
      (tbas ?? []).find((tba) => {
        return tba.tbaId === tbaId;
      }) ?? null
  );

export const selectTBALoadingState = (state: RootState) => state.tbas?.loading;

export const selectComparabilityOptions = (state: RootState) => state.tbas?.comparabilityOptions;

export const selectCurrentTBA = (state: RootState) => state.tbas?.currentTBA;

export const selectCurrentTBALocked = (state: RootState) => state.tbas?.currentTBALocked;

export const selectTbaEvaluationMethods = (state: RootState) => state.tbas?.tbaEvaluationMethods;

export const selectTbaSearchStrategy = (state: RootState) => state.tbas?.currentTBASearchStrategy;

export const selectTbaParameters = (state: RootState) => state.tbas?.currentTBAParameters;

export const selectUncontrolledTransactions = (state: RootState) => state.tbas?.uncontrolledTransactions;

export const selectCurrentUncontrolledTransactionParameters = (state: RootState) =>
  state.tbas?.currentUncontrolledTransactionParameters;

export const selectCurrentUncontrolledTransactionComparability = (state: RootState) =>
  state.tbas?.currentUncontrolledTransactionComparability;

export const selectTbaRangeTypes = (state: RootState) => state.tbas?.currentTbaRangeTypes;

export const selectStatisticalMethods = (state: RootState) => state.tbas?.statisticalMethods;

export const selectTbaRangeResults = (state: RootState) => state.tbas?.currentTbaRangeResults;

export const selectTbaTaxJurisdictionCodes = (state: RootState) => state.tbas?.currentTbaTaxJurisdictionCodes;

export const selectTBAFunctionalAnalysisCharacteristics = (state: RootState) =>
  state.tbas.functionalAnalysisCharacteristics;
