import { getAuthInfo, decodeTokens } from './';
import { ReportStatus } from '../app/LocalReports/LocalReports.proptype';
import { ROLES } from '../app/Router/pureConstants';
let userRole: string | undefined;

if (getAuthInfo?.()) {
  const { authToken } = getAuthInfo();
  const decodedToken = decodeTokens(authToken);
  userRole = decodedToken?.role;
}

export function hasEditAccess() {
  return userRole !== ROLES.documentationReview && userRole !== ROLES.reportsOnly;
}

export function hasReportsOnlyAccess() {
  return userRole === ROLES.reportsOnly;
}

export function hasRfRbAccess() {
  return userRole === ROLES.psgAdminTransferPricing;
}

export function hasPsgAnalysisTemplatesAccess() {
  if (!userRole) return;
  const unallowedRoles = [ROLES.transferPricing, ROLES.salesTransferPricing, ROLES.documentationReview];
  return !unallowedRoles.includes(userRole);
}

export function isNotDIY() {
  if (!userRole) return;
  const unallowedRoles = [ROLES.transferPricing];
  return !unallowedRoles.includes(userRole);
}

export const canDownloadReport = (status: ReportStatus) => {
  if (!hasEditAccess()) {
    return status === 'final' || status === 'published';
  }

  return true;
};

export function handleRoleRedirect(defaultPath: string) {
  if (userRole === ROLES.documentationReview || userRole === ROLES.reportsOnly) {
    window.location.replace(`/legacy/central/compliance`);
  } else {
    window.location.replace(defaultPath);
  }
}
