import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, IconButton, Chip, makeStyles } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { PbaTbaJurisdictionStatus } from '@xbs/xbs-enums';
import { JurisdictionStatusDropDown } from '../../../components';
import { Country, PbaJurisdictionStatus } from '../../../models';
import { resetTimer } from '../../../redux/studies';
import { selectWorkingContainer } from '../../../selectors';
import designTokens from '../../../styles/designTokens';
import { title2, captionOverline } from '../../../styles/typography';
import { InRangeIcon, OutOfRangeIcon } from '../../../svgs/';
import { decodeTokens, getAuthInfo } from '../../../utils';
import { JurisdictionPli } from '../../JurisdictionAnalysisInfo/JurisdictionAnalysisInfo.proptype';

export interface TuneCompSearchHeaderProps {
  jurisdictionInfo?: Country;
  jurisdictionStatus?: PbaJurisdictionStatus;
  updateJurisdictionStatus: (status: number) => void;
  selectedPli: JurisdictionPli;
  link: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    height: '2.75rem'
  },
  pliRange: {
    margin: 'auto',
    marginRight: '0.5em'
  },
  inRange: {
    backgroundColor: designTokens.product15,
    color: designTokens.product100,
    '& g': {
      fill: designTokens.product100
    }
  },
  outOfRange: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    '& g': {
      fill: theme.palette.warning.dark
    }
  },
  leftBlock: {
    display: 'flex'
  },
  rightBlock: {
    display: 'flex',
    marginRight: '0.5em'
  },
  jurisdictionName: {
    ...title2,
    margin: 'auto',
    marginRight: '0.5em',
    color: designTokens.core3
  },
  pliName: {
    margin: 'auto',
    textTransform: 'uppercase'
  },
  captionOverline
}));

export const TuneCompSearchHeader = ({
  jurisdictionInfo,
  selectedPli,
  link,
  jurisdictionStatus = PbaTbaJurisdictionStatus.ByName.Complete.Id,
  updateJurisdictionStatus
}: TuneCompSearchHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const jurisdictionName = jurisdictionInfo ? jurisdictionInfo.name : '';
  let pliName = '';
  let pliInRangeText = '';
  let inRange = true;
  if (selectedPli) {
    pliName = selectedPli.pli;
    pliInRangeText = selectedPli.inRangeStatus;
    inRange = selectedPli.inRangeStatus === 'in_range_status.in_range';
  }

  const dispatch = useDispatch();
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;
  const { authToken } = getAuthInfo();
  const userData = decodeTokens(authToken);

  return (
    <Box className={classes.root}>
      <span className={classes.leftBlock}>
        <IconButton component={Link} to={link}>
          <ArrowBackIcon
            onClick={() => {
              dispatch(resetTimer({ containerId, userId: userData?.userId }));
            }}
          />
        </IconButton>
        <Box component="span" className={classes.jurisdictionName}>
          {jurisdictionName} {t('analysis:title')}
        </Box>
        <Box component="span" className={classes.pliName}>
          <Chip
            className={classes.captionOverline}
            color="primary"
            size="small"
            label={`${t(`analysis:${String(pliName)}`)} ${t('analysis:pli-title')}`}
          />
        </Box>
      </span>
      <span className={classes.rightBlock}>
        <Box component="span" className={classes.pliRange}>
          <Chip
            className={`${inRange ? classes.inRange : classes.outOfRange} ${classes.captionOverline}`}
            size="medium"
            label={t(`analysis:${pliInRangeText}`)}
            icon={inRange ? <InRangeIcon /> : <OutOfRangeIcon />}
          />
        </Box>
        <JurisdictionStatusDropDown
          jurisdictionStatus={jurisdictionStatus}
          updateJurisdictionStatus={updateJurisdictionStatus}
        />
      </span>
    </Box>
  );
};
