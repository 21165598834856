import { configureStore } from '@reduxjs/toolkit';
import reducer from './redux';

const store = configureStore({
  reducer,
  devTools: {
    trace: true,
    traceLimit: 25
  }
});

export type AppDispatch = typeof store.dispatch;

export default store;
