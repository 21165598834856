import { useState, MouseEvent } from 'react';
import { Button, ButtonProps, Menu, MenuItem, MenuItemProps, makeStyles } from '@material-ui/core';

export interface DropDownButtonProps extends ButtonProps {
  items?: MenuItemProps[];
  onItemClick?: () => void;
  onItemClose?: () => void;
  closeAnchor?: boolean;
  menuProps?: any;
}

const useStyles = makeStyles(() => ({
  listStyle: {
    '& .MuiList-root': {
      background: ({ menuProps }: any) => (menuProps ? menuProps.backgroundColor : ''),
      padding: ({ menuProps }: any) => (menuProps ? menuProps.padding : '')
    }
  }
}));

export const DropDownButton = (props: DropDownButtonProps) => {
  const { onItemClick, onItemClose, menuProps, closeAnchor, ...buttonProps } = props;

  const classes = useStyles({ menuProps });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (onItemClick) {
      onItemClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onItemClose) {
      onItemClose();
    }
  };

  return (
    <>
      <Button aria-haspopup="true" onClick={handleOpen} {...buttonProps}>
        {props.children}
      </Button>
      <Menu
        keepMounted
        className={classes.listStyle}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
      >
        {(props.items ?? []).map((itemProps, i) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            {...itemProps}
            button
            onClick={(event) => {
              if (!closeAnchor) {
                handleClose();
              }

              itemProps.onClick?.(event);
            }}
          >
            {itemProps.children}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
