import { EntityFinancialStateInterface } from './entityFinancial.types';

export const entityFinancialInitialState: EntityFinancialStateInterface = {
  incomeStatement: {
    data: [],
    completionStatus: [],
    additionalFinancialInfo: '',
    status: 'none',
    error: null
  },
  balanceSheet: {
    data: [],
    completionStatus: [],
    additionalFinancialInfo: '',
    status: 'none',
    error: null
  }
};
