import { MethodEvaluation, ProfitBasedEvaluation, TransactionBasedEvaluation } from '../../../models';

export type Analysis = TransactionBasedEvaluation | ProfitBasedEvaluation | null;

export interface AnalysisAndMethod {
  analysis: Analysis;
  method: MethodEvaluation;
  used: boolean;
  appliesToSource: boolean;
  appliesToDestination: boolean;
  completed?: boolean;
}

export interface EditedEntity {
  discussion: string;
  evaluationType?: number;
}

// NestedList requires nested items to have individual ids.
// Because the same tba/pba objects will be reused within different methods, we need to create unique id by concatenating
// their ids.
// Multiplying method id by a big number mitigates collision that can happen if we add them.
export const getId = ({ analysis, method }: AnalysisAndMethod) =>
  (analysis ? ('tba' in analysis ? analysis.tba.tbaId : analysis.pba.pbaId) : 0) + 100000 * method.evaluationMethodId;

export const getAnalysisName = (analysis: Analysis) =>
  analysis ? ('tba' in analysis ? analysis.tba.name : analysis.pba.name) : '';

export const getAnalysisDiscussion = (analysis: Analysis, isSource: boolean) => {
  if (!analysis) {
    return '';
  }

  const evaluation = 'tba' in analysis ? analysis.methodEvaluation : analysis.methodEvaluations?.[0];
  return evaluation?.[isSource ? 'srcDiscussion' : 'destDiscussion'] ?? '';
};

export const getCompletionState = (analysis: Analysis) => {
  if (!analysis) {
    return false;
  }

  const evaluation = 'tba' in analysis ? analysis.methodEvaluation : analysis.methodEvaluations?.[0];
  return evaluation?.isCompleted ?? false;
};

export const getAnalysisEvaluationType = (analysis: Analysis, isSource: boolean) =>
  analysis
    ? 'tba' in analysis
      ? analysis.methodEvaluation?.[isSource ? 'sourceEvaluationType' : 'destinationEvaluationType']
      : analysis.methodEvaluations?.[0]?.[isSource ? 'sourceEvaluationType' : 'destinationEvaluationType']
    : undefined;

export const mergeAnalysis = (
  analysis: TransactionBasedEvaluation | ProfitBasedEvaluation,
  isCompleted: boolean,
  source: EditedEntity,
  destination: EditedEntity
): TransactionBasedEvaluation | ProfitBasedEvaluation =>
  'tba' in analysis
    ? {
        ...analysis,
        methodEvaluation: {
          ...(analysis.methodEvaluation ?? {}),
          srcDiscussion: source.discussion,
          destDiscussion: destination.discussion,
          isCompleted,
          sourceEvaluationType: source.evaluationType,
          destinationEvaluationType: destination.evaluationType
        }
      }
    : {
        ...analysis,
        methodEvaluations: [
          {
            ...(analysis.methodEvaluations?.[0] ?? {}),
            srcDiscussion: source.discussion,
            destDiscussion: destination.discussion,
            isCompleted,
            sourceEvaluationType: source.evaluationType,
            destinationEvaluationType: destination.evaluationType
          }
        ]
      };
