import { TransactionTypes } from '../app/NewAnalysisModal/CreateTBAModal.proptypes';
import { TradingPair } from '../app/PBADashboardTPDSummary/PBADashboardTPDSummary.proptype';
import { AssociatedTransactionsPBA, Entity, Transaction } from '../models';

export const getTradingPairFromTransaction = (
  transaction: Transaction | AssociatedTransactionsPBA,
  primaryEntityId: number
) => {
  const tradingPair: TradingPair = {};
  transaction.legalEntityTransactions.forEach((lET) => {
    if (lET.entity.entityId === primaryEntityId) {
      tradingPair.primaryEntity = lET.entity;
    } else {
      tradingPair.testedParty = lET.entity;
    }
  });

  return tradingPair;
};

export const filterTransactionsByTestedPartyAndPrimaryEntity = ({
  testedParty,
  primaryEntity,
  transactions
}: {
  testedParty: Entity;
  primaryEntity: Entity;
  transactions: Transaction[];
}) => {
  const transactionHasTestedParty = (tr: Transaction | AssociatedTransactionsPBA, tp: Entity) => {
    const idsOfTransactionEntities = tr.legalEntityTransactions.map((lET) => lET.entity.entityId);
    return idsOfTransactionEntities.includes(tp.entityId);
  };

  if (!testedParty) return [];

  const transactionHasBothTestedPartyAndPrimaryEntity = (
    tr: Transaction | AssociatedTransactionsPBA,
    testedPartyEntity: Entity,
    primaryEntity: Entity
  ) => {
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const idsOfTransactionEntities = tr.legalEntityTransactions
      .map((lET) => lET.entity.entityId)
      .sort()
      .toString();
    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const idsOfBothEntities = [testedPartyEntity.entityId, primaryEntity.entityId].sort().toString();

    return idsOfTransactionEntities === idsOfBothEntities;
  };

  const transactionsWithTestedParty = transactions.filter((tr) => transactionHasTestedParty(tr, testedParty));

  if (testedParty?.entityId === primaryEntity?.entityId) {
    return transactionsWithTestedParty;
  }

  const transactionsWithTestedPartyAndPrimaryEntity = transactionsWithTestedParty.filter((tr) =>
    transactionHasBothTestedPartyAndPrimaryEntity(tr, testedParty, primaryEntity)
  );

  return transactionsWithTestedPartyAndPrimaryEntity;
};

export const filterTransactionsByTransactionType = ({
  transactions,
  transactionType
}: {
  transactions: Transaction[];
  transactionType: TransactionTypes;
}) => {
  return transactions.filter((tr) => tr.transactionType.name === transactionType);
};
