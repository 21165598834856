import { AppBar, Box, IconButton, Toolbar, Typography, makeStyles, Button } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SearchableContainerSelector } from './SearchableContainerSelector';
import { UserMenu } from './UserMenu';
import { productNameFont } from '../../../styles/typography';
import { ExacteraStandaloneWhite } from '../../../svgs';
import Logo from '../../../svgs/LogoSvg';
import { HeaderProps } from '../Header.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark
  },
  toolBar: {
    minHeight: 0,
    padding: theme.spacing(0.5, 2, 0.5, 1)
  },
  logoButton: {
    padding: '10px'
  },
  xbsLogo: {
    height: '1rem',
    width: '1rem',
    fill: theme.palette.common.white
  },
  logo: {
    height: '1.5rem',
    width: '1.5rem',
    fill: theme.palette.common.white
  },
  xbsTitle: {
    flexGrow: 1,
    color: theme.palette.common.white,
    ...productNameFont
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.white,
    ...productNameFont
  },
  selectorContainer: {
    height: '2.688rem',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1.5rem'
  },
  legacyUiButton: {
    marginRight: theme.spacing(2),
    background: '#6E56DB',
    borderColor: 'white',
    color: 'white'
  }
}));

export const Header = (props: HeaderProps) => {
  const { title, onLogoClick, goToLegacyUI, legacyUiText, shouldShowLegacyUiButton } = props;
  const classes = useStyles();
  const flags = useFlags();

  return (
    <>
      <AppBar position="static" elevation={0} color="primary" className={classes.root}>
        <Toolbar className={classes.toolBar}>
          <IconButton className={classes.logoButton} onClick={onLogoClick}>
            {flags.tpRebranding === true ? (
              <ExacteraStandaloneWhite className={classes.logo} />
            ) : (
              <Logo className={classes.xbsLogo} />
            )}
          </IconButton>
          <Typography variant="h3" className={flags.tpRebranding ? classes.title : classes.xbsTitle}>
            {title}
          </Typography>
          {shouldShowLegacyUiButton ? (
            <Button variant="outlined" className={classes.legacyUiButton} onClick={goToLegacyUI}>
              {legacyUiText}
            </Button>
          ) : null}
          <UserMenu {...props} />
        </Toolbar>
      </AppBar>
      <Box className={classes.selectorContainer}>
        <SearchableContainerSelector {...props} />
      </Box>
    </>
  );
};
