import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography, IconButton, Grid, Card, CardContent, makeStyles } from '@material-ui/core';
import { Launch as LaunchIcon } from '@material-ui/icons';
import { DashboardCardEntityTransactionsProps } from './DashboardCardEntityTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  transactionsText: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(1)
  },
  goToText: {
    color: theme.palette.text.secondary
  },
  launchIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  },
  valueLabel: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(1)
  },
  valueText: {
    fontSize: '2rem',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1)
  },
  card: {
    background: theme.palette.background.default
  }
}));

const Component = ({ count, currency, totalValue, url }: DashboardCardEntityTransactionsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography align="left" className={classes.transactionsText}>
              {t('transactions:label-count', { count })}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right" className={classes.goToText}>
              {t('transactions:label-go-to')}
              <IconButton size="small" component={Link} to={url}>
                <LaunchIcon className={classes.launchIcon} />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" className={classes.valueLabel}>
              {t('transactions:label-value', { currency: currency?.isoCode })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="left" className={classes.valueText}>
              {t('{{value, currency}}', { value: totalValue, currency: currency?.isoCode })}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Component;
