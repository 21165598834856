import { makeStyles } from '@material-ui/core';
import { FinancialTable } from './FinancialTable';
import { ComparableInformationProps } from '../FineTuning.proptype';

const useStyles = makeStyles(() => ({
  wrapperComponent: {
    height: '100%',
    overflow: 'auto'
  }
}));

export const BalanceSheetTab = (props: ComparableInformationProps) => {
  const keysFinancial = [
    'totalAssets',
    'operatingAssets',
    'netPayables',
    'netReceivables',
    'netPpe',
    'netInventory',
    'cash',
    'cashEquivalents',
    'investedCapital'
  ];
  const classes = useStyles();

  return (
    <div className={classes.wrapperComponent}>
      {props.financialInfo && <FinancialTable keysFinancial={keysFinancial} financialInfo={props.financialInfo} />}
    </div>
  );
};
