import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { CenteredProgress, CompletionLabel } from '../../components';
import { DashboardCard } from '../DashboardCard';

const useStyles = makeStyles((theme) => ({
  entity: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    flex: 1,
    padding: theme.spacing(1, 1.5)
  },
  entityName: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(1),
    minHeight: '2.5em'
  },
  entitiesContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center'
  }
}));

export interface TransactionEvaluationCardProps {
  entitiesWithCompletion?: Array<{ code: string; completed: boolean }>;
  onNavigate: () => void;
}

export const TransactionEvaluationCard = ({ entitiesWithCompletion, onNavigate }: TransactionEvaluationCardProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <DashboardCard title={t('transactions:title-evaluation')} onNavigate={onNavigate}>
      <Box className={classes.entitiesContainer}>
        {entitiesWithCompletion ? (
          entitiesWithCompletion.map(({ code, completed }) => (
            <Box key={code} className={classes.entity}>
              <Typography variant="h2" className={classes.entityName}>
                {code}
              </Typography>
              <CompletionLabel completed={completed} />
            </Box>
          ))
        ) : (
          <CenteredProgress />
        )}
      </Box>
    </DashboardCard>
  );
};
