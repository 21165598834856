import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography, Box, TextField, FormControl, Select, MenuItem, Button } from '@material-ui/core';
import { AddCircleOutline, Add as AddIcon, RemoveCircleOutline, OpenInNew, Block } from '@material-ui/icons';
import { AddPinnedComparableModalProps, DomicileOption } from './AddPinnedComparableModal.proptype';
import { useStyles } from './AddPinnedComparableModal.styles';
import { CenteredProgress, SearchBox } from '../../../../../components';
import { CustomTooltip } from '../../../../../components/CustomTooltip';
import { ErrorMessage } from '../../../../../components/Editor/ErrorWrapper';
import { selectWorkingContainer } from '../../../../../selectors';
import { title3 } from '../../../../../styles/typography';
import { ManualComp } from '../../../../../svgs';
import { logGoogleAnalyticsEvent } from '../../../../../utils/sendGoogleAnalyticaEvent';
import { Table } from '../../../../Table';
import { TPModal, TPModalActions } from '../../../../TPModal';

export const AddPinnedComparableModal = ({
  companiesData,
  onDone,
  isOpen,
  isSearching,
  addCompany,
  removeCompany,
  onSearchCompanies,
  clearPinnedComparableComps,
  domicileOptions
}: AddPinnedComparableModalProps) => {
  const workingContainer = useSelector(selectWorkingContainer);
  const classes = useStyles();
  const { t } = useTranslation();
  const defaultValues = {
    companyName: '',
    domicile: null, // Autocomplete component needs null value
    sicCode: '',
    poolSize: 10
  };
  const formMethods = useForm<any>({
    defaultValues
  });

  const { handleSubmit, control, errors, reset } = formMethods;

  const formattedData = companiesData?.map((company) => {
    const companySelected = company.available && company.companyAdded;
    const companyName = (
      <CustomTooltip className={classes.tooltip} title={company.name}>
        {company.available ? (
          <Box
            className={`${classes.companyColumnText} ${
              companySelected ? classes.selectedCompany : classes.ellipsizeLongNames
            }`}
          >
            {company.name}
          </Box>
        ) : (
          <Box className={`${classes.disabledText}`}>
            <Block className={classes.blockIcon} />
            {company.name}
          </Box>
        )}
      </CustomTooltip>
    );
    const website =
      company.available && company.website ? (
        <Box className={classes.websiteColumnIcon}>
          <Button
            target="_blank"
            href={company.website.includes('http') ? company.website : `https://${company.website}`}
            endIcon={<OpenInNew />}
            className={classes.buttonLinkWrapper}
          >
            <Typography>{t('analysis:label-comparables-website')}</Typography>
          </Button>
        </Box>
      ) : (
        <div />
      );
    let add = company.companyAdded ? (
      <Box
        className={`${classes.removeAddIcon} ${classes.companyAddedIcon}`}
        onClick={() => {
          removeCompany(company.sourceId);
        }}
      >
        <RemoveCircleOutline />
      </Box>
    ) : (
      <Box
        className={classes.removeAddIcon}
        onClick={() => {
          /* eslint-disable camelcase */
          void logGoogleAnalyticsEvent({
            event_category: 'pinned_comp_add_click',
            event_label: `Add a comp to the pinned comp click`,
            container_id: workingContainer?.containerId
          });
          addCompany(company.sourceId);
        }}
      >
        <AddCircleOutline />
      </Box>
    );

    if (!company.available) {
      add = (
        <Typography className={`${classes.disabledText} ${classes.unavailableText}`}>
          {t('analysis:label-comparables-unavailable')}
        </Typography>
      );
    }

    return {
      companyName,
      domicile: <Typography className={companySelected ? classes.selectedText : ''}>{company.domicileName}</Typography>,
      sicCode: company.sicCode,
      description: (
        <CustomTooltip className={classes.tooltip} title={company.description}>
          <Box className={classes.description}>{company.description}</Box>
        </CustomTooltip>
      ),
      dataProvider: company.dataProvider,
      website,
      add: company.tagged ? <div className={classes.removeAddContainer}>{add}</div> : <span>Unavailable</span>
    };
  });

  const tableColumns = [
    {
      key: 'companyName',
      width: 300,
      header: <div className={classes.companyColumnHeader}>{t('analysis:label-comparables-company-name')}</div>
    },
    { key: 'domicile', header: t('analysis:label-comparables-domicile') },
    { key: 'sicCode', header: t('analysis:label-comparables-sic-code') },
    { key: 'description', header: t('analysis:label-comparables-description'), width: 200 },
    { key: 'dataProvider', header: t('analysis:label-comparables-data-provider') },
    { key: 'website', header: '', width: 100 },
    {
      key: 'add',
      header: <div className={classes.addColumnHeader}> {t('analysis:label-comparables-add')} </div>,
      width: 100
    }
  ];

  const poolSizes = [10, 50, 100, 200, 300, 400, 500].map((size) => {
    return { title: size, value: size };
  });
  const title = (
    <span className={classes.modalTitleWrapper}>
      <AddIcon className={classes.headerIcon} />
      <Typography variant="h1">{t(`analysis:label-comparables-add-companies`)}</Typography>
    </span>
  );
  const footer = (
    <Typography className={classes.selectedText}>
      {t(`analysis:label-comparables-companies-added`, {
        companiesAdded: companiesData?.filter((company) => company.available && company.companyAdded).length
      })}
    </Typography>
  );
  const actionButtons: TPModalActions[] = [
    {
      name: t('action-done'),
      handler: onDone,
      color: 'primary',
      variant: 'contained',
      className: classes.doneButton
    }
  ];
  return (
    <TPModal
      isOpen={isOpen}
      maxWidth="lg"
      title={title}
      footerComponent={footer}
      actions={actionButtons}
      containerStyle={classes.modalContent}
      onClose={onDone}
    >
      <Box>
        <form
          onSubmit={handleSubmit((data: any) => {
            const domicileId = data?.domicile?.data?.countryId;
            const sicCode = data?.sicCode;
            onSearchCompanies({
              companyName: data.companyName,
              count: Number(data.poolSize),
              domicile: domicileId,
              sicCode
            });
          })}
        >
          <Box className={classes.topForm}>
            <Box className={classes.inputsContainer}>
              <Box className={classes.largeInput}>
                <Typography className={classes.label}>{t('analysis:label-comparables-company-name')}</Typography>
                <Controller
                  name="companyName"
                  render={({ onChange, value }) => (
                    <TextField
                      id="companyName"
                      name="companyName"
                      className={`${classes.textField} ${value ? classes.textFieldNonEmpty : ''}`}
                      margin="dense"
                      variant="outlined"
                      value={value}
                      placeholder={t('analysis:label-comparables-company-name')}
                      autoComplete="off"
                      onChange={onChange}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.companyName}
                  rules={{ required: true }}
                  error={Boolean(errors.companyName)}
                />
                <ErrorMessage
                  error={Boolean(errors.companyName)}
                  message={t('analysis:invalid-entry-required-company-name')}
                />
              </Box>
              <Box className={classes.smallInput}>
                <Typography className={classes.label}>{t('analysis:label-comparables-domicile')}</Typography>
                <Controller
                  name="domicile"
                  render={({ onChange, value }) =>
                    defaultValues.domicile ? (
                      <TextField
                        disabled
                        id="domicile"
                        name="domicile"
                        className={`${classes.textField} ${value ? classes.textFieldNonEmpty : ''}`}
                        margin="dense"
                        variant="outlined"
                        value={value.value}
                      />
                    ) : (
                      <SearchBox
                        className={`${value ? classes.selectdNonEmpty : ''}`}
                        placeholder={t('analysis:label-comparables-add-domicile')}
                        error={Boolean(errors.domicile)}
                        value={value}
                        options={domicileOptions}
                        forcePopupIcon={false}
                        getOptionLabel={(option: DomicileOption) => option.value || ''}
                        getOptionSelected={(option: DomicileOption, value: DomicileOption) =>
                          option.value === value.value
                        }
                        renderOption={(option) => <Typography>{option.value}</Typography>}
                        onChange={(_, data) => {
                          onChange(data);
                        }}
                      />
                    )
                  }
                  control={control}
                  defaultValue={defaultValues.domicile}
                  error={Boolean((errors as any).currency)}
                />
                <ErrorMessage error={Boolean(errors.domicile)} message={t('analysis:invalid-entry-domicile')} />
              </Box>
              <Box className={classes.smallInput}>
                <Typography className={classes.label}>{t('analysis:label-comparables-sic-code')}</Typography>
                <Controller
                  name="sicCode"
                  render={({ onChange, value }) => (
                    <TextField
                      id="sicCode"
                      name="sicCode"
                      className={`${classes.textField} ${value ? classes.textFieldNonEmpty : ''}`}
                      margin="dense"
                      variant="outlined"
                      value={value}
                      placeholder={t('analysis:label-comparables-sic-code')}
                      autoComplete="off"
                      type="number"
                      onChange={onChange}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.sicCode}
                  error={Boolean(errors.sicCode)}
                />
                <ErrorMessage error={Boolean(errors.sicCode)} message={t('analysis:invalid-entry-sic-code')} />
              </Box>

              <Box className={`${classes.xsmallInput} ${classes.poolSize}`}>
                <Typography className={classes.label}>Pool Size</Typography>
                <FormControl className={classes.selectStyles} size="small" variant="outlined">
                  <Controller
                    as={
                      <Select displayEmpty>
                        {poolSizes.map(({ value, title }) => (
                          <MenuItem key={value} value={value}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    name="poolSize"
                    control={control}
                    defaultValue={defaultValues.poolSize}
                    rules={{ required: false }}
                    error={Boolean(errors.poolSize)}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={classes.formActions}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  reset();
                  clearPinnedComparableComps();
                }}
              >
                {t('analysis:action-clear-all')}
              </Button>
              <Button
                className={classes.searchCompaniesButton}
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                disabled={isSearching}
              >
                {t('analysis:action-search-companies')}
              </Button>
            </Box>
          </Box>
        </form>
        <Box className={classes.bottomTable}>
          {isSearching ? (
            <CenteredProgress />
          ) : (
            <>
              <Table data={formattedData ?? []} columns={tableColumns} />
              {companiesData?.length === 0 || companiesData === null ? (
                <Box className={classes.noItemsSplashContainer}>
                  <Box className={classes.noItemsSplashIcon}>
                    <ManualComp />
                  </Box>
                  <Box className={classes.noItemsSplashTexts}>
                    {companiesData === null ? (
                      <>
                        <Typography style={{ ...title3 }}>
                          {t('analysis:label-comparables-no-items-splash-title')}
                        </Typography>
                        <Typography> {t('analysis:label-comparables-no-items-splash-sub-title')}</Typography>
                      </>
                    ) : (
                      <>
                        <Typography style={{ ...title3 }}>
                          {t('analysis:label-comparables-no-comps-by-search-criteria-title')}
                        </Typography>
                        <Typography>{t('analysis:label-comparables-no-comps-by-search-criteria-sub-title')}</Typography>
                      </>
                    )}
                  </Box>
                </Box>
              ) : null}
            </>
          )}
        </Box>
      </Box>
    </TPModal>
  );
};
