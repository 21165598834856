import { ComponentType, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TbaFinalRangeComponentProps } from './TbaFinalRange.proptype';
import { TransactionBasedAnalysis } from '../../models/transactionBasedAnalyses.interface';
import {
  createTbaRangeType,
  CreateTbaRangeType,
  deleteTbaRangeType,
  fetchStatisticalMethods,
  fetchTbaRangeResults,
  fetchTbaRangeTypes,
  UpdateTbaRangeType,
  updateTbaRangeType
} from '../../redux/transactionBasedAnalyses';
import {
  selectCurrentUncontrolledTransactionComparability,
  selectStatisticalMethods,
  selectTbaRangeResults,
  selectTbaTaxJurisdictionCodes,
  selectUncontrolledTransactions
} from '../../selectors';
import { isRangeValueTypeUnitPrice } from '../../utils';

interface ConnectorProps {
  component: ComponentType<TbaFinalRangeComponentProps>;
  selectedTba: TransactionBasedAnalysis | null;
}

const Connector = ({ component: Component, selectedTba }: ConnectorProps) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const uncontrolledTransactions = useSelector(selectUncontrolledTransactions);
  const currentUncontrolledTransactionComparabilities =
    useSelector(selectCurrentUncontrolledTransactionComparability) ?? [];

  const statisticalMethods = useSelector(selectStatisticalMethods);
  const tbaRangeResults = useSelector(selectTbaRangeResults);
  const tbaTaxJurisdictionCodes = useSelector(selectTbaTaxJurisdictionCodes);
  const isUnitPrice = isRangeValueTypeUnitPrice(selectedTba);

  useEffect(() => {
    if (selectedTba) {
      dispatch(fetchTbaRangeTypes(selectedTba?.tbaId));
    }
  }, [dispatch, selectedTba]);

  useEffect(() => {
    if (selectedTba && !statisticalMethods) {
      dispatch(fetchStatisticalMethods());
    }
  }, [dispatch, selectedTba, statisticalMethods]);
  useEffect(() => {
    if (selectedTba && !Array.isArray(tbaRangeResults)) {
      dispatch(fetchTbaRangeResults(selectedTba?.tbaId));
    }
  }, [dispatch, selectedTba, tbaRangeResults]);

  const handleUpdateRangeTypes = async (rangeTypeId: number, data: UpdateTbaRangeType) => {
    setIsLoading(true);
    void dispatch<any>(updateTbaRangeType({ rangeTypeId, data })).then(() => {
      if (selectedTba) {
        void dispatch(fetchTbaRangeTypes(selectedTba.tbaId));
        void dispatch(fetchTbaRangeResults(selectedTba.tbaId));
      }

      setIsLoading(false);
    });
  };

  const handleCreateRangeTypes = async (data: CreateTbaRangeType) => {
    setIsLoading(true);
    void dispatch<any>(createTbaRangeType(data)).then(() => {
      if (selectedTba) {
        void dispatch(fetchTbaRangeTypes(selectedTba.tbaId));
        void dispatch(fetchTbaRangeResults(selectedTba.tbaId));
      }

      setIsLoading(false);
    });
  };

  const handleDeleteRangeTypes = async (tbaRangeTypeId: number) => {
    setIsLoading(true);
    void dispatch<any>(deleteTbaRangeType(tbaRangeTypeId)).then(() => {
      if (selectedTba) {
        void dispatch(fetchTbaRangeTypes(selectedTba?.tbaId));
        void dispatch(fetchTbaRangeResults(selectedTba?.tbaId));
      }

      setIsLoading(false);
    });
  };

  return (
    <Component
      uncontrolledTransactions={uncontrolledTransactions}
      currentUncontrolledTransactionComparabilities={currentUncontrolledTransactionComparabilities}
      statisticalMethods={statisticalMethods}
      tbaRangeResults={tbaRangeResults}
      tbaTaxJurisdictionCodes={tbaTaxJurisdictionCodes}
      selectedTba={selectedTba}
      isLoading={isLoading}
      isUnitPrice={isUnitPrice}
      handleCreateRangeTypes={handleCreateRangeTypes}
      handleUpdateRangeTypes={handleUpdateRangeTypes}
      handleDeleteRangeTypes={handleDeleteRangeTypes}
    />
  );
};

export default Connector;
