import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { TbaFinalRangeTabHeaderProps } from './TbaFinalRange.proptype';
import { TaxJurisdictionSwitch } from '../../components/TaxJurisdictionSwitch';
import tokens from '../../styles/designTokens';
import { title2 } from '../../styles/typography';

const useStyles = makeStyles(() => ({
  headerArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title2,
  buttonDivider: {
    margin: '0 2rem',
    border: `1px solid ${tokens.neutral90}`,
    height: '2.625rem'
  }
}));

export const TbaFinalRangeTabHeader = ({
  tbaTaxJurisdictionCodes,
  currentValue,
  handleSourceSelected,
  handleDestinationSelected
}: TbaFinalRangeTabHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.headerArea}>
      <Typography variant="h2" className={classes.title2}>
        {t('analysis:final-range')}
      </Typography>
      <TaxJurisdictionSwitch
        source={tbaTaxJurisdictionCodes?.source ?? ''}
        destination={
          tbaTaxJurisdictionCodes?.destination && tbaTaxJurisdictionCodes.source !== tbaTaxJurisdictionCodes.destination
            ? tbaTaxJurisdictionCodes.destination
            : undefined
        }
        isSourceSelected={currentValue === tbaTaxJurisdictionCodes?.source}
        handleSourceSelected={handleSourceSelected}
        handleDestinationSelected={handleDestinationSelected}
      />
    </Box>
  );
};
