import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { TransactionsTabProps } from './TransactionsTab.proptype';
import { SearchSelectList, SearchSelectListItem } from '../../../components/SearchSelectList/SearchSelectList';
import { Transaction } from '../../../models';
import tokens from '../../../styles/designTokens';
import { parseAndFormatForDisplay } from '../../../utils/dates';
import { TableColumnDef } from '../../Table/Table.proptype';

const useStyles = makeStyles((theme: Theme) => ({
  FormWrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: '1.1875rem 2.125rem 3rem 2.125rem'
  },
  entities: {
    fontFamily: tokens.mediumFont.fontFamily
  },
  amount: {
    fontFamily: tokens.mediumFont.fontFamily
  }
}));

const getEntitiesStringFromTransaction = (transaction: Transaction): string => {
  const entities = transaction.legalEntityTransactions;
  const sourceEntityIndex = entities.findIndex((ent) => ent.isSource);
  return `${entities[sourceEntityIndex].entity.code} → ${entities[sourceEntityIndex ^ 1].entity.code}`;
};

export const TransactionsTab = ({
  transactions,
  upeCurrency,
  selectedTransactions,
  onSelectionChange,
  disableSelectAll
}: TransactionsTabProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState<number[]>(selectedTransactions);
  const dataTransactions = useMemo<SearchSelectListItem[]>(
    () =>
      (transactions ?? []).map((transaction) => ({
        entities: <Box className={classes.entities}>{getEntitiesStringFromTransaction(transaction)}</Box>,
        rowId: transaction.transactionId,
        type: transaction.transactionType.name,
        date: transaction.transferDate
          ? t('date-format-short', {
              date: parseAndFormatForDisplay(transaction.transferDate)
            })
          : '',
        amount: (
          <Box className={classes.amount}>
            {new Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
              transaction.value
            )}
          </Box>
        )
      })),
    [transactions, classes.entities, classes.amount, t]
  );

  const onSelectAll = (shouldSelect: boolean) => {
    const selectedItemsTemp = shouldSelect ? (transactions ?? []).map((transaction) => transaction.transactionId) : [];
    setSelectedItems(selectedItemsTemp);
    onSelectionChange(selectedItemsTemp);
  };

  const onSelectOne = (shouldSelect: boolean, value: any) => {
    const selectedItemsTemp = [...selectedItems];

    if (shouldSelect) {
      selectedItemsTemp.push(value);
    } else {
      selectedItemsTemp.splice(selectedItemsTemp.indexOf(value), 1);
    }

    setSelectedItems(selectedItemsTemp);
    onSelectionChange(selectedItemsTemp);
  };

  const columns: TableColumnDef[] = [
    { key: 'type', header: 'Type' },
    { key: 'date', header: 'Date' },
    {
      key: 'amount',
      header: (
        <div>
          Amount
          <br />({upeCurrency.isoCode})
        </div>
      ),
      align: 'right'
    }
  ];

  return (
    <Box className={classes.FormWrapper}>
      <SearchSelectList
        title="Select Transactions"
        searchBy="entities"
        searchByPlaceholder={t('reports:placeholder-search-by-entity')}
        columns={columns}
        data={dataTransactions}
        selectedRows={new Set(selectedItems)}
        disableSelectAll={disableSelectAll}
        onSelectAll={onSelectAll}
        onSelectOne={onSelectOne}
      />
    </Box>
  );
};
