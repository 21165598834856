import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import { SearchChecklistProps } from './SearchChecklist.proptype';
import { ProgressChip } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    height: '100%',
    paddingLeft: '10rem',
    paddingTop: '5rem'
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25rem'
  },
  grid: {
    paddingTop: '3rem'
  },
  card: {
    height: '2.75rem',
    width: '25rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.313rem',
    margin: '0.3rem',
    boxShadow: `0 0 0 0.125rem ${theme.palette.secondary.main}`,
    paddingTop: '1rem',
    paddingLeft: '2rem',
    display: 'inline-flex'
  },
  testedPartyStatus: {
    color: theme.palette.primary.main,
    fontSize: 16
  },
  checkList: {
    position: 'relative',
    bottom: '0.1rem',
    paddingLeft: '0.5rem',
    fontSize: '0.875rem'
  }
}));

export const SearchChecklist = ({
  currentTestedParty,
  transactionsPBA,
  incomeStatementData,
  currentMajorClassifications,
  currentValueAddedDistributor,
  isValidPba
}: SearchChecklistProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const testedPartyName = currentTestedParty?.name;
  const testedPartySegmentType = currentTestedParty?.segmentType;
  const testedPartyPrimaryFunction = currentTestedParty?.primaryFunction;
  const testedPartyJustification = currentTestedParty?.justification;
  const everyMajorHasMinorClassifications = currentMajorClassifications.every((major) => {
    return major.minorServiceClassifications.length;
  });

  const isServiceProvider = PrimaryFunctions.ByType.ServiceProvider.includes(
    testedPartyPrimaryFunction?.primaryFunctionId
  );
  const isDistributor = PrimaryFunctions.ByType.Distributor.includes(testedPartyPrimaryFunction?.primaryFunctionId);

  const isSicCodeDefined = Boolean(
    currentValueAddedDistributor
      ? currentValueAddedDistributor.codeType && currentValueAddedDistributor.industrialCodeValue
      : false
  );
  const isValidIncomeStatement = isValidPba && incomeStatementData?.length;

  const testedPartyDetailsProgress = [
    Boolean(testedPartyName),
    Boolean(testedPartySegmentType),
    Boolean(testedPartyPrimaryFunction),
    Boolean(testedPartyJustification),
    Boolean(transactionsPBA?.length),
    Boolean(isValidIncomeStatement)
  ];

  const testedPartyCharacterizationProgress = isServiceProvider
    ? [everyMajorHasMinorClassifications, currentMajorClassifications.length > 0]
    : [isSicCodeDefined];

  return (
    <Box className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {t('analysis:search-checklist')}
      </Typography>
      <Typography variant="caption">{t('analysis:search-checklist-description')}</Typography>
      <Grid container className={classes.grid}>
        <Grid item xs={4}>
          <Typography style={{ paddingBottom: '1rem' }}>
            {t('analysis:title-section-item-tested-party-details')}
            <ProgressChip margin progresses={testedPartyDetailsProgress} labelText="COMPLETED" size="small" />
          </Typography>
          <Typography variant="subtitle2">{t('analysis:summary')}</Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '6rem' }}>
          <Typography>
            {t('analysis:title-section-item-tested-party-characterization')}
            <ProgressChip margin progresses={testedPartyCharacterizationProgress} labelText="COMPLETED" size="small" />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.card}>
            <FiberManualRecord
              className={classes.testedPartyStatus}
              style={{ color: testedPartyName ? theme.palette.primary.main : theme.palette.warning.main }}
            />
            <Typography align="center" className={classes.checkList}>
              {t('analysis:search-checklist-add-tested-party-name')}
            </Typography>
          </Box>
          <Box className={classes.card}>
            <FiberManualRecord
              className={classes.testedPartyStatus}
              style={{ color: testedPartySegmentType ? theme.palette.primary.main : theme.palette.warning.main }}
            />
            <Typography align="center" className={classes.checkList}>
              {t('analysis:search-checklist-add-tested-party-segment-type')}
            </Typography>
          </Box>
          <Box className={classes.card}>
            <FiberManualRecord
              className={classes.testedPartyStatus}
              style={{ color: testedPartyPrimaryFunction ? theme.palette.primary.main : theme.palette.warning.main }}
            />
            <Typography align="center" className={classes.checkList}>
              {t('analysis:search-checklist-add-tested-party-primary-function')}
            </Typography>
          </Box>
          <Box className={classes.card}>
            <FiberManualRecord
              className={classes.testedPartyStatus}
              style={{ color: testedPartyJustification ? theme.palette.primary.main : theme.palette.warning.main }}
            />
            <Typography align="center" className={classes.checkList}>
              {t('analysis:search-checklist-add-tested-party-justification')}
            </Typography>
          </Box>
          <Box className={classes.card}>
            <FiberManualRecord
              className={classes.testedPartyStatus}
              style={{
                color:
                  transactionsPBA && transactionsPBA.length > 0
                    ? theme.palette.primary.main
                    : theme.palette.warning.main
              }}
            />
            <Typography align="center" className={classes.checkList}>
              {t('analysis:search-checklist-add-at-least-one-transaction')}
            </Typography>
          </Box>
          <Typography variant="subtitle2" style={{ paddingBottom: '1rem', paddingTop: '2rem' }}>
            {t('analysis:income-statement')}
          </Typography>
          <Box className={classes.card}>
            <FiberManualRecord
              className={classes.testedPartyStatus}
              style={{
                color: isValidIncomeStatement ? theme.palette.primary.main : theme.palette.warning.main
              }}
            />
            <Typography align="center" className={classes.checkList}>
              {t('analysis:search-checklist-add-at-least-one-year-income-statement')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '6rem' }}>
          {isServiceProvider && (
            <Box className={classes.card}>
              <FiberManualRecord
                className={classes.testedPartyStatus}
                style={{
                  color:
                    currentMajorClassifications && currentMajorClassifications.length > 0
                      ? theme.palette.primary.main
                      : theme.palette.warning.main
                }}
              />
              <Typography align="center" className={classes.checkList}>
                {t('analysis:search-checklist-add-at-least-one-major-classification')}
              </Typography>
            </Box>
          )}
          {isServiceProvider && (
            <Box className={classes.card}>
              <FiberManualRecord
                className={classes.testedPartyStatus}
                style={{
                  color: everyMajorHasMinorClassifications ? theme.palette.primary.main : theme.palette.warning.main
                }}
              />
              <Typography align="center" className={classes.checkList}>
                {t('analysis:search-checklist-add-at-least-one-minor-classification')}
              </Typography>
            </Box>
          )}
          {isDistributor && (
            <Box className={classes.card}>
              <FiberManualRecord
                className={classes.testedPartyStatus}
                style={{
                  color: isSicCodeDefined ? theme.palette.primary.main : theme.palette.warning.main
                }}
              />
              <Typography align="center" className={classes.checkList}>
                {t('analysis:search-checklist-add-at-least-one-sic-code')}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
