/* eslint-disable camelcase */
import { Fragment } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Box, Divider, List, Typography, makeStyles } from '@material-ui/core';
import { SideMenuItem } from './SideMenuItem';
import { selectWorkingContainer } from '../../../selectors';
import { getPlatformModule } from '../../../services/platform/platform';
import { SideMenuProps } from '../SideMenu.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  list: {
    padding: theme.spacing(1, 2),
    '&:first-child': {
      flexGrow: 1
    }
  },
  sectionTitle: {
    fontSize: theme.typography.h3.fontSize,
    padding: theme.spacing(1, 2, 0),
    textTransform: 'uppercase'
  }
}));

export const SideMenu = ({ sections, selectedPath, onNavigate }: SideMenuProps) => {
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);

  const logNavigationEvent = async (path: string) => {
    const { IdentityInfo } = await getPlatformModule();
    const session = await IdentityInfo.getIdentitySession();
    const { userUuid } = session;

    const payload = {
      event_category: 'Navigation Event',
      event_label: `Navigation to ${path}`,
      container_id: workingContainer?.containerId,
      userUuid
    };

    ReactGA.event('navigation_click', payload);
  };

  return (
    <Box className={classes.root}>
      {sections.map(({ title, items }, i) => (
        <Fragment key={title ?? i}>
          {i > 0 && <Divider />}
          {title && <Typography className={classes.sectionTitle}>{title}</Typography>}
          <List component="nav" className={classes.list}>
            {items.map((props) => (
              <SideMenuItem
                key={props.text}
                {...props}
                selected={selectedPath.startsWith(props.path)}
                onClick={() => {
                  if (!props.disabled) {
                    void logNavigationEvent(props.path);
                    onNavigate(props.path);
                  }
                }}
              />
            ))}
          </List>
        </Fragment>
      ))}
    </Box>
  );
};
