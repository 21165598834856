import { PbaJurisdictionInfo } from '../../app/NewAnalysisSideMenu/AnalysisSideMenu.proptype';

export interface CompSearchAnimationProps {
  currentCSStatus: string;
  setCurrentCSStatus: (value: string) => void;
  pbaJurisdictionInfo: PbaJurisdictionInfo;
  navigateToAnalysis: () => void;
}

export const csRunningStates = {
  notRunning: 'Not Running',
  completed: 'Completed',
  running: 'Running',
  failed: 'Failed',
  done: 'Done'
};

export const shouldOpenCSAnimation = [csRunningStates.completed, csRunningStates.running, csRunningStates.failed];
