import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Box } from '@material-ui/core';
import { GapAnalysisCompData, GapAnalysisResponse } from '../../../../models';
import { selectGapAnalysis } from '../../../../selectors';
import { Table } from '../../../Table';
import { StickyColsDefinition } from '../../../Table/Table.proptype';

interface GapAnalysisTabInfo extends GapAnalysisCompData {
  currentYearCompPool: string;
  previousYearCompPool: string;
}

const useStyles = makeStyles((theme) => ({
  ul: {
    paddingLeft: 0
  },
  tableContainer: {
    overflow: 'auto',
    maxHeight: theme.spacing(40)
  }
}));

const compare = (recordA: GapAnalysisTabInfo, recordB: GapAnalysisTabInfo) => {
  return recordA.taxPayerName.localeCompare(recordB.taxPayerName);
};

export const GapAnalysis = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const stickyCols: StickyColsDefinition = {};

  const gapAnalysisCompPool: GapAnalysisResponse | undefined = useSelector(selectGapAnalysis);

  if (!gapAnalysisCompPool) {
    return <Box>{t('analysis:no_gap_information')}</Box>;
  }

  const { previousYearCompPool } = gapAnalysisCompPool;
  const { currentYearCompPool } = gapAnalysisCompPool;

  const currentAllComp = currentYearCompPool.map((record: GapAnalysisCompData) => {
    const existPriorYear = previousYearCompPool?.find(
      (payer: GapAnalysisCompData) => payer.sourceId === record.sourceId
    );

    const data: GapAnalysisTabInfo = {
      taxPayerName: record.taxPayerName,
      sourceId: record.sourceId,
      isRejected: record.isRejected,
      rejectionName: record.rejectionName,
      currentYearCompPool: record.taxPayerName,
      previousYearCompPool: existPriorYear
        ? existPriorYear.isRejected === 'true'
          ? existPriorYear.rejectionName
            ? existPriorYear.rejectionName
            : t('analysis:company_not_available_in_search_results')
          : existPriorYear.taxPayerName
        : ''
    };

    return data;
  });

  const priorMissingComp = previousYearCompPool
    .filter((record: GapAnalysisCompData) => !currentYearCompPool?.find((payer) => payer.sourceId === record.sourceId))
    .map((record) => {
      return {
        taxPayerName: record.taxPayerName,
        sourceId: record.sourceId,
        isRejected: record.isRejected,
        rejectionName: record.rejectionName,
        currentYearCompPool: t('analysis:company_not_included_in_current_gap_analysis'),
        previousYearCompPool: record.taxPayerName
      };
    });

  const dataGapTab = [...currentAllComp, ...priorMissingComp].sort(compare);
  const renderCompPoolList = dataGapTab.map((data: GapAnalysisTabInfo) => {
    return {
      currentYearCompPool: (
        <Box className={classes.ul}>
          {data.currentYearCompPool.includes('rejection_type')
            ? t(`analysis:${String(data.currentYearCompPool)}`)
            : String(data.currentYearCompPool)}
        </Box>
      ),
      previousYearCompPool: (
        <Box className={classes.ul}>
          {data.previousYearCompPool.includes('rejection_type')
            ? t(`analysis:${String(data.previousYearCompPool)}`)
            : String(data.previousYearCompPool)}
        </Box>
      )
    };
  });

  return (
    <Grid item xs={12} className={classes.tableContainer}>
      <Table
        data={renderCompPoolList}
        stickyCols={stickyCols}
        columns={[
          { key: 'currentYearCompPool', header: 'Current Year Comps', width: 200 },
          { key: 'previousYearCompPool', header: 'Prior Year Comps', width: 200 }
        ]}
      />
    </Grid>
  );
};
