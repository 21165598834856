import { useState, ReactNode } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

export type SortOrder = 'asc' | 'desc';

export interface TableCellSortProps {
  children: ReactNode;
  onSortClicked: (sortOrder: SortOrder) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  label: {
    justifyContent: 'flex-start'
  }
}));

export const TableCellSort = ({ onSortClicked, children }: TableCellSortProps) => {
  const [sortOrderAsc, setSortOrderAsc] = useState(true);

  return (
    <Button
      fullWidth
      disableFocusRipple
      size="large"
      classes={useStyles()}
      endIcon={sortOrderAsc ? <ArrowDownward /> : <ArrowUpward />}
      onClick={() => {
        setSortOrderAsc((isDesc) => !isDesc);
        onSortClicked(sortOrderAsc ? 'desc' : 'asc');
      }}
    >
      {children}
    </Button>
  );
};
