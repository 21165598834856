import { AnalysisSideMenu as Component } from './components/AnalysisSideMenu';
import Connector from './connector';
import UpdatedConnector from './newConnector';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export const NewAnalysisSideMenu = () => {
  const [featureFlagIsActive] = useFeatureFlags();
  const isSplashScreenDisplayUpdatesEnabled = featureFlagIsActive('searchResultsSplashScreenDisplayUpdates');
  if (isSplashScreenDisplayUpdatesEnabled) {
    return <UpdatedConnector component={Component} />;
  }

  return <Connector component={Component} />;
};
