import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { TuneCompSearchHeader } from './TuneCompSearchHeader';
import { ArrowNavigationTabsBar } from '../../../components/ArrowNavigationTabsBar/ArrowNavigationTabsBar';
import designTokens from '../../../styles/designTokens';
import { captionOverline } from '../../../styles/typography';
import { TuneCompSearchProps } from '../TuneCompSearch.proptype';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  panelWrapper: {
    height: 'calc(100% - 2.75rem - 2.75rem)',
    backgroundColor: designTokens.neutral70,
    padding: '1.125rem'
  },
  bottomHeader: {
    display: 'flex',
    height: '2.75rem'
  },
  bottomLeftHeader: {
    display: 'flex'
  },
  bottomRightHeader: {
    borderTop: '1px solid ' + theme.palette.divider,
    borderBottom: '1px solid ' + theme.palette.divider,
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1
  },
  pliInfoText: {
    ...captionOverline,
    display: 'flex',
    textTransform: 'uppercase',
    margin: 'auto',
    marginRight: '.5em',
    color: designTokens.core2
  }
}));

export const TuneCompSearch = ({
  selectedPli,
  jurisdictionInfo,
  jurisdictionStatus,
  updateJurisdictionStatus,
  selectedKey,
  tabs,
  onSelectTab
}: TuneCompSearchProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const matchJurisdictionPath: any = useRouteMatch(
    '/analysis/:studyId/pba-dashboard/:pbaId/jurisdiction/:jurisdictionId'
  );
  const jurisdictionPath: string = matchJurisdictionPath?.url ?? null;

  let pliInfoText = '';
  if (selectedPli) {
    pliInfoText = `${selectedPli.years}YRS ${t(`analysis:${selectedPli.averageMethod}`)} (${t(
      `analysis:${selectedPli.statMethod}`
    )})`;
  }

  const selectedTab = tabs.find((tab) => tab.key === selectedKey);
  const backLink = `${jurisdictionPath}/jurisdiction-analysis-info`;

  return (
    <div className={classes.pageWrapper}>
      <div>
        <TuneCompSearchHeader
          jurisdictionStatus={jurisdictionStatus}
          updateJurisdictionStatus={updateJurisdictionStatus}
          jurisdictionInfo={jurisdictionInfo}
          selectedPli={selectedPli}
          link={backLink}
        />
        <span className={classes.bottomHeader}>
          <span className={classes.bottomLeftHeader}>
            <ArrowNavigationTabsBar
              tabs={tabs.map((tab) => ({
                key: tab.key,
                label: tab.label,
                disabled: tab.disabled,
                onTabClick: onSelectTab
              }))}
              selectedTab={selectedKey}
              selectedTabColor={designTokens.product15}
            />
          </span>
          <span className={classes.bottomRightHeader}>
            <div className={classes.pliInfoText}>{pliInfoText}</div>
          </span>
        </span>
      </div>
      <div className={classes.panelWrapper}>{selectedTab?.content}</div>
    </div>
  );
};
