import { CompSearchState } from '@xbs/xbs-enums';

export const TP_TITLE = 'CrossBorder DIY';
export const REFRESH_URL = 'v1/refresh';
export const LOGIN_URL = 'https://login-ui.devxbs.com/';
export const PLATFORM_CONTAINER_CHANGED = 'XBS-Container-Changed';

export const AUTH_REFRESH_TIME_WINDOW_MILLISECONDS = 20 * 60 * 1000;
export const NOTIFICATION_SNACKBAR_DURATION = 30000;
export const NOTIFICATION_SNACKBAR_MAX_COUNT = 10;
export const TEXT_EDITOR_MAX_SIZE = 4194304;

export const IDLE_SESSION_TIMEOUT = 3000 * 1000;
export const IDLE_SESSION_PROMPT_TIMEOUT = 600 * 1000;

export const FailedCsStates = [
  CompSearchState.ByName.Failed.Id,
  CompSearchState.ByName.OutOfSync.Id,
  CompSearchState.ByName.TimedOut.Id
];

export enum FetchLoadingStateEnum {
  loading = 'loading',
  fulfilled = 'fulfilled',
  error = 'error',
  reload = 'reload'
}

export type FetchLoadingState =
  | FetchLoadingStateEnum.loading
  | FetchLoadingStateEnum.fulfilled
  | FetchLoadingStateEnum.error
  | FetchLoadingStateEnum.reload;

export const tinyMceDictionary = [
  'Exactera',
  'Comparables',
  'comparables',
  'Interquartile',
  'interquartile',
  'NCP',
  'OM',
  'ROA',
  'CP',
  'BR',
  'GM',
  'CPM',
  'TNMM',
  'CUT',
  'CUP',
  'RSP',
  'PLI',
  'OECD',
  'PS',
  'RPM',
  'SCM',
  'Renderer',
  'renderer',
  'CUSP',
  'CSP',
  'GSMM',
  'Licensor',
  'licensor',
  'Rentee',
  'rentee',
  'LLC',
  'GmbH'
];
