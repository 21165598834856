import { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  popupIndicatorOpen: {
    transform: 'none'
  }
}));

export interface SearchBoxProps<T>
  extends Omit<AutocompleteProps<T, false, boolean, false>, 'renderInput' | 'popupIcon'> {
  error?: boolean;
}

const SearchBox = <T,>({ error, onChange, disableClearable, ...props }: SearchBoxProps<T>) => {
  const [isClearableDisabled, setIsClearableDisabled] = useState<boolean>(disableClearable ?? true);

  return (
    <Autocomplete
      fullWidth
      disableClearable={isClearableDisabled}
      classes={useStyles()}
      onChange={(...args) => {
        if (onChange) {
          onChange(...args);
        }

        if (!disableClearable) {
          const isClearable = Boolean(args[1]);
          setIsClearableDisabled(!isClearable);
        }
      }}
      {...props}
      popupIcon={!props.disabled && <SearchIcon />}
      renderInput={(params) => (
        <TextField placeholder={props.placeholder ?? ''} {...params} error={error} variant="outlined" />
      )}
    />
  );
};

export { SearchBox };
