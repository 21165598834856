import React from 'react';

const LogoSvg = ({ className }: { className: string }) => {
  return (
    <svg className={className} width="104" height="104" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 100">
      <g>
        <title>background</title>
        <rect fill="none" id="canvas_background" height="106" width="106" y="-1" x="-1" />
        <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
          <rect fill="url(#gridpattern)" strokeWidth="0" y="0" x="0" height="100%" width="100%" />
        </g>
      </g>
      <g>
        <title>Layer 1</title>
        <ellipse ry="20" rx="20" id="svg_4" cy="20" cx="20" strokeWidth="0" stroke="#fff" />
        <ellipse ry="20" rx="20" id="svg_8" cy="20" cx="84.25" strokeWidth="0" stroke="#000000" />
        <ellipse ry="20" rx="20" id="svg_9" cy="84.25" cx="84.25" strokeWidth="0" stroke="#fff" />
        <ellipse ry="20" rx="20" id="svg_10" cy="84.25" cx="20" strokeWidth="0" stroke="#fff" />
      </g>
    </svg>
  );
};

export default LogoSvg;
