import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalGuide, NormalizedStateSlice } from '../../models';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';
interface GlobalGuidesState extends NormalizedStateSlice<GlobalGuide> {
  error?: string;
  report: GlobalGuideReportsData | null;
}

const initialState: GlobalGuidesState = { byId: null, allIds: [], report: null };

export const fetchGlobalGuides = createAsyncThunk<GlobalGuide[], void, { rejectValue: Error }>(
  'globalGuides/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: GlobalGuide[] }>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: 'wtp/mne-world'
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export interface GlobalGuideVersion {
  containerId: number;
  reportId: string;
  reportStatus: number;
  versionId: string;
}
export interface GlobalGuideReport {
  containerId: number;
  fileExtension: string;
  filename: string;
  hasVersions: boolean;
  reportId: string;
  reportStatus: number;
  reportTypeId: number;
  versions: GlobalGuideVersion[];
}

export interface GlobalGuideReportsData {
  hasReports: boolean;
  reports: GlobalGuideReport[];
}
export const fetchGlobalGuideReport = createAsyncThunk<any, void, { rejectValue: Error }>(
  'globalGuideReport/fetch',
  async (_, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<{ data: GlobalGuideReportsData[] }>({
          method: 'get',
          apiUrlKey: 'tpCoreApiUrl',
          relativePath: '/v1/report/global-guide'
        })
      ).data.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const downloadGlobalGuideReportVersion = createAsyncThunk<
  any,
  { reportId: string; versionId: string },
  { rejectValue: Error }
>('globalGuideReportDetails/download', async (params, { rejectWithValue }) => {
  try {
    return (
      await httpService.request<{ data: any }>({
        method: 'get',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/report/global-guide/${params.reportId}/version/${params.versionId}`
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const generateGlobalGuideReportVersion = createAsyncThunk<
  any,
  { reportId?: string; payload?: any },
  { rejectValue: Error }
>('generateGlobalGuideReportVersion/post', async (param, { rejectWithValue }) => {
  try {
    if (!param.reportId) {
      return (
        await httpService.request<{ data: any }>({
          method: 'post',
          apiUrlKey: 'tpCoreApiUrl',
          relativePath: `/v1/report/global-guide`,
          data: param.payload
        })
      ).data.data;
    }

    return (
      await httpService.request<{ data: any }>({
        method: 'post',
        apiUrlKey: 'tpCoreApiUrl',
        relativePath: `/v1/report/global-guide/${param.reportId}/version`,
        data: param.payload
      })
    ).data.data;
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

const globalGuidesSlice = createSlice({
  name: 'globalGuides',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchGlobalGuides.fulfilled, (state, { payload: guides }) => {
        state.byId = {};
        state.allIds = [];
        for (const guide of guides ?? []) {
          state.byId[guide.countryId] = guide;
          state.allIds.push(guide.countryId);
        }
      })
      .addCase(fetchGlobalGuideReport.fulfilled, (state, { payload: reportData }) => {
        state.report = reportData;
      })
      .addMatcher(
        (action) => action.type.match(/^globalGuides\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^globalGuides\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default globalGuidesSlice.reducer;
