import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton, TextField, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { SlideOut } from '@xbs/xbs-common-ui';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: theme.spacing(2, 2.5, 2, 2),
    minWidth: 350
  },
  headerWrapper: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  title: {
    fontSize: theme.typography.h2.fontSize,
    flexGrow: 1
  },
  notes: {
    textAlign: 'left',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`,
    width: '100%'
  }
}));

export interface NoteSliderProps {
  notes?: string;
  onClose: () => void;
  onChange: (notes: string) => void;
}

export const NoteSlider = ({ notes, onClose, onChange }: NoteSliderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value || '');
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Box>
      <SlideOut.Container>
        <Box className={classes.container}>
          <Box className={classes.headerWrapper}>
            <Typography variant="subtitle1" className={classes.title}>
              {t('title-note')}
            </Typography>
            <IconButton onClick={handleOnClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            multiline
            id="entity-notes"
            rows={6}
            value={notes}
            variant="outlined"
            className={classes.notes}
            onChange={handleOnChange}
          />
        </Box>
      </SlideOut.Container>
    </Box>
  );
};
