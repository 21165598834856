import { ReactNode } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { NOTIFICATION_SNACKBAR_DURATION, NOTIFICATION_SNACKBAR_MAX_COUNT } from '../../constants';
import { ErrorMessage, Notification } from '../../models';
import httpService from '../../services/http';

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    showNotification({ type, message }: Notification) {
      const decodeMessageContent = (msg: ErrorMessage) => {
        const identifier = msg.subject?.identifier ?? msg.subject?.code;
        if (identifier) {
          return <Box key={String(Math.random())}>{`"${String(identifier)}" has error "${String(msg.message)}"`}</Box>;
        }

        return <Box key={String(Math.random())}>{String(msg.message)}</Box>;
      };

      let messageData = Array.isArray(message) ? message.join('') : message;
      let content = <Box>{messageData}</Box>;

      try {
        const parsingData = message[0].replaceAll('".', '": ');
        messageData = JSON.parse(parsingData);
        content = Array.isArray(messageData) ? (
          <Box>{messageData.map((msg) => decodeMessageContent(msg))}</Box>
        ) : (
          <Box>{messageData}</Box>
        );
      } catch {}

      enqueueSnackbar(content, {
        variant: type,
        autoHideDuration: NOTIFICATION_SNACKBAR_DURATION,
        action: (key) => (
          <IconButton
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <Close />
          </IconButton>
        )
      });
    }
  };
};

const HttpServiceConfigurer = (props: { children: any }) => {
  httpService.setShowNotification(useNotifications().showNotification);
  return props.children;
};

export const NotificationsContext = (props: { children: ReactNode }) => (
  <SnackbarProvider maxSnack={NOTIFICATION_SNACKBAR_MAX_COUNT} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
    <HttpServiceConfigurer {...props} />
  </SnackbarProvider>
);
