import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Box, Button, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { PbaTbaJurisdictionStatus, PrimaryFunctions } from '@xbs/xbs-enums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LoadDeleteAnalysisTemplateModal } from './components/LoadDeleteAnalysisTemplateModal';
import { LoadDeletePSGAnalysisTemplateModal } from './components/LoadDeletePSGAnalysisTemplateModal';
import { SaveAnalysisTemplateModal } from './components/SaveAnalysisTemplateModal';
import { JurisdictionAnalysisInfoProps, JurisdictionPli } from './JurisdictionAnalysisInfo.proptype';
import { DropDownButton, JurisdictionStatusDropDown } from '../../components';
import { CustomSwitch } from '../../components/CustomSwitch';
import editAccess from '../../components/HigherOrderComponents/EditAccess';
import { SummaryTile } from '../../components/SummaryTile';
import { CompSearchAnalysisTemplate, SavedPsgAnalysisTemplates } from '../../models';
import {
  LoadAnalysisTemplateParams,
  LoadAnalysisTemplatePayload,
  fetchJurisdictionDescription,
  fetchJurisdictionPlis,
  fetchSavedAnalysisTemplates,
  loadAnalysisTemplate,
  deleteAnalysisTemplate,
  deletePsgAnalysisTemplate,
  fetchSavedPsgAnalysisTemplates
} from '../../redux/profitBasedAnalyses';
import { selectContainers, selectWorkingContainer } from '../../selectors';
import { AppDispatch } from '../../store';
import tokens from '../../styles/designTokens';
import { regularFont } from '../../styles/fonts';
import { title2, body1, body2, body4, buttonText2, captionOverline, title3 } from '../../styles/typography';
import { getAppConfig } from '../../utils';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../utils/formatPliDisplayValue';
import { hasPsgAnalysisTemplatesAccess } from '../../utils/hasAccess';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    height: '100%',
    width: '100%'
  },
  body4,
  pliBoxContainer: {
    borderBottom: `1px solid ${tokens.neutral90}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  title: {
    ...title3,
    paddingLeft: theme.spacing(3)
  },
  switch: {
    marginLeft: theme.spacing(1)
  },
  headerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  },
  leftDataBlocks: {
    display: 'flex'
  },
  infoBlock: {
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap'
  },
  infoTitle: {
    ...captionOverline,
    lineHeight: '1.25rem',
    color: tokens.core2,
    textTransform: 'uppercase'
  },
  infoValue: {
    ...body1,
    color: tokens.core1,
    marginTop: theme.spacing(0.5),
    backgroundColor: tokens.neutral70,
    borderRadius: '0.25rem',
    textAlign: 'center',
    padding: '0.375rem 0.75rem'
  },
  endBlock: {
    display: 'flex',
    marginRight: theme.spacing(2)
  },
  companiesBlock: {
    paddingRight: theme.spacing(2)
  },
  compsValue: {
    ...tokens.regularFont,
    fontSize: '1.5rem',
    lineHeight: '1.625rem',
    color: theme.palette.text.primary,
    margin: '0.3rem 0 0 0',
    textAlign: 'right'
  },
  pliList: {
    padding: theme.spacing(2),
    backgroundColor: tokens.neutral70,
    height: '100%',
    width: '100%'
  },
  pliBlock: {
    alignItems: 'center',
    display: 'flex'
  },
  testedPartyPliBlock: {
    alignItems: 'center',
    display: 'flex',
    letteSpacing: '0.009rem'
  },
  testedPartPli: {
    ...captionOverline,
    paddingRight: theme.spacing(2),
    color: theme.palette.info.dark,
    textTransform: 'uppercase'
  },
  pliValue: {
    ...body2,
    color: theme.palette.primary.main,
    padding: '0.375rem 0.75rem',
    borderRadius: '0.25rem'
  },
  testedPartyStatusBlock: {
    alignItems: 'center',
    display: 'flex',
    '& .MuiBox-root': {
      height: '1.75',
      ...regularFont,
      letterSpacing: 0,
      textTransform: 'none'
    }
  },
  status: {
    color: theme.palette.info.dark,
    ...regularFont,
    letterSpacing: '0.038rem',
    marginRight: '1rem'
  },
  statusCompletionLabel: {
    height: '1.75rem',
    width: '6.375rem'
  },
  testedPartyRangeBlock: {
    alignItems: 'center',
    display: 'flex'
  },
  range: {
    ...captionOverline,
    color: theme.palette.info.dark,
    width: '4.5rem'
  },
  pliSwitch: {
    '& .switchBase': {
      backgrounColor: 'red'
    },
    '& .switchBase.Mui-checked': {
      backgrounColor: 'blue'
    }
  },
  viewAnalysisButton: {
    ...buttonText2,
    height: '2.216rem',
    width: '11rem',
    paddingLeft: '1.563rem',
    display: 'inline-flex',
    color: theme.palette.info.dark
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  headerTitle: {
    justifyContent: 'space-between',
    display: 'flex'
  },
  leftBlock: {
    display: 'flex'
  },
  rightBlock: {
    display: 'flex',
    marginRight: '1.5rem'
  },
  analysisButtons: {
    marginLeft: theme.spacing(2)
  },
  AnalysisTemplateModalForm: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.0rem',
    '& p': {
      width: '50%'
    }
  },
  AnalysisTemplateModalDoneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  tile: {
    ...title2,
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem',
    padding: '1rem',
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    minHeight: '4.5rem',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12);',
    borderRadius: '4px'
  }
}));

export const JurisdictionAnalysisInfo = ({
  compSearchId,
  currentTestedParty,
  jurisdictionDescription,
  plis,
  jurisdictionPliFormat,
  jurisdictionStatus = PbaTbaJurisdictionStatus.ByName.Complete.Id,
  classifications,
  hasFetchErrors,
  updateJurisdictionStatus,
  onChange,
  saveAnalysisTemplate,
  savePsgAnalysisTemplate,
  loadPsgAnalysisTemplate
}: JurisdictionAnalysisInfoProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const matchJurisdictionPath: any = useRouteMatch(
    '/analysis/:studyId/pba-dashboard/:pbaId/jurisdiction/:jurisdictionId'
  );
  const [isSaveAnalysisTemplateModalOpen, setIsSaveAnalysisTemplateModalOpen] = useState(false);
  const [isPsgSaveAnalysisTemplateModalOpen, setIsSavePsgAnalysisTemplateModalOpen] = useState(false);

  const [isLoadAnalysisTemplateModalOpen, setIsLoadAnalysisTemplateModalOpen] = useState(false);
  const [isLoadPsgSaveAnalysisTemplateModalOpen, setIsLoadPsgAnalysisTemplateModalOpen] = useState(false);

  const [isDeleteAnalysisTemplateModalOpen, setIsDeleteAnalysisTemplateModalOpen] = useState(false);
  const [isDeletePsgSaveAnalysisTemplateModalOpen, setIsDeletePsgAnalysisTemplateModalOpen] = useState(false);

  const jurisdictionPath: string = matchJurisdictionPath?.url ?? null;

  const flags = useFlags();
  const { xbsEnvironmentShort } = getAppConfig();
  const containers = useSelector(selectContainers);
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;

  const showLibraryButtons =
    flags.pbaLibraryAnalysisButtons &&
    (flags.pbaLibraryAnalysisButtons === true ||
      flags.pbaLibraryAnalysisButtons.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
      flags.pbaLibraryAnalysisButtons.includes(`${String(xbsEnvironmentShort)}-*`));

  const formatPli = (pli: JurisdictionPli, value: number) => {
    const pliFormat = jurisdictionPliFormat?.find((jurisdictionPli) => jurisdictionPli.pliTypeId === pli.pliTypeId);
    const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pli.pliTypeId);

    const formattedValue = formatPliDisplayValue(formatType, roundingValue, value);
    return formattedValue;
  };

  const formatClassificationsDataLabel = (): string => {
    const numberOfMajorClassifications = classifications.length;
    let numberOfMinorClassifications = 0;
    classifications.forEach((classification) => {
      numberOfMinorClassifications += (classification.minorServiceClassifications ?? []).length;
    });
    const majorText = `Major${numberOfMajorClassifications > 1 ? 's' : ''}`;
    const minorText = `Minor${numberOfMinorClassifications > 1 ? 's' : ''}`;

    return `${numberOfMajorClassifications} ${majorText} ${numberOfMinorClassifications} ${minorText}`;
  };

  const formatPliDataLabel = (plis?: JurisdictionPli[]): string => {
    if (plis && plis.length > 0) {
      const data = plis[0]; /* All plis have the same values for the properties displayed on the DATA header */
      const yearsText = `yr${data.years > 1 ? 's' : ''}`;
      return `${data.years} ${yearsText} ${t(`analysis:${data.averageMethod}`)} (${t(
        `analysis:${data.statMethod}`
      )})`.toUpperCase();
    }

    return t('analysis:label-data-unavailable');
  };

  const onOpenSaveAnalysisTemplateModal = () => {
    setIsSaveAnalysisTemplateModalOpen(true);
  };

  const onSaveAnalysisTemplateModalDone = (value: string) => {
    saveAnalysisTemplate(value);
    setIsSaveAnalysisTemplateModalOpen(false);
    if (container?.tenantId) void dispatch(fetchSavedAnalysisTemplates(container?.tenantId));
  };

  const onOpenSavePsgAnalysisTemplateModal = () => {
    setIsSavePsgAnalysisTemplateModalOpen(true);
  };

  const onSavePsgAnalysisTemplateModalDone = (value: string) => {
    savePsgAnalysisTemplate(value);
    setIsSavePsgAnalysisTemplateModalOpen(false);
  };

  const onOpenLoadPsgAnalysisTemplateModal = () => {
    setIsLoadPsgAnalysisTemplateModalOpen(true);
  };

  const onOpenDeletePsgAnalysisTemplateModal = () => {
    setIsDeletePsgAnalysisTemplateModalOpen(true);
  };

  const onOpenLoadAnalysisTemplateModal = () => {
    setIsLoadAnalysisTemplateModalOpen(true);
  };

  const onOpenDeleteAnalysisTemplateModal = () => {
    setIsDeleteAnalysisTemplateModalOpen(true);
  };

  const onLoadPsgAnalysisTemplateModalDone = (template: SavedPsgAnalysisTemplates) => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      container_id: containerId,
      event_category: 'psg_analysis_templates_load',
      event_label: 'Load PSG analysis template click'
    });

    void loadPsgAnalysisTemplate({
      loadCompSearchId: template?.compSearchId ?? 0,
      loadContainerId: template?.containerId ?? 0,
      loadJurisdictionId: template?.jurisdictionId ?? 0
    });
    onCloseSearchLibraryModal();
  };

  const onDeletePsgAnalysisTemplateModalDone = async (template: SavedPsgAnalysisTemplates) => {
    setIsDeletePsgAnalysisTemplateModalOpen(false);
    const { compSearchGlobalLibraryId } = template;

    await dispatch(deletePsgAnalysisTemplate({ compSearchGlobalLibraryId }));
    void dispatch(fetchSavedPsgAnalysisTemplates());
  };

  const onLoadAnalysisTemplateModalDone = async (analysisTemplate: CompSearchAnalysisTemplate) => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      container_id: containerId,
      event_category: 'analysis_templates_load',
      event_label: 'Load analysis template click'
    });

    setIsLoadAnalysisTemplateModalOpen(false);
    const loadContainer = containers?.find((container) => container.containerId === analysisTemplate.containerId);
    const jurisdictionId = matchJurisdictionPath?.params?.jurisdictionId;
    if (loadContainer && container && compSearchId && jurisdictionId && containerId) {
      const payload: LoadAnalysisTemplatePayload = {
        compSearchName: analysisTemplate.compSearchName,
        container: loadContainer,
        loadCompSearchId: analysisTemplate.compSearchId,
        loadJurisdictionId: analysisTemplate.jurisdictionId,
        loadContainerId: analysisTemplate.containerId,
        tenantId: analysisTemplate.tenantId
      };
      const params: LoadAnalysisTemplateParams = {
        compSearchId,
        jurisdictionId,
        containerId
      };
      await dispatch(loadAnalysisTemplate({ payload, params }));
      void dispatch(fetchJurisdictionDescription({ compSearchId, jurisdictionId }));
      void dispatch(fetchJurisdictionPlis({ compSearchId, jurisdictionId }));
    }
  };

  const onDeleteAnalysisTemplateModalDone = async (template: CompSearchAnalysisTemplate) => {
    setIsDeleteAnalysisTemplateModalOpen(false);
    const { tenantId, compSearchLocalLibraryId } = template;
    if (compSearchLocalLibraryId) {
      await dispatch(deleteAnalysisTemplate({ compSearchLocalLibraryId, tenantId }));
      if (container?.tenantId) void dispatch(fetchSavedAnalysisTemplates(container?.tenantId));
    }
  };

  const onCloseSearchLibraryModal = () => {
    setIsSaveAnalysisTemplateModalOpen(false);
    setIsSavePsgAnalysisTemplateModalOpen(false);
    setIsLoadAnalysisTemplateModalOpen(false);
    setIsDeleteAnalysisTemplateModalOpen(false);
    setIsLoadPsgAnalysisTemplateModalOpen(false);
    setIsDeletePsgAnalysisTemplateModalOpen(false);
  };

  const pliTableDisplay = () => {
    if (hasFetchErrors || !plis) {
      return <Paper className={classes.tile}>{t('analysis:error_pli_list_message')}</Paper>;
    }

    if (plis.length === 0) {
      return <Paper className={classes.tile}>{t('analysis:empty_pli_list_message')}</Paper>;
    }

    return plis.map((pli: JurisdictionPli, index: number) => (
      <SummaryTile
        key={pli.pli}
        data={[
          <div key="pliBlock" className={classes.pliBlock}>
            <Typography className={classes.body4}>{t(`analysis:${String(pli.pli)}`)}</Typography>
          </div>,
          <div key="pliTestedParty" className={classes.testedPartyPliBlock}>
            <Typography className={classes.testedPartPli}>{t('analysis:tested_party_pli')}</Typography>
            <Typography
              style={{
                backgroundColor:
                  pli?.inRangeStatus === `in_range_status.not_in_range`
                    ? theme.palette.warning.light
                    : theme.palette.primary.light,
                color:
                  pli?.inRangeStatus === `in_range_status.not_in_range`
                    ? theme.palette.warning.dark
                    : theme.palette.primary.dark
              }}
              className={classes.pliValue}
            >
              {pli?.testedPartyPliValue !== undefined && pli?.testedPartyPliValue !== null
                ? formatPli(pli, pli.testedPartyPliValue)
                : ' '}
            </Typography>
          </div>,
          <div key="pliStatus" className={classes.testedPartyStatusBlock}>
            <Typography className={classes.status}>{t('analysis:tested_party_pli_status')}</Typography>
            <Typography
              style={{
                backgroundColor:
                  pli?.inRangeStatus === `in_range_status.not_in_range`
                    ? theme.palette.warning.light
                    : theme.palette.primary.light,
                color:
                  pli?.inRangeStatus === `in_range_status.not_in_range`
                    ? theme.palette.warning.dark
                    : theme.palette.primary.dark
              }}
              className={classes.pliValue}
            >
              {t(`analysis:${String(pli?.inRangeStatus)}`)}
            </Typography>
          </div>,
          <div key="pliRange" className={classes.testedPartyRangeBlock}>
            <Typography
              className={classes.range}
              style={{
                color: pli?.exclude === 'global.yes' ? theme.palette.secondary.contrastText : theme.palette.primary.dark
              }}
            >
              {pli?.exclude === 'global.yes' ? t(`analysis:pli_range_excluded`) : t(`analysis:pli_range_included`)}
            </Typography>
            {editAccess(
              <CustomSwitch
                className={classes.switch}
                checked={pli?.exclude === 'global.no'}
                onChange={(e) => {
                  onChange(e.target.checked, index);
                }}
              />
            )}
          </div>,

          <div key="pliButton">
            <Button
              variant="contained"
              color="secondary"
              className={classes.viewAnalysisButton}
              endIcon={<ChevronRight />}
              onClick={() => {
                const path = `${jurisdictionPath}/comp-search/${compSearchId!}/pli/${pli.pliTypeId}/pli-avg/${
                  pli.pliAvgTypeId
                }`;
                history.push(path);
              }}
            >
              {t('analysis:action-view-analysis')}
            </Button>
          </div>
        ]}
      />
    ));
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.pliBoxContainer}>
        <Box className={classes.headerTitle}>
          <span className={classes.leftBlock}>
            {jurisdictionDescription?.jurisdictionName && (
              <>
                <Typography className={classes.title}>
                  {jurisdictionDescription?.jurisdictionName} {t('analysis:title-section-search-results')}
                </Typography>
                {showLibraryButtons && (
                  <DropDownButton
                    variant="contained"
                    color="primary"
                    className={classes.analysisButtons}
                    items={[
                      {
                        children: <Typography>{t(`analysis:pba-library-save`)}</Typography>,
                        onClick: () => {
                          onOpenSaveAnalysisTemplateModal();
                        }
                      },
                      {
                        children: <Typography>{t(`analysis:pba-library-load`)}</Typography>,
                        onClick: () => {
                          onOpenLoadAnalysisTemplateModal();
                        }
                      },
                      {
                        children: <Typography>{t(`analysis:pba-library-delete`)}</Typography>,
                        onClick: () => {
                          onOpenDeleteAnalysisTemplateModal();
                        }
                      }
                    ]}
                  >
                    {t(`analysis:pba-analysis-template-button`)}
                  </DropDownButton>
                )}
                {showLibraryButtons && hasPsgAnalysisTemplatesAccess() && (
                  <DropDownButton
                    variant="contained"
                    color="primary"
                    className={classes.analysisButtons}
                    items={[
                      {
                        children: <Typography>{t(`analysis:pba-library-save`)}</Typography>,
                        onClick: () => {
                          onOpenSavePsgAnalysisTemplateModal();
                        }
                      },
                      {
                        children: <Typography>{t(`analysis:pba-library-load`)}</Typography>,
                        onClick: () => {
                          onOpenLoadPsgAnalysisTemplateModal();
                        }
                      },
                      {
                        children: <Typography>{t(`analysis:pba-library-delete`)}</Typography>,
                        onClick: () => {
                          onOpenDeletePsgAnalysisTemplateModal();
                        }
                      }
                    ]}
                  >
                    {t(`analysis:pba-psg-analysis-template-button`)}
                  </DropDownButton>
                )}
              </>
            )}
          </span>
          <span className={classes.rightBlock}>
            <JurisdictionStatusDropDown
              jurisdictionStatus={jurisdictionStatus}
              updateJurisdictionStatus={updateJurisdictionStatus}
            />
          </span>
        </Box>
        <span className={classes.headerInfo}>
          <span className={classes.leftDataBlocks}>
            <span className={classes.infoBlock}>
              <Typography className={classes.infoTitle}>{t('analysis:title-tested-party')}</Typography>
              <Typography
                style={{
                  backgroundColor: tokens.product15,
                  textAlign: 'center',
                  borderRadius: '0.25rem',
                  color: theme.palette.primary.dark
                }}
                className={classes.infoValue}
              >
                {currentTestedParty?.name}
              </Typography>
            </span>
            <span className={classes.infoBlock}>
              <Typography className={classes.infoTitle}>
                {t('analysis:jurisdiction-comp-search-info-region-searched')}
              </Typography>
              <Typography className={classes.infoValue}>{jurisdictionDescription?.location}</Typography>
            </span>
            {jurisdictionDescription?.codeRanges && (
              <span className={classes.infoBlock}>
                <Typography className={classes.infoTitle}>
                  {t('analysis:jurisdiction-comp-search-info-industry-codes')}
                </Typography>
                <Typography className={classes.infoValue}>SIC {jurisdictionDescription?.codeRanges}</Typography>
              </span>
            )}
            {classifications.length > 0 &&
              jurisdictionDescription?.primaryFunctionId === PrimaryFunctions.ByName.ServiceProvider.Id && (
                <span className={classes.infoBlock}>
                  <Typography className={classes.infoTitle}>
                    {t('analysis:jurisdiction-comp-search-info-classifications')}
                  </Typography>
                  <Typography className={classes.infoValue}>{formatClassificationsDataLabel()}</Typography>
                </span>
              )}
            <span className={classes.infoBlock}>
              <Typography className={classes.infoTitle}>{t('analysis:jurisdiction-comp-search-info-data')}</Typography>
              <Typography className={classes.infoValue}>{formatPliDataLabel(plis)}</Typography>
            </span>
          </span>
          <span className={classes.endBlock}>
            <span className={classes.companiesBlock}>
              <Typography className={classes.infoTitle}>
                {t('analysis:jurisdiction-comp-search-info-fionas-comparables')}
              </Typography>
              <Typography className={classes.compsValue}>
                {jurisdictionDescription?.numCompsInPool &&
                  new Intl.NumberFormat().format(jurisdictionDescription.numCompsInPool)}
              </Typography>
            </span>
            <span>
              <Typography className={classes.infoTitle}>
                {t('analysis:jurisdiction-comp-search-info-private-public-searched')}
              </Typography>
              <Typography className={classes.compsValue}>
                {jurisdictionDescription?.initialCount &&
                  new Intl.NumberFormat().format(jurisdictionDescription.initialCount)}
              </Typography>
            </span>
          </span>
        </span>
      </Box>
      <Box className={classes.pliList}>
        {pliTableDisplay()}
        {showLibraryButtons && (
          <>
            <SaveAnalysisTemplateModal
              title={t('analysis:title.save-analysis-template')}
              isOpen={isSaveAnalysisTemplateModalOpen}
              onClose={onCloseSearchLibraryModal}
              onDone={onSaveAnalysisTemplateModalDone}
            />
            {hasPsgAnalysisTemplatesAccess() && (
              <>
                <SaveAnalysisTemplateModal
                  title={t('analysis:title.save-analysis-template')}
                  isOpen={isPsgSaveAnalysisTemplateModalOpen}
                  onClose={onCloseSearchLibraryModal}
                  onDone={onSavePsgAnalysisTemplateModalDone}
                />
                <LoadDeletePSGAnalysisTemplateModal
                  title={t('analysis:title.load-analysis-template')}
                  isOpen={isLoadPsgSaveAnalysisTemplateModalOpen}
                  onClose={onCloseSearchLibraryModal}
                  onDone={onLoadPsgAnalysisTemplateModalDone}
                />
                <LoadDeletePSGAnalysisTemplateModal
                  title={t('analysis:title.delete-analysis-template')}
                  isOpen={isDeletePsgSaveAnalysisTemplateModalOpen}
                  onClose={onCloseSearchLibraryModal}
                  onDone={onDeletePsgAnalysisTemplateModalDone}
                />
              </>
            )}

            <LoadDeleteAnalysisTemplateModal
              title={t('analysis:title.load-analysis-template')}
              isOpen={isLoadAnalysisTemplateModalOpen}
              onDone={onLoadAnalysisTemplateModalDone}
              onClose={onCloseSearchLibraryModal}
            />
            <LoadDeleteAnalysisTemplateModal
              title={t('analysis:title.delete-analysis-template')}
              isOpen={isDeleteAnalysisTemplateModalOpen}
              onDone={onDeleteAnalysisTemplateModalDone}
              onClose={onCloseSearchLibraryModal}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
