import { useTranslation } from 'react-i18next';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { TreeChart } from '../../../components';
import { Entity } from '../../../models';
import { formatPercentage } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    height: '100%'
  },
  majorityOwnership: {
    position: 'absolute',
    top: theme.spacing(-2),
    right: 0
  },
  dotContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    left: 0,
    position: 'absolute',
    right: 0,
    top: theme.spacing(-0.75)
  },
  dot: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '100%',
    height: theme.spacing(1.5),
    width: theme.spacing(1.5)
  }
}));

export const OrgChart = ({
  entities,
  onNavigate
}: {
  entities: Entity[] | null;
  onNavigate: (path: string) => void;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = entities
    ? entities.map((entity) => {
        const { entityId, code, domicile, parentEntities } = entity;
        const ownership = [...parentEntities]
          .sort((a, b) =>
            a.ownership === b.ownership
              ? (a.parent?.name ?? '') < (b.parent?.name ?? '')
                ? -1
                : 1
              : (b.ownership ?? 0) - (a.ownership ?? 0)
          )
          .filter(({ parent, ownership }) => parent && ownership)
          .map(({ parent, ownership }) => ({
            percent: formatPercentage(ownership! * 100),
            id: parent!.entityId.toString(),
            name: parent!.name
          }));
        return {
          id: entityId.toString(),
          content: (
            <>
              <Box className={classes.dotContainer}>
                {ownership.length > 1 &&
                  ownership.slice(1).map((ownership) => (
                    <Tooltip
                      key={ownership.id}
                      title={t('entities:tooltip-parent-ownership', ownership)!}
                      placement="top"
                    >
                      <Box className={classes.dot} />
                    </Tooltip>
                  ))}
              </Box>
              {ownership.length > 0 && <Box className={classes.majorityOwnership}>{ownership[0].percent}%</Box>}
              <Box color="primary.main">{code}</Box>
              {domicile?.isoCode && <Box>{domicile.isoCode}</Box>}
            </>
          ),
          parentId: ownership.length > 0 ? ownership[0].id : undefined
        };
      })
    : undefined;

  return (
    <Box className={classes.root}>
      <TreeChart
        data={data}
        nodeWidth={200}
        onNodeClick={(event, data) => {
          onNavigate(`/entities/${data.id}/dashboard`);
        }}
      />
    </Box>
  );
};
