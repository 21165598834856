import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { Box, Button, Chip, Paper, Typography, makeStyles } from '@material-ui/core';
import { PliValues } from '../../app/ProfitLevelIndicator/ProfitLevelIndicator.proptype';
import { CSInitialSearchJurisdictionData, LegalEntityTransaction, PbaCompsSearchOptions } from '../../models';
import {
  selectPbaCompSearchJurisdictionOptions,
  selectCurrentMajorClassification,
  selectParentContainerCompPoolInfo,
  selectPBAPli
} from '../../selectors';
import tokens from '../../styles/designTokens';
import { buttonText2, captionOverline } from '../../styles/typography';
import { Jurisdiction } from '../../svgs';
import { Overlay } from '../Overlay';

interface JurisdictionProps {
  countryId: number;
  isoCode: string;
  name: string;
  ready: boolean;
}

interface Jurisdictions {
  entitiesSortedBySource: LegalEntityTransaction[];
  isServiceProvider: boolean;
  searchAction: (countries: CSInitialSearchJurisdictionData[]) => void;
  isUpdating: boolean;
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '19rem'
  },
  searchButton: {
    width: '100%',
    maxWidth: '11.75rem'
  },
  searchMenu: {
    position: 'absolute',
    bottom: '3rem',
    padding: '1.5rem',
    boxShadow: '0 1px 6px 0 rgba(0,0,0,0.29)',
    background: tokens.white
  },
  searchRow: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: '1.5rem'
    }
  },
  activeRow: {
    cursor: 'pointer'
  },
  searchRowTextArea: {
    marginLeft: '1rem'
  },
  jurisdictionChip: {
    ...captionOverline,
    textTransform: 'uppercase',
    height: '3rem',
    width: '3rem',
    '& .MuiChip-label': {
      padding: 0
    }
  },
  jurisdictionChipReady: {
    color: tokens.white,
    background: tokens.purpleLight2
  },
  jurisdictionChipIncomplete: {
    color: tokens.neutral95,
    background: tokens.neutral70
  },
  readyChip: {
    ...captionOverline,
    textTransform: 'uppercase',
    color: tokens.product100,
    background: tokens.product15,
    height: 'auto',
    padding: '0.1rem 0.6rem 0.2rem',
    '& .MuiChip-label': {
      padding: 0
    }
  },
  incompleteChip: {
    ...captionOverline,
    textTransform: 'uppercase',
    color: tokens.core2,
    padding: '0.2rem 0'
  },
  jurisdictionNameReady: {
    ...buttonText2,
    color: tokens.core1
  },
  jurisdictionNameIncomplete: {
    ...buttonText2,
    color: tokens.core2
  },
  incompleteError: {
    ...buttonText2,
    color: '#FF0000',
    marginBottom: '1rem'
  }
}));

export const PbaSearchButton = ({
  entitiesSortedBySource,
  isServiceProvider,
  searchAction,
  isUpdating
}: Jurisdictions) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openSearchMenu, setOpenSearchMenu] = useState<boolean>(false);
  const currentMajorClassifications = useSelector(selectCurrentMajorClassification);
  const parentContainerCompPoolInfo = useSelector(selectParentContainerCompPoolInfo);
  let formInputs = useSelector(selectPbaCompSearchJurisdictionOptions);
  const pbaPli = useSelector(selectPBAPli);

  const { url } = useRouteMatch();
  const baseUrl = url.split('/initial-search')[0];

  const incomeStatementNavPath = `${baseUrl}/tested-party-details/income-statement`;
  const pliNavPath = `${baseUrl}/tested-party-details/profit-level-indicators`;
  const classificationNavPath = `${baseUrl}/tested-party-characterization`;

  const shouldUseParentContainerRunOnDate = Object.keys(formInputs).length === 0;
  if (shouldUseParentContainerRunOnDate) {
    formInputs = parentContainerCompPoolInfo?.parentContainerRunOnDate as {
      [key: number]: PbaCompsSearchOptions;
    };
  }

  const isClassificationsComplete = isServiceProvider
    ? currentMajorClassifications.length > 0 &&
      currentMajorClassifications.every((major) => {
        return major.minorServiceClassifications.length;
      })
    : true;

  const isTestedPartyFinancialsComplete = pbaPli?.pliValues.some((val: PliValues) => val.pliValue);

  const jurisdictions = useMemo(() => {
    return entitiesSortedBySource.map((tabEntity) => {
      const { countryId, isoCode, name } = tabEntity.entity.taxJurisdiction;
      const formInputsForTabEntity = formInputs?.[countryId];
      let ready = false;
      if (formInputsForTabEntity) {
        const { dataDate, chosenCountry, minSicCode, maxSicCode } = formInputsForTabEntity;

        ready =
          Boolean(dataDate) &&
          Boolean(chosenCountry) &&
          Boolean(
            isServiceProvider
              ? isClassificationsComplete && isTestedPartyFinancialsComplete
              : minSicCode && maxSicCode && isTestedPartyFinancialsComplete
          );
      }

      return {
        countryId,
        isoCode,
        name,
        ready
      };
    });
  }, [
    entitiesSortedBySource,
    formInputs,
    isServiceProvider,
    isClassificationsComplete,
    isTestedPartyFinancialsComplete
  ]);

  const searchNow = (isoCode: string, countryId: number, ready: boolean) => {
    setOpenSearchMenu(false);
    if (ready) {
      const countries: CSInitialSearchJurisdictionData[] = jurisdictions.map((jurisdiction) => ({
        countryId: jurisdiction.countryId,
        isOverride: isoCode === 'all' ? true : jurisdiction.countryId === countryId
      }));
      searchAction(countries);
    }
  };

  const allReady = jurisdictions.filter((j) => j.ready).length === jurisdictions.length;
  const allIsoCodes = jurisdictions.map((jurisdiction) => jurisdiction.isoCode);
  const uniqueIsoCodes = [...new Set(allIsoCodes)];
  const showMultipleJurisdictions = uniqueIsoCodes.length > 1;

  const jurisdictionRow = (jurisdiction: JurisdictionProps) => (
    <Box
      key={jurisdiction.name}
      className={`${jurisdiction.ready ? classes.activeRow : ''} ${classes.searchRow}`}
      onClick={() => {
        searchNow(jurisdiction.isoCode, jurisdiction.countryId, jurisdiction.ready);
      }}
    >
      <Chip
        className={`${classes.jurisdictionChip} ${classes.activeRow} ${
          jurisdiction.ready ? classes.jurisdictionChipReady : classes.jurisdictionChipIncomplete
        }`}
        label={jurisdiction.isoCode === 'all' ? <Jurisdiction /> : jurisdiction.isoCode}
      />
      <Box className={classes.searchRowTextArea}>
        <Typography className={jurisdiction.ready ? classes.jurisdictionNameReady : classes.jurisdictionNameIncomplete}>
          {jurisdiction.name}
        </Typography>
        {jurisdiction.ready ? (
          <Chip
            className={`${classes.readyChip} ${classes.activeRow}`}
            label={t('analysis:pba-initial-search-ready')}
          />
        ) : (
          <Typography className={classes.incompleteChip}>
            {t('analysis:pba-initial-search-complete-criteria')}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <Box className={classes.container}>
      {openSearchMenu && (
        <>
          <Overlay
            visible
            onClick={() => {
              setOpenSearchMenu(false);
            }}
          />
          <Paper className={classes.searchMenu}>
            <>
              {showMultipleJurisdictions
                ? jurisdictions.map((j: JurisdictionProps) => jurisdictionRow(j))
                : jurisdictionRow(jurisdictions[0])}
              {showMultipleJurisdictions &&
                jurisdictionRow({ ready: allReady, isoCode: 'all', name: 'Both Jurisdictions', countryId: -1 })}

              <Box>
                {isTestedPartyFinancialsComplete ? null : (
                  <Typography className={classes.incompleteError}>
                    {t('analysis:comp-search-missing-tested-party-financials')}
                    <Link style={{ color: 'red' }} to={incomeStatementNavPath}>
                      <strong>{t('analysis:comp-search-missing-income-statement')}</strong>
                    </Link>
                    {t('analysis:comp-search-missing-to-calculate')}

                    <Link style={{ color: 'red' }} to={pliNavPath}>
                      <strong>{t('analysis:comp-search-missing-pli')}</strong>
                    </Link>
                    {t('analysis:comp-search-missing-tested-party')}
                  </Typography>
                )}

                {isClassificationsComplete ? null : (
                  <Typography className={classes.incompleteError}>
                    {t('analysis:comp-search-missing-classifications')}

                    <Link style={{ color: 'red' }} to={classificationNavPath}>
                      <strong>{t('analysis:comp-search-missing-classifications-major-minor')}</strong>
                    </Link>
                    {t('analysis:comp-search-missing-classifications-tested-party')}
                  </Typography>
                )}
              </Box>
            </>
          </Paper>
        </>
      )}

      <Button
        variant="contained"
        color="primary"
        className={classes.searchButton}
        disabled={isUpdating}
        onClick={() => {
          setOpenSearchMenu(!openSearchMenu);
        }}
      >
        {isUpdating
          ? t('analysis:standardized_functional-analysis.updating')
          : t('analysis:standardized_functional-analysis.search')}
      </Button>
    </Box>
  );
};
