import { Box, Typography, makeStyles } from '@material-ui/core';
import { NestedListItem, NestedListSection } from '../NestedList.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%'
  },
  title: {
    flexGrow: 1
  }
}));

interface NestedListHeaderProps<T extends NestedListItem> {
  data: NestedListSection<T>;
}

export const Header = <T extends NestedListItem>({ data }: NestedListHeaderProps<T>) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="caption">
        {data.name}
      </Typography>
      {data.extra}
    </Box>
  );
};
