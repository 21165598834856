import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, List, Typography, makeStyles } from '@material-ui/core';
import { TbaAnalysisSideMenuProps } from './TbaAnalysisSideMenu.proptype';
import { clearTbaData } from '../../redux/transactionBasedAnalyses';
import { selectWorkingContainer } from '../../selectors';
import { captionOverline } from '../../styles/typography';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { AnalysisSideMenuItem } from '../NewAnalysisSideMenu/components/AnalysisSideMenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  list: {
    padding: theme.spacing(1, 2),
    '&:first-child': {
      flexGrow: 1
    }
  },
  sectionTitle: {
    ...captionOverline,
    padding: theme.spacing(1, 2, 0),
    textTransform: 'uppercase'
  }
}));

export const TbaAnalysisSideMenu = ({ sections, onNavigate }: TbaAnalysisSideMenuProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workingContainer = useSelector(selectWorkingContainer);
  useEffect(() => {
    return () => {
      void dispatch(clearTbaData());
    };
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      {sections.map(({ title, items }, i) => (
        <Fragment key={title ?? i}>
          {i > 0 && <Divider />}
          {title && <Typography className={classes.sectionTitle}>{title}</Typography>}
          <List component="nav" className={classes.list}>
            {items.map((props) => (
              <AnalysisSideMenuItem
                key={props.text}
                selected={false}
                onClick={() => {
                  /* eslint-disable camelcase */
                  void logGoogleAnalyticsEvent({
                    event_category: 'tba_button_click',
                    event_label: `${String(props.text)} click`,
                    container_id: workingContainer?.containerId
                  });
                  onNavigate(props.path);
                }}
                {...props}
              />
            ))}
          </List>
        </Fragment>
      ))}
    </Box>
  );
};
