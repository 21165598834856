import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, makeStyles, Typography, Theme, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { SearchBox } from '../../../../components';
import { SimpleDatePicker } from '../../../../components/SimpleDatePicker';
import { updatePbaJurisdictionCompSearchOptions } from '../../../../redux/profitBasedAnalyses';
import {
  selectCurrentValueAddedDistributor,
  selectPbaCompSearchJurisdictionOptions,
  selectSicTypes
} from '../../../../selectors';
import { AppDispatch } from '../../../../store';
import tokens from '../../../../styles/designTokens';
import { body3, body4 } from '../../../../styles/typography';
import { hasEditAccess } from '../../../../utils';
import { dateStringInOurFormatIsValidDate } from '../../../../utils/dates';
import {
  Distributor,
  IndustrialCodeValue,
  sicType
} from '../../../TestedPartyDistributor/TestedPartyDistributor.proptype';
import { InitialSearchSetupProps } from '../InitialSearch.proptype';
export interface ProgressButtonProps {
  isValueAddedDistributor: boolean;
  onChange?: (complete: boolean) => void;
}

export const initialSearchUseStyles = makeStyles<Theme, ProgressButtonProps>((theme) => ({
  formContainer: {
    padding: '2rem'
  },
  formElement: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.25rem'
  },
  inputField: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  sicSelectionContainer: {
    padding: theme.spacing(2),
    backgroundColor: tokens.neutral70
  },
  gridDistributor: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  sicCodeRangeTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  typographyHeading: body4,
  typographyLabel: {
    ...body3,
    color: tokens.core2,
    fontSize: '16px',
    marginLeft: theme.spacing(1)
  },
  gridDistributorContainer: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  connectorContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  connector: {
    backgroundColor: tokens.neutral70,
    minHeight: '1.125rem',
    width: '1.875rem'
  },
  errorText: {
    color: tokens.muiError
  },
  searchBoxInput: {
    '& .MuiOutlinedInput-root': {
      marginTop: theme.spacing(1)
    },
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.white,
      '&::placeholder': {
        color: tokens.neutral60
      }
    },
    '& > .MuiTextField-root .MuiAutocomplete-inputRoot': {
      color: theme.palette.common.white,
      placeholderColor: theme.palette.common.white,
      backgroundColor: tokens.purpleLight2,
      '& > .MuiAutocomplete-endAdornment .MuiIconButton-root': {
        color: theme.palette.common.white,
        placeholderColor: theme.palette.common.white
      }
    }
  },
  countrySearchInput: {
    width: '100%',
    marginBottom: theme.spacing(0.7)
  }
}));

export const InitialSearchDistributor = ({
  locations,
  jurisdictionId,
  recalculateProjectedCounts
}: InitialSearchSetupProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const sicTypes: sicType[] = useSelector(selectSicTypes);
  const currentValueAddedDistributor: Distributor = useSelector(selectCurrentValueAddedDistributor);
  const formInputs = useSelector(selectPbaCompSearchJurisdictionOptions);
  const currentFormInputs = formInputs?.[jurisdictionId] ?? {};
  const { chosenCountry, maxSicCode, minSicCode } = currentFormInputs;
  const { dataDate } = currentFormInputs;

  const displayedSicIndustries: IndustrialCodeValue[] = sicTypes.length > 0 ? sicTypes[1].codeTypes[0].subtypes : [];
  const isValueAddedDistributor: boolean = currentValueAddedDistributor.characterizationFunctionsPerformed
    ? currentValueAddedDistributor.characterizationFunctionsPerformed[0].state
    : false;

  const classes = initialSearchUseStyles({
    isValueAddedDistributor
  });

  const onSelectedSICIndustry = (event: any, selectedSICIndustry: IndustrialCodeValue, rangeType: 'min' | 'max') => {
    if (!selectedSICIndustry || !selectedSICIndustry.industrialCodeValueId) {
      return;
    }

    let selectedMinSicCode = minSicCode;
    let selectedMaxSicCode = maxSicCode;

    if (rangeType === 'min') {
      selectedMinSicCode = selectedSICIndustry;

      dispatch(
        updatePbaJurisdictionCompSearchOptions({
          jurisdictionId,
          minSicCode: selectedSICIndustry
        })
      );
    } else {
      selectedMaxSicCode = selectedSICIndustry;

      dispatch(
        updatePbaJurisdictionCompSearchOptions({
          jurisdictionId,
          maxSicCode: selectedSICIndustry
        })
      );
    }

    if (selectedMinSicCode && selectedMaxSicCode && chosenCountry && dataDate) {
      recalculateProjectedCounts({
        minSicCode: selectedMinSicCode,
        maxSicCode: selectedMaxSicCode,
        location: chosenCountry,
        dataDate
      });
    }
  };

  const onDateChange = (newDate: string | null) => {
    if (newDate && dateStringInOurFormatIsValidDate(newDate)) {
      dispatch(
        updatePbaJurisdictionCompSearchOptions({
          jurisdictionId,
          dataDate: newDate
        })
      );
      if (minSicCode && maxSicCode && chosenCountry) {
        recalculateProjectedCounts({ minSicCode, maxSicCode, location: chosenCountry, dataDate: newDate });
      }
    }
  };

  return (
    <div className={classes.formContainer}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Box className={classes.formElement}>
            <Typography className={classes.typographyHeading}>{t('analysis:title-choose-data-date')}</Typography>
            <Box className={classes.inputField}>
              <SimpleDatePicker
                key={`${jurisdictionId}-${String(dataDate)}`}
                defaultDate={dataDate ?? ''}
                onChange={onDateChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formElement}>
            <Typography className={classes.typographyHeading}>{t('analysis:title-select-country')}</Typography>
            <SearchBox
              className={clsx(classes.searchBoxInput, classes.countrySearchInput)}
              placeholder={t('analysis:placeholder.search_country_or_region')}
              noOptionsText={t('analysis:country-or-region-label-no-match')}
              value={chosenCountry ?? null}
              options={locations ?? []}
              getOptionLabel={(option: any) => option.name}
              getOptionSelected={(option: any, value: any) => option?.locationId === value?.locationId}
              renderOption={(option) => <Typography>{option.name}</Typography>}
              onChange={(_, data: any) => {
                if (data) {
                  dispatch(
                    updatePbaJurisdictionCompSearchOptions({
                      jurisdictionId,
                      chosenCountry: data
                    })
                  );

                  if (minSicCode && maxSicCode && data && dataDate) {
                    recalculateProjectedCounts({
                      minSicCode,
                      maxSicCode,
                      location: data,
                      dataDate
                    });
                  }
                }
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.formElement}>
            <Typography className={classes.typographyHeading}>
              {t('analysis:title-distributor-sic-code-range-selection')}
            </Typography>
            <Box className={classes.gridDistributor}>
              <Grid className={classes.gridDistributorContainer}>
                <Grid item xs={6} className={classes.sicSelectionContainer}>
                  <Box className={classes.sicCodeRangeTitle}>
                    <Typography className={classes.typographyHeading}>{t('common:label-minimum')}</Typography>
                    <Typography className={classes.typographyLabel}>{t('analysis:title-sic-code-range')}</Typography>
                  </Box>

                  <SearchBox
                    className={classes.searchBoxInput}
                    value={minSicCode ?? null}
                    getOptionLabel={(displayedSicIndustry) => {
                      return displayedSicIndustry
                        ? t(`${displayedSicIndustry.value} - ${t(`analysis:${displayedSicIndustry.name}`)}`)
                        : '';
                    }}
                    getOptionSelected={(option: IndustrialCodeValue, value: IndustrialCodeValue) =>
                      option.value === value.value
                    }
                    options={displayedSicIndustries}
                    noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                    placeholder={t('analysis:tested_party_characterization.search_industry_sic')}
                    disabled={!hasEditAccess()}
                    onChange={(event, selectedSICIndustry) => {
                      if (selectedSICIndustry) {
                        onSelectedSICIndustry(event, selectedSICIndustry, 'min');
                      }
                    }}
                  />
                </Grid>
                <Grid item className={classes.connectorContainer}>
                  <Box className={classes.connector} />
                </Grid>
                <Grid item xs={6} className={classes.sicSelectionContainer}>
                  <Box className={classes.sicCodeRangeTitle}>
                    <Typography className={classes.typographyHeading}>{t('common:label-maximum')}</Typography>
                    <Typography className={classes.typographyLabel}>{t('analysis:title-sic-code-range')}</Typography>
                  </Box>
                  <SearchBox
                    className={classes.searchBoxInput}
                    value={maxSicCode ?? null}
                    getOptionLabel={(displayedSicIndustry) => {
                      return displayedSicIndustry
                        ? t(`${displayedSicIndustry.value} - ${t(`analysis:${displayedSicIndustry.name}`)}`)
                        : '';
                    }}
                    getOptionSelected={(option: IndustrialCodeValue, value: IndustrialCodeValue) =>
                      option.value === value.value
                    }
                    options={displayedSicIndustries}
                    noOptionsText={t('analysis:tested_party_characterization.no_matching_industry_sic')}
                    placeholder={t('analysis:tested_party_characterization.search_industry_sic')}
                    disabled={!hasEditAccess()}
                    onChange={(event, selectedSICIndustry) => {
                      if (selectedSICIndustry) {
                        onSelectedSICIndustry(event, selectedSICIndustry, 'max');
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
