import { useEffect, useRef, useState } from 'react';
import useDebounce from '../../../utils/debounce';

export function useResizedDetection() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [resizeEvent, setResizeEvent] = useState(false);
  const [resizeCallback] = useDebounce(() => {
    setResizeEvent((previous) => !previous);
  }, 100);

  useEffect(() => {
    const element = wrapperRef.current;

    if (element !== null) {
      window.addEventListener('resize', resizeCallback);

      const resizeObserver = new ResizeObserver(() => {
        resizeCallback();
      });

      resizeObserver.observe(element);

      return () => {
        window.removeEventListener('resize', resizeCallback);

        resizeObserver.unobserve(element);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { wrapperRef, resizeEvent };
}
