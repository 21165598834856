import { useEffect, useRef, useState } from 'react';

// https://wattenberger.com/blog/react-and-d3#sizing-responsivity
export function useDimensions() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const element = wrapperRef.current;
    if (element !== null) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (Array.isArray(entries) && entries.length > 0) {
          const { contentRect } = entries[0];
          if (width !== contentRect.width) {
            setWidth(contentRect.width);
          }

          if (height !== contentRect.height) {
            setHeight(contentRect.height);
          }
        }
      });
      resizeObserver.observe(element);
      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { width, height, wrapperRef };
}
