import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../redux';
import { RollingContainersState } from '../redux/rollingContainers/rollingContainers.proptype';

export const selectRollingContainerState = (state: RootState) => state.rollingContainers;

export const selectRollingStatus = (state: RootState) => state.rollingContainers.rollingStatus;

export const selectRollingContainerIds = (state: RootState) => state.rollingContainers.rollingContainerIds;

export const selectRollingContainerStatus = createSelector(
  selectRollingContainerState,
  (rollingState: RollingContainersState) => ({
    canRollBack: rollingState.canRollBack,
    canRollForward: rollingState.canRollForward,
    cantRollBackReason: rollingState.cantRollBackReason,
    cantRollForwardReason: rollingState.cantRollForwardReason
  })
);

export const selectHistoricalCopyContainerInfo = createSelector(
  selectRollingContainerState,
  (rollingState: RollingContainersState) => rollingState.historicalCopyContainerInfo
);

export const selectRollingForecast = createSelector(
  selectRollingContainerState,
  ({ rollForwardForecast, rollBackForecast }: RollingContainersState) => {
    return {
      rollForwardForecast,
      rollBackForecast
    };
  }
);
