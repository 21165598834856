import { Container, Country, Currency, Entity, Transaction } from '../../models';

export const container: Container = {
  containerId: 3543,
  containerUuid: 'sjaghjdsa-sdafjsahfjisoda-3q4iq',
  name: "D. Paxton-Lawrence's Playground",
  taxYear: 2020,
  isService: true,
  isPlaceholder: false,
  tenantId: 809
};

export const currencies: Currency[] = [
  { currencyId: 50, name: 'Danish Khrone', isoCode: 'DKH' },
  { currencyId: 100, name: 'European Euro', isoCode: 'EUR' },
  { currencyId: 150, name: 'United States dollar', isoCode: 'USD' }
];

const upeName = 'Test MNE';

export const countries: Country[] = [
  { countryId: 235, name: 'United States of America', isoCode: 'USA', defaultCurrency: currencies[0] },
  { countryId: 83, name: 'Germany', isoCode: 'DEU', defaultCurrency: currencies[1] },
  { countryId: 150, name: 'Faroe Islands', isoCode: 'FRO', defaultCurrency: currencies[2] },
  { countryId: 23, name: 'Danemark', isoCode: 'DNK', defaultCurrency: currencies[2] }
];
countries[2].defaultJurisdiction = countries[3];

export const countryById = countries.reduce((byId, country) => ({ ...byId, [country.countryId]: country }), {});
export const allCountryIds = Object.keys(countryById);

export const entities: Entity[] = [
  {
    entityId: 62620,
    name: 'Germany Service Provider',
    upeName,
    code: 'germany-service',
    fiscalYearEnd: '2020-12-30',
    description: '',
    upe: false,
    upeCurrencyScale: 0,
    prepareByDate: '2015-03-21',
    primaryFunction: { primaryFunctionId: 3, name: 'Service Provider' },
    otherPrimaryFunction: '',
    container,
    domicile: countries[1],
    legalEntityCurrencyConversion: [{ taxYear: 2019, conversionValue: 1.2, localCurrencyScale: 0 }],
    taxJurisdiction: countries[1],
    upeCurrency: currencies[0],
    parentEntities: [],
    completionStatus: true
  },
  {
    entityId: 62619,
    name: 'Test Entity',
    upeName,
    code: 'test-entity',
    fiscalYearEnd: '2020-12-30',
    description: 'This is a test',
    upe: true,
    upeCurrencyScale: 0,
    prepareByDate: '2015-03-21',
    primaryFunction: { primaryFunctionId: 3, name: 'Service Provider' },
    otherPrimaryFunction: '',
    container,
    domicile: countries[0],
    legalEntityCurrencyConversion: [],
    taxJurisdiction: countries[0],
    upeCurrency: currencies[0],
    parentEntities: [{ ownership: null, parent: null, child: null }],
    completionStatus: true
  },
  {
    entityId: 62621,
    name: 'Faroe Islands',
    upeName,
    code: 'faroe-islands',
    fiscalYearEnd: '2021-06-17',
    description: '',
    upe: false,
    upeCurrencyScale: 0,
    prepareByDate: '2019-09-24',
    primaryFunction: { primaryFunctionId: 2, name: 'Distributor' },
    otherPrimaryFunction: '',
    container,
    domicile: countries[2],
    legalEntityCurrencyConversion: [],
    taxJurisdiction: countries[3],
    upeCurrency: currencies[0],
    parentEntities: [],
    completionStatus: false
  }
];
entities[1].parentEntities.push({ ownership: 1, parent: entities[0], child: null });
entities[2].parentEntities.push({ ownership: 1, parent: entities[0], child: null });

export const upeId = entities[0].entityId;

export const transactionsByEntityId = new Map<number, Transaction[]>([
  [entities[0].entityId, Array.from({ length: 48 })],
  [entities[1].entityId, Array.from({ length: 5 })],
  [entities[2].entityId, Array.from({ length: 14 })]
]);
