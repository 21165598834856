import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import { PliAveragesTable } from './PliAveragesTable';
import { PliValuesTable } from './PliValuesTable';
import { ToggleButtons } from '../../../components/ToggleButton';
import tokens from '../../../styles/designTokens';
import { ProfitLevelIndicatorProps } from '../ProfitLevelIndicator.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: tokens.neutral80
  },
  rowContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  row: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 2, 0),
    '& > *': {
      flex: 1
    }
  },
  paper: {
    overflow: 'hidden',
    height: '45rem',
    backgroundColor: theme.palette.background.default
  },
  testedPartyTitle: {
    color: theme.palette.secondary.dark
  }
}));

export const ProfitLevelIndicator = ({
  testedPartyName,
  handleAlignment,
  alignment,
  displayablePliAverages,
  displayablePliValues,
  buttonItems
}: ProfitLevelIndicatorProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.rowContainer}>
        <Paper className={classes.paper}>
          <Box display="flex" marginBottom="1rem" borderBottom="1px solid #efeff2" padding="2rem 1rem">
            <Typography variant="h2">{t('analysis:profit-level-indicators-pli')}</Typography>
            <Box marginLeft="5rem">
              <Typography className={classes.testedPartyTitle} variant="subtitle2">
                {t('analysis:column-tested-party-name')}
              </Typography>
              <Typography variant="subtitle2">{testedPartyName}</Typography>
            </Box>
          </Box>
          <Box padding="0.5rem 1rem">
            <ToggleButtons buttonItems={buttonItems} handleAlignment={handleAlignment} alignment={alignment} />
            {alignment === 'PLI Averages' ? (
              <PliAveragesTable displayablePliAverages={displayablePliAverages} />
            ) : (
              <PliValuesTable displayablePliValues={displayablePliValues} />
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
