import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles, Button, Drawer, Tabs, Tab, IconButton } from '@material-ui/core';
import editAccess from '../../components/HigherOrderComponents/EditAccess';
import tokens from '../../styles/designTokens';
import { title2, title3, body3, body4, buttonText2 } from '../../styles/typography';
import { PanelClose as CloseIcon } from '../../svgs';

const useStyles = makeStyles((theme) => ({
  panelHeight: {
    '& .MuiDrawer-paper': {
      top: '3.563rem'
    }
  },
  header: {
    marginBottom: '1rem',
    padding: '2rem 1rem',
    marginLeft: '2rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  smartTextButton: {
    paddingLeft: '2.5rem',
    paddingRight: '2.5rem',
    marginRight: '2.313rem',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    ...buttonText2,
    boxSizing: 'border-box',
    height: '2.25rem',
    width: '9.375rem',
    borderRadius: '0.25rem'
  },
  smartTextDrawer: {
    width: '30vw',
    top: '5rem',
    height: '100%',
    padding: '0 1rem',
    backgroundColor: tokens.neutral70,
    paddingLeft: '1.625rem'
  },
  spacedHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0.5rem 0 0 0.5rem'
  },
  standardizedTabpaperContainer: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginLeft: '0.5rem',
    maxHeight: '4rem',
    '& .Mui-selected': {
      color: theme.palette.primary.dark
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  drawerLabel: {
    ...body4,
    color: tokens.product100,
    margin: 'auto 0'
  },
  drawerLabelIcon: {
    background: tokens.product100,
    color: tokens.white,
    width: '2rem',
    height: '2rem',
    marginRight: '0.25rem',
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drawerHeader: {
    ...title2,
    color: tokens.core3,
    margin: '0.5rem 0 0 0.5rem'
  }
}));

interface FunctionalAnalysisHeaderProps {
  selectedContent: JSX.Element[];
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  isDrawerOpen: boolean;
  trackGATime: () => void;
}

export const FunctionalAnalysisHeader = ({
  setIsDrawerOpen,
  isDrawerOpen,
  selectedContent,
  trackGATime
}: FunctionalAnalysisHeaderProps) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const { t } = useTranslation();

  const tabs = [
    { key: 0, label: 'standardized' },
    { key: 1, label: 'search' }
  ];

  useEffect(() => {
    trackGATime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.header}>
      <Box>
        <Typography style={{ ...title3 }}>{t(`analysis:functional-analysis`)}</Typography>
        <Typography style={{ ...body3, color: tokens.core2 }}>
          {t(`analysis:functional-analysis-description`)}
        </Typography>
      </Box>
      <Box>
        {editAccess(
          <Button
            variant="outlined"
            color="primary"
            className={classes.smartTextButton}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          >
            {t(`analysis:smart-text`)}
          </Button>
        )}
      </Box>
      <Drawer
        hideBackdrop
        open={isDrawerOpen}
        anchor="right"
        className={classes.panelHeight}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      >
        <div className={classes.smartTextDrawer}>
          <div className={classes.spacedHeader}>
            <span className={classes.drawerLabel}>
              <span className={classes.drawerLabelIcon}>T</span>
              {t(`analysis:smart-text`)}
            </span>
            <IconButton
              onClick={() => {
                setIsDrawerOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.drawerHeader}>{t(`analysis:functional-analysis-narrative`)}</div>
          <Tabs
            className={classes.tabs}
            value={tab}
            onChange={(_, value) => {
              setTab(value);
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.key} label={t(`analysis:standardized_functional-analysis.${tab.label}`)} />
            ))}
          </Tabs>
          <Box className={classes.standardizedTabpaperContainer}>{selectedContent[tab]}</Box>
        </div>
      </Drawer>
    </Box>
  );
};
