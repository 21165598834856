import { useCallback, useMemo, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { PliAverageTypes, PliTypes } from '@xbs/xbs-enums';
import { ToggleButtons } from '../../../../../components';
import { FineTuningPLIInfoData } from '../../../../../models';
import { body2 } from '../../../../../styles/typography';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { Table } from '../../../../Table';
import { StickyColsDefinition, TableColumnDef } from '../../../../Table/Table.proptype';
import { ComparableInformationProps } from '../FineTuning.proptype';

enum SelectedTypeEnum {
  VALUE = 'VALUE',
  AVERAGE = 'AVERAGE'
}

const useStyles = makeStyles(() => ({
  wrapperComponent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  wrapperTable: {
    paddingTop: '0.5rem',
    height: '100%',
    overflow: 'auto'
  },
  scrollTable: {
    width: '100%',
    '& .MuiTableCell-body:first-child': {
      ...body2
    }
  }
}));

export const PLITab = (props: ComparableInformationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState(SelectedTypeEnum.VALUE);

  const { formatType, roundingValue } = getDisplayFormatAndRounding(props.pliFormat, props.pliId);

  const OptionsSelectedType = [
    { title: t('analysis:action.fine-tuning_pli-value'), value: SelectedTypeEnum.VALUE },
    { title: t('analysis:action.fine-tuning_pli-avg'), value: SelectedTypeEnum.AVERAGE }
  ];

  const onClickOption = useCallback((event: MouseEvent<HTMLElement>, newOption: string | null) => {
    if (newOption !== null) {
      setSelectedType(newOption as SelectedTypeEnum);
    }
  }, []);

  const uniqueYears = useMemo(() => new Set([...(props.pliInfo?.yearly ?? []).map((current) => current.taxYear)]), [
    props.pliInfo
  ]);

  const keyAvgMethodology = useCallback((pliAvgType: string) => {
    const numYearsAvg: number = PliAverageTypes.ById[pliAvgType].NumYearsInAvg;
    const avgMethodology: number = PliAverageTypes.ById[pliAvgType].AveragingMethodology;
    return `${numYearsAvg}-${avgMethodology}`;
  }, []);

  const columns = useMemo(() => {
    const stickyCols: StickyColsDefinition = {};
    const displayColumns: TableColumnDef[] = [];

    displayColumns.push({ key: 'pli', header: t('analysis:column.fine-tuning_pli-name'), width: '1rem' });
    stickyCols.pli = { side: 'left', position: 0 };

    if (selectedType === SelectedTypeEnum.VALUE) {
      uniqueYears.forEach((year) => {
        displayColumns.push({ key: year.toString(), header: year.toString(), width: '2rem' });
      });
    }

    if (selectedType === SelectedTypeEnum.AVERAGE) {
      Object.keys(PliAverageTypes.ById).forEach((pliAvgType) => {
        if (
          pliAvgType !== PliAverageTypes.ByName.OneYear.Id.toString() &&
          pliAvgType !== PliAverageTypes.ByName.OneYearAverage.Id.toString()
        ) {
          const nameAvgType: string = PliAverageTypes.ById[pliAvgType].Name;
          displayColumns.push({
            key: `${keyAvgMethodology(pliAvgType)}`,
            header: <>{t(`analysis:${nameAvgType}`)}</>,
            width: '1rem'
          });
        }
      });
    }

    return {
      stickyCols,
      displayColumns
    };
  }, [keyAvgMethodology, selectedType, t, uniqueYears]);

  const findValueYear = useCallback(
    (year: number, pliType: string) => {
      const valuesByYearType: FineTuningPLIInfoData[] | undefined = props.pliInfo?.yearly?.filter(
        (pliInfo) => pliInfo.taxYear === year && pliInfo.pliTypeId === pliType
      );

      if (valuesByYearType) {
        return formatPliDisplayValue(formatType, roundingValue, valuesByYearType[0].pliValue);
      }

      return 0;
    },
    [formatType, props.pliInfo?.yearly, roundingValue]
  );

  const findValueAverage = useCallback(
    (pliType: string) => {
      const valuesByYearType: FineTuningPLIInfoData[] | undefined = props.pliInfo?.average?.filter(
        (pliInfo) => pliInfo.pliTypeId === pliType
      );
      const valuesAverage: any = {};
      if (valuesByYearType) {
        Object.keys(PliAverageTypes.ById).forEach((pliAvgType) => {
          if (
            pliAvgType !== PliAverageTypes.ByName.OneYear.Id.toString() &&
            pliAvgType !== PliAverageTypes.ByName.OneYearAverage.Id.toString()
          ) {
            const valueByAvgType: FineTuningPLIInfoData | undefined = valuesByYearType.find(
              (pli) => pli.pliAverageTypeId === pliAvgType
            );
            if (valueByAvgType) {
              const key = keyAvgMethodology(pliAvgType);
              valuesAverage[key] = formatPliDisplayValue(formatType, roundingValue, valueByAvgType.pliValue);
            }
          }
        });
      }

      return valuesAverage;
    },
    [formatType, keyAvgMethodology, props.pliInfo?.average, roundingValue]
  );

  const data = useMemo(() => {
    const dataFin: Array<Record<string, HTMLElement | number>> = [];
    Object.keys(PliTypes.ById)
      .filter((type) => type !== PliTypes.ByName.None.Id.toString())
      .forEach((pliType) => {
        let valueByType: any = {};
        uniqueYears.forEach((year) => {
          if (selectedType === SelectedTypeEnum.VALUE) {
            valueByType[year] = findValueYear(year, pliType);
          }

          if (selectedType === SelectedTypeEnum.AVERAGE) {
            const averages = findValueAverage(pliType);
            valueByType = { ...valueByType, ...averages };
          }
        });

        const namePliType: string = PliTypes.ById[pliType].Name;
        dataFin.push({ pli: <>{t(`analysis:${namePliType}`)}</>, ...valueByType });
      });
    return dataFin;
  }, [findValueAverage, findValueYear, selectedType, t, uniqueYears]);

  return (
    <div className={classes.wrapperComponent}>
      <ToggleButtons buttonItems={OptionsSelectedType} handleAlignment={onClickOption} alignment={selectedType} />
      <div className={classes.wrapperTable}>
        <Table
          className={classes.scrollTable}
          data={data}
          columns={columns.displayColumns}
          stickyCols={columns.stickyCols}
        />
      </div>
    </div>
  );
};
