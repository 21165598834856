import { PliDisplayFormats } from '@xbs/xbs-enums';
import { PbaJurisdictionPliFormat } from '../models';

export const formatPliDisplayValue = (displayFormat: string, roundingValue: number, value: number) => {
  let formattedValue;
  if (displayFormat === 'percentage') {
    const percentageValue = `${(value * 100).toFixed(roundingValue)}%`;
    formattedValue =
      percentageValue === `-0.${'0'.repeat(roundingValue)}%` ? `0.${'0'.repeat(roundingValue)}%` : percentageValue;
  } else {
    const decimalValue = value.toFixed(roundingValue);
    formattedValue =
      decimalValue === `-0.${'0'.repeat(roundingValue)}` ? `0.${'0'.repeat(roundingValue)}` : decimalValue;
  }

  return formattedValue;
};

export const getDisplayFormatAndRounding = (pliFormat: PbaJurisdictionPliFormat | null | undefined, pliId: number) => {
  let formatType = PliDisplayFormats.ById[pliId].Value.displayFormat === 1 ? 'decimal' : 'percentage';
  let { roundingValue } = PliDisplayFormats.ById[pliId].Value;

  if (pliFormat) {
    formatType = pliFormat?.displayFormat === PliDisplayFormats.ById[2].Value.displayFormat ? 'decimal' : 'percentage';
    roundingValue = pliFormat?.roundingValue;
  }

  return { formatType, roundingValue };
};
