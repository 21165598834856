import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { DropDownButton } from '../../../components';
import { TransactionsEventsProps } from '../Transactions.proptype';

export const AddTransactionsButton = ({ onAddSingle, onImport }: TransactionsEventsProps) => {
  const { t } = useTranslation();
  return (
    <DropDownButton
      variant="contained"
      color="primary"
      items={[
        { children: t('transactions:action-new'), onClick: onAddSingle },
        { children: t('transactions:action-import'), onClick: onImport }
      ]}
    >
      <Add />
      {t('transactions:action-add-choice')}
    </DropDownButton>
  );
};
