import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { CheckCircle, Cancel, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { DropDownButton } from '../../../../../components';
import { body1 } from '../../../../../styles/typography';
import { hasEditAccess } from '../../../../../utils';
import { RejectionStatusDropdownProps } from '../FineTuning.proptype';

const useStyles = makeStyles(() => ({
  statusDropDown: {
    minWidth: 'none',
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  statusIcon: {
    ...body1,
    width: '1.375rem',
    height: '1.375rem',
    strokeWidth: '0rem'
  },
  checkCircle: {
    color: '#6E56DB'
  },
  acceptedCircle: {
    color: '#6E56DB'
  },
  cancelCircle: {
    backgroundColor: 'inherit'
  },
  dropDown: {
    ...body1,
    paddingLeft: '0.5rem'
  }
}));

export const RejectionStatusDropdown = ({
  children,
  handleAccepted,
  handleAcceptedAndReviewed,
  handleRejected
}: RejectionStatusDropdownProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const dropDownItems = [
    {
      children: (
        <>
          <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
          <Typography className={`${classes.dropDown}`}>
            {t(`analysis:comparable-range-accepted-and-reviewed`)}
          </Typography>
        </>
      ),
      onClick: handleAcceptedAndReviewed
    },
    {
      children: (
        <>
          <RadioButtonUncheckedOutlined className={`${classes.acceptedCircle} ${classes.statusIcon}`} />
          <Typography className={`${classes.dropDown}`}>{t(`analysis:comparable-range-accepted`)}</Typography>
        </>
      ),
      onClick: handleAccepted
    },
    {
      children: (
        <>
          <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
          <Typography className={classes.dropDown}>{t(`analysis:comparable-range-rejected`)}</Typography>
        </>
      ),
      onClick: handleRejected
    }
  ];

  return (
    <DropDownButton
      disabled={!hasEditAccess()}
      variant="text"
      className={`${classes.statusDropDown}`}
      items={dropDownItems}
    >
      {children}
    </DropDownButton>
  );
};
