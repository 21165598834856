import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { TEXT_EDITOR_MAX_SIZE } from '../../constants';
import tokens from '../../styles/designTokens';

export interface ErrorWrapperProps {
  length: number;
  children: ReactElement;
}

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%'
  },
  error: {
    borderRadius: '0.25rem',
    border: `1px solid ${tokens.muiError}`
  }
}));

export const ErrorMessage = ({ error, message }: { error: boolean; message: string }) => (
  <Typography color="error" variant="caption" style={{ visibility: error ? 'visible' : 'hidden' }}>
    {message}
  </Typography>
);

export const ErrorWrapper = ({ length, children }: ErrorWrapperProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [lengthError, setLengthError] = useState<boolean>(false);

  useEffect(() => {
    if (length > TEXT_EDITOR_MAX_SIZE) {
      setLengthError(true);
    } else {
      setLengthError(false);
    }
  }, [length]);

  return (
    <>
      <Box className={`${lengthError ? `${classes.error} ${classes.fullHeight}` : classes.fullHeight}`}>{children}</Box>
      <ErrorMessage error={lengthError} message={t('analysis:content-over-limit')} />
    </>
  );
};
