import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Typography, Switch, Theme, FormControlLabel } from '@material-ui/core';
import { hasEditAccess } from '../../../utils';
import { TestedPartyDistributorProps } from '../TestedPartyDistributor.proptype';

export interface ProgressButtonProps {
  isValueAddedDistributor: boolean;
  onChange?: (complete: boolean) => void;
}

const useStyles = makeStyles<Theme, ProgressButtonProps>((theme) => ({
  labelRoot: {
    backgroundColor: ({ isValueAddedDistributor }) =>
      isValueAddedDistributor ? theme.palette.primary.light : theme.palette.secondary.light,
    borderRadius: 20,
    color: ({ isValueAddedDistributor }) =>
      isValueAddedDistributor ? theme.palette.primary.main : theme.palette.text.primary,
    margin: 0,
    padding: theme.spacing(0.5, 1.5, 0.5, 1),
    textTransform: 'uppercase'
  },
  label: {
    fontSize: '0.9em'
  },
  switchRoot: {
    display: 'flex',
    height: 12,
    marginRight: '1em',
    padding: 0,
    width: 28
  },
  switchBase: {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      '& + .MuiSwitch-track': {
        opacity: 1
      }
    }
  },
  track: {
    backgroundColor: theme.palette.secondary.dark,
    opacity: 1
  },
  thumb: {
    color: theme.palette.common.white,
    boxShadow: 'none',
    height: 8,
    width: 8
  }
}));

export const TestedPartyDistributor = ({ toggleDistributor, isValueAddedDistributor }: TestedPartyDistributorProps) => {
  const { label, labelRoot, switchRoot, ...switchClasses } = useStyles({
    isValueAddedDistributor
  });

  const { t } = useTranslation();

  return (
    <Box>
      <Box margin="1rem 3rem" display="flex">
        <Typography variant="subtitle1">{t('analysis:tested_party_characterization.is_value_added')}</Typography>
        <Box marginLeft="1rem">
          <FormControlLabel
            classes={{ root: labelRoot, label }}
            control={
              <Switch
                classes={{ root: switchRoot, ...switchClasses }}
                checked={isValueAddedDistributor}
                color={isValueAddedDistributor ? 'primary' : 'secondary'}
                disabled={!hasEditAccess()}
                onChange={() => {
                  toggleDistributor(isValueAddedDistributor);
                }}
              />
            }
            label={t(isValueAddedDistributor ? 'Yes' : 'No')}
          />
        </Box>
      </Box>
    </Box>
  );
};
