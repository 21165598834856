import { makeStyles, TextField } from '@material-ui/core';

interface LabelInputProps {
  isActive: boolean;
  disabled?: boolean;
  value: string;
  onChange: (content: string) => void;
  onBlur: () => void;
  onSubmit: () => void;
  placeholder?: string;
  classOverrides?: Record<string, string>;
  maxLength?: number;
}

const useStyles = makeStyles(() => ({
  enabled: {
    width: '100%'
  },
  disabled: {
    width: '100%',
    border: 'none',
    cursor: 'disabled'
  }
}));

export const LabelInput = ({
  isActive,
  disabled = false,
  value,
  onChange,
  onBlur,
  onSubmit,
  placeholder,
  classOverrides,
  maxLength
}: LabelInputProps) => {
  const classes = useStyles();

  return (
    <TextField
      classes={classOverrides}
      autoFocus={isActive}
      variant="outlined"
      value={value}
      placeholder={placeholder ?? ''}
      disabled={!isActive || disabled}
      className={isActive ? classes.enabled : classes.disabled}
      onBlur={() => {
        onBlur();
      }}
      onChange={(content: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = content.target.value;
        if (maxLength && newValue.length > maxLength) {
          newValue = content.target.value.slice(0, maxLength);
        }

        onChange(newValue);
      }}
      onKeyUp={(key: any) => {
        if (key.key === 'Enter') {
          onSubmit();
        }
      }}
    />
  );
};
