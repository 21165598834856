import {
  FilingTimingByJurisdiction,
  ReportFileTypeName,
  ReportsUtilitiesGetNameProps,
  ReportsUtilitiesProps
} from './Reports.proptype';
import { Entity, GlobalGuide, Transaction } from '../../models';

const entitiesForReportsTable = (entities: Entity[] | null, transactions: Transaction[] | null): Entity[] => {
  if (entities && entities.length > 0) {
    const countryList: Entity[] = [];
    const uniqueCountryObj: any = {};
    entities.forEach((entity) => {
      if (!uniqueCountryObj[entity.domicile.name]) {
        const existTransactions = transactions?.find((transaction) =>
          transaction.legalEntityTransactions.find((legalEntity) => legalEntity.entity.entityId === entity.entityId)
        );
        if (existTransactions) {
          uniqueCountryObj[entity.domicile.name] = entity;
          countryList.push(entity);
        }
      }
    });
    return countryList;
  }

  return [];
};

const filingTimingByJurisdiction = (globalGuides: GlobalGuide[] | null) => {
  const filingTimingWtpReviewTypeId = 5;
  const data: FilingTimingByJurisdiction = {};

  if (!globalGuides) {
    return data;
  }

  globalGuides.forEach((globalGuide) => {
    const filingTime = globalGuide.wtpData.find(
      ({ wtpReviewType: { wtpReviewTypeId } }) => wtpReviewTypeId === filingTimingWtpReviewTypeId
    );

    data[globalGuide.isoCode] = filingTime?.value;
  });

  return data;
};

export const formatReportsData = ({
  allEntities,
  allTransactions,
  reportsInfo,
  globalGuides
}: ReportsUtilitiesProps) => {
  const reportCounts = {
    draft: 0,
    review: 0,
    published: 0,
    final: 0,
    totalJurisdictions: 0,
    jurisdictionsWithReports: 0
  };

  const entitiesForReports = entitiesForReportsTable(allEntities, allTransactions);
  const filingTimingData = filingTimingByJurisdiction(globalGuides);

  const entityReportData = entitiesForReports
    ? entitiesForReports.map((entity: Entity) => {
        const associatedReport: any = reportsInfo
          ? reportsInfo.find((report) => report.domicileId === entity.domicile.countryId)
          : null;

        const { domicile, entityId } = entity;
        const { countryId: jurisdictionId } = domicile;
        const countryName = domicile.name ? domicile.name : '';
        const { countryId: domicileId, isoCode } = domicile;
        const { prepareByDate } = associatedReport ?? entity;
        const domicileStatus = associatedReport?.domicileStatus ?? 0;
        const dueDate = prepareByDate ?? '';
        const filingTiming = filingTimingData[isoCode];
        const hasReports = Boolean(
          associatedReport?.draft || associatedReport?.review || associatedReport?.published || associatedReport?.final
        );
        const draft = associatedReport?.draft ?? 0;
        const review = associatedReport?.review ?? 0;
        const published = associatedReport?.published ?? 0;
        const final = associatedReport?.final ?? 0;
        reportCounts.draft += Number(draft);
        reportCounts.review += Number(review);
        reportCounts.published += Number(published);
        reportCounts.final += Number(final);
        reportCounts.totalJurisdictions++;
        reportCounts.jurisdictionsWithReports += hasReports ? 1 : 0;

        return {
          countryName,
          domicileId,
          domicileStatus,
          dueDate,
          draft,
          entityId,
          isoCode,
          filingTiming,
          final,
          hasReports,
          jurisdictionId,
          published,
          review
        };
      })
    : [];
  return { entityReportData, reportCounts };
};

export const getReportName = ({ reportName, reportTemplateId, version }: ReportsUtilitiesGetNameProps) => {
  if (reportTemplateId === undefined || reportTemplateId === null) return `${reportName} V${version}`;

  const reportTemplateName = reportTemplateId.includes(ReportFileTypeName.analysisBenchmarkReport)
    ? ReportFileTypeName.benchmarkReport
    : ReportFileTypeName.localFile;

  return `${reportName} ${reportTemplateName} V${version}`;
};
