import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import decodeJWT from 'jwt-decode';
import { Container, Country, Currency, Entity, Language, NormalizedStateSlice, PrimaryFunction } from '../../models';
import httpService from '../../services/http';
interface BaseDataState {
  containers: NormalizedStateSlice<Container>;
  countries: NormalizedStateSlice<Country>;
  currencies: NormalizedStateSlice<Currency>;
  primaryFunctions: NormalizedStateSlice<PrimaryFunction>;
  languages: NormalizedStateSlice<Language>;
  workingContainerId?: number;
  upeId?: number;
  upeCurrencyId?: number;
  error?: string;
  isBaseDataFetch: boolean;
  isContainerRollable: boolean;
}

interface JWTToken {
  // eslint-disable-next-line camelcase
  given_name: string;
  // eslint-disable-next-line camelcase
  family_name: string;
  'cognito:username': string;
}

const initialState: BaseDataState = {
  containers: { byId: null, allIds: [] },
  countries: { byId: null, allIds: [] },
  currencies: { byId: null, allIds: [] },
  primaryFunctions: { byId: null, allIds: [] },
  languages: { byId: null, allIds: [] },
  isBaseDataFetch: false,
  isContainerRollable: false
};

export const fetchBaseData = createAsyncThunk<
  {
    containers: Container[];
    currencies: Currency[];
    countries: Country[];
    primaryFunctions: PrimaryFunction[];
    languages: Language[];
    upe: Entity;
  },
  void,
  { rejectValue: Error }
>('baseData/fetch', async (_, { rejectWithValue }) => {
  try {
    const [
      { data: containersResponse },
      { data: countriesResponse },
      { data: upeResponse },
      { data: currenciesResponse },
      { data: primaryFunctionResponse }
    ] = await Promise.all([
      httpService.request<{ data: Container[] }>({
        method: 'get',
        apiUrlKey: 'principleUrl',
        relativePath: 'container'
      }),
      httpService.request<{ data: Country[] }>({ method: 'get', apiUrlKey: 'baseUrl', relativePath: 'countries' }),
      httpService.request<{ data: Entity }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: 'containers/workingContainer'
      }),
      httpService.request<{ data: Currency[] }>({ method: 'get', apiUrlKey: 'baseUrl', relativePath: 'currencies' }),
      httpService.request<{ data: PrimaryFunction[] }>({
        method: 'get',
        apiUrlKey: 'baseUrl',
        relativePath: 'primary-functions'
      })
    ]);

    // TODO: Remove this once legacy UI is deprecated
    const token = localStorage.getItem('idToken');
    if (token) {
      const decodedToken = decodeJWT(token);

      const user = {
        firstName: (decodedToken as JWTToken).given_name || 'User',
        lastName: (decodedToken as JWTToken).family_name || '',
        username: (decodedToken as JWTToken)['cognito:username']
      };

      localStorage.setItem('user', JSON.stringify(user));
    }

    // TODO: Remove this once legacy UI is deprecated
    localStorage.setItem('workingContainer', JSON.stringify(upeResponse?.data?.container));
    localStorage.setItem('countries', JSON.stringify(countriesResponse?.data));
    localStorage.setItem('containerUpeId', JSON.stringify(upeResponse?.data?.entityId || 0));
    localStorage.setItem('containerUpeCurrency', JSON.stringify(upeResponse?.data?.upeCurrency));
    localStorage.setItem('containerUpeCurrencyScale', JSON.stringify(upeResponse?.data?.upeCurrencyScale || 0));
    localStorage.setItem(
      'containersList',
      JSON.stringify(
        containersResponse?.data.map((container) => ({
          ...container,
          displayName: `${container.name} ${container.taxYear}`
        }))
      )
    );
    localStorage.setItem('workspaceChoice', 'createDocumentation');

    return {
      containers: containersResponse?.data ?? [],
      countries: countriesResponse?.data ?? [],
      currencies: currenciesResponse?.data ?? [],
      primaryFunctions: primaryFunctionResponse?.data ?? [],
      // in languages missing service to gel all supported languages
      languages: [
        {
          languageId: 1,
          name: 'English - United States',
          nativeName: 'English',
          localizationCode: 'EN-US',
          dateFormat: ''
        },
        {
          languageId: 2,
          name: 'Spanish - Colombia',
          nativeName: 'Spanish',
          localizationCode: 'ES-CO',
          dateFormat: ''
        }
      ],
      // in case the current container has no UPE, we receive an object with 'container' property only.
      upe: upeResponse?.data ?? {}
    };
  } catch (error: unknown) {
    return rejectWithValue(error as Error);
  }
});

export const getWorkingContainer = createAsyncThunk<Entity, void, { rejectValue: Error }>(
  'baseData/getWorkingContainer',
  async (_, { rejectWithValue }) => {
    try {
      // in case the current container has no UPE, we receive an object with 'container' property only.
      return (
        (
          await httpService.request<{ data: Entity }>({
            method: 'get',
            apiUrlKey: 'baseUrl',
            relativePath: 'containers/workingContainer'
          })
        ).data.data ?? {}
      );
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const getContainers = createAsyncThunk<Container[], void, { rejectValue: Error }>(
  'baseData/fetch/containers',
  async (_, { rejectWithValue }) => {
    try {
      const { data: containersList } = await httpService.request<{ data: Container[] }>({
        method: 'get',
        apiUrlKey: 'principleUrl',
        relativePath: 'container'
      });

      localStorage.setItem(
        'containersList',
        JSON.stringify(
          containersList?.data.map((container) => ({
            ...container,
            displayName: `${container.name} ${container.taxYear}`
          }))
        )
      );

      return containersList.data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

const storeInSlice = <T extends Record<string, any>>(slice: NormalizedStateSlice<T>, data: T[], idProp: string) => {
  slice.byId = {};
  slice.allIds = [];
  for (const datum of data ?? []) {
    slice.byId[datum[idProp]] = datum;
    slice.allIds.push(datum[idProp]);
  }
};

const baseDataSlice = createSlice({
  name: 'baseData',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        fetchBaseData.fulfilled,
        (state, { payload: { containers, countries, currencies, primaryFunctions, upe, languages } }) => {
          storeInSlice(state.containers, containers, 'containerId');
          storeInSlice(state.countries, countries, 'countryId');
          storeInSlice(state.currencies, currencies, 'currencyId');
          storeInSlice(state.primaryFunctions, primaryFunctions, 'primaryFunctionId');
          storeInSlice(state.languages, languages, 'languageId');
          state.workingContainerId = upe.container?.containerId;
          state.upeId = upe.entityId;
          state.upeCurrencyId = upe.upeCurrency?.currencyId;
          state.isBaseDataFetch = true;
          state.isContainerRollable = upe.container.isRollable ?? false;
        }
      )
      .addCase(getWorkingContainer.fulfilled, (state, { payload: upe }) => {
        state.workingContainerId = upe.container?.containerId;
        state.upeId = upe.entityId;
        state.upeCurrencyId = upe.upeCurrency?.currencyId;
      })
      .addCase(getContainers.fulfilled, (state, { payload: containersList }) => {
        storeInSlice(state.containers, containersList, 'containerId');
      })
      .addMatcher(
        // do not import { saveEntity } from '../entities' to avoid creating a circular dependency
        (action) => action.type === 'entities/save/fulfilled',
        (state, { payload }) => {
          if (payload.upe && !state.upeId) {
            state.upeId = payload.entityId;
            state.upeCurrencyId = payload.upeCurrency?.currencyId;
          }
        }
      )
      .addMatcher(
        (action) => action.type.match(/^baseData\/.+\/pending$/),
        (state) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^baseData\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default baseDataSlice.reducer;
