import { useTranslation } from 'react-i18next';
import { Button, LinearProgress, makeStyles } from '@material-ui/core';
import {
  FunctionalAnalysisCharacteristic,
  TBAFunctionalAnalysisCharacteristic
} from './FunctionalAnalysisTable.proptype';
import tokens from '../../styles/designTokens';
import { body1, body2, captionOverline, titleSmallPrimary } from '../../styles/typography';

const useStyles = makeStyles(() => ({
  primaryCard: {
    background: tokens.product15,
    display: 'flex',
    justifyContent: 'space-between',
    border: `solid 1px ${tokens.product25}`,
    borderRadius: '0.25rem',
    padding: '1rem 1.438rem 1rem 1.438rem',
    margin: '2rem 0 2rem 0.5rem'
  },
  primaryHeader: {
    ...body2,
    color: tokens.product100,
    margin: '0.5rem 0 0 0'
  },
  primarySubheader: {
    ...body1,
    color: tokens.product90,
    margin: '0 0 0.5rem 0'
  },
  primaryButton: {
    margin: 'auto 0',
    color: tokens.product100,
    ...tokens.mediumFont,
    backgroundColor: tokens.product25
  },
  selectedSubtitle: {
    ...captionOverline,
    color: tokens.core2,
    textTransform: 'uppercase',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    marginLeft: '0.5rem'
  },
  secondaryCard: {
    backgroundColor: 'white',
    border: `solid 1px ${tokens.neutral90}`,
    borderRadius: '0.25rem',
    padding: '1.625rem 1.625rem 1.4rem 1.625rem',
    marginLeft: '0.5rem'
  },
  standardizedTextBadge: {
    color: tokens.product100,
    ...captionOverline,
    width: 'fit-content',
    backgroundColor: tokens.product15,
    borderRadius: '0.25rem',
    padding: '0.25rem'
  },
  characterizationTitle: {
    ...titleSmallPrimary,
    color: tokens.core1,
    margin: '0.5rem 0'
  },
  defaultText: {
    ...body1,
    color: tokens.core1,
    margin: '0.5rem 0'
  },
  replacementRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5rem 0 1rem 0'
  },
  replacementDescription: {
    ...body1,
    color: tokens.core2,
    margin: 'auto 0'
  },
  loadingButton: {
    width: '100%',
    color: tokens.product100,
    backgroundColor: tokens.product25,
    '& .MuiLinearProgress-indeterminate': {
      backgroundColor: tokens.white
    }
  }
}));

interface StandardizedFunctionalAnalysisWizardProps {
  onApply: () => void;
  onBulkApply: () => void;
  selectedAnalysis: FunctionalAnalysisCharacteristic | TBAFunctionalAnalysisCharacteristic | undefined;
  FASingleApplyStatus: boolean;
  FABulkApplyStatus: boolean;
}

export const StandardizedFunctionalAnalysisWizard = ({
  onApply,
  onBulkApply,
  selectedAnalysis,
  FASingleApplyStatus,
  FABulkApplyStatus
}: StandardizedFunctionalAnalysisWizardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    // Todo: Use functionalCharacteristicType from TBA: DUO-1611
    <>
      <div className={classes.primaryCard}>
        <span>
          <div className={classes.primaryHeader}>{t(`analysis:standardized_functional_analysis.bulk_apply_title`)}</div>
          <div className={classes.primarySubheader}>
            {t(`analysis:standardized_functional_analysis.bulk_apply_text`)}
          </div>
        </span>

        <Button
          className={classes.primaryButton}
          onClick={() => {
            onBulkApply();
          }}
        >
          {!FABulkApplyStatus && t(`analysis:action-apply`)}
          {FABulkApplyStatus && (
            <div className={classes.loadingButton}>
              <LinearProgress />
            </div>
          )}
        </Button>
      </div>
      <span className={classes.selectedSubtitle}>
        {t(`analysis:standardized_functional_analysis.selected_characteristics`)}
      </span>
      <div className={classes.secondaryCard}>
        <div className={classes.standardizedTextBadge}>
          {t(`analysis:standardized_functional_analysis.standardized_text`).toUpperCase()}
        </div>
        {selectedAnalysis ? (
          <>
            <div className={classes.characterizationTitle}>
              {selectedAnalysis.functionalCharacteristicType
                ? t(`analysis:${selectedAnalysis.functionalCharacteristicType.name}`)
                : selectedAnalysis.characteristicName}
            </div>
            <div className={classes.defaultText}>
              {selectedAnalysis.functionalCharacteristicType
                ? selectedAnalysis?.functionalCharacteristicType?.discussion
                : t(`analysis:standardized_functional_analysis.no-default-discussion`)}
            </div>
            {selectedAnalysis && (
              <div className={classes.replacementRow}>
                <span className={classes.replacementDescription}>
                  {t(`analysis:standardized_functional_analysis.characterization_replacement_text`)}
                </span>
                <Button
                  className={classes.primaryButton}
                  onClick={() => {
                    onApply();
                  }}
                >
                  {FASingleApplyStatus && (
                    <div className={classes.loadingButton}>
                      <LinearProgress />
                    </div>
                  )}
                  {!FASingleApplyStatus && t(`analysis:action-apply`)}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className={classes.defaultText}>
            {t(`analysis:standardized_functional_analysis.no-characteristic-selected`)}
          </div>
        )}
      </div>
    </>
  );
};
