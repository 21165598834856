import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, makeStyles, Radio, Theme, Typography } from '@material-ui/core';
import { TransactionsSortBy, SelectedTransaction } from './CreatePBAModal.proptypes';
import { SearchAndSort, SortOrder } from '../../components';
import { Transaction, Entity, Currency } from '../../models';
import { filterData, sortData } from '../../services/filtering';
import tokens from '../../styles/designTokens';
import { titleFont } from '../../styles/fonts';
import { currencyValueFormatter, findTransactionDestination, findTransactionSource } from '../../utils';
import { parseAndFormatForDisplay } from '../../utils/dates';
import { Table } from '../Table';

const useStyles = makeStyles((theme: Theme) => ({
  counterLabel: {
    color: tokens.product100,
    padding: theme.spacing(0.5, 0),
    fontSize: '0.9rem',
    fontFamily: titleFont.fontFamily,
    fontWeight: 400,
    lineHeight: 1.167
  },
  formDivider: {
    marginTop: theme.spacing(2)
  },
  radioButton: {
    marginRight: theme.spacing(0),
    width: '0.5rem'
  },
  table: {
    borderSpacing: theme.spacing(0, 0.5),
    '& .MuiTableCell-root': {
      textAlign: 'center',
      whiteSpace: 'normal'
    },
    '& .MuiTableRow-root:not(.MuiTableRow-head)': {
      cursor: 'pointer'
    },
    '& .Mui-selected': {
      borderRadius: theme.spacing(0.5),
      boxShadow: `inset 0 0 0 1px  ${theme.palette.primary.dark}`,
      '& .MuiTableCell-root': {
        backgroundColor: 'transparent'
      }
    },
    '& .Mui-selected:hover, & .Mui-selected': {
      backgroundColor: theme.palette.action.hover,
      '& .MuiTableCell-root.MuiTableCell-body': {
        border: 'none',
        '&:nth-child(2)': {
          fontWeight: '600'
        }
      }
    },
    '& .MuiTableCell-root.MuiTableCell-body .MuiIconButton-root': {
      color: theme.palette.primary.dark
    }
  },
  tableContainer: {
    overflow: 'auto',
    maxHeight: theme.spacing(24.5)
  }
}));
interface ProfitBasedAnlaysisAddModalTableProps {
  upeCurrency?: Currency;
  transactions: Transaction[] | null;
  selectedTransactions: SelectedTransaction[];
  entities: Entity[] | null | undefined;
  onChange: (selectedTransactions: SelectedTransaction[]) => void;
}

export const ProfitBasedAnalysisAddModalTable = ({
  selectedTransactions,
  transactions,
  upeCurrency,
  entities,
  onChange
}: ProfitBasedAnlaysisAddModalTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sortObject, setSort] = useState<{ sortBy?: TransactionsSortBy; sortOrder?: SortOrder }>({});
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const [selectedIndex, setSelectedIndex] = useState<number[]>([]);
  const entityById = useMemo(() => new Map((entities ?? []).map((entity) => [entity.entityId, entity])), [entities]);

  const FormDivider = () => (
    <Grid item xs={12}>
      <Divider variant="fullWidth" />
    </Grid>
  );

  const FormDividerTop = () => (
    <Grid item xs={12} className={classes.formDivider}>
      <Divider variant="fullWidth" />
    </Grid>
  );

  useEffect(() => {
    const selectedTransactionIds = new Set(selectedTransactions.map((tr) => tr.transactionId));
    const transactionIds = transactions ? transactions.map((tr) => tr.transactionId) : [];
    const currentSelections = transactions
      ? transactions
          .filter((tr) => selectedTransactionIds.has(tr.transactionId))
          .map((tr) => transactionIds.indexOf(tr.transactionId))
      : [];

    setSelectedIndex(currentSelections);
  }, [transactions, selectedTransactions]);

  const handleSelected = ({ index, id, transactionId }: SelectedTransaction) => {
    const transactionindex = selectedTransactions.findIndex((transaction) => transaction.id === id);
    const newSelected = [...selectedTransactions];

    if (transactionindex === -1) {
      newSelected.push({ index, id, transactionId });
    } else {
      newSelected.splice(transactionindex, 1);
    }

    onChange(newSelected);
  };

  const displayableTransactions = (transactions ?? []).map((transaction) => {
    const { transferDate, transactionType, propertyTransferred, value, identifier, transactionId } = transaction;
    const source = findTransactionSource(transaction, entityById)?.code ?? '';
    const destination = findTransactionDestination(transaction, entityById)?.code ?? '';

    return {
      icon: (
        <Radio
          className={classes.radioButton}
          checked={selectedTransactions.some(({ id }) => id === identifier)}
          value={identifier}
          name={`transaction-${identifier}`}
          size="small"
        />
      ),
      source,
      destination,
      type: transactionType?.name ? t(`transactions:type-${transactionType.name}`) : '',
      identifier,
      transferDate: transferDate
        ? t(`date-format-short`, {
            date: parseAndFormatForDisplay(transferDate)
          })
        : '',
      property: propertyTransferred,
      value: value ? new Intl.NumberFormat('en', currencyValueFormatter(2, 2, upeCurrency!)).format(value) : '',
      transactionId
    };
  });

  return (
    <>
      <Grid item xs={12} className={classes.tableContainer}>
        <Table
          className={classes.table}
          selectedIndices={selectedIndex}
          data={sortData(filterData(displayableTransactions, filterObject), sortObject)}
          columns={[
            {
              key: 'icon'
            },
            {
              key: 'source',
              header: (
                <SearchAndSort
                  field={t('transactions:column-source')}
                  onSortClicked={(sortOrder) => {
                    setSort({ sortBy: 'source', sortOrder });
                  }}
                  onSearchChange={(value) => {
                    setFilter({ ...filterObject, source: value });
                  }}
                />
              ),
              width: 250
            },
            {
              key: 'destination',
              header: (
                <SearchAndSort
                  field={t('transactions:column-destination')}
                  onSortClicked={(sortOrder) => {
                    setSort({ sortBy: 'destination', sortOrder });
                  }}
                  onSearchChange={(value) => {
                    setFilter({ ...filterObject, destination: value });
                  }}
                />
              ),
              width: 250
            },
            {
              key: 'type',
              header: (
                <SearchAndSort
                  field={t('transactions:column-type')}
                  onSortClicked={(sortOrder) => {
                    setSort({ sortBy: 'type', sortOrder });
                  }}
                  onSearchChange={(value) => {
                    setFilter({ ...filterObject, type: value });
                  }}
                />
              ),
              width: 250
            },
            {
              key: 'property',
              header: t('transactions:label-property-transferred')
            },
            {
              key: 'transferDate',
              header: t('analysis:column-transaction-transfer-date')
            },
            {
              key: 'value',
              header: `${t('analysis:column-transaction-amount')} (${
                upeCurrency?.isoCode ? upeCurrency?.isoCode : 'USD'
              })`
            }
          ]}
          onRowClick={(event, index, row) => {
            event.preventDefault();
            handleSelected({ index, id: row.identifier, transactionId: row.transactionId });
          }}
        />
      </Grid>
      <FormDividerTop />
      <Grid container item xs={12} justify="flex-start">
        <Typography className={classes.counterLabel}>
          {selectedTransactions.length} {t('analysis:column-selected-transactions')}
        </Typography>
      </Grid>
      <FormDivider />
    </>
  );
};
