import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Select, MenuItem } from '@material-ui/core';
import { ConfirmationPinnedComparableModalProps } from './ConfirmationPinnedComparableModal.proptype';
import { useStyles } from './ConfirmationPinnedComparableModal.styles';
import { WarningIcon } from '../../../../../svgs';
import { TPModal, TPModalActions } from '../../../../TPModal';

export const ConfirmationPinnedComparableModal = ({
  isoCodes,
  onDone,
  isOpen,
  onClose
}: ConfirmationPinnedComparableModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedJurisdictionId, setSelectedJurisdictionId] = useState<string>(
    isoCodes.length > 1 ? 'both' : String(isoCodes[0].jurisdictionId)
  );

  const title = (
    <span className={classes.modalTitleWrapper}>
      <WarningIcon className={classes.warningIcon} />
      <Typography className={classes.title}>{t(`analysis:title-pinned-comparables-confirmation`)}</Typography>
    </span>
  );

  const handleOnDone = () => {
    onDone(selectedJurisdictionId);
  };

  const actionButtons: TPModalActions[] = [
    {
      name: t('action-cancel'),
      handler: onClose,
      className: classes.cancelButton,
      color: 'inherit',
      variant: 'text'
    },
    {
      name: t('action-confirm'),
      handler: handleOnDone,
      color: 'primary',
      variant: 'contained'
    }
  ];

  const selectOptions: Array<{ key: string; value: string; label: string }> = isoCodes.map((isoCode) => ({
    key: String(isoCode.jurisdictionId),
    value: String(isoCode.jurisdictionId),
    label: isoCode.isoCode
  }));

  if (isoCodes.length > 1) {
    selectOptions.push({
      key: 'both',
      value: 'both',
      label: t('analysis:choose-both')
    });
  }

  const handleOnChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelectedJurisdictionId(String(event.target.value));
  };

  return (
    <TPModal
      isOpen={isOpen}
      maxWidth="sm"
      title={title}
      actions={actionButtons}
      containerStyle={classes.modalContent}
      onClose={onClose}
    >
      <Box>
        <Typography className={classes.description}>
          {t('analysis:label-pinned-comparables-confirmation-text')}
        </Typography>
        <Box className={classes.selectContainer}>
          <Typography className={classes.selectJurisdictionsContainer}>
            {t('analysis:title-pinned-jurisdictions')}
          </Typography>
          <Select
            value={selectedJurisdictionId}
            className={classes.selectStyles}
            variant="outlined"
            onChange={handleOnChange}
          >
            {selectOptions.map(({ key, label, value }) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </TPModal>
  );
};
