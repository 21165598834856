import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Launch } from '@material-ui/icons';

interface EmptyTabProps {
  isTBA?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

export const EmptyTab = ({ isTBA, onClick = () => ({}) }: EmptyTabProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Typography variant="h6">{t(isTBA ? 'title-tba' : 'title-pba')}</Typography>
      <Typography>{t(isTBA ? 'transactions:message-go-to-tba' : 'transactions:message-go-to-pba')}</Typography>
      <Button variant="outlined" endIcon={<Launch />} onClick={onClick}>
        {t(isTBA ? 'link-tba' : 'link-pba')}
      </Button>
    </Box>
  );
};
