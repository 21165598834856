import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  TbaTransactionOverviewProps,
  TbaTransactionOverviewFormDataInputs,
  TbaTransactionOverviewFormSearchStrategyInputs
} from './TbaTransactionOverview.proptype';
import { CenteredProgress } from '../../components';
import { TransactionBasedAnalysis } from '../../models';
import { fetchStudies } from '../../redux/studies';
import {
  editTbaSearchStrategy,
  fetchTbaEvaluationMethods,
  fetchTbaSearchStrategy,
  fetchTBAs,
  saveTBA,
  fetchCurrentTBA,
  saveTbaFinancialInfo,
  saveTbaFinancialInfoParams,
  TbaControlledParameter
} from '../../redux/transactionBasedAnalyses';
import { fetchTransactions } from '../../redux/transactions';
import {
  selectCurrentTBA,
  selectCurrentTBALocked,
  selectStudy,
  selectTbaEvaluationMethods,
  selectTbaParameters,
  selectTbaSearchStrategy,
  selectTransactionsList,
  selectUPECurrency,
  selectWorkingContainer
} from '../../selectors';
import { hasEditAccess } from '../../utils';
import { FinancialInfoFieldValues, FinancialInfoValues } from '../NewAnalysisModal/CreateTBAModal.proptypes';
import { tbaParameterIdMap } from '../StudyDashboard/connector';
import { TbaStudy } from '../TbaAnalysisHeader';
import { tbaAssociateTransactionPayload } from '../TbaAssociateTransactionModal/TbaAssociateTransactionModal.proptype';

interface ConnectorProps {
  component: ComponentType<TbaTransactionOverviewProps>;
}

const Connector = ({ component: Component }: ConnectorProps) => {
  // Todo - use useDispatch<AppConfig>()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tba = useSelector(selectCurrentTBA);
  const container = useSelector(selectWorkingContainer);
  const tbaEvaluationMethods = useSelector(selectTbaEvaluationMethods);
  const tbaSearchStrategy = useSelector(selectTbaSearchStrategy);
  const tbaPropertiesLocked = useSelector(selectCurrentTBALocked);
  const [initialTransactionTypes, setInitialTransactionTypes] = useState<Array<{ title: string; value: number }>>([]);

  const tbaTransactions = tba?.transactions ?? [];
  const upeCurrency: any = useSelector(selectUPECurrency);

  const match = useRouteMatch('/analysis/:studyId/tba-dashboard/:tbaId');

  const params: TbaStudy = match?.params as TbaStudy;

  const studyId = Number.parseInt(params?.studyId, 10);
  const tbaId = Number.parseInt(params?.tbaId, 10);

  const study = useSelector(selectStudy(studyId));
  const transactions = useSelector(selectTransactionsList);
  const tbaParameters = useSelector(selectTbaParameters);

  const skipTbaControlledParameters =
    tba?.tbaEvaluationMethod?.name === 'CUT/CUP' && tba?.transactionType.name === 'Tangible Goods';

  const onSubmitAssociatedTransactions = async (data: tbaAssociateTransactionPayload) => {
    if (!hasEditAccess()) return;

    // @Todo: figure out how to save transactions if the types are CUT/CUP & 'Tangible Goods' (or other derived)
    const tbaControlledParameters: TbaControlledParameter[] = skipTbaControlledParameters
      ? [] /* sending empty array since financial info field is calculated */
      : Object.keys(data.financialInfo)
          .filter((propertyName: any) => Boolean(tbaParameterIdMap[propertyName as FinancialInfoFieldValues]))
          .map((propertyName: any) => {
            const regex = /[\s$%,]/gi;
            const parameterValue = Number(
              data.financialInfo[propertyName as FinancialInfoFieldValues]?.replace(regex, '')
            );
            const tbaParameterId: number = tbaParameterIdMap[propertyName as FinancialInfoFieldValues] ?? 0;

            return {
              parameterValue,
              tba: {
                exclude: false,
                tbaEvaluationMethod: {},
                transactionType: {},
                tbaId
              },
              tbaParameter: {
                tbaParameterId,
                type: propertyName,
                description: propertyName,
                calculated: false,
                optional: false
              }
            };
          });
    const saveFinancialInfoPayload: saveTbaFinancialInfoParams = {
      tbaId,
      addTransactionIds: data.addTransactionIds,
      container,
      removeTransactionIds: data.removedTransactionIds,
      tbaControlledParameters
    };
    unwrapResult(await dispatch<any>(saveTbaFinancialInfo(saveFinancialInfoPayload)));
    unwrapResult(await dispatch<any>(fetchCurrentTBA(tbaId)));
  };

  useEffect(() => {
    if (!tba) {
      dispatch(selectCurrentTBA);
    }

    if (tba) {
      if (tbaEvaluationMethods.length === 0) {
        dispatch(fetchTbaEvaluationMethods());
      } else {
        const transactionTypes = tbaEvaluationMethods.find(
          (method) => method.tbaEvaluationMethodId === tba?.tbaEvaluationMethod.tbaEvaluationMethodId
        )?.transactionTypes;
        if (transactionTypes) {
          const tbaTransactionTypes = transactionTypes.map((transactionType) => ({
            title: transactionType.name,
            value: transactionType.transactionTypeId
          }));
          tbaTransactionTypes.sort((option1, option2) => (option1.title > option2.title ? 1 : -1));
          if (transactionTypes) setInitialTransactionTypes(tbaTransactionTypes);
        }
      }

      dispatch(fetchTbaSearchStrategy(tba.tbaId));
    }
  }, [dispatch, tba, tbaEvaluationMethods]);

  useEffect(() => {
    if (transactions === null) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (study === null) {
      void dispatch(fetchStudies());
    }
  }, [study, dispatch]);

  const onSubmitTBAData = (tbaData: TbaTransactionOverviewFormDataInputs) => {
    if (!hasEditAccess()) return;
    if (tba && tbaData) {
      const updatedTba: Partial<TransactionBasedAnalysis> = {
        name: tbaData.name,
        description: tba.description,
        exclude: tba.exclude,
        tbaId: tba.tbaId
      };
      dispatch<any>(saveTBA({ tba: updatedTba, t })).then(() => {
        dispatch(fetchTBAs);
      });
    }
  };

  const onSubmitTBASearchStrategy = (tbaSearchStrategyData: TbaTransactionOverviewFormSearchStrategyInputs) => {
    if (!hasEditAccess()) return;
    if (tba && container) {
      const { tbaId } = tba;
      const strategy = tbaSearchStrategyData.searchStrategy ?? '';
      dispatch<any>(editTbaSearchStrategy({ tbaId, strategy, container })).then(() => {
        dispatch(fetchTbaSearchStrategy(tbaId));
      });
    }
  };

  const onSubmitFinancialInfo = async (financialInfo: FinancialInfoValues) => {
    if (!hasEditAccess()) return;

    if (tba && financialInfo) {
      const { tbaId } = tba;
      const tbaControlledParameters: TbaControlledParameter[] = skipTbaControlledParameters
        ? [] /* sending empty array since financial info field is calculated */
        : Object.keys(financialInfo)
            .filter((propertyName: any) => Boolean(tbaParameterIdMap[propertyName as FinancialInfoFieldValues]))
            .map((propertyName: any) => {
              const regex = /[\s$%,]/gi;
              const parameterValue = Number(
                financialInfo[propertyName as FinancialInfoFieldValues]?.replace(regex, '')
              );
              const tbaParameterId: number = tbaParameterIdMap[propertyName as FinancialInfoFieldValues] ?? 0;

              return {
                parameterValue,
                tba: {
                  exclude: false,
                  tbaEvaluationMethod: {},
                  transactionType: {},
                  tbaId
                },
                tbaParameter: {
                  tbaParameterId,
                  type: propertyName,
                  description: propertyName,
                  calculated: false,
                  optional: false
                }
              };
            });

      const saveFinancialInfoPayload: saveTbaFinancialInfoParams = {
        tbaId,
        addTransactionIds: [],
        container,
        removeTransactionIds: [],
        tbaControlledParameters
      };

      unwrapResult(await dispatch<any>(saveTbaFinancialInfo(saveFinancialInfoPayload)));
      void dispatch(fetchCurrentTBA(tbaId));
    }
  };

  const onDeleteAssociatedTransactionFromTBA = async (payload: saveTbaFinancialInfoParams) => {
    await dispatch<any>(saveTbaFinancialInfo(payload)).then(() => {
      if (tba) {
        dispatch(fetchCurrentTBA(tba.tbaId));
      }
    });
  };

  return tba ? (
    <Component
      tba={tba}
      tbaPropertiesLocked={tbaPropertiesLocked}
      tbaEvaluationMethodTypes={tbaEvaluationMethods}
      tbaSearchStrategy={tbaSearchStrategy}
      tbaTransactions={tbaTransactions}
      initialTransactionTypes={initialTransactionTypes}
      study={study}
      transactions={transactions}
      upeCurrency={upeCurrency}
      tbaParameters={tbaParameters}
      onDeleteAssociatedTransactionFromTBA={onDeleteAssociatedTransactionFromTBA}
      onSubmitTBASearchStrategy={onSubmitTBASearchStrategy}
      onSubmitTBAData={onSubmitTBAData}
      onSubmitAssociatedTransactions={onSubmitAssociatedTransactions}
      onSubmitFinancialInfo={onSubmitFinancialInfo}
    />
  ) : (
    <CenteredProgress />
  );
};

export default Connector;
