import { ReactElement, ReactNode, cloneElement } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

type ButtonWithLoadingSpinnerProps = ButtonProps & {
  loading: boolean;
  loadingClassName?: string;
  loadingSize?: string;
  container?: ReactElement;
};

const ButtonWithLoadingSpinner = (props: ButtonWithLoadingSpinnerProps) => {
  const { loading, children, loadingClassName, loadingSize, container, ...rest } = props;

  const renderChildren = (): ReactNode => {
    return loading ? <CircularProgress className={loadingClassName} size={loadingSize} /> : children;
  };

  return (
    <Button {...rest}>{container ? cloneElement(container, { children: renderChildren() }) : renderChildren()}</Button>
  );
};

export default ButtonWithLoadingSpinner;
