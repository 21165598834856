import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Connector from './connector';
import RouteOptions from '../Router/RouteOptions';
import { TbaComparableAnalysis } from '../TbaComparableAnalysis';
import { TBAFunctionalAnalysis } from '../TbaFunctionalAnalysis';
import { TbaTransactionOverview } from '../TbaTransactionOverview';
import { TbaUncontrolledTransactions } from '../TbaUncontrolledTransactions';

const ChildRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route key="transaction-overview" path={`${path}/transaction-overview`}>
        <Connector component={TbaTransactionOverview} />
      </Route>
      <Route key="functional-analysis" path={`${path}/functional-analysis`}>
        <Connector component={TBAFunctionalAnalysis} />
      </Route>
      <Route key="uncontrolled-transactions" path={`${path}/uncontrolled-transactions`}>
        <Connector component={TbaUncontrolledTransactions} />
      </Route>
      <Route key="comparable-analysis" path={`${path}/comparable-analysis`}>
        <Connector component={TbaComparableAnalysis} />
      </Route>
      <Redirect to={`${path}/transaction-overview`} />
    </Switch>
  );
};

export const TransactionBasedAnalysisDashboard = () => {
  return (
    <RouteOptions
      RootRoute={<Connector component={TbaTransactionOverview} />}
      ChildRoutes={[{ path: '/:tbaId', component: <ChildRoutes /> }]}
    />
  );
};
