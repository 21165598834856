import { ComponentType, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProfitLevelIndicatorProps,
  PliValuesTypes,
  PliAveragesTypes,
  DisplayablePliValues,
  DisplayablePliAverages
} from './ProfitLevelIndicator.proptype';
import { CenteredProgress } from '../../components';
import { FetchLoadingStateEnum } from '../../constants';
import { fetchPBAPli } from '../../redux/profitBasedAnalyses';
import { selectCurrentPBA, selectPBALoadingState, selectPBAPli } from '../../selectors';

interface ConnectorProps {
  component: ComponentType<ProfitLevelIndicatorProps>;
}

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const pba = useSelector(selectCurrentPBA);
  const currentPbaId = pba?.pbaId;
  const pbaPli = useSelector(selectPBAPli);
  const loadingStates = useSelector(selectPBALoadingState);

  useEffect(() => {
    if (
      !loadingStates.fetchPBAPli &&
      (!loadingStates.updateTestedPartyFinancial ||
        loadingStates.updateTestedPartyFinancial === FetchLoadingStateEnum.fulfilled)
    ) {
      dispatch(fetchPBAPli(currentPbaId!));
    }
  }, [currentPbaId, loadingStates, dispatch]);
  const currentPba = useSelector(selectCurrentPBA);
  const testedPartyName = currentPba?.testedParty?.name ?? '';
  const pliValues = pbaPli?.pliValues;
  const pliAverages = pbaPli?.pliAverages;
  const buttonItems = [
    { title: 'PLI Values', value: 'PLI Values' },
    { title: 'PLI Averages', value: 'PLI Averages' }
  ];
  const initialValue = buttonItems ? buttonItems[0].value : null;
  const [alignment, setAlignment] = useState<string | null>(initialValue);

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const displayablePliValues: DisplayablePliValues[] = useMemo(() => {
    const tableRows = {
      GrossMargin: { name: 'Gross Margin' },
      BerryRatio: { name: 'Berry Ratio' },
      OperatingMargin: { name: 'Operating Margin' },
      RtnonOperAssets: { name: 'Rtn on Oper Assets' },
      CostPlus: { name: 'Cost Plus' },
      NetCostPlus: { name: 'Net Cost Plus' }
    } as any;
    (pliValues ?? []).forEach((pliValue) => {
      if (pliValue.taxYear) {
        tableRows[PliValuesTypes[pliValue.pliTypeId]][pliValue.taxYear] = pliValue.roundedValue;
      }
    });
    return Object.values(tableRows);
  }, [pliValues]);

  const displayablePliAverages: DisplayablePliAverages[] = useMemo(() => {
    const tableRows = {
      GrossMargin: {
        name: 'Gross Margin',
        Year3Simple: null,
        Year3Weighted: null,
        Year3PeriodWeighted: null,
        Year5Simple: null,
        Year5Weighted: null,
        Year5PeriodWeighted: null
      },
      BerryRatio: {
        name: 'Berry Ratio',
        Year3Simple: null,
        Year3Weighted: null,
        Year3PeriodWeighted: null,
        Year5Simple: null,
        Year5Weighted: null,
        Year5PeriodWeighted: null
      },
      OperatingMargin: {
        name: 'Operating Margin',
        Year3Simple: null,
        Year3Weighted: null,
        Year3PeriodWeighted: null,
        Year5Simple: null,
        Year5Weighted: null,
        Year5PeriodWeighted: null
      },
      RtnonOperAssets: {
        name: 'Rtn on Oper Assets',
        Year3Simple: null,
        Year3Weighted: null,
        Year3PeriodWeighted: null,
        Year5Simple: null,
        Year5Weighted: null,
        Year5PeriodWeighted: null
      },
      CostPlus: {
        name: 'Cost Plus',
        Year3Simple: null,
        Year3Weighted: null,
        Year3PeriodWeighted: null,
        Year5Simple: null,
        Year5Weighted: null,
        Year5PeriodWeighted: null
      },
      NetCostPlus: {
        name: 'Net Cost Plus',
        Year3Simple: null,
        Year3Weighted: null,
        Year3PeriodWeighted: null,
        Year5Simple: null,
        Year5Weighted: null,
        Year5PeriodWeighted: null
      }
    } as any;

    (pliAverages ?? []).forEach((pliAverage) => {
      tableRows[PliValuesTypes[pliAverage.pliTypeId]][PliAveragesTypes[pliAverage.pliAverageTypeId]] =
        pliAverage.roundedValue;
    });
    return Object.values(tableRows);
  }, [pliAverages]);

  if (!loadingStates.fetchPBAPli || loadingStates.fetchPBAPli === FetchLoadingStateEnum.loading) {
    return <CenteredProgress />;
  }

  return (
    <Component
      testedPartyName={testedPartyName}
      handleAlignment={handleAlignment}
      alignment={alignment}
      displayablePliAverages={displayablePliAverages}
      displayablePliValues={displayablePliValues}
      buttonItems={buttonItems}
    />
  );
};

export default Connector;
