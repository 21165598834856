import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles, InputAdornment, Theme, TextField } from '@material-ui/core';
import { EntityOption, OrganisationStructureProps } from './EntityAddEditModal.proptype';
import { SearchBox } from '../../components';
import { body2, titleStandard } from '../../styles/typography';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    ...titleStandard,
    lineHeight: '1.875rem'
  },
  parentLabelGrid: {
    display: 'flex',
    alignItems: 'center',
    height: '2.25rem',
    paddingLeft: theme.spacing(1),
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.light
  },
  parentLabelTypography: {
    ...body2,
    color: theme.palette.primary.dark
  },
  ownershipPercentLabel: {
    textAlign: 'right'
  },
  ownershipInputs: {
    marginTop: theme.spacing(3)
  },
  subtitle: {
    color: theme.palette.primary.dark
  },
  columnHeader: {
    ...body2,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  row: {
    marginBottom: theme.spacing(2)
  },
  inputField: {
    width: '100%',
    marginTop: '0px !important',
    '& .MuiOutlinedInput-input': {
      border: 'none'
    }
  },
  textField: {
    width: '100%',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  }
}));

const ownershipRangeValidation = (input: string) => {
  const numInput = Number(input);
  return input ? numInput > 0 && numInput <= 100 : true;
};

const OrganisationStructure = ({ formMethods, entities, defaultValues }: OrganisationStructureProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, getValues, errors } = formMethods;
  const parentRows = ['entities:label-1st-parent', 'entities:label-2nd-parent', 'entities:label-3rd-parent'];
  const hundredPercentValidation = () => {
    const valid =
      Number(getValues('ownershipPercent1')) +
        Number(getValues('ownershipPercent2')) +
        Number(getValues('ownershipPercent3')) ===
        100 || !t('entities:invalid-entry-ownership-percentage');
    return valid;
  };

  const uniqueParentsValidation = () => {
    const pe1 = getValues('parentEntity1')?.value ?? null;
    const pe2 = getValues('parentEntity2')?.value ?? null;
    const pe3 = getValues('parentEntity3')?.value ?? null;
    return (pe1 !== pe2 || pe2 === null) && (pe1 !== pe3 || pe3 === null) && (pe2 !== pe3 || pe2 === null);
  };

  const entityHasOwnershipValidation = (fieldNum: number) => () => {
    const parentEntity: EntityOption | undefined = getValues(`parentEntity${fieldNum}`);
    const ownPercent: string = getValues(`ownershipPercent${fieldNum}`);
    return parentEntity === null || (parentEntity !== null && ownPercent !== '');
  };

  return (
    <>
      <Grid item xs={11}>
        <Typography className={classes.title}>{t('entities:title-organisation-structure')}</Typography>
      </Grid>
      <Grid item xs={11}>
        <Typography className={classes.subtitle}>{t('entities:message-organisation-structure')}</Typography>
      </Grid>
      <Grid container item xs={11} justify="space-around" className={classes.ownershipInputs}>
        <Grid item xs={3} />
        <Grid item xs={5}>
          <Typography className={classes.columnHeader}>{t('entities:label-parent')}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.ownershipPercentLabel}>
          <Typography className={classes.columnHeader}>{t('entities:label-ownership-percentage')}</Typography>
        </Grid>
        {parentRows.map((rowName, index) => (
          <Grid key={rowName} container justify="space-between" className={classes.row}>
            <Grid item xs={3} className={classes.parentLabelGrid}>
              <Typography className={classes.parentLabelTypography}>{t(rowName)}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Controller
                name={`parentEntity${index + 1}`}
                render={({ onChange, value }) => (
                  <SearchBox
                    placeholder={t('entities:placeholder-select-code')}
                    error={Boolean((errors as any)[`parentEntity${index + 1}`])}
                    value={value}
                    options={entities}
                    getOptionSelected={(option, value) => option.value === value.value}
                    getOptionLabel={(parent) => parent.value}
                    onChange={(_, item) => {
                      onChange(item);
                    }}
                  />
                )}
                defaultValue={defaultValues?.[`parentEntity${index + 1}`]?.code ?? null}
                control={control}
                rules={{ required: index === 0, ...{ uniqueParentsValidation } }}
                error={Boolean((errors as any)[`parentEntity${index + 1}`]?.code)}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`ownershipPercent${index + 1}`}
                render={({ onChange, value }) => (
                  <TextField
                    id={`ownershipPercent${index + 1}`}
                    name={`ownershipPercent${index + 1}`}
                    className={classes.inputField}
                    type="number"
                    margin="dense"
                    variant="outlined"
                    value={value}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    onChange={onChange}
                  />
                )}
                control={control}
                defaultValue={defaultValues[`ownershipPercent${index + 1}`]}
                rules={{
                  required: index === 0,
                  ...{
                    hasOwnership: entityHasOwnershipValidation(index + 1),
                    ownershipRange: ownershipRangeValidation,
                    totalsHundred: hundredPercentValidation
                  }
                }}
              />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography color="error" variant="caption">
            {(errors.parentEntity1 || errors.ownershipPercent1?.type === 'required') &&
              t('entities:invalid-entry-required-parent')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error" variant="caption">
            {(errors.parentEntity2 || errors.parentEntity3) && t('entities:invalid-entry-unique-parent')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error" variant="caption">
            {(errors.ownershipPercent1?.type === 'ownershipRange' ||
              errors.ownershipPercent2?.type === 'ownershipRange' ||
              errors.ownershipPercent3?.type === 'ownershipRange') &&
              t('entities:invalid-entry-ownership')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error" variant="caption">
            {(errors.ownershipPercent1?.type === 'hasOwnership' ||
              errors.ownershipPercent2?.type === 'hasOwnership' ||
              errors.ownershipPercent3?.type === 'hasOwnership') &&
              t('entities:invalid-entry-required-ownership')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="error" variant="caption">
            {errors.ownershipPercent1?.type === 'totalsHundred' && t('entities:invalid-entry-ownership-percentage')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganisationStructure;
