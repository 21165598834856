import { Entity, Transaction } from '../models';

export const findTransactionSourceRaw = (transaction: Transaction | undefined | null) =>
  transaction?.legalEntityTransactions.find(({ isSource }) => isSource);

export const findTransactionDestinationRaw = (transaction: Transaction | undefined | null) =>
  transaction?.legalEntityTransactions.find(({ isSource }) => !isSource);

export const findTransactionSource = (
  transaction: Transaction | undefined | null,
  entityById: Map<number, Entity> | undefined
) => {
  const source = findTransactionSourceRaw(transaction)?.entity;
  return source && entityById?.get(source?.entityId);
};

export const findTransactionDestination = (
  transaction: Transaction | undefined | null,
  entityById: Map<number, Entity> | undefined
) => {
  const destination = findTransactionDestinationRaw(transaction)?.entity;
  return destination && entityById?.get(destination?.entityId);
};
