import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box, Button, Paper, makeStyles, Tab, Tabs } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Study, ProfitBasedAnalysis, TransactionBasedAnalysis } from '../../../models';
import { hasEditAccess } from '../../../utils';
import { ContentHeader } from '../../ContentHeader';
import { TBATabIndex } from '../StudyDashboard.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    minHeight: '100%',
    '& > :nth-child(2)': {
      '& .Mui-selected': {
        color: theme.palette.primary.dark
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.dark
      }
    }
  },
  paperContainer: {
    alignItems: 'stretch',
    display: 'flex',
    flex: '1 1 0',
    overflow: 'auto',
    padding: theme.spacing(2),
    width: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'center',
    overflow: 'inherit',
    width: '100%'
  },
  contentHeader: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative',
    width: '100%'
  },
  contentHeaderBorder: {
    backgroundColor: theme.palette.background.default
  },
  headerCreateButton: {
    width: '6.5rem',
    height: '2.3rem',
    marginTop: theme.spacing(1)
  }
}));

export interface StudyDashboardWithTabsProps {
  title: string;
  pbas: ProfitBasedAnalysis[] | null;
  tbas: TransactionBasedAnalysis[] | null;
  study: Study | null;
  selectedKey: string;
  tabs: Array<{
    key: string;
    label: string;
    disabled: boolean;
    content: ReactNode;
    createButtonText: string;
  }>;
  onAddSingle: () => void;
  onCreateTba: () => void;
  onSelectTab: (tab: number) => void;
}

export const StudyDashboardWithTabs = ({
  title,
  pbas,
  tbas,
  study,
  selectedKey,
  tabs,
  onAddSingle,
  onCreateTba,
  onSelectTab
}: StudyDashboardWithTabsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match: any = useLocation();
  const [tab, setTab] = useState(0);
  const selected = tabs.find(({ key }) => key === selectedKey);
  const isStudy = 'studyId' in (study ?? {});
  const name = isStudy ? study?.name : '';

  useEffect(() => {
    if (match?.hash) {
      onSelectTab(TBATabIndex);
      setTab(TBATabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={(classes.root, classes.contentHeader)}>
        <ContentHeader title={title} primaryEntity={name!} link="/analysis" icon={<ArrowBackIcon />} />
        {pbas?.length && tab === 0 && hasEditAccess() ? (
          <Button
            id="button-newui-create-pba-create"
            className={classes.headerCreateButton}
            variant="contained"
            color="primary"
            onClick={onAddSingle}
          >
            {tabs[tab].createButtonText}
          </Button>
        ) : tbas?.length && tab === 1 && hasEditAccess() ? (
          <Button
            id="button-newui-create-tba-create"
            className={classes.headerCreateButton}
            variant="contained"
            color="primary"
            onClick={onCreateTba}
          >
            {tabs[tab].createButtonText}
          </Button>
        ) : null}
      </Box>
      <Tabs
        className={classes.contentHeaderBorder}
        value={tab}
        onChange={(event, value) => {
          onSelectTab(value);
          setTab(value);
        }}
      >
        {tabs.map((tab) => (
          <Tab key={tab.key} label={t(`analysis:${tab.label}`)} />
        ))}
      </Tabs>
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>{selected?.content}</Paper>
      </Box>
    </Box>
  );
};
