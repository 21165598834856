import { BackgroundInfoType, CopyBackgroundInfoSource, Country } from '../models';
import httpService from '../services/http';

export const fetchStatementOfFactsCopySources = async ({
  type,
  id,
  jurisdiction,
  countries
}: {
  type: BackgroundInfoType;
  id: number;
  jurisdiction: string;
  countries: Country[];
}) => {
  const jurisdictionId = countries.find((country) => country.isoCode === jurisdiction)?.countryId;
  return (
    await httpService.request<{ data: CopyBackgroundInfoSource[] }>({
      method: 'get',
      apiUrlKey: 'baseUrl',
      relativePath: `background-info/copy/${id}/type/${type}`,
      params: jurisdictionId ? { jurisdiction: `${jurisdictionId}` } : {}
    })
  ).data.data;
};
