import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { AddEntitiesButton, EntitiesSplashScreen, EntitiesTable, OrgChart } from '.';
import { CenteredProgress, ContentLayout } from '../../../components';
import editAccess from '../../../components/HigherOrderComponents/EditAccess';
import { OrgChartIcon, TableIcon } from '../../../svgs';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
import { EntitiesProps } from '../Entities.proptype';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    width: '100%',
    whiteSpace: 'nowrap'
  }
}));

export const Entities = (props: EntitiesProps) => {
  const { entities, onOpenCurrencyConversion } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isList, setIsList] = useState(true);
  const location = useLocation();
  const { containerId } = props;

  useEffect(() => {
    if (location.pathname.includes('orgchart')) {
      setIsList(false);
    } else {
      setIsList(true);
    }
  }, [location.pathname]);

  const handleOrgChartClick = () => {
    /* eslint-disable camelcase */
    void logGoogleAnalyticsEvent({
      event_category: 'entity_clicks',
      event_label: `Entity Organization chart click`,
      container_id: containerId
    });
  };

  return (
    <Box height={1}>
      {entities === null ? (
        <CenteredProgress />
      ) : entities?.length ? (
        <ContentLayout
          title={t('entities:title')}
          actions={
            <Box className={classes.buttonContainer}>
              {editAccess(<AddEntitiesButton {...props} />)}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onOpenCurrencyConversion();
                }}
              >
                &yen;$&pound;
              </Button>
              <ToggleButtonGroup exclusive size="small" value={isList ? 'list' : 'chart'}>
                <ToggleButton value="list">
                  <Link to="/entities">
                    <TableIcon />
                  </Link>
                </ToggleButton>
                <ToggleButton value="chart" onClick={handleOrgChartClick}>
                  <Link to="/entities/orgchart" data-testid="orgChartButton">
                    <OrgChartIcon />
                  </Link>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          }
          main={isList ? <EntitiesTable {...props} /> : <OrgChart {...props} />}
        />
      ) : (
        <EntitiesSplashScreen {...props} />
      )}
    </Box>
  );
};
