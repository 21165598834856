import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, FormControl, TextField, makeStyles } from '@material-ui/core';
import { title2, title1 } from '../../../styles/typography';
import { TPModal } from '../../TPModal';
import { SaveAnalysisTemplateModalProps } from '../JurisdictionAnalysisInfo.proptype';

const useStyles = makeStyles((theme) => ({
  AnalysisTemplateModalForm: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1.0rem',
    '& p': {
      width: '50%'
    }
  },
  AnalysisTemplateModalDoneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.dark
    }
  }
}));

export const SaveAnalysisTemplateModal = ({ title, isOpen, onClose, onDone }: SaveAnalysisTemplateModalProps) => {
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <TPModal
      maxWidth="sm"
      isOpen={isOpen}
      title={<Typography style={title2}>{title}</Typography>}
      actions={[
        {
          name: t('action-cancel'),
          handler: () => {
            setText('');
            setError('');
            onClose();
          }
        },
        {
          name: t('action-done'),
          className: classes.AnalysisTemplateModalDoneButton,
          handler: () => {
            if (!text) {
              setError(t('analysis:label-analysis-template-name-required-error'));
              return;
            }

            onDone(text);
            setText('');
            setError('');
          }
        }
      ]}
      onClose={onClose}
    >
      <FormControl size="medium" variant="outlined" className={classes.AnalysisTemplateModalForm}>
        <Typography style={title1}>{t('analysis:label-analysis-template-name')}</Typography>
        <TextField
          fullWidth
          size="medium"
          placeholder=""
          variant="outlined"
          error={Boolean(error)}
          defaultValue=""
          value={text}
          onChange={(e) => {
            setText(e?.target?.value || '');
          }}
        />
        {error ? (
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        ) : null}
      </FormControl>
    </TPModal>
  );
};
