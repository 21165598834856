import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import tokens from '../../styles/designTokens';
import { SearchIcon } from '../../svgs/index';

export interface SearchBarProps {
  placeholder: string;
  onSearchChange: (filterBy: string) => void;
  className?: string;
}

const useStyles = makeStyles(() => ({
  searchBar: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      color: tokens.core2
    },
    '& .MuiOutlinedInput-adornedEnd': {
      cursor: 'default'
    }
  }
}));

export const SearchBar = ({ className, placeholder, onSearchChange }: SearchBarProps) => {
  const classes = useStyles();

  return (
    <TextField
      key={`${placeholder}-input-field`}
      className={`MuiDataGrid-colCellTitle ${className ?? classes.searchBar}`}
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        onChange: ({ target: { value } }) => {
          onSearchChange(value);
        },
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};
