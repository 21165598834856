import httpService from '../services/http';

export const fetchLatestFinalReport = async (filePath: string, name: string, containerId: number) => {
  const {
    data: { data }
  } = await httpService.request<{ data: { url: string } }>({
    method: 'post',
    apiUrlKey: 'docApiUrl',
    relativePath: '/v1/download',
    data: { containerId, filePath, name }
  });
  window.open(data.url, '_self');
};
