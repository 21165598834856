import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { TbaAssociateTransactionModalProps } from './TbaAssociateTransactionModal.proptype';
import tokens from '../../styles/designTokens';
import { body2, title2 } from '../../styles/typography';
import {
  filterTransactionsByTestedPartyAndPrimaryEntity,
  filterTransactionsByTransactionType,
  getTradingPairFromTransaction
} from '../../utils/analysis';
import { TransactionsTab } from '../LocalReportCreationTabs/TransactionsTab/component';
import { FinancialInfoForm } from '../NewAnalysisModal';
import { SelectedTransaction } from '../NewAnalysisModal/CreatePBAModal.proptypes';
import { FinancialInfoValues, MethodTypes, TransactionTypes } from '../NewAnalysisModal/CreateTBAModal.proptypes';
import { TradingPair } from '../PBADashboardTPDSummary/PBADashboardTPDSummary.proptype';
import { TPModal } from '../TPModal';

const useStyles = makeStyles((theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  modalTitle: {
    ...title2
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  },
  inputGridElement: {
    marginBottom: theme.spacing(2)
  },
  errorBorder: {
    borderRadius: '0.25rem',
    border: '1px solid red'
  },
  gridContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.25rem',
    boxShadow: '0 0 3px 0 rgba(0,0,0,0.12)',
    padding: '1.375rem 2.25rem',
    margin: '1.5rem 0',
    justifyContent: 'flex-start'
  },
  editorLabel: {
    ...body2,
    color: tokens.core1,
    margin: theme.spacing(1, 0, 0.5, 0)
  },
  label: {
    ...body2,
    color: tokens.core2,
    marginBottom: '0.375rem'
  },
  textField: {
    width: '100%',
    '&.MuiTextField-root': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  greyBackground: {
    backgroundColor: tokens.neutral70
  },
  noMargins: {
    margin: 0
  },
  transactionsList: {
    width: '100%'
  }
}));

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const TbaAssociateTransactionModal = ({
  onClose,
  onSubmit,
  study,
  transactions,
  upeCurrency,
  selectedTba,
  tbaParameters
}: TbaAssociateTransactionModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedMethod, setSelectedMethod] = useState<MethodTypes | null>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [financialInfo, setFinancialInfo] = useState<FinancialInfoValues>({});
  const [financialFormHasErrors, setFinancialFormHasErrors] = useState<boolean>(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState<TransactionTypes>('');
  const [selectedTransactions, setSelectedTransactions] = useState<SelectedTransaction[]>([]);
  const [initialTransactionIds, setinitialTransactionIds] = useState<number[]>([]);
  const [tradingPair, setTradingPair] = useState<TradingPair>({});

  useEffect(() => {
    if (selectedTba) {
      if (selectedTba.transactionType) {
        setSelectedTransactionType(selectedTba.transactionType.name);
      }

      if (selectedTba.tbaEvaluationMethod) {
        setSelectedMethod(selectedTba.tbaEvaluationMethod.name);
      }

      const tradingPair: TradingPair = { primaryEntity: selectedTba.primaryLegalEntity };
      if (selectedTba.secondaryLegalEntity) {
        tradingPair.testedParty = selectedTba.secondaryLegalEntity;
      }

      setTradingPair(tradingPair);
    }
  }, [selectedTba]);

  useEffect(() => {
    if (selectedTba?.transactions) {
      setinitialTransactionIds(selectedTba.transactions.map((transaction) => transaction.transactionId));
      setSelectedTransactions(
        selectedTba.transactions.map((transaction, index) => {
          return {
            index,
            id: String(transaction.transactionId),
            transactionId: transaction.transactionId
          };
        })
      );
    }
  }, [transactions, selectedTransactionType, selectedTba]);

  const transactionsList = useMemo(() => {
    if (!transactions) {
      return [];
    }

    const transactionsFilteredByTransactionType = filterTransactionsByTransactionType({
      transactions,
      transactionType: selectedTba.transactionType.name
    });
    const selectedTransactionIds = new Set(selectedTransactions.map((tr) => tr.transactionId));

    if (tradingPair.primaryEntity && tradingPair.testedParty) {
      return filterTransactionsByTestedPartyAndPrimaryEntity({
        testedParty: tradingPair.testedParty,
        primaryEntity: tradingPair.primaryEntity,
        transactions: transactionsFilteredByTransactionType
      }).map((tr) => {
        const newTr = { selected: selectedTransactionIds.has(tr.transactionId), ...tr };
        return newTr;
      });
    }

    if (tradingPair.primaryEntity) {
      if (tradingPair.testedParty) {
        return filterTransactionsByTestedPartyAndPrimaryEntity({
          testedParty: tradingPair.testedParty,
          primaryEntity: tradingPair.primaryEntity,
          transactions: transactionsFilteredByTransactionType
        }).map((tr) => {
          const newTr = { selected: selectedTransactionIds.has(tr.transactionId), ...tr };
          return newTr;
        });
      }

      if (selectedTba.transactions?.length || selectedTransactions.length > 0) {
        let transactionTradingPair;
        if (selectedTba.transactions?.length) {
          transactionTradingPair = getTradingPairFromTransaction(
            selectedTba.transactions[0],
            tradingPair.primaryEntity.entityId
          );
        } else {
          const firstSelectedTransaction = transactions.find((tr) => selectedTransactionIds.has(tr.transactionId));
          transactionTradingPair = firstSelectedTransaction
            ? getTradingPairFromTransaction(firstSelectedTransaction, tradingPair.primaryEntity.entityId)
            : {};
        }

        if (transactionTradingPair.primaryEntity && transactionTradingPair.testedParty) {
          return filterTransactionsByTestedPartyAndPrimaryEntity({
            testedParty: transactionTradingPair.testedParty,
            primaryEntity: transactionTradingPair.primaryEntity,
            transactions: transactionsFilteredByTransactionType
          }).map((tr) => {
            const newTr = { selected: selectedTransactionIds.has(tr.transactionId), ...tr };
            return newTr;
          });
        }
      }

      return filterTransactionsByTestedPartyAndPrimaryEntity({
        testedParty: tradingPair.primaryEntity,
        primaryEntity: tradingPair.primaryEntity,
        transactions: transactionsFilteredByTransactionType
      }).map((tr) => {
        const newTr = { selected: selectedTransactionIds.has(tr.transactionId), ...tr };
        return newTr;
      });
    }

    return [];
  }, [selectedTba, transactions, tradingPair, selectedTransactions]);

  const onSelectTransactionChange = (selectedIds: number[]) => {
    if (!transactions || !study) {
      return;
    }

    const newlySelectedTransactions = transactions
      .filter((transA) => selectedIds.includes(transA.transactionId))
      .map((transA, index) => {
        return { index, id: String(transA.transactionId), transactionId: transA.transactionId };
      });

    setSelectedTransactions(newlySelectedTransactions ?? []);
  };

  return (
    <TPModal
      isOpen
      maxWidth="lg"
      title={
        <span className={classes.modalTitleWrapper}>
          <AddIcon className={classes.headerIcon} />
          <Typography variant="h2">{t('analysis:title-add-associated-transactions')}</Typography>
        </span>
      }
      containerStyle={classes.greyBackground}
      actions={[
        {
          name: t('action-cancel'),
          handler: () => {
            onClose();
          }
        },
        {
          name: t('action-done'),
          className: classes.doneButton,
          handler: () => {
            setShowErrors(true);
            try {
              if (selectedTransactions.length > 0 && !financialFormHasErrors) {
                const selectedTransactionIds: number[] = selectedTransactions.map(
                  (selectedTransaction: SelectedTransaction) => selectedTransaction.transactionId
                );
                const removedTransactionIds: number[] = initialTransactionIds.filter(
                  (initialTransactionId) => !selectedTransactionIds.includes(initialTransactionId)
                );
                const addTransactionIds: number[] = selectedTransactionIds.filter(
                  (selectedTransactionIds) => !initialTransactionIds.includes(selectedTransactionIds)
                );
                onSubmit({ addTransactionIds, financialInfo, removedTransactionIds });
                onClose();
              }
            } catch (error: unknown) {
              console.log(error);
            }
          }
        }
      ]}
      onClose={onClose}
    >
      <Grid container justify="center" className={classes.noMargins}>
        <Grid container item xs={12} justify="center">
          {selectedTransactionType && transactionsList.length === 0 && (
            <Grid container item xs={12} spacing={1} justify="space-around" className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography className={classes.editorLabel}>{`${t(
                  'analysis:invalid-entry-no-matching-transactions-1'
                )} ${study?.primaryEntity.name ?? ''} ${t(
                  'analysis:invalid-entry-no-matching-transactions-2'
                )}`}</Typography>
              </Grid>
            </Grid>
          )}
          {upeCurrency && transactionsList.length > 0 && (
            <div data-testid="transactions-list" className={classes.transactionsList}>
              <Grid
                item
                xs={12}
                className={
                  selectedTransactions.length === 0 && showErrors
                    ? `${classes.inputGridElement} ${classes.errorBorder}`
                    : `${classes.inputGridElement}`
                }
              >
                <TransactionsTab
                  transactions={transactionsList}
                  upeCurrency={upeCurrency}
                  selectedTransactions={initialTransactionIds}
                  disableSelectAll={selectedTransactions.length === 0}
                  onSelectionChange={onSelectTransactionChange}
                />
              </Grid>
              <ErrorMessage
                error={selectedTransactions.length === 0 && showErrors}
                message={t('analysis:invalid-entry-required-transactions')}
              />
            </div>
          )}
          <FinancialInfoForm
            selectedMethod={selectedMethod}
            selectedTransactionType={selectedTransactionType}
            selectedTransactions={selectedTransactions}
            transactions={transactions}
            setFinancialFormHasErrors={setFinancialFormHasErrors}
            setFinancialInfo={setFinancialInfo}
            showErrors={showErrors}
            tbaParameters={tbaParameters}
          />
        </Grid>
      </Grid>
    </TPModal>
  );
};
