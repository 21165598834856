import { TbaComparableTransactionsTable } from './TbaComparableTransactionsTable';
import { TbaFinalRangeComponentProps } from './TbaFinalRange.proptype';
import { TbaFinalRangeTabContent } from './TbaFinalRangeTabContent';
import { SplitView } from '../../components/SplitView';

export const TbaFinalRange = ({
  uncontrolledTransactions,
  currentUncontrolledTransactionComparabilities,
  statisticalMethods,
  tbaRangeResults,
  tbaTaxJurisdictionCodes,
  selectedTba,
  isLoading,
  isUnitPrice,
  handleCreateRangeTypes,
  handleUpdateRangeTypes,
  handleDeleteRangeTypes
}: TbaFinalRangeComponentProps) => {
  return (
    <SplitView
      left={
        <TbaComparableTransactionsTable
          uncontrolledTransactions={uncontrolledTransactions}
          currentUncontrolledTransactionComparabilities={currentUncontrolledTransactionComparabilities}
          selectedTba={selectedTba}
          isLoading={isLoading}
          isUnitPrice={isUnitPrice}
        />
      }
      right={
        <TbaFinalRangeTabContent
          statisticalMethods={statisticalMethods}
          selectedTba={selectedTba}
          tbaRangeResults={tbaRangeResults}
          tbaTaxJurisdictionCodes={tbaTaxJurisdictionCodes}
          isLoading={isLoading}
          isUnitPrice={isUnitPrice}
          handleCreateRangeTypes={handleCreateRangeTypes!}
          handleUpdateRangeTypes={handleUpdateRangeTypes!}
          handleDeleteRangeTypes={handleDeleteRangeTypes!}
        />
      }
    />
  );
};
