import { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Entity } from '../../models/entities.interface';
import { selectTransactionTradingPairsByEntityCode, selectEntity, selectUPECurrency } from '../../selectors';
import { formatPercentage } from '../../utils';

const DashboardCardEntityTransactionsTradingPairsConnector = ({ component: Component }: any) => {
  const [selectedTradingPartner, setSelectedTradingPartner] = useState<Entity | undefined>();
  const { entityId }: { entityId: string } = useParams();
  const numberEntityId = Number(entityId);
  const tradingPartners = useSelector(selectTransactionTradingPairsByEntityCode(numberEntityId));
  const currentEntity = useSelector(selectEntity(numberEntityId));
  const upeCurrency = useSelector(selectUPECurrency);
  const firstTradingPartner = Object.values(tradingPartners)[0]?.entity;
  const pairsForList = Object.values(tradingPartners).map((pair) => {
    const total = Object.values(pair.metrics).reduce((a, b) => a + b);
    return {
      tradingPair: `${String(currentEntity?.code)} - ${String(pair.entity.code)}`,
      types: [
        ...Object.entries(pair.metrics).map(([key, value]) => ({
          type: key,
          value,
          percent: formatPercentage(value ? Math.floor((value / total) * 100) : 0)
        }))
      ],
      entityCode: pair.entity.code
    };
  });

  const onGraphClick = (entity: Entity) => {
    if (entity.code !== currentEntity?.code) {
      setSelectedTradingPartner(tradingPartners[entity.code].entity);
    }
  };

  const onSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedTradingPartner(tradingPartners[event.target.value].entity);
  };

  return (
    <Component
      pairsForList={pairsForList}
      upeCurrency={upeCurrency}
      currentEntity={currentEntity}
      nodes={tradingPartners}
      currentTradingPartner={selectedTradingPartner ?? firstTradingPartner}
      onGraphClick={onGraphClick}
      onSelectChange={onSelectChange}
    />
  );
};

export default DashboardCardEntityTransactionsTradingPairsConnector;
