import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FinalLocalReport,
  InternalLocalFileReport,
  InternalLocalFileReportInstance,
  NormalizedStateSlice
} from '../../models';
import httpService from '../../services/http';
import { getWorkingContainer } from '../baseData';

interface ReportsState {
  // ids are entityId
  internal: NormalizedStateSlice<InternalLocalFileReport[]>;
  // ids are entityId
  final: NormalizedStateSlice<FinalLocalReport[]>;
  error?: string;
}

const initialState: ReportsState = {
  internal: { byId: null, allIds: [] },
  final: { byId: null, allIds: [] }
};

export const fetchInternalReports = createAsyncThunk<InternalLocalFileReport[], number, { rejectValue: Error }>(
  'reports/fetchInternal',
  async (entityId, { rejectWithValue }) => {
    try {
      return (
        await httpService.request<InternalLocalFileReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `entities/${entityId}/internal-local-file-reports`
        })
      ).data;
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

export const fetchFinalReports = createAsyncThunk<FinalLocalReport[], number, { rejectValue: Error }>(
  'reports/fetchFinal',
  async (entityId, { rejectWithValue }) => {
    try {
      const reports = (
        await httpService.request<FinalLocalReport[]>({
          method: 'get',
          apiUrlKey: 'baseUrl',
          relativePath: `entities/${entityId}/local-file-reports`
        })
      ).data;

      return (
        await Promise.all(
          reports.map(async (report) => {
            const instanceId = report.internalLocalfileReportInstance?.internalLocalfileReportInstanceId;
            return instanceId
              ? httpService.request<{
                  result: { internalLocalfileReportInstance: InternalLocalFileReportInstance };
                }>({
                  method: 'get',
                  apiUrlKey: 'baseUrl',
                  relativePath: `internal-local-file-reports/1/instance/${instanceId}`
                })
              : { data: { result: {} } };
          })
        )
      ).map((details, i) => ({ ...reports[i], ...details.data.result }));
    } catch (error: unknown) {
      return rejectWithValue(error as Error);
    }
  }
);

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getWorkingContainer.fulfilled, () => initialState)
      .addCase(fetchInternalReports.fulfilled, (state, { payload, meta: { arg: entityId } }) => {
        if (!state.internal.byId) {
          state.internal.byId = {};
        }

        state.internal.byId[entityId] = payload;

        if (!state.internal.allIds.includes(entityId)) {
          state.internal.allIds.push(entityId);
        }
      })
      .addCase(fetchFinalReports.fulfilled, (state, { payload, meta: { arg: entityId } }) => {
        if (!state.final.byId) {
          state.final.byId = {};
        }

        state.final.byId[entityId] = payload;

        if (!state.final.allIds.includes(entityId)) {
          state.final.allIds.push(entityId);
        }
      })
      .addMatcher(
        (action) => action.type.match(/^reports\/.+\/pending$/),
        (state: ReportsState) => {
          state.error = undefined;
        }
      )
      .addMatcher(
        (action) => action.type.match(/^reports\/.+\/rejected$/),
        (state, action: PayloadAction<Error | undefined>) => {
          state.error = action.payload?.message;
        }
      );
  }
});

export default reportsSlice.reducer;
