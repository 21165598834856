import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, Chip, Dialog, Typography, makeStyles } from '@material-ui/core';
import HistoryViewComponent from './components/HistoryView';
import HomeView from './components/HomeView';
import { ContainerModalViewKeys, CopyContainerModalProps } from './CopyContainerModal.proptypes';
import { CopyContainerModalContainer } from './CopyContainerModalContainer';
import { rollContainer } from '../../redux/rollingContainers';
import tokens from '../../styles/designTokens';
import { body4, title2, captionOverline } from '../../styles/typography';
import { useNotifications } from '../Notifications';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      height: '100%'
    }
  },
  navCard: {
    width: '30rem',
    height: '25rem',
    padding: '1.875rem 2.25rem',
    backgroundColor: tokens.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
    margin: 'auto',
    marginTop: '1rem'
  },
  contentRight: {
    width: '100%',
    height: '100%'
  },
  titleNavBtn: {
    color: tokens.core3,
    ...title2
  },
  copyContainerOptions: {
    '&.update': {
      backgroundColor: tokens.mustard,
      color: tokens.darkMustard,
      marginBottom: '0.5rem',
      ...captionOverline
    },
    '&.new': {
      backgroundColor: tokens.purpleLight2,
      color: tokens.white,
      marginBottom: '0.5rem',
      ...captionOverline
    }
  },
  confirmButton: {
    backgroundColor: tokens.themeAp100,
    color: tokens.white,
    width: '100%',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      borderRadius: '0.15rem',
      background: tokens.product90
    }
  },
  navCardText: {
    ...body4,
    color: tokens.core1,
    marginTop: '2.5rem',
    marginBottom: '5.5rem'
  }
}));

export const CopyContainerModal = ({
  onClose,
  canRollBack,
  canRollForward,
  cantRollBackReason,
  cantRollForwardReason,
  rollingContainerForecast,
  container,
  historicalCopyContainerInfo,
  modalInitialView
}: CopyContainerModalProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();
  const [selectedView, setSelectedView] = useState<ContainerModalViewKeys>(() => modalInitialView);
  const [selectedViewBackTo, setSelectedViewBackTo] = useState<ContainerModalViewKeys | null>(() =>
    modalInitialView === ContainerModalViewKeys.Home ? null : ContainerModalViewKeys.Home
  );
  const { showNotification } = useNotifications();
  const rollForwardYear = container ? container.taxYear + 1 : null;
  const rollBackYear = container ? container.taxYear - 1 : null;

  const copyContainerOptions = (direction: string) => {
    let statusClass = '';
    let label = '';
    let description = '';
    const startRollingContainer = () => {
      const newTaxYear = direction === 'next' ? rollForwardYear : rollBackYear;
      const containerId = container?.containerId;
      if (newTaxYear && containerId) {
        dispatch(
          rollContainer({
            containerId,
            newTaxYear
          })
        );
      }

      showNotification({ type: 'success', message: t('common:rolling-container-in-progress') });

      onClose();
    };

    if (rollForwardYear && direction === 'next') {
      if (rollingContainerForecast.rollForwardForecast.isIncrementalRoll) {
        statusClass = 'update';
        label = 'UPDATE';
        description = `New data from this container will be copied to ${rollingContainerForecast.rollForwardForecast.containerName}.`;
      } else {
        statusClass = 'new';
        label = 'CREATE NEW CONTAINER';
        description = `A new container will be created for ${rollForwardYear}.`;
      }
    } else if (rollBackYear && direction === 'previous') {
      if (rollingContainerForecast.rollBackForecast.isIncrementalRoll) {
        statusClass = 'update';
        label = 'UPDATE';
        description = `New data from this container will be copied to ${rollingContainerForecast.rollBackForecast.containerName}.`;
      } else {
        statusClass = 'new';
        label = 'CREATE NEW CONTAINER';
        description = `A new container will be created for ${rollBackYear}.`;
      }
    }

    return (
      <Box className={styles.contentRight}>
        <Chip className={styles.copyContainerOptions + ' ' + statusClass} size="small" label={label} />
        <Typography className={styles.titleNavBtn}>Copy to {direction} tax year</Typography>
        <Typography className={styles.navCardText}>{description}</Typography>
        <Button className={styles.confirmButton} onClick={startRollingContainer}>
          Confirm
        </Button>
      </Box>
    );
  };

  const views = [
    {
      key: ContainerModalViewKeys.Home,
      component: (
        <HomeView
          canRollForward={canRollForward}
          canRollBack={canRollBack}
          cantRollBackReason={cantRollBackReason}
          cantRollForwardReason={cantRollForwardReason}
          setBackTo={setSelectedViewBackTo}
          setView={setSelectedView}
        />
      )
    },
    {
      key: ContainerModalViewKeys.CopyForwardConfirmation,
      component: <Box className={styles.navCard}>{copyContainerOptions('next')}</Box>
    },
    {
      key: ContainerModalViewKeys.CopyBackConfirmation,
      component: <Box className={styles.navCard}>{copyContainerOptions('previous')}</Box>
    },
    // TODO: replace mock data after integration
    {
      key: ContainerModalViewKeys.Historical,
      component: <HistoryViewComponent historyData={historicalCopyContainerInfo.historicalCopyInfo} />
    }
  ];

  const view = views.find((v) => v.key === selectedView)!;

  return (
    <Dialog fullWidth open className={styles.root} maxWidth="md" onClose={onClose}>
      <CopyContainerModalContainer
        container={container}
        setBackTo={setSelectedViewBackTo}
        setView={setSelectedView}
        backTo={selectedViewBackTo}
        view={view}
        onClose={onClose}
      />
    </Dialog>
  );
};
