import { PliDisplayFormats } from '@xbs/xbs-enums';
import { PliAverages, PliValues } from '../app/ProfitLevelIndicator/ProfitLevelIndicator.proptype';

export const checkNumberValue = (str: string) => {
  if (!str) {
    return 'empty';
  }

  if (Number.isNaN((str as unknown) as number) || Number.isNaN(Number(str))) {
    return 'not a number';
  }

  if (Number.parseFloat(str) === 0) {
    return 'zero';
  }

  if (Number.parseFloat(str) !== Math.abs(Number.parseFloat(str))) {
    return 'negative number';
  }

  return 'positive number';
};

export const roundValue = (value: number, exp: number) => {
  if (typeof exp === 'undefined' || Number(exp) === 0) {
    return Math.round(value);
  }

  value = Number(value);
  exp = Number(exp);
  if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return Number.NaN;
  }

  const convertedIntegerValue = Math.round(Number(`${value}e${-exp}`));
  const backToFloatValue = Number(`${convertedIntegerValue}e${exp}`);

  return backToFloatValue;
};

export const roundPliValues = (pliValues: any) => {
  (pliValues ?? []).forEach((pliValue: PliValues | PliAverages) => {
    const isDecimal = PliDisplayFormats.ByName[pliValue.pliValueType.name].Value.displayFormat;
    const { roundingValue } = PliDisplayFormats.ByName[pliValue.pliValueType.name].Value;
    const roundedValue = isDecimal
      ? roundValue(Number(`${pliValue.value}`), -roundingValue)
      : roundValue(Number(`${pliValue.value}e2`), -roundingValue);
    const cuttedValue = isDecimal
      ? Number.isNaN(roundedValue)
        ? null
        : `${roundedValue.toFixed(roundingValue)}`
      : Number.isNaN(roundedValue)
      ? null
      : `${roundedValue.toFixed(roundingValue)}%`;
    pliValue.roundedValue = cuttedValue;
  });

  return pliValues;
};
