import { createSelector } from 'reselect';
import { NormalizedStateSlice } from '../models';
import { RootState } from '../redux';

const makeArray = <T>({ allIds, byId }: NormalizedStateSlice<T>) => allIds.map((id) => (byId ?? {})[id]);

export const selectContainers = createSelector(({ baseData: { containers } }: RootState) => containers, makeArray);

export const selectCountries = createSelector(({ baseData: { countries } }: RootState) => countries, makeArray);

export const selectCountry = (id?: number | null) =>
  createSelector(
    ({ baseData: { countries } }: RootState) => countries.byId ?? {},
    (byId) => (id ? byId[id] : undefined)
  );

export const selectCurrencies = createSelector(({ baseData: { currencies } }: RootState) => currencies, makeArray);

export const selectCurrency = (id?: number | null) =>
  createSelector(
    ({ baseData: { currencies } }: RootState) => currencies.byId ?? {},
    (byId) => (id ? byId[id] : undefined)
  );

export const selectPrimaryFunctions = createSelector(
  ({ baseData: { primaryFunctions } }: RootState) => primaryFunctions,
  makeArray
);

export const selectWorkingContainer = createSelector(
  ({ baseData: { containers } }: RootState) => containers.byId ?? {},
  ({ baseData: { workingContainerId } }: RootState) => workingContainerId,
  (byId, id) => (id ? byId[id] : undefined)
);

export const selectUPECurrency = createSelector(
  ({ baseData: { currencies } }: RootState) => currencies.byId ?? {},
  ({ baseData: { upeCurrencyId } }: RootState) => upeCurrencyId,
  (byId, id) => (id ? byId[id] : undefined)
);

export const selectUPE = createSelector(
  ({ entities: { entities } }: RootState) => entities ?? [],
  ({ baseData: { upeId } }: RootState) => upeId,
  (entities, id) => entities.find((entity) => entity.entityId === id)
);

export const selectContainerIsRollable = (state: RootState) => state.baseData.isContainerRollable;

export const selectLanguages = createSelector(({ baseData: { languages } }: RootState) => languages, makeArray);

export const selectIsBaseDataFetch = (state: RootState) => state.baseData.isBaseDataFetch;
