/* eslint-disable no-negated-condition */
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CenteredProgress, SearchAndSort, SortOrder } from '../../../components';
import { SortControl } from '../../../components/SortControl';
import { RangeColumns, RangeComparables } from '../../../models';
import { selectWorkingContainer } from '../../../selectors';
import { filterData, sortData } from '../../../services/filtering';
import tokens from '../../../styles/designTokens';
import { body2, body4 } from '../../../styles/typography';
import { getAppConfig } from '../../../utils';
import { Table } from '../../Table';
import { TableColumnDef, StickyColsDefinition } from '../../Table/Table.proptype';
import { Table as VirtualizedTable } from '../../VirtualizedTable';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  scrollTable: {
    width: '100%'
  },
  headingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 1rem'
  },
  pinnedBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0rem 0rem 0rem 0.313rem'
  },
  unpinnedBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0rem 0.5rem'
  },
  circle: {
    border: `0.06rem solid ${tokens.supportColorA100}`,
    height: '0.75rem',
    width: '0.75rem',
    backgroundColor: tokens.supportColorA20,
    borderRadius: '50%',
    justifyContent: 'center'
  },
  headingTitle: {
    ...body4,
    margin: 'auto 0'
  },
  tableWrapper: {
    overflow: 'auto',
    height: '100%'
  },
  emptyMessage: {
    ...body2,
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2em'
  }
}));

interface CompanyTableProps {
  comparables?: RangeComparables | undefined;
  companies: Array<Record<string, HTMLElement | number>>;
  showPinnedCompanies?: boolean;
  columns: RangeColumns[];
  chips: any;
  selectedCompanies?: any[];
  bulkPanel?: any;
  filteringBulkRejection?: string;
  onRowClick?: (event: MouseEvent, index: number, data: any) => void;
  setSortedVisibleData?: (data: any[]) => void;
  isExpanded?: boolean;
  isFineTuning?: boolean;
}

const mergeCompLists = (
  compListOne: Array<Record<string, HTMLElement | number | string>>,
  compListTwo: Array<Record<string, string | boolean>>
) =>
  compListOne.map((comparable: Record<string, any>) => ({
    ...compListTwo.find(
      (company: Record<string, string | boolean>) =>
        company.companyName === comparable.companyName.props.title && company
    ),
    ...comparable
  }));

const searchAndSortMap: Record<string, any> = {
  companyName: {
    field: 'Search Companies',
    filterValue: 'companyName',
    width: '18em',
    align: 'left',
    sortControl: SearchAndSort,
    sticky: { side: 'left', position: 1 }
  },
  average: {
    field: 'analysis:average',
    filterValue: 'average',
    width: '8em',
    align: 'right',
    sortControl: SortControl,
    sticky: { side: 'right', position: 0 }
  },
  rejectionReason: {
    field: 'Search Rejection',
    filterValue: 'rejectionReason',
    width: '18em',
    align: 'left',
    sortControl: SearchAndSort,
    sticky: { side: 'right', position: 1 }
  },
  rejectionStatus: {
    field: 'Rejection Status',
    filterValue: 'rejectionStatus',
    width: '4em',
    align: 'center',
    sortControl: () => null,
    sticky: { side: 'right', position: 2 }
  },
  rejectionType: {
    field: 'Rejection Reason',
    filterValue: 'rejectionType',
    width: '7.5rem',
    align: 'center',
    sortControl: () => null,
    header: 'Rejection Reason',
    sticky: { side: 'right', position: 1 }
  },
  country: {
    field: 'Country',
    filterValue: 'country',
    width: '12em',
    align: 'left',
    sortControl: SearchAndSort
  },
  sic: {
    field: 'SIC',
    filterValue: 'sic',
    width: '12em',
    align: 'left',
    sortControl: SearchAndSort
  },
  businessDescription: {
    field: 'Description',
    filterValue: 'businessDescription',
    width: '12em',
    align: 'left',
    sortControl: SearchAndSort
  }
};

export const CompanyTable = (props: CompanyTableProps) => {
  const {
    comparables,
    companies,
    columns,
    chips,
    selectedCompanies,
    bulkPanel,
    filteringBulkRejection,
    onRowClick,
    setSortedVisibleData,
    showPinnedCompanies = true,
    isExpanded,
    isFineTuning
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'name',
    sortOrder: 'asc'
  });
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const stickyCols: StickyColsDefinition = {};
  const { xbsEnvironmentShort } = getAppConfig();
  const flags = useFlags();
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;
  const comparablesValues = useMemo(() => (comparables?.data !== undefined ? comparables.data : []), [comparables]);

  const showPinnedComps =
    showPinnedCompanies &&
    flags.priorYearCompsYear2 &&
    (flags.priorYearCompsYear2 === true ||
      flags.priorYearCompsYear2.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
      flags.priorYearCompsYear2.includes(`${String(xbsEnvironmentShort)}-*`));

  const pinnedAndNonPinnedComps = useMemo(() => {
    return comparablesValues.map((comparable: Record<string, any>) => {
      return {
        companyName: comparable.companyName,
        pinned: comparable.isPinnedCompany
      };
    });
  }, [comparablesValues]);

  const mergedComparableData: Array<Record<string, HTMLElement | number | string | boolean>> = mergeCompLists(
    companies,
    pinnedAndNonPinnedComps
  );

  const displayColumns: TableColumnDef[] = columns.map((clmn) => {
    if (clmn.key === 'pinned') {
      if (!showPinnedComps) {
        return { key: clmn.key, header: '', width: '0' };
      }

      stickyCols[clmn.key] = { side: 'left', position: 0 };
      return {
        key: clmn.key,
        header: clmn.label || ''
      };
    }

    if (clmn.key === 'checkbox') {
      stickyCols[clmn.key] = { side: 'right', position: 3 };
      return {
        key: clmn.key,
        header: clmn.label,
        width: '3em',
        align: 'center',
        sortControl: () => null
      };
    }

    const columnConfig = searchAndSortMap[clmn.key];

    if (columnConfig) {
      if (columnConfig.sticky) {
        stickyCols[clmn.key] = columnConfig.sticky;
      }

      return {
        key: clmn.key,
        header: columnConfig.header ?? (
          <columnConfig.sortControl
            field={
              clmn.key === 'average'
                ? `${clmn.label} (%)`
                : clmn.key === 'rejectionReason'
                ? `${String(columnConfig.field)}`
                : clmn.key === 'companyName'
                ? columnConfig.field
                : clmn.label || columnConfig.field
            }
            onSortClicked={(sortOrder: SortOrder) => {
              setSort({ sortBy: clmn.key, sortOrder });
            }}
            onSearchChange={
              columnConfig.filterValue
                ? (value: string) => {
                    setFilter({ [columnConfig.filterValue]: value });
                  }
                : undefined
            }
          />
        ),
        width: columnConfig.width,
        align: columnConfig.align
      };
    }

    return {
      key: clmn.key,
      header: clmn.label,
      width: '5em'
    };
  });

  const getDefaultMessage = () => {
    if (filteringBulkRejection) {
      return `${t(`analysis:no-companies-rejected`)} for ${filteringBulkRejection}`;
    }

    return t('analysis:no-companies-rejected-bulk');
  };

  const formattedData = mergedComparableData.map((company) => {
    const data: any = {};
    for (const column of displayColumns) {
      if (column.key === 'pinned' && showPinnedCompanies) {
        data[column.key] = company.pinned ? (
          <Tooltip arrow title="Previously Accepted Comparable">
            <div className={classes.pinnedBox}>
              <div className={classes.circle} />
            </div>
          </Tooltip>
        ) : (
          <div className={classes.unpinnedBox} />
        );
      } else {
        data[column.key] = company[column.key];
      }
    }

    if (company.className) {
      data.className = company.className;
    }

    if (company.sourceId) {
      data.sourceId = company.sourceId;
    }

    return data;
  });

  const sortByAverages = (companies: any[]) => {
    const sortable: any[] = [];
    const blanks: any = [];
    companies.forEach((company) => {
      if (company.average) {
        sortable.push(company);
      } else {
        blanks.push(company);
      }
    });

    const sortableSorted: any = sortData(filterData(sortable, filterObject), sortObject);
    const blanksSortingObject: { sortBy: string; sortOrder: SortOrder } = { sortBy: 'companyName', sortOrder: 'asc' };
    const blanksSorted: any = sortData(filterData(blanks, filterObject), blanksSortingObject);

    return [...sortableSorted, ...blanksSorted];
  };

  const sortedData = useMemo(() => {
    if (sortObject.sortBy === 'average') {
      return sortByAverages(formattedData);
    }

    return sortData(filterData(formattedData, filterObject), sortObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedData, filterObject, sortObject]);

  const data = useMemo(() => {
    if (sortObject.sortBy === 'average') {
      return sortByAverages(companies);
    }

    return sortData(filterData(companies, filterObject), sortObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, filterObject, sortObject]);

  useEffect(() => {
    setSortedVisibleData?.(sortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject]);

  const columnSpecificStyles = {
    rejectionType: {
      maxWidth: '7.5rem',
      width: '7.5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    pinned: {
      padding: '0'
    },
    checkbox: {
      padding: '0 0.688rem'
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headingRow}>
        <span className={classes.headingTitle}>{t('analysis:comparable-companies')}</span>
        {(selectedCompanies?.length ?? 0) > 0 ? bulkPanel : chips}
      </div>
      {comparables ? (
        companies.length > 0 ? (
          <div className={classes.tableWrapper}>
            {flags.virtualizedTable && isFineTuning ? (
              <VirtualizedTable
                className={classes.scrollTable}
                data={showPinnedComps ? sortedData : data}
                columns={displayColumns}
                stickyCols={stickyCols}
                columnSpecificStyles={columnSpecificStyles}
                isExpanded={isExpanded ?? false}
                onRowClick={onRowClick}
              />
            ) : (
              <Table
                className={classes.scrollTable}
                data={showPinnedComps ? sortedData : data}
                columns={displayColumns}
                stickyCols={stickyCols}
                columnSpecificStyles={columnSpecificStyles}
                onRowClick={onRowClick}
              />
            )}
          </div>
        ) : (
          <Typography className={classes.emptyMessage}>{getDefaultMessage()}</Typography>
        )
      ) : (
        <CenteredProgress />
      )}
    </div>
  );
};
