import { Chip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface ProgressChipProps {
  progresses: boolean[];
  completed?: boolean;
  size: 'large' | 'small';
  margin?: boolean;
  labelText?: string;
}

const useStyles = makeStyles((theme) => ({
  progressChip: {
    backgroundColor: theme.palette.warning.light,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.primary.light}`,
    fontWeight: 'bold'
  },
  completed: {
    color: theme.palette.info.main,
    border: `1px solid ${theme.palette.primary.light}`,
    fontWeight: 'bold',
    backgroundColor: theme.palette.info.light
  },
  large: {
    fontSize: '1.3rem'
  },
  margin: {
    marginLeft: '1rem'
  }
}));

const checkedItems = (data: boolean[]): number => data.filter((item) => item).length;

const bubbleLabel = (data: boolean[]): string => `${checkedItems(data)} / ${data.length}`;

export const ProgressChip = ({ progresses, completed, size, margin = false, labelText = '' }: ProgressChipProps) => {
  const styles = useStyles();
  const classes = clsx({
    [styles.progressChip]: true,
    [styles.completed]: completed ? completed : checkedItems(progresses) === progresses.length,
    [styles.large]: size === 'large',
    [styles.margin]: margin
  });

  return progresses.length > 0 ? <Chip label={`${bubbleLabel(progresses)} ${labelText}`} className={classes} /> : null;
};
