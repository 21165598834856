import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Chip, LinearProgress, Paper, Typography, makeStyles } from '@material-ui/core';
import { Add, ArrowDropDown, RemoveCircleOutline } from '@material-ui/icons';
import { AddPinnedComparableModal } from './AddPinnedComparableModal/AddPinnedComparableModal';
import { SearchPinnedComparablesCompaniesParams } from './AddPinnedComparableModal/AddPinnedComparableModal.proptype';
import { CannotAddCompanyModal } from './CannotAddCompanyModal';
import { ConfirmationPinnedComparableModal } from './ConfirmationPinnedComparableModal/ConfirmationPinnedComparableModal';
import { CenteredProgress, DropDownButton } from '../../../../components';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import { FetchLoadingState, FetchLoadingStateEnum } from '../../../../constants';
import { AddManualComparableCompanyPayload, Country, ManualComparablesCompany } from '../../../../models';
import {
  addManualComparablesCompany,
  removeManualComparablesCompany,
  searchManualComparablesCompanies,
  resetAllAvailableManualComparableCompanies,
  JurisdictionRules
} from '../../../../redux/profitBasedAnalyses';
import {
  selectAllAvailableManualComparablesCompanies,
  selectCountries,
  selectCurrentPBA,
  selectManualComparablesCompanies,
  selectWorkingContainer
} from '../../../../selectors';
import { AppDispatch } from '../../../../store';
import tokens from '../../../../styles/designTokens';
import { body1, body2, body3, body4, title3, captionOverline } from '../../../../styles/typography';
import { ManualCompDefault } from '../../../../svgs';
import { decodeTokens, getAuthInfo } from '../../../../utils';
import { logGoogleAnalyticsTimeSpent } from '../../../../utils/sendGoogleAnalyticaEvent';
import { TimeTrackingEvents } from '../../../MethodEvaluation/hooks/useTrackingTime';
import { Table } from '../../../Table';

const useStyles = makeStyles((theme) => ({
  body1,
  body2,
  body3,
  body4,
  captionOverline,
  title3,
  clickable: {
    cursor: 'pointer'
  },
  greyBackground: {
    background: tokens.neutral70,
    padding: '1.4rem 0',
    height: 'auto',
    overflowX: 'scroll'
  },
  paperContainer: {
    background: tokens.white,
    margin: '2rem',
    padding: '2rem'
  },
  noCompaniesContainer: {
    marginTop: '1.5rem',
    padding: '1.5rem',
    background: tokens.neutral60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  noCompaniesIcon: {
    color: tokens.product100,
    lineHeight: 0
  },
  noCompaniesMessage: {
    maxWidth: '31rem'
  },
  noCompaniesTextContainer: {
    margin: '1.2rem'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rightHeaderContent: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  tableContainer: {
    background: tokens.white,
    marginTop: '1.5rem',
    height: '40vh',
    overflowX: 'scroll'
  },
  totalCountChip: {
    ...captionOverline,
    border: '0.063rem solid',
    height: '2.6rem',
    width: '6rem',
    marginLeft: '0.5rem',
    backgroundColor: tokens.product15,
    color: tokens.product100,
    justifyContent: 'flex-end',
    letterSpacing: '0.038rem',
    '& span': {
      textAlign: 'right'
    }
  },
  buttons: {
    width: '6rem',
    height: '2.25rem',
    padding: '0.313rem',
    color: tokens.core1,
    border: `1px solid ${tokens.core2}`,
    '&:hover': {
      background: theme.palette.action.selected
    }
  },
  dropDown: {
    ...body1,
    paddingLeft: '0.5rem',
    textTransform: 'uppercase'
  },
  loader: {
    width: '100%'
  },
  name: {
    ...body2,
    maxWidth: '20rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      ...body1,
      color: tokens.core2,
      backgroundColor: theme.palette.background.default,
      borderRadius: '4px',
      boxShadow: '0 0 4px 4px rgba(226,227,230,0.4)',
      padding: '1.1875rem 2.125rem',
      maxWidth: '40rem'
    }
  }
}));

export const PinnedComparables = ({
  pinnedCompsFetching,
  jurisdictionIsoCodes,
  updateManualComparableCompanyJurisdictions,
  companyJurisdictionsLoading,
  jurisdictionRules
}: {
  pinnedCompsFetching: boolean;
  jurisdictionIsoCodes: Array<{ jurisdictionId: number; isoCode: string }>;
  updateManualComparableCompanyJurisdictions: ({
    sourceId,
    sourceType,
    jurisdictionIds
  }: {
    sourceId: number;
    sourceType: number | undefined;
    jurisdictionIds: number[];
  }) => void;
  companyJurisdictionsLoading?: {
    [key: number]: FetchLoadingState;
  };
  jurisdictionRules?: JurisdictionRules;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { authToken } = getAuthInfo();
  const dispatch = useDispatch<AppDispatch>();
  const [addModalIsOpen, setAddModalIsOpen] = useState<boolean>(false);
  const [confirmationModalIsoCodes, setConfirmationModalIsoCodes] = useState<
    Array<{ jurisdictionId: number; isoCode: string }>
  >([]);
  const userData = decodeTokens(authToken);
  const pinnedComparablesCompanies: ManualComparablesCompany[] = useSelector(selectManualComparablesCompanies) ?? [];
  const countries = useSelector(selectCountries);
  const [isSearching, setIsSearching] = useState(false);
  const allAvailablePinnedComparablesCompanies: ManualComparablesCompany[] | null =
    useSelector(selectAllAvailableManualComparablesCompanies) ?? null;
  const currentPBA = useSelector(selectCurrentPBA);
  const workingContainer = useSelector(selectWorkingContainer);

  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false);
  const [cannotAddCompanyModalIsOpen, setCannotAddCompanyModalIsOpen] = useState<boolean>(false);
  const [confirmedJurisdictionsByModal, setConfirmedJurisdictionsByModal] = useState<number[] | null>(null);
  const [currentCompanySourceId, setCurrentCompanySourceId] = useState<number | null>(null);

  const removeCompany = (sourceId: number) => {
    if (currentPBA?.pbaId) {
      void dispatch(removeManualComparablesCompany({ sourceId, pbaId: currentPBA?.pbaId }));
    }
  };

  const getAllowedJurisdictions = (
    fullCompanyData: ManualComparablesCompany | undefined
  ): { jurisdictionsAllowedByMustUsePublicComps: number[]; jurisdictionsAllowedByMustUseLocalComps: number[] } => {
    if (fullCompanyData?.sourceType === undefined)
      return { jurisdictionsAllowedByMustUsePublicComps: [], jurisdictionsAllowedByMustUseLocalComps: [] };

    const { sourceType, domicileName } = fullCompanyData;
    const isPublicComp = sourceType === 0;
    const jurisdictionIds = new Set(jurisdictionIsoCodes.map((jCode) => jCode.jurisdictionId));
    const currentJurisdictions = Object.keys(jurisdictionRules ?? []).filter((jurisdictionId) =>
      [...jurisdictionIds].includes(Number(jurisdictionId))
    );
    const jurisdictionsAllowedByMustUsePublicComps: number[] = [];
    const jurisdictionsAllowedByMustUseLocalComps: number[] = [];
    currentJurisdictions.forEach((jurisdictionId) => {
      const mustUsePublicComps = Boolean(
        jurisdictionRules?.[Number(jurisdictionId)][workingContainer!.taxYear]?.mustUsePublicComps
      );
      const mustUseLocalComps = Boolean(
        jurisdictionRules?.[Number(jurisdictionId)][workingContainer!.taxYear]?.mustUseLocalComps
      );

      const jurisdictionIsoCode = jurisdictionIsoCodes.find(
        (isoCode) => isoCode.jurisdictionId === Number(jurisdictionId)
      );

      if (isPublicComp || !mustUsePublicComps) {
        jurisdictionsAllowedByMustUsePublicComps.push(Number(jurisdictionId));
      }

      if (domicileName === jurisdictionIsoCode?.isoCode || !mustUseLocalComps) {
        jurisdictionsAllowedByMustUseLocalComps.push(Number(jurisdictionId));
      }
    });

    return { jurisdictionsAllowedByMustUsePublicComps, jurisdictionsAllowedByMustUseLocalComps };
  };

  useEffect(() => {
    if (
      Array.isArray(confirmedJurisdictionsByModal) &&
      confirmedJurisdictionsByModal.length > 0 &&
      currentCompanySourceId
    ) {
      const sourceId = currentCompanySourceId;
      setCurrentCompanySourceId(null);
      addCompany(sourceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedJurisdictionsByModal, currentCompanySourceId]);

  const onConfirmationModalDone = (selectedJurisdictionId: string) => {
    setConfirmationModalIsOpen(false);
    if (selectedJurisdictionId === 'both') {
      setConfirmedJurisdictionsByModal(jurisdictionIsoCodes.map((jCode) => jCode.jurisdictionId));
    } else {
      setConfirmedJurisdictionsByModal([Number(selectedJurisdictionId)]);
    }
  };

  const addCompany = (sourceId: number) => {
    if (currentPBA?.pbaId) {
      const fullCompanyData: ManualComparablesCompany | undefined = allAvailablePinnedComparablesCompanies?.find(
        (company) => company.sourceId === sourceId
      );
      if (fullCompanyData) {
        const {
          sourceType,
          sourceId,
          id,
          name,
          domicileId,
          domicileName,
          sicCode,
          tagged,
          description
        } = fullCompanyData;

        const {
          jurisdictionsAllowedByMustUsePublicComps,
          jurisdictionsAllowedByMustUseLocalComps
        } = getAllowedJurisdictions(fullCompanyData);

        if (jurisdictionsAllowedByMustUsePublicComps.length === 0) {
          setCannotAddCompanyModalIsOpen(true);
          return;
        }

        const jurisdictionsAllowedByBoth = jurisdictionsAllowedByMustUsePublicComps.filter((jId) =>
          jurisdictionsAllowedByMustUseLocalComps.includes(jId)
        );

        const jurisdictionsIds = confirmedJurisdictionsByModal
          ? confirmedJurisdictionsByModal
          : jurisdictionsAllowedByBoth;

        if (jurisdictionsIds?.length === 0) {
          const jurisdictionIsoCodesThatUserCanManuallyApply = jurisdictionIsoCodes.filter((jIsoCode) =>
            jurisdictionsAllowedByMustUsePublicComps.includes(jIsoCode.jurisdictionId)
          );
          setCurrentCompanySourceId(sourceId);
          setConfirmationModalIsoCodes(jurisdictionIsoCodesThatUserCanManuallyApply);
          setConfirmationModalIsOpen(true);
        } else if (sourceType !== undefined && workingContainer?.containerId) {
          setConfirmedJurisdictionsByModal(null);
          const company: AddManualComparableCompanyPayload = {
            sourceId,
            sourceType,
            containerId: workingContainer?.containerId,
            pbaId: currentPBA?.pbaId,
            jurisdictionIds: jurisdictionsIds,
            id,
            name,
            domicileId,
            domicileName,
            sicCode,
            tagged,
            description
          };

          void dispatch(addManualComparablesCompany({ pbaId: currentPBA?.pbaId, company }));
        }
      }
    }
  };

  const searchManualComparableComps = async (searchParams: SearchPinnedComparablesCompaniesParams) => {
    setIsSearching(true);
    await dispatch(
      searchManualComparablesCompanies({
        companyName: searchParams.companyName,
        count: searchParams.count,
        domicile: searchParams.domicile,
        sicCode: searchParams.sicCode
      })
    );
    setIsSearching(false);
  };

  const clearPinnedComparableComps = () => {
    void dispatch(resetAllAvailableManualComparableCompanies());
  };

  const getJurisdictionDropdownOptions = (companyData: ManualComparablesCompany) => {
    const { jurisdictionsAllowedByMustUsePublicComps } = getAllowedJurisdictions(companyData);
    const jurisdictionIsoCodesThatUserCanManuallyApply = jurisdictionIsoCodes.filter((jIsoCode) =>
      jurisdictionsAllowedByMustUsePublicComps.includes(jIsoCode.jurisdictionId)
    );

    const jurisdictionDropdownOptions = jurisdictionIsoCodesThatUserCanManuallyApply.map(
      (isoCode: { jurisdictionId: number; isoCode: string }) => {
        if (jurisdictionIsoCodesThatUserCanManuallyApply.length > 1) {
          return {
            children: <Typography className={`${classes.dropDown}`}>{isoCode.isoCode}</Typography>,
            onClick: () => {
              updateManualComparableCompanyJurisdictions({
                sourceId: companyData.id,
                sourceType: companyData.sourceType,
                jurisdictionIds: [isoCode.jurisdictionId]
              });
            }
          };
        }

        return {
          children: <Typography className={`${classes.dropDown}`}>{isoCode.isoCode}</Typography>
        };
      }
    );

    if (jurisdictionIsoCodesThatUserCanManuallyApply.length > 1) {
      jurisdictionDropdownOptions.push({
        children: <Typography className={`${classes.dropDown}`}>{t('analysis:choose-both')}</Typography>,
        onClick: () => {
          const bothCodes = jurisdictionIsoCodesThatUserCanManuallyApply.map(
            (isoCode: { jurisdictionId: number; isoCode: string }) => isoCode.jurisdictionId
          );
          updateManualComparableCompanyJurisdictions({
            sourceId: companyData.id,
            sourceType: companyData.sourceType,
            jurisdictionIds: bothCodes
          });
        }
      });
    }

    return jurisdictionDropdownOptions;
  };

  const displayCompanyJurisdictions = (
    companyData: ManualComparablesCompany,
    jurisdictionIsoCodes: Array<{ jurisdictionId: number; isoCode: string }>
  ) => {
    if (
      companyJurisdictionsLoading &&
      companyJurisdictionsLoading[companyData.sourceId] === FetchLoadingStateEnum.loading
    ) {
      return (
        <Box className={classes.loader}>
          <LinearProgress />
        </Box>
      );
    }

    if (companyData.jurisdictionIds && companyData.jurisdictionIds.length < 2) {
      const isoCode = jurisdictionIsoCodes.find((jurIso) =>
        companyData.jurisdictionIds?.includes(jurIso.jurisdictionId)
      );
      if (isoCode) {
        return (
          <>
            <Typography className={`${classes.dropDown}`}>{isoCode.isoCode}</Typography>
            <ArrowDropDown />
          </>
        );
      }
    }

    return (
      <>
        <Typography className={`${classes.dropDown}`}>{t('analysis:choose-both')}</Typography>
        <ArrowDropDown />
      </>
    );
  };

  const tableData = pinnedComparablesCompanies.map((companyData: ManualComparablesCompany) => {
    return {
      ...companyData,
      name: (
        <CustomTooltip className={classes.tooltip} title={companyData.name}>
          <span className={classes.name}>{companyData.name || t('analysis:label-comparables-unavailable-name')}</span>
        </CustomTooltip>
      ),
      domicileName: companyData.domicileName || t('analysis:label-comparables-unavailable-domicile'),
      jurisdictions: (
        <DropDownButton
          variant="outlined"
          className={classes.buttons}
          items={getJurisdictionDropdownOptions(companyData)}
        >
          {displayCompanyJurisdictions(companyData, jurisdictionIsoCodes)}
        </DropDownButton>
      ),
      remove: (
        <RemoveCircleOutline
          className={classes.clickable}
          onClick={() => {
            removeCompany(companyData.sourceId);
          }}
        />
      )
    };
  });

  const pinnedComparablesTable = (
    <Table
      data={tableData}
      columns={[
        {
          key: 'name',
          header: t('analysis:label-comparables-company-name')
        },
        {
          key: 'domicileName',
          header: t('analysis:label-comparables-domicile')
        },
        {
          key: 'sicCode',
          header: t('analysis:tested_party_characterization.sic')
        },
        {
          key: 'jurisdictions',
          header: t('analysis:title-pinned-jurisdictions')
        },
        {
          key: 'remove',
          header: t('analysis:tba-uncontrolled-transaction-current-search-bulk-actions-remove')
        }
      ]}
      columnSpecificStyles={{
        name: {
          ...body2,
          maxWidth: '20rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        },
        remove: {
          color: tokens.core2,
          textAlign: 'right'
        }
      }}
      stickyHeader={false}
    />
  );

  const emptyTable = (
    <Box className={classes.noCompaniesContainer}>
      <Box className={classes.noCompaniesIcon}>
        <ManualCompDefault />
      </Box>
      <Box className={classes.noCompaniesTextContainer}>
        <Typography className={classes.title3}>{t('analysis:label-pinned-comparables-add')}</Typography>
        <Box className={classes.noCompaniesMessage}>
          <Typography className={classes.body3}>{t('analysis:label-pinned-comparables-add-text')}</Typography>
        </Box>
      </Box>
    </Box>
  );

  const addModalCompanies = () => {
    if (addModalIsOpen) {
      const includedCompanyIds = new Set(pinnedComparablesCompanies.map((company) => company.sourceId));

      if (!Array.isArray(allAvailablePinnedComparablesCompanies)) {
        return null;
      }

      return allAvailablePinnedComparablesCompanies?.map((company: ManualComparablesCompany) => {
        const companyAdded = includedCompanyIds.has(company.sourceId);
        return { ...company, companyAdded };
      });
    }

    return [];
  };

  const domicileOptions = countries.map((country: Country) => ({
    value: t('label-name-and-code', country),
    data: country
  }));

  return (
    <>
      {addModalIsOpen ? (
        <AddPinnedComparableModal
          isOpen={addModalIsOpen}
          isSearching={isSearching}
          companiesData={addModalCompanies()}
          addCompany={addCompany}
          removeCompany={removeCompany}
          domicileOptions={domicileOptions}
          clearPinnedComparableComps={clearPinnedComparableComps}
          onClose={() => {
            setAddModalIsOpen(false);
          }}
          onDone={() => {
            setAddModalIsOpen(false);
          }}
          onSearchCompanies={searchManualComparableComps}
        />
      ) : null}
      {confirmationModalIsOpen ? (
        <ConfirmationPinnedComparableModal
          isoCodes={confirmationModalIsoCodes}
          isOpen={addModalIsOpen}
          onClose={() => {
            setConfirmationModalIsOpen(false);
          }}
          onDone={onConfirmationModalDone}
        />
      ) : null}
      {cannotAddCompanyModalIsOpen ? (
        <CannotAddCompanyModal
          isoCodes={jurisdictionIsoCodes}
          isOpen={cannotAddCompanyModalIsOpen}
          onClose={() => {
            setCannotAddCompanyModalIsOpen(false);
          }}
          onDone={onConfirmationModalDone}
        />
      ) : null}
      <Paper className={classes.paperContainer}>
        <Box className={classes.header}>
          <Box>
            <Typography className={classes.body4}>{t('analysis:title-pinned-comparables')}</Typography>
            <Typography className={classes.body1}>{t('analysis:sub-title-pinned-comparables')}</Typography>
          </Box>
          <Box className={classes.rightHeaderContent}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setAddModalIsOpen(true);
                void dispatch(resetAllAvailableManualComparableCompanies());
                /* eslint-disable camelcase */
                void logGoogleAnalyticsTimeSpent({
                  title: TimeTrackingEvents.AddSearchCompaniesModal,
                  page: '/custom/pba/comp_search/add_country',
                  user_id: userData?.userId ?? '',
                  container_id: workingContainer?.containerId
                });
              }}
            >
              <Add />
              {t('analysis:label-comparables-add-companies')}
            </Button>
            <Chip
              key="total-chip"
              clickable
              data-testid="total-chip"
              className={classes.totalCountChip}
              size="medium"
              component="div"
              label={
                <>
                  <Box className={classes.captionOverline}>Total</Box>
                  <Box className={classes.body2}>{tableData.length}</Box>
                </>
              }
            />
          </Box>
        </Box>
        <Box className={classes.tableContainer}>
          {pinnedCompsFetching ? <CenteredProgress /> : pinnedComparablesTable}
          {tableData.length === 0 ? emptyTable : null}
        </Box>
      </Paper>
    </>
  );
};
