import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Typography, makeStyles, Box } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { CompSearchExecution, PrimaryFunctions } from '@xbs/xbs-enums';
import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ComparableCountChip } from './components/ComparableCountChip';
import { InitialSearchDistributor } from './components/InitialSearchDistributor';
import { ServiceProviderSearchSetup } from './components/IntialSearchServiceProvider';
import { PinnedComparables } from './components/PinnedComparables';
import { RecalculateComparablesProps } from './InitialSearch.proptype';
import { CenteredProgress } from '../../../components';
import { PbaSearchButton } from '../../../components/PbaSearchButton';
import { FetchLoadingStateEnum } from '../../../constants';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import {
  LegalEntityTransaction,
  ProjectCompPoolWithoutCompSearchPayload,
  PbaCompSearchOptionsByJurisdiction,
  PbaJurisdiction,
  CSInitialSearchJurisdictionData,
  PbaCompsSearchOptions,
  ApiCompPoolOverrides
} from '../../../models';
import {
  fetchCurrentTestedParty,
  runCSRun,
  runCompSearch,
  updatePbaJurisdictionCompSearchOptions,
  removeCurrentPbaJurisdictions,
  fetchJurisdictionRules,
  fetchManualComparablesCompanies,
  fetchParentContainerCompPoolRunOnDate,
  projectCompPoolCountWithoutCompSearch,
  fetchPBAPli,
  updateJurisdictionsForManualComparablesCompany,
  fetchGetJurisdictionRules
} from '../../../redux/profitBasedAnalyses';
import {
  selectCurrentTestedParty,
  selectPbaJurisdictions,
  selectWorkingContainer,
  selectCurrentMajorClassification,
  selectTransactionByPbaId,
  selectPbaCompSearchJurisdictionOptions,
  selectSicTypes,
  selectCurrentPBA,
  selectParentContainerCompPoolInfo,
  selectPBALoadingState,
  selectGetJurisdictionRules
} from '../../../selectors';
import { AppDispatch } from '../../../store';
import tokens from '../../../styles/designTokens';
import { title3 } from '../../../styles/typography';
import NavigationTabs from '../../NavigationTabs/component';
import { IndustrialCodeValue, sicType } from '../../TestedPartyDistributor';
import { MajorClassification } from '../../TestedPartyServiceProvider';
import { PbaLocation } from '../AnalysisSideMenu.proptype';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  headerContent: {
    ...title3,
    padding: '1.5rem'
  },
  footerContainer: {
    ...title3,
    padding: '1.125rem',
    backgroundColor: tokens.white,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: '0',
    width: 'calc(100% - 18rem + 9.375rem)',
    paddingRight: '15rem'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  comparableCountChipsContainer: {
    display: 'flex'
  },
  comparableCountChips: {
    marginRight: '2rem'
  }
}));

const Connector = () => {
  const [featureFlagIsActive] = useFeatureFlags();
  const classes = useStyles();
  const flags = useFlags();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [tab, setTab] = useState(0);
  const [compPoolCountToDisplay, setCompPoolCountToDisplay] = useState<Record<number, number | null>>({});
  const [locations, setLocations] = useState<PbaLocation[] | null>(null);
  const [savedOptionsLoaded, setSavedOptionsLoaded] = useState<boolean>(false);
  const [entitiesSortedBySource, setEntitiesSortedBySource] = useState<LegalEntityTransaction[]>([]);
  const [sortedCountryIds, setSortedCountryIds] = useState<number[]>([-1, -1]);
  const [sortedJurisdictions, setSortedJurisdictions] = useState<PbaJurisdiction[]>([]);
  const [isUpdatingCounters, setIsUpdatingCounters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const formInputs: { [key: number]: PbaCompsSearchOptions } = useSelector(selectPbaCompSearchJurisdictionOptions);
  const workingContainer = useSelector(selectWorkingContainer);
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const taxYear = workingContainer?.taxYear ?? null;
  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);
  const pbaJurisdictionDetails = useSelector(selectPbaJurisdictions);
  const currentPBA = useSelector(selectCurrentPBA);
  const sicTypes: sicType[] = useSelector(selectSicTypes);
  const loadingStates = useSelector(selectPBALoadingState);

  const match: any = useRouteMatch('/analysis/:studyId/pba-dashboard/:pbaId');
  const pbaId: any = match.params.pbaId ?? null;
  const jurisdictionId = sortedCountryIds[tab];
  const transactionsPBA = useSelector(selectTransactionByPbaId(Number(pbaId)));
  const parentContainerCompPoolInfo = useSelector(selectParentContainerCompPoolInfo);
  const jurisdictionRules = useSelector(selectGetJurisdictionRules);

  useEffect(() => {
    if (pbaId && !loadingStates.fetchPBAPli) {
      void dispatch(fetchPBAPli(pbaId));
    }
  }, [pbaId, loadingStates.fetchPBAPli, dispatch]);

  useEffect(() => {
    const jurisdictionInputsFromCurrentContainer = Object.keys(formInputs);
    const useJurisdictionInputsFromParentContainer = jurisdictionInputsFromCurrentContainer.length < 2;
    if (useJurisdictionInputsFromParentContainer && parentContainerCompPoolInfo) {
      const jurisdictionIds: Set<number> = new Set(
        entitiesSortedBySource.map((entity) => entity.entity.taxJurisdiction.countryId)
      );

      const jurisdictionInputsFromParentContainer: any = parentContainerCompPoolInfo.parentContainerRunOnDate ?? {};

      // only merge the jurisdiction that have not been ran in current container
      Object.keys(jurisdictionInputsFromParentContainer).forEach((parentContainerJurisdictionId) => {
        if (
          jurisdictionIds.has(Number(parentContainerJurisdictionId)) &&
          !jurisdictionInputsFromCurrentContainer.includes(parentContainerJurisdictionId)
        ) {
          // change time to current container year
          const jurisdictionInputs = { ...jurisdictionInputsFromParentContainer[parentContainerJurisdictionId] };
          const { parentContainerTaxYear } = parentContainerCompPoolInfo.parentcontainerInfo;

          if (taxYear && parentContainerTaxYear) {
            const monthAdjustment = (taxYear - parentContainerTaxYear) * 12;
            const dataDateMonth = new Date(jurisdictionInputs.dataDate).getMonth() + monthAdjustment;
            const dateObject = new Date(jurisdictionInputs.dataDate).setMonth(dataDateMonth);
            jurisdictionInputs.dataDate = format(dateObject, 'yyyy-MM-dd');
          }

          dispatch(
            updatePbaJurisdictionCompSearchOptions({
              jurisdictionId: parentContainerJurisdictionId,
              ...jurisdictionInputs
            })
          );
        }
      });
    }
  }, [dispatch, formInputs, parentContainerCompPoolInfo, entitiesSortedBySource, taxYear]);

  const isServiceProvider = PrimaryFunctions.ByType.ServiceProvider.includes(
    currentTestedParty?.primaryFunction?.primaryFunctionId
  );

  const majorClassificationIds = isServiceProvider
    ? currentMajorClassifications.map((majorClassification) => majorClassification.majorServiceClassificationId)
    : [];

  useEffect(() => {
    const shouldFetchParentContainerRunOnDate = flags.initialSearchFetchParentContainerRunOnDate;
    if (
      (!pbaJurisdictionDetails || pbaJurisdictionDetails.length < 2) &&
      currentPBA &&
      workingContainer &&
      shouldFetchParentContainerRunOnDate &&
      !loadingStates.fetchParentContainerCompPoolRunOnDate
    ) {
      void dispatch(
        fetchParentContainerCompPoolRunOnDate({ pbaId: currentPBA.pbaId, containerId: workingContainer?.containerId })
      );
    }
  }, [
    currentPBA,
    dispatch,
    flags.initialSearchFetchParentContainerRunOnDate,
    pbaJurisdictionDetails,
    workingContainer,
    loadingStates.fetchParentContainerCompPoolRunOnDate
  ]);

  useEffect(() => {
    if (
      (loadingStates.fetchCurrentTestedParty === FetchLoadingStateEnum.fulfilled ||
        loadingStates.fetchCurrentTestedParty === FetchLoadingStateEnum.error) &&
      (loadingStates.fetchJurisdictionRules === FetchLoadingStateEnum.fulfilled ||
        loadingStates.fetchJurisdictionRules === FetchLoadingStateEnum.error) &&
      (loadingStates.fetchManualComparablesCompanies === FetchLoadingStateEnum.fulfilled ||
        loadingStates.fetchManualComparablesCompanies === FetchLoadingStateEnum.error) &&
      (loadingStates.fetchPBAPli === FetchLoadingStateEnum.fulfilled ||
        loadingStates.fetchPBAPli === FetchLoadingStateEnum.error)
    ) {
      setIsLoading(false);
    }
  }, [loadingStates]);

  const displayedSicIndustries: IndustrialCodeValue[] = sicTypes.length > 0 ? sicTypes[1].codeTypes[0].subtypes : [];

  /* picking first transaction since all of them should have
  the same entities cause they are associated to the current PBA
  */
  const transactions = useMemo(() => {
    if (transactionsPBA?.length) {
      return transactionsPBA[0].legalEntityTransactions ?? [];
    }

    return [];
  }, [transactionsPBA]);

  const entities = useMemo(
    () =>
      transactions.filter(
        (entity, index) =>
          transactions.findIndex(
            (currentTrx) => currentTrx.entity.taxJurisdiction.countryId === entity.entity.taxJurisdiction.countryId
          ) === index
      ),
    [transactions]
  );

  const loadJurisdictionRules = useCallback(async () => {
    if (!jurisdictionId || !pbaId || !taxYear) {
      return;
    }

    const result = unwrapResult(await dispatch(fetchJurisdictionRules({ jurisdictionId, pbaId, taxYear })));

    const locations: PbaLocation[] = result
      .map((location) => ({
        ...location,
        testedPartyLocationId: location.pbaPrimaryJurisdictionId
      }))
      .sort((a, b) => {
        if (a.preference > b.preference) {
          return 1;
        }

        if (a.preference < b.preference) {
          return -1;
        }

        return 0;
      });

    setLocations(locations);
  }, [dispatch, jurisdictionId, pbaId, taxYear]);

  useEffect(() => {
    if (jurisdictionId !== -1) {
      void loadJurisdictionRules();
    }
  }, [jurisdictionId, loadJurisdictionRules]);

  useEffect(() => {
    // the source entity should be in the first tab
    const sortedEntities = entities.slice().sort((a) => (a.isSource ? -1 : 1));
    const sortedEntityIds = sortedEntities.map((ent) => ent.entity.taxJurisdiction.countryId);
    setSortedCountryIds(sortedEntityIds);
    const sourceEntity = sortedEntities[0];
    const sortedEntityCountryIds = sortedEntities.map((entity) => entity.entity.taxJurisdiction.countryId);
    if (pbaJurisdictionDetails && sourceEntity) {
      const sortedJurDetails = pbaJurisdictionDetails
        .slice()
        .sort((a) => (a?.jurisdictionId === sourceEntity?.entity.taxJurisdiction.countryId ? -1 : 1));
      setSortedJurisdictions(sortedJurDetails);
    }

    setSortedCountryIds(sortedEntityCountryIds);
    setEntitiesSortedBySource(sortedEntities);
  }, [entities, pbaJurisdictionDetails]);

  useEffect(() => {
    if (currentPBA && !loadingStates.fetchCurrentTestedParty) {
      void dispatch(fetchCurrentTestedParty(currentPBA.pbaId));
      void dispatch(
        fetchManualComparablesCompanies({
          pbaId: currentPBA.pbaId,
          runOnDate: format(new Date(), 'yyyy-MM-dd')
        })
      );
    }
  }, [currentPBA, loadingStates.fetchCurrentTestedParty, dispatch]);

  useEffect(() => {
    const jurisdictionIds: Set<number> = new Set(
      entitiesSortedBySource.map((entity) => entity.entity.taxJurisdiction.countryId)
    );
    [...jurisdictionIds].forEach((jurisdictionId) => {
      const currentGetJurisdictionRule = loadingStates.fetchGetJurisdictionRules?.[jurisdictionId]?.[taxYear!];
      if (jurisdictionId !== -1 && taxYear && !currentGetJurisdictionRule) {
        void dispatch(fetchGetJurisdictionRules({ jurisdictionId, taxYear }));
      }
    });
  }, [dispatch, taxYear, entitiesSortedBySource, loadingStates.fetchGetJurisdictionRules]);

  useMemo(() => {
    if (!savedOptionsLoaded && sortedJurisdictions.length > 0) {
      let loaded = true;
      const compPoolCountToDisplayTemp: Record<number, number | null> = {};
      sortedJurisdictions.forEach((detail: PbaJurisdiction) => {
        if (detail.pbaJurisdictionInfo) {
          const {
            jurisdictionId,
            locationId,
            locations,
            maxIndustrialCodeRange,
            minIndustrialCodeRange,
            numCompsInPool,
            runOnDate
          } = detail.pbaJurisdictionInfo;

          const chosenCountry = locations?.find((country: PbaLocation) => country.locationId === locationId);
          const fullMinSicCode = displayedSicIndustries.find(
            (sicIndustry) => sicIndustry.value === minIndustrialCodeRange
          );
          const fullMaxSicCode = displayedSicIndustries.find(
            (sicIndustry) => sicIndustry.value === maxIndustrialCodeRange
          );

          compPoolCountToDisplayTemp[jurisdictionId] = numCompsInPool;

          void dispatch(
            updatePbaJurisdictionCompSearchOptions({
              jurisdictionId,
              chosenCountry,
              minSicCode: fullMinSicCode,
              maxSicCode: fullMaxSicCode,
              compPoolCount: numCompsInPool,
              dataDate: runOnDate
            })
          );
        } else {
          loaded = false;
          return null;
        }

        setCompPoolCountToDisplay(compPoolCountToDisplayTemp);
      });
      setSavedOptionsLoaded(loaded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sortedJurisdictions)]);

  const comparableCountChips = entitiesSortedBySource.map((entity) => (
    <span key={`comp-count-chip-${entity.entity.taxJurisdiction.isoCode}`} className={classes.comparableCountChips}>
      <ComparableCountChip
        country={entity.entity.taxJurisdiction.isoCode}
        count={compPoolCountToDisplay[entity.entity.taxJurisdiction.countryId]}
      />
    </span>
  ));

  async function updateManualComparableCompanyJurisdictions({
    sourceId,
    sourceType,
    jurisdictionIds
  }: {
    sourceId: number;
    sourceType: number | undefined;
    jurisdictionIds: number[];
  }) {
    if (workingContainer) {
      void dispatch(
        updateJurisdictionsForManualComparablesCompany({
          pbaId,
          sourceId,
          containerId: workingContainer?.containerId,
          jurisdictionIds,
          sourceType
        })
      );
    }
  }

  async function recalculateProjectedCounts(data: RecalculateComparablesProps) {
    const { dataDate, location, minSicCode, maxSicCode } = data;

    if (!currentTestedParty || !workingContainer) return;

    const projectedCompPoolPayload: ProjectCompPoolWithoutCompSearchPayload = {
      container: workingContainer,
      primaryFunctionId: currentTestedParty?.primaryFunction.primaryFunctionId,
      testedPartyId: currentTestedParty?.testedPartyId,
      locationId: location.locationId,
      locationTypeId: location.locationTypeId,
      runOnDateAsDate: dataDate,
      minIndustrialCodeRange: minSicCode?.value,
      maxIndustrialCodeRange: maxSicCode?.value,
      majorClassificationIds
    };

    compPoolCountToDisplay[jurisdictionId] = -1;
    setIsUpdatingCounters(true);
    setCompPoolCountToDisplay(compPoolCountToDisplay);

    const passPreconditions = (projectedCompPoolPayload: ProjectCompPoolWithoutCompSearchPayload) =>
      (Boolean(projectedCompPoolPayload.minIndustrialCodeRange) &&
        Boolean(projectedCompPoolPayload.maxIndustrialCodeRange)) ||
      projectedCompPoolPayload.majorClassificationIds.length > 0;

    const response = passPreconditions(projectedCompPoolPayload)
      ? unwrapResult(
          await dispatch(projectCompPoolCountWithoutCompSearch({ jurisdictionId, projectedCompPoolPayload }))
        )
      : { numCompsInPool: null };

    setIsUpdatingCounters(false);
    compPoolCountToDisplay[jurisdictionId] = response?.numCompsInPool;
    setCompPoolCountToDisplay(compPoolCountToDisplay);
    void dispatch(
      updatePbaJurisdictionCompSearchOptions({
        jurisdictionId,
        compPoolCount: compPoolCountToDisplay[jurisdictionId] ?? 0
      })
    );
  }

  const searchNow = async (countries: CSInitialSearchJurisdictionData[]) => {
    const mergedData: PbaCompSearchOptionsByJurisdiction = {};
    countries.forEach((country) => {
      const currentFormInputs = formInputs[country.countryId] ?? {};
      mergedData[country.countryId] = { ...currentFormInputs };
    });
    if (currentTestedParty?.primaryFunction?.primaryFunctionId) {
      const runForAllJurisdictions = countries.every((country) => country.isOverride);
      const jurisdictionRunId = runForAllJurisdictions
        ? -1
        : countries.find((country) => country.isOverride)?.countryId;

      const jurisdictions = countries.reduce((acc, country) => {
        const { countryId, isOverride } = country;
        const compPoolOverrides: ApiCompPoolOverrides = {
          industrialCodeTypeId:
            PrimaryFunctions.ById[currentTestedParty.primaryFunction.primaryFunctionId].industrialCodeTypeId ?? 0,
          locationId: mergedData[countryId].chosenCountry?.locationId ?? 0,
          locationTypeId: mergedData[countryId].chosenCountry?.locationTypeId ?? 0,
          runOnDate: mergedData[countryId].dataDate ?? '',
          minIndustrialCodeRange: isServiceProvider ? 0 : mergedData[countryId].minSicCode?.value ?? 0,
          maxIndustrialCodeRange: isServiceProvider ? 0 : mergedData[countryId].maxSicCode?.value ?? 0,
          majorClassificationIds
        };

        return isOverride
          ? {
              ...acc,
              [countryId]: {
                executionMode: CompSearchExecution.ByName.Override.Id,
                jurisdictionId: countryId,
                compPoolOverrides
              }
            }
          : {
              ...acc,
              [countryId]: {
                executionMode: CompSearchExecution.ByName.Copy.Id,
                jurisdictionId: countryId
              }
            };
      }, {});

      const compSearchPayload = {
        container: workingContainer,
        pbaId,
        containerId: workingContainer?.containerId,
        executionContext: {
          jurisdictions
        }
      };

      void dispatch(runCSRun({ jurisdictionRunId }));
      void dispatch(runCompSearch({ pbaId, compSearchPayload }));
      void dispatch(removeCurrentPbaJurisdictions());
    }
  };

  const navigationTabs = useMemo(() => {
    const jurisdictionIsoCodes: Array<{ jurisdictionId: number; isoCode: string }> = [];
    const tabs = entitiesSortedBySource.map((entity) => {
      const content = isServiceProvider ? (
        <ServiceProviderSearchSetup
          locations={locations}
          jurisdictionId={jurisdictionId}
          recalculateProjectedCounts={recalculateProjectedCounts}
          parentContainerCompPoolInfo={parentContainerCompPoolInfo}
        />
      ) : (
        <InitialSearchDistributor
          locations={locations}
          recalculateProjectedCounts={recalculateProjectedCounts}
          jurisdictionId={jurisdictionId}
          parentContainerCompPoolInfo={parentContainerCompPoolInfo}
        />
      );

      jurisdictionIsoCodes.push({
        jurisdictionId: entity.entity.taxJurisdiction.countryId,
        isoCode: entity.entity.taxJurisdiction.isoCode
      });

      return {
        key: entity.entity.taxJurisdiction.isoCode,
        label: entity.entity.taxJurisdiction.isoCode,
        disabled: false,
        content,
        createButtonText: t('analysis:summary')
      };
    });
    if (featureFlagIsActive('priorYearCompsYear1')) {
      tabs.push({
        key: 'pinned-comparables',
        label: t('analysis:title-pinned-comparables'),
        disabled: false,
        content: (
          <PinnedComparables
            pinnedCompsFetching={loadingStates.fetchCurrentTestedParty !== 'fulfilled'}
            jurisdictionIsoCodes={jurisdictionIsoCodes}
            updateManualComparableCompanyJurisdictions={updateManualComparableCompanyJurisdictions}
            companyJurisdictionsLoading={loadingStates.updateJurisdictionsForManualComparablesCompany}
            jurisdictionRules={jurisdictionRules}
          />
        ),
        createButtonText: 'Add New'
      });
    }

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitiesSortedBySource, featureFlagIsActive, isServiceProvider, jurisdictionId, locations, t]);

  return (
    <Box className={classes.container}>
      <Box className={classes.headerContainer}>
        <Typography className={classes.headerContent}>
          {t('analysis:title-section-comparable-initial-search')}
        </Typography>
        <Box
          key={`comp-count-${String(compPoolCountToDisplay[0])}-${String(compPoolCountToDisplay[1])}`}
          className={classes.comparableCountChipsContainer}
        >
          {comparableCountChips}{' '}
        </Box>
      </Box>
      {isLoading ? (
        <CenteredProgress />
      ) : (
        <NavigationTabs
          tabs={navigationTabs}
          onSelectTab={(tab: number) => {
            setTab(tab);
          }}
        />
      )}
      <Box className={classes.footerContainer}>
        <PbaSearchButton
          entitiesSortedBySource={entitiesSortedBySource}
          isServiceProvider={isServiceProvider}
          searchAction={searchNow}
          isUpdating={isUpdatingCounters}
        />
      </Box>
    </Box>
  );
};

export default Connector;
