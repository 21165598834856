import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { RollingContainerProps } from './RollingContainer.proptype';
import { selectWorkingContainer } from '../../selectors';
import { selectRollingContainerStatus, selectRollingForecast } from '../../selectors/rollingContainers';

export type ConnectorProps = {
  component: ComponentType<RollingContainerProps>;
};

const Connector = ({ component: Component }: ConnectorProps) => {
  const container = useSelector(selectWorkingContainer);
  const containerRollStatus = useSelector(selectRollingContainerStatus);
  const containerRollingForecast = useSelector(selectRollingForecast);

  if (!container) {
    return null;
  }

  return (
    <Component
      containerId={container.containerId}
      taxYear={container.taxYear}
      containerRollStatus={containerRollStatus}
      containerRollingForecast={containerRollingForecast}
    />
  );
};

export default Connector;
