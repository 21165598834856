import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Transactions as Component } from './components';
import Connector from './connector';
import RouteOptions from '../Router/RouteOptions';
import { TransactionDashboard } from '../TransactionDashboard';
import { TransactionDetails, TransactionDetailsTab } from '../TransactionDetails';

const ChildRoutes = () => {
  const { path } = useRouteMatch();
  const transactionId = Number(useParams<{ transactionId: string }>().transactionId);
  return (
    <Switch>
      <Route path={`${path}/dashboard`}>
        <TransactionDashboard transactionId={transactionId} />
      </Route>
      <Route path={`${path}/details`}>
        <Switch>
          {Object.values(TransactionDetailsTab).map((tab) => (
            <Route key={tab} path={`${path}/details/${tab}`}>
              <TransactionDetails transactionId={transactionId} tab={tab} />
            </Route>
          ))}
          <Redirect to={`${path}/details/${TransactionDetailsTab.StatementOfFacts}`} />
        </Switch>
      </Route>
      <Redirect to={`${path}/dashboard`} />
    </Switch>
  );
};

export const Transactions = () => (
  <RouteOptions
    RootRoute={<Connector component={Component} />}
    ChildRoutes={[{ path: '/:transactionId', component: <ChildRoutes /> }]}
  />
);
