import { FinancialConversion } from '../../models';

export const groupByEntityId = (rates: FinancialConversion[]) => {
  const groups = new Map<number, FinancialConversion[]>();
  for (const rate of rates) {
    let entityRates = groups.get(rate.legalEntity.entityId);
    if (!entityRates) {
      entityRates = [];
      groups.set(rate.legalEntity.entityId, entityRates);
    }

    entityRates.push(rate);
  }

  return groups;
};

export const differs = (ratesA: FinancialConversion[], ratesB: FinancialConversion[]) => {
  if (ratesA.length !== ratesB.length) {
    return true;
  }

  const orderedA = [...ratesA].sort((a, b) => a.taxYear - b.taxYear);
  const orderedB = [...ratesB].sort((a, b) => a.taxYear - b.taxYear);

  return orderedA.find(
    (item, i) => item.taxYear !== orderedB[i].taxYear || item.conversionValue !== orderedB[i].conversionValue
  );
};
