import { Box } from '@material-ui/core';
import { ClassificationCard } from './ClassificationCard';
import { MajorClassification, TestedPartyServiceProviderProps } from '../TestedPartyServiceProvider.proptype';

export const TestedPartyServiceProvider = ({
  allMajorClassifications,
  majorClassifications,
  dropdownMinorClassifications,
  removeMinorClassification,
  onSelectMajorClassification,
  onSelectMinorClassification
}: TestedPartyServiceProviderProps) => {
  return (
    <Box display="flex" marginLeft="1rem">
      {majorClassifications.map((majorClassification: MajorClassification | null, index: number) => (
        <ClassificationCard
          key={`classification-card-${Math.random().toString(36).slice(4)}`}
          cardNumber={index}
          selectedMajorClassification={majorClassification}
          allMajorClassifications={allMajorClassifications}
          selectedMajorClassifications={majorClassifications}
          dropDownMinorClassifications={dropdownMinorClassifications[index]}
          removeMinorClassification={removeMinorClassification}
          majorClassificationIndex={index + 1}
          onSelectMajorClassification={onSelectMajorClassification}
          onSelectMinorClassification={onSelectMinorClassification}
        />
      ))}
    </Box>
  );
};
