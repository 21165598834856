import { Route, Switch, useParams, useRouteMatch, Redirect } from 'react-router';
import { Analysis as Component } from './components';
import Connector from './connector';
import { ProfitBasedAnalysisDashboard } from '../ProfitBasedAnalysisDashboard';
import RouteOptions from '../Router/RouteOptions';
import { StudyDashboard } from '../StudyDashboard';
import { StudyDashboardTab } from '../StudyDashboard/StudyDashboard.proptype';
import { TransactionBasedAnalysisDashboard } from '../TransactionBasedAnalysisDashboard';

const ChildRoutes = () => {
  const { path } = useRouteMatch();
  const studyId = Number(useParams<{ studyId: string }>().studyId);
  return (
    <Route path={path}>
      <Switch>
        {Object.values(StudyDashboardTab).map((tab) => (
          <Route key={tab} path={path}>
            <StudyDashboard studyId={studyId} tab={tab} />
          </Route>
        ))}
        <Redirect to={path} />
      </Switch>
    </Route>
  );
};

const PbaChildRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Route path={path}>
      <Switch>
        <Route key="dashboard" path={path}>
          <ProfitBasedAnalysisDashboard />
        </Route>
      </Switch>
    </Route>
  );
};

const TbaChildRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Route path={path}>
      <Switch>
        <Route key="dashboard" path={path}>
          <TransactionBasedAnalysisDashboard />
        </Route>
      </Switch>
    </Route>
  );
};

export const Analysis = () => (
  <RouteOptions
    RootRoute={<Connector component={Component} />}
    ChildRoutes={[
      { path: '/:studyId/pba-dashboard', component: <PbaChildRoutes /> },
      { path: '/:studyId/tba-dashboard', component: <TbaChildRoutes /> },
      { path: '/:studyId', component: <ChildRoutes /> }
    ]}
  />
);
