const MAXIMIZE_ICON =
  '<svg class="maximize" width="24" height="24" viewBox="0 0 24 24" ><path d="M3 5v4h2V5h4V3H5c-1.1 0-2 .9-2 2zm2 10H3v4c0 1.1.9 2 2 2h4v-2H5v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2V5c0-1.1-.9-2-2-2z"></path></svg>';
const BOLD_ICON =
  '<svg class="bold" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1"><path d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"></path></svg>';
const ITALIC_ICON =
  '<svg class="italic" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" tabindex="-1"><path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z"></path></svg>';
const FORMAT_ICON =
  '<svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H11V18C11 18.5523 10.5523 19 10 19C9.44772 19 9 18.5523 9 18V7H5C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5H15Z" /><rect x="21" y="9.41418" width="2" height="9" rx="1" transform="rotate(-45 21 9.41418)" /><rect x="32.364" y="8" width="2" height="9" rx="1" transform="rotate(45 32.364 8)" /></svg>';

export const Icons = [
  {
    name: 'bold',
    icon: BOLD_ICON
  },
  {
    name: 'italic',
    icon: ITALIC_ICON
  },
  {
    name: 'maximize-icon',
    icon: MAXIMIZE_ICON
  },
  {
    name: 'format-icon',
    icon: FORMAT_ICON
  }
];
