import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Theme, makeStyles, Typography, Box, TextField } from '@material-ui/core';
import { Add as AddIcon, CreateOutlined as CreateOutlinedIcon } from '@material-ui/icons';
import { StudyAddEditModalProps, StudyInputs } from './StudyAddEditModal.proptype';
import { SearchBox, TPModal } from '../../components';
import { Study } from '../../models';
import { selectWorkingContainer } from '../../selectors';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    borderRadius: '50%',
    boxSizing: 'content-box',
    fill: theme.palette.primary.contrastText,
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(0.5, 5.5),
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
  formItem: {
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(0.5, 0)
  },
  label: {
    padding: theme.spacing(0.5)
  }
}));

const mapStudyToForm = ({ study }: { study: Study }): StudyInputs => {
  const { name = '', description = '', primaryEntity } = study ?? {};

  return {
    name,
    description,
    primaryEntity: primaryEntity ?? null
  };
};

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

export const StudyAddEditModal = ({ onClose, onSubmit, study, entities = [] }: StudyAddEditModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const workingContainer = useSelector(selectWorkingContainer);

  const defaultValues = mapStudyToForm({ study: study! });

  const formMethods = useForm<StudyInputs>({
    defaultValues
  });
  const { handleSubmit, control, errors } = formMethods;

  return (
    <TPModal
      isOpen
      maxWidth="sm"
      title={
        <span className={classes.modalTitleWrapper}>
          {study ? <CreateOutlinedIcon className={classes.headerIcon} /> : <AddIcon className={classes.headerIcon} />}
          <Typography variant="h6">{t(`analysis:${study ? 'title-edit-study' : 'action-new'}`)}</Typography>
        </span>
      }
      actions={
        [
          { name: t('action-cancel'), handler: onClose },
          {
            name: t('action-done'),
            variant: 'contained',
            color: 'primary',
            handler: handleSubmit(async (data: Study) => {
              try {
                onSubmit(data);
                /* eslint-disable camelcase */
                void logGoogleAnalyticsEvent({
                  event_category: 'create_analysis_group_done_button',
                  event_label: 'Create Analysis Group modal done button',
                  container_id: workingContainer?.containerId
                });
                onClose();
              } catch {}
            })
          }
        ].filter(Boolean) as any
      }
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography className={classes.label}>{t('analysis:column-name')}</Typography>
          <Controller
            name="name"
            render={({ onChange, value }) => (
              <TextField
                id="name"
                name="name"
                margin="dense"
                variant="outlined"
                value={value}
                error={Boolean(errors.name)}
                onChange={onChange}
              />
            )}
            control={control}
            defaultValue={defaultValues.name}
            rules={{
              required: {
                message: t('analysis:invalid-entry-required-study-name'),
                value: true
              },
              maxLength: {
                value: 60,
                message: t('analysis:invalid-entry-study-name-too-long')
              }
            }}
            error={Boolean(errors.name)}
          />
          <ErrorMessage error={Boolean(errors.name)} message={errors.name?.message ?? ''} />
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.formItem}>
          <Typography className={classes.label}>{t('analysis:column-primary-entity')}</Typography>
          <Controller
            name="primaryEntity"
            render={({ onChange, value }) =>
              study?.primaryEntity ? (
                <TextField disabled id="primaryEntity" margin="dense" variant="outlined" value={value.code} />
              ) : (
                <SearchBox
                  error={Boolean(errors.primaryEntity)}
                  value={value}
                  options={entities}
                  getOptionSelected={(option, value) => option.entityId === value.entityId}
                  getOptionLabel={(parent) => parent.code}
                  onChange={(_, item) => {
                    onChange(item);
                  }}
                />
              )
            }
            defaultValue={defaultValues.primaryEntity}
            control={control}
            rules={{ required: true }}
            error={Boolean(errors.primaryEntity)}
          />
          <ErrorMessage
            error={Boolean(errors.primaryEntity)}
            message={t('analysis:invalid-entry-required-parent-entity')}
          />
        </Box>
      </Box>
    </TPModal>
  );
};
