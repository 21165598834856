import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import { TPModal } from '../../app/TPModal';

export interface ConfirmationModalProps {
  open: boolean;
  title: string;
  subtitle: string;
  text: string;
  confirmButtonText?: ReactElement | string;
  cancelButtonText?: ReactElement | string;
  handleClose: (confirmation: boolean) => void;
  HeaderIcon?: ReactNode | null;
  isLoading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '100%',
    fill: theme.palette.primary.contrastText,
    height: '2.25rem',
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.5),
    verticalAlign: 'bottom',
    width: '2.25rem'
  },
  container: {
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(1, 0)
    }
  },
  progressBar: {
    color: 'white'
  }
}));

export const ConfirmationModal = ({
  open,
  handleClose,
  title,
  subtitle,
  text,
  confirmButtonText = 'action-confirm',
  cancelButtonText = 'action-cancel',
  HeaderIcon,
  isLoading
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCancel = () => {
    handleClose(false);
  };

  if (isLoading) {
    confirmButtonText = '';
  }

  const HeaderIconCasted = HeaderIcon as SvgIconComponent;

  return (
    <TPModal
      isOpen={open}
      title={
        <>
          {HeaderIcon && <HeaderIconCasted fontSize="large" className={classes.icon} />}
          {title}
        </>
      }
      actions={[
        { name: t(cancelButtonText as string), handler: handleCancel },
        {
          name: confirmButtonText ? t(confirmButtonText as string) : '',
          variant: 'contained',
          color: 'primary',
          handler: () => {
            handleClose(true);
          }
        }
      ]}
      maxWidth="sm"
      centeredProgressSize={30} // Default is 40px. This avoid scrolling bar is presented
      onClose={handleCancel}
    >
      <Box className={classes.container}>
        {subtitle ? (
          <Typography variant="caption" component="p">
            {subtitle}
          </Typography>
        ) : null}
        {text ? (
          <Typography variant="caption" component="p">
            {text}
          </Typography>
        ) : null}
      </Box>
    </TPModal>
  );
};
