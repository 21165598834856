import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isValid, parse } from 'date-fns';
import { initialSearchUseStyles } from './InitialSearchDistributor';
import { SearchBox } from '../../../../components';
import { SimpleDatePicker } from '../../../../components/SimpleDatePicker';
import { updatePbaJurisdictionCompSearchOptions } from '../../../../redux/profitBasedAnalyses';
import { selectPbaCompSearchJurisdictionOptions } from '../../../../selectors';
import { AppDispatch } from '../../../../store';
import { dateStringInOurFormatIsValidDate } from '../../../../utils/dates';
import { InitialSearchSetupProps } from '../InitialSearch.proptype';

export const ServiceProviderSearchSetup = ({
  locations,
  jurisdictionId,
  recalculateProjectedCounts
}: InitialSearchSetupProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const classes = initialSearchUseStyles({ isValueAddedDistributor: false });
  const formInputs = useSelector(selectPbaCompSearchJurisdictionOptions);
  const currentFormInputs = formInputs?.[jurisdictionId] ?? {};
  const { chosenCountry } = currentFormInputs;
  const { dataDate } = currentFormInputs;

  const onDateChange = (newDate: string | null) => {
    if (newDate && dateStringInOurFormatIsValidDate(newDate)) {
      dispatch(
        updatePbaJurisdictionCompSearchOptions({
          jurisdictionId,
          dataDate: newDate
        })
      );
      if (chosenCountry && isValid(parse(newDate, 'yyyy-MM-dd', new Date()))) {
        recalculateProjectedCounts({ location: chosenCountry, dataDate: newDate });
      }
    }
  };

  return (
    <Box className={classes.formContainer}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Box className={classes.formElement}>
            <Typography className={classes.typographyHeading}>{t('analysis:title-choose-data-date')}</Typography>
            <Box className={classes.inputField}>
              <SimpleDatePicker
                key={`${jurisdictionId}-${String(dataDate)}`}
                defaultDate={dataDate ?? ''}
                onChange={onDateChange}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.formElement}>
            <Typography className={classes.typographyHeading}>{t('analysis:title-select-country')}</Typography>
            <SearchBox
              className={clsx(classes.searchBoxInput, classes.countrySearchInput)}
              placeholder={t('analysis:placeholder.search_country_or_region')}
              noOptionsText={t('analysis:country-or-region-label-no-match')}
              value={chosenCountry ?? null}
              options={locations ?? []}
              getOptionLabel={(option: any) => option.name}
              getOptionSelected={(option: any, value: any) => option?.locationId === value?.locationId}
              renderOption={(option) => <Typography>{option.name}</Typography>}
              onChange={(_, data: any) => {
                if (data) {
                  dispatch(
                    updatePbaJurisdictionCompSearchOptions({
                      jurisdictionId,
                      chosenCountry: data
                    })
                  );

                  if (dataDate) {
                    recalculateProjectedCounts({ location: data, dataDate });
                  }
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
