import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { JurisdictionAnalysisInfo } from './component';
import { JurisdictionPli } from './JurisdictionAnalysisInfo.proptype';
import { CenteredProgress } from '../../components';
import { FetchLoadingStateEnum } from '../../constants';
import { LoadPsgAnalysisTemplateApiCallParams } from '../../models';
import {
  fetchCurrentTestedParty,
  fetchJurisdictionDescription,
  fetchJurisdictionPliFormats,
  fetchJurisdictionPlis,
  fetchPBAs,
  fetchPbaJurisdictions,
  updatePliStatus,
  updatePbaJurisdictionStatus,
  editJurisdictionPliStatus,
  saveAnalysisTemplate,
  savePsgAnalysisTemplate,
  loadPsgAnalysisTemplate
} from '../../redux/profitBasedAnalyses';
import {
  selectCurrentMajorClassification,
  selectCurrentPBA,
  selectCurrentTestedParty,
  selectJurisdictionDescription,
  selectJurisdictionPliFormat,
  selectJurisdictionPlis,
  selectPBAJurisdictionById,
  selectPBALoadingState,
  selectPBAsList,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { MajorClassification } from '../TestedPartyCharacterization/TestedPartyCharacterization.proptype';

const Connector = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pba = useSelector(selectCurrentPBA);
  const pbas = useSelector(selectPBAsList);

  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const studyId = Number(useParams<{ studyId: string }>().studyId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);

  const currentContainer = useSelector(selectWorkingContainer);

  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const pbaJurisdictionDetails = useSelector(selectPBAJurisdictionById(jurisdictionId));

  const jurisdictionDescription = useSelector(selectJurisdictionDescription(jurisdictionId));

  const jurisdictionPli = useSelector(selectJurisdictionPlis(jurisdictionId));
  const jurisdictionPliFormat = useSelector(selectJurisdictionPliFormat(jurisdictionId));
  const loadingStates = useSelector(selectPBALoadingState);

  const [plis, setPlis] = useState<JurisdictionPli[]>(jurisdictionPli);

  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);

  const jurisdictionStatusInfo = pba?.jurisdictionStatuses
    ? pba.jurisdictionStatuses.find((status) => status.jurisdictionId === jurisdictionId)
    : undefined;

  const updateJurisdictionStatus = async (status: number) => {
    if (jurisdictionStatusInfo) {
      const updatedJurisdictionStatus = {
        status,
        jurisdictionId: jurisdictionStatusInfo.jurisdictionId,
        jurisdictionIsoCode: jurisdictionStatusInfo.jurisdictionIsoCode
      };

      await dispatch(updatePbaJurisdictionStatus({ pbaId, jurisdictionStatus: updatedJurisdictionStatus }));
      void dispatch(fetchPBAs(studyId));
    }
  };

  const onChange = (toggle: boolean, index: number) => {
    const updatedPli = [...plis];
    updatedPli[index] = {
      ...updatedPli[index],
      exclude: toggle ? 'global.no' : 'global.yes'
    };
    setPlis(updatedPli);
    void dispatch(
      updatePliStatus({
        compSearchId: Number(pbaJurisdictionDetails?.compSearchId),
        jurisdictionId,
        pliTypeId: updatedPli[index].pliTypeId,
        pliAverageTypeId: updatedPli[index].pliAvgTypeId,
        pliStatus: Number(!toggle),
        currentContainer
      })
    );
    void dispatch(editJurisdictionPliStatus(jurisdictionId, index, updatedPli[index].exclude));
  };

  const saveAnalysisTemplateApiCall = (value: string) => {
    void dispatch(
      saveAnalysisTemplate({
        tenantId: currentContainer?.tenantId ?? 0,
        compSearchId: pbaJurisdictionDetails?.compSearchId ?? 0,
        compSearchName: value,
        containerId: currentContainer?.containerId ?? 0,
        jurisdictionId
      })
    );
  };

  const savePsgAnalysisTemplateApiCall = (value: string) => {
    void dispatch(
      savePsgAnalysisTemplate({
        compSearchId: pbaJurisdictionDetails?.compSearchId ?? 0,
        compSearchName: value,
        containerId: currentContainer?.containerId ?? 0,
        jurisdictionId
      })
    );
  };

  const loadPsgAnalysisTemplateApiCall = async (params: LoadPsgAnalysisTemplateApiCallParams) => {
    await dispatch(
      loadPsgAnalysisTemplate({
        compSearchId: pbaJurisdictionDetails?.compSearchId ?? 0,
        containerId: currentContainer?.containerId ?? 0,
        jurisdictionId,
        loadCompSearchId: params.loadCompSearchId,
        loadContainerId: params.loadContainerId,
        loadJurisdictionId: params.loadJurisdictionId
      })
    );
    if (pbaJurisdictionDetails?.compSearchId) {
      void dispatch(
        fetchJurisdictionDescription({
          compSearchId: pbaJurisdictionDetails.compSearchId,
          jurisdictionId
        })
      );
      void dispatch(
        fetchJurisdictionPlis({
          compSearchId: pbaJurisdictionDetails.compSearchId,
          jurisdictionId
        })
      );
    }
  };

  useEffect(() => {
    if (!loadingStates.fetchPBAs) {
      void dispatch(fetchPBAs(studyId));
    }

    if (!loadingStates.fetchCurrentTestedParty) {
      void dispatch(fetchCurrentTestedParty(pbaId));
    }

    if (!loadingStates.fetchPBAJurisdictionDetails) {
      void dispatch(fetchPbaJurisdictions(pbaId));
    }

    if (pbaJurisdictionDetails) {
      if (!loadingStates.fetchPBAJurisdictionDescription) {
        void dispatch(
          fetchJurisdictionDescription({
            compSearchId: pbaJurisdictionDetails.compSearchId,
            jurisdictionId
          })
        );
      }

      if (
        !loadingStates.fetchJurisdictionPlis ||
        loadingStates.fetchJurisdictionPlis === FetchLoadingStateEnum.reload
      ) {
        void dispatch(
          fetchJurisdictionPlis({
            compSearchId: pbaJurisdictionDetails.compSearchId,
            jurisdictionId
          })
        );
      }

      if (!loadingStates.fetchPBAJurisdictionPLIFormats) {
        void dispatch(
          fetchJurisdictionPliFormats({ compSearchId: pbaJurisdictionDetails?.compSearchId, jurisdictionId })
        );
      }
    }
  }, [
    dispatch,
    currentTestedParty,
    jurisdictionDescription,
    jurisdictionPli,
    pbaId,
    jurisdictionId,
    pbaJurisdictionDetails,
    jurisdictionPliFormat,
    pba,
    pbas,
    studyId,
    loadingStates
  ]);

  useEffect(() => {
    if (jurisdictionPli) {
      setPlis(jurisdictionPli);
    }
  }, [jurisdictionPli]);

  const isJurisdictionAnalysisInfoReady = [
    loadingStates.fetchPBAs,
    loadingStates.fetchCurrentTestedParty,
    loadingStates.fetchPBAJurisdictionDetails,
    loadingStates.fetchPBAJurisdictionDescription,
    loadingStates.fetchJurisdictionPlis,
    loadingStates.fetchPBAJurisdictionPLIFormats
  ].every((loadingState) => loadingState !== FetchLoadingStateEnum.loading);

  const isJurisdictionAnalysisInfoFailed = [
    loadingStates.fetchJurisdictionPlis,
    loadingStates.fetchPBAJurisdictionPLIFormats
  ].some((loadingState) => loadingState === FetchLoadingStateEnum.error);

  return isJurisdictionAnalysisInfoReady ? (
    <JurisdictionAnalysisInfo
      compSearchId={pbaJurisdictionDetails?.compSearchId}
      currentTestedParty={currentTestedParty}
      jurisdictionDescription={jurisdictionDescription}
      plis={plis}
      jurisdictionPliFormat={jurisdictionPliFormat}
      jurisdictionStatus={jurisdictionStatusInfo}
      classifications={currentMajorClassifications}
      hasFetchErrors={isJurisdictionAnalysisInfoFailed}
      updateJurisdictionStatus={updateJurisdictionStatus}
      saveAnalysisTemplate={saveAnalysisTemplateApiCall}
      savePsgAnalysisTemplate={savePsgAnalysisTemplateApiCall}
      loadPsgAnalysisTemplate={loadPsgAnalysisTemplateApiCall}
      onChange={onChange}
    />
  ) : (
    <CenteredProgress />
  );
};

export default Connector;
