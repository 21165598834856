import * as d3 from 'd3';
import { TreeNode } from '../TreeChart.proptype';

export function drawChart({
  svg,
  nodeContainer,
  nodeWidth,
  nodeHeight,
  linkColor,
  data
}: {
  svg: SVGSVGElement;
  nodeContainer: HTMLElement;
  nodeWidth: number;
  nodeHeight: number;
  linkColor: string;
  data: TreeNode[];
}) {
  const layout = d3.tree<TreeNode>().nodeSize([nodeWidth, nodeHeight]);
  const root = layout(
    d3
      .stratify<TreeNode>()
      .id((d) => d.id?.toString())
      .parentId((d) => d.parentId?.toString())(data)
  );
  const container = d3.select<HTMLElement, unknown>(nodeContainer);

  // computes actual dimensions
  const extent = { top: 0, left: 0, bottom: 0, right: 0 };
  root.each(({ x, y }) => {
    if (x < extent.left) {
      extent.left = x;
    } else if (x > extent.right) {
      extent.right = x;
    }

    if (y < extent.top) {
      extent.top = y;
    } else if (y > extent.bottom) {
      extent.bottom = y;
    }
  });
  const width = extent.right - extent.left;
  const height = extent.bottom - extent.top;
  const offset = -extent.left + nodeWidth / 2;

  // zooming/panning support
  const zoom = d3
    .zoom<HTMLElement, unknown>()
    .constrain((transform: any) => {
      const { x, y, k } = transform;
      const dx0 = width * 0.5 * k - x;
      const dx1 = width * -0.5 * k - x;
      const dy0 = -y;
      const dy1 = height * -k - y;
      return transform.translate(
        dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
        dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
      );
    })
    .scaleExtent([0.2, 1])
    .on('zoom', ({ transform: { x, y, k } }) => {
      container
        .transition()
        .duration(50)
        .style('transform', `translate(${x as number}px, ${y as number}px) scale(${k as number})`);
    });

  d3.select(nodeContainer.parentElement!).call(zoom);

  // links
  d3.select(svg)
    .attr('width', width + nodeWidth)
    .attr('height', height)
    .selectAll('path')
    .data(root.links())
    .join(
      (enter) => enter.append('path'),
      (update) => update,
      (exit) => exit.remove()
    )
    .attr('d', ({ source, target }) => {
      const halfY = (target.y + source.y) / 2;
      const corner = source.x < target.x ? -5 : 5;
      return source.x === target.x
        ? `M${offset + source.x},${source.y}V${target.y}`
        : `M${offset + source.x},${source.y}V${halfY}H${offset + target.x + corner}Q${offset + target.x},${halfY},${
            offset + target.x
          },${halfY + Math.abs(corner)}V${target.y}`;
    })
    .attr('fill', 'none')
    .attr('stroke', linkColor)
    .attr('stroke-width', 1);

  // nodes
  container
    .selectAll('[data-id]')
    .data(root.descendants(), function (d: any) {
      return d?.data?.id ?? (this as HTMLElement).dataset.id;
    })
    .join(
      (enter) => {
        // center on UPE
        zoom.translateTo(container, offset - width * 0.5, 0, [0, 0]);
        return enter;
      },
      (update) => update.style('top', ({ y }) => `${y}px`).style('left', ({ x }) => `${offset + x}px`),
      (exit) => exit
    );
}
