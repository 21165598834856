import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { getAppConfig } from '../../utils';

interface GoogleAnalyticsProviderProps {
  children: JSX.Element;
}

export default function GoogleAnalyticsProvider({ children }: GoogleAnalyticsProviderProps) {
  const config = getAppConfig();

  useEffect(() => {
    const xbsEnvironment = config?.xbsEnvironment;
    const googleAnalyticsTrackId = config?.googleAnalyticsTrackId?.[xbsEnvironment];
    if (googleAnalyticsTrackId) {
      ReactGA.initialize(googleAnalyticsTrackId);
    }
  }, [config]);

  return children;
}
