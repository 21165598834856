// ROLES was previously in the Router/constants.ts file, but the utils/hasEditAccess.ts file imports ROLES,
// and the Router/constants.ts depends on utils/hasEditAccess.ts
// to prevent such circular dependencies, this file should only be for pure declarative constants
export const ROLES = {
  reportsOnly: 'reportsonly',
  documentationReview: 'documentationreview',
  transferPricing: 'transferpricing',
  salesTransferPricing: 'salestransferpricing',
  psgAnalystTransferPricing: 'psganalysttransferpricing',
  psgAdminTransferPricing: 'psgadmintransferpricing',
  psgAdmin: 'psgadmin'
};
