/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons/';
import { selectWorkingContainer } from '../../selectors';
import { getPlatformModule } from '../../services/platform/platform';
import designTokens from '../../styles/designTokens';

const widthDivider = 11;
const paddingPage = 18;
const sizeMenuInPercentage = Number(window.innerWidth) < 1280 ? 25 : 16.666667;
const sizeMenuInPixels = (Number(window.innerWidth) * sizeMenuInPercentage) / 100;
const minWidthPercentage = 35;

interface SplitViewProps {
  left: React.ReactElement;
  right: React.ReactElement;
  className?: string;
  tab?: string;
  handleIsExpanded?: (isExpanded: boolean) => void;
}

const useStyles = makeStyles(() => ({
  dividerHitbox: {
    position: 'relative',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.25rem',
    '&:hover': {
      '& span:nth-of-type(1)': {
        backgroundColor: designTokens.purpleLight2
      },
      '& div:nth-of-type(1)': {
        visibility: 'visible'
      }
    }
  },
  divider: {
    width: '0.188rem',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    backgroundColor: designTokens.neutral70
  },
  splitView: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  pane: {
    padding: '1em',
    borderRadius: '0.25em',
    backgroundColor: '#ffffff',
    height: '100%'
  },
  actionsBtns: {
    visibility: 'hidden',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  actionsBtnsExpanded: {
    width: '43px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: designTokens.white,
    border: `1px dashed ${designTokens.neutral90}`,
    height: '100%',
    cursor: 'auto'
  },
  rightIcon: {
    cursor: 'pointer',
    borderRadius: '50%',
    color: designTokens.core2,
    border: `1px solid ${designTokens.core2}`,
    backgroundColor: designTokens.neutral60,
    marginBottom: '0.5rem'
  },
  leftIcon: {
    cursor: 'pointer',
    borderRadius: '50%',
    color: designTokens.core2,
    border: `1px solid ${designTokens.core2}`,
    backgroundColor: designTokens.neutral60,
    transform: 'rotate(180deg)'
  }
}));

export const SplitView = ({ left, right, className, tab, handleIsExpanded }: SplitViewProps) => {
  const classes = useStyles();

  const [dragging, setDragging] = useState(false);
  const [expandRight, setExpandRight] = useState(false);
  const [expandLeft, setExpandLeft] = useState(false);
  const splitPaneRef = useRef<HTMLDivElement>(null);
  const leftPaneRef = useRef<HTMLDivElement>(null);
  const rightPaneRef = useRef<HTMLDivElement>(null);

  const isExpanded = expandRight || expandLeft;
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;

  useEffect(() => {
    onResize();
  }, [splitPaneRef, leftPaneRef, rightPaneRef]);

  const onMouseDown = () => {
    setDragging(true);
  };

  const onTouchStart = () => {
    setDragging(true);
  };

  const onMove = (clientX: number) => {
    const xPositionInComponent = clientX - sizeMenuInPixels;

    if (dragging && splitPaneRef.current && leftPaneRef.current && rightPaneRef.current) {
      const maxWidth = splitPaneRef.current.clientWidth;
      const minWidth = (maxWidth * minWidthPercentage) / 100;

      if (xPositionInComponent > minWidth && xPositionInComponent < maxWidth - minWidth) {
        leftPaneRef.current.style.width = `${xPositionInComponent - widthDivider / 2 - paddingPage}px`;
        rightPaneRef.current.style.width = `${maxWidth - xPositionInComponent + paddingPage}px`;
      }
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    onMove(e.clientX);
  };

  const onTouchMove = (e: TouchEvent) => {
    onMove(e.touches[0].clientX);
  };

  const onMouseUp = () => {
    setDragging(false);
  };

  const onResize = () => {
    if (splitPaneRef?.current && leftPaneRef?.current && rightPaneRef?.current) {
      const maxWidth = splitPaneRef.current.clientWidth;

      const widthValueWithDivider = (maxWidth - widthDivider) / 2;
      leftPaneRef.current.style.display = 'block';
      rightPaneRef.current.style.display = 'block';
      leftPaneRef.current.style.width = `${widthValueWithDivider}px`;
      rightPaneRef.current.style.width = `${widthValueWithDivider}px`;
    }
  };

  const logCollapseExpansionEvent = async (direction?: string) => {
    const { IdentityInfo } = await getPlatformModule();
    const session = await IdentityInfo.getIdentitySession();
    const { userUuid } = session;

    const payload = {
      event_category: 'Card Collapse/Expansion Event',
      event_label: direction ? `Expanded to the ${direction}` : 'Collapsed',
      tab,
      containerId,
      userUuid
    };

    ReactGA.event('fine_tuning_expand', payload);
  };

  const handleExpandViews = (direction: string) => {
    if (isExpanded) {
      setExpandLeft(false);
      setExpandRight(false);
      onResize();
      void logCollapseExpansionEvent();
      if (handleIsExpanded) {
        handleIsExpanded(false);
      }

      return;
    }

    if (handleIsExpanded) {
      handleIsExpanded(true);
    }

    setExpandRight(direction === 'right');
    setExpandLeft(direction === 'left');
    void logCollapseExpansionEvent(direction);

    if (leftPaneRef.current && splitPaneRef.current && rightPaneRef.current) {
      const maxWidth = splitPaneRef.current.clientWidth;
      const newDividerWidth = 43;
      const widthValueWithDivider = maxWidth - newDividerWidth;

      if (direction === 'right') {
        rightPaneRef.current.style.display = 'none';
        leftPaneRef.current.style.display = 'block';
        leftPaneRef.current.style.width = `${widthValueWithDivider}px`;
      }

      if (direction === 'left') {
        leftPaneRef.current.style.display = 'none';
        rightPaneRef.current.style.display = 'block';
        rightPaneRef.current.style.width = `${widthValueWithDivider}px`;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('mouseup', onMouseUp);
    window.addEventListener('resize', onResize);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('resize', onResize);
    };
  });

  return (
    <div ref={splitPaneRef} className={`${classes.splitView} ${className ?? ''}`}>
      <div ref={leftPaneRef} className={classes.pane}>
        {left}
      </div>
      <div
        className={classes.dividerHitbox}
        style={{ cursor: isExpanded ? 'auto' : 'col-resize' }}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onMouseUp}
      >
        {!isExpanded && <span className={classes.divider} />}

        <div className={isExpanded ? classes.actionsBtnsExpanded : classes.actionsBtns}>
          {!expandRight && (
            <PlayArrow
              className={classes.rightIcon}
              onClick={() => {
                handleExpandViews('right');
              }}
            />
          )}

          {!expandLeft && (
            <PlayArrow
              className={classes.leftIcon}
              onClick={() => {
                handleExpandViews('left');
              }}
            />
          )}
        </div>
      </div>

      <div ref={rightPaneRef} className={classes.pane}>
        {right}
      </div>
    </div>
  );
};
