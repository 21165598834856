import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, List, Typography, makeStyles } from '@material-ui/core';
import { AnalysisSideMenuItem } from './AnalysisSideMenuItem';
import { CompSearchAnimation } from '../../../components/CompSearchAnimation';
import { shouldOpenCSAnimation } from '../../../components/CompSearchAnimation/CompSearchAnimation.proptype';
import { clearLoadingStates } from '../../../redux/profitBasedAnalyses';
import { captionOverline } from '../../../styles/typography';
import { AnalysisSideMenuProps } from '../AnalysisSideMenu.proptype';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  list: {
    padding: theme.spacing(1, 2),
    '&:first-child': {
      flexGrow: 1
    }
  },
  sectionTitle: {
    ...captionOverline,
    padding: theme.spacing(1, 2, 0),
    textTransform: 'uppercase'
  }
}));

export const AnalysisSideMenu = ({
  sections,
  selectedPath,
  onNavigate,
  onRunSearch,
  currentCSStatus,
  setCurrentCSStatus,
  pbaJurisdictionInfo,
  navigateToAnalysis
}: AnalysisSideMenuProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Box className={classes.root}>
      {shouldOpenCSAnimation.includes(currentCSStatus) && (
        <CompSearchAnimation
          currentCSStatus={currentCSStatus}
          setCurrentCSStatus={setCurrentCSStatus}
          pbaJurisdictionInfo={pbaJurisdictionInfo}
          navigateToAnalysis={navigateToAnalysis}
        />
      )}
      {sections.map(({ title, items }, i) => (
        <Fragment key={title ?? i}>
          {i > 0 && <Divider />}
          {title && <Typography className={classes.sectionTitle}>{title}</Typography>}
          <List component="nav" className={classes.list}>
            {items.map((props) => (
              <AnalysisSideMenuItem
                key={props.text}
                {...props}
                selected={selectedPath.startsWith(props.pathMatch ?? props.path)}
                onClick={() => {
                  dispatch(clearLoadingStates());
                  // TODO: This will change once we are not deep linking to the legacy UI
                  if (!props.disabled && props.path !== '#') {
                    onNavigate(props.path);
                  }

                  if (props.path === '#' && onRunSearch) onRunSearch();
                }}
                onIconClick={() => {
                  if (props.iconPath) {
                    onNavigate(props.iconPath);
                  } else {
                    onNavigate(props.path);
                  }
                }}
              />
            ))}
          </List>
        </Fragment>
      ))}
    </Box>
  );
};
