import { createSelector } from 'reselect';
import { BackgroundInfoType } from '../models';
import { RootState } from '../redux';

type SelectorParams = { type: BackgroundInfoType; id: number };

const selectSliceByType = (type: BackgroundInfoType) => (state: RootState) =>
  state.statementOfFacts[type === BackgroundInfoType.Entity ? 'entities' : 'transactions'];

export const selectStatementOfFacts = ({ type, id }: SelectorParams) =>
  createSelector(selectSliceByType(type), ({ byId }) => byId?.[id]);

export const selectStatementOfFactsCompletion = (params: SelectorParams) =>
  createSelector(selectStatementOfFacts(params), (perJurisdiction) =>
    perJurisdiction
      ? Object.values(perJurisdiction)
          .flat()
          .map(({ subTopics }) => (subTopics ? subTopics.every(({ completed }) => completed) : false))
      : undefined
  );
