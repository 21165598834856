import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import designTokens from '../../../../../styles/designTokens';
import {
  body1,
  body2,
  body3,
  body4,
  buttonText1,
  buttonText2,
  captionOverline
} from '../../../../../styles/typography';
import { ComparableInformationProps } from '../FineTuning.proptype';

const useStyles = makeStyles(() => ({
  containerGrid: {
    marginBottom: '1.5rem'
  },
  rejectedWrapper: {
    ...captionOverline,
    color: designTokens.core1,
    textTransform: 'uppercase',
    backgroundColor: designTokens.neutral80,
    padding: '0.5rem',
    borderRadius: '4px',
    display: 'inline-block'
  },
  contentTextOne: {
    ...body1,
    marginTop: '0.2rem',
    marginBottom: '0.5rem'
  },
  contentTextTwo: {
    ...body3
  },
  titleTextOne: {
    ...body2
  },
  titleTextTwo: {
    ...body4
  },
  buttonMoreWrapper: {
    ...buttonText2
  },
  websiteTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  buttonLinkWrapper: {
    ...buttonText1,
    backgroundColor: designTokens.neutral70
  }
}));

export const SummaryTab = ({ company }: ComparableInformationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const maxLengthDescription = 150;
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [showMoreWebsite, setShowMoreWebsite] = useState(false);

  const handleOnChangeShowMoreDescription = () => {
    setShowMoreDescription(!showMoreDescription);
  };

  const handleOnChangeShowMoreWebsite = () => {
    setShowMoreWebsite(!showMoreWebsite);
  };

  const RejectionReason = ({ rejectionName, otherRejection }: { rejectionName: string; otherRejection: string }) => {
    if (rejectionName === 'rejection_type.other') {
      return (
        <Typography className={classes.contentTextOne}>
          {otherRejection ? otherRejection : t(`analysis:${rejectionName}`)}
        </Typography>
      );
    }

    return (
      <Typography className={classes.contentTextOne}>{rejectionName ? t(`analysis:${rejectionName}`) : ''}</Typography>
    );
  };

  return (
    <Box>
      {company?.isRejected === 1 && (
        <Grid container className={classes.containerGrid}>
          <Grid item xs={12}>
            <Typography className={classes.rejectedWrapper}>{t('analysis:title.rejected-reason')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <RejectionReason rejectionName={company.rejectionName} otherRejection={company.otherRejection} />
          </Grid>
        </Grid>
      )}
      {company?.primaryFunctionId === PrimaryFunctions.ByName.Distributor.Id && (
        <Grid container className={classes.containerGrid}>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-primary_function')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-country')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne}>{company?.primaryFunction}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne}>{company?.countryName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-function_performed')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-primary_sic_code')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne}>{company?.industryCodeValue}</Typography>
          </Grid>
        </Grid>
      )}
      {company?.primaryFunctionId === PrimaryFunctions.ByName.ServiceProvider.Id && (
        <Grid container className={classes.containerGrid}>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-major_classification')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-country')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne}>
              {company?.classifications
                .map((classification) => t(`analysis:${classification.majorClassificationName}`))
                .join(', ')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne}>{company?.countryName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-minor_classification')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.titleTextOne}>{t('analysis:title.summary-primary_sic_code')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne}>
              {company?.classifications.map((classification) =>
                classification.minorClassifications
                  .map((minor) => t(`analysis:${minor.minorClassificationName}`))
                  .join(', ')
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.contentTextOne}>{company?.industryCodeValue}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container className={classes.containerGrid}>
        <Grid item xs={12}>
          <Typography className={classes.titleTextOne}>
            {t('analysis:title.summary-description', {
              provider: company?.dataSourceName ? t(`analysis:${company?.dataSourceName}`) : ''
            })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.contentTextOne}>
            {company?.sourceDescription &&
            company?.sourceDescription.replace(/ /g, '').length > maxLengthDescription &&
            !showMoreDescription
              ? `${company?.sourceDescription.slice(0, maxLengthDescription)}...`
              : company?.sourceDescription}
          </Typography>
        </Grid>
        {company?.sourceDescription && company?.sourceDescription.replace(/ /g, '').length > maxLengthDescription && (
          <Grid item xs={12}>
            <Button onClick={handleOnChangeShowMoreDescription}>
              {t(`analysis:${showMoreDescription ? 'action-summary-show_less' : 'action-summary-show_more'}`)}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container className={classes.containerGrid}>
        <Grid item xs={12} className={classes.websiteTitleWrapper}>
          <Typography className={classes.titleTextOne}>{t('analysis:title.summary-website_information')}</Typography>
          <Button target="_blank" href={company?.url} startIcon={<OpenInNew />} className={classes.buttonLinkWrapper}>
            {t('analysis:button.summary-visit_website')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.contentTextOne}>
            {company?.xbsDescription &&
            company?.xbsDescription.replace(/ /g, '').length > maxLengthDescription &&
            !showMoreWebsite
              ? `${company?.xbsDescription.slice(0, maxLengthDescription)}...`
              : company?.xbsDescription}
          </Typography>
        </Grid>
        {company?.xbsDescription && company?.xbsDescription.replace(/ /g, '').length > maxLengthDescription && (
          <Grid item xs={12}>
            <Button onClick={handleOnChangeShowMoreWebsite}>
              {t(`analysis:${showMoreWebsite ? 'action-summary-show_less' : 'action-summary-show_more'}`)}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
