import { ComponentType, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { BackgroundInfoType } from '../../models';
import { fetchEntities } from '../../redux/entities';
import {
  copyStatementOfFacts,
  fetchStatementOfFacts,
  resetStatementOfFacts,
  saveStatementOfFacts,
  updateSubtopcDiscussionState
} from '../../redux/statementOfFacts';
import { selectCountries, selectEntitiesList, selectEntity, selectStatementOfFacts } from '../../selectors';
import { hasEditAccess } from '../../utils';
import { fetchStatementOfFactsCopySources } from '../../utils/statementOfFacts';
import { StatementOfFactsOverviewProps } from '../StatementOfFactsOverview';

const Connector = ({ component: Component }: { component: ComponentType<StatementOfFactsOverviewProps> }) => {
  const dispatch = useDispatch();
  const entityId = Number(useParams<{ entityId: string }>().entityId);
  const entities = useSelector(selectEntitiesList);
  const entity = useSelector(selectEntity(entityId));
  const statementOfFacts = useSelector(selectStatementOfFacts({ type: BackgroundInfoType.Entity, id: entityId }));
  const countries = useSelector(selectCountries);

  useEffect(() => {
    if (!entities) {
      dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (!statementOfFacts) {
      dispatch(fetchStatementOfFacts({ type: BackgroundInfoType.Entity, id: entityId }));
    }
  }, [dispatch, statementOfFacts, entityId]);

  const fetchCopySources = useCallback(
    async (jurisdiction: string) =>
      fetchStatementOfFactsCopySources({ type: BackgroundInfoType.Entity, id: entityId, jurisdiction, countries }),
    [entityId, countries]
  );

  return (
    <Component
      name={entity?.code ?? ''}
      namespace="entities"
      statementOfFacts={statementOfFacts}
      fetchCopySources={fetchCopySources}
      onAddSubtopic={(args) => {
        dispatch(saveStatementOfFacts({ type: BackgroundInfoType.Entity, id: entityId, ...args }));
      }}
      onCopySubtopics={(args) => {
        dispatch(copyStatementOfFacts({ type: BackgroundInfoType.Entity, id: entityId, ...args }));
      }}
      onResetSubtopics={({ jurisdiction }) => {
        dispatch(resetStatementOfFacts({ type: BackgroundInfoType.Entity, id: entityId, jurisdiction }));
      }}
      onSubmit={(args) => {
        if (!hasEditAccess()) return;
        dispatch(updateSubtopcDiscussionState({ type: BackgroundInfoType.Entity, id: entityId, ...args }));
        dispatch(saveStatementOfFacts({ type: BackgroundInfoType.Entity, id: entityId, ...args }));
      }}
    />
  );
};

export default Connector;
