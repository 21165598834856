import { Entity, FinancialConversion } from '../models';

export const getLegalEntityCurrencyConversionByTaxYear = (
  sourceOrDestinationEntity: Entity,
  year: number,
  conversionRates: FinancialConversion[]
) =>
  conversionRates.find(
    ({ legalEntity, taxYear }) => legalEntity.entityId === sourceOrDestinationEntity.entityId && taxYear === year
  );

// Calculate from MNE value to local currency scale
export const convertValueToLocalValue = (
  input: number,
  taxYear: number,
  conversionRates: FinancialConversion[],
  sourceOrDestinationEntity: Entity
) => {
  const currencyConversion = getLegalEntityCurrencyConversionByTaxYear(
    sourceOrDestinationEntity,
    taxYear,
    conversionRates
  );

  return currencyConversion ? Number((input / currencyConversion.conversionValue).toFixed(2)) : input;
};

export const convertLocalValueToValue = (
  input: number,
  taxYear: number,
  conversionRates: FinancialConversion[],
  sourceOrDestinationEntity: Entity
) => {
  const currencyConversion = getLegalEntityCurrencyConversionByTaxYear(
    sourceOrDestinationEntity,
    taxYear,
    conversionRates
  );

  return currencyConversion ? Number((input * currencyConversion.conversionValue).toFixed(2)) : input;
};
