import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Typography, Select, makeStyles } from '@material-ui/core';
import { FinancialTableHeaderProps } from '../FinancialTable.proptype';

const useStyles = makeStyles((theme) => ({
  title: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  headerInfo: {
    display: 'flex'
  },
  infoBlock: {
    margin: '0 1rem'
  },
  infoLabel: {
    color: 'grey'
  },
  currencySelector: {
    margin: '0 0.5em',
    paddingLeft: '0.5em'
  },
  buttonContainer: {
    flex: 1,
    textAlign: 'right'
  }
}));

export const FinancialTableHeader = ({
  title,
  headerInfo,
  handleChangeCurrency,
  currencyOptions
}: FinancialTableHeaderProps) => {
  const { t } = useTranslation();
  const [currencyIndex, setCurrencyIndex] = useState(0);
  const classes = useStyles();

  function onCurrencyChange(index: any) {
    handleChangeCurrency(index.props.value);
    setCurrencyIndex(index.props.value);
  }

  return (
    <Box className={classes.title}>
      <Typography variant="h2">{title}</Typography>
      <span className={classes.headerInfo}>
        {headerInfo.map((info) => {
          return (
            <span key={info.label} className={classes.infoBlock}>
              <div className={classes.infoLabel}>{info.label}</div>
              <div>{info.value}</div>
            </span>
          );
        })}
      </span>
      <Box className={classes.buttonContainer}>
        <Select
          disableUnderline
          className={classes.currencySelector}
          value={currencyIndex}
          onChange={(_, i) => {
            onCurrencyChange(i);
          }}
        >
          {currencyOptions.map((currency, i) => (
            <MenuItem key={currency.currencyId} value={i}>
              {t('label-name-and-code', currency)}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};
