import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { UPESplashImage } from '../../../svgs';
import { InformationBox } from '../../InformationBox';
import { EntitiesEventsProps } from '../Entities.proptype';

export const EntitiesSplashScreen = ({ onAddUPE }: Pick<EntitiesEventsProps, 'onAddUPE'>) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" height={1} justifyContent="center" alignItems="center" bgcolor="secondary.light">
      <InformationBox
        image={<UPESplashImage />}
        title={t('entities:title-info-box')}
        subTitle={t('link-entities')}
        text={t('entities:message-info-box')}
        footerButtons={[
          {
            caption: t('entities:action-add-upe'),
            onClick: () => {
              onAddUPE();
            }
          }
        ]}
      />
    </Box>
  );
};
