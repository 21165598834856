import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { currentTBA, fetchCurrentTBA, fetchTBAs } from '../../redux/transactionBasedAnalyses';
import { selectTBA, selectTBAsList } from '../../selectors';

interface ConnectorProps {
  component: any;
}

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch();
  const tbaId = Number(useParams<{ tbaId: string }>().tbaId);
  const studyId = Number(useParams<{ studyId: string }>().studyId);
  const tbas = useSelector(selectTBAsList);
  const tba = useSelector(selectTBA(tbaId));

  useEffect(() => {
    if (tbas === null) {
      dispatch(fetchTBAs(studyId));
    }
  }, [dispatch, tbas, tba, studyId]);

  useEffect(() => {
    if (tba) dispatch(currentTBA(tba));
    if (tba?.tbaId) dispatch(fetchCurrentTBA(tba?.tbaId));
  }, [dispatch, tba]);

  return <Component />;
};

export default Connector;
