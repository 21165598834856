import { useState } from 'react';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { IconSortDown } from '../../svgs';

export type SortOrder = 'asc' | 'desc';

export interface SortControlProps {
  field: string;
  onSortClicked: (sortOrder: SortOrder) => void;
}

const useStyles = makeStyles(() => ({
  flexField: {
    display: 'flex'
  },
  iconDisplay: {
    margin: 'auto 0.5rem'
  },
  flip: {
    transform: 'rotate(180deg)'
  }
}));

export const SortControl = ({ field, onSortClicked }: SortControlProps) => {
  const classes = useStyles();
  const [sortOrderAsc, setSortOrderAsc] = useState(true);

  return (
    <div key={`${field}-input-field`} className={classes.flexField}>
      <span>{field}</span>
      <InputAdornment
        position="end"
        className={classes.iconDisplay}
        onClick={() => {
          setSortOrderAsc((isAsc) => !isAsc);
          onSortClicked(sortOrderAsc ? 'desc' : 'asc');
        }}
      >
        <IconSortDown className={`${sortOrderAsc ? '' : classes.flip}`} />
      </InputAdornment>
    </div>
  );
};
