import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles, Paper } from '@material-ui/core';
import { ComparabilityTabHeader } from './ComparabilityTabHeader';
import { ComparabilityTabTable } from './ComparabilityTabTable';
import {
  ComparabileAnalysisTableTransactions,
  ComparabilityTabProps,
  statusIdMap
} from './TbaComparableAnalysis.proptype';
import { FetchLoadingStateEnum } from '../../constants';
import {
  selectCurrentUncontrolledTransactionComparability,
  selectUncontrolledTransactions,
  selectComparabilityOptions,
  selectWorkingContainer,
  selectTBALoadingState
} from '../../selectors';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import {
  StatusTypes,
  TbaUncontrolledTransactionComparability,
  UncontrolledTransactionComparabilities
} from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.375em',
    backgroundColor: theme.palette.common.white,
    overflow: 'auto'
  }
}));

export const ComparabilityTab = ({
  selectedTba,
  updateComparabilityApiCall,
  updateUncontrolledTransactionComparibility,
  bulkUpdateComparabilityApiCall,
  bulkUpdateSimilaritiesApiCall,
  bulkUpdateEvaluationsApiCall
}: ComparabilityTabProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const uncontrolledTransactions = useSelector(selectUncontrolledTransactions);
  const comparabilityOptions = useSelector(selectComparabilityOptions) ?? [];
  const workingContainer = useSelector(selectWorkingContainer);
  const [isUcTransactionStatusUpdating, setIsUcTransactionStatusUpdating] = useState(false);
  const loadingState = useSelector(selectTBALoadingState);
  const tbaUpdated = loadingState.fetchTbaRangeType === FetchLoadingStateEnum.fulfilled;

  useEffect(() => {
    if (tbaUpdated) {
      setIsUcTransactionStatusUpdating(false);
    }
  }, [tbaUpdated, setIsUcTransactionStatusUpdating]);
  const [statusToDisplay, setStatusToDisplay] = useState<StatusTypes>('total');

  const [selectedUncontrolledTransactionIds, setSelectedUncontrolledTransactionIds] = useState<number[]>([]);

  const currentUncontrolledTransactionComparabilities =
    useSelector(selectCurrentUncontrolledTransactionComparability) ?? [];
  const uncontrolledTransactionsBySelectedStatus: TbaUncontrolledTransactionComparability[] = currentUncontrolledTransactionComparabilities.filter(
    (aTransaction) => statusToDisplay === 'total' || aTransaction.status === statusIdMap[statusToDisplay]
  );

  const selectUncontrolledTransaction = (currentIndex: number, value: number) => {
    const updateCheckedList = [...selectedUncontrolledTransactionIds];
    if (currentIndex >= 0) {
      updateCheckedList.splice(currentIndex, 1);
    } else {
      updateCheckedList.push(value);
      /* eslint-disable camelcase */
      void logGoogleAnalyticsEvent({
        event_category: 'tba_button_click',
        event_label: `Comparability Bulk Edit click`,
        container_id: workingContainer?.containerId
      });
    }

    setSelectedUncontrolledTransactionIds(updateCheckedList);
  };

  const updateComparability = async (transactionComparabilityId: number, status: number) => {
    if (selectedTba) {
      updateComparabilityApiCall(transactionComparabilityId, status, Number(selectedTba.tbaId));
    }
  };

  const bulkUpdateComparabilityStatus = async (selectedUncontrolledTransactionIds: number[], status: number) => {
    if (selectedTba) {
      setIsUcTransactionStatusUpdating(true);
      bulkUpdateComparabilityApiCall(selectedUncontrolledTransactionIds, status, Number(selectedTba.tbaId));
    }
  };

  const bulkUpdateSimilarities = async (
    selectedUncontrolledTransactionIds: number[],
    economicAnalysisSimilarity: number,
    functionalAnalysisSimilarity: number,
    contractualTermsSimilarity: number
  ) => {
    if (selectedTba) {
      bulkUpdateSimilaritiesApiCall({
        tbaId: selectedTba.tbaId,
        uncontrolledTransactionIds: selectedUncontrolledTransactionIds,
        economicAnalysisSimilarity,
        functionalAnalysisSimilarity,
        contractualTermsSimilarity
      });
      unselectAll();
    }
  };

  const bulkUpdateEvaluations = async (
    selectedUncontrolledTransactionIds: number[],
    comparabilityEvaluation: string
  ) => {
    if (selectedTba) {
      bulkUpdateEvaluationsApiCall({
        tbaId: selectedTba.tbaId,
        uncontrolledTransactionIds: selectedUncontrolledTransactionIds,
        comparabilityEvaluation
      });
      unselectAll();
    }
  };

  const onApplyDescription = (selectedTransactionToUpdate: ComparabileAnalysisTableTransactions) => {
    const uncontrolledCOmparabilityPayload: ComparabileAnalysisTableTransactions = {
      comparabilityEvaluation: selectedTransactionToUpdate.comparabilityEvaluation,
      contractualTermsSimilarity: Number(selectedTransactionToUpdate.contractualTermsSimilarity),
      economicAnalysisSimilarity: Number(selectedTransactionToUpdate.economicAnalysisSimilarity),
      functionalAnalysisSimilarity: Number(selectedTransactionToUpdate.functionalAnalysisSimilarity),
      status: selectedTransactionToUpdate.status,
      transactionComparabilityId: Number(selectedTransactionToUpdate.transactionComparabilityId),
      tba: selectedTba
    };
    updateUncontrolledTransactionComparibility(uncontrolledCOmparabilityPayload);
  };

  const numberOfRejectedTransactions = currentUncontrolledTransactionComparabilities.filter(
    (uncontrolledTransaction) => uncontrolledTransaction.status === UncontrolledTransactionComparabilities.Rejected
  ).length;
  const numberOfAcceptedTransactions = currentUncontrolledTransactionComparabilities.filter(
    (uncontrolledTransaction) => uncontrolledTransaction.status === UncontrolledTransactionComparabilities.Accepted
  ).length;

  const chips = [
    {
      amount: currentUncontrolledTransactionComparabilities.length,
      disabled: false,
      isSelected: statusToDisplay === 'total',
      key: 'total' as StatusTypes,
      label: t('analysis:comparable-range-total')
    },
    {
      amount: numberOfRejectedTransactions,
      disabled: numberOfRejectedTransactions === 0,
      isSelected: statusToDisplay === 'rejected',
      key: 'rejected' as StatusTypes,
      label: t('analysis:comparable-range-rejected')
    },
    {
      amount: numberOfAcceptedTransactions,
      disabled: numberOfAcceptedTransactions === 0,
      isSelected: statusToDisplay === 'accepted',
      key: 'accepted' as StatusTypes,
      label: t('analysis:comparability-range-accepted')
    }
  ];

  const filteredTransactions: any[] = uncontrolledTransactionsBySelectedStatus.map((comp) => {
    const uncontrolledTransaction = uncontrolledTransactions.find(
      (trans) => trans.transactionId === comp.uncontrolledTransaction.transactionId
    );

    return {
      transactionId: uncontrolledTransaction?.transactionId,
      entityName: `${
        uncontrolledTransaction?.ucSourceLegalEntity?.name
          ? String(uncontrolledTransaction?.ucSourceLegalEntity?.name)
          : String(uncontrolledTransaction?.sourceLegalEntity?.name)
      } → ${
        uncontrolledTransaction?.ucDestinationLegalEntity?.name
          ? String(uncontrolledTransaction?.ucDestinationLegalEntity?.name)
          : String(uncontrolledTransaction?.destinationLegalEntity?.name)
      }`,
      status: comp.status ?? 0,
      backgroundInformation: comparabilityOptions[comp.economicAnalysisSimilarity]?.name,
      functionalAnalysis: comparabilityOptions[comp.functionalAnalysisSimilarity]?.name,
      contractualTerms: comparabilityOptions[comp.contractualTermsSimilarity]?.name,
      identifier: uncontrolledTransaction?.identifier,
      transactionComparabilityId: comp.transactionComparabilityId,
      comparabilityEvaluation: comp.comparabilityEvaluation ?? '',
      economicAnalysisSimilarity: comparabilityOptions[comp.economicAnalysisSimilarity]?.id,
      functionalAnalysisSimilarity: comparabilityOptions[comp.functionalAnalysisSimilarity]?.id,
      contractualTermsSimilarity: comparabilityOptions[comp.contractualTermsSimilarity]?.id
    };
  });

  const filterByStatus = (status: StatusTypes) => {
    setStatusToDisplay(status);
  };

  const unselectAll = () => {
    setSelectedUncontrolledTransactionIds([]);
  };

  return (
    <Paper className={classes.pageWrapper}>
      <ComparabilityTabHeader
        isUcTransactionStatusUpdating={isUcTransactionStatusUpdating}
        setIsUcTransactionStatusUpdating={setIsUcTransactionStatusUpdating}
        chips={chips}
        filterByStatus={filterByStatus}
        selectedUncontrolledTransactionIds={selectedUncontrolledTransactionIds}
        unselectAll={unselectAll}
        bulkUpdateComparabilityStatus={bulkUpdateComparabilityStatus}
        bulkUpdateSimilarities={bulkUpdateSimilarities}
        bulkUpdateEvaluations={bulkUpdateEvaluations}
      />
      <ComparabilityTabTable
        isUcTransactionStatusUpdating={isUcTransactionStatusUpdating}
        setIsUcTransactionStatusUpdating={setIsUcTransactionStatusUpdating}
        uncontrolledTransactions={filteredTransactions}
        updateComparability={updateComparability}
        selectedUncontrolledTransactionIds={selectedUncontrolledTransactionIds}
        selectUncontrolledTransaction={selectUncontrolledTransaction}
        selectAllUncontrolledTransactions={setSelectedUncontrolledTransactionIds}
        onApplyDescription={onApplyDescription}
      />
    </Paper>
  );
};
