import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { ContainerModalViewKeys, CopyContainerModalContainerProps } from './CopyContainerModal.proptypes';
import tokens from '../../styles/designTokens';
import { mediumFont } from '../../styles/fonts';
import { buttonText2 } from '../../styles/typography';
import CopyContainerModalIcon from '../../svgs/CopyContainerModalIcon';

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 2)
  },
  closeIcon: {
    marginLeft: 'auto'
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },
  content: {
    backgroundColor: tokens.neutral70,
    height: '100%',
    overflow: 'hidden',
    padding: '1.43rem 4.75rem 2.37rem 4.75rem',
    display: 'flex',
    justifyContent: 'center'
  },
  titleLabel: {
    ...mediumFont,
    fontSize: '1.125rem'
  },
  iconWrapper: {
    backgroundColor: tokens.product15,
    color: tokens.product100,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    height: '2.25rem',
    width: '2.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  modalFooter: {
    marginTop: 'auto',
    padding: theme.spacing(1, 4),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between'
  },
  cancelButton: {
    marginLeft: 'auto',
    border: '1px solid',
    borderColor: tokens.product100,
    borderRadius: '0.25rem',
    ...buttonText2,
    color: tokens.product100
  },
  backButton: {
    width: '7rem',
    ...buttonText2
  },
  viewHistory: {
    marginLeft: 'auto',
    marginRight: '1rem',
    border: '1px solid',
    borderColor: tokens.product100,
    borderRadius: '0.25rem',
    ...buttonText2
  }
}));

export const CopyContainerModalContainer = ({
  container,
  onClose,
  view,
  setView,
  setBackTo,
  backTo
}: CopyContainerModalContainerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isHistorical = view.key === ContainerModalViewKeys.Historical;
  const isHome = view.key === ContainerModalViewKeys.Home;

  return (
    <Box className={classes.contentRoot}>
      <Box className={classes.modalHeader}>
        <Box className={classes.titleContainer}>
          <Box className={classes.iconWrapper}>
            <CopyContainerModalIcon />
          </Box>
          <span className={classes.titleLabel}>
            {container?.name} {container?.taxYear}
          </span>
        </Box>

        <Box>
          <Button
            data-testid="view-history-btn"
            className={classes.viewHistory}
            onClick={() => {
              const nextView = isHistorical ? backTo : ContainerModalViewKeys.Historical;

              const previousView =
                nextView === ContainerModalViewKeys.Historical ? view.key : ContainerModalViewKeys.Home;

              setBackTo(previousView);

              if (nextView) {
                setView(nextView);
              }
            }}
          >
            {view.key === ContainerModalViewKeys.Historical ? t('common:copy-options') : t('common:view-history')}
          </Button>
          <IconButton data-testid="x-btn" className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Box className={classes.content}>{view.component}</Box>

      <Box className={classes.modalFooter}>
        {backTo && !isHistorical && !isHome ? (
          <Button
            color="primary"
            variant="contained"
            className={classes.backButton}
            onClick={() => {
              setView(backTo);
              setBackTo(null);
            }}
          >
            {t('common:action-back')}
          </Button>
        ) : null}
        <Button key="name" className={classes.cancelButton} onClick={onClose}>
          {t('common:action-cancel')}
        </Button>
      </Box>
    </Box>
  );
};
