import { TFunction } from 'i18next';
import { GlobalGuide } from '../../models';

interface TableData {
  id: number;
  country: string;
  value?: string;
}

export const formatTableData = (data: GlobalGuide[] | null, reviewType: string): TableData[] =>
  Array.isArray(data)
    ? data.map((countryData) => ({
        id: countryData.countryId,
        country: countryData.name,
        value: countryData.wtpData.find((wtpData) => wtpData.wtpReviewType.name === reviewType)?.value
      }))
    : [];

export const dataFromTableData = (data: GlobalGuide[] | null, tableData: TableData) =>
  (data ?? []).find(({ countryId }) => countryId === tableData.id);

export const getMenuItems = (data: GlobalGuide[] | null, t: TFunction) =>
  Array.isArray(data) && data[0]
    ? data[0].wtpData
        .map((wtpData) => ({
          value: wtpData.wtpReviewType.name,
          text: t(`globalguide:wtp-review-type-${wtpData.wtpReviewType.name.toLowerCase().replace(/ /g, '-')}`)
        }))
        .sort((previousMenuItem, currentMenuItem) => {
          return previousMenuItem.value < currentMenuItem.value
            ? -1
            : previousMenuItem.value > currentMenuItem.value
            ? 1
            : 0;
        })
    : [];
