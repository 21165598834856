import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles, Button, Box } from '@material-ui/core';
import clsx from 'clsx';
import { TbaAnalysisHeaderProps } from './TbaAnalysisHeader.proptype';
import { TbaAnalysisIcon } from '../../svgs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0),
    minHeight: '100%',
    '& > :nth-child(2)': {
      '& .Mui-selected': {
        color: theme.palette.primary.dark
      },
      '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.primary.dark
      }
    }
  },
  contentHeader: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '3.4rem',
    padding: theme.spacing(0, 2)
  },
  contentHeaderBorder: {
    backgroundColor: theme.palette.background.default,
    marginTop: 0,
    marginBottom: 0,
    fontSize: '1rem'
  },
  headerTexts: {
    gap: '2rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  contentHeaderColor: {
    color: theme.palette.secondary.dark,
    fontWeight: 500
  },
  contentHeaderPrimary: {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 600,
    gap: theme.spacing(0.5)
  },
  TbaTitle: {
    color: theme.palette.primary.dark
  },
  TbaAnalysisIcon: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    borderRadius: '3.75rem',
    padding: '0.375rem'
  },
  TbaName: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    padding: '0.375rem',
    borderRadius: '0.25rem'
  },
  TbaTransactionTypeName: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.secondary.light,
    padding: '0.375rem',
    borderRadius: '0.25rem'
  }
}));

export const TbaAnalysisHeader = ({ selectedTba, workingContainer, onHandleClose }: TbaAnalysisHeaderProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onHandleActionClose = useCallback(() => {
    onHandleClose();
  }, [onHandleClose]);

  return (
    <Box>
      <Box className={classes.contentHeader}>
        <Box className={classes.headerTexts}>
          <Typography key={1} variant="h2" className={clsx(classes.contentHeaderBorder, classes.contentHeaderPrimary)}>
            <TbaAnalysisIcon className={classes.TbaAnalysisIcon} />
            <Box component="span" className={classes.TbaTitle}>
              {t('analysis:title-transaction-based-analysis')}
            </Box>
          </Typography>
          <Typography key={2} variant="subtitle1" className={clsx(classes.TbaTransactionTypeName, classes.TbaName)}>
            <Box component="span">{selectedTba?.name}</Box>
          </Typography>
          <Typography key={3} variant="subtitle1" className={clsx(classes.contentHeaderBorder, classes.TbaName)}>
            <Box component="span">{selectedTba?.transactionType?.name}</Box>
          </Typography>
          <Typography key={4} variant="subtitle2">
            <Box component="span">
              {workingContainer?.name} {workingContainer?.taxYear}
            </Box>
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" color="primary" onClick={onHandleActionClose}>
            {t('analysis:action-close-analysis')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
