import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Entities as Component } from './components';
import Connector from './connector';
import { EntityDashboard } from '../EntityDashboard';
import { EntityDetails, EntityDetailsTab } from '../EntityDetails';
import RouteOptions from '../Router/RouteOptions';

const ChildRoutes = () => {
  const { path } = useRouteMatch();
  const entityId = Number(useParams<{ entityId: string }>().entityId);
  return (
    <Switch>
      <Route path={`${path}/dashboard`}>
        <EntityDashboard entityId={entityId} />
      </Route>
      <Route path={`${path}/details`}>
        <Switch>
          {Object.values(EntityDetailsTab).map((tab) => (
            <Route key={tab} path={`${path}/details/${tab}`}>
              <EntityDetails entityId={entityId} tab={tab} />
            </Route>
          ))}
          <Redirect to={`${path}/details/${EntityDetailsTab.StatementOfFacts}`} />
        </Switch>
      </Route>
      <Redirect to={`${path}/dashboard`} />
    </Switch>
  );
};

export const Entities = () => (
  <RouteOptions
    RootRoute={<Connector component={Component} />}
    ChildRoutes={[
      { path: '/orgchart', component: <Connector component={Component} /> },
      { path: '/:entityId', component: <ChildRoutes /> }
    ]}
  />
);
