import { Container, ProfitBasedAnalysis, TransactionBasedAnalysis } from '../../models';
import { entities } from '../Entities/Entities.testdata';

export const container: Container = {
  containerId: 3543,
  containerUuid: 'dsavghjbdknl-fsdafsd-fsadf',
  name: "D. Paxton-Lawrence's Playground",
  taxYear: 2020,
  isService: true,
  isPlaceholder: false,
  tenantId: 809
};

export const pba: ProfitBasedAnalysis = {
  pbaId: 9789,
  name: 'US to Germany IT Services',
  exclude: false,
  isSourceComplete: false,
  isDestinationComplete: false,
  description: 'IT Services',
  method: 0,
  testedParty: {
    testedPartyId: 9559,
    name: 'USA',
    segmentType: 1,
    justification: '<p>IT Services</p>'
  },
  primaryLegalEntity: entities[0]
};

export const tba: TransactionBasedAnalysis = {
  tbaId: 3310,
  name: 'Abcd test 123 456',
  exclude: false,
  isSourceComplete: false,
  isDestinationComplete: false,
  description: '',
  primaryLegalEntity: entities[0],
  secondaryLegalEntity: null,
  transactionType: {
    transactionTypeId: 1,
    name: 'Tangible Goods'
  },
  tbaEvaluationMethod: {
    tbaEvaluationMethodId: 1,
    name: 'CUT/CUP'
  },
  study: {
    studyId: 10368,
    name: '2018 - US',
    description: '',
    primaryEntity: entities[0]
  },
  uncontrolledTransactions: []
};
