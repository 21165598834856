import { FinancialData, FinancialInfo, FinancialCompletionStatus, HttpRequestStatus } from '../../models';

export const SET_ENTITYFINANCIAL_DATA = 'SET_ENTITYFINANCIAL_DATA';
export const SET_ENTITYFINANCIAL_REQUEST_STATUS = 'SET_ENTITYFINANCIAL_REQUEST_STATUS';
export const SET_ENTITY_FINANCIAL_COMPLETION_STATUS = 'GET_ENTITY_FINANCIAL_COMPLETION_STATUS';
export const SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS = 'GET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS';
export const SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS = 'SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS';
export const SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS =
  'SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS';
export const SET_ENTITY_ADDITIONAL_FINANCIAL_INFO = 'SET_ENTITY_ADDITIONAL_FINANCIAL_INFO';
export const RESET_ENTITY_FINANCIAL_STATUS = 'RESET_ENTITY_FINANCIAL_STATUS';

export interface SetEntityFinancialDataAction {
  type: typeof SET_ENTITYFINANCIAL_DATA;
  payload: {
    data: FinancialData[];
    financialAreaId: number;
  };
}

export interface SetEntityFinancialRequestStatus {
  type: typeof SET_ENTITYFINANCIAL_REQUEST_STATUS;
  payload: {
    status: HttpRequestStatus;
    error: unknown;
    financialAreaId: number;
  };
}

export interface SetEntityFinancialCompletionStatusAction {
  type: typeof SET_ENTITY_FINANCIAL_COMPLETION_STATUS;
  payload: {
    data: FinancialCompletionStatus[];
    financialAreaId: number;
  };
}

export interface SetEntityFinancialCompletionRequestStatus {
  type: typeof SET_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS;
  payload: {
    status: HttpRequestStatus;
    error: unknown;
    financialAreaId: number;
  };
}

export interface SetUpdateEntityFinancialCompletionStatusAction {
  type: typeof SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_STATUS;
  payload: {
    data: FinancialCompletionStatus;
    financialAreaId: number;
  };
}

export interface SetEntityAdditionalFinancialInfoAction {
  type: typeof SET_ENTITY_ADDITIONAL_FINANCIAL_INFO;
  payload: {
    data: string;
    financialAreaId: number;
  };
}

export interface SetUpdateEntityFinancialCompletionRequestStatus {
  type: typeof SET_UPDATE_ENTITY_FINANCIAL_COMPLETION_REQUEST_STATUS;
  payload: {
    status: HttpRequestStatus;
    error: unknown;
    financialAreaId: number;
  };
}

export interface ResetEntityFinancialStatus {
  type: typeof RESET_ENTITY_FINANCIAL_STATUS;
  payload: {
    financialAreaId: number;
  };
}

export type EntityFinancialActions =
  | SetEntityFinancialDataAction
  | SetEntityFinancialRequestStatus
  | SetEntityFinancialCompletionStatusAction
  | SetEntityFinancialCompletionRequestStatus
  | SetUpdateEntityFinancialCompletionStatusAction
  | SetUpdateEntityFinancialCompletionRequestStatus
  | SetEntityAdditionalFinancialInfoAction
  | ResetEntityFinancialStatus;

export interface FinancialState {
  data: FinancialData[];
  completionStatus: FinancialCompletionStatus[];
  additionalFinancialInfo: string;
  status: string;
  error: unknown | null;
}

export interface EntityFinancialStateInterface {
  incomeStatement: FinancialState;
  balanceSheet: FinancialState;
}

export interface GetEntityFinancialDataType extends FinancialInfo {
  entityId: number;
  financialAreaId: number;
}

export interface UpdateEntityFinancialDataType extends GetEntityFinancialDataType {
  financialData: FinancialData[];
}
