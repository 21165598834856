import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { TbaComparableAnalysisProps } from './TbaComparableAnalysis.proptype';
import { TbaComparableAnalysisHeader } from './TbaComparableAnalysisHeader';
import { ArrowNavigationTabsBar } from '../../components/ArrowNavigationTabsBar/ArrowNavigationTabsBar';
import { fetchTbaRangeResults } from '../../redux/transactionBasedAnalyses';
import { selectTbaRangeResults } from '../../selectors';
import designTokens from '../../styles/designTokens';
import { captionOverline } from '../../styles/typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  list: {
    padding: theme.spacing(1, 2),
    '&:first-child': {
      flexGrow: 1
    }
  },
  sectionTitle: {
    ...captionOverline,
    padding: theme.spacing(1, 2, 0),
    textTransform: 'uppercase'
  },

  pageWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  panelWrapper: {
    height: 'calc(100% - 2.75rem - 2.75rem)',
    backgroundColor: designTokens.neutral70,
    padding: '1.125rem'
  },
  bottomHeader: {
    display: 'flex',
    height: '2.75rem'
  },
  bottomLeftHeader: {
    display: 'flex'
  },
  bottomRightHeader: {
    borderTop: '1px solid ' + theme.palette.divider,
    borderBottom: '1px solid ' + theme.palette.divider,
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1
  },
  pliInfoText: {
    ...captionOverline,
    display: 'flex',
    textTransform: 'uppercase',
    margin: 'auto',
    marginRight: '.5em',
    color: designTokens.core2
  }
}));

export const TbaComparableAnalysis = ({ selectedTba, tabs }: TbaComparableAnalysisProps) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [selectedKey, setSelectedKey] = useState(tabs[0].key);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const tbaRangeResults = useSelector(selectTbaRangeResults);
  useEffect(() => {
    if (selectedTba && !Array.isArray(tbaRangeResults)) {
      void dispatch(fetchTbaRangeResults(selectedTba?.tbaId));
    }
  }, [dispatch, selectedTba, tbaRangeResults]);
  const onSelectTab = (newKey: string) => {
    if (newKey === selectedKey) {
      return;
    }

    setSelectedKey(newKey);
    const newTab = tabs.find((tab) => tab.key === newKey);
    if (newTab) {
      setSelectedTab(newTab);
    }
  };

  return (
    <div className={classes.pageWrapper}>
      <div>
        <TbaComparableAnalysisHeader selectedTba={selectedTba} tbaRangeResults={tbaRangeResults} />
        <span className={classes.bottomHeader}>
          <span className={classes.bottomLeftHeader}>
            <ArrowNavigationTabsBar
              tabs={tabs.map((tab) => ({
                key: tab.key,
                label: tab.label,
                disabled: tab.disabled,
                onTabClick: onSelectTab
              }))}
              selectedTab={selectedKey}
              selectedTabColor={designTokens.product15}
            />
          </span>
          <span className={classes.bottomRightHeader}>&nbsp;</span>
        </span>
      </div>
      <div className={classes.panelWrapper}>{selectedTab?.content}</div>
    </div>
  );
};
