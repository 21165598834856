import { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { SummaryTileProps } from './SummaryTile.proptype';
import tokens from '../../styles/designTokens';

export const SummaryTile = ({ data, altWidths }: SummaryTileProps) => {
  interface TileProps {
    length: number;
  }

  const useStyles = makeStyles<Theme, TileProps>((theme) => ({
    tile: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1rem',
      backgroundColor: theme.palette.common.white,
      alignItems: 'center',
      height: '4.5rem',
      boxShadow: '0 0 3px 0 rgba(0,0,0,0.12);',
      borderRadius: '4px',
      '& .lightGreyTone': {
        color: tokens.core2
      },

      '& span:last-child': {
        display: 'flex',
        justifyContent: 'right'
      }
    },
    tileContent: {
      margin: '0 0.5rem',
      width: '100%'
    }
  }));

  const classes = useStyles({ length: data.length });

  return (
    <span className={classes.tile} role="summarytile">
      {data.map((d: ReactElement, index: number) => {
        return (
          <span
            key={data[index].key}
            className={classes.tileContent}
            style={{ width: `${altWidths?.find((alt) => alt.index === index)?.width ?? 'default'}` }}
          >
            {d}
          </span>
        );
      })}
    </span>
  );
};
