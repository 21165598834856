import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { AnalysisSplashImage } from '../../../svgs';
import { InformationBox } from '../../InformationBox';
import { AnalysisSplashScreenProps } from '../Analysis.proptype';

export const AnalysesSplashScreen = ({ onAddSingle }: AnalysisSplashScreenProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" height={1} justifyContent="center" alignItems="center" bgcolor="secondary.light">
      <InformationBox
        image={<AnalysisSplashImage />}
        title={t('analysis:info-box-title')}
        subTitle={t('analysis:title')}
        text={t('analysis:info-box-text')}
        footerButtons={[
          {
            caption: t('analysis:action-new'),
            onClick: onAddSingle
          }
        ]}
      />
    </Box>
  );
};
