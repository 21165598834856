import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select, Typography, makeStyles, FormControl } from '@material-ui/core';
import { Editor } from '../../../components';
import useTrackingTime, { TimeTrackingEvents } from '../hooks/useTrackingTime';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  description: {
    flexShrink: 0,
    margin: theme.spacing(1, 0),
    minHeight: theme.spacing(6)
  },
  evaluationType: {
    margin: theme.spacing(0, 0, 1)
  }
}));

interface EntityDiscussionProps {
  title: string;
  methodName: string;
  used: boolean;
  discussion: string;
  evaluationTypes: Array<{ value: number; labelKey: string }>;
  evaluationType?: number;
  disabled?: boolean;
  notApplicable?: boolean;
  onChange: (props: { discussion: string; evaluationType?: number }) => void;
  saveChange: (event: string) => void;
}

export const EntityDiscussion = ({
  title,
  methodName,
  discussion,
  evaluationType,
  evaluationTypes,
  used,
  disabled,
  notApplicable,
  onChange,
  saveChange
}: EntityDiscussionProps) => {
  const { startTime, resetTime } = useTrackingTime(TimeTrackingEvents.JurisdictionAnalysisTextArea);
  const { t } = useTranslation();
  const classes = useStyles();
  const [evaluationTypeValue, setEvaluationTypeValue] = useState(0);

  useEffect(() => {
    saveChange('save dropdown value change');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationTypeValue]);

  return (
    <Box className={classes.root}>
      <Typography variant="h3">{title}</Typography>
      <Typography className={classes.description}>
        {t(
          notApplicable
            ? 'transactions:message-method-not-applicable'
            : used
            ? 'transactions:message-method-used-discussion'
            : 'transactions:message-method-not-used-discussion',
          {
            methodName
          }
        )}
      </Typography>
      {used && (
        <FormControl size="small" variant="outlined" className={classes.evaluationType}>
          <Select
            value={evaluationType}
            disabled={notApplicable! || disabled}
            onChange={({ target: { value } }) => {
              const newValue = Number(value);
              if (newValue !== evaluationType) {
                onChange({ discussion, evaluationType: newValue });
                setEvaluationTypeValue(newValue);
              }
            }}
          >
            {evaluationTypes.map(({ value, labelKey }) => (
              <MenuItem key={value} value={value}>
                {t(labelKey)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Editor
        theme="TransferPricing"
        init={{ height: '100%' }}
        disabled={notApplicable! || disabled}
        value={discussion}
        onFocus={() => {
          startTime();
        }}
        onBlur={() => {
          void resetTime();
          saveChange('autoSave');
        }}
        onEditorChange={(value = '') => {
          if (value !== discussion) {
            onChange({ discussion: value, evaluationType: used ? evaluationType : undefined });
          }
        }}
      />
    </Box>
  );
};
