import { ComponentType, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CreateNewUncontrolledTransactionModal } from './components/CreateNewUncontrolledTransactionModal';
import { EditUncontrolledTransactionModal } from './components/EditUncontrolledTransactionModal';
import { RoyaltyStatImportModal } from './components/RoyaltyStatImportModal';
import {
  CreateTransactionModalSource,
  CurrentSearchUncontrolledTransaction,
  TbaUncontrolledParametersPayload,
  TbaUncontrolledTransactionsProps,
  UncontrolledTransaction,
  UncontrolledTransactionParameters,
  UpdateTbaUncontrolledParameter
} from './TbaUncontrolledTransactions.proptype';
import { CenteredProgress } from '../../components';
import { FetchLoadingStateEnum } from '../../constants';
import { useRangeTypes } from '../../hooks/useRangeTypes';
import { fetchEntities } from '../../redux/entities';
import {
  fetchTbaRangeTypes,
  createNewUncontrolledTransaction,
  fetchUncontrolledTransactionComparability,
  fetchUncontrolledTransactionParameters,
  fetchUncontrolledTransactions,
  fetchRoyaltyStatTransactions,
  ImportRoyaltyStatTransactionsParams,
  addTBAUncontrolledTransaction,
  editUncontrolledTransaction,
  UploadMultipleTransactionsParams,
  ImportData,
  importUncontrolledTransactionMappings,
  fetchCurrentTBA,
  fetchTbaRangeResults,
  updateUncontrolledTransactionFinancialInfoParameters,
  CopyTBAParameters,
  copyTBA
} from '../../redux/transactionBasedAnalyses';
import { fetchPartyRoles, fetchTransactions } from '../../redux/transactions';
import {
  selectCurrentTBA,
  selectCurrentUncontrolledTransactionComparability,
  selectCurrentUncontrolledTransactionParameters,
  selectEntitiesList,
  selectPartyRoles,
  selectTBALoadingState,
  selectTbaParameters,
  selectTbaRangeTypes,
  selectTransactionsList,
  selectUncontrolledTransactions,
  selectUPECurrency,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { findTBARangeValue, getAppConfig } from '../../utils';
import { FinancialInfoFieldValues, TransactionTypes } from '../NewAnalysisModal/CreateTBAModal.proptypes';
import { tbaParameterIdMap, transactionTypeMap } from '../StudyDashboard/connector';

interface ConnectorProps {
  component: ComponentType<TbaUncontrolledTransactionsProps>;
}

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const flags = useFlags();
  const { xbsEnvironmentShort } = getAppConfig();
  const { t } = useTranslation();
  const tba = useSelector(selectCurrentTBA);
  const tbaParameters = useSelector(selectTbaParameters);
  const transactions = useSelector(selectTransactionsList);
  const upeCurrency = useSelector(selectUPECurrency);
  const currentUncontrolledTransactionParameters = useSelector(selectCurrentUncontrolledTransactionParameters);
  const currentUncontrolledTransactionComparabilities = useSelector(selectCurrentUncontrolledTransactionComparability);
  const tbaRangeTypes = useSelector(selectTbaRangeTypes);
  const [handleRangeTypes] = useRangeTypes(tba?.tbaId ?? 0);
  const partyRoles = useSelector(selectPartyRoles);
  const [addUncontrolledTransactionModalOpen, setAddUncontrolledTransactionModalOpen] = useState<boolean>(false);
  const [isOpenImportFromRoyaltyStatModal, setIsOpenImportFromRoyaltyStatModal] = useState<boolean>(false);
  const [currentSearchUncontrolledTransactions, setCurrentSearchUncontrolledTransactions] = useState<
    UncontrolledTransaction[] | null
  >(null);
  const [allUncontrolledTransactions, setAllUncontrolledTransactions] = useState<UncontrolledTransaction[] | null>(
    null
  );
  const [editUncontrolledTransactionModalOpen, setEditUncontrolledTransactionModalOpen] = useState<boolean>(false);
  const [transactionToEdit, setTransactionToEdit] = useState<UncontrolledTransaction | null>(null);
  const [transactionToEditFiInfo, setTransactionToEditFiInfo] = useState<UncontrolledTransactionParameters[]>([]);
  const [savingNewTransaction, setSavingNewTransaction] = useState<boolean>(false);
  const [importingRoyaltyStatTransactions, setImportingRoyaltyStatTransactions] = useState<boolean>(false);
  const [savingEditedTransaction, setSavingEditedTransaction] = useState<boolean>(false);
  const [
    createUncontrolledTransactionModalSource,
    setCreateUncontrolledTransactionModalSource
  ] = useState<CreateTransactionModalSource>('');
  const entities = useSelector(selectEntitiesList);
  const container = useSelector(selectWorkingContainer);
  const loadingStates = useSelector(selectTBALoadingState);

  useEffect(() => {
    if (entities === null) {
      void dispatch(fetchEntities());
    }
  }, [dispatch, entities]);

  useEffect(() => {
    if (transactions === null) {
      void dispatch(fetchTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (partyRoles === null) {
      void dispatch(fetchPartyRoles());
    }
  }, [dispatch, partyRoles]);

  useEffect(() => {
    if (
      importingRoyaltyStatTransactions &&
      (loadingStates.fetchRoyaltyStatTransactions === FetchLoadingStateEnum.fulfilled ||
        loadingStates.fetchRoyaltyStatTransactions === FetchLoadingStateEnum.error)
    ) {
      setImportingRoyaltyStatTransactions(false);
      setIsOpenImportFromRoyaltyStatModal(false);
      if (loadingStates.fetchRoyaltyStatTransactions === FetchLoadingStateEnum.fulfilled) {
        void refreshTransactionList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, importingRoyaltyStatTransactions, loadingStates.fetchRoyaltyStatTransactions, tba]);

  const selectorUncontrolledTransactions = useSelector(selectUncontrolledTransactions);

  useMemo(() => {
    const currentSearchUncontrolledTransactionIds = tba?.uncontrolledTransactions
      ? tba.uncontrolledTransactions.map((uncontrolledTransaction) => uncontrolledTransaction.transactionId)
      : [];
    const currentSearchUTs =
      selectorUncontrolledTransactions && tba?.uncontrolledTransactions
        ? selectorUncontrolledTransactions.filter((allUncontrolledTransaction) => {
            return currentSearchUncontrolledTransactionIds.includes(allUncontrolledTransaction.transactionId);
          })
        : [];

    const addParameterRangeValueToTransactions = (currentSearchUTs: CurrentSearchUncontrolledTransaction[]) => {
      currentSearchUTs = currentSearchUTs.map((currentSearchUncontrolledTransaction) => {
        const findMatchingParams = currentUncontrolledTransactionParameters?.filter((param) => {
          return param.uncontrolledTransaction.transactionId === currentSearchUncontrolledTransaction.transactionId;
        });
        const parameterValue = findTBARangeValue(findMatchingParams, tba);
        return {
          ...currentSearchUncontrolledTransaction,
          parameterValue: Number(parameterValue)
        };
      });
      return currentSearchUTs;
    };

    const addComparabilityStatusToTransactions = (currentSearchUTs: CurrentSearchUncontrolledTransaction[]) => {
      currentSearchUTs = currentSearchUTs.map((currentSearchUncontrolledTransaction) => {
        const uncontrolledTransactionComparability = currentUncontrolledTransactionComparabilities?.find(
          (currentUncontrolledTransactionComparability) =>
            currentUncontrolledTransactionComparability.uncontrolledTransaction.transactionId ===
            currentSearchUncontrolledTransaction.transactionId
        );
        return {
          ...currentSearchUncontrolledTransaction,
          status: uncontrolledTransactionComparability?.status,
          transactionComparabilityId: uncontrolledTransactionComparability?.transactionComparabilityId
        };
      });
      return currentSearchUTs;
    };

    setCurrentSearchUncontrolledTransactions(
      addParameterRangeValueToTransactions(addComparabilityStatusToTransactions(currentSearchUTs))
    );
    setAllUncontrolledTransactions(addParameterRangeValueToTransactions(selectorUncontrolledTransactions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectorUncontrolledTransactions, tba, setSavingNewTransaction]);

  useEffect(() => {
    if (tba) {
      void dispatch(fetchUncontrolledTransactions());
    }
  }, [dispatch, tba]);

  useEffect(() => {
    if (tba) {
      void dispatch(fetchUncontrolledTransactionParameters(tba.tbaId));
      void dispatch(fetchUncontrolledTransactionComparability(tba.tbaId));
      void dispatch(fetchTbaRangeTypes(tba.tbaId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tba?.tbaId]);

  const refreshTransactionList = async () => {
    if (tba) {
      setSavingNewTransaction(true);
      const promises: any = [
        dispatch(fetchUncontrolledTransactionParameters(tba.tbaId)),
        dispatch(fetchUncontrolledTransactionComparability(tba.tbaId)),
        dispatch(fetchUncontrolledTransactions()),
        dispatch(fetchCurrentTBA(tba.tbaId))
      ];
      await Promise.all(promises);
      setSavingNewTransaction(false);
    }
  };

  const openCreateSingleUncontrolledTransactionModal = (source?: CreateTransactionModalSource) => {
    setCreateUncontrolledTransactionModalSource(source ?? '');
    setAddUncontrolledTransactionModalOpen(true);
  };

  const closeCreateSingleUncontrolledTransactionModal = () => {
    setAddUncontrolledTransactionModalOpen(false);
    setEditUncontrolledTransactionModalOpen(false);
  };

  const submitNewUncontrolledTransaction = async (data: Record<string, any>) => {
    if (container && tba) {
      try {
        setSavingNewTransaction(true);
        closeCreateSingleUncontrolledTransactionModal();

        const transaction = {
          container,
          description: data.description,
          destinationLegalEntity: {},
          identifier: data.transactionId,
          partyRole: {
            partyRoleId: data.partyRole?.partyRoleId,
            name: data.partyRole?.name
          },
          propertyTransferred: data.propertyTransferred,
          sourceLegalEntity: {},
          tbaId: tba.tbaId,
          transactionType: {
            transactionTypeId: transactionTypeMap[data.transactionType as TransactionTypes],
            name: data.transactionType
          },
          transferDate: data.transferDate,
          ucSourceLegalEntity: {},
          ucDestinationLegalEntity: {},
          units: data.units,
          valueEnteredIn: 1,
          value: data.amount
        };

        if (data.destinationEntity) {
          transaction.destinationLegalEntity = {
            domicile: {},
            container: {},
            entityId: data.destinationEntity.entityId
          };
          transaction.ucDestinationLegalEntity = {};
        } else {
          transaction.ucDestinationLegalEntity = {
            domicile: {},
            container: {},
            name: data.destinationEntityName
          };
          transaction.destinationLegalEntity = {};
        }

        if (data.sourceEntity) {
          transaction.sourceLegalEntity = {
            domicile: {},
            container: {},
            entityId: data.sourceEntity.entityId
          };
        } else {
          transaction.ucSourceLegalEntity = {
            domicile: {},
            container: {},
            name: data.sourceEntityName
          };
        }

        const transactionResult = await new Promise<UncontrolledTransaction>((res, rej) => {
          dispatch(createNewUncontrolledTransaction({ transaction, t }))
            .then((data) => {
              res(unwrapResult(data));
            })
            .catch((error) => {
              rej(error);
            });
        });

        if (createUncontrolledTransactionModalSource === 'current-search') {
          const tbaUncontrolledParameters: TbaUncontrolledParametersPayload[] =
            tba.tbaEvaluationMethod.name === 'CUT/CUP' && tba.transactionType.name === 'Tangible Goods'
              ? []
              : Object.keys(data.financialInfo).map((parameterName) => {
                  return {
                    tbaParameter: {
                      description: parameterName,
                      type: parameterName,
                      tbaParameterId: Number(tbaParameterIdMap[parameterName as FinancialInfoFieldValues])
                    },
                    tba: {
                      tbaId: tba.tbaId
                    },
                    parameterValue: Number(data.financialInfo[parameterName as FinancialInfoFieldValues]),
                    uncontrolledTransaction: {
                      transactionId: transactionResult.transactionId
                    }
                  };
                });

          const payload = {
            tbaId: tba.tbaId,
            tbaUncontrolledParameters,
            transactionId: transactionResult.transactionId,
            container
          };

          await dispatch(addTBAUncontrolledTransaction(payload));
        } else if (
          flags.updateUncontrolledTransactionFinancialInfoApi &&
          (flags.updateUncontrolledTransactionFinancialInfoApi === true ||
            flags.updateUncontrolledTransactionFinancialInfoApi.includes(
              `${String(xbsEnvironmentShort)}-${String(container.containerId)}`
            ) ||
            flags.updateUncontrolledTransactionFinancialInfoApi.includes(`${String(xbsEnvironmentShort)}-*`)) &&
          !(tba.tbaEvaluationMethod.name === 'CUT/CUP' && tba.transactionType.name === 'Tangible Goods')
        ) {
          const tbaUncontrolledParameters: UpdateTbaUncontrolledParameter[] = Object.keys(data.financialInfo).map(
            (parameterName) => {
              return {
                parameterValue: Number(data.financialInfo[parameterName as FinancialInfoFieldValues]),
                tbaParameterId: Number(tbaParameterIdMap[parameterName as FinancialInfoFieldValues])
              };
            }
          );

          const payload = {
            tbaUncontrolledParameters,
            tbaId: tba.tbaId,
            transactionId: transactionResult.transactionId
          };

          await dispatch(updateUncontrolledTransactionFinancialInfoParameters(payload));
        }

        await Promise.all([
          dispatch(fetchUncontrolledTransactions()),
          dispatch(fetchCurrentTBA(tba.tbaId)),
          dispatch(fetchUncontrolledTransactionParameters(tba.tbaId)),
          dispatch(fetchUncontrolledTransactionComparability(tba.tbaId)),
          dispatch(fetchTbaRangeTypes(tba.tbaId)),
          dispatch(fetchTbaRangeResults(tba.tbaId))
        ]);
      } finally {
        setSavingNewTransaction(false);
      }
    }
  };

  const onEditSingleTransaction = (transactionId: number, source?: CreateTransactionModalSource) => {
    if (allUncontrolledTransactions) {
      const theTransactionToEdit = allUncontrolledTransactions.find(
        (transaction) => transaction.transactionId === transactionId
      );

      if (theTransactionToEdit) {
        setTransactionToEditFiInfo(
          currentUncontrolledTransactionParameters?.filter(
            (param) => param.uncontrolledTransaction.transactionId === theTransactionToEdit.transactionId
          ) ?? []
        );
        setCreateUncontrolledTransactionModalSource(source ?? '');
        setTransactionToEdit(theTransactionToEdit);
        setEditUncontrolledTransactionModalOpen(true);
      }
    }
  };

  const submitEditedUncontrolledTransaction = async (data: any) => {
    if (container && tba) {
      setSavingEditedTransaction(true);

      const transaction = {
        container,
        comparabilityEvaluation: '',
        description: data.description,
        destinationLegalEntity: data.destinationEntity,
        identifier: data.identifier,
        partyRole: {
          partyRoleId: data.partyRole?.partyRoleId,
          name: data.partyRole?.name
        },
        propertyTransferred: data.propertyTransferred,
        sourceLegalEntity: data.sourceEntity,
        tbaId: tba.tbaId,
        transactionId: data.transactionId,
        transactionType: {
          transactionTypeId: transactionTypeMap[data.transactionType as TransactionTypes],
          name: data.transactionType
        },
        transferDate: data.transferDate,
        ucSourceLegalEntity: data.ucSourceLegalEntity,
        ucDestinationLegalEntity: data.ucDestinationLegalEntity,
        units: data.units,
        valueEnteredIn: 1,
        value: data.amount
      };

      await dispatch(editUncontrolledTransaction({ transaction, t }));

      const originalTransaction = selectorUncontrolledTransactions?.find(
        (singleTransaction) => singleTransaction.transactionId === data.transactionId
      );

      const { financialInfo, transactionId } = data;

      if (
        financialInfo &&
        Object.keys(financialInfo).length > 0 &&
        originalTransaction &&
        !(tba.tbaEvaluationMethod.name === 'CUT/CUP' && tba.transactionType.name === 'Tangible Goods')
      ) {
        const tbaUncontrolledParameters: UpdateTbaUncontrolledParameter[] = [];
        Object.keys(financialInfo).forEach((parameterName) => {
          const parameter = transactionToEditFiInfo.find(
            (fiInfo) => fiInfo.tbaParameter.description === parameterName || fiInfo.tbaParameter.type === parameterName
          );
          const tbaParameterId = tbaParameterIdMap[parameterName as FinancialInfoFieldValues];
          if (tbaParameterId) {
            if (parameter) {
              const updatedParameter: UpdateTbaUncontrolledParameter = {
                parameterValue: Number(financialInfo[parameterName as FinancialInfoFieldValues]),
                tbaUncontrolledParameterId: parameter.tbaUncontrolledParameterId,
                tbaParameterId
              };
              tbaUncontrolledParameters.push(updatedParameter);
            } else {
              const newParameter: UpdateTbaUncontrolledParameter = {
                parameterValue: Number(financialInfo[parameterName as FinancialInfoFieldValues]),
                tbaParameterId
              };
              tbaUncontrolledParameters.push(newParameter);
            }
          }
        });

        const payload = {
          tbaUncontrolledParameters,
          tbaId: tba.tbaId,
          transactionId
        };

        await dispatch(updateUncontrolledTransactionFinancialInfoParameters(payload));
      }

      await Promise.all([
        dispatch(fetchUncontrolledTransactions()),
        dispatch(fetchCurrentTBA(tba.tbaId)),
        dispatch(fetchUncontrolledTransactionParameters(tba.tbaId)),
        dispatch(fetchUncontrolledTransactionComparability(tba.tbaId)),
        dispatch(fetchTbaRangeTypes(tba.tbaId)),
        dispatch(fetchTbaRangeResults(tba.tbaId))
      ]);

      setSavingEditedTransaction(false);
      setEditUncontrolledTransactionModalOpen(false);
    }
  };

  const onUploadMultipleTransactions = async (data: UploadMultipleTransactionsParams) => {
    const columns = data?.headers?.map((header: string) => {
      return { header };
    });

    if (!columns) {
      return;
    }

    columns.forEach((column: ImportData) => {
      const mappedValue = data.mappings.find((field) => field.header === column.header);
      column.field = mappedValue?.field ?? '';
    });

    if (tba?.tbaId && container) {
      await dispatch(
        importUncontrolledTransactionMappings({
          hash: data.hash,
          tbaId: tba.tbaId,
          mappings: columns,
          container
        })
      );
      void refreshTransactionList();
    }
  };

  const onImportRoyaltyStatTransactions = async (folderId: number) => {
    if (tba) {
      setImportingRoyaltyStatTransactions(true);
      const params: ImportRoyaltyStatTransactionsParams = {
        folderId,
        tbaId: tba.tbaId,
        transactionType: tba.transactionType
      };
      void dispatch(fetchRoyaltyStatTransactions(params));
    }
  };

  const onCopyTba = async (data: CopyTBAParameters) => {
    if (tba?.tbaId && container) {
      await dispatch(
        copyTBA({
          data,
          t
        })
      );
      void refreshTransactionList();
      await handleRangeTypes();
      await dispatch(fetchTbaRangeTypes(tba.tbaId));
      await dispatch(fetchTbaRangeResults(tba.tbaId));
    }
  };

  return tba ? (
    <>
      <Component
        tba={tba}
        allUncontrolledTransactions={allUncontrolledTransactions ?? []}
        currentSearchUncontrolledTransactions={currentSearchUncontrolledTransactions ?? []}
        tbaParameters={tbaParameters}
        tbaRangeTypes={tbaRangeTypes}
        savingNewTransaction={savingNewTransaction}
        setIsOpenImportFromRoyaltyStatModal={setIsOpenImportFromRoyaltyStatModal}
        onCreateSingleTransaction={openCreateSingleUncontrolledTransactionModal}
        onCopyTba={onCopyTba}
        onUploadMultipleTransactions={onUploadMultipleTransactions}
        onEditSingleTransaction={onEditSingleTransaction}
      />
      {addUncontrolledTransactionModalOpen && entities && upeCurrency && createUncontrolledTransactionModalSource && (
        <CreateNewUncontrolledTransactionModal
          tba={tba}
          entities={entities}
          transactions={transactions}
          container={container}
          partyRoles={partyRoles}
          upeCurrency={upeCurrency}
          savingNewTransaction={savingNewTransaction}
          source={createUncontrolledTransactionModalSource}
          submitNewUncontrolledTransaction={submitNewUncontrolledTransaction}
          onClose={closeCreateSingleUncontrolledTransactionModal}
        />
      )}
      {editUncontrolledTransactionModalOpen && transactionToEdit && entities && upeCurrency && (
        <EditUncontrolledTransactionModal
          source={createUncontrolledTransactionModalSource}
          tba={tba}
          transactionToEdit={transactionToEdit}
          entities={entities}
          transactions={transactions}
          transactionToEditFiInfo={transactionToEditFiInfo}
          container={container}
          partyRoles={partyRoles}
          upeCurrency={upeCurrency}
          savingEditedTransaction={savingEditedTransaction}
          submitEditedUncontrolledTransaction={submitEditedUncontrolledTransaction}
          onClose={closeCreateSingleUncontrolledTransactionModal}
        />
      )}
      {isOpenImportFromRoyaltyStatModal && (
        <RoyaltyStatImportModal
          title={t('analysis:tba-uncontrolled-transaction-rs-import')}
          isOpen={isOpenImportFromRoyaltyStatModal}
          isLoading={importingRoyaltyStatTransactions}
          setIsOpenImportFromRoyaltyStatModal={setIsOpenImportFromRoyaltyStatModal}
          onImportRoyaltyStatTransactions={onImportRoyaltyStatTransactions}
        />
      )}
    </>
  ) : (
    <CenteredProgress />
  );
};

export default Connector;
