import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, TextField, Typography, makeStyles } from '@material-ui/core';
import tokens from '../../../styles/designTokens';
import { title2 } from '../../../styles/typography';
import { UploadIcon } from '../../../svgs';
// keeping for STREAM-1028 Metrics
// import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
// keeping for STREAM-1028 Metrics
// import { container } from '../../Entities/Entities.testdata';
import { TPModal } from '../../TPModal';

export interface RoyaltyStatImportModalProps {
  isOpen: boolean;
  title: string;
  isLoading: boolean;
  setIsOpenImportFromRoyaltyStatModal: (isOpen: boolean) => void;
  onImportRoyaltyStatTransactions: (folderId: number) => void;
}

const useStyles = makeStyles((theme) => ({
  headerIcon: {
    height: '2.25rem',
    width: '2.25rem',
    backgroundColor: tokens.product15,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '& svg': {
      fill: tokens.product100
    },
    '&.MuiIconButton-root': {
      padding: '0.5rem'
    }
  },
  title: {
    ...title2
  },
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  textField: {
    width: '100%',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  contentBox: {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(2),
    boxShadow: '0 0 0.188rem 0 rgba(0,0,0,0.12)'
  },
  contentWrapper: {
    backgroundColor: tokens.neutral70
  }
}));

export const RoyaltyStatImportModal = ({
  isOpen,
  title,
  isLoading,
  setIsOpenImportFromRoyaltyStatModal,
  onImportRoyaltyStatTransactions
}: RoyaltyStatImportModalProps) => {
  const classes = useStyles();
  const [error, setError] = useState<string>('');
  const [folderId, setFolderId] = useState<string>('');
  const { t } = useTranslation();

  const getInputError = (folderId: string) => {
    if (folderId === String(Number.parseInt(folderId, 10))) {
      return '';
    }

    if (folderId.length > 0) {
      return t('analysis:tba-uncontrolled-transaction-rs-import-numbers-only-error');
    }

    return t('analysis:tba-uncontrolled-transaction-rs-import-folder-id-required-error');
  };

  return (
    <TPModal
      isOpen={isOpen}
      maxWidth="md"
      containerStyle={classes.contentWrapper}
      title={
        <span className={classes.modalTitleWrapper}>
          <IconButton className={classes.headerIcon}>
            <UploadIcon />
          </IconButton>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
        </span>
      }
      actions={[
        {
          name: t('action-cancel'),
          variant: 'outlined',
          color: 'primary',
          handler: () => {
            // keeping for STREAM-1028 Metrics
            // /* eslint-disable camelcase */
            // void logGoogleAnalyticsEvent({
            //   event_category: 'tba_button_click',
            //   event_label: `${source} On Cancel Import RoyaltyStat Folder Modal click`,
            //   container_id: container?.containerId
            // });
            setFolderId('');
            setIsOpenImportFromRoyaltyStatModal(false);
          }
        },
        {
          name: isLoading ? '' : t('action-import'),
          color: 'primary',
          variant: 'contained',
          handler: async () => {
            // keeping for STREAM-1028 Metrics
            // /* eslint-disable camelcase */
            // void logGoogleAnalyticsEvent({
            //   event_category: 'tba_button_click',
            //   event_label: `${source} On Import Import RoyaltyStat Folder Modal click`,
            //   container_id: container?.containerId
            // });
            setError(getInputError(folderId));
            if (!getInputError(folderId)) {
              onImportRoyaltyStatTransactions(Number(folderId));
            }
          }
        }
      ]}
      onClose={() => {
        // keeping for STREAM-1028 Metrics
        // /* eslint-disable camelcase */
        // void logGoogleAnalyticsEvent({
        //   event_category: 'tba_button_click',
        //   event_label: `${source} On Close Import RoyaltyStat Folder Modal click`,
        //   container_id: container?.containerId
        // });
        setIsOpenImportFromRoyaltyStatModal(false);
        setFolderId('');
      }}
    >
      <Box className={classes.contentBox}>
        <TextField
          id="folderId"
          name="folderId"
          placeholder="RoyaltyStat Folder Id"
          className={classes.textField}
          margin="dense"
          variant="outlined"
          value={folderId}
          inputProps={{ 'data-testid': 'rsFolderId' }}
          error={Boolean(error)}
          onChange={(e) => {
            if (error) {
              setError(getInputError(e?.target?.value));
            }

            setFolderId(e?.target?.value || '');
          }}
        />
        {error ? (
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        ) : null}
      </Box>
    </TPModal>
  );
};
