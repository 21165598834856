import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';
import { TFunction } from 'i18next';
import { AnalysisAndMethod, getId, getAnalysisName, getCompletionState } from './utils';
import { NestedList, NestedListItem, NestedListSection } from '../../../components';
import { MethodEvaluation, TransactionEvaluations } from '../../../models';
import { isAnalysisUsed, isMethodApplicable, NotApplicableMethods, notApplicableMethods } from '../../../utils';
import { MethodEvaluationProps } from '../MethodEvaluation.proptype';

interface AnalysisItem extends NestedListItem {
  data: AnalysisAndMethod;
}

interface MethodSection extends NestedListSection<AnalysisItem> {
  data: MethodEvaluation;
  nbUsed: number;
}

const makeMethodNestedList = ({
  transactionType,
  evaluations,
  isoCodes,
  t,
  language,
  notApplicableMethods
}: {
  transactionType: number;
  evaluations?: TransactionEvaluations;
  isoCodes: { source: string; destination: string };
  t: TFunction;
  language: string;
  notApplicableMethods: NotApplicableMethods;
}): MethodSection[] =>
  evaluations
    ? evaluations.methods
        .map((method) => {
          let nbUsed = 0;
          const appliesToSource = isMethodApplicable(
            method.methodName,
            transactionType,
            isoCodes.source,
            notApplicableMethods
          );
          const appliesToDestination = isMethodApplicable(
            method.methodName,
            transactionType,
            isoCodes.destination,
            notApplicableMethods
          );
          const disabled = !appliesToSource && !appliesToDestination;

          const children = [...evaluations.pbas, ...evaluations.tbas].map((analysis) => {
            const used = isAnalysisUsed(analysis, method);
            const checked = used ? getCompletionState(analysis) : method.isCompleted ?? false;
            const analysisAndMethod = { analysis, method, used, appliesToSource, appliesToDestination, checked };
            if (used) {
              nbUsed++;
            }

            let statusLabel = t(`transactions:label-method-${used ? '' : 'not-'}used`, isoCodes);
            if (disabled) {
              statusLabel = t('transactions:label-method-not-applicable', isoCodes);
            } else if (!appliesToSource) {
              statusLabel = t(`transactions:label-method-${used ? '' : 'not-'}used-single`, {
                value: isoCodes.destination
              });
            } else if (!appliesToDestination) {
              statusLabel = t(`transactions:label-method-${used ? '' : 'not-'}used-single`, {
                value: isoCodes.source
              });
            }

            return {
              id: getId(analysisAndMethod),
              name: getAnalysisName(analysis),
              disabled,
              checked,
              data: analysisAndMethod,
              extra: <Chip role="note" variant="outlined" color="primary" label={statusLabel} />
            };
          });
          return {
            name: method.methodName,
            data: method,
            nbUsed,
            disabled,
            children
          };
        })
        // first method with most used analyses,
        // then method with unused analyses
        // finally unapplicable method
        // use alphabetical order for draws
        .sort((a, b) =>
          !b.disabled && !a.disabled
            ? b.nbUsed === a.nbUsed
              ? a.name.localeCompare(b.name, language)
              : b.nbUsed - a.nbUsed
            : b.disabled && !a.disabled
            ? -1
            : !b.disabled && a.disabled
            ? 1
            : a.name.localeCompare(b.name, language)
        )
    : [];

interface ListProps extends Omit<MethodEvaluationProps, 'onAutoSave' | 'onSave' | 'evaluationsCompletion'> {
  current: AnalysisAndMethod | null;
  onSelect: (data: AnalysisAndMethod) => void;
}

export const List = ({
  transactionType,
  evaluations,
  sourceJurisdiction,
  destinationJurisdiction,
  current,
  onSelect
}: ListProps) => {
  const { t, i18n } = useTranslation();

  const data = useMemo(
    () =>
      makeMethodNestedList({
        transactionType,
        evaluations,
        isoCodes: { source: sourceJurisdiction?.isoCode ?? '', destination: destinationJurisdiction?.isoCode ?? '' },
        t,
        language: i18n.language,
        notApplicableMethods
      }),
    [transactionType, evaluations, sourceJurisdiction?.isoCode, destinationJurisdiction?.isoCode, t, i18n.language]
  );

  useEffect(() => {
    if (!current && data[0]?.children[0]) {
      onSelect(data[0].children[0].data);
    }
  }, [onSelect, current, data]);

  return (
    <NestedList
      data={data}
      selectedId={current ? getId(current) : 0}
      onSelect={({ data }) => {
        onSelect(data);
      }}
    />
  );
};
