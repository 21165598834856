import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConnectorProps } from './DashboardCardEntityTransactions.proptype';
import { selectTransactionsByEntity, selectTransactionsTotalByEntity, selectUPECurrency } from '../../selectors';

const Connector = ({ component: Component }: ConnectorProps) => {
  const entityId = Number(useParams<{ entityId: string }>().entityId);
  const count = useSelector(selectTransactionsByEntity(entityId)).length;
  const totalValue = useSelector(selectTransactionsTotalByEntity(entityId));
  const upeCurrency = useSelector(selectUPECurrency);
  return <Component count={count} totalValue={totalValue} currency={upeCurrency} url="/transactions" />;
};

export default Connector;
