import { ComponentType, ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MajorClassification, MinorClassification } from './TestedPartyServiceProvider.proptype';
import { fetchCurrentTestedParty } from '../../redux/profitBasedAnalyses';
import {
  addMajorClassifications,
  addMinorClassifications,
  deleteMajorClassifications,
  deleteMinorClassifications,
  fetchCurrentMajorClassifications,
  fetchMajorClassifications
} from '../../redux/testedParty';
import { selectCurrentTestedParty } from '../../selectors';
import { selectAllMajorClassifications, selectCurrentMajorClassification } from '../../selectors/testedParty';

const Connector = ({ component: Component }: { component: ComponentType<any> }) => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const allMajorClassifications: MajorClassification[] = useSelector(selectAllMajorClassifications);
  const currentMajorClassifications: MajorClassification[] = useSelector(selectCurrentMajorClassification);
  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  useEffect(() => {
    dispatch(fetchMajorClassifications());
    dispatch(fetchCurrentMajorClassifications(pbaId));
  }, [dispatch, pbaId]);

  const [dropdownMinorClassifications, setDropdownMinorClassifications] = useState<MinorClassification[][] | null[]>([
    null,
    null,
    null
  ]);
  const [majorClassifications, setMajorClassifications] = useState<MajorClassification[] | null[]>([null, null, null]);
  const [columnOrderByClassificationId, setColumnOrderByClassificationId] = useState<number[]>([-1, -1, -1]);

  useEffect(() => {
    dispatch(fetchCurrentTestedParty(pbaId));
  }, [dispatch, pbaId]);

  useEffect(() => {
    if (currentMajorClassifications && currentMajorClassifications.length > 0) {
      if (initialized) {
        // make sure to keep the list organized here so columns don't change
        const initialMajorClassifications = majorClassifications;
        const initialDropdownMinorClassifications = dropdownMinorClassifications;
        currentMajorClassifications.forEach((currentMajorClassification) => {
          const classificationId = currentMajorClassification.majorServiceClassificationId;
          const classificationIndex = columnOrderByClassificationId.indexOf(classificationId);
          if (classificationIndex >= 0) {
            initialMajorClassifications[classificationIndex] = currentMajorClassification;
            const fullMajorClassification = allMajorClassifications.find(
              (mc) => mc.majorServiceClassificationId === currentMajorClassification.majorServiceClassificationId
            );
            if (fullMajorClassification) {
              const dropdownList = [...fullMajorClassification.minorServiceClassifications];
              currentMajorClassification.minorServiceClassifications.forEach((msc) => {
                const indexOfClassification = dropdownList
                  .map((ioc) => ioc.minorServiceClassificationId)
                  .indexOf(msc.minorServiceClassificationId);
                if (indexOfClassification >= 0) {
                  dropdownList.splice(indexOfClassification, 1);
                }
              });
              initialDropdownMinorClassifications[classificationIndex] = dropdownList;
            }
          }
        });
        setDropdownMinorClassifications(initialDropdownMinorClassifications);
        setMajorClassifications(initialMajorClassifications);
      } else {
        setInitialized(true);
        const initialMajorClassifications = majorClassifications;
        const initialDropdownMinorClassifications = dropdownMinorClassifications;
        currentMajorClassifications.forEach((currentMajorClassification, index) => {
          initialMajorClassifications[index] = currentMajorClassification;
          const columnOrder = columnOrderByClassificationId;
          columnOrder[index] = currentMajorClassification.majorServiceClassificationId;
          setColumnOrderByClassificationId(columnOrder);
          const fullMajorClassification = allMajorClassifications.find(
            (mc) => mc.majorServiceClassificationId === currentMajorClassification.majorServiceClassificationId
          );
          if (fullMajorClassification) {
            const dropdownList = [...fullMajorClassification.minorServiceClassifications];
            currentMajorClassification.minorServiceClassifications.forEach((msc) => {
              const indexOfClassification = dropdownList
                .map((ioc) => ioc.minorServiceClassificationId)
                .indexOf(msc.minorServiceClassificationId);
              if (indexOfClassification >= 0) {
                dropdownList.splice(indexOfClassification, 1);
              }
            });
            initialDropdownMinorClassifications[index] = dropdownList;
          }
        });
        setDropdownMinorClassifications(initialDropdownMinorClassifications);
        setMajorClassifications(initialMajorClassifications);
      }
    }
  }, [
    columnOrderByClassificationId,
    currentMajorClassifications,
    allMajorClassifications,
    dropdownMinorClassifications,
    majorClassifications,
    initialized,
    dispatch,
    pbaId
  ]);

  const deleteMajorClassification = async (majorToBeDeleted: MajorClassification) => {
    const promises: any = [];
    const deleteMajor = { pbaId, majorClassificationId: majorToBeDeleted.majorServiceClassificationId };
    promises.push(dispatch(deleteMajorClassifications(deleteMajor)));
    majorToBeDeleted.minorServiceClassifications.forEach((minorServiceClassification) => {
      const deleteMinor = {
        pbaId,
        minorClassificationId: minorServiceClassification.minorServiceClassificationId
      };
      promises.push(dispatch(deleteMinorClassifications(deleteMinor)));
    });
    await Promise.all(promises);
    dispatch(fetchCurrentMajorClassifications(pbaId));
  };

  const addMajorClassification = async (majorToBeAdded: MajorClassification) => {
    const addMajor = { pbaId, majorClassificationId: majorToBeAdded.majorServiceClassificationId };
    dispatch<any>(addMajorClassifications(addMajor)).then(() => {
      dispatch(fetchCurrentMajorClassifications(pbaId));
    });
  };

  const addMinorClassification = (minorToBeAdded: MinorClassification) => {
    const addMinor = { pbaId, minorClassificationId: minorToBeAdded.minorServiceClassificationId };
    dispatch<any>(addMinorClassifications(addMinor)).then(() => {
      dispatch(fetchCurrentMajorClassifications(pbaId));
    });
  };

  const deleteMinorClassification = (minorToBeDeleted: MinorClassification) => {
    const removeMinor = { pbaId, minorClassificationId: minorToBeDeleted.minorServiceClassificationId };
    dispatch<any>(deleteMinorClassifications(removeMinor)).then(() => {
      dispatch(fetchCurrentMajorClassifications(pbaId));
    });
  };

  const onSelectMajorClassification = async (
    cardNumber: number,
    selectedMajorClassification: MajorClassification,
    newSelectedMajorClassification: MajorClassification
  ) => {
    if (
      selectedMajorClassification &&
      newSelectedMajorClassification &&
      JSON.stringify(selectedMajorClassification) !== JSON.stringify(newSelectedMajorClassification)
    ) {
      await deleteMajorClassification(selectedMajorClassification);
      await addMajorClassification(newSelectedMajorClassification);
    } else if (!selectedMajorClassification && newSelectedMajorClassification) {
      await addMajorClassification(newSelectedMajorClassification);
    } else if (selectedMajorClassification && !newSelectedMajorClassification) {
      await deleteMajorClassification(selectedMajorClassification);
    }

    const oldMajorClassifications = majorClassifications;
    const columnOrder = columnOrderByClassificationId;
    if (newSelectedMajorClassification === null) {
      oldMajorClassifications[cardNumber] = newSelectedMajorClassification;
      columnOrder[cardNumber] = -1;
    } else {
      const newMinorsList: MinorClassification[] = [...newSelectedMajorClassification.minorServiceClassifications];
      const newMajorClassification = { ...newSelectedMajorClassification };
      newMajorClassification.minorServiceClassifications = [];
      const dropdownList = dropdownMinorClassifications;
      dropdownList[cardNumber] = newMinorsList;
      setDropdownMinorClassifications(dropdownList);
      oldMajorClassifications[cardNumber] = newMajorClassification;
      columnOrder[cardNumber] = newMajorClassification.majorServiceClassificationId;
    }

    setMajorClassifications(oldMajorClassifications);
    setColumnOrderByClassificationId(columnOrder);
  };

  const onSelectMinorClassification = (
    event: ChangeEvent<Record<string, unknown>>,
    value: MinorClassification | null
  ) => {
    if (value) {
      addMinorClassification(value);
    }
  };

  const removeMinorClassification = (removedMinorClassification: MinorClassification) => {
    if (removedMinorClassification) {
      deleteMinorClassification(removedMinorClassification);
    }
  };

  return (
    <Component
      allMajorClassifications={allMajorClassifications}
      currentTestedParty={currentTestedParty}
      majorClassifications={majorClassifications}
      dropdownMinorClassifications={dropdownMinorClassifications}
      removeMinorClassification={removeMinorClassification}
      onSelectMajorClassification={onSelectMajorClassification}
      onSelectMinorClassification={onSelectMinorClassification}
    />
  );
};

export default Connector;
