export interface Currency {
  currencyId: number;
  name: string;
  isoCode: string;
}

export interface CurrencyType {
  id: number;
  name: string;
}

export interface InputCurrencyOption {
  id: number;
  name: string;
}

export enum CurrencyScale {
  FullValue,
  Hundredths,
  Thousandths,
  TenThousands,
  HundredThousands,
  Millionths,
  TenMillionths,
  HundredMillionths
}

export interface CurrencyConversion {
  taxYear: number;
  conversionValue: number;
  localCurrencyScale: CurrencyScale;
}
