import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  Typography,
  Divider,
  makeStyles,
  Theme,
  TextField,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';
import { Add as AddIcon, CreateOutlined as CreateOutlinedIcon } from '@material-ui/icons';
import { PrimaryFunctions } from '@xbs/xbs-enums';
import {
  EntityAddEditModalProps,
  EntityInputs,
  UPEInputs,
  DomicileOption,
  CurrencyOption
} from './EntityAddEditModal.proptype';
import OrganisationStructure from './OrganisationStructure';
import { SearchBox } from '../../components';
import { SimpleDatePicker } from '../../components/SimpleDatePicker';
import { Entity } from '../../models';
import tokens from '../../styles/designTokens';
import { body2 } from '../../styles/typography';
import { formatPercentage, sortAlphabetically } from '../../utils';
import { validateDateString } from '../../utils/dates';
import { logGoogleAnalyticsEvent } from '../../utils/sendGoogleAnalyticaEvent';
import { TPModal } from '../TPModal';

const useStyles = makeStyles((theme: Theme) => ({
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    boxSizing: 'content-box',
    padding: theme.spacing(0.75),
    marginRight: theme.spacing(1)
  },
  organisationStructure: {
    margin: theme.spacing(3, 0)
  },
  doneButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2)
  },
  inputGridElement: {
    marginBottom: theme.spacing(2)
  },
  columnFields: {
    margin: theme.spacing(3, 0, 2)
  },
  label: {
    ...body2,
    color: tokens.product100,
    marginBottom: '6px'
  },
  textField: {
    width: '100%',
    marginTop: '0px !important',
    marginBottom: '0px !important'
  },
  otherPrimaryFunction: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0)
  },
  placeHolder: {
    color: theme.palette.text.secondary
  },
  formControl: {
    width: '100%'
  }
}));

const entityCodeValidation = (input: string) => input.length >= 3 && input.length <= 20;
const entityNameValidation = (input: string) => input.length >= 3 && input.length <= 75;

const mapEntityToForm = ({
  entity,
  t,
  isUpe
}: {
  entity: Entity | null;
  t: TFunction;
  isUpe: boolean;
}): Partial<EntityInputs | UPEInputs> => {
  if (!entity) {
    return {
      entityCode: '',
      domicile: null,
      taxJurisdiction: '',
      primaryFunction: '',
      otherPrimaryFunction: '',
      ownershipPercent1: '',
      ownershipPercent2: '',
      ownershipPercent3: ''
    };
  }

  const {
    code = '',
    name = '',
    fiscalYearEnd,
    parentEntities = [],
    domicile,
    taxJurisdiction,
    primaryFunction,
    otherPrimaryFunction,
    upeCurrency,
    upeName
  } = entity ?? {};

  const [firstParentEntity, secondParentEntity, thirdParentEntity] = parentEntities;
  const ownershipDecimal1 = firstParentEntity?.ownership;
  const ownershipDecimal2 = secondParentEntity?.ownership;
  const ownershipDecimal3 = thirdParentEntity?.ownership;

  const parentEntity1 = firstParentEntity?.parent
    ? {
        value: firstParentEntity.parent?.code,
        data: firstParentEntity.parent
      }
    : null;
  const parentEntity2 = secondParentEntity?.parent
    ? {
        value: secondParentEntity.parent?.code,
        data: secondParentEntity.parent
      }
    : null;
  const parentEntity3 = thirdParentEntity?.parent
    ? {
        value: thirdParentEntity.parent?.code,
        data: thirdParentEntity.parent
      }
    : null;

  const defaultValues = {
    entityCode: code,
    entityFullName: name,
    domicile: {
      value: t('label-name-and-code', domicile),
      data: {
        ...domicile,
        defaultJurisdiction: taxJurisdiction
      }
    },
    taxJurisdiction: t('label-name-and-code', taxJurisdiction),
    fiscalYearEnd,
    primaryFunction: primaryFunction.primaryFunctionId,
    otherPrimaryFunction,
    ...(isUpe
      ? {
          currency: { value: t('label-name-and-code', upeCurrency), data: upeCurrency },
          upeName
        }
      : {
          ...{ ownershipPercent1: ownershipDecimal1 ? formatPercentage((ownershipDecimal1 ?? 0) * 100, 6) : '' },
          ...{ ownershipPercent2: ownershipDecimal2 ? formatPercentage((ownershipDecimal2 ?? 0) * 100, 6) : '' },
          ...{ ownershipPercent3: ownershipDecimal3 ? formatPercentage((ownershipDecimal3 ?? 0) * 100, 6) : '' },
          ...{ parentEntity1 },
          ...{ parentEntity2 },
          ...{ parentEntity3 }
        })
  };
  return defaultValues;
};

const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
  error ? (
    <Typography color="error" variant="caption">
      {message}
    </Typography>
  ) : null;

const FormDivider = () => (
  <Grid item xs={12}>
    <Divider variant="middle" />
  </Grid>
);

const testedPartyPrimaryFunctionsDropDown = new Set([
  PrimaryFunctions.ByName.Distributor.Name,
  PrimaryFunctions.ByName.Integrated.Name,
  PrimaryFunctions.ByName.Manufacturer.Name,
  PrimaryFunctions.ByName.Other.Name,
  PrimaryFunctions.ByName.ServiceProvider.Name
]);

// eslint-disable-next-line complexity
export const EntityAddEditModal = ({
  onClose,
  onSubmit,
  domicileOptions,
  primaryFunctionOptions,
  entityOptions,
  entity,
  isUpe,
  hasTransactions,
  currencyOptions,
  container,
  hasNonUpeEntities
}: EntityAddEditModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultValues = mapEntityToForm({ entity, t, isUpe });
  const primaryFunctionDropDown = primaryFunctionOptions.filter((primaryFunctionOption) =>
    testedPartyPrimaryFunctionsDropDown.has(primaryFunctionOption.title)
  );
  const menuItems = sortAlphabetically(
    primaryFunctionDropDown ? [...primaryFunctionDropDown] : [],
    ({ title }) => title
  );
  const [hasOtherPrimaryFunction, setHasOtherPrimaryFunction] = useState(
    Boolean(defaultValues.primaryFunction === PrimaryFunctions.ByName.Other.Id)
  );
  const [fiscalYearEndErrorMessage, setFiscalYearEndErrorMessage] = useState<string>(
    t('entities:invalid-entry-fiscal-year-end-required')
  );

  const formMethods = useForm<EntityInputs | UPEInputs>({
    defaultValues: isUpe ? (defaultValues as UPEInputs) : (defaultValues as EntityInputs)
  });
  const { handleSubmit, setValue, control, errors } = formMethods;

  const onSelectPrimaryFunction = (event: any) => {
    const selectedPrimaryFunctionId = event.target.value;
    setHasOtherPrimaryFunction(PrimaryFunctions.ByName.Other.Id === selectedPrimaryFunctionId);
  };

  const setTaxJurisdictionName = (option: DomicileOption | null) => {
    const parentJurisdiction = option?.data.defaultJurisdiction
      ? t('label-name-and-code', option.data.defaultJurisdiction)
      : '';
    const taxJurisdictionLabel = parentJurisdiction || option?.value;
    setValue('taxJurisdiction', taxJurisdictionLabel ?? '');
  };

  // Acceptable dates are from January 1st of the year preceding the tax year
  // to December 31st of the year following the tax year. A 3 year period.
  const currentYear = new Date().getFullYear();
  const taxYear = container?.taxYear ?? currentYear;
  const minDate = `${taxYear - 1}-01-01`;
  const maxDate = `${taxYear + 1}-12-31`;
  const validateFiscalYearEnd = (date: string) => {
    const { valid, message } = validateDateString({
      date,
      maxDate,
      minDate,
      invalidErrorMessage: t('errors:error_message.invalid_date'),
      lessThanErrorMessage: t('errors:error_message.date_can_not_be_less_than'),
      greaterThanErrorMessage: t('errors:error_message.date_can_not_be_larger_than')
    });

    setFiscalYearEndErrorMessage(date ? message : t('entities:invalid-entry-fiscal-year-end-required'));

    return valid;
  };

  const handleClose = (event?: Record<string, unknown>, reason?: string) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <TPModal
      isOpen
      maxWidth="md"
      title={
        <span className={classes.modalTitleWrapper}>
          {entity ? <CreateOutlinedIcon className={classes.headerIcon} /> : <AddIcon className={classes.headerIcon} />}
          {isUpe && <Typography variant="h6">{t(`entities:${entity ? 'title-edit-upe' : 'title-upe'}`)}</Typography>}
          {!isUpe && <Typography variant="h6">{t(`entities:${entity ? 'action-edit' : 'action-new'}`)}</Typography>}
        </span>
      }
      actions={[
        {
          name: t('action-cancel'),
          handler: () => {
            handleClose();
          }
        },
        {
          name: t('action-done'),
          id: 'button-newui-add-entity-modal-done',
          className: classes.doneButton,
          handler: handleSubmit((data) => {
            /* eslint-disable camelcase */
            void logGoogleAnalyticsEvent({
              event_category: 'entity_clicks',
              event_label: `Entity ${entity ? 'Edit' : 'Add'} modal Done click`,
              container_id: container?.containerId
            });

            try {
              onSubmit(data);
              handleClose();
            } catch (error: unknown) {
              console.log(error);
            }
          })
        }
      ]}
      onClose={handleClose}
    >
      <Grid container justify="center">
        <form>
          <Grid container item xs={12} justify="center">
            <Grid container item xs={11} spacing={1} className={classes.columnFields} justify="space-around">
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box>
                  <Typography className={classes.label}>{t(`entities:column-${isUpe ? 'upe' : 'code'}`)}</Typography>
                  <Controller
                    data-testid="entityCode2"
                    name={isUpe ? 'upeName' : 'entityCode'}
                    render={({ value, onChange }) => (
                      <TextField
                        id={isUpe ? 'upeName' : 'entityCode'}
                        name={isUpe ? 'upeName' : 'entityCode'}
                        placeholder={t(`entities:placeholder-${isUpe ? 'upe-name' : 'code'}`)}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        value={value}
                        inputProps={{ 'data-testid': 'entityCode' }}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    defaultValue={isUpe ? (defaultValues as UPEInputs).upeName : defaultValues.entityCode}
                    rules={{
                      required: {
                        value: true,
                        message: t(isUpe ? 'entities:invalid-entry-required-upe-name' : 'entities:invalid-entry-code')
                      },
                      maxLength: {
                        value: isUpe ? 75 : 20,
                        message: t(isUpe ? 'entities:invalid-entry-length-upe-name' : 'entities:invalid-entry-code')
                      },
                      minLength: {
                        value: 3,
                        message: t(isUpe ? 'entities:invalid-entry-length-upe-name' : 'entities:invalid-entry-code')
                      }
                    }}
                  />
                </Box>
                <ErrorMessage
                  error={Boolean(isUpe ? (errors as any).upeName : errors.entityCode)}
                  message={(isUpe ? (errors as any).upeName : errors.entityCode)?.message}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Typography className={classes.label}>{t('entities:column-name')}</Typography>
                <Controller
                  name="entityFullName"
                  render={({ value, onChange }) => (
                    <TextField
                      id="entityFullName"
                      name="entityFullName"
                      placeholder={t('entities:placeholder-name')}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      value={value}
                      error={Boolean(errors.entityFullName)}
                      onChange={onChange}
                    />
                  )}
                  control={control}
                  defaultValue={defaultValues.entityFullName ?? ''}
                  rules={{ required: true, validate: entityNameValidation }}
                  error={Boolean(errors.entityFullName)}
                />
                <ErrorMessage error={Boolean(errors.entityFullName)} message={t('entities:invalid-entry-name')} />
              </Grid>
              {isUpe && (
                <>
                  <Grid item xs={6} className={classes.inputGridElement}>
                    <Typography className={classes.label}>{t('entities:column-code')}</Typography>
                    <Controller
                      name="entityCode"
                      render={({ value, onChange }) => (
                        <TextField
                          id="entityCode"
                          name="entityCode"
                          className={classes.textField}
                          margin="dense"
                          variant="outlined"
                          value={value}
                          error={Boolean(errors.entityCode)}
                          onChange={onChange}
                        />
                      )}
                      control={control}
                      defaultValue={defaultValues.entityCode}
                      rules={{ required: true, validate: entityCodeValidation }}
                      error={Boolean(errors.entityCode)}
                    />
                    <Typography color="error" variant="caption">
                      {errors.entityCode && t('entities:invalid-entry-code')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.inputGridElement}>
                    <Typography className={classes.label}>{t('column-currency')}</Typography>
                    <Controller
                      name="currency"
                      render={({ onChange, value }) =>
                        hasTransactions! || hasNonUpeEntities ? (
                          <TextField
                            disabled
                            id="currency"
                            className={classes.textField}
                            variant="outlined"
                            margin="dense"
                            value={value?.value}
                            placeholder={t('placeholder-select-currency')}
                            onChange={onChange}
                          />
                        ) : (
                          <SearchBox
                            error={Boolean((errors as any).currency)}
                            value={value}
                            options={currencyOptions}
                            getOptionSelected={(option: CurrencyOption, value: CurrencyOption) =>
                              option.data.currencyId === value.data.currencyId
                            }
                            getOptionLabel={(parent: CurrencyOption) => parent.data.name}
                            onChange={(_, item) => {
                              onChange(item);
                            }}
                          />
                        )
                      }
                      control={control}
                      defaultValue={(defaultValues as UPEInputs).currency}
                      rules={{ required: true }}
                      error={Boolean((errors as any).currency)}
                    />
                    <ErrorMessage
                      error={Boolean((errors as any).currency)}
                      message={t('invalid-entry-required-currency')}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6} className={classes.inputGridElement}>
                <Typography className={classes.label}>{t('column-domicile')}</Typography>
                <Controller
                  name="domicile"
                  render={({ onChange, value }) =>
                    defaultValues.domicile ? (
                      <TextField
                        disabled
                        id="domicile"
                        name="domicile"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        value={value.value}
                      />
                    ) : (
                      <SearchBox
                        placeholder={t('entities:placeholder-domicile')}
                        error={Boolean(errors.domicile)}
                        value={value}
                        options={domicileOptions}
                        getOptionLabel={(option: DomicileOption) => option.value}
                        getOptionSelected={(option: DomicileOption, value: DomicileOption) =>
                          option.value === value.value
                        }
                        renderOption={(option) => <Typography>{option.value}</Typography>}
                        onChange={(_, data) => {
                          onChange(data);
                          setTaxJurisdictionName(data);
                        }}
                      />
                    )
                  }
                  control={control}
                  defaultValue={defaultValues.domicile}
                  rules={{ required: true }}
                  error={Boolean((errors as any).currency)}
                />
                <ErrorMessage error={Boolean(errors.domicile)} message={t('invalid-entry-domicile')} />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box>
                  <Typography className={classes.label}>{t('entities:column-tax-jurisdiction')}</Typography>
                  <Controller
                    name="taxJurisdiction"
                    render={({ value, onChange }) => (
                      <TextField
                        disabled
                        id="taxJurisdiction"
                        name="taxJurisdiction"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    control={control}
                    defaultValue={defaultValues.taxJurisdiction}
                    rules={{ required: false }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Typography className={classes.label}>{t('entities:column-fiscal-year-end')}</Typography>
                <Controller
                  name="fiscalYearEnd"
                  render={({ onChange }) => (
                    <SimpleDatePicker defaultDate={defaultValues.fiscalYearEnd ?? ''} onChange={onChange} />
                  )}
                  defaultValue={defaultValues.fiscalYearEnd ?? null}
                  control={control}
                  rules={{ required: true, validate: validateFiscalYearEnd }}
                  error={Boolean(errors.fiscalYearEnd)}
                />
                <ErrorMessage error={Boolean(errors.fiscalYearEnd)} message={fiscalYearEndErrorMessage} />
              </Grid>
              <Grid item xs={6} className={classes.inputGridElement}>
                <Box width="100%">
                  <Typography className={classes.label}>{t('entities:column-primary-function')}</Typography>
                  <FormControl size="small" variant="outlined" className={classes.formControl}>
                    <Controller
                      render={({ onChange, value }) => (
                        <Select
                          displayEmpty
                          value={value}
                          onChange={($event) => {
                            onChange($event);
                            onSelectPrimaryFunction($event);
                          }}
                        >
                          <MenuItem disabled value="">
                            <div className={classes.placeHolder}>{t('entities:placeholder-primary-function')}</div>
                          </MenuItem>
                          {menuItems.map(({ value, title }) => (
                            <MenuItem key={value} value={value}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      name="primaryFunction"
                      control={control}
                      defaultValue={defaultValues.primaryFunction}
                      rules={{ required: true }}
                    />
                  </FormControl>
                </Box>
                <ErrorMessage
                  error={Boolean(errors.primaryFunction)}
                  message={t('entities:invalid-entry-primary-function')}
                />
                {hasOtherPrimaryFunction ? (
                  <>
                    <Controller
                      name="otherPrimaryFunction"
                      render={({ value, onChange }) => (
                        <TextField
                          name="otherPrimaryFunction"
                          id="otherPrimaryFunction"
                          className={classes.otherPrimaryFunction}
                          margin="dense"
                          variant="outlined"
                          value={value ? value : defaultValues.otherPrimaryFunction}
                          inputProps={{ maxLength: 75 }}
                          onChange={onChange}
                        />
                      )}
                      control={control}
                      defaultValue={defaultValues.otherPrimaryFunction ?? ''}
                      rules={{ required: true }}
                    />
                    <ErrorMessage
                      error={Boolean(errors.otherPrimaryFunction)}
                      message={t('entities:invalid-other-primary-function')}
                    />
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          {!isUpe && (
            <>
              <FormDivider />
              <Grid container item xs={12} justify="space-around" className={classes.organisationStructure}>
                <OrganisationStructure
                  defaultValues={defaultValues}
                  entities={entityOptions}
                  formMethods={formMethods}
                />
              </Grid>
            </>
          )}
        </form>
      </Grid>
    </TPModal>
  );
};
