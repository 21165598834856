import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { TbaComparableTransactionsHeader } from './TbaComparableTransactionsHeader';
import { TableDisplayData, TbaFinalRangeComponentProps } from './TbaFinalRange.proptype';
import { SearchAndSort } from '../../components';
import { SortControl, SortOrder } from '../../components/SortControl';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { filterData, sortData } from '../../services/filtering';
import tokens from '../../styles/designTokens';
import { body2 } from '../../styles/typography';
import { formatRangeValue } from '../../utils';
import { Table } from '../Table';
import { statusIdMap } from '../TbaComparableAnalysis/TbaComparableAnalysis.proptype';
import {
  StatusTypes,
  TbaUncontrolledTransactionComparability
} from '../TbaUncontrolledTransactions/TbaUncontrolledTransactions.proptype';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  scrollTable: {
    width: '100%',
    '& .MuiTableCell-root': {
      '&.MuiTableCell-body': {
        '& .MuiIconButton-root': {
          color: tokens.product100,
          '&:hover': {
            backgroundColor: tokens.product25
          }
        }
      }
    }
  },
  tableWrapper: {
    overflow: 'auto',
    height: '100%'
  },
  rowNotSelected: {
    '&:hover': {
      '& .MuiTableCell-root': {
        backgroundColor: tokens.neutral60
      }
    },
    '& td.MuiTableCell-body:first-child': {
      ...body2,
      width: '10rem'
    },
    '& td.MuiTableCell-body:nth-child(2)': {
      ...body2
    }
  }
}));

export const TbaComparableTransactionsTable = ({
  uncontrolledTransactions,
  currentUncontrolledTransactionComparabilities,
  selectedTba,
  isUnitPrice
}: TbaFinalRangeComponentProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [featureFlagIsActive] = useFeatureFlags();

  const showRangeValueFixFormatting = featureFlagIsActive('tbaRangeValuesFormatting');

  const [sortObject, setSort] = useState<{ sortBy: string; sortOrder: SortOrder }>({
    sortBy: 'name',
    sortOrder: 'asc'
  });
  const [filterObject, setFilter] = useState<Record<string, string>>({});
  const tbaMethodTransactionTypeMap: { [key: string]: Array<{ name: string; value: string }> } = {
    Services: [
      { name: 'CP', value: '(%) Gross Profit Markup' },
      { name: 'CUT/CUP', value: '(%) Service' }
    ],
    'Tangible Goods': [
      { name: 'CP', value: '(%) Gross Profit Markup' },
      { name: 'CUT/CUP', value: 'Unit Price' },
      { name: 'RSP', value: '(%) Gross Profit Margin' }
    ],
    'Intangible Goods': [{ name: 'CUT/CUP', value: '(%) Royalty' }],
    Loans: [{ name: 'CUT/CUP', value: '(%) Interest' }]
  };

  const tbaMethodEvaluationList = selectedTba ? tbaMethodTransactionTypeMap[selectedTba.transactionType.name] : [];
  const selectedTbaMethodEvaluation = tbaMethodEvaluationList.find(
    (tbaMethodEvaluation) => tbaMethodEvaluation.name === selectedTba?.tbaEvaluationMethod.name
  );
  const selectedTbaMethodEvaluationValue = selectedTbaMethodEvaluation
    ? selectedTbaMethodEvaluation.value
    : '(%) Final Range Value';

  let tableDisplayData: TableDisplayData[] = [];

  const uncontrolledTransactionsBySelectedStatus: TbaUncontrolledTransactionComparability[] = currentUncontrolledTransactionComparabilities.filter(
    (aTransaction) => aTransaction.status === statusIdMap.accepted
  );

  const filteredTransactions = uncontrolledTransactionsBySelectedStatus.map((comp) => {
    const uncontrolledTransaction = uncontrolledTransactions.find(
      (trans) => trans.transactionId === comp.uncontrolledTransaction.transactionId
    );

    const getFinalRangeValue = () => {
      if (showRangeValueFixFormatting) return formatRangeValue(comp.tbaRangeComponent?.finalRangeValue, isUnitPrice);
      if (selectedTbaMethodEvaluationValue === 'Unit Price') return comp.tbaRangeComponent?.finalRangeValue;
      return `${((comp.tbaRangeComponent?.finalRangeValue ?? 0) * 100).toFixed(2)}`;
    };

    return {
      identifier: uncontrolledTransaction?.identifier,
      entityName: `${
        uncontrolledTransaction?.ucSourceLegalEntity?.name
          ? String(uncontrolledTransaction?.ucSourceLegalEntity?.name)
          : String(uncontrolledTransaction?.sourceLegalEntity?.name)
      } → ${
        uncontrolledTransaction?.ucDestinationLegalEntity?.name
          ? String(uncontrolledTransaction?.ucDestinationLegalEntity?.name)
          : String(uncontrolledTransaction?.destinationLegalEntity?.name)
      }`,
      finalRangeValue: getFinalRangeValue(),
      className: classes.rowNotSelected
    };
  });

  tableDisplayData = (sortData(
    filterData(filteredTransactions, filterObject),
    sortObject
  ) as unknown) as TableDisplayData[];

  const chips = [
    {
      amount: tableDisplayData.length,
      disabled: false,
      isSelected: true,
      key: 'total' as StatusTypes,
      label: t('analysis:comparable-range-total')
    }
  ];

  const displayColumns = [
    {
      key: 'identifier',
      header: (
        <SortControl
          field={t('analysis:column-id')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'identifier', sortOrder });
          }}
        />
      ),
      width: 50
    },
    {
      key: 'entityName',
      header: (
        <SearchAndSort
          field={t('analysis:search-companies')}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'entityName', sortOrder });
          }}
          onSearchChange={(value) => {
            setFilter({ ...filterObject, entityName: value });
          }}
        />
      ),
      width: 200
    },
    {
      key: 'finalRangeValue',
      header: (
        <SortControl
          field={selectedTbaMethodEvaluationValue}
          onSortClicked={(sortOrder) => {
            setSort({ sortBy: 'finalRangeValue', sortOrder });
          }}
        />
      ),
      width: 50
    }
  ];

  return (
    <Box className={classes.container}>
      <TbaComparableTransactionsHeader chips={chips} />
      <Box className={classes.tableWrapper}>
        <Table className={classes.scrollTable} data={tableDisplayData} columns={displayColumns} />
      </Box>
    </Box>
  );
};
