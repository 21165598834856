import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Import } from './Import';
import { Upload } from './Upload';
import { TPModal, TPModalActions } from '../../../components';
import { ImportMapping } from '../../../models';
import tokens from '../../../styles/designTokens';
import { body3, title1 } from '../../../styles/typography';
import { UploadIcon } from '../../../svgs';
import { logGoogleAnalyticsEvent } from '../../../utils/sendGoogleAnalyticaEvent';
import { ImportModalProps } from '../ImportModal.proptype';

const useStyles = makeStyles((theme) => ({
  cancelButtonOnImport: {
    marginRight: 'auto'
  },
  modalTitleWrapper: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  headerIcon: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },
    '& svg': {
      fill: theme.palette.primary.contrastText
    }
  },
  contentTitle: {
    color: tokens.core3,
    marginBottom: '1rem',
    ...title1
  },
  contentLabel: {
    color: tokens.core2,
    ...body3,
    letterSpacing: '0.009rem',
    fontSize: '0.90rem'
  },
  fileEmptyContainer: {
    width: '100%',
    padding: '3rem 6rem'
  }
}));

export const ImportModal = ({
  namespace,
  loading,
  mappings,
  allowedMappings,
  disableMappings,
  onClose,
  onUpload,
  onImport,
  containerId
}: ImportModalProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [file, setFile] = useState<File | null | undefined>();
  const [editedMappings, setEditedMappings] = useState<ImportMapping[] | undefined>();

  useEffect(() => {
    setFile(null);
    setEditedMappings(mappings);
  }, [mappings]);

  const uploadActions: TPModalActions[] = [
    { name: t('action-cancel'), handler: onClose },
    {
      name: t('action-upload'),
      id: `button-newui-import-${namespace}-modal-upload`,
      variant: 'contained',
      color: 'primary',
      disabled: !file,
      handler: () => {
        onUpload(file!);
      }
    }
  ];

  const importActions: TPModalActions[] = [
    {
      name: t('action-back'),
      variant: 'outlined',
      className: classes.cancelButtonOnImport,
      handler: () => {
        setEditedMappings(undefined); // eslint-disable-line unicorn/no-useless-undefined
      }
    },
    { name: t('action-cancel'), handler: onClose },
    {
      name: t('action-import'),
      id: `button-newui-import-${namespace}-modal-import`,
      variant: 'contained',
      color: 'primary',
      disabled: editedMappings?.length === 0,
      handler: () => {
        /* eslint-disable camelcase */
        void logGoogleAnalyticsEvent({
          event_category: 'entity_clicks',
          event_label: `Entity Import modal upload click`,
          container_id: containerId
        });

        if (editedMappings) {
          onImport(editedMappings);
        }
      }
    }
  ];

  return (
    <TPModal
      isOpen
      maxWidth="md"
      title={
        <span className={classes.modalTitleWrapper}>
          <IconButton className={classes.headerIcon}>
            <UploadIcon />
          </IconButton>
          <Typography variant="h6">{t(`${namespace}:action-import`)}</Typography>
        </span>
      }
      actions={editedMappings ? importActions : uploadActions}
      onClose={onClose}
    >
      {editedMappings === undefined ? (
        <Upload namespace={namespace} loading={loading} file={file} onSelectFile={setFile} />
      ) : editedMappings.length === 0 ? (
        <Box className={classes.fileEmptyContainer}>
          <Typography className={classes.contentTitle}>{t('transactions:empty-file-uploaded-first-text')}</Typography>
          <Typography className={classes.contentLabel}>{t('transactions:empty-file-uploaded-second-text')}</Typography>
        </Box>
      ) : (
        <Import
          namespace={namespace}
          mappings={editedMappings}
          allowedMappings={allowedMappings}
          disableMappings={disableMappings}
          onChangeMapping={setEditedMappings}
        />
      )}
    </TPModal>
  );
};
