import { Box, makeStyles } from '@material-ui/core';
import { CenteredProgress, CompletionLabel } from '../../components';
import { DashboardCard } from '../DashboardCard';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    padding: theme.spacing(0, 1.5),
    gap: theme.spacing(1)
  }
}));

export interface CompletedItem {
  label: string;
  completed: boolean;
}

export interface CompletedItemCardProps {
  title: string;
  message: string;
  items?: CompletedItem[];
  onNavigate: () => void;
}

export const CompletedItemCard = ({ title, message, items, onNavigate }: CompletedItemCardProps) => {
  const classes = useStyles();
  return (
    <DashboardCard title={title} subtitle={message} onNavigate={onNavigate}>
      {items ? (
        <Box className={classes.container}>
          {items.map((props) => (
            <CompletionLabel key={props.label} {...props} />
          ))}
        </Box>
      ) : (
        <CenteredProgress />
      )}
    </DashboardCard>
  );
};
