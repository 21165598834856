import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { CustomIconMenu } from '../../../components';
import editAccess from '../../../components/HigherOrderComponents/EditAccess';
import { sortData } from '../../../services/filtering';
import { hasEditAccess } from '../../../utils';
import { ConversionTableProps } from '../CurrencyConversionModal.proptype';

const useStyles = makeStyles((theme) => ({
  table: {
    borderRight: `1px solid ${theme.palette.divider}`,
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.background.paper
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(1)
    },
    '& td.MuiTableCell-root': {
      borderLeft: `1px solid ${theme.palette.divider}`,
      textAlign: 'right'
    }
  },
  actionCell: {
    '&.MuiTableCell-root': {
      padding: 0
    }
  }
}));

export const ConversionTable = ({
  rates,
  entities,
  years,
  onDeleteEntityRates,
  onEditEntityRate
}: ConversionTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [pendingCurrencyValues, setPendingCurrencyValues] = useState<any>({});

  const columns = [
    {
      name: t('entities:column-code'),
      isHeader: true
    },
    {
      name: t('column-currency'),
      isHeader: true
    },
    ...years.map((name) => ({ name, isHeader: false }))
  ];

  if (hasEditAccess()) {
    columns.push({ name: '', isHeader: false }); // empty header for action icons
  }

  const currenciesByEntityId = useMemo(() => {
    const map = new Map();
    for (const { entityId, currency } of entities) {
      map.set(entityId, t('label-name-and-code', currency));
    }

    return map;
  }, [entities, t]);

  const rows = useMemo(() => {
    const ratesByEntity = new Map();
    for (const { legalEntity, taxYear, conversionValue } of rates) {
      let rates = ratesByEntity.get(legalEntity.entityId);
      if (!rates) {
        rates = {
          entityId: legalEntity.entityId,
          entityName: legalEntity.name,
          entityCode: legalEntity.code,
          currency: currenciesByEntityId.get(legalEntity.entityId),
          values: {}
        };
        ratesByEntity.set(rates.entityId, rates);
      }

      rates.values[taxYear] = conversionValue;
    }

    const sortedRows = sortData([...ratesByEntity.values()], { sortBy: 'entityName', sortOrder: 'asc' });
    const stringValues: any = {};

    // we store stringified values in state so we can maintain the string formatting while the user is typing
    for (const [i, country] of sortedRows.entries()) {
      stringValues[i] = {};
      const refValue = stringValues[i];
      Object.keys(country?.values).forEach((year) => {
        refValue[year] = country.values[year]?.toString();
      });
    }

    setPendingCurrencyValues(stringValues);
    return sortedRows;
  }, [rates, currenciesByEntityId]);

  return (
    <Table stickyHeader className={classes.table}>
      <colgroup>
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>
      <TableHead>
        <TableRow>
          {columns.map(({ name, isHeader }) => (
            <TableCell key={name ?? 'action'} component={isHeader ? 'th' : 'td'}>
              {name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(({ entityId, entityName, entityCode, currency }, index) => (
          <TableRow key={entityId}>
            <TableCell component="th" scope="row">
              {entityCode}
            </TableCell>
            <TableCell component="th" scope="row">
              {currency}
            </TableCell>
            {years.map((year) => (
              <TableCell key={year}>
                <Input
                  disableUnderline
                  type="number"
                  placeholder={t('entities:placeholder-rate')}
                  margin="dense"
                  defaultValue={pendingCurrencyValues[index] ? pendingCurrencyValues[index][year] : ''}
                  disabled={!hasEditAccess()}
                  onBlur={({ target }) => {
                    // clears invalid entries when leaving the field
                    if (Number.isNaN(Number.parseFloat(target.value))) {
                      target.value = '';
                    } else {
                      // update the displayed value
                      target.value = Number.parseFloat(target.value).toString();
                    }
                  }}
                  onChange={({ target: { value } }) => {
                    // update the displayed value as a string
                    const newStringValues: any = JSON.parse(JSON.stringify(pendingCurrencyValues));
                    newStringValues[index][year] = value;
                    setPendingCurrencyValues(newStringValues);

                    // update the stored value as a number
                    onEditEntityRate(entityId, year, Number.parseFloat(value));
                  }}
                />
              </TableCell>
            ))}
            {editAccess(
              <TableCell className={classes.actionCell}>
                <CustomIconMenu
                  Icon={MoreHoriz}
                  menuItems={[
                    {
                      text: t('entities:action-delete-rate'),
                      handleOnClick: () => {
                        onDeleteEntityRates({ entityId, name: entityName });
                      }
                    }
                  ]}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
