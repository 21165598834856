import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BulkRejectionsTab as BulkRejectionsTabPage } from './components/BulkRejections';
import { FinalTab } from './components/FinalRange';
import { FineTuning } from './components/FineTuning';
import { GapAnalysis } from './components/GapAnalysis/GapAnalysisTab';
import { GapAnalysis as NewGapAnalysis } from './components/GapAnalysis/GapAnalysisTabNewUI';
import { InitialTab } from './components/InitialRange';
import { TuneCompSearchTab, ConnectorProps } from './TuneCompSearch.proptype';
import { GapAnalysisResponse, PbaJurisdictionPliFormat } from '../../models';
import {
  fetchJurisdictionPlis,
  fetchPbaJurisdictions,
  fetchCurrentTestedParty,
  updatePbaJurisdictionStatus,
  fetchPBAs,
  fetchJurisdictionPliFormats,
  clearCompSearch,
  fetchGapAnalysis,
  fetchNewGapAnalysis,
  fetchManualComparablesCompanies
} from '../../redux/profitBasedAnalyses';
import { startTimer } from '../../redux/studies';
import {
  selectJurisdictionPlis,
  selectCurrentTestedParty,
  selectPBAJurisdictionById,
  selectCountry,
  selectPBAsList,
  selectPBA,
  selectJurisdictionPliFormat,
  selectStudiesTimer,
  selectGapAnalysis,
  selectWorkingContainer
} from '../../selectors';
import { AppDispatch } from '../../store';
import { getAppConfig } from '../../utils';
import { JurisdictionPli } from '../JurisdictionAnalysisInfo/JurisdictionAnalysisInfo.proptype';

const { InititalRangeTab, BulkRejectionsTab, FineTuningTab, FinalRangeTab, GapAnalysisTab } = TuneCompSearchTab;

const Connector = ({ component: Component }: ConnectorProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [selectedKey, setSelectedKey] = useState(InititalRangeTab);

  const pbaId = Number(useParams<{ pbaId: string }>().pbaId);
  const compSearchId = Number(useParams<{ compSearchId: string }>().compSearchId);
  const jurisdictionId = Number(useParams<{ jurisdictionId: string }>().jurisdictionId);
  const pliId = Number(useParams<{ pliId: string }>().pliId);
  const pliAvgId = Number(useParams<{ pliAvgId: string }>().pliAvgId);
  const pliTypeId = Number(useParams<{ pliId: string }>().pliId);
  const studyId = Number(useParams<{ studyId: string }>().studyId);
  const flags = useFlags();
  const { xbsEnvironmentShort } = getAppConfig();
  const container = useSelector(selectWorkingContainer);
  const containerId = container?.containerId;
  const gapAnalysisFlag = flags.wrathGapAnalysisExperiment;
  const newGapAnalysisPageFlag = flags.newGapAnalysisPage;
  const showPinnedComps =
    flags.priorYearCompsYear2 &&
    (flags.priorYearCompsYear2 === true ||
      flags.priorYearCompsYear2.includes(`${String(xbsEnvironmentShort)}-${String(containerId)}`) ||
      flags.priorYearCompsYear2.includes(`${String(xbsEnvironmentShort)}-*`));

  const gapAnalysisCompPool: GapAnalysisResponse | undefined = useSelector(selectGapAnalysis);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliId && pliAvgId && pbaId && gapAnalysisFlag) {
      void dispatch(
        fetchGapAnalysis({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId,
          pbaId
        })
      );
    }
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId, pbaId, gapAnalysisFlag]);

  const existGapInfo = useMemo(() => {
    if (gapAnalysisFlag) {
      return gapAnalysisCompPool?.previousYearCompPool ? gapAnalysisCompPool.previousYearCompPool.length > 0 : false;
    }

    return false;
  }, [gapAnalysisFlag, gapAnalysisCompPool]);

  useEffect(() => {
    if (
      selectedKey === GapAnalysisTab &&
      compSearchId &&
      jurisdictionId &&
      pliId &&
      pliAvgId &&
      pbaId &&
      gapAnalysisFlag &&
      existGapInfo &&
      newGapAnalysisPageFlag
    ) {
      void dispatch(
        fetchNewGapAnalysis({
          compSearchId,
          jurisdictionId,
          pliId,
          pliAvgId,
          pbaId
        })
      );
    }
  }, [
    dispatch,
    compSearchId,
    jurisdictionId,
    pliId,
    pliAvgId,
    pbaId,
    gapAnalysisFlag,
    newGapAnalysisPageFlag,
    selectedKey,
    existGapInfo
  ]);

  useEffect(() => {
    if (compSearchId && jurisdictionId && pliId && pliAvgId) {
      dispatch(clearCompSearch());
    }
  }, [dispatch, compSearchId, jurisdictionId, pliId, pliAvgId]);

  useEffect(() => {
    if (pbaId && showPinnedComps) {
      void dispatch(
        fetchManualComparablesCompanies({
          runOnDate: format(new Date(), 'yyyy-MM-dd'),
          pbaId
        })
      );
    }
  }, [dispatch, pbaId, showPinnedComps]);

  const currentTestedParty = useSelector(selectCurrentTestedParty);
  const pbaJurisdictionDetails = useSelector(selectPBAJurisdictionById(jurisdictionId));
  const jurisdictionPlis = useSelector(selectJurisdictionPlis(jurisdictionId));
  const jurisdictionInfo = useSelector(selectCountry(jurisdictionId));
  const pbas = useSelector(selectPBAsList);
  const pba = useSelector(selectPBA(pbaId));
  const studiesTimer = useSelector(selectStudiesTimer);
  const jurisdictionStatusInfo = pba?.jurisdictionStatuses
    ? pba.jurisdictionStatuses.find((status) => status.jurisdictionId === jurisdictionId)
    : undefined;
  const selectedPli = jurisdictionPlis
    ? jurisdictionPlis.find((pli: JurisdictionPli) => pli.pliAvgTypeId === pliAvgId && pli.pliTypeId === pliId)
    : null;
  const jurisdictionPliFormat: PbaJurisdictionPliFormat[] = useSelector(selectJurisdictionPliFormat(jurisdictionId));

  const tabs = [
    {
      key: InititalRangeTab,
      label: t('analysis:initial-range'),
      disabled: false,
      content: <InitialTab />
    },
    {
      key: BulkRejectionsTab,
      label: t('analysis:bulk-rejections'),
      disabled: false,
      content: <BulkRejectionsTabPage />
    },
    {
      key: FineTuningTab,
      label: t('analysis:fine-tuning'),
      disabled: false,
      content: <FineTuning />
    },
    {
      key: FinalRangeTab,
      label: t('analysis:final-range'),
      disabled: false,
      content: <FinalTab />
    }
  ];

  if (gapAnalysisFlag && !newGapAnalysisPageFlag && existGapInfo) {
    tabs.push({ key: GapAnalysisTab, label: t('analysis:gap-analysis'), disabled: false, content: <GapAnalysis /> });
  }

  if (gapAnalysisFlag && newGapAnalysisPageFlag && existGapInfo) {
    tabs.push({
      key: GapAnalysisTab,
      label: t('analysis:gap-analysis'),
      disabled: false,
      content: (
        <NewGapAnalysis
          compSearchId={compSearchId}
          jurisdictionId={jurisdictionId}
          pliTypeId={pliTypeId}
          pliAverageTypeId={pliAvgId}
          pbaId={pbaId}
        />
      )
    });
  }

  const updateJurisdictionStatus = async (status: number) => {
    if (jurisdictionStatusInfo) {
      const updatedJurisdictionStatus = {
        status,
        jurisdictionId: jurisdictionStatusInfo.jurisdictionId,
        jurisdictionIsoCode: jurisdictionStatusInfo.jurisdictionIsoCode
      };

      await dispatch(updatePbaJurisdictionStatus({ pbaId, jurisdictionStatus: updatedJurisdictionStatus }));
      void dispatch(fetchPBAs(studyId));
    }
  };

  useEffect(() => {
    if (!pbaJurisdictionDetails) {
      void dispatch(fetchPbaJurisdictions(pbaId));
    }

    if (pbaJurisdictionDetails && !jurisdictionPlis) {
      void dispatch(
        fetchJurisdictionPlis({
          compSearchId,
          jurisdictionId
        })
      );
    }
  }, [dispatch, jurisdictionId, compSearchId, jurisdictionPlis, pbaJurisdictionDetails, pbaId]);

  useEffect(() => {
    if (pbas === null) {
      void dispatch(fetchPBAs(studyId));
    }

    if (!currentTestedParty) {
      void dispatch(fetchCurrentTestedParty(pbaId));
    }
  }, [dispatch, currentTestedParty, pbas, studyId, pbaId]);

  useEffect(() => {
    if (!jurisdictionPliFormat) {
      void dispatch(fetchJurisdictionPliFormats({ compSearchId, jurisdictionId }));
    }
  }, [compSearchId, dispatch, jurisdictionId, jurisdictionPliFormat]);

  useEffect(() => {
    if (!studiesTimer) {
      dispatch(startTimer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Component
      jurisdictionInfo={jurisdictionInfo}
      jurisdictionStatus={jurisdictionStatusInfo}
      updateJurisdictionStatus={updateJurisdictionStatus}
      selectedPli={selectedPli}
      selectedKey={selectedKey}
      tabs={tabs}
      onSelectTab={(arg: string) => {
        const selectedTab = tabs.find((tab) => tab.key === arg);
        setSelectedKey(selectedTab?.key!);
      }}
    />
  );
};

export default Connector;
