import { useRef, MutableRefObject, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { logUserLogout, redirectToLogin } from '../../utils';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
    height: '100%'
  },
  iframeStyle: {
    flexGrow: 1,
    border: 1
  }
}));

export const MasterFile = () => {
  const classes = useStyles();
  const iframeRef = useRef() as MutableRefObject<HTMLIFrameElement>;
  const baseUrl = window.location.origin;
  const reportsUrl = `${baseUrl}/legacy/master-file?iframe=true`;

  const onHandlerWindowMessage = useCallback((e: MessageEvent) => {
    const data = typeof e?.data === 'string' ? JSON.parse(e.data) : e.data;
    if (data.kind === 'AUTH' && data.message === 'SESSION_EXPIRED') {
      void logUserLogout('session_expired');
      void redirectToLogin();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onHandlerWindowMessage);
    return () => {
      window.removeEventListener('message', onHandlerWindowMessage);
    };
  }, [onHandlerWindowMessage]);

  return (
    <div className={classes.content}>
      <iframe ref={iframeRef} className={classes.iframeStyle} src={reportsUrl} />
    </div>
  );
};
